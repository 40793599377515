// import React, { useEffect, useState } from 'react';

// const CustomNotificationRadioButton = ({
//   fieldNameOption1,
//   fieldNameOption2,
//   publishContent,
//   publishContentHo,
//   onPublishContentChange,onPublishContentHoChange
// }) => {
//   // Use state to manage whether to display options

 

//   return (
//     <div>
     
//         <div>
//         {publishContent === 'Yes' && (
//           <label style={{ fontSize: 16, fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'` }}>
//             <input
//               type="radio"
//               name={fieldNameOption1}
//               value="option1"

//               onChange={onPublishContentChange}
//             />
//             <span>
//               General Document - This will be Publish General Document to Homeowners
//             </span>
//           </label>
//           )}
//           <br />
//           {publishContentHo === 'Yes' && (

//           <label style={{ fontSize: 16, fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'` }}>
//             <input
//               type="radio"
//               name={fieldNameOption2}
//               value="option2"
//               onChange={onPublishContentHoChange}
//             />
//             <span>
//               Need 51% Approval from Board Member
//             </span>
//           </label>
//           )}
//         </div>
      
//     </div>
//   );
// };

// export default CustomNotificationRadioButton;
import React from 'react';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Typography, Tooltip, IconButton } from '@mui/material';
import { Controller } from 'react-hook-form';
import { InfoOutlined } from "@mui/icons-material";

function CustomNotificationRadioButton(props) {
  return (
    <div className="flex items-center md:w-1/2 w-full">
      <Controller
        name={props.fieldName}
        control={props.fieldControl}
        rules={{
          required: props.publishContent === 'Yes' || props.publishContentHo === 'Yes' ? props.requiredErrorMessage : false 
        }}
        render={({ field, fieldState }) => (
          <FormControl fullWidth error={fieldState.invalid}>
            {(props.publishContent === 'Yes' || props.publishContentHo === 'Yes') && (
              
              <div style={{ display: 'flex', alignItems: 'center' }}>
                 
                <FormLabel id="demo-row-radio-buttons-group-label">
                    <div>
                      <Typography style={{ fontSize: 16, fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'` }}>
                    {props.labelName}
                        <span className='text-red-500'>*</span>
                  </Typography>
                    </div>
                </FormLabel>
                <Tooltip
                  title={
                    <Typography sx={{ fontSize: '14px' }}>
                      {props.tooltipMessage}
                    </Typography>
                  }
                  placement="right"
                  arrow
                >
                 <IconButton size="small" style={{ marginLeft: 'auto' }}>
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              </div>
              
            )}
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              {...field}
              value={props.value} // Set the initial value based on the prop
              onChange={(e) => {
                field.onChange(e);
                const selectedValue = e.target.value;
                props.onChange && props.onChange(selectedValue);
              }}
            >
              {props.publishContent === 'Yes' && (
                <FormControlLabel
                  value={props.valueOne}
                  control={<Radio />}
                  label={
                    <Typography style={{ fontSize: 16, fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'` }}>
                      {props.labelOne}
                    </Typography>
                  }
                />
              )}
              {props.publishContentHo === 'Yes' && (
                <FormControlLabel
                  value={props.valueTwo}
                  control={<Radio />}
                  label={
                    <Typography style={{ fontSize: 16, fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'` }}>
                      {props.labelTwo}
                    </Typography>
                  }
                />
              )}
            </RadioGroup>
            {(props.publishContent === 'Yes' || props.publishContentHo === 'Yes') && (
              <FormHelperText style={{ color: "#D32F2F" }}>
                {fieldState.invalid ? fieldState.error?.message : ""}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </div>
  );
}

export default CustomNotificationRadioButton;

