import React, { useEffect, useMemo, useState } from "react";
import CustomHeading from "../../../components/CustomHeading";
import CustomMaterialTable from "../../../components/CustomMaterialTable";
import { json, useNavigate, useParams } from "react-router-dom";
import {
  EditNoteOutlined,
  ListAlt,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import CustomModel from "../../../components/CustomModel";
import { Controller, useForm } from "react-hook-form";
import {
  CATEGORY_REQUIRED_MESSAGE,
  DESCRIPTION_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_THOUSAND,
  MAX_LENGTH_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
  PUBLISH_DATE_REQUIRED_MESSAGE,
  DOCUMENT_TYPE_INVALID_MESSAGE,
} from "../../../utills/ApplicationConstants";
import {
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import CustomInputField from "../../../components/CustomInputField";
import { FileUploader } from "react-drag-drop-files";
import CustomButton from "../../../components/CustomButton";
import CustomDatePicker from "../../../components/CustomDatePicker";
import CustomRadioButton from "../../../components/CustomRadioButton";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  BASE_URL,
  DOCUMENT,
  GET_ALL_ARC_DOCUMENT,
  GET_ALL_BM_DOCUMENT,
  GET_ALL_MC_DOCUMENT,
} from "../../../utills/ApplicationRouting";
import moment from "moment";
import { request } from "../../../services/AxiosConfig";
import CustomToastContainer from "../../../components/CustomToastContainer";
import CustomLoading from "../../../components/CustomLoading";
import { InfoOutlined } from "@mui/icons-material";
import {Tooltip,IconButton,ListItemText, ListItemSecondaryAction, ListItem} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import CustomPublishDatePicker from "../../../components/CustomPublishDate";

function McBmArcDocumentList() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openSendDoc, setOpenSendDoc] = useState(false);
  const [file, setFile] = useState(null);
  const [radioShow, setRadioShow] = useState(false);
  const [hideOpen, setHideOpen] = useState(false);
  const fileTypes = ["PDF"];
  const { token, roles, userDetails, userLoginDetails } = useSelector(
    (state) => state.hybridhoa
  );
  const [documentDetails, setDocumentDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [criteria, setCriteria] = useState("");
  const [publishDate, setPublishDate] = useState("");

  const minDate = dayjs().startOf("day");
  const maxDate = dayjs().add(1, "year").endOf("day");
  // const [selectedRow, setSelectedRow] = useState(null);
  const [categoryValue, setCategoryValue] = useState("");
  const { documentId } = useParams();
  const [updateRes, setUpdateRes] = useState([]);
  const selectedRow = updateRes;
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [publishDateData, setPublishDateData] = useState("");
  const [descriptionData, setDescriptionData] = useState("");
  const [fileData, setFileData] = useState(null);
  const [radioData, setRadioData] = useState("");
  const [editRadioShow, setEditRadioShow] = useState(false);
  const [PublishDateWarning, setPublishDateWarning] = useState(false);
  const [isHidden, setisHidden] = useState(false);
  const [hideSelectedRow, setHideSelectedRow] = useState("");
  const [publics,setPublics]=useState("")

  const handleFileChange = (file) => {
    setFile(file);
    setFileData(`Selected File: ${file.name}`);
  };
  const handleEditFileChange = (file) => {
    setFile(file);
      setFileData(`Selected File: ${file.name}`);
  };
  const handleCategoryChange = (event) => {
    setRadioShow(event.target.value);
    setSelectedCategory(event.target.value);
    console.log(event.target.value);
  };
  const handleViewClick = (selectedRow) => {
    navigate(`/mcbmarcdocument/${selectedRow.row.original.documentId}`);
  };

  const handleAddClickOpen = () => {
    navigate(`/mcbmarcadddocument`);
    // setOpen(true);
  };
  const handleAddClose = (status) => {
    if (status == 200) {
      setOpen(false);
    }
  };
  const handleIconClose = () => {
    setOpen(false);
    setSelectedCategory("");
    setRadioShow("");
    setCriteria("");
    setFile("");
  };

  const handleEditClose = (status) => {
    if (status == 200) {
      setOpenEdit(false);
    }
  };
  const handleEditIconClose = () => {
    setOpenEdit(false);
    setCategoryValue("");
    setEditRadioShow("");
    
    setFileData("");
  };

  const handleSendDocClose = (status) => {
    if (status == 200) {
      setOpenSendDoc(false);
    }
  };
  const handleSendDocIconClose = () => {
    setOpenSendDoc(false);
  };

  const handleHideClickOpen = (selectedRow) => {
    setHideOpen(true);
    setHideSelectedRow(selectedRow);
    console.log(selectedRow.documentId);
  };

  const handleHideClose = () => {
    setHideOpen(false);
  };
  const handleRadioChange = (e) => {
    setCriteria(e.target.value);
  };

  const handleEditPublish = (selectedPublishData) => {
    setPublishDateData(selectedPublishData);
  };
  const handleEditDescription = (e) => {
    setDescriptionData(e.target.value);
  };

  const handleEditCategory = (e) => {
    setEditRadioShow(e.target.value);
    setCategoryValue(e.target.value);
  };

  useEffect(()=>{
    if(categoryValue==="PUBLIC"){
      setPublics(true)
    }else{
      setPublics(false)
    }
  },[categoryValue])

  const handleEditRadio = (newValue) => {
    console.log("Checked value:", newValue)
    setRadioData(newValue);
  };

  const handlePublishDateChange = (selectedPublishDate) => {
    setPublishDate(selectedPublishDate);
    console.log("hi");
  };

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm();

  const getDocumentDetails = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);
      setIsLoading(true);
      const response = await axios.get(`${BASE_URL}${GET_ALL_ARC_DOCUMENT}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;

        setDocumentDetails(data);
        setPublishDateWarning(false);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const getDocumentDetailsByBm = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);
      setIsLoading(true);
      const response = await axios.get(`${BASE_URL}${GET_ALL_BM_DOCUMENT}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setDocumentDetails(data);
        setIsLoading(false);
        setPublishDateWarning(true);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const getDocumentDetailsByMc = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);
      setIsLoading(true);
      const response = await axios.get(`${BASE_URL}${GET_ALL_MC_DOCUMENT}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setDocumentDetails(data);
        setIsLoading(false);
        setPublishDateWarning(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

 
  useEffect(() => {
    const fetchData = async () => {

      {roles.includes('ROLE_BM')   ? ( 
        await getDocumentDetailsByBm()) 
        :
        roles.includes('ROLE_ARC') ? (
        await getDocumentDetails()) 
        :
        await getDocumentDetailsByMc()}
      };

    fetchData();
  }, [roles]);

  const onSubmit = (data) => {
    if (!data.description) {
      setError("description", {
        type: "manual",
        message: DESCRIPTION_REQUIRED_MESSAGE,
      });
      return;
    }
    const formData = new FormData();
    formData.append("category", selectedCategory);
    formData.append("description", data.description);
    if (selectedCategory === "PUBLIC") {
      formData.append("isPublic", data.documentType);
    } else {
      formData.append("isPublic", false);
    }
    formData.append("file", file);
    formData.append("publishDate", dayjs(publishDate).format("YYYY-MM-DD"));
    formData.append("isHidden", false);
    formData.append("role", roles[0]);

    console.log("description", data.description);
    console.log("category", selectedCategory);
    console.log("isPublic", data.documentType);
    console.log("publishDate", dayjs(publishDate).format("YYYY-MM-DD"));
    console.log("file:", file);
    // return;
    // if (!selectedCategory) {
    //   setError("category", {
    //     type: "manual",
    //     message: CATEGORY_REQUIRED_MESSAGE,
    //   });
    //   return;
    // }
    // return;
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    setIsLoading(true);

    request({ url: DOCUMENT, method: "post", data: formData, headers })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");
        
            {roles.includes('ROLE_BM')   ? ( 
               getDocumentDetailsByBm()) 
              :
              roles.includes('ROLE_ARC') ? (
             getDocumentDetails()) 
              :
             getDocumentDetailsByMc()}
            
        
          setIsLoading(false);
          handleAddClose(res.status);
          setSelectedCategory("");
          setRadioShow("");
          setCriteria("");
          setFile("");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };



  const handleEditClickOpen = (selectedRow) => {
    navigate(`/mcbmarceditdocument/${selectedRow.original.documentId}`);
    // setOpenEdit(true);

    // console.log("selectedRow:", selectedRow);
    // console.log("selectedRow.id:", selectedRow.original.documentId);
    // setSelectedRowId(selectedRow.original.documentId);
    // console.log(selectedRow.documentId);
    // getRenterDetailsById(selectedRow.original.documentId);
  };

  const handleSendDocClickOpen = (selectedRow) => {
    navigate(`/mcbmarcunhidedocument/${selectedRow.original.documentId}`);
    // setOpenSendDoc(true);


    // console.log("selectedRow:", selectedRow);
    // console.log("selectedRow.id:", selectedRow.original.documentId);
    // setSelectedRowId(selectedRow.original.documentId);
    // console.log(selectedRow.documentId);
    // getRenterDetailsById(selectedRow.original.documentId);
  };

  const onSubmitEdit = (data) => {
    if (descriptionData.trim() == "" || descriptionData == null) {
      setError("description", {
        type: "manual",
        message: DESCRIPTION_REQUIRED_MESSAGE,
      });
      return;
    }
    
    const publishDate = dayjs(publishDateData);
    const currentDate = dayjs();

    if (publishDate.isBefore(currentDate, 'day')) {
        setError("publishdate", {
            type: "manual",
            message: "Please select a future date",
        });
        setIsLoading(false);
        return;
    }
    const formData = new FormData();
    formData.append("category", categoryValue);
    formData.append("description", descriptionData);
    if (categoryValue  === "PUBLIC") {
      console.log("insife public");
      formData.append("isPublic", radioData);
    } else {
      console.log("insife public else ");

      formData.append("isPublic", false);
    }
    formData.append("file", file);
    formData.append("publishDate", dayjs(publishDateData).format("YYYY-MM-DD"));
    formData.append("isHidden", isHidden);
    formData.append("role", roles[0]);

    console.log("description", descriptionData);
    console.log("category", categoryValue);
    console.log("isPublic", radioData);
    console.log("publishDate", dayjs(publishDateData).format("YYYY-MM-DD"));
    console.log("file:", file);
    // return;
 
    console.log(formData);
    setIsLoading(true);
    request({
      url: `${DOCUMENT}/${selectedRow}`,
      method: "put",
      data: formData,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          setIsLoading(false);
          handleEditClose(res.status);
          // setEditRadioShow('');
          // setCategoryValue('');
          // setFileData('')
          {roles.includes('ROLE_BM')   ? ( 
            getDocumentDetailsByBm()) 
           :
           roles.includes('ROLE_ARC') ? (
          getDocumentDetails()) 
           :
          getDocumentDetailsByMc()}
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };
  const onSubmitSendDoc = (data) => {

    if (!categoryValue) {
      setError("lastName", {
        type: "manual",
        message: CATEGORY_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (descriptionData.trim() == "" || descriptionData == null) {
      setError("description", {
        type: "manual",
        message: DESCRIPTION_REQUIRED_MESSAGE,
      });
      return;
    }


    const publishDate = dayjs(publishDateData);
    const currentDate = dayjs();

    if (publishDate.isBefore(currentDate, 'day')) {
        setError("publishdate", {
            type: "manual",
            message: "Please select a date in the future",
        });
        setIsLoading(false);
        return;
    }
    const formData = new FormData();
    formData.append("category", categoryValue);
    formData.append("description", descriptionData.trim());
    if (categoryValue === "PUBLIC") {
      formData.append("isPublic", radioData);
    } else {
      formData.append("isPublic", false);
    }
    formData.append("file", file);
    formData.append("publishDate", dayjs(publishDateData).format("YYYY-MM-DD"));
    formData.append("isHidden", false);
    formData.append("role", roles[0]);

    console.log("description", descriptionData);
    console.log("category", categoryValue);
    console.log("isPublic", data.editDocumentType);
    console.log("publishDate", dayjs(publishDateData).format("YYYY-MM-DD"));
    console.log("file:", fileData);
    // return;
    setIsLoading(true);
    request({
      url: `${DOCUMENT}/${selectedRow}`,
      method: "put",
      data: formData,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          setIsLoading(false);
          handleSendDocClose(res.status);
          if(roles=="ROLE_ARC"){
            getDocumentDetails()
          }else if (roles=="ROLE_BM"){
            getDocumentDetailsByBm()
          }else if (roles=="ROLE_MC"){
            getDocumentDetailsByMc()
          }

          // {
          //   roles === "ROLE_ARC"
          //     ? getDocumentDetails()
          //     : roles === "ROLE_BM"
          //     ? getDocumentDetailsByBm()
          //     : getDocumentDetailsByMc();
          // }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };
  const onSubmitHide = (data) => {
    const DocumentId = hideSelectedRow.documentId;
    const formData = new FormData();
    formData.append("isHidden", true);
    setIsLoading(true);
    request({ url: `${DOCUMENT}/${DocumentId}`, method: "put", data: formData })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          setIsLoading(false);

          if(roles=="ROLE_ARC"){
            getDocumentDetails()
          }else if (roles=="ROLE_BM"){
            getDocumentDetailsByBm()
          }else if (roles=="ROLE_MC"){
            getDocumentDetailsByMc()
          }
          // {
          //   roles === "ROLE_ARC"
          //     ? getDocumentDetails()
          //     : roles === "ROLE_BM"
          //     ? getDocumentDetailsByBm()
          //     : getDocumentDetailsByMc();
          // }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '' || str === null) return '';
  
    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };
  

  const documentTable = useMemo((rowData) => [
    {
      accessorKey: "serialNo",
      header: "#",
      size: 20,
      Cell: ({ row }) => row.index + 1,
    },
    {
      accessorKey: "category",
      header: "Category",
      size: 20,
      accessorFn: (row) => convertToPascalCase(row.category),
    },
    // {
    //   accessorKey: "description",
    //   header: "Description",
    // },
    {
      accessorKey: "description",
      header: "Description",
      accessorFn: (row) => {
        const description = row && row.description;
        if (description && description.length > 28) {
          return description.slice(0, 28) + "...";
        }
        return description;
      }
      // Cell: ({ row }) => {
      //   const description = row.original.description;
      //   return description.length > 28 ? `${description.slice(0, 28)}...` : description
      // },
    },
    {
      accessorKey: "uploadedBy",
      header: "Uploaded By",
      accessorFn: (row) => `${row.createdByDetails? row.createdByDetails.firstName : ""} ${row.createdByDetails? row.createdByDetails.lastName : ""} `,
      sortDescFirst: true, 
   
    },
    {
      accessorKey: "createdDateAndTime",
      header: "Created Date",
      accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),
    },
   

    {
      accessorKey: "publishDate",
      header: "Publish Date",
      Cell: ({ row }) => {
        const publishDate = row.original.publishDate;
        const status = row.original.status;
        const isPublic = row.original.isPublic;
        const category = row.original.category;
  
        const isWithinOneWeek = moment(publishDate).isSameOrBefore(moment().add(1, 'weeks'));
        const shouldHighlight = PublishDateWarning &&  category !== "CONFIDENTIAL" && status == "PENDING"  && !isPublic && isWithinOneWeek;
        const dateStyle = { color: shouldHighlight ? 'red' : 'inherit' };
  
        return (
          <span style={dateStyle}>
            {moment(publishDate).format("MM/DD/YYYY")}
          </span>
        );
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 20,
      accessorFn: (row) => convertToPascalCase(row.status),
    },
    {
      accessorKey: "Document Type",
      header: "Document Type",
      size: 20,
      accessorFn: (row) => 
      row.category === "CONFIDENTIAL" 
        ? "N/A"
        : row.isPublic 
          ? "General Document - This will be Published General Document to Homeowners" 
          : "Need 51% Approval from Board Member",
      
    },
    {
      accessorKey: "isHidden",
      header: "Is Hidden",
      size: 20,
      accessorFn: (row) =>row.createdBy == userLoginDetails.homeOwnerLoginId ? (row.isHidden ? 'Yes':'No') : "N/A",
    },
  ]);



  const rowActions = (row) => {
    console.log(userLoginDetails.homeOwnerLoginId);
    const actions = [
      // {
      //   label: "View",
      //   icon: <ListAlt color="primary" />,
      //   click: () => handleViewClick(row),
      // },
    ];
    if (
      row.original.createdBy == userLoginDetails.homeOwnerLoginId &&
      !row.original.isHidden && row.original.status !== "APPROVED" && row.original.status!=="REJECTED"
    ) {
      actions.push({
        label: "Hide",
        icon: <VisibilityOutlined color="primary" />,
        click: () => handleHideClickOpen(row.original),
      });
    }
    if (
      row.original.createdBy == userLoginDetails.homeOwnerLoginId &&
      row.original.isHidden && row.original.status !== "APPROVED" 
    ) {
      actions.push({
        label: "Show",
        icon: <VisibilityOffOutlined color="primary" />,
        click: () => handleSendDocClickOpen(row),
      });
    }

    if (
      row.original.createdBy === userLoginDetails.homeOwnerLoginId && row.original.status !== "APPROVED" && row.original.status!=="REJECTED" && ((row.original.status=== "PENDING PUBLISH" && row.original.isPublic===true && row.original.category ==="PUBLIC") || (row.original.status=== "PENDING" && row.original.isPublic===false && row.original.category ==="PUBLIC")|| (row.original.status=== "PENDING PUBLISH" && row.original.isPublic===false && row.original.category ==="CONFIDENTIAL"))
    ) {
      actions.push({
        label: "Edit",
        icon: <EditNoteOutlined color="primary" />,
        click: () => handleEditClickOpen(row),
      });
    }
    return actions;
  };

  const tableActionButtons = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Add"
        click={handleAddClickOpen}
      />
    </>
  );

  const addActionButton = (
    <>
    <CustomButton fieldType="submit" buttonName="Add" click={handleAddClose} />
    {isLoading && <CustomLoading/>}
    </>
    
  );
  const addModel = (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography gutterBottom>
            <Controller
              name="category"
              control={control}
              rules={{ required:  CATEGORY_REQUIRED_MESSAGE}}
              render={({ field, fieldState }) => (
                <FormControl
                  variant="standard"
                  fullWidth
                  error={fieldState.invalid}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Select Category <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={selectedCategory}
                    // onChange={handleCategoryChange}
                    onChange={(e) => {
                      setSelectedCategory(e.target.value);
                      setRadioShow(e.target.value);
                      field.onChange(e.target.value);
                    }}
                    label="Please Select Category"
                  >
                    <MenuItem value="PUBLIC">Public</MenuItem>
                    <MenuItem value="CONFIDENTIAL">
                      <ListItemText primary="Confidential" />
                      <ListItemSecondaryAction>
                        <Tooltip title="confidential Documents Did not shows to Home Owners" arrow>
                          <IconButton edge="end" size="small">
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </MenuItem>
                  </Select>
                  <FormHelperText style={{ color: "#D32F2F" }}>
                    {fieldState.invalid ? fieldState.error?.message : ""}
                  </FormHelperText>
                </FormControl>
              )}
            />

            <br />
            <br />
            {radioShow === "PUBLIC" ? (
              <CustomRadioButton
                fieldName="documentType"
                fieldControl={control}
                fieldError={errors}
                labelName="Document Type"
                requiredErrorMessage={DOCUMENT_TYPE_INVALID_MESSAGE}
                valueOne={true}
                labelOne="General Document - This will be Publish General Document to Homeowners"
                valueTwo={false}
                labelTwo="Need 51% Approval from Board Member"
                // onChange={handleRadioChange}
             
              />
            ) : null}
<section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
            <CustomDatePicker
              fieldName="publishdate"
              label="Publish Date"
              control={control}
              requiredErrorMessage={PUBLISH_DATE_REQUIRED_MESSAGE}
              minDate={minDate}
              maxDate={maxDate}
              onChange={handlePublishDateChange}
              fieldRequiredIcon="*"
            />
</section>
            <CustomInputField
              fieldName="description"
              requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
              fieldId="description"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldLabel="Description"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
            />

            <br />
            <br />
            <FormLabel>
              Upload Document (PDF Format only){" "}
              <span style={{ color: "red" }}>*</span>
              <FileUploader
                handleChange={handleFileChange}
                name="file"
                types={fileTypes}
                required
              />
              {file ? (
                <p>Selected File: {file.name}</p>
              ) : (
                <p>No file selected</p>
              )}
            </FormLabel>
          </Typography>
        </DialogContent>
        <DialogActions>{addActionButton}</DialogActions>
      </form>
    </>
  );

  const editActionButton = (
    <CustomButton
      fieldType="submit"
      buttonName="Update"
      click={handleEditClose}
    >
      {isLoading && <CustomLoading />}
    </CustomButton>
  );
  const editModel = (
    <>
      {isLoading && <CustomLoading />}
      <form
        onSubmit={handleSubmit(onSubmitEdit)}
        className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography gutterBottom>
            <Controller
              name="category"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl
                  variant="standard"
                  fullWidth
                  error={fieldState.invalid}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Select Category<span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={categoryValue}
                    onChange={(e) => {
                      handleEditCategory(e);
                    }}
                    label="Please Select Category"
                  >
                    <MenuItem value="PUBLIC">Public</MenuItem>
                    <MenuItem value="CONFIDENTIAL">
                      <ListItemText primary="Confidential" />
                      <ListItemSecondaryAction>
                        <Tooltip title="confidential Documents Did not shows to Home Owners" arrow>
                          <IconButton edge="end" size="small">
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </MenuItem>
                  </Select>
                  <FormHelperText style={{ color: "#D32F2F" }}>
                    {fieldState.invalid ? fieldState.error?.message : ""}
                  </FormHelperText>
                </FormControl>
              )}
            />

            <br />
            <br />
            {categoryValue === "PUBLIC" || editRadioShow === "PUBLIC" ? (
              <CustomRadioButton
                fieldName="editDocumentType"
                fieldControl={control}
                fieldError={errors}
                labelName="Document Type"
                valueOne={true}
                labelOne="General Document - This will be Publish General Document to Homeowners"
                valueTwo={false}
                labelTwo="Need 51% Approval from Board Member"
                value={radioData}
                onChange={handleEditRadio}
              />
            ) : null}
<section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">

  <CustomDatePicker
      fieldName="publishdate"
      label="Publish Date"
      control={control}
      minDate={minDate}
      maxDate={maxDate}
      error={errors}
      value={publishDateData}
      onChange={handleEditPublish}
      fieldRequiredIcon="*"
      validate={publishDateData} 
      // pastDateErrorMessage="Please select a date in the future"
  />

</section>
            <CustomInputField
              fieldName="description"
              fieldId="description"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldLabel="Description"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
              value={descriptionData}
              onInput={handleEditDescription}
            />

            <br />
            <br />
            {/* <FormLabel>
              Upload Document (PDF Format only){" "}
              <span style={{ color: "red" }}></span>
              <FileUploader
                handleChange={handleEditFileChange}
                name="file"
                types={fileTypes}
                // required
              />
              {fileData ? <p>{fileData}</p> : <p>No file selected</p>}
            </FormLabel> */}
            <FormLabel>
            Upload Document (PDF Format only){" "}<Tooltip
        title={`Please note that uploading a new document will override the existing one`}
      >
        <IconButton>
          <InfoOutlined />
        </IconButton>
      </Tooltip>
                <FileUploader
                  handleChange={handleEditFileChange}
                  name="file"
                  types={fileTypes}
                  // required
                />
                {fileData ? <p>{fileData}</p> : <p>No file selected</p>}
              </FormLabel>
          </Typography>
        </DialogContent>
        <DialogActions>{editActionButton}</DialogActions>
      </form>
    </>
  );

  const sendDocActionButton = (
    <CustomButton
      fieldType="submit"
      buttonName="Resend"
      click={handleSendDocClose}
    >
      {isLoading && <CustomLoading />}
    </CustomButton>
  );
  const sendDocModel = (
    <>
      {isLoading && <CustomLoading />}
      <form
        onSubmit={handleSubmit(onSubmitSendDoc)}
        className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography gutterBottom>
            <Controller
              name="category"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl
                  variant="standard"
                  fullWidth
                  error={fieldState.invalid}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Select Category<span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={categoryValue}
                    onChange={(e) => {
                      handleEditCategory(e);
                    }}
                    label="Please Select Category"
                  >
                    <MenuItem value="PUBLIC">Public</MenuItem>
                    <MenuItem value="CONFIDENTIAL">
                      <ListItemText primary="Confidential" />
                      <ListItemSecondaryAction>
                        <Tooltip title="confidential Documents Did not showes to Home Owners" arrow>
                          <IconButton edge="end" size="small">
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </MenuItem>
                  </Select>
                  <FormHelperText style={{ color: "#D32F2F" }}>
                    {fieldState.invalid ? fieldState.error?.message : ""}
                  </FormHelperText>
                </FormControl>
              )}
            />

            <br />
            <br />
            {categoryValue === "PUBLIC" || editRadioShow === "PUBLIC" ? (
              <CustomRadioButton
                fieldName="editDocumentType"
                fieldControl={control}
                fieldError={errors}
                // requiredErrorMessage={REQUEST_TYPE_REQUIRED_MESSAGE}
                labelName="Document Type"
                valueOne={true}
                labelOne="General Document - This will be Publish General Document to Homeowners"
                valueTwo={false}
                labelTwo="Need 51% Approval from Board Member"
                value={radioData}
                onChange={handleEditRadio}
              />
            ) : null}

<section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
            <CustomPublishDatePicker
    fieldName="publishdate"
    label="Publish Date"
    control={control}
    minDate={minDate}
    maxDate={maxDate}
    error={errors}
    value={publishDateData}
    onChange={handleEditPublish}
    fieldRequiredIcon="*"
    validate={publishDateData} // Pass publishDateData as argument
    // pastDateErrorMessage="Please select a date in the future"
/>
</section>

            <CustomInputField
              fieldName="description"
              fieldId="description"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldLabel="Description"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
              value={descriptionData}
              onInput={handleEditDescription}
            />

            <br />
            <br />
            <FormLabel>
              Upload Document (PDF Format only){" "}<Tooltip
        title={`Please note that uploading a new document will override the existing one`}
      >
        <IconButton>
          <InfoOutlined />
        </IconButton>
      </Tooltip>
              {/* <span style={{ color: "red" }}>*</span> */}
              <FileUploader
                handleChange={handleEditFileChange}
                name="file"
                types={fileTypes}
                // required
              />
              {fileData ? <p>{fileData}</p> : <p>No file selected</p>}
            </FormLabel>
          </Typography>
        </DialogContent>
        <DialogActions>{sendDocActionButton}</DialogActions>
      </form>
    </>
  );

  const okCancelButtonActions = (
    <>
    <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleHideClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Hide"
        click={handleHideClose}
      />
      
    </>
  );

  const hideModel = (
    <form
      onSubmit={handleSubmit(onSubmitHide)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography> Do you want to hide this document ?</Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );

  return (
    <div>
      <CustomHeading title="Documents" />
      <CustomToastContainer />

      <CustomMaterialTable
        columns={documentTable}
        data={documentDetails}
        rowActions={rowActions}
        actionButtons={tableActionButtons}
        isLoading={isLoading}
        onRowClick = {handleViewClick}
      />
      <CustomModel
        title="Add Document"
        submit={onSubmit}
        content={addModel}
        action={addActionButton}
        openStatus={open}
        closeStatus={handleAddClose}
        iconCloseStatus={handleIconClose}
        reset={reset}
      />

      <CustomModel
        title="Edit Document"
        submit={onSubmitEdit}
        content={editModel}
        action={editActionButton}
        openStatus={openEdit}
        closeStatus={handleEditClose}
        iconCloseStatus={handleEditIconClose}
        reset={reset}
      />

      <CustomModel
        title="Send Document"
        submit={onSubmitSendDoc}
        content={sendDocModel}
        action={sendDocActionButton}
        openStatus={openSendDoc}
        closeStatus={handleSendDocClose}
        iconCloseStatus={handleSendDocIconClose}
        reset={reset}
      />

      <CustomModel
        title=" Hide Document"
        submit={onSubmitHide}
        content={hideModel}
        action={okCancelButtonActions}
        openStatus={hideOpen}
        closeStatus={handleHideClose}
        iconCloseStatus={handleHideClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      />
    </div>
  );
}

export default McBmArcDocumentList;
