import {
  Close,
  ContentCopy,
  Delete,
  DeleteForever,
  FileDownload,
  ListAlt,
  Telegram,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { download, generateCsv, mkConfig } from "export-to-csv";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { setPageIndex } from "../redux/Hybridhoa";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function CustomMaterialWithoutActons(props) {
  const navigate = useNavigate();
  const actions = props.rowActions;
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
// const dispatch = useDispatch();
// const location = useLocation();
// const currentUrl = location.pathname;
// const actions = props.rowActions;
// const pageIndexes = useSelector((state) => state.hybridhoa.pageIndexes);
//   const savedPageIndex = pageIndexes[currentUrl] || 0;
//   const [pagination, setPagination] = useState({
//     pageIndex: savedPageIndex,
//     pageSize: 5,
//   });
//   useEffect(() => {
//     const storedPageIndex = parseInt(localStorage.getItem(currentUrl), 10);
//     if (!isNaN(storedPageIndex)) {
//       setPagination((prev) => ({ ...prev, pageIndex: storedPageIndex }));
//     }
//   }, [currentUrl]);

//   useEffect(() => {
//     localStorage.setItem(currentUrl, pagination.pageIndex);
//     dispatch(setPageIndex({ url: currentUrl, pageIndex: pagination.pageIndex }));
//   }, [pagination.pageIndex, dispatch, currentUrl]);

  return (
    <MaterialReactTable
      columns={props.columns}
      data={props.data}
      enableColumnOrdering
       positionActionsColumn="last"
      enableSorting={false}
      initialState={{
        pagination: pagination,
      }}
      onPaginationChange={setPagination}
      state={{
        // isLoading: props.isLoading,
        pagination,
      }}
      muiTableBodyRowProps={(row) => ({
        onClick: () => props.onRowClick(row),
        sx: {
          cursor: 'pointer', 
        },
      })}
      muiTableHeadCellProps={{
        sx: {
          fontWeight: "semi-bold",
          fontSize: 14,
          fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,
        },
      }}
      muiTableBodyCellProps={{
        sx: {
          fontSize: 14,
          fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,
        },
      }}
      enableColumnPinning
      localization={{rowActions:"more info"}}
      renderRowActionMenuItems={({ row }) => {
        const renderActions = Array.isArray(actions)
          ? actions.map((i) => (
              <MenuItem key={i.label} onClick={() => i.click(row)}>
                {i.icon}
                <Typography sx={{ pl: 1 }}> {i.label}</Typography>
              </MenuItem>
            ))
          : actions(row).map((i) => (
              <MenuItem key={i.label} onClick={i.click}>
                {i.icon}
                <Typography sx={{ pl: 1 }}>{i.label}</Typography>
              </MenuItem>
            ));

            return renderActions.length > 0 ? renderActions : (
              <MenuItem disabled>
                <Typography sx={{ pl: 1 }}>N/A</Typography>
              </MenuItem>
            );
      }}
      renderTopToolbarCustomActions={() => (
        <>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              p: "0.5rem",
              flexWrap: "wrap",
            }}
          >
            {props.actionButtons}
          </Box>
        </>
      )}
    />
  );
};

export default CustomMaterialWithoutActons