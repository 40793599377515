import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { setHomeId } from "../redux/Hybridhoa";
import localStorage from "redux-persist/es/storage";
import { useNavigate } from "react-router-dom";

const HomeDetailsSelect = () => {
  const { homeDetails,userRoles } = useSelector((state) => state.hybridhoa);
  const [selectedHome, setSelectedHome] = useState(null);
  const [containerWidth, setContainerWidth] = useState(200); 
  const dispatch = useDispatch();
  const options = homeDetails.map((home, index) => ({
    value: home.homeId,
    label: home.address,
    key: index
  }));
const navigate=useNavigate()

  const handleHomeChange = (selectedOption) => {
                  if (selectedOption !== null) {
                    console.log(selectedOption,"testee");
                    setSelectedHome(selectedOption);
                    dispatch(setHomeId(selectedOption.value));
                    navigate('/dashboard-ho')
                  //  const localMultipleHomes= localStorage.setItem('MultipleHomeAdress',selectedOption.value)
                    console.log("Selected Home:", selectedOption);
                    console.log("To Check Which Selected",selectedOption.value);
                  } else {
                    setSelectedHome(null);
                  }
                };

  // const calculateContainerWidth = () => {
  //   const longestAddress = homeDetails.reduce((prev, current) => {
  //     return prev.address.length > current.address.length ? prev : current;
  //   });
  //   const textWidth = longestAddress.address.length * 10; 
  //   const minWidth = 200; 
  //   const maxWidth = 500;
  //   return Math.min(Math.max(textWidth, minWidth), maxWidth);
  // };

  // // useEffect(() => {
  // //   setContainerWidth(calculateContainerWidth());
  // // }, [homeDetails]);
  // useEffect(() => {
  //   if (userRoles[0] !== "MC") {
  //     setContainerWidth(calculateContainerWidth(homeDetails));
  //   }
  // }, [homeDetails, userRoles]);
  const calculateContainerWidth = (homeDetails) => {
    if (homeDetails.length === 0) {
      return 200; // Or any default width you prefer
    }
    const longestAddress = homeDetails.reduce((prev, current) => {
      return prev.address.length > current.address.length ? prev : current;
    }, homeDetails[0]);
    const textWidth = longestAddress.address.length * 10; 
    const minWidth = 180; 
    const maxWidth = 500;
    return Math.min(Math.max(textWidth, minWidth), maxWidth);
  };
  
  
    useEffect(() => {
      if (userRoles[0] !== "MC" && homeDetails.length > 0) {
        setContainerWidth(calculateContainerWidth(homeDetails));
      }
    }, [homeDetails, userRoles]);
  
  useEffect(() => {
                  defaultValue();
                }, [homeDetails]); 
               
                const defaultValue = () => {
                  if (options.length > 0) {
                    setSelectedHome(options[0]);
                  } else {
                    setSelectedHome(null); 
                  }
                };

  return (
    <div style={{ width: containerWidth }}>
      <Select
        value={selectedHome}
        onChange={handleHomeChange}
        options={options}
        placeholder="Select a Home"
        isSearchable
        menuPlacement="auto"
        menuPortalTarget={document.body}
        menuPosition="fixed"
        maxMenuHeight={160}
        isClearable
      />
    </div>
  );
};

export default HomeDetailsSelect;
