import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../../components/CustomInputField";
import {
  CATEGORY_REQUIRED_MESSAGE,
  COMMENTS_REQUIRED_MESSAGE,
  DESCRIPTION_REQUIRED_MESSAGE,
  MAX_LENGTH_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
  REVIEW_COMMENT_REQUIRED_MESSAGE,
  STATUS_REQUIRED_MESSAGE,
} from "../../utills/ApplicationConstants";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CustomHeading from "../../components/CustomHeading";
import { FileUploader } from "react-drag-drop-files";
import { useEffect, useMemo, useState } from "react";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import { useNavigate, useParams } from "react-router-dom";
import CustomViewPage from "../../components/CustomViewPage";
import CustomButton from "../../components/CustomButton";
import { BASE_URL, INVOICE } from "../../utills/ApplicationRouting";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import { FileDownloadOutlined } from "@mui/icons-material";
import { request } from "../../services/AxiosConfig";
import CustomToastContainer from "../../components/CustomToastContainer";
import CustomLoading from '../../components/CustomLoading';
import CustomPascalChange from "../../components/CustomPascalChange";
import AttachmentListsView from "../../components/AttachmentListsView";
import CustomViewWithTooltip from "../../components/CustomViewWithTooltip";
import CustomMaterialWithoutActons from "../../components/CustomMaterialWithoutActons";
import CustomReviewTableWithoutActions from "../../components/CustomReviewTableWithoutActions";
import CustomViewWithMore from "../../components/CustomViewWithMore";
import CustomViewHeading from "../../components/CustomViewHeading";


function InvoiceView() {
 
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const fileTypes = ["JPG", "PNG", "GIF"];
  const { token,roles } = useSelector(state => state.hybridhoa);
  const [invoiceData,setInvoiceData] = useState([]);
  const [fileUrl,setFileUrl]=useState([]);
  const [createdDateAndTime,setCreatedDateAndTime]=useState("");
  const [isLoading, setIsLoading] = useState(true);
  const {invoiceId} = useParams();
  const [firstNameData, setFirstNameData] = useState("");
  const [lastNameData, setLastNameData] = useState("");
  const [statusData,setStatusData] = useState("");
  const[reviewComment,setReviewComment]=useState([]);
  const[homeOwnerloginId,setHomeOwnerloginId]=useState("");
  const [createdByDetails,setCreatedByDetails]=useState("");

  const [statuss,setStatuss]=useState("")

  const {
    handleSubmit,
    control,
    formState: { errors },


    setValue,
    clearErrors,
  } = useForm();

  const handleStatusChange = (event) => {
    setStatusData(event.target.value) 
  };
  
  
  const onSubmit = (data) => {

    setIsLoading(true);
    // const postData ={
    //   comments : data.reviewComment,
    //   status: statusData ,
    
    // }

    const storedToken = token;

    console.log(storedToken);
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${storedToken}`,
    };
    const formData = new FormData();
    formData.append('status',statusData);
 
    formData.append('comments',data.reviewComment);
  
    console.log("comments" ,data.reviewComment);
    // return;
    request({url:`${INVOICE}/${invoiceId}`, method:"put",headers, data:formData,  }).then(res=>{
      setIsLoading(false); 
      console.log(res);
  //     if(res.status==200){
        
  //       setIsLoading(false); 
  
  //   }
  //   }).catch(error=>{
  //     console.log(error.response);
  //     setIsLoading(false); 
  //     if (error.response) {
     
  //       console.log("error.response.status" + error.response.status);
       
  
  //     }  
  //   });

  // };
  if (res.status === 200) {
    // If the update is successful, fetch the updated data and update the review comments state
    getUpdatedReviewComments();
  }
})
.catch(error => {
  console.log(error.response);
  if (error.response) {
    console.log("error.response.status" + error.response.status);
  }
});
};

const getUpdatedReviewComments = async () => {
try {
// Fetch the updated review comments data
const storedToken = token;
const response = await axios.get(`${BASE_URL}${INVOICE}/${invoiceId}`, {
  headers: {
    Authorization: `Bearer ${storedToken}`,
  },
});

if (response.status === 200) {
  const data = response.data;
  setReviewComment(data.auditList);
  // setTimeout(() => {
  //   navigate(-1);
  // }, 4000);
  setTimeout(()=>{
    navigate(-1);
  },4000)
   // Update the review comments state with the updated data
} else {
  throw new Error('Failed to fetch data');
}
} catch (error) {
console.error("Error fetching updated data:", error);
}
};

 const handleClick = () => {
    navigate(-1);
  }; 
  const handleFileChange = (file) => {
    setFile(file);
  };


  const getInvoiceDetailsById = async () => {
    try {
          const storedToken = token

      console.log(storedToken);
      
        const response= await axios.get(`${BASE_URL}${INVOICE}/${invoiceId}`,{
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
              console.log(response.data); 

      if (response.status === 200) {
        const data = response.data;
        const createdDetails = data.createdByDetails
        const persistRoot = JSON.parse(localStorage.getItem("persist:root"));
        const userDetails = JSON.parse(persistRoot?.userDetails);
        
        const storedHomeOwnerLoginId = userDetails?.homeOwnerLoginId;
        setHomeOwnerloginId(storedHomeOwnerLoginId)
        console.log((storedHomeOwnerLoginId));
        console.log(data.createdBy);
        if (createdDetails) {
          setFirstNameData(createdDetails.firstName);
          setLastNameData(createdDetails.lastName);
        }
        
        setStatusData(data.status);
        console.log(data.status);
        setFileUrl(data.attachments);
        console.log(data.fileUrl);
        const formattedDate = moment(data.createdDateAndTime).format("MM/DD/YYYY hh:mm A");
        setCreatedDateAndTime(formattedDate);
        setInvoiceData(data);
        setIsLoading(false);
        setStatuss(data.status)
        setCreatedByDetails(data.createdByDetails)
        setReviewComment(data.auditList);


      } else {
        setIsLoading(false);
        throw new Error('Failed to fetch data');
      }
     
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };



useEffect(()=>{
  getInvoiceDetailsById();
},[invoiceId])
const fileList = fileUrl.map(file => ({
  docname: file.file.substring(12), // Assuming file name is the document name
  fileUrl: file.file, // Assuming file location is the file URL
  documentnamed: file.file, // Assuming file name is the document name
  uploadedTimes: moment(file.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"), // Assuming uploadedTimes is available
  }));
// const fullName = firstNameData + "" + lastNameData;

//   const data = {
//     pageTitle: " Invoice",
//     buttons: [
//       {
//         buttonTitle: "Back",
//         onClick: handleClick,
//       },
//     ],
//     content: [

//       {
//         topic: "By",
//         description: `${invoiceData.createdByDetails?.firstName} ${invoiceData.createdByDetails?.lastName}`,
//       },

//       {
//         topic: "Updated Date Time",
//         description: moment(invoiceData.auditDateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
//       },
//       {
//         topic: "Invoice Type", 
//         description: invoiceData.category,

//       },
//       // {
//       //   topic: "Uploaded By",
//       //   description: `${invoiceData.createdByDetails?.firstName} ${invoiceData.createdByDetails?.lastName}`,

//       // },

//       {
//         topic: "Status",
//         // description: invoiceData.status,
//         description: CustomPascalChange(statuss),
      
//       },
//       {
//         topic: "Description",
//         description: invoiceData.description,
//       },
     
//       {
//         topic: "Attachments",
//         component: "AttachmentList", 
//         props: {
//           fileUrl: fileUrl,
//           documentName: fileUrl,
//           docname: fileUrl,
//           uploadedTimes: createdDateAndTime,
//         },
//       },
//     ],
//   };


const [showFullDescription, setShowFullDescription] = useState(false);
const handleToggleDescription = () => {
  setShowFullDescription(!showFullDescription);
};
const renderDescription = () => {
  console.log("inside render ");
  if (invoiceData.description && !showFullDescription) {
  console.log("inside render ",invoiceData.description);

    return (
      <>
      
        {invoiceData.description.slice(0, 250)}
        {invoiceData.description.length > 250 && (
          <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Read More</Button>
        )}
      </>
    );
  } else {
  console.log("inside render else ",invoiceData.description);

    return (
      <>
        {invoiceData.description}
        <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Less</Button>
      </>
    );
  }
};
const data = [
  {
    groupName: 'Invoice Details',
    items: [
     
             { label: 'Invoice Type', data: `${invoiceData?.category || ""}` ,text: 'Type of the invoice (e.g., Purchases, Sales, Suppliers,Expenses)'},
            //  { label: 'Description', data:invoiceData.description ,text: 'Tooltip text 1'},
            { label: 'Description', data: renderDescription() ,text: 'Detailed description of the invoice'},

               
                   { label: 'Status', data: `${CustomPascalChange(statuss)}` ,text: 'Current status of the invoice (e.g., Pending, Approved, Rejected)'},
                   { label:'Attachment', data: (
                    <AttachmentListsView fileList={fileList} />

                  ), text: 'Attached documents related to the invoice'},

                  // { label: 'Updated Date and Time', data: `${moment(invoiceData.auditDateAndTime).format(
                  //   "MM/DD/YYYY hh:mm:ss A")}` ,text: 'The date and time when the invoice was last updated'},

                  
                    { label: 'Created Date And Time', data: `${moment(invoiceData.createdDateAndTime).format(
                      "MM/DD/YYYY hh:mm:ss A")}` ,text: 'The date and time when the invoice was last created'},

    ],
  },
  {
    groupName: ' By Details',
    items: [
      { label: 'Firstname', data: `${createdByDetails?.firstName ?? ""}`, text: 'First name of the person who created the invoice' },
      { label: 'Lastname', data: `${createdByDetails?.lastName ?? ""}`, text: 'Last name of the person who created the invoice' },
      { label: 'Email', data: `${createdByDetails?.email ?? ""}`, text: 'Email address of the person who created the invoice' },
      ...(roles === 'ROLE_BM' || roles === 'ROLE_ARC'
        ? [{ label: 'Address', data: `${invoiceData?.createdByAddress?.address || ""}`, text: 'Address of the person who created the invoice' }]
        : []),
      ...(roles === 'ROLE_MC' || roles === 'ROLE_GU'
        ? [{ label: 'Address', data: `${invoiceData?.createdByDetails?.addressForCommunication || ""}`, text: 'Communication address of the person who created the invoice' }]
        : []),
      { label: 'Contact', data: `${createdByDetails?.contact ?? ""}`, text: 'Contact number of the person who created the invoice' },
    ],
  },
];

  const isApproved = invoiceData.status === "APPROVED";
  const viewData = [
    {
      reviewBy: "home test",
      status: "Pending",
      reviewDateTime: "10/06/2023",
      reviewComment: "testing",
    },
    {
        reviewBy: "home test",
        status: "Pending",
        reviewDateTime: "10/06/2023",
        reviewComment: "testing",
    },
    {
        reviewBy: "home test",
        status: "Pending",
        reviewDateTime: "10/06/2023",
        reviewComment: "testing",
    },
    {
        reviewBy: "home test",
        status: "Pending",
        reviewDateTime: "10/06/2023",
        reviewComment: "testing",
    },
    {
        reviewBy: "home test",
        status: "Pending",
        reviewDateTime: "10/06/2023",
        reviewComment: "testing",
    },
  ];

  const viewDataTable = useMemo((rowData) => [
    {
      accessorKey: "name",
      header: "Review By",
      
      
    },
    {
      accessorKey: "status",
      header: "Status",
     
    },
    {
      accessorKey: "createdDateAndTime",
      header: "Review Date Time",
      // accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),
      accessorFn: (row) =>
      moment(row.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
    },
    
    // {
    //   accessorKey: "comments",
    //   header: "Review Comment",
      
    // },
  ]);

  return (
    <div>

      {/* <CustomViewPage data={data} /> */}
      <CustomViewHeading onClick={handleClick}
       title={"Invoice "} data={data} fileList={fileList}
       description={(invoiceData?.description || "")} >
       
       
         {/* <CustomViewHeading onClick={handleClick} title={"View ByLaw"} data={data}
      fileList={fileList}
      >  */}
      <CustomViewWithMore data={data}  />
      <CustomToastContainer/>
      <br />
      <br />

      {invoiceData.status !== "APPROVED" &&
    invoiceData.status !== "REJECTED" &&
    invoiceData.createdBy !== homeOwnerloginId &&
    invoiceData.role !== "ROLE_ARC" &&
    invoiceData.role !== "ROLE_MC" &&
    invoiceData.role !== "ROLE_BM" &&
    invoiceData.role !== "ROLE_GU" && (
            <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
            <section class="grid md:grid-cols-2 xl:grid-cols-2 gap-5">


            <Controller
                name="status"
                control={control}
                // rules={{ required: STATUS_REQUIRED_MESSAGE }}
                render={({ field, fieldState }) => (
                  <FormControl
                    variant="standard"
                    fullWidth
                    error={fieldState.invalid}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                        Select Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={statusData}
                      onChange={handleStatusChange}
                      label="Please  Select Status"
                    >
                      <MenuItem value="PENDING" >
                      Pending
                      </MenuItem>
                      <MenuItem value="APPROVED">Approved</MenuItem>
                      <MenuItem value="REJECTED" >Rejected</MenuItem>
                                        </Select>
                    <FormHelperText style={{ color: "#D32F2F" }}>
                      {fieldState.invalid ? fieldState.error?.message : ""}
                    </FormHelperText>
                  </FormControl>
                )}
              />


              <CustomInputField
                fieldName="reviewComment"
                requiredErrorMessage={REVIEW_COMMENT_REQUIRED_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={1000}
                maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
                fieldId="reviewComment"
                fieldType="text"
                fieldLabel="Review Comment"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                multiline={true}
                numberOfRows={1}
              />

            

             
            </section>
            <div class="text-center mt-10">

    <CustomButton fieldType="submit" buttonName="Update"     />

  {isLoading && <CustomLoading />}
</div>
          </CardContent>
        </form>
      </Card>
         )}

      <CustomReviewTableWithoutActions columns={viewDataTable} data={reviewComment} />
      
      </CustomViewHeading>
    </div>
  );
}

export default InvoiceView;
