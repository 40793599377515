
import axios from "axios";
import { BASE_URL } from "../utills/ApplicationRouting";
import { toast } from "react-toastify";

const client =axios.create({baseURL:BASE_URL})


export  const request =({...options})=>{
    const token=localStorage.getItem("token")
    client.defaults.headers.common.Authorization=`Bearer ${token}`
    const onSuccess = (response) => {
        if (response.status === 200) {
            console.log(response.data);
          toast.success(response.data);
        }
        return response;
      };
    const onError =(error) => {
        if (error.response && error.response.status !== 200) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message);
          }
          return error;
    }
    return client(options).then(onSuccess).catch(onError)
}