import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CustomViewPage from '../../components/CustomViewPage'
import { useSelector } from 'react-redux';
import moment from 'moment';
import CustomLoading from '../../components/CustomLoading';
import { BASE_URL, NOTIFICATION } from '../../utills/ApplicationRouting';
import axios from 'axios';

function ArchivedNotificationView() {
  const { token, roles,userLoginDetails } = useSelector((state) => state.hybridhoa);
  const [notificationData, setNotificationData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { notificationId } = useParams();
    const [firstNameData, setFirstNameData] = useState("");
    const [lastNameData, setLastNameData] = useState("");

    const handleClick =() =>{
        navigate(-1)
    }

    const getNotificationDetailsById = async () => {
      try {
        setIsLoading(true);
        const storedToken = token;
  
        console.log(storedToken);
  
        const response = await axios.get(
          `${BASE_URL}${NOTIFICATION}/${notificationId}`,
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
        console.log(response.data);
  
        if (response.status === 200) {
          const data = response.data;
          const createdDetails = data.createdByDetails;
  
          setFirstNameData(createdDetails.firstName);
          setLastNameData(createdDetails.lastName);
          setNotificationData(data);
     
          setIsLoading(false);
        } else {
          setIsLoading(false);
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        // Handle error
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    };
  
    useEffect(() => {
      getNotificationDetailsById();
    }, [notificationId]);
    const fullName = firstNameData + " " + lastNameData;
  
  const data = {
    pageTitle:"Archived Notification View",
    buttons :[
      {
        buttonTitle:"Back",
        onClick : handleClick
      },  
     
    ],
    content: [
      {
        topic: "Subject",
        description: notificationData.subject,
      },
      {
        topic: "Created By",
        description: fullName,
      },
      {
        topic: "Created Date",
        description: moment(notificationData.createdDateAndTime).format(
          "MM/DD/YYYY"
        ),
      },
      {
        topic: "Description",
        description: notificationData.description,
      },
      {
        topic: "Status",
        description: notificationData.status,
      },
    ],
  }
    return (
      <div>
        {isLoading && <CustomLoading/>}
        <CustomViewPage data = {data} />
           </div>
    )
  }

export default ArchivedNotificationView