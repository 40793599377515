import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import {
  Autocomplete,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import { useSelector } from "react-redux";
import axios from "axios";
import { Tooltip, IconButton } from "@mui/material";
import dayjs from "dayjs";
import { ADD_MAINTENANCE, ADD_MAINTENANCE_MC, BASE_URL, BYLAW, CATEGORY, HOMES } from "../../../utills/ApplicationRouting";
import { BYLAW_REQUIRED_MESSAGE, CATEGORY_INVALID_MESSAGE, CATEGORY_REQUIRED_MESSAGE, HOME_ADDRESS_INVALID_MESSAGE, HOME_ADDRESS_REQUIRED_MESSAGE, LEASE_END_DATE_REQUIRED_GREATER_MESSAGE, MAX_LENGTH_FIVE_THOUSAND, MAX_LENGTH_THOUSAND, MIN_LENGTH_VALIDATE_MESSAGE, WHAT_NEED_ATTENTION_REQUIRED_MESSAGE } from "../../../utills/ApplicationConstants";
import CustomHeading from "../../../components/CustomHeading";
import CustomButton from "../../../components/CustomButton";
import CustomAutoComplete from "../../../components/CustomAutoComplete";
import CustomInputField from "../../../components/CustomInputField";
import CustomFile from "../../../components/CustomFile";
import CustomDatePicker from "../../../components/CustomDatePicker";
import CustomRadioButton from "../../../components/CustomRadioButton";
import CustomToastContainer from "../../../components/CustomToastContainer";
import CustomLoading from "../../../components/CustomLoading";
import { request } from "../../../services/AxiosConfig";
import CustomTooltipField from "../../../components/CustomTooltipField";
import { InfoOutlined } from "@mui/icons-material";

function McMaintenanceRequestAdd() {
  const [file, setFile] = useState([]);
  const [homes,setHomes]= useState([]);
  const [isLoading,setIsLoading]= useState(true);
  const [homeAddresses, setHomeAddresses] = useState([]);
  const [homeDetails, setHomeDetails] = useState("");
  const [categoryDetails,setCategoryDetails] =useState([]);
  const [byLawDetails,setByLawDetails] = useState([]);
  const [byLawDescription,setByLawDescription] = useState('');
  const [selectedHomeId, setSelectedHomeId] = useState(null);
  const [selectedBylawId,setSelectedBylawId]= useState(null);
  const [selectedCategoryId,setSelectedCategoryId]= useState(null);
  const { token, homeId } = useSelector(state => state.hybridhoa);
  const [isUrgent,setIsUrgent]=useState(false)
  const [isSafety,setIsSafety]=useState(false)
  const [homeOwnerDetails,setHomeOwnerDetails]= useState('');
  const [bylawsUpdated, setBylawsUpdated] = useState(true);
  const minDate = dayjs().startOf("day");
  const maxDate = dayjs().add(1, "year").endOf("day");
  const [toDateDisabled, setToDateDisabled] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fileError, setFileError] = useState("");
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,watch,setError
  } = useForm();

const fileTypes = ["JPG", "PNG", "JPEG","PDF"];
const navigate = useNavigate();

const handleViewClick = () => {
  navigate(-1);
};

const handleFileChange = (file) => {
  setFile(file);
};


const getHomes = async () => {
  try {
    const storedToken = token;
  

    const response = await axios.get(`${BASE_URL}${HOMES}`, {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    });
    console.log(response.data);

    if (response.status === 200) {
      const data = response.data;
      setHomeOwnerDetails(data.homeOwnerDetails)
      setHomeAddresses(data); 
      setIsLoading(false);
    } else {
      setIsLoading(false);
      throw new Error('Failed to fetch data');
    }

  } catch (error) {
    // Handle error
    setIsLoading(false);
    console.error("Error fetching data:", error);
  }
};

useEffect(() => {
  getHomes();
}, []);

useEffect(() => {
  const selectedHome = watch("selectedOption");

  if (!selectedHome || selectedHome === "") {
    setHomeDetails("");
    // setIsBylawFieldEmpty(true);
  } else {
    // Find the matching byLaw from byLawDetails
    const matchingHome = homeAddresses.find(
      (home) => home.homeId === selectedHome
    );
    console.log(matchingHome);

    if (matchingHome) {
      const {name} = matchingHome;
      const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";
      setHomeDetails(selectedHomeDescription);

      // setIsBylawFieldEmpty(false);
    } else {
      // Handle the case where no matching byLaw is found
      setHomeDetails("");
      // setIsBylawFieldEmpty(true);
    }
  }
}, [watch("selectedOption"), homeDetails]);

const handleHomeChange = (event, newValue) => {
  if (newValue && newValue.value !== undefined && newValue.value !== null) {
      const selectedHomeId = newValue.value;
      const selectedHome = homeAddresses.find(
          (home) => home.homeId === selectedHomeId
      );
      const homeOwner = selectedHome;

      if (homeOwner) {
          const { name } = homeOwner;
          const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";
          console.log("Selected Home Description:", selectedHomeDescription);

          // Set the selected home details to the state if selectedHomeDescription is not null
        
              setHomeDetails(selectedHomeDescription);

          // Use setValue to set the selected homeId in the react-hook-form
          setValue("selectedOption", selectedHomeId);

          // You can also do other operations if needed
          console.log("Selected Home:", selectedHomeId);
          console.log("Home Details:", homeAddresses);
      } else {
          setHomeDetails("");
          // setValue("selectedOption", null);
          setValue("selectedOption", selectedHomeId);
      }
  } else {
      // Handle the case when the selected option is removed (e.g., by pressing backspace)
      setHomeDetails("");

      // Use setValue to clear the selected homeId in the react-hook-form
      setValue("selectedOption", null);
  }
};


const getCategory = async () => {
  try {
    const storedToken = token;
  

    const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    });
    console.log(response.data);

    if (response.status === 200) {
      const data = response.data;
      setCategoryDetails(data); // Set the fetched data to the state
      setIsLoading(false);
    } else {
      setIsLoading(false);
      throw new Error('Failed to fetch data');
    }

  } catch (error) {
    // Handle error
    setIsLoading(false);
    console.error("Error fetching data:", error);
  }
};

useEffect(() => {
  getCategory();
}, []);




const handleCategoryChange = (event, newValue) => {
  if (newValue && newValue.value !== undefined && newValue.value !== null) {
    const selectedCategoryId = newValue.value;
    const selectedHomeDescription = byLawDetails.find(
      (home) => home.homeId === selectedHomeId
    )?.description;

    // Set the selected homeId to the state
    setValue("category", selectedCategoryId);

    // You can also do other operations if needed

    console.log("selectedCategoryId:", selectedCategoryId);
    console.log("Home Details:", homeAddresses);
  } else {
    setValue("category",null);
  }
};

const handleFromDateChange = (selectedFromDate) => {
  setStartDate(selectedFromDate);
  if(selectedFromDate){
  setToDateDisabled(false)
  }
};


const handleendDateChange = (selectedEndDate) => {
  setEndDate(selectedEndDate);
};

useEffect(() => {
  // Check if start date is greater than end date
  if (startDate && endDate && startDate.isAfter(endDate)) {
    console.log("working")
    setEndDate(null); // Clear end date if start date is greater
  }
}, [startDate, endDate]);

const getBylaw = async () => {
  try {
    const storedToken = token;
  

    const response = await axios.get(`${BASE_URL}${BYLAW}`, {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    });
    console.log(response.data);

    if (response.status === 200) {
      const data = response.data;
      setByLawDetails(data); 
      setBylawsUpdated(data.length > 0);
      // setByLawDescription(data.description);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      throw new Error('Failed to fetch data');
    }

  } catch (error) {
    // Handle error
    setIsLoading(false);
    console.error("Error fetching data:", error);
  }
};

useEffect(() => {
  getBylaw();
}, []);

const handleByLawChange = (event, newValue) => {
  console.log("working");

  if (newValue && newValue.value !== undefined && newValue.value !== null) {
    const selectedByLawId = newValue.value;
    setValue("selectedBylaw",selectedByLawId)
    const selectedByLawDescription = byLawDetails.find(
      (byLaw) => byLaw.byLawId === selectedByLawId
    )?.description;

    console.log("Selected ByLaw:", newValue);
    console.log("ByLaw Details:", byLawDetails);
    console.log("Selected ByLaw Description:", selectedByLawDescription);

    setByLawDescription(selectedByLawDescription || "");
  } else {
    setByLawDescription("");
    setValue("selectedBylaw",null)
  }
};

useEffect(()=>{
  console.log("byLawDescription", byLawDescription)
},[byLawDescription])

const renderedAddresses = homeAddresses
    .map((home, index) => ({
      address: home.address,
      value: home.homeId,
      key: index,
    }));

const renderedCategory = categoryDetails
.filter(category => category.type === 'MR') // Filter categories with type 'CR'
.map((category, index) => ({
  category: category.category,
  value: category.categoryId,
  key: index,
}));

  const renderedBylaw = byLawDetails.map((ByLaw, index) => ({
    topic: ByLaw.topic,
    value: ByLaw.byLawId,
    key: index,
  }));



useEffect(() => {
  const selectedBylaw = watch('selectedBylaw');


    if (!selectedBylaw || selectedBylaw === "") {
      setByLawDescription('');
      // setIsBylawFieldEmpty(true);
    } else {
      // Find the matching byLaw from byLawDetails
      const matchingByLaw = byLawDetails.find((byLaw) => byLaw.byLawId === selectedBylaw);

      if (matchingByLaw) {
        setByLawDescription(matchingByLaw.description);
        // setIsBylawFieldEmpty(false);
      } else {
        // Handle the case where no matching byLaw is found
        setByLawDescription('');
        // setIsBylawFieldEmpty(true);
      }
    }
  
}, [watch('selectedBylaw'), byLawDetails]);

const onSubmit = (data) => {

  if (startDate && endDate) { // Checks if both dates are not null or empty
    if (endDate < startDate) {
      setError("endDate", {
        type: "manual",
        message: LEASE_END_DATE_REQUIRED_GREATER_MESSAGE,
      });
      return;
    }
  }

  const totalFileSize = file.reduce((acc, file) => acc + file.size, 0);
  const maxSizeInMB = 10;
  const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

  if (totalFileSize > maxSizeInBytes) {
    // setFileError(`Total file size should not exceed ${maxSizeInMB}MB.`);
    return;
  }

  // Check file types
  const allowedFileTypes = ["image/jpg", "image/png", "image/jpeg", "application/pdf"];
  const invalidFiles = file.filter(file => !allowedFileTypes.includes(file.type));

  if (invalidFiles.length > 0) {
    // setFileError("Some files have invalid file types. Allowed types are JPG, PNG, JPEG, PDF.");
    return;
  }


  const formData = new FormData();
  formData.append('topic',data.whatneedsattention);
  formData.append("homeId", data.selectedOption);
  formData.append('category',data.category);
  // formData.append('description',data.moreInformation);
  file.forEach((file) => {
    formData.append('files', file);
    });
  // formData.append('bylawId',data.selectedBylaw);
  formData.append('isUrgent',isUrgent ? 'true' : 'false');
  formData.append('isSafty',isSafety ? 'true' : 'false');
  formData.append('role','ROLE_MC');
  formData.append('type', data.selectyourRequesttype);
  if (startDate && dayjs(startDate).isValid()) {
    formData.append('startDate', dayjs(startDate).format("YYYY-MM-DD"));
  } else {
    formData.append('startDate', ''); 
  }
  
  // Check if endDate is valid
  if (endDate && dayjs(endDate).isValid()) {
    formData.append('endDate', dayjs(endDate).format("YYYY-MM-DD"));
  } else {
    formData.append('endDate', ''); 
  }
  if (data.moreInformation !== undefined && data.moreInformation.trim() !== '') {
    formData.append('description', data.moreInformation);
  } else {
    formData.append('description', ''); // Append empty string if description is empty or undefined
  }
  if (data.selectedBylaw !== undefined && data.selectedBylaw.trim() !== '') {
    formData.append('bylawId', data.selectedBylaw);
  } else {
    formData.append('bylawId', ''); // Append empty string if description is empty or undefined
  }
  console.log('homeId:', data.whatneedsattention);
  console.log('category:', selectedCategoryId);
  console.log('description:', data.violationDescription);
  console.log('file:', file); 
  console.log('bylawId:', selectedBylawId);
  console.log('role:', 'ROLE_HO');

  console.log('formData:', formData);


const headers = {
'Content-Type': 'multipart/form-data'
};
// return;

request({url:ADD_MAINTENANCE, method:"post", data:formData,  headers}).then(res=>{
setIsLoading(true); 
console.log(res);
if(res.status==200){
  
  setIsLoading(true); 
  setTimeout(() => {
    navigate(-1);
  }, 1000); // Adjust the delay time as needed (in milliseconds)
  
}
}).catch(error=>{
console.log(error.response);
setIsLoading(false); 
if (error.response) {

  console.log("error.response.status" + error.response.status);
 

}  
});
};

const renderTooltip = (message) => (
  <Tooltip title={<Typography sx={{ fontSize: "14px" }}>{message}</Typography>} placement="right">
  <IconButton>
  <InfoOutlined />
  </IconButton>
  </Tooltip>
  );

    return (
      <div>
        <div class="flex flex-col md:flex-row">
          <CustomHeading title="Add Maintenance" />
  
          <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
            <CustomButton
              fieldType="button"
              buttonName="Back"
              click={handleViewClick}
            />
          </div>
        </div>
        <Card>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-4 md:space-y-6 "
          >
            <CardContent>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
            <CustomAutoComplete
                  fieldName="selectedOption"
                  requiredErrorMessage={HOME_ADDRESS_REQUIRED_MESSAGE}
                  id="selectId"
                  options={renderedAddresses}
                  fieldLabel="Select Home Address"
                  fieldControl={control}
                  error={errors}
                  setValue={setValue}
                  fieldRequiredIcon="*"
                  onChange={handleHomeChange}
                  invalidOption={HOME_ADDRESS_INVALID_MESSAGE}
                  tooltipMessage={"Please select the home address of the homeowner you are creating the maintenance for"}
                />
                 <CustomTooltipField
                  fieldName="homeOwnerDetails"
                  fieldId="homeOwnerDetails"
                  fieldType="text"
                  fieldLabel="HomeOwner Name"
                  fieldControl={control}
                  fieldError={errors}
                  disabled={true}
                  value={homeDetails}
                  tooltipMessage={"This field displays the name of the homeowner based on the selected home address"}
                />
              <CustomTooltipField
                  fieldName="whatneedsattention"
                  requiredErrorMessage={WHAT_NEED_ATTENTION_REQUIRED_MESSAGE}
                  minLength={2}
                  minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                  maxLength={1000}
                  maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
                  fieldId="whatneedsattention"
                  fieldType="text"
                  fieldLabel="What needs attention?"
                  fieldControl={control}
                  fieldError={errors}
                  fieldRequiredIcon="*"
                  multiline={true}
                  numberOfRows={1}
                  tooltipMessage={"Please provide a brief description of what needs attention"}
                />

<CustomTooltipField
                  fieldName="moreInformation"
                  minLength={2}
                  minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                  maxLength={5000}
                  maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
                  fieldId="moreInformation"
                  fieldType="text"
                  fieldLabel="More Information"
                  fieldControl={control}
                  fieldError={errors}
                  multiline={true}
                  numberOfRows={1}
                  tooltipMessage={"You can provide additional information or details here if needed"}
                />
                <div className="flex items-center md:w-1/2 ">
  <div className="w-full ">
 <FormControlLabel control={<Switch checked={isUrgent}  onChange={(event) => setIsUrgent(event.target.checked)} />} label="Is this issue urgent?: " > </FormControlLabel></div>         <div>{renderTooltip("Check this if the issue requires immediate attention.")}</div></div>
 <div className="flex items-center md:w-1/2 ">
  <div className="w-full ">
 <FormControlLabel control={<Switch checked={isSafety}  onChange={(event) => setIsSafety(event.target.checked)} />} label="Is this issue actively causing property damage or a threat to personal safety?: " > </FormControlLabel>      </div>         <div>{renderTooltip("Is this issue actively causing property damage or a threat to personal safety?")}</div></div>        
 <CustomAutoComplete 
              fieldName="category"
              requiredErrorMessage = {CATEGORY_REQUIRED_MESSAGE}
              id="selectId"
              options={renderedCategory}
              fieldLabel="Select Category/Subject"
              fieldControl={control}
              error={errors}
              setValue={setValue}
              fieldRequiredIcon="*"         
             onChange={handleCategoryChange}
             invalidOption={CATEGORY_INVALID_MESSAGE}
             tooltipMessage={"Please select the category or subject that best fits your request"}
              />
  {/* <CustomAutoComplete 
              fieldName="selectedBylaw"
              // requiredErrorMessage = {BYLAW_REQUIRED_MESSAGE}
             isRequired={false}
              id="selectId"
              options={renderedBylaw}
              fieldLabel="Select Bylaw"
              fieldControl={control}
              error={errors}
              setValue={setValue}
              onChange={handleByLawChange}
              // fieldRequiredIcon="*" 
              invalidOption={BYLAW_REQUIRED_MESSAGE}
              /> */}
              <div className="h-1/2">
{!bylawsUpdated ? (<Tooltip title="The bylaw in your association is not updated, so please contact the group admin." placement="bottom">
      <span>
        <CustomAutoComplete
          fieldName="selectedBylaw"
          id="selectId"
          options={renderedBylaw}
          fieldLabel="Select Bylaw"
          fieldControl={control}
          setValue={setValue}
          onChange={handleByLawChange}
          // value={selectedBylawValue}
          isRequired={false}
          invalidOption={BYLAW_REQUIRED_MESSAGE}
          bylawNull="no bylaw"
          disabled={!bylawsUpdated}
        />
      </span>
    </Tooltip>) : <CustomAutoComplete
          fieldName="selectedBylaw"
          id="selectId"
          options={renderedBylaw}
          fieldLabel="Select Bylaw"
          fieldControl={control}
          setValue={setValue}
          onChange={handleByLawChange}
          // value={selectedBylawValue}
          isRequired={false}
          invalidOption={BYLAW_REQUIRED_MESSAGE}
          bylawNull="no bylaw"
          tooltipMessage={"Please select the relevant bylaw if applicable"}
        />
      }
    </div>
  
  <CustomInputField
                fieldName="bylawDescription"
                fieldId="bylawDescription"
                fieldType="text"
                fieldLabel="Bylaw Description"
                fieldControl={control}
                fieldError={errors}
                disabled={true}
                multiline={true}
                numberOfRows={2}
                value={byLawDescription}
                tooltipMessage={"This field displays the description of the selected bylaw."}
              />


  
{/* <FormLabel>
              Attach Documents 
              <FileUploader 
              handleChange={handleFileChange}
              name="file"
              types={fileTypes}
            
            />
            {file ? <p>Selected File: {file.name}</p> : <p>No file selected</p>}
            </FormLabel> */}
           
<CustomFile file={file} handleFileChange={handleFileChange} token={token} tooltipMessage={"Upload supporting documents such as lease agreement, identity proof, etc."} />
            <CustomRadioButton
                fieldName="selectyourRequesttype"
                fieldControl={control}
                fieldError={errors}
                labelName="Select your Request type"
                requiredErrorMessage=" "
                valueOne="Request for Home Maintenance "
                labelOne="Request for Home Maintenance"
                valueTwo="Request for Common area Maintenance"
                labelTwo="Request for Common area Maintenance"
                tooltipMessage={"Please select the type of request you are making."}
              />
              <CustomDatePicker
                fieldName="startDate"
                label="Start Date"
                control={control}
                // requiredErrorMessage={LEASE_START_DATE_REQUIRED_MESSAGE}
                minDate={minDate}
                maxDate={maxDate}
                value={startDate}
                onChange={(date) => handleFromDateChange(date)}
                // fieldRequiredIcon="*"
                tooltipMessage={"Please select the start date for your request."}
              />
  
  <CustomDatePicker
  fieldName="endDate"
  label="End Date"
  control={control}
  minDate={startDate ? dayjs(startDate).add(1, 'day') : null}
  maxDate={maxDate}
  disabled={toDateDisabled}
  onChange={handleendDateChange}
  value={endDate}
  tooltipMessage={"Please select the end date for your request, if applicable."}
/>
              </section>
             
             
  
              <div class="text-center mt-10">
                <CustomButton fieldType="submit" buttonName="Add" />
                {isLoading && <CustomLoading />}
                <CustomToastContainer />
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    );
  }
  

export default McMaintenanceRequestAdd