import CustomViewPage from "../../../components/CustomViewPage";
import CustomButton from "../../../components/CustomButton";
import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../../../components/CustomInputField";
import { useEffect} from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

import CustomPascalChange from "../../../components/CustomPascalChange";


import {
  COMMENTS_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_THOUSAND,
  MAX_LENGTH_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
  REVIEW_COMMENT_REQUIRED_MESSAGE,
  STATUS_REQUIRED_MESSAGE,
} from "../../../utills/ApplicationConstants";
import {
   Card,
  CardContent,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  DialogContent,
  Typography ,
  DialogActions, 
  Button,
} from "@mui/material";
import CustomHeading from "../../../components/CustomHeading";
import { FileUploader } from "react-drag-drop-files";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import CustomModel from "../../../components/CustomModel";

import CustomMaterialTable from "../../../components/CustomMaterialTable";
import { ADD_MAINTENANCE, BASE_URL, MAINTENANCE_REVIEW_COMMANENTS ,  GENERAL_COMMENTS,
  GET_ALL_GENERAL_COMMENTS,} from "../../../utills/ApplicationRouting";
import moment from "moment";
import { FileDownloadOutlined,  DeleteOutline,
  EditNoteOutlined, } from "@mui/icons-material";
import { request } from "../../../services/AxiosConfig";
import CustomToastContainer from "../../../components/CustomToastContainer";
import CustomLoading from "../../../components/CustomLoading";
import Tooltip from "@mui/material/Tooltip";
import CustomFile from "../../../components/CustomFile";
import CustomViewWithTooltip from "../../../components/CustomViewWithTooltip";
import AttachmentList from "../../../components/AttachmentList";
import CustomFileEdit from "../../../components/CustomFileEdit";
import AttachmentListsView from "../../../components/AttachmentListsView";
import CustomViewHeading from "../../../components/CustomViewHeading";


function GuMaintenanceView() {
    const [selectedStatus, setSelectedStatus] = useState("");
    const [status,setStatus]=useState("");
    const navigate = useNavigate();
    const {maintenanceId} = useParams();
    const [fileUrl, setFileUrl] = useState("");
    const [createdDateAndTime, setCreatedDateAndTime] = useState("");
  const [reviewComment,setReviewComment]=useState([])
  const [generalCommentDetails, setGeneralCommentDetails] = useState("");
  const [statuss,setStatuss]=useState("")
  const [fileError, setFileError] = useState("");
 const [createdByDetails,setCreatedByDetails]=useState("");
 const [createdToDetails,setCreatedToDetails]=useState("");
 const [fileData,setFileData] = useState([]);
 const [file, setFile] = useState([]);
 const [showFullDescription, setShowFullDescription] = useState(false);
 const [showTopic,setShowTopic]=useState(false)
 const [fileImage,setFileImage]=useState([])

 
  const [editModes, setEditModes] = useState(
    Array.isArray(generalCommentDetails)
      ? Array(generalCommentDetails.length).fill(false)
      : []
  );
    const [gumaintenanceRequestData,setGuMaitenanceRequestData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { token ,userDetails,userLoginDetails} = useSelector(state => state.hybridhoa);
  const [commentsId, setCommentsId] = useState("");

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [generalCommentsData, setGeneralCommentsData] = useState(""); 
  const [generalCommentsDataAdd, setGeneralCommentsDataAdd] = useState("");
  const HomeLoginId= userLoginDetails.homeOwnerLoginId;

    

    // const [file, setFile] = useState(null);

    const fileTypes = ["JPG", "PNG", "JPEG","PDF"];
    const [errorMessage, setErrorMessage] = useState('');
    const maxSize=1;
    const {
      handleSubmit,
      control,
      formState: { errors },
      setValue,
      reset ,
    } = useForm();
    const {
      handleSubmit: handleSubmitGeneral,
      control: controlGeneral,
      formState: { errors: errorsGeneral },
      setValue: setValueGeneral,
      setError: setErrorGeneral,
      reset: resetGeneral,
      watch: watchGeneral,
      clearErrors:clearErrorsGeneral
    } = useForm();
    console.log("errors",errors);
    // const onSubmit = (data) => {};
    const handleDeleteClose = () => {
      setDeleteOpen(false);
    };
  
    const onSubmit = (data) => {
      const totalFileSize = file.reduce((acc, file) => acc + file.size, 0);
      const maxSizeInMB = 10;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    
      if (totalFileSize > maxSizeInBytes) {
        // setFileError(`Total file size should not exceed ${maxSizeInMB}MB.`);
        return;
      }
    
      // Check file types
      const allowedFileTypes = ["image/jpg", "image/png", "image/jpeg", "application/pdf"];
      const invalidFiles = file.filter(file => !allowedFileTypes.includes(file.type));
    
      if (invalidFiles.length > 0) {
        // setFileError("Some files have invalid file types. Allowed types are JPG, PNG, JPEG, PDF.");
        return;
      }
      const comments = data.reviewComment || '';

      // Constructing JSON object
      const formData = new FormData();
      formData.append('status',data.status);
    
      formData.append('comments',comments);
      file.forEach((file) => {
        formData.append('files', file);
      });
      formData.append('role','ROLE_GU');
      // Debugging console.log statements
      console.log('formData:', formData);
    
      setIsLoading(true)
    
      // Sending POST request using Axios
      request({ url: `${MAINTENANCE_REVIEW_COMMANENTS}${maintenanceId}`, method: "put", data: formData })
        .then(res => {
          // setIsLoading(false);
          console.log(res);
          if (res.status === 200) {
           
            // If the update is successful, fetch the updated data and update the review comments state
         
            setTimeout(() => {
              navigate(`/gumaintenance`)
              // getUpdatedReviewComments();
              // getGuMaintenanceRequestDetailsById();
              setIsLoading(false)
            }, 2000);
          }
        })
        .catch(error => {
          console.log(error.response);
          if (error.response) {
            console.log("error.response.status" + error.response.status);
          }
        });
    };
    
    const getUpdatedReviewComments = async () => {
      try {
        // Fetch the updated review comments data
        const storedToken = token;
        const response = await axios.get(`${BASE_URL}${ADD_MAINTENANCE}/${maintenanceId}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
    
        if (response.status === 200) {
          const data = response.data;
          setReviewComment(data.auditList); // Update the review comments state with the updated data
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (error) {
        console.error("Error fetching updated data:", error);
      }
    };
    
  
      const handleClick =() =>{
          navigate(-1)
      }
      const handleFileChange = (file) => {
        console.log("first",file)
        setFile(file);
      };

    
      const getGuMaintenanceRequestDetailsById = async () => {
        try {
          setIsLoading(true);
              const storedToken = token
    
          console.log(storedToken);
          
            const response= await axios.get(`${BASE_URL}${ADD_MAINTENANCE}/${maintenanceId}`,{
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });
                  console.log(response.data); 
    
          if (response.status === 200) {
            const data = response.data;
            setGuMaitenanceRequestData(data);
            setSelectedStatus(data.status);
            setStatus(data.status);
            setValue("status",data.status)
            setFileUrl(data.fileUrl);
            setFileData(data.attachments);
            setFileImage(data.attachments)
            setStatuss(data.status)
            console.log(data.fileUrl,"test");
            const formattedDate = moment(data.createdDateAndTime).format("MM/DD/YYYY hh:mm A");
            setCreatedDateAndTime(formattedDate)
            setIsLoading(false);
        setReviewComment(data.auditList);

    
          } else {
            setIsLoading(false)
            throw new Error('Failed to fetch data');
          }
         
        } catch (error) {
          // Handle error
          setIsLoading(false)
          console.error("Error fetching data:", error);
        }
      };
    
      useEffect(() => {
        // Reset fileError when a valid file is selected
        if (file && file.size <= 10 * 1024 * 1024) {
          console.log("File:", file);
          const fileType = file.type && file.type.split("/")[1]; // Check if file.type exists
          console.log("File type:", fileType);
          if (fileType) {
            const fileExtension = fileType.toUpperCase().replace(/[^\w]/g, ""); // Remove special characters
            console.log("File extension:", fileExtension);
            if (fileTypes.includes(fileExtension)) {
              setFileError("");
            }
          }
        }
      }, [file, fileTypes]);
    
    
    useEffect(()=>{
      getGuMaintenanceRequestDetailsById();
    },[])
    const handleToggleDescription = () => {
      setShowFullDescription(!showFullDescription);
    };
    const handleToggleTopic = () => {
      setShowTopic(!showTopic);
    };
    const renderDescription = () => {
      const description = gumaintenanceRequestData.description || ''; // Default to an empty string if null or undefined
    
      if (!showFullDescription) {
        return (
          <>
            {description.slice(0, 250)}
            {description.length > 250 && (
              <Button onClick={handleToggleDescription} style={{ fontSize: "12px" }}>Read More</Button>
            )}
          </>
        );
      } else {
        return (
          <>
            {description}
            <Button onClick={handleToggleDescription} style={{ fontSize: "12px" }}>Less</Button>
          </>
        );
      }
    };

    const renderTopic = () => {
      console.log("inside render ");
      if (gumaintenanceRequestData.topic && !showTopic) {
      console.log("inside render ",gumaintenanceRequestData.topic);
    
        return (
          <>
          
            {gumaintenanceRequestData.topic.slice(0, 250)}
            {gumaintenanceRequestData.topic.length > 250 && (
              <Button onClick={handleToggleTopic} style={{fontSize:"12px"}}>Read More</Button>
            )}
          </>
        );
      } else {
      console.log("inside render else ",gumaintenanceRequestData.topic);
    
        return (
          <>
            {gumaintenanceRequestData.topic}
            <Button onClick={handleToggleTopic} style={{fontSize:"12px"}}>Less</Button>
          </>
        );
      }
    };
useEffect(()=>{
  console.log("reviewcomments",reviewComment);
},[reviewComment])
  
  //     const data = {
  //       pageTitle:" Maintenance Request",
  //       buttons :[
  //         {
  //           buttonTitle:"Back",
  //           onClick : handleClick
  //         },
         
      
  //       ],
  //       content : [
  //         {
  //           topic :'Maintenance Request ID',
  //           description: gumaintenanceRequestData.mrId,

  //         },
  //         {
  //           topic :'What needs attention?',
  //           description: gumaintenanceRequestData.topic,
  //         },
  //         {
  //           topic :'More Information',
  //           description: gumaintenanceRequestData.description,
  //         },
  //         {
  //           topic :'Requested Date Time',
  //           description : moment(gumaintenanceRequestData.createdDateAndTime).format("MM/DD/YYYY hh:mm A")
  //         },
  //         {
  //           topic :'Updated Date Time',
  //           description : moment(gumaintenanceRequestData.auditDateAndTime).format("MM/DD/YYYY hh:mm A")
  //         },
  //         {
  //           topic :'Is this issue urgent?',
  //           description: gumaintenanceRequestData.isUrgent ? "Yes":"No",


  //         },
  //         {
  //           topic :'Is this issue causing a threat to personal safety?',
  //           description: gumaintenanceRequestData.isSafty ? "Yes":"No",

  //         },
          
  //         // {
  //         //   topic :'Created By',
  //         //   description :'Home Req test Three'
  //         // },
  //         {
  //           topic: "Created By",
  //           description: `${gumaintenanceRequestData.createdByDetails?.firstName} ${gumaintenanceRequestData.createdByDetails?.lastName}`,
  //         },
  //         {
  //           topic :'Status',
  //           // description: gumaintenanceRequestData.status,
            
  // description: CustomPascalChange(statuss),
  //         },
          
          
          
          
  //         // {
  //         //   topic :'Occupation',
  //         //   description :'Electrical'
  //         // },
  //         {
  //           topic: 'Job Category',
  //           description: gumaintenanceRequestData.jobDetails?.occupation,
  //         },
  //         {
  //           topic :'Assign-To',
  //           description: `${gumaintenanceRequestData.assignedTo?.firstName} ${gumaintenanceRequestData.assignedTo?.lastName}`,
  //         },
  //         {
  //           topic: "Attachments",
  //           component: "AttachmentList", 
  //           props: {
  //             fileUrl: fileUrl,
  //             documentName: fileUrl,
  //             docname: fileUrl,
  //             uploadedTimes: createdDateAndTime,
  //           },
  //         },
  //         {
  //           topic: "Address",
  //           description: `${gumaintenanceRequestData.homeDetails?.address}`,
  //         },
  //          ]
  //     }

  const fileList = fileImage.map(file => ({
    docname: file.file.substring(12), // Assuming file name is the document name
    fileUrl: file.file, // Assuming file location is the file URL
    documentnamed: file.file, // Assuming file name is the document name
    uploadedTimes:  moment(file.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"), // Assuming uploadedTimes is available
  }));


  const datas = [
    {
        groupName: 'Maintenance Request Details',
        items: [
            { label: 'Maintenance Request ID', data: `${gumaintenanceRequestData?.mrId || ""}`, text: 'Unique identifier for the maintenance request.' },
            { label: 'Is this issue causing a threat to personal safety?', data: gumaintenanceRequestData.isSafty ? "Yes" : "No", text: 'Indicates whether the issue poses a threat to personal safety (Yes/No).' },
            { label: 'Is this issue urgent?', data: gumaintenanceRequestData.isUrgent ? "Yes" : "No", text: 'Indicates whether the issue is urgent (Yes/No).' },
            { label: 'What needs attention?', data: renderTopic(), text: 'Description of the issue or topic that needs attention.' },
            { label: 'Status', data: CustomPascalChange(statuss), text: 'Current status of the maintenance request.' },
            { label: 'More Information', data: renderDescription(), text: 'Detailed Description of the Maintenance.' },
            { label: 'Job Category', data: gumaintenanceRequestData.jobDetails?.occupation || "", text: 'Selected Category Of Maintenance' },
        ],
    },
    {
        groupName: 'Created By Details',
        items: [
            { label: 'Firstname', data: gumaintenanceRequestData.createdByDetails?.firstName || "", text: 'First name of the person who created the maintenance request.' },
            { label: 'Lastname', data: gumaintenanceRequestData.createdByDetails?.lastName || "", text: 'Last name of the person who created the maintenance request.' },
            { label: 'Email', data: gumaintenanceRequestData.createdByDetails?.email || "", text: 'Email of the person who created the maintenance request.' },
            { label: 'Contact', data: gumaintenanceRequestData.createdByDetails?.contact || "", text: 'Contact of the person who created the maintenance request.' },
        ],
    },
    {
        groupName: 'Date Details',
        items: [
            { label: 'Created Date', data: `${moment(gumaintenanceRequestData?.createdDateAndTime).format("MM/DD/YYYY") || ""}`, text: "Date when the maintenance request was initiated." },
            { label: 'Audit Date', data: `${moment(gumaintenanceRequestData.auditDateAndTime).format("MM/DD/YYYY") || ""}`, text: 'Date when the maintenance request was last updated.' },
        ],
    },
    {
        groupName: 'Attachment Details',
        items: [
            { label: 'Attachment', data: <AttachmentListsView fileList={fileList} />, text: 'List of attached files/documents related to the maintenance request.' },
        ],
    },
];
  

  

  
  
    
      const viewDataTable = useMemo((rowData) => [
        {
          accessorKey: "serialNo",
          header: "Id",
          size: 20,
          Cell: ({ row }) => row.index + 1,
        },
       
        {
          accessorKey: "name",
          header: "Commented By",
          size: 20
       
        },
        {
          accessorKey: "status",
          // header: "Review OutCome"
          header: "Review Status",

          size: 20,
           accessorFn:(row) =>CustomPascalChange(row.status),
          
        },
              {
          accessorKey: "createdDateAndTime",
          header: "Review DateTime",
          size: 20,
          accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY hh:mm A"),
        },
        {
          accessorKey: "comments",
          header: "Review Comments",
          size: 20
        },
      ]);

      
  const handleEditClick = (index, generalCommentsId) => {
    console.log("Edit button clicked", index, generalCommentsId);
    setEditModes((prevModes) =>
      prevModes.map((mode, i) => (i === index ? true : mode))
    ); 
    setCommentsId(generalCommentsId);
    console.log("CommentsId set:", generalCommentsId);
    getGeneralCommentsById();
  };

  const handleInputChange = (e) => {
    setGeneralCommentsData(e.target.value);
  };
  const handleInputChangeAdd = (e) => {
    setGeneralCommentsDataAdd(e.target.value);
  };
  const handleCancelClick = () => {
   clearErrorsGeneral('');
    getGeneralComments();
    setEditModes(false);
    setValueGeneral('editComments',"")
  };

  const getGeneralComments = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${GET_ALL_GENERAL_COMMENTS}/${maintenanceId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setGeneralCommentDetails(data);
        setEditModes(Array(data.length).fill(false));
        setIsLoading(false);
      } else {
        setIsLoading(false)
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false)
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getGeneralComments();
  }, []);
  const onSubmitGeneral = (data,e) => {
  
    setIsLoading(true);

    // if (generalCommentsData == "" || generalCommentsData == null) {
    //   setError("comments", {
    //     type: "manual",
    //     message: COMMENTS_REQUIRED_MESSAGE,
    //   });
    //   setIsLoading(false);
    //   return;
    // }
    if (typeof generalCommentsDataAdd !== 'string' || generalCommentsDataAdd.trim() === '') {
      setErrorGeneral("comments", {
        type: "manual",
        message: COMMENTS_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    const postData = {
      comments: generalCommentsDataAdd,
      referenceId: maintenanceId,
    };
    request({ url: GENERAL_COMMENTS, method: "post", data: postData })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          getGeneralComments();
          // postData = {};
          console.log("Success");
          setIsLoading(false);
          setGeneralCommentsDataAdd("")  
          // e.target.reset();
          // formRef.current.reset(); 
          setTimeout(() => {
            //  navigate(-1);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const getGeneralCommentsById = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${GENERAL_COMMENTS}/${commentsId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        console.log(response.data);
        setGeneralCommentsData(data.comments);

        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getGeneralCommentsById();
  }, [commentsId]);

  useEffect(()=>{
    if (generalCommentsData.trim().length > 1000) {
      setErrorGeneral("editComments", {
          type: "manual",
          message: MAX_LENGTH_THOUSAND,
      });
      setIsLoading(false);
      return;
  }
  },[generalCommentsData])

  const submitUpdate = (data) => {
    console.log("Test");
    setIsLoading(true);
  

    if (generalCommentsData.trim() == "" || generalCommentsData.trim() == null) {
      setErrorGeneral("editComments", {
        type: "manual",
        message: COMMENTS_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    // if (generalCommentsData.trim().length < 2) {
    //   setErrorGeneral("editComments", {
    //     type: "manual",
    //     message: MIN_LENGTH_VALIDATE_MESSAGE,
    //   });
    //   setIsLoading(false);
    //   return;
    // }

    // if (generalCommentsData.trim().length > 1000) {
    //   setErrorGeneral("editComments", {
    //     type: "manual",
    //     message: MAX_LENGTH_THOUSAND,
    //   });
    //   setIsLoading(false);
    //   return;
    // }
    const putData = {
      comments: generalCommentsData.trim(),
      referenceId: commentsId,
    };
    request({
      url: `${GENERAL_COMMENTS}/${commentsId}`,
      method: "put",
      data: putData,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");
          setIsLoading(false);
          getGeneralComments();
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const handleDeleteClickOpen = (generalCommentsId) => {
    setDeleteOpen(true);

    setCommentsId(generalCommentsId);
    getGeneralCommentsById();
  };
  const onSubmitDelete = () => {
    request({ url: `${GENERAL_COMMENTS}/${commentsId}`, method: "delete" })
      .then((res) => {
        console.log(res);
        getGeneralComments();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const okCancelButtonActions = (
    <>
      
      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleDeleteClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleDeleteClose}
      />
    </>
  );

  const deleteModel = (
    <form
      onSubmit={handleSubmitGeneral(onSubmitDelete)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography> Are you sure you want to delete comment?</Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );
  
    return (



      <div>
        {/* <CustomViewPage data={data}/> */}
        <CustomViewHeading onClick={handleClick} title="Maintenance View" data={datas} fileList={fileList} information={gumaintenanceRequestData?.description || ""} attention={gumaintenanceRequestData.topic}>
        <CustomViewWithTooltip data={datas}  />
        <Divider/>
    {(selectedStatus !== "CLOSED") && ( 
        <>
           <Card>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-4 md:space-y-6 "
          >

            <CustomToastContainer />
            <CardContent>
              <section class="grid md:grid-cols-1 xl:grid-cols-2 gap-5">
  
  
              <Controller
                  name="status"
                  control={control}
                  rules={{ required: STATUS_REQUIRED_MESSAGE }}
                  render={({ field, fieldState }) => (
                    <FormControl
                      variant="standard"
                      fullWidth
                      error={fieldState.invalid}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Select Status <span style={{color:'red'}}>*</span>
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={field.value || status}
                        onChange={(value) => {
                         field.onChange(value);
                                                }}
                        label="Select Status"
                      >
                        <MenuItem value="IN PROGRESS" >
                        In Progress
                        </MenuItem>
                        <MenuItem value="PENDING" >Pending</MenuItem>
                        <MenuItem value="COMPLETED" >Completed</MenuItem>
                       
                      </Select>
                      <FormHelperText style={{ color: "#D32F2F" }}>
                        {fieldState.invalid ? fieldState.error?.message : ""}
                      </FormHelperText>
                    </FormControl>
                  )}
                />

{/* <CustomFileEdit downloadFiles={true} fileData={fileData} handleFileChange={handleFileChange} token={token} file={file}/> */}
<CustomFile file={file} handleFileChange={handleFileChange} token={token} tooltipMessage={"Upload supporting documents such as lease agreement, identity proof, etc."} />


  
  

                <CustomInputField
                  fieldName="reviewComment"
                  requiredErrorMessage={REVIEW_COMMENT_REQUIRED_MESSAGE}
                  minLength={2}
                  minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                  maxLength={5000}
                  maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
                  fieldId="reviewComment"
                  fieldType="text"
                  fieldLabel="Review Comment"
                  fieldControl={control}
                  fieldError={errors}
                  fieldRequiredIcon="*"
                  multiline={true}
                  numberOfRows={5}
                />
             
              

               
              </section>
              <div class="text-center mt-10">
                <CustomButton fieldType="submit" buttonName="Update" />
              </div>
            </CardContent>
          </form>
        </Card>
        </>
    ) }
     
         {/* <Divider/> */}
         <br></br><br></br>

         <CustomMaterialTable
            columns={viewDataTable}
            // data={viewData}
            data={reviewComment}
                        />
  
            <br/>
            <br/>
            <div>
        {/* {isLoading && <CustomLoading />} */}
        </div>
      <div>
        <CustomToastContainer />
        <br />

        <CustomHeading title="Comments" />
        <CustomModel
          title=" Delete Comments"
          submit={onSubmitDelete}
          content={deleteModel}
          action={okCancelButtonActions}
          openStatus={deleteOpen}
          closeStatus={handleDeleteClose}
          iconCloseStatus={handleDeleteClose}
          reset={reset}
          modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }} />
        <Card>
          <form
            onSubmit={handleSubmitGeneral(onSubmitGeneral)}
            className="space-y-4 md:space-y-6 "
          >
            <CardContent>
              <CustomInputField
                fieldName="comments"
                // requiredErrorMessage={COMMENTS_REQUIRED_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={1000}
                maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
                fieldId="comments"
                fieldType="text"
                fieldLabel="Write a Comments"
                fieldControl={controlGeneral}
                fieldError={errorsGeneral}
                fieldRequiredIcon="*"
                multiline={true}
                numberOfRows={2}
                value={generalCommentsDataAdd}
                onInput={handleInputChangeAdd} />

              <div class="text-right mt-6">
                <CustomButton fieldType="submit" buttonName="Post" />
                {isLoading && <CustomLoading />}
              </div>
            </CardContent>
          </form>
          <CardContent>
            {generalCommentDetails &&
              generalCommentDetails.map((comment, index) => (
                <div key={comment.generalCommentsId}>
                 <section className="grid md:grid-cols-1 xl:grid-cols-1 gap-0 mt-2 ml-16 mb-0">
                    <p >
                      <span className="text-primary" style={{ float: "left" }}>
                        @ {comment.createdByName}{" "}
                      </span>
                      <span style={{ float: "right" }}>


                      {!editModes[index]  && comment.createdById === HomeLoginId && (
                          <>
                            {index === 0 && (
                              <>
                                <Tooltip title="Edit Comment">
                                  <EditNoteOutlined
                                    color="primary"
                                    onClick={() => {
                                      handleEditClick(index, comment.generalCommentsId);
                                      console.log(comment.generalCommentsId);
                                    } } />
                                </Tooltip>
                                &nbsp; {/* Adding space between icons */}
                                <Tooltip title="Delete Comment">
                                  <DeleteOutline
                                    color="error"
                                    onClick={() => {
                                      handleDeleteClickOpen(comment.generalCommentsId);
                                      console.log(comment.generalCommentsId);
                                    } } />
                                </Tooltip>
                              </>
                            )}
                          </>
                        )}
                        {moment(comment.createdDateAndTime).format(
                          "MM/DD/YYYY hh:mm:ss a"
                        )}
                      </span>
                      {editModes[index] ? (
                        <form
                          onSubmit={handleSubmitGeneral(submitUpdate)}
                          className="space-y-4 md:space-y-6 "
                        >
                          <CardContent>
                            <CustomInputField
                              fieldName="editComments"
                              minLength={2}
                              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                              maxLength={1000}
                              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}

                              fieldId="editComments"
                              fieldType="text"
                              // fieldLabel="Write a Comments"
                              fieldControl={controlGeneral}
                              errors={errorsGeneral}
                              // fieldRequiredIcon="*"
                              multiline={true}
                              numberOfRows={2}
                              value={generalCommentsData}
                              onInput={handleInputChange} />

<div className="flex justify-center mt-8 space-x-1">
  <CustomButton
    fieldType="submit"
    buttonName="Save"
  />
  <CustomButton
    fieldType="button"
    buttonName="Cancel"
    click={handleCancelClick}
  />
  {isLoading && <CustomLoading />}
</div>
                          </CardContent>
                        </form>
                      ) : (
                        <p className="mt-6 mb-2">{comment.comments}</p>
                      )}
                    </p>
                  </section>

                  <Divider />
                </div>
              ))}
          </CardContent>
        </Card>
      </div>
    
  </CustomViewHeading>
      </div>
    )
  }

export default GuMaintenanceView