import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  BASE_URL,
  BYLAW,
  DECISION_MAINTENANCE,
  EDIT_MAINTENANCE,
  GENERAL_COMMENTS,
  GET_ALL_GENERAL_COMMENTS,
  MAINTENANCE_REQUEST,
  REVIEW_MAINTENANCE,
} from "../../../utills/ApplicationRouting";
import {
  DeleteOutline,
  EditNoteOutlined,
  FileDownloadOutlined,
} from "@mui/icons-material";
import CustomViewPage from "../../../components/CustomViewPage";
import CustomHeading from "../../../components/CustomHeading";
import {
  Card,
  CardContent,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Typography,
} from "@mui/material";
import CustomInputField from "../../../components/CustomInputField";
import {
  BYLAW_REQUIRED_MESSAGE,
  COMMENTS_REQUIRED_MESSAGE,
  MAX_LENGTH_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
  REVIEW_COMMENT_REQUIRED_MESSAGE,
} from "../../../utills/ApplicationConstants";
import CustomButton from "../../../components/CustomButton";
import moment from "moment";
import CustomLoading from "../../../components/CustomLoading";
import CustomToastContainer from "../../../components/CustomToastContainer";
import { request } from "../../../services/AxiosConfig";
import CustomModel from "../../../components/CustomModel";
import CustomOptions from "../../../components/CustomOptions";
import CustomAutoComplete from "../../../components/CustomAutoComplete";
import CustomMaterialTable from "../../../components/CustomMaterialTable";
import CustomModelView from "../../../components/CustomModelView";
import Tooltip from "@mui/material/Tooltip";
import CustomPascalChange from "../../../components/CustomPascalChange";
import CustomMaterialWithoutRoutes from "../../../components/CustomMaterialWithoutRoutes";
import CustomReviewTable from "../../../components/CustomReviewTable";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AttachmentList from "../../../components/AttachmentList";
import CustomViewHeading from "../../../components/CustomViewHeading";
import CustomViewWithTooltip from "../../../components/CustomViewWithTooltip";
import AttachmentListsView from "../../../components/AttachmentListsView";
import CustomViewWithMore from "../../../components/CustomViewWithMore";
import CustomMainButton from "../../../components/CustomMainButton";
import CustomGeneralComments from "../../../components/CustomGeneralComments";

function BmMaintenanceView() {
  const navigate = useNavigate();
  const { maintenanceId } = useParams();
  const [maintenanceRequestData, setMaitenanceRequestData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [byLawDetails, setByLawDetails] = useState([]);
  const [byLawDescription, setByLawDescription] = useState("");
  const [status, setStatus] = useState("");
  const { token, roles ,userDetails, userLoginDetails, HomeLoginId} = useSelector((state) => state.hybridhoa);
  const [selectedBylawValue, setSelectedBylawValue] = useState(null);
  const [generalCommentsDataAdd, setGeneralCommentsDataAdd] = useState("");
  const[fileImage,setFileImage]=useState([])
  const {
    handleSubmit: handleSubmitGeneral,
    control: controlGeneral,
    formState: { errors: errorsGeneral },
    setValue: setValueGeneral,
    setError: setErrorGeneral,
    reset: resetGeneral,
    watch: watchGeneral,
    clearErrors:clearErrorsGeneral
  } = useForm();

  const {
    handleSubmit: handleSubmitReviewComments,
    control: controlReviewComments,
    formState: { errors: errorsReviewComments },
    setValue: setValueReviewComments,
    setError: setErrorReviewComments,
    reset: resetReviewComments,
    watch: watchReviewComments,
  } = useForm();
  const [generalCommentDetails, setGeneralCommentDetails] = useState("");
  const [editModes, setEditModes] = useState(
    Array.isArray(generalCommentDetails)
      ? Array(generalCommentDetails.length).fill(false)
      : []
  );
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [generalCommentsData, setGeneralCommentsData] = useState("");
  const [commentsId, setCommentsId] = useState("");
  const [bylawTopic, setBylawTopic] = useState("");
  const [reviewComment, setReviewComment] = useState("");
  const [approvalOpen, setApprovalOpen] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);
  const [isBylawFieldEmpty, setIsBylawFieldEmpty] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createdByDetails,setCreatedByDetails]=useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [createdDateAndTime, setCreatedDateAndTime] = useState("");


  const handleClick = () => {
    navigate(-1);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const getMaintenanceRequestDetailsById = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${EDIT_MAINTENANCE}${maintenanceId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        setIsLoading(false);
        const data = response.data;
        console.log("mrIds", data.mrId);
        setMaitenanceRequestData(data);
        setSelectedOption(data.status);
        setStatus(data.status);
        setFileUrl(data.fileUrl);
        setFileImage(data.attachments)
        setCreatedByDetails(data.createdByDetails)
        const formattedDate = moment(data.createdDateAndTime).format(
          "MM/DD/YYYY hh:mm A"
        );
        setCreatedDateAndTime(formattedDate);
        setReviewComment(data.reviewComments);

        const matchingByLaw = byLawDetails.find(
          (byLaw) => byLaw.byLawId === data.bylawId
        );
        if (matchingByLaw) {
          setBylawTopic(matchingByLaw.topic);
          setValueReviewComments("selectedBylaw", matchingByLaw.byLawId);
          setByLawDescription(matchingByLaw.description);
          setSelectedBylawValue({
            label: matchingByLaw.topic,
            value: matchingByLaw.byLawId,
          });
        } else {
          setValueReviewComments("selectedBylaw", "");
          setByLawDescription("");
          setSelectedBylawValue(null);
        }

        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMaintenanceRequestDetailsById();
  }, [maintenanceId, byLawDetails]);

  const handleEditClick = (index, generalCommentsId) => {
    console.log("Edit button clicked", index, generalCommentsId);
    setEditModes((prevModes) =>
      prevModes.map((mode, i) => (i === index ? true : mode))
    );
    setCommentsId(generalCommentsId);
    console.log("CommentsId set:", generalCommentsId);
    getGeneralCommentsById();
  };
  // const handleInputChangeAdd = (e) => {
  //   setGeneralCommentsDataAdd(e.target.value);
  // };

  const handleInputChange = (e) => {
    setGeneralCommentsData(e.target.value);
  };

  const handleCancelClick = () => {
    console.log("Cancel");
    getGeneralComments();
    setEditModes(false);
    clearErrorsGeneral('')
    setValueGeneral('editComments',"")
  };

  const getGeneralComments = async () => {
    try {
      // setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${GET_ALL_GENERAL_COMMENTS}/${maintenanceId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setGeneralCommentDetails(data);
        setEditModes(Array(data.length).fill(false));
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getGeneralComments();
  }, []);
  const onSubmit = (data,e) => {
    console.log("hi");
    if (typeof generalCommentsDataAdd !== 'string' || generalCommentsDataAdd.trim() === '') {
      setErrorGeneral("comments", {
        type: "manual",
        message: COMMENTS_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    setIsLoading(true);

    const postData = {
      comments:generalCommentsDataAdd ,
      referenceId: maintenanceId,
    };
    // if (data.comments == "" || data.comments == null) {
    //   setErrorGeneral("comments", {
    //     type: "manual",
    //     message: COMMENTS_REQUIRED_MESSAGE,
    //   });
    //   setIsLoading(false);
    //   return;
    // }
    request({ url: GENERAL_COMMENTS, method: "post", data: postData })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
      
          getGeneralComments();
          console.log("Success");
          setIsLoading(false);
          // e.target.reset();
          setGeneralCommentsDataAdd("") 
          setTimeout(() => {
            //  navigate(-1);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const getGeneralCommentsById = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${GENERAL_COMMENTS}/${commentsId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        console.log(response.data);
        setGeneralCommentsData(data.comments);

        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getGeneralCommentsById();
  }, [commentsId]);

  useEffect(()=>{
    if (generalCommentsData.trim().length > 1000) {
      setErrorGeneral("editComments", {
          type: "manual",
          message: MAX_LENGTH_THOUSAND,
      });
      setIsLoading(false);
      return;
  }
  },[generalCommentsData])

  const submitUpdate = (data) => {
    console.log("Test");
    setIsLoading(true);
    const putData = {
      comments: generalCommentsData,
      referenceId: commentsId,
    };

    if (generalCommentsData == "" || generalCommentsData == null) {
      setErrorGeneral("editComments", {
        type: "manual",
        message: COMMENTS_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    request({
      url: `${GENERAL_COMMENTS}/${commentsId}`,
      method: "put",
      data: putData,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");
          setIsLoading(false);
          getGeneralComments();
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const handleDeleteClickOpen = (generalCommentsId) => {
    setDeleteOpen(true);

    setCommentsId(generalCommentsId);
    getGeneralCommentsById();
  };
  const onSubmitDelete = () => {
    request({ url: `${GENERAL_COMMENTS}/${commentsId}`, method: "delete" })
      .then((res) => {
        console.log(res);
        getGeneralComments();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBylaw = async () => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${BYLAW}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setByLawDetails(data);
        // setByLawDescription(data.description);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getBylaw();
  }, []);

  // const handleByLawChange = (event, newValue) => {
  //   if (newValue && newValue.value !== undefined && newValue.value !== null) {
  //     const selectedByLawId = newValue.value;
  //     setValue("selectedBylaw", selectedByLawId);
  //     const selectedByLawDescription = byLawDetails.find(
  //       (byLaw) => byLaw.byLawId === selectedByLawId
  //     )?.description;

  //     setSelectedByLaw(selectedByLawId);
  //     setByLawDescription(selectedByLawDescription);
  //     setIsBylawFieldEmpty(false);

  //     // Update the state here
  //     setSelectedBylawValue(newValue);
  //   } else {
  //     // Clear the state values when no bylaw is selected
  //     setByLawDescription('');
  //     setValue("selectedBylaw", null);
  //     setSelectedByLaw(null);
  //     setIsBylawFieldEmpty(true);

  //     // Update the state here
  //     setSelectedBylawValue(null);
  //   }
  // };

  const handleByLawChange = (event, newValue) => {
    if (newValue && newValue.value !== undefined && newValue.value !== null) {
      const selectedByLawId = newValue.value;
      setValue("selectedBylaw", selectedByLawId);
      const selectedByLawDescription = byLawDetails.find(
        (byLaw) => byLaw.byLawId === selectedByLawId
      )?.description;

      setByLawDescription(selectedByLawDescription);

      // Update the state here
      setSelectedBylawValue(newValue);
    } else {
      // Clear the state values
      setByLawDescription("");
      setValue("selectedBylaw", null);

      // Update the state here
      setSelectedBylawValue(null);
    }
  };

  const renderedBylaw = byLawDetails.map((ByLaw, index) => ({
    topic: ByLaw.topic,
    value: ByLaw.byLawId,
    key: index,
  }));

  const [selectedOption, setSelectedOption] = useState("");
  const [checked, setChecked] = useState(false);
  const [selectedByLaw, setSelectedByLaw] = useState(null);

  const options = [
    { label: "PENDING", value: "PENDING_IN_ARC" },
    { label: "ACKNOWLEDGED", value: "ACKNOWLEDGED" },
    { label: "PENDING APPROVAL", value: "PENDING APPROVAL", disabled: true },
    { label: "APPROVED", value: "APPROVED", disabled: true },
    { label: "REJECTED", value: "REJECTED", disabled: true },
  ];

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const viewDataTable = useMemo((rowData) => [
    // {
    //   accessorKey: "serialNo",
    //   header: "S.No",
    //   size: 1,
    //   Cell: ({row}) => row.index+1
    // },
    {
      accessorKey: "createdDateAndTime",
      header: "Assigned DateTime",
      size: 20,
      accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss A"),
    },
    {
      accessorKey: "name",
      header: "Commented  By",
      size: 20,
    },
    {
      accessorKey: "status",
      header: "Review Status",
      size: 20,
    },
    {
      accessorKey: "auditDateAndTime",
      header: "Review DateTime",
      size: 20,
      accessorFn: (row) => moment(row.auditDateAndTime).format("MM/DD/YYYY hh:mm:ss A"),
    },
  //  { 
  //     accessorKey: "comments",
  //     header: "Review Comments",
  //     size: 20,
  //   },
  ]);

  useEffect(() => {
    const selectedBylaw = watchReviewComments("selectedBylaw");

    if (!isModalOpen) {
      if (!selectedBylaw || selectedBylaw === "") {
        setByLawDescription("");
        setIsBylawFieldEmpty(true);
      } else {
        // Find the matching byLaw from byLawDetails
        const matchingByLaw = byLawDetails.find(
          (byLaw) => byLaw.byLawId === selectedBylaw
        );

        if (matchingByLaw) {
          setByLawDescription(matchingByLaw.description);
          setIsBylawFieldEmpty(false);
        } else {
          // Handle the case where no matching byLaw is found
          setByLawDescription("");
          setIsBylawFieldEmpty(true);
        }
      }
    }
  }, [watchReviewComments("selectedBylaw"), byLawDetails]);

  // const onUpdateSubmit=(data)=>{
  //   const rolesString = roles.join(", ");
  //   const payload={
  //     status:selectedOption,
  //     bylawId:data.selectedBylaw,
  //     role:rolesString,
  //     assignToBM:checked,
  //   }

  // request({url:`${REVIEW_MAINTENANCE}${maintenanceId}`, method:"put", data:payload}).then(res=>{
  // setIsLoading(false);
  // console.log(res);
  // if(res.status==200){

  //   setIsLoading(false);

  // }
  // }).catch(error=>{
  // console.log(error.response);
  // setIsLoading(false);
  // if (error.response) {

  //   console.log("error.response.status" + error.response.status);

  // }
  // });
  // }
  const onApproveSubmit = (data) => {

    const isBylawValid = data.selectedBylaw !== null && data.selectedBylaw !== '';

    if (!isBylawValid) {
      // Set error for the selectedBylaw field
      setErrorReviewComments('selectedBylaw', {
        type: 'manual',
        message: 'Please provide a valid bylaw.',
      });

      // Optionally, return or handle the invalid case
      return;
    }

    if (!data.reviewComment) {
      setErrorReviewComments("reviewComment", {
        type: "manual",
        message: REVIEW_COMMENT_REQUIRED_MESSAGE,
      });
      return;
    }else if (data.reviewComment.length < 2 || data.reviewComment.length > 1000) {
      setErrorReviewComments("reviewComment", {
        type: "manual",
        message: data.reviewComment.length < 2
          ? MIN_LENGTH_VALIDATE_MESSAGE
          : MAX_LENGTH_THOUSAND,
      });
      return;
    }
    const rolesString = roles.toString();
    const payload = {
      status: "APPROVED",
      comments: data.reviewComment,
      role:rolesString,
    };
      const storedToken = token;

    axios.put(`${BASE_URL}${DECISION_MAINTENANCE}${maintenanceId}`, payload,{
      headers: {
       Authorization: `Bearer ${storedToken}`,
     }
   })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          setApprovalOpen(false);
          const message=res.data
          toast.success(message, { position: "top-center" });
          setIsLoading(false);
          getMaintenanceRequestDetailsById();
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        if (error.response && error.response.data) {
          const message = error.response.data.message;
          toast.error(message, { position: "top-center" });
          console.log("error.response.status" + error.response.status);
        } else {
          console.error(error); // Log the entire error for further debugging
          toast.error("An error occurred while processing your request.");
        }
      });
  };

  const handleApprovalOpen = () => {
    setApprovalOpen(true);
    setIsModalOpen(true); // Set the modal as open
    resetReviewComments(); // Reset form errors
  };

  const handleApprovalClose = () => {
    setApprovalOpen(false);
    setIsModalOpen(false); // Set the modal as closed
    resetReviewComments(); // Reset form errors
  };

  const handleRejectOpen = () => {
    setRejectOpen(true);
    setIsModalOpen(true); // Set the modal as open
    resetReviewComments(); // Reset form errors
  };

  const handleRejectClose = () => {
    setRejectOpen(false);
    setIsModalOpen(false); // Set the modal as closed
    resetReviewComments(); // Reset form errors
  };

  const handleIconClose = () => {
    setApprovalOpen(false);
    setIsModalOpen(false);
    setValueReviewComments("reviewComment", "");
  };
  const handleRejectIconClose = () => {
    setRejectOpen(false);
    setIsModalOpen(false);
    setValueReviewComments("reviewComment", "");
  };

  const approvalModelAction = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Approve"
      />
    </>
  );
  const approveModelContent = (
    <>
      <form
        onSubmit={handleSubmitReviewComments(onApproveSubmit)}
        className="space-y-4 md:space-y-6 "
      >
    
        <DialogContent dividers>
          <CustomInputField
            fieldName="reviewComment"
            // requiredErrorMessage={REVIEW_COMMENT_REQUIRED_MESSAGE}
            // minLength={2}
            // minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
            // maxLength={1000}
            // maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
            fieldId="reviewComment"
            fieldType="text"
            fieldLabel="Review Comment"
            fieldControl={controlReviewComments}
            fieldError={errorsReviewComments}
            fieldRequiredIcon="*"
            multiline={true}
            numberOfRows={3}
          />
        </DialogContent>
        <DialogActions>{approvalModelAction}</DialogActions>
      </form>
    </>
  );

  const onRejectSubmit = (data) => {
    const isBylawValid = data.selectedBylaw !== null && data.selectedBylaw !== '';

    if (!isBylawValid) {
      // Set error for the selectedBylaw field
      setErrorReviewComments('selectedBylaw', {
        type: 'manual',
        message: 'Please provide a valid bylaw.',
      });

      // Optionally, return or handle the invalid case
      return;
    }

    if (!data.reviewComment) {
      setErrorReviewComments("reviewComment", {
        type: "manual",
        message: REVIEW_COMMENT_REQUIRED_MESSAGE,
      });
      return;
    }else if (data.reviewComment.length < 2 || data.reviewComment.length > 1000) {
      setErrorReviewComments("reviewComment", {
        type: "manual",
        message: data.reviewComment.length < 2
          ? MIN_LENGTH_VALIDATE_MESSAGE
          : MAX_LENGTH_THOUSAND,
      });
      return;
    }
    const rolesString = roles.toString();
    const payload = {
      status: "REJECTED",
      comments: data.reviewComment,
      role:rolesString,
    };
    const storedToken = token;
    const headers= {
       Authorization: `Bearer ${storedToken}`,
     }
     request({ url: `${BASE_URL}${DECISION_MAINTENANCE}${maintenanceId}`, method: "put", data: payload,token:headers })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          const message=res.data
          // toast.success(message, { position: "top-center" });
          setRejectOpen(false);
          setIsLoading(false);
          getMaintenanceRequestDetailsById();
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        if (error.response && error.response.data) {
          // const message = error.response.data.message;
          // toast.error(message, { position: "top-center" });
          console.log("error.response.status" + error.response.status);
        } else {
          console.error(error); // Log the entire error for further debugging
          // toast.error("An error occurred while processing your request.");
        }
      });
  };

  const rejectModelAction = (
    <>
      <CustomButton fieldType="submit" buttonName="Reject" />
    </>
  );
  const rejectModelContent = (
    <>
      <form
        onSubmit={handleSubmitReviewComments(onRejectSubmit)}
        className="space-y-4 md:space-y-6 "
      >
        <CustomToastContainer />
        <DialogContent dividers>
          <CustomInputField
            fieldName="reviewComment"
            // requiredErrorMessage={REVIEW_COMMENT_REQUIRED_MESSAGE}
            // minLength={2}
            // minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
            // maxLength={1000}
            // maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
            fieldId="reviewComment"
            fieldType="text"
            fieldLabel="Review Comment"
            fieldControl={controlReviewComments}
            fieldError={errorsReviewComments}
            fieldRequiredIcon="*"
            multiline={true}
            numberOfRows={3}
          />
        </DialogContent>
        <DialogActions>{rejectModelAction}</DialogActions>
      </form>
    </>
  );

  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '') return '';
  
    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const rowActions = ({ rowData, tabIndex }) => {
    return (
      <div className="text-center flex space-x-3">
        {selectedOption === "PENDING APPROVAL"  && (rowData.createdFor === userDetails.homeOwnerLoginId || rowData.createdFor === userLoginDetails.homeOwnerLoginId) && (
          <>
            <CustomMainButton
              fieldType="submit"
              buttonName="Approve"
              click={handleSubmitReviewComments(handleApprovalOpen)}
              tabIndex={tabIndex}
            />
            <CustomMainButton
              fieldType="submit"
              buttonName="Reject"
              click={handleSubmitReviewComments(handleRejectOpen)}
              tabIndex={tabIndex}
            />
          </>
        )}
      </div>
    );
  };
  

  

  // const data = {
  //   pageTitle: "View Maintenance",
  //   buttons: [
  //     {
  //       buttonTitle: "Back",
  //       onClick: handleClick,
  //     },
  //   ],
  //   content: [
  //     {
  //       topic: "MaintenanceRequest Id",
  //       description: maintenanceRequestData.mrId,
  //     },
  //     {
  //       topic: "What needs attention?",
  //       description: maintenanceRequestData.topic,
  //     },
  //     {
  //       topic: "More Information ",
  //       description: maintenanceRequestData.description,
  //     },
  //     {
  //       topic: "Requested Date Time",
  //       description: moment(maintenanceRequestData.createdDateAndTime).format(
  //         "MM/DD/YYYY hh:mm:ss A"
  //       ),
  //     },
  //     {
  //       topic: "Updated Date Time",
  //       description: moment(maintenanceRequestData.auditDateAndTime).format(
  //         "MM/DD/YYYY hh:mm:ss A"
  //       ),
  //     },
  //     {
  //       topic: "Is this issue urgent? ",
  //       description: maintenanceRequestData.isUrgent ? "Yes" : "No",
  //     },
  //     {
  //       topic: "Is this issue causing a threat to personal safety?",
  //       description: maintenanceRequestData.isSafty ? "Yes" : "No",
  //     },
  //     {
  //       topic: "Created By",
  //       description: `${maintenanceRequestData.createdByDetails?.firstName || "Unknown"
  //         } ${maintenanceRequestData.createdByDetails?.lastName || "Unknown"}`,
  //     },
  //     {
  //       topic: "Status",
  //       description: CustomPascalChange(status),
  //     },
  //     {
  //       topic: "Request Type",
  //       description: maintenanceRequestData.type,
  //     },
  //     {
  //       topic: "Attachments",
  //       component: "AttachmentList",
  //       props: {
  //         fileUrl: fileUrl,
  //         documentName: fileUrl,
  //         docname: fileUrl,
  //         uploadedTimes: createdDateAndTime,
  //       },
  //     },
  //   ],
  // };

  const fileList = fileImage.map(file => ({
    docname: file.file.substring(12), // Assuming file name is the document name
    fileUrl: file.file, // Assuming file location is the file URL
    documentnamed: file.file, // Assuming file name is the document name
    uploadedTimes: moment(file.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"), // Assuming uploadedTimes is available
    }));

  let formattedContact = "";
if (createdByDetails?.contact) {
  formattedContact = createdByDetails.contact.replace(/^\+\d+\s?(\d{3})(\d{4})(\d{3})$/, "$1-$2-$3");
}

const datas = [
  {
    groupName: 'Maintenance Details',
    items: [
      { label: 'MaintenanceRequest Id', data: `${maintenanceRequestData?.mrId || ""}`, text: 'Unique identifier for the maintenance request.' },
      { label: 'What needs attention?', data: `${maintenanceRequestData?.topic || ""}`, text: 'Description of the issue or topic that needs attention.' },
      { label: 'More Information', data: `${maintenanceRequestData?.description || ""}`, text: 'Additional information or details about the maintenance request.' },
      { label: 'Is this issue urgent?', data: `${maintenanceRequestData.isUrgent ? "Yes" : "No" || ""}`, text: 'Indicates whether the issue requires immediate attention (Yes/No).' },
      { label: 'Is this issue causing a threat to personal safety?', data: `${maintenanceRequestData.isSafty ? "Yes" : "No" || ""}`, text: 'Indicates whether the issue poses a threat to personal safety (Yes/No).' },
      { label: 'Status', data: `${convertToPascalCase(maintenanceRequestData?.status) || ""}`, text: 'Current status of the maintenance request.' },
      { label: 'Request Type', data: `${maintenanceRequestData?.type || ""}`, text: 'Type or category of the maintenance request.' },
    ],
  },
  {
    groupName: 'Date Details',
    items: [
      {
        label: 'Start Date',
        data: maintenanceRequestData?.startDate && moment(maintenanceRequestData.startDate).isValid() ? moment(maintenanceRequestData.startDate).format("MM/DD/YYYY") : "",
        text: 'Date and time when the maintenance request was initiated.'
      },
      {
        label: 'End Date Time',
        data: maintenanceRequestData?.endDate && moment(maintenanceRequestData.endDate).isValid() ? moment(maintenanceRequestData.endDate).format("MM/DD/YYYY") : "",
        text: 'Date and time when the maintenance request is expected to be completed.'
      },  
      // { label: 'Requested Date Time', data: `${moment(maintenanceRequestData?.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss A") || "" }`, text: 'Date and time when the maintenance request was submitted.' },
      // { label: 'Updated Date Time', data: `${moment(maintenanceRequestData?.auditDateAndTime).format("MM/DD/YYYY hh:mm:ss A") || ""}`, text: 'Date and time when the maintenance request was last updated.' },
    ],
  },
  {
    groupName: 'Attachment Details',
    items: [
      { label: 'Attachment', data: (
        <AttachmentListsView fileList={fileList} />
        ), text: 'List of attached files/documents related to the maintenance request.' },
    ],
  },
];


  const okCancelButtonActions = (
    <>
      
      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleDeleteClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleDeleteClose}
      />
    </>
  );

  const deleteModel = (
    <form
      onSubmit={handleSubmitGeneral(onSubmitDelete)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography> Are you sure you want to delete comment?</Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );

  return (
    <div>
     
        {/* <CustomViewPage data={data} /> */}
        <CustomViewHeading onClick={handleClick}  title={"Maintenance Request"} data={datas} fileList={fileList} information={(maintenanceRequestData.description)} attention={maintenanceRequestData.topic}>
      <CustomViewWithMore data={datas}  />
        {isLoading && <CustomLoading />}
        <CustomToastContainer />
  
      <br />
    
        <CustomModelView
          title="Maintenance Request"
          submit={onApproveSubmit}
          content={approveModelContent}
          action={approvalModelAction}
          openStatus={approvalOpen}
          closeStatus={handleApprovalClose}
          iconCloseStatus={handleIconClose}
        />
        <CustomModelView
          title="Maintenance Request"
          submit={onRejectSubmit}
          content={rejectModelContent}
          action={rejectModelAction}
          openStatus={rejectOpen}
          closeStatus={handleRejectClose}
          iconCloseStatus={handleRejectIconClose}
        />
      
      <div>
        <Card>
          <form className="space-y-4 md:space-y-6 ">
            <CardContent>
              <section className="grid md:grid-cols-2 xl:grid-cols-3 gap-5">
                <CustomOptions
                  label="Status"
                  options={options}
                  value={selectedOption}
                  onChange={handleSelectChange}
                  disabled={true}
                  // fieldRequiredIcon="*"
                />

                <CustomAutoComplete
                  fieldName="selectedBylaw"
                  // requiredErrorMessage={BYLAW_REQUIRED_MESSAGE}
                  id="selectId"
                  options={renderedBylaw}
                  fieldLabel="Select Bylaw"
                  fieldControl={controlReviewComments}
                  error={errorsReviewComments}
                  setValue={setValueReviewComments}
                  onChange={handleByLawChange}
                  value={selectedBylawValue}
                  // fieldRequiredIcon="*"
                  isRequired={false}
                />


                <CustomInputField
                  fieldName="bylawDescription"
                  fieldId="bylawDescription"
                  fieldType="text"
                  fieldLabel="Bylaw Description"
                  fieldControl={controlReviewComments}
                  fieldError={errorsReviewComments}
                  disabled={true}
                  value={byLawDescription}
                />
                {/* {status === 'PENDING_IN_ARC' && (
  <div onClick={(e) => e.stopPropagation()}>
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={handleCheckboxChange}
          color="primary"
          size="small"
        />
      }
      label={<span style={{ fontSize: '14px' }}>Assign to BM?</span>}
    />
  </div>
)} */}
              </section>
              <br />
              <Divider />
              <div>
              <CustomReviewTable
                columns={viewDataTable}
                data={reviewComment}
                rowActions={rowActions}
              />
</div>
              {/*<div className="text-center mt-10 flex space-x-3">
                {/*   <CustomButton fieldType="submit" buttonName="Update" click={handleSubmit(onUpdateSubmit)} />  
                {status === "PENDING APPROVAL" && (
                  <>
                    <CustomButton
                      fieldType="submit"
                      buttonName="Approve"
                      // disabled={isBylawFieldEmpty}
                      click={handleSubmitReviewComments(handleApprovalOpen)}
                    />
                    <CustomButton
                      fieldType="submit"
                      buttonName="Reject"
                      // disabled={isBylawFieldEmpty}
                      click={handleSubmitReviewComments(handleRejectOpen)}
                    />
                  </>
                )}
              </div>*/}
            </CardContent>
          </form>
        </Card>
      </div>
      <br />
      <br />

      {/* <CustomModel
        title=" Delete Comments"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        reset={resetGeneral}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      />
      <CustomHeading title="General Comments" />
     
      <Card>
      <form
           onSubmit={handleSubmitGeneral(onSubmit)}

>
  <CardContent>
    <div className="flex items-center space-x-4">
      <CustomInputField
        fieldName="comments"
        // requiredErrorMessage={COMMENTS_REQUIRED_MESSAGE}
        minLength={2}
        minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
        maxLength={1000}
        maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
        fieldId="comments"
        fieldType="text"
        fieldLabel="Write a Comment"
        fieldControl={controlGeneral}
        fieldError={errorsGeneral}
        fieldRequiredIcon="*"
        multiline={true}
        numberOfRows={2}
        value={generalCommentsDataAdd}
        onInput={(e)=>{setGeneralCommentsDataAdd(e.target.value)}}
        className="flex-grow"
      />
      <CustomButton fieldType="submit" buttonName="Post" />
      {/* {isLoading && <CustomLoading />} 
    </div>
  </CardContent>
</form>
        <CardContent>
          {generalCommentDetails &&
            generalCommentDetails.map((comment, index) => (
              <div key={comment.generalCommentsId}>
                <section className="grid md:grid-cols-1 xl:grid-cols-1 gap-3  ml-5">
                  <p>
                    <span className="text-primary " style={{ float: "left" }}>
                      @{comment.createdByName}{" "}
                    </span>
                    <span style={{ float: "right" }}>
                     
                      {!editModes[index] && comment.createdById === userLoginDetails.homeOwnerLoginId && (
                        <>
                          {index === 0 && (
                            <>
                              {" "}
                              {/* Add a space here 
                              <EditNoteOutlined
                                color="primary"
                                onClick={() => {
                                  handleEditClick(
                                    index,
                                    comment.generalCommentsId
                                  );
                                  console.log(comment.generalCommentsId);
                                }}
                              />{" "}

                              <DeleteOutline
                                color="error"
                                onClick={() => {
                                  handleDeleteClickOpen(
                                    comment.generalCommentsId
                                  );
                                  console.log(comment.generalCommentsId);
                                }}
                              />
                            </>
                          )}
                        </>
                      )}
                       {moment(comment.createdDateAndTime).format(
                        "MM/DD/YYYY HH:mm:ss"
                      )}
                    </span>

                    {editModes[index] ? (
                      <form
                        onSubmit={handleSubmitGeneral(submitUpdate)}
                        className="space-y-4 md:space-y-6 "
                      >
                        <CardContent>
                          <CustomInputField
                            fieldName="editComments"
                            minLength={2}
                            minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                            maxLength={1000}
                            maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
                            fieldId="editComments"
                            fieldType="text"
                            // fieldLabel="Write a Comments"
                            fieldControl={controlGeneral}
                            errors={errorsGeneral}
                            // fieldRequiredIcon="*"
                            multiline={true}
                            numberOfRows={2}
                            value={generalCommentsData}
                            onInput={handleInputChange}
                          />

<div className="flex justify-center mt-8 space-x-1">
  <CustomButton
    fieldType="submit"
    buttonName="Save"
  />
  <CustomButton
    fieldType="button"
    buttonName="Cancel"
    click={handleCancelClick}
  />
  {isLoading && <CustomLoading />}
</div>
                        </CardContent>
                      </form>
                    ) : (
                      <p className="mt-8 mb-2 break-words">
                        {comment.comments}
                      </p>
                    )}
                  </p>
                </section>

                <Divider />
              </div>
            ))}
        </CardContent>
      </Card>  */}
      <CustomGeneralComments />
      </CustomViewHeading>
    </div>
  );
}

export default BmMaintenanceView;
