import React, { useEffect, useMemo, useState } from "react";
import CustomHeading from "../../components/CustomHeading";
import CustomMaterialTables from "../../components/CustomMaterialPaymentTable";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL, USER_DUE } from "../../utills/ApplicationRouting";
import moment from 'moment'
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { setRemainingDueAmount, setSelectedTotalAmount } from "../../redux/Hybridhoa";
function Dues() {
  const { token, homeId,selectedTotalAmount,remainingDueAmount } = useSelector((state) => state.hybridhoa);
  const [dueDetails, setDueDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const CustomCell = ({ cellData }) => {
    const [expanded, setExpanded] = useState(false);

    if (!cellData) return null;

    const content = expanded ? cellData : `${cellData.slice(0, 50)}`;

    return (
      <div style={{ wordBreak: 'break-word' }}>
        {content}
        {cellData.length > 50 && (
          <Button onClick={() => setExpanded(!expanded)}>
            {expanded ? "Show less" : "Show more"}
          </Button>
        )}
      </div>
    );
  };
  const getDueDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${USER_DUE}?homeId=${homeId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        console.log("total amount from dues.js page",data[0].dueAmount);
        console.log("checking type of from due.js", typeof data[0].dueAmount);
        const totalDueAmountStore = parseFloat(data[0].totalAmount);
        const remainingAmountStore = parseFloat(data[0].dueAmount)
        
        console.log("totalDueAmountStore from due.js", typeof totalDueAmountStore);
        dispatch(setSelectedTotalAmount(totalDueAmountStore));
        dispatch(setRemainingDueAmount(remainingAmountStore));
        
        
        
        setDueDetails(data);
        console.log("data", data);
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDueDetails();
  }, [homeId]);

  const documentTable = useMemo((rowData) => [
    {
      accessorKey: "dueType",
      header: "Duetype",
      size: 20,
    },
    {
      accessorKey: "title",
      header: "Title",
     
      Cell: ({ cell }) => <CustomCell cellData={cell.getValue()} />,
    },

    {
      accessorKey: "description",
      header: "Due Description",
      size: 20,
      Cell: ({ cell }) => <CustomCell cellData={cell.getValue()} />,
    },
   
    {
      accessorKey: "dueDate",
      header: "Due Date",
      accessorFn: (row) =>
        row.dueDate ? moment(row.dueDate).format("MM/DD/YYYY") : " ",
      size: 20,
    },

    {
      accessorKey: "endDate",
      header: "Last Date",
      accessorFn: (row) =>
        row.endDate ? moment(row.endDate).format("MM/DD/YYYY") : " ",
      size: 20,
    },
    {
      accessorKey: "dueAmount",
      header: "Amount",
      size: 20,
    },
    {
      accessorKey: "waivedOffAmount",
      header: "Waived off Amount",
      size: 20,
    },
  ]);

  return (
    <div>
      <CustomHeading title="User Due" />

      <div>
        <CustomMaterialTables columns={documentTable} data={dueDetails} />
      </div>
    </div>
  );
}

export default Dues;
