import React, { useState } from 'react';
import logo from "../../assets/img/logo.png";
import "../../App.css";
import { useForm, Controller } from "react-hook-form";
import { EMAIL_REGEX_PATTERN, EMAIL_REGEX_PATTERNS, ONLY_ALPHABETIC } from '../../utills/ApplicationRegex';
import { EMAIL_INVALID_MESSAGE, EMAIL_REQUIRED_MESSAGE, FIRST_NAME_INVALID_MESSAGE, FIRST_NAME_REQUIRED_MESSAGE, LAST_NAME_INVALID_MESSAGE, LAST_NAME_REQUIRED_MESSAGE, MAX_LENGTH_VALIDATE_MESSAGE, MIN_LENGTH_VALIDATE_MESSAGE, PASSWORD_REQUIRED_MESSAGE, PHONE_REQUIRED_MESSAGE } from '../../utills/ApplicationConstants';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setRoles, setToken, setUserDetails, setUserLoginDetails } from '../../redux/Hybridhoa';
import { BASE_URL, LOGIN, REQUEST_FOR_ACCESS } from '../../utills/ApplicationRouting';
import axios from 'axios';
import CustomInputField from '../../components/CustomInputField';
import CustomButton from '../../components/CustomButton';
import CustomPhoneInput from '../../components/CustomPhoneInput';
import { request } from '../../services/AxiosConfig';
import CustomToastContainer from '../../components/CustomToastContainer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isValidPhoneNumber } from 'react-phone-number-input';
import CustomLoading from '../../components/CustomLoading';

const RequestForAccess = () => {
  const { token } = useSelector(state => state.hybridhoa);
  const dispatch = useDispatch();
  const storedToken = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();



  const {
    handleSubmit,
    watch,
    control,clearErrors,
    formState: { errors },setError,
  } = useForm();

  const onSubmit = async (data) => {
    const jsonFormData = {
      firstName: data.firstName,
      lastName: data.lastName,
      contact: data.phoneNumber,
      email: data.email,
    };
    const isValidPhoneNumberForCountry = isValidPhoneNumber(data.phoneNumber);

    if (!isValidPhoneNumberForCountry) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);

    axios.post(`${BASE_URL}${REQUEST_FOR_ACCESS}`, jsonFormData)
      .then((res) => {
        console.log(res);
        const message=res.data
        console.log("first",message)
        if (res.status === 200) {
                  setIsLoading(false);
                  toast.success(message, { position: "top-center" }); // Display success message
                 setTimeout(()=>{
                  navigate("/", { replace: true });
                 },4000)
                }
      })
      .catch((error) => {
        setIsLoading(false);
                  if (error.response && error.response.data) {
                    const message = error.response.data.message;
                    toast.error(message, { position: "top-center" });
                    console.log("error.response.status" + error.response.status);
                  } else {
                    console.error(error); // Log the entire error for further debugging
                    toast.error("An error occurred while processing your request.");
                  }
                });
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="w-full lg:w-1/2">
      <div className="mb-6 absolute top-3 left-1/2 transform -translate-x-1/2 md:left-36">
  <img className="w-44 h-14 mx-auto md:mx-0" src={logo} alt="logo" />
</div>
        <section className="">
          <div className="flex flex-col items-center justify-center mx-auto md:h-screen lg:py-0">
            <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0 ">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                
              <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 text-center text-size-20px">
                  Owner Portal
                </h1>
                <form
                  className="space-y-4 md:space-y-6"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {/* <ToastContainer /> */}
                  <CustomToastContainer />
                  {isLoading && <CustomLoading />}
                  <CustomInputField
                requiredErrorMessage={FIRST_NAME_REQUIRED_MESSAGE}
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={FIRST_NAME_INVALID_MESSAGE}
                // minLength={2}
                // minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldName="firstName"
                fieldId="firstName"
                fieldType="text"
                fieldLabel="First Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                
              />
              <CustomInputField
                fieldName="lastName"
                requiredErrorMessage={LAST_NAME_REQUIRED_MESSAGE}
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={LAST_NAME_INVALID_MESSAGE}
                // minLength={2}
                // minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldId="lastName"
                fieldType="text"
                fieldLabel="Last Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                
              />
                  <div>
                    <CustomInputField
                      fieldName="email"
                      requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
                      fieldPattern={EMAIL_REGEX_PATTERNS}
                      patternErrorMessage={EMAIL_INVALID_MESSAGE}
                      fieldId="login-email"
                      fieldType="text"
                      fieldLabel="Email"
                      fieldControl={control}
                      fieldError={errors}
                      fieldRequiredIcon="*"

                    />
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                  <Controller
  name="phoneNumber"
  control={control}
  rules={{ required: PHONE_REQUIRED_MESSAGE,
    validate: {
      isValidPhoneNumber: value => isValidPhoneNumber(value) || "Invalid phone number"
    } }}
  render={({ field, fieldState }) => (
    <>
      <CustomPhoneInput
        fieldControl={control}
        label="Phone Number"
        clearErrors={clearErrors}
        country="US"
        value={field.value} 
        onChange={(value) => field.onChange(value)} 
        error={fieldState.invalid ? fieldState.error : null}
        helperText={fieldState.invalid ? fieldState.error?.message : ''}
      />
    </>
  )}
/>
                  </div>
                  <div className="flex items-center justify-center">
                    <CustomButton
                      fieldType="submit"
                      buttonName="Submit"
                    />
                  </div>

                  <hr></hr>
                  <div className="flex items-center justify-center">
  <p className="text-sm mr-2">Already have login and password?</p>
  <span>
    <a
      href="/"
      className="text-sm font-medium text-primary-600 hover:underline text-blue-600"
    >
      Sign in
    </a>
  </span>
</div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default RequestForAccess;
