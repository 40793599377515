import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BASE_URL, EMAIL_VERIFY } from '../../utills/ApplicationRouting';
import { useParams } from 'react-router-dom';
import ColoredCircle from '../../components/coloredCircle'; // Make sure the import path is correct
import { useNavigate } from 'react-router-dom';
function EmailVerified() {
  const { token,tokenId } = useParams();
  const [status, setStatus] = useState('');
  let navigate = useNavigate();
  const [message,setMessage]=useState('')

  const getEmailVerification = async () => {
    try {
      if (token) {
        localStorage.removeItem("token");
         localStorage.removeItem("persist:root");

        dispatch(clearAuthToken());
        dispatch(setToken(null));
        return
      }
      const response = await axios.get(`${BASE_URL}${EMAIL_VERIFY}${tokenId}`, {});
      console.log(response.data);

      if (response.status === 200) {
        const statuss = response.status;
        setStatus(statuss);
        setMessage(response.data)
        setTimeout(()=>{
                  navigate('/')
        },5000)
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setMessage(error.response.data.message)
    }
  };

  useEffect(() => {
getEmailVerification();
  }, [tokenId]);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div>
        <ColoredCircle isSuccess={status === 200} />
      </div>
      <div className="mt-4">
        {status === 200 ? (
          <p className='text-xl font-bold'>{message}</p>
        ) : (
          <p className='text-xl font-bold'>{message}</p>
        )}
      </div>
    </div>
  );
}

export default EmailVerified;
