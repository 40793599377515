import React, { useEffect, useMemo, useState } from "react";
import CustomHeading from "../../../components/CustomHeading";
import CustomMaterialTable from "../../../components/CustomMaterialTable";
import { useNavigate ,useParams  } from "react-router-dom";
import {
  CancelOutlined,
  DeleteOutline,
  EditNoteOutlined,
  ListAlt,
} from "@mui/icons-material";
import CustomModel from "../../../components/CustomModel";
import { Controller, useForm } from "react-hook-form";
import CustomButton from "../../../components/CustomButton";
import CustomModelView from '../../../components/CustomModelView';
import { InfoOutlined } from "@mui/icons-material";

import {
  Autocomplete,
  DialogActions,
  DialogContent,
  FormControl,
  Card,
  Grid,
  Tooltip ,
  IconButton,
  TextField,
  Typography,FormControlLabel,Checkbox,
  CardContent,
} from "@mui/material";
import {
  ADDRESS_REQUIRED_MESSAGE,
  CATEGORY_INVALID_MESSAGE,
  CATEGORY_REQUIRED_MESSAGE,
  DESCRIPTION_REQUIRED_MESSAGE,
  EMAIL_INVALID_MESSAGE,
  EMAIL_REQUIRED_MESSAGE,
  EVENT_ADDRESS_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_HUNDRED,
  MAX_LENGTH_FIVE_THOUSAND,
  MAX_LENGTH_VALIDATE_MESSAGE,
  MIN_LENGTH_VALIDATE_MESSAGE,
  NAME_INVALID_MESSAGE,
  NAME_REQUIRED_MESSAGE,
  PHONE_REQUIRED_MESSAGE,
  PUBLISH_DATE_REQUIRED_MESSAGE,
  TOPIC_REQUIRED_MESSAGE,
} from "../../../utills/ApplicationConstants";
import CustomInputField from "../../../components/CustomInputField";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileDateTimePicker,
  StaticDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CustomCheckBox from "../../../components/CustomCheckBox";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  BASE_URL,
  CATEGORY,
  EVENT,
  EVENT_CANCEL,ARC_EVENT,
} from "../../../utills/ApplicationRouting";
import { request } from "../../../services/AxiosConfig";
import CustomToastContainer from "../../../components/CustomToastContainer";
import CustomAutoComplete from "../../../components/CustomAutoComplete";
import CustomLoading from "../../../components/CustomLoading";
import CustomAutoCompleteEdit from "../../../components/CustomAutoCompleteEdit";
import moment from "moment";
import CustomDatePicker from "../../../components/CustomDatePicker";
import CustomTooltipField from "../../../components/CustomTooltipField";
import { EMAIL_REGEX_PATTERN, ONLY_ALPHABETIC } from "../../../utills/ApplicationRegex";
import CustomPhoneInput from "../../../components/CustomPhoneInput";
import { isValidPhoneNumber } from "react-phone-number-input";


function McBmArcEditEvent() {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const minDate = dayjs().startOf("day");
  const maxDate = dayjs().add(1, "year").endOf("day");
  const currentDateTime = new Date();
  const { token, roles, userLoginDetails } = useSelector(
    (state) => state.hybridhoa
  );
  const [eventDetails, setEventDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRow] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [eventTime, setEventTime] = useState("");
  const [isCommunity, setIsCommunity] = useState(false);
  const [disabled, setdisabled] = useState(true);
  const [immediatedisabled, setimmediatedisabled] = useState(true);
  const [emailNotified, setEmailNotified] = useState(false);
  const [customDate, setCustomDate] = useState(null);
  const [isCustomeChecked, setIsCustomeChecked] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [ImmediateModalOpen, setImmediateModalOpen] = useState(false);
  const handleEventChange = (selectedFromDate) => {
    setEventTime(selectedFromDate);
    setdisabled(false);
    setCustomDate(null);
    setIsCustomeChecked(false);
    setIsImmediateChecked(false);
  };
  const [updateRes, setUpdateRes] = useState([]);
  const selectedRow = updateRes;
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [topicData, setTopicData] = useState("");
  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [descriptionData, setDescriptionData] = useState("");
  const [eventTimeData, setEventTimeData] = useState("");
  const [eventAddress, seteventAddress] = useState("");
  const [communityData, setCommunityData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [crId, setCrId] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [publishDate, setPublishDate] = useState("");
  const [publishDateData, setPublishDateData] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [checked, setChecked] = useState(false);
  const [notforcommunitychecked, setNotforcommunitychecked] = useState(false);
  const [confirmationOpenEdittwo, setConfirmationOpenEdittwo] = useState(false);
  const [showError, setShowError] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
 const [confirmationOpenEdit, setConfirmationOpenEdit] = useState(false);
 const [isImmediateChecked, setIsImmediateChecked] = useState(false);

 const [reasonForCancel, setReasonForCancel] = useState('');

 const handleReasonForCancelChange = (event) => {
   setReasonForCancel(event.target.value);
 };

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const {
    handleSubmit:handleSubmitCommunityEvent,
    
  } = useForm();

  const handlePublishDateChange = (selectedPublishDate) => {
    setPublishDate(selectedPublishDate);
    console.log("hi");
  };

  const handleEditPublish = (selectedPublishData) => {
    setPublishDateData(selectedPublishData);
  };

  const handleViewClick = (selectedRow) => {
    navigate(-1);
  };



  const handleEditClose = (status) => {
    if (status == 200) {
      setOpenEdit(false);
    }
  };
  const handleConfirmationCloseEdit = () => {
    setConfirmationOpenEdit(false);
    setIsModalOpen(false); 
    setCommunityData(false)
  };
  const handleConfirmationCloseEditTwo = () => {
    setConfirmationOpenEdittwo(false);
    setIsModalOpen(false); 
    setCommunityData(false)
  };
  const handleConfirmationConfirmEdit = () => {
    setChecked(true);
    setConfirmationOpenEdit(false);
    setIsModalOpen(false);
    setCommunityData(true)
    setNotforcommunitychecked(false);
    setShowError(false);
  };

  const handleConfirmationConfirmEditTwo = () => {
    setShowError(false);
    setNotforcommunitychecked(true);
    setConfirmationOpenEdittwo(false);
    setIsCommunity(false);
    setChecked(false);
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
    setIsModalOpen(false); 
    setIsCommunity(false)
  };
 
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };
  const handleImmediateCheckbox = (e) => {
    setIsImmediateChecked(e.target.checked);
    if (e.target.checked) {
      setCustomDate(dayjs());
      setIsCustomeChecked(false);
      setIsDatePickerOpen(false);
      setImmediateModalOpen(false);
    }else{
      setImmediateModalOpen(true);
      setCustomDate(null);
    }
  };
  const handleCustomeCheckbox = (e) => {
    
    if (e.target.checked) {
      
      setIsDatePickerOpen(true);
    } else {
      setIsCustomeChecked(false);
      setIsDatePickerOpen(false);
      setCustomDate(null);
    }
  };
  const handleCancel = (date) => {
    setIsDatePickerOpen(false);

  };
  const handleAccept = (date) => {
    setIsImmediateChecked(false);
    setCustomDate(date);
    setIsDatePickerOpen(false);
    setIsCustomeChecked(true);
  };
  const handleEditname = (e) => {
    setname(e.target.value);
  };
  const handleEditemail = (e) => {
    setemail(e.target.value);
  };

  const getCategory = async () => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      console.log(response.data)

      if (response.status === 200) {
        const allCategories = response.data;

       if (roles=="ROLE_BM"){
        const filteredCategories = allCategories.filter((category) => {
          return (
            (category.type === "EV" && category.isEventForArc === false )
          );
        });
        console.log("filteredCategories",filteredCategories);
        setCategoryDetails(filteredCategories);
      
        
        setIsLoading(false);
        const categoryIds = filteredCategories.map(
          (category) => category.categoryId
        );

        categoryIds.forEach((singleCategoryId) => {
          setCategoryId(singleCategoryId);
        });
        setCategoryData(filteredCategories);
      }else if (roles=="ROLE_ARC"){
        const filteredCategories = allCategories.filter((category) => {
          return (
            (category.type === "EV" && category.isEventForArc === true )
          );
        });
        console.log("filteredCategories",filteredCategories);
        setCategoryDetails(filteredCategories);
      
        
        setIsLoading(false);
        const categoryIds = filteredCategories.map(
          (category) => category.categoryId
        );

        categoryIds.forEach((singleCategoryId) => {
          setCategoryId(singleCategoryId);
        });
        setCategoryData(filteredCategories);
      }
      else if (roles=="ROLE_MC"){
        const filteredCategories = allCategories.filter((category) => {
          return (
            (category.type === "EV" && category.isEventForArc === true )
          );
        });
        console.log("filteredCategories",filteredCategories);
        setCategoryDetails(filteredCategories);
        console.log("fielst s",categoryDetails);
        
      
        
        setIsLoading(false);
        const categoryIds = filteredCategories.map(
          (category) => category.categoryId
        );

        categoryIds.forEach((singleCategoryId) => {
          setCategoryId(singleCategoryId);
        });
        setCategoryData(filteredCategories);
      }
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getEventDetailsById = async (id) => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${EVENT}/${id}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        console.log(response.data);
        setUpdateRes(data.eventId);
        setTopicData(data.topic);
        // setValue("Name",data.name);
       
        setDescriptionData(data.description);
        seteventAddress(data.eventAddress);
        if(data.notificationDate !=null){
        const customdate = dayjs(data.notificationDate, {
          format: "YYYY-MM-DD",
        });
        setCustomDate(customdate);
        }
        const eventTime = dayjs(data.dateAndTime, {
          format: "YYYY-MM-DD HH:mm:ss",
        });
        setEventTimeData(eventTime);
        console.log(eventTime);
        if(eventTime != null){
        setdisabled(false);
        

        }
        setimmediatedisabled(false);
        const today = dayjs();
        if(data.notificationDate!=null || data.isNotifyNow===true){
        const date = dayjs(data.notificationDate);
        
        if(((date.isBefore(today, 'day') || date.isSame(today, 'day'))) || data.isNotifyNow===true){
          console.log("dissabled ");
        
          setEmailNotified(true);
          setdisabled(true);
          setimmediatedisabled(true);

        
      }
    }

        if(data.isCommunityEvent===true){
          setname(data.name);
          setemail(data.email);
          setValue("phoneNumber",data.contact);
          setChecked(true)
          setNotforcommunitychecked(false)
        }else{

          setChecked(false)
          setNotforcommunitychecked(true)
        }
        setIsImmediateChecked(data.isNotifyNow);
        if(data.isNotifyNow == false && data.notificationDate !=null){
        setIsCustomeChecked(true);

        }

        // setCommunityData(data.isCommunityEvent);
        setCrId(response.data.crId);
        setIsLoading(false);
        setPublishDateData(dayjs(data.publishDate));
        console.log("category",categoryDetails);
        // console.log(filteredCategories);
        const matchingCategory = categoryDetails.find((category) => category.categoryId === data.category);
        console.log("matchingCategory", matchingCategory);
        console.log("categoryDetails", categoryDetails);
        if(matchingCategory){
          setSelectedCategory(matchingCategory.category)
        console.log('category', matchingCategory.categoryId)
          setValue('editcategory', matchingCategory.categoryId)
        }else{
          setSelectedCategory('')
          setValue('editcategory', '')
        }   

      } else {
        setIsLoading(false);

        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);

      console.log(error);
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  const handleCancelClose = () => {
    setCancelOpen(false);
  };


  const handleEditTopic = (e) => {
    setTopicData(e.target.value);
  };

  const handleEditDescription = (e) => {
    setDescriptionData(e.target.value);
  };
  const handleEditAddress = (e) => {
    seteventAddress(e.target.value);
  };
  const handleEditEventDate = (selectedEventTime) => {
    console.log(selectedEventTime);
    const now = dayjs();
    if (selectedEventTime.isSame(now, 'day')) {
      // Set the current time if the selected date is today
      const updatedDate = selectedEventTime.set('hour', now.hour()).set('minute', now.minute()+2);
      setEventTimeData(updatedDate);
      console.log("date is today ");
      if(!emailNotified){
    setCustomDate(null);
    setIsCustomeChecked(false);
    setIsImmediateChecked(false);
      }
    }
    console.log("date is nott today ");
  };

  const handleEventDateAccept = (date) => {
    const now = dayjs();
    if (date.isBefore(now)) {
      setError("editselectedDate", {
        type: "manual",
        message: "Please select a date and time that is not in the past",
      });
    setEventTime("");
  } else {
    setEventTimeData(date);
    if(!emailNotified && date.isBefore(customDate)){
    // setdisabled(false);
    
    setCustomDate(null);
    setIsCustomeChecked(false);
    setIsImmediateChecked(false);
    }
    // else if(date.isBefore(customDate)){
    //   // setdisabled(false);
    //   console.log("the custome date is befour datee");
    //   setCustomDate(null);
    //   setIsCustomeChecked(false);
    //   setIsImmediateChecked(false);
    //   }
    clearErrors("editselectedDate");
  }
};


  const handleConfirmationConfirm = () => {
    setChecked(true);
    setConfirmationOpen(false);
    setIsModalOpen(false);
    setIsCommunity(true)
  };

  const handleCheckboxChangeForEventEdit = () => {
    if (!checked) {
      setConfirmationOpenEdit(true);
      setIsModalOpen(true); // Set isModalOpen to true when opening the modal
    } else {
      // setemail("");
      // setname("");
      // setValue("phoneNumber","");
      setChecked(false);
    }
  };
 
  const handleCheckboxChange = () => {
    // console.log("hiiiii"+selectedOption);
    if (!notforcommunitychecked) {
      console.log("hiiiii 2");
      setConfirmationOpenEdittwo(true);

    } else {
      console.log("hiiiii 3");
      setNotforcommunitychecked(false);
    }
  };




  const eventCardModelActionEdit = (
    <>
      <CustomButton
        buttonName="Cancel"
        click={handleConfirmationCloseEdit}

      />
      <CustomButton
        fieldType="submit"
        buttonName="OK"
        click={handleConfirmationConfirmEdit}
      />

    </>
  );
  const eventCardModelActionEditTwo = (
    <>
      <CustomButton
        buttonName="Cancel"
        click={handleConfirmationCloseEditTwo}

      />
      <CustomButton
        fieldType="submit"
        buttonName="OK"
        click={handleConfirmationConfirmEditTwo}
      />

    </>
  );
  const EventCardContentEdit = (
    <>
      <form onSubmit={handleSubmitCommunityEvent(handleConfirmationConfirmEdit)}>
        <DialogContent dividers>
          <div className="flex mb-5 mt-5">Are you sure you want to send this event to all homeowners?</div>
        </DialogContent>


        <DialogActions>{eventCardModelActionEdit}</DialogActions>
      </form>
    </>)
 const EventCardContentEditTwo = (
  <>
    <form onSubmit={handleSubmitCommunityEvent(handleConfirmationConfirmEditTwo)}>
      <DialogContent dividers>
        <div className="flex mb-5 mt-5">Are you sure you want to send this event to the board?</div>
      </DialogContent>


      <DialogActions>{eventCardModelActionEditTwo}</DialogActions>
    </form>
  </>)


  useEffect(() => {
    getCategory();
    getEventDetailsById(eventId);
    
  }, []);

  // const getCategory = async () => {
  //   try {
  //     const storedToken = token;

  //     const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
  //       headers: {
  //         Authorization: `Bearer ${storedToken}`,
  //       },
  //     });

  //     if (response.status === 200) {
  //       const allCategories = response.data;

  //       // Filter categories based on type (e.g., 'EV')
  //       const filteredCategories = allCategories.filter(category => category.type === 'EV');

  //       setCategoryDetails(filteredCategories); // Set the filtered data to the state
  //       setIsLoading(false);
  //       setCategoryData(filteredCategories);
  //     } else {
  //       throw new Error("Failed to fetch data");
  //     }
  //   } catch (error) {
  //     // Handle error
  //     console.error("Error fetching data:", error);
  //   }
  // };


  // useEffect(() => {
  //   getCategory();
  // }, []);


  // const getCategory = async () => {
  //   try {
  //     const storedToken = token;

  //     const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
  //       headers: {
  //         Authorization: `Bearer ${storedToken}`,
  //       },
  //     });
  //     console.log(response.data);

  //     if (response.status === 200) {
  //       const data = response.data;
  //       setCategoryDetails(data); // Set the fetched data to the state
  //       setIsLoading(false);
  //       setCategoryData(data);
  //     } else {
  //       throw new Error("Failed to fetch data");
  //     }
  //   } catch (error) {
  //     // Handle error
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // useEffect(() => {
  //   getCategory();
  // }, []);


  const handleEditCategoryChange = (event, newValue) => {
    if (newValue) {
      const selectedCategoryId = newValue.value;
    
      // Set the selected homeId to the state
      setValue("editcategory", selectedCategoryId);
    
      // You can also do other operations if needed
    
      console.log("selectedCategoryId:", selectedCategoryId);
    } else {
      setValue("editcategory", null); // Reset to null if no category is selected
    }
  };



  useEffect(() => {
    // getCategory();
    console.log("category",categoryDetails);
    getEventDetailsById(eventId);
  }, [categoryDetails]);

  const renderedCategory = useMemo(() => {
    console.log("Category Details:", categoryDetails);
    const options = categoryDetails.map((category, index) => ({
      category: category.category,
      value: category.categoryId,
      key: index,
    }));
    console.log("Rendered Category:", options);
    return options;
  }, [categoryDetails]);

  const onSubmitDelete = () => {
    setIsLoading(true);

    const EventId = selectedRows.eventId;
    console.log(selectedRows.eventId);
    // return;
    request({ url: `${EVENT}/${EventId}`, method: "delete" })
      .then((res) => {
        console.log(res);
        getEventDetails();
        setIsLoading(false);

      })
      .catch((error) => {
        setIsLoading(false);

        console.log(error);
      });
  };




  

  const onSubmitCancel = (data) => {
    const EventId = selectedRows.eventId;
    setIsLoading(true);
    // console.log(data.reasonforcancel);
    // console.log(FormControl[reasonforcancel].value);/
    const postData={
      reason:data.reasonforcancel
    }
    console.log(postData);
    request({ url: `${EVENT_CANCEL}/${EventId}`, method: "post", data: postData })
      .then((res) => {
        console.log(res);
        getEventDetails();
        setIsLoading(false);

      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };


  const onSubmitUpdate = (data) => {
    if (!topicData) {
      setError("edittopic", {
        type: "manual",
        message: TOPIC_REQUIRED_MESSAGE,
      });
      return;
    }
    if (!descriptionData) {
      setError("editdescription", {
        type: "manual",
        message: DESCRIPTION_REQUIRED_MESSAGE,
      });
      return;
    }
    if (!eventAddress) {
      setError("eventAddress", {
        type: "manual",
        message: EVENT_ADDRESS_REQUIRED_MESSAGE,
      });
      return;
    }
    if(checked){
      console.log("checked "+JSON.stringify(data));
      if (!name.trim()) {
        setError("Name", {
          type: "manual",
          message: NAME_REQUIRED_MESSAGE,
        });
        setIsLoading(false);
        return;
      }
      if (!email) {
        setError("email", {
          type: "manual",
          message: EMAIL_REQUIRED_MESSAGE,
        });
        setIsLoading(false);
        return;
      }
      if (!data.phoneNumber) {
        setError("phoneNumber", {
          type: "manual",
          message: PHONE_REQUIRED_MESSAGE
        });
        setIsLoading(false);
        return;
      }
  
      if (!isValidPhoneNumber(data.phoneNumber)) {
        setError("phoneNumber", {
          type: "manual",
          message: "Invalid phone number"
        });
        setIsLoading(false);
        return;
      }
  }
  if(!eventTimeData){
    setError("eventTimeData", {
      type: "manual",
      message: "Event Date and time is Required"
    });
    setIsLoading(false);
    return;
  }

    // if (dayjs(publishDateData).isBefore(dayjs(eventTimeData))) {
    //   // Publish date is earlier than event date
    //   // alert("Publish Date cannot be earlier than Date & time of event.");
    //   setIsLoading(false);
    //   setError("publishdate", {
    //     type: "manual",
    //     message: "Publish Date cannot be earlier than Date & time of event.",
    //   });
    //   return;
    // }
    // if (dayjs(publishDateData).isAfter(dayjs(eventTimeData))) {
    //   // Publish date is after the event date
    //   setIsLoading(false);
    //   setError("publishdate", {
    //     type: "manual",
    //     message: "Publish Date cannot be later than the Date & time of event.",
    //   });
    //   return;
    // }
    // const publishDate = dayjs(publishDateData);
    const currentDate = dayjs();

    // if (publishDate.isBefore(currentDate, 'day')) {
    //     setError("publishdate", {
    //         type: "manual",
    //         message: "Please select a future date",
    //     });
    //     setIsLoading(false);
    //     return;
    // }
    if(!checked && !notforcommunitychecked){
      console.log("in the conditoin");
      setShowError(true);
      // setErrorMessage("Event is Required");
      setIsLoading(false);
      return;
    }
    console.log("hii hema one "+topicData);

    if (topicData == " " || topicData == null) {
      console.log("hii hema");
      setError("edittopic", {
        type: "manual",
        message: TOPIC_REQUIRED_MESSAGE,
      });
      return;
    }

    if (topicData.trim() == "" || topicData == null) {
      setError("edittopic", {
        type: "manual",
        message: TOPIC_REQUIRED_MESSAGE,
      });
      return;
    }

    if (descriptionData.trim() == "" || descriptionData == null) {
      setError("editdescription", {
        type: "manual",
        message: DESCRIPTION_REQUIRED_MESSAGE,
      });
      return;
    }
    if (eventAddress.trim()== "" || eventAddress == null) {
      setError("eventAddress", {
        type: "manual",
        message: EVENT_ADDRESS_REQUIRED_MESSAGE,
      });
      return;
    }
    const now = dayjs();
    if (dayjs(eventTimeData).isBefore(now)||!dayjs(eventTimeData).isValid() ) {
      console.log("my congition");
      setError("editselectedDate", {
        type: "manual",
        message: "Please select a date and time that is not in the past",
      });

      return;
    // setEventTime("");
  } 

    setIsLoading(true);
    console.log("customeDate"+customDate);

   const putData = {
      topic: topicData,
      description: descriptionData,
      // category: crId,
      dateAndTime: dayjs(eventTimeData).format("YYYY-MM-DD HH:mm:ss"),
      isCommunityEvent: checked,
      role: roles[0],
      category:data.editcategory,
      isNotifyNow :isImmediateChecked,
      // publishDate: dayjs(publishDate).format("YYYY-MM-DD"),
      notificationDate: customDate ? customDate.format("YYYY-MM-DD HH:mm:ss") : null,
      eventAddress:data.eventAddress,
      email: checked ? email : null,
      name: checked ? name : null,
      contact: checked ? data.phoneNumber : null,
    };


    console.log(data.topic);
    console.log(data.description);
    console.log(selectedCategoryId);
    console.log(dayjs(eventTime).format("YYYY-MM-DD HH:mm:ss"));
    console.log(isCommunity);

    request({ url: `${EVENT}/${selectedRow}`, method: "put", data: putData })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          setIsLoading(false);
          handleEditClose(res.status);
                setemail("");
      setname("");
      setValue("phoneNumber","");
          setTimeout(() => {
            navigate(-1);
          }, 3000); 
        }else{
          setIsLoading(false);

        }
      })
      .catch((error) => {
        setIsLoading(false);

        console.log(error.response);
        if (error.response) {
          setIsLoading(false);

          console.log("error.response.status" + error.response.status);
        }
      });
  };






  const CancelButtonActions = (
    <>
    {/* <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleCancelClose}
      /> */}
      <CustomButton
        fieldType="submit"
        buttonName="Submit"
        click={handleCancelClose}
      />
    </>
  );

  const cancelModel = (
    <form
      onSubmit={handleSubmit(onSubmitCancel)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
      <Typography gutterBottom>
            <CustomInputField
              fieldName="reasonforcancel"
              requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
              fieldId="reasonforcancel"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={5000}
              maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
              fieldLabel="Reason for cancel"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
              onChange={handleReasonForCancelChange}
            />
          </Typography>
        {/* <Typography> Are you sure you want to cancel this event ?</Typography> */}
      </DialogContent>
      <DialogActions>{CancelButtonActions}</DialogActions>
    </form>
  );



  

  const UpdateActionButton = (
    <>
    <div class="text-center mt-10">
    <CustomButton
      fieldType="submit"
      buttonName="Update"
      click={handleEditClose}
    >
    </CustomButton>
    {isLoading && <CustomLoading />}
    </div>
    </>
  );
 

  return (
    <div>
         <div class="flex flex-col md:flex-row">
        <CustomHeading title="Edit Event" />
        <CustomToastContainer />

        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleViewClick}
          />
        </div>
      </div>
        <Card>
        <form
        onSubmit={handleSubmit(onSubmitUpdate)}
        className="space-y-4 md:space-y-6 "
      >
        
        <CardContent>
        <CustomHeading title="Event Details" />
        <text>Choose Your Event: <span className='text-red-500'>*</span></text>
          <div onClick={(e) => e.stopPropagation()}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleCheckboxChangeForEventEdit}
                        color="primary"
                        size="small"
                        title="Check to share event with everyone. Boost community spirit by making it visible to all"
                      />
                    }
                    label={<span style={{ fontSize: '14px' }}>Community Event </span>}
                  />
                <FormControlLabel
        control={
          <Checkbox
            checked={notforcommunitychecked}
            onChange={handleCheckboxChange}
            name="notCommunityEvent"
            color="primary"
            size="small"
            title="Check this if the event is not for the community"
          />
        }
        label={<span style={{ fontSize: '14px' }}>Board Event</span>}
      />
      </div>
      {/* {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>} */}

        {showError && (
       <label>
       {<span style={{ color: "#d32f2f", fontSize: '12px' }}> Please choose a Event </span>}
     </label> )}
                <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
          <Typography gutterBottom>   
            <CustomInputField
              fieldName="edittopic"
              fieldId="edittopic"
              // requiredErrorMessage={TOPIC_REQUIRED_MESSAGE}
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={500}
              maxLengthErrorMessage={MAX_LENGTH_FIVE_HUNDRED}
              fieldLabel="Topic of Event"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              value={topicData}
              multiline={true}
              onInput={handleEditTopic}
              tooltipMessage=" Provide a Topic of the Event"
            />

            <CustomInputField
              fieldName="editdescription"
              // requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
              fieldId="editdescription"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={5000}
              maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
              fieldLabel="Description"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
              value={descriptionData}
              onInput={handleEditDescription}
              tooltipMessage={"Provide a detailed description of the Event"}
            />


            <CustomAutoCompleteEdit
              fieldName="editcategory"
              requiredErrorMessage={CATEGORY_REQUIRED_MESSAGE}
              id="selectId"
              options={renderedCategory}
              fieldLabel="Select Category"
              fieldControl={control}
              error={errors}
              setValue={setValue}
              fieldRequiredIcon="*"
              value={selectedCategory}
              tooltipMessage={"Please select the category relevant to the Event."}
              onChange={handleEditCategoryChange}
              invalidOption={CATEGORY_INVALID_MESSAGE}
            />


            
<div className="flex items-center w-full md:w-1/2">
        
    <div className="flex  w-full ">
            <Controller
              control={control}
              name="editselectedDate"
              render={({ field }) => (
                <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDateTimePicker
                  {...field}
                  minDate={minDate}
                  maxDate={maxDate}
                  value={eventTimeData}
                  disablePast={true}
                  onChange={(date) => {
                    handleEditEventDate(date);
                    field.onChange(date); // Manually trigger onChange for React Hook Form
                  }}
                  onAccept={(date) => {
                    handleEventDateAccept(date);
                    field.onChange(date); // Manually trigger onChange for React Hook Form
                  }}
                  slotProps={{
                    textField: {
                      variant: "standard",
                      label: (
                        <span>
                          Date & time of event
                          <span style={{ color: "red" }}> *</span>
                        </span>
                      ),
                      error: !!errors.editselectedDate,
                      helperText: errors.editselectedDate ? errors.editselectedDate.message : "",
                    },
                  }}
                />
                </LocalizationProvider>
          </FormControl>
              )}

              // rules={{ required: 'Date & time is required' }} // Add validation rules
            />
          </div>
     
        <Tooltip
          title={<Typography sx={{ fontSize: "14px" }}>Please Provide Event Date and time </Typography>}
          placement="right"
          arrow
        >
          <IconButton size="small">
            <InfoOutlined />
          </IconButton>
        </Tooltip>
    </div>
         {/* <Controller
              control={control}
              name="editselectedDate"
              // rules={{ required: "Date is required" }}
              render={({ field, fieldState }) => (
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDateTimePicker
                      {...field}
                      value={eventTimeData}
                      minDate={minDate}
                      maxDate={maxDate}
                      disablePast={true}
                      // ampm={false}
                      onChange={handleEditEventDate}
                      slotProps={{
                        textField: {
                          variant: "standard",
                          label: (
                            <span>
                              Date & time of event 
                              <span style={{ color: "red" }}> *</span>
                            </span>
                          ),
                          error: fieldState.invalid,
                          helperText: fieldState.invalid
                            ? fieldState.error?.message
                            : "",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              )}

            /> */}


                <CustomInputField
              fieldName="eventAddress"
              
              fieldId="eventAddress"
              minLength={2}
              // requiredErrorMessage={EVENT_ADDRESS_REQUIRED_MESSAGE}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={500}
              maxLengthErrorMessage={MAX_LENGTH_FIVE_HUNDRED}
              fieldLabel="Event Address"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              value={eventAddress}
              numberOfRows={2}
              onInput={handleEditAddress}
              tooltipMessage={"Please provide the Address of the event."}
            />
{/* <br/>
<text>Email Notification (Optional)</text>
<br/>
<Tooltip title={emailNotified ? "Email notification has been already sended " : ""} disableHoverListener={!emailNotified}>
<FormControlLabel
                    control={
                      <Checkbox
                        disabled={immediatedisabled}
                        checked={isImmediateChecked}
                        onChange={handleImmediateCheckbox}
                        color="primary"
                        size="small"
                      />
                    }
                    label={<span style={{ fontSize: '14px' }}>Immediate</span>}
                  />
                  
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={disabled}
                        checked={isCustomeChecked}
                        onChange={handleCustomeCheckbox}
                        color="primary"
                        size="small"
                       
                        inputProps={{
                          'aria-label': 'Checkbox A',
                        }}
                      />
                    }
                    label={<span style={{ fontSize: '14px' }}>Custome Date</span>}
                  />
                  </Tooltip>
<div className="flex items-center w-full md:w-1/2">
        <div className="flex  w-full ">
        <FormControl fullWidth>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          minDate={minDate}
          maxDate={eventTimeData}
          disablePast
          open={isDatePickerOpen}
          onAccept={handleAccept}
          onClose={handleCancel}
          value={customDate}
          slotProps={{
            textField: {
              variant: "standard",
              disabled: true,
              label: (
                <span>
                  Custom Date 
                </span>
              ),
            },
          }}
        />
      </LocalizationProvider>
    </FormControl>
    </div>
    </div> */}
    {checked &&
            <div>
          <CustomHeading title="Contact Details" />
          <CustomTooltipField
                fieldName="Name"
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={NAME_INVALID_MESSAGE}
                // requiredErrorMessage={NAME_REQUIRED_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldId="Name"
                fieldType="text"
                fieldLabel="Name"
                fieldControl={control}
                fieldError={errors}
                // setValue={setValue}
                fieldRequiredIcon="*"
                value={name}
                onInput={handleEditname}
                tooltipMessage=" Update your name here. This is how we'll address you in communications and personalize your experience on our platform"
              />
              
              <CustomInputField
                fieldName="email"
                fieldPattern={EMAIL_REGEX_PATTERN}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                // requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
                fieldId="email"
                fieldType="text"
                fieldLabel="Email"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={email}
                onInput={handleEditemail}
                tooltipMessage=" your current email address. This is how we'll communicate with you and keep you informed about important updates and notifications"
              />


              <Controller
                name="phoneNumber"
                control={control}
                
                render={({ field, fieldState }) => (
                  <>
                    <CustomPhoneInput
                      fieldControl={control}
                      label="Phone Number"
                      clearErrors={clearErrors}
                      country="US"
                      value={field.value}
                      tooltipMessage=" Enter your current Phone number. This is how we'll communicate with you and keep you informed about important updates and notifications"
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      error={fieldState.invalid ? fieldState.error : null}
                      helperText={fieldState.invalid ? fieldState.error?.message : ''}
                      fieldError={errors}
                    />                


                  </>
                )}
              />
              </div>}
    </Typography>
        </section>
         
        
        {/* <DialogActions>{UpdateActionButton}</DialogActions> */}
        <div class="text-center mt-10">
    <CustomButton
      fieldType="submit"
      buttonName="Update"
      click={handleEditClose}
    >
    </CustomButton>
    {isLoading && <CustomLoading />}
    </div>
    </CardContent>
      </form>
        </Card>
        <CustomModelView
        title="Community Event"
        content={EventCardContentEdit}
        action={eventCardModelActionEdit}
        openStatus={confirmationOpenEdit}
        closeStatus={handleConfirmationCloseEdit}
        iconCloseStatus={handleConfirmationCloseEdit}
      />
      <CustomModelView
        title="Board Event"
        content={EventCardContentEditTwo}
        action={eventCardModelActionEditTwo}
        openStatus={confirmationOpenEdittwo}
        closeStatus={handleConfirmationCloseEditTwo}
        iconCloseStatus={handleConfirmationCloseEditTwo}/>
    </div>
  );
}
export default McBmArcEditEvent;
