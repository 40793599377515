import CustomViewPages from "../../components/CustomViewPages";
import { useNavigate, useParams } from "react-router-dom";
import { useMemo, useState,useEffect } from "react";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import moment from "moment/moment";
import axios from "axios";
import { BASE_URL, BASE_URL_IMAGE, COMPLIANCE_REQUEST_BY_ID, GENERAL_COMMENTS, GET_ALL_GENERAL_COMMENTS, GROUP_CONFIG } from "../../utills/ApplicationRouting";
import { useSelector } from "react-redux";
import CustomLoading from "../../components/CustomLoading";
import { pdf } from '@react-pdf/renderer';
import PDFDocument from "../../components/Templatepdf";
import CustomPascalChange from "../../components/CustomPascalChange";

import { DeleteOutline, EditNoteOutlined } from '@mui/icons-material';
import {
  Controller,
  FormProvider,
  useForm,
  useFormState,
} from "react-hook-form";
import { COMMENTS_REQUIRED_MESSAGE, MAX_LENGTH_THOUSAND, MIN_LENGTH_VALIDATE_MESSAGE } from "../../utills/ApplicationConstants";
import CustomButton from "../../components/CustomButton";
import { Button, Card, CardContent, DialogActions, DialogContent, Divider, Tooltip, Typography } from "@mui/material";
import CustomHeading from "../../components/CustomHeading";
import CustomModel from "../../components/CustomModel";
import CustomInputField from "../../components/CustomInputField";
import { request } from "../../services/AxiosConfig";
import CustomToastContainer from "../../components/CustomToastContainer";
import CustomReviewTable from "../../components/CustomReviewTable";
import AttachmentList from "../../components/AttachmentList";
import CustomViewWithExport from "../../components/CustomViewWithExport";
import CustomViewReportHeading from "../../components/CustomViewReportHeading";
import CustomViewWithTooltip from "../../components/CustomViewWithTooltip";
import AttachmentListsView from "../../components/AttachmentListsView";
import CustomViewWithMore from "../../components/CustomViewWithMore";
function ArcMcBmHomeActivityCrView() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };
  const { token, userLoginDetails, roles ,userDetails} = useSelector(
    (state) => state.hybridhoa
  );
    const {
    handleSubmit: handleSubmitGeneral,
    control: controlGeneral,
    formState: { errors: errorsGeneral },
    setValue: setValueGeneral,
    setError: setErrorGeneral,
    reset: resetGeneral,
    watch: watchGeneral,
  } = useForm();
  const [generalCommentsDataAdd, setGeneralCommentsDataAdd] = useState("");
  const [generalCommentDetails, setGeneralCommentDetails] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [generalCommentsData,setGeneralCommentsData] = useState('');
  const [commentsId,setCommentsId] = useState('');
  const [isLoading,setIsLoadings]=useState(false)
  const [editModes, setEditModes] = useState(
    Array.isArray(generalCommentDetails)
      ? Array(generalCommentDetails.length).fill(false)
      : []
  );
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };
  const [fineApply,setFineApply] = useState("");
  const [showFullDescription, setShowFullDescription] = useState(false);
  const HomeLoginId= userDetails.homeOwnerLoginId;
  const [includeComments, setIncludeComments] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [createdDateAndTime, setCreatedDateAndTime] = useState("");
  const {complainceId}=useParams()
  const [complainceDetails,setComplainceDetails]=useState('')
  const [reviewComments,setReviewComments]=useState([])
  const [loading,setIsLoading]=useState("")
  const [fileImage,setFileImage]=useState([])
  const [groupConfigurationDetails,setGroupConfigurationDetails]=useState([])
  const [logoUrl, setLogoUrl] = useState('');
  const [imageBlob,setImageBlob]=useState('');
  const[footerAdress,setFooterAdress]=useState('')
  const [filePdf,setFilePdf]=useState("");
const[adresspdf,setAdressPdf]=useState("")
  const[groupAdress,setGroupAdress]=useState('')
  const getGroupConfigurationDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GROUP_CONFIG}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        setGroupConfigurationDetails(data);
        const logoConfig = data.find(config => config.topic === 'Logo');
        console.log(logoConfig);
        if (logoConfig) {
          setLogoUrl(logoConfig.content);
          console.log(logoConfig.content);
        }
        const footer = data.find(config => config.topic === 'Contact Details');
        console.log(logoConfig);
        if (footer) {
          setFooterAdress(footer.content);
          console.log(footer.content);
        }
        const groupField = data.find(config => config.topic ==='Group Name');
        console.log(logoConfig);
        if (groupField) {
          setGroupAdress(groupField.content);
          console.log(groupField.content);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(()=>{
     getGroupConfigurationDetails()
  },[])
  const getDocumentDetailsbyId = async () => {
    console.log(token);
    try {
      setIsLoading(true);
      // console.log(documentId);
      const response = await axios.get(
        `${BASE_URL}${COMPLIANCE_REQUEST_BY_ID}/${complainceId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      console.log(response.data.renter);
      if (response.status === 200) {
        const data = response.data.compliance;
        setReviewComments(data.reviewComments)
        console.log(data.reviewComments);
        setComplainceDetails(data);
        console.log("hellocomplaince",data);
        setAdressPdf(data.createdToDetails.addressForCommunication)
        setIsLoading(false);
        setFileUrl(data.fileUrl);
        setFilePdf(data.fileUrl)
        setFineApply(data.isFineApplicable);

        setFileImage(data.attachments);
        console.log(data.fileUrl);
        const formattedDate = moment(data.createdDateAndTime).format("MM/DD/YYYY hh:mm A");
        setCreatedDateAndTime(formattedDate)
       
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDocumentDetailsbyId();
  }, [complainceId])
  const getGroupAttachments=async () => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL_IMAGE}/${logoUrl}`, {
        // headers: {
        //   Authorization: `Bearer ${storedToken}`,
        // },
        responseType: "blob",
      });
      const blob = response.data; // Accessing the blob data from the response
      const fileURL = URL.createObjectURL(blob);
      setImageBlob(fileURL);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(()=>{
    getGroupAttachments();
  },[logoUrl])
  
  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '') return '';

    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};
  // const data = {
  //   pageTitle: "Compliance Request",
  //   buttons: [
  //     {
  //       buttonTitle: "Back",
  //       onClick: handleClick,
  //     },
      
  //   ],
  //   content: [
  //     {
  //       topic: "Compliance Request Id",
  //       description: complainceDetails.crId,
  //     },
  //     {
  //       topic: "Created By",
  //       description : `${complainceDetails.createdByDetails?.firstName || ""} ${complainceDetails.createdByDetails?.lastName ||""}`,
  //     },
  //     {
  //       topic: "Created By Address",
  //       description : complainceDetails.createdByHomeAddress,
  //     },
  //     {
  //       topic: "Created Date Time",
  //       description: moment(complainceDetails.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
  //     },
  //     {
  //       topic: "Category/Subject",
  //       description : `${complainceDetails.categoryDetails?.category??""}`,
  //     },
  //     {
  //       topic: "ByLaws Topic",
  //       description :`${complainceDetails.bylawDetails?.topic??""}`
  //    },
  //     {
  //       topic: "Audit Date Time",
  //       description: moment(complainceDetails.auditDateAndTime??"").format("MM/DD/YYYY hh:mm:ss a"),
  //     },
  //     {
  //       topic: "Status",
  //       description: convertToPascalCase(complainceDetails.status??""),
  //     },
  //     {
  //       topic: "Created For",
  //       description : `${complainceDetails.createdToDetails?.firstName??""} ${complainceDetails.createdToDetails?.lastName??""}`,
  //     },
  //     // {
  //     //   topic: "Address",
  //     //   description :`${complainceDetails.address?.address??""}`
  //     // },
  //     {
  //       topic: "Address",
  //       description : `${complainceDetails.address?.address??""}`,
  //     },
  //     {
  //       topic: "Address for Communication",
  //       description : `${complainceDetails.createdToDetails?.addressForCommunication??""}`,
  //     },
  //     {
  //       topic: "Description",
  //       description:complainceDetails.description??""
  //     },

  //     {
  //       topic: "Attachments",
  //       component: "AttachmentList", 
  //       props: {
  //         fileUrl: fileUrl,
  //         documentName: fileUrl,
  //         docname: fileUrl,
  //         uploadedTimes: createdDateAndTime,
  //       },
  //     },
  //   ],
  // };
  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  // console.log(complianceRequestData);
  const renderDescription = () => {
    console.log("inside render ");
    if (complainceDetails.description && !showFullDescription) {
    console.log("inside render ",complainceDetails.description);
  
      return (
        <>
        
          {complainceDetails.description.slice(0, 250)}
          {complainceDetails.description.length > 250 && (
            <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Read More</Button>
          )}
        </>
      );
    } else {
    console.log("inside render else ",complainceDetails.description);
  
      return (
        <>
          {complainceDetails.description}
          <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Less</Button>
        </>
      );
    }
  };

  const fileList = fileImage.map(file => ({
    docname: file.file.substring(12), // Assuming file name is the document name
    fileUrl: file.file, // Assuming file location is the file URL
    documentnamed: file.file, // Assuming file name is the document name
    uploadedTimes:  moment(file.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"), // Assuming uploadedTimes is available
  }));
  const data = [
    {
      groupName: 'Compliance Request Details',
      items: [
        { label: 'Compliance Request Id', data: `${ complainceDetails?.crId || ""}`, text: 'Unique identifier for the compliance request' },
        { label: 'Created Date Time', data: `${moment(complainceDetails.createdDateAndTime).format(
               "MM/DD/YYYY hh:mm:ss A")}` ,text: 'The date and time when the compliance request was created'},
               { label: 'Category', data: `${complainceDetails.categoryDetails?.category}` ,text: 'The category or subject of the compliance request'},
               { label: 'Description', data: renderDescription() ,text: 'Detailed description of the compliance issue'},
               { label: 'ByLaws Topic', data: `${complainceDetails.bylawDetails?.topic ?? ""}` ,text: 'The topic of the bylaw related to the compliance issue'},
               { label: 'ByLaws Description', data: `${complainceDetails.bylawDetails?.description || ""}`, text: 'The description of the bylaw related to the compliance issue' },

        ],
     },
    //  {
    //   groupName: 'Created By Details',
    //   items: [
    //     { label: 'Firstname', data:`${complainceDetails.createdByDetails?.firstName??""}`},
    //     { label: 'Lastname', data:`${complainceDetails.createdByDetails?.lastName??""}` },
    //     { label: 'Email', data:`${complainceDetails.createdByDetails?.email??""}` },
    //     { label: 'Address', data:`${complainceDetails?.address?.address??""}` },
    //     { label: 'contact', data:`${complainceDetails.createdByDetails?.contact??""}` },
    //   ],
    // },
    
     {
      groupName: 'Audit and Status Details',
      items: [
        { label: 'Audit Date Time', data: `${moment(complainceDetails.auditDateAndTime).format(
        "MM/DD/YYYY hh:mm:ss A")}` ,text: 'The date and time of the last audit.'},
           { label: 'Status', data: `${CustomPascalChange(complainceDetails.status??"")}` ,text: 'Current status of the compliance request (e.g., Open, Closed)'},
           { label: 'Is Fine Applicable', data: `${fineApply ?'Yes': 'No'}` ,text: 'Indicates if a fine is applicable for this compliance request'},
        ],
     },
    //  { label: 'ByLaws Topic', data: `${complainceDetails.bylawDetails?.topic ?? ""}` ,text: 'Tooltip text 1'},
    //  { label: 'Audit Date Time', data: `${moment(complainceDetails.auditDateAndTime).format(
    //     "MM/DD/YYYY hh:mm:ss A")}` ,text: 'Tooltip text 1'},
    //     { label: 'Status', data: `${CustomPascalChange(complainceDetails.status??"")}` ,text: 'Tooltip text 1'},
    //     { label: 'Is Fine Applicable', data: `${fineApply ?'Yes': 'No'}` ,text: 'Tooltip text 1'},
    //     { label: 'Status', data: `${CustomPascalChange(complainceDetails.status??"")}` ,text: 'Tooltip text 1'},
       
    //     { label:'Attachment', data: (
    //      <AttachmentList
    //        fileUrl={attachmentData.props.fileUrl}
    //        documentName={attachmentData.props.documentName}
    //        docname={attachmentData.props.docname}
    //        uploadedTimes={attachmentData.props.uploadedTimes}
    //      />
    //    ), text: 'Tooltip text 1'},
    
    {
      groupName: 'Created To Details',
      items: [
        { label: 'Firstname', data:`${complainceDetails.createdToDetails?.firstName??""}`,text:"First name of the person or entity to whom the compliance request was created."},
        { label: 'Lastname', data:`${complainceDetails.createdToDetails?.lastName??""}`,text:"Last name of the person or entity to whom the compliance request was created." },
        { label: 'Email', data:`${complainceDetails.createdToDetails?.email??""}`,text:"Email address of the person or entity to whom the compliance request was created." },
        
        { label: 'Address', data:`${complainceDetails.address?.address || ""}` ,text:"Home address of the person or entity to whom the compliance request was created."},
        { label: 'Address for communication', data: `${complainceDetails.createdToDetails?.addressForCommunication || ""}`,
         text: 'Address for communication of the person who created compliance request' },

        { label: 'contact', data:`${complainceDetails.createdToDetails?.contact??""}`,text:"Contact number of the person or entity to whom the compliance request was created." },
      ],
    },
    {
      groupName: 'Attachment Details',
      items: [
        { label:'Attachment', data: (
          <AttachmentListsView fileList={fileList} />

             ), text: 'List of Document attached to compliance request'},   
        ],
     },
  ];
 const viewDataTable = useMemo((rowData) => [
    // {
    //   accessorKey: "serialNo",
    //   header: "Id",
    //   size: 20,
    //   Cell: ({ row }) => row.index + 1,

    // },
    // {
    //   accessorKey: "createdDateAndTime",
    //   header: "Assigned DateTime",
    //   size: 20,
    //   accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY  hh:mm:ss a"),

    // },
    {
      accessorKey: "assignedToName",
      header: "Name",
      size: 20,
    },
    {
      accessorKey: "status",
      header: "Review Status",
      size: 20,
    },
    {
      accessorKey: "auditDateAndTime",
      header: "Review DateTime",
      size: 20,
      accessorFn: (row) => moment(row.auditDateAndTime).format("MM/DD/YYYY hh:mm:ss a"),

    },
    // {
    //   accessorKey: "comments",
    //   header: "Review Comments",
    //   size: 20,
    // },
  ]);
  const handleCheckboxChange = (event) => {
    setIncludeComments(event.target.checked);
  };
  const currentDate = moment(); // Get the current date
  let dateTimeText = currentDate.format("DD MMMM YYYY"); // Format the current date
  const handleRenderPDF = () => {
    
    const styles = {
      title: {
        fontSize: 12,
        color: "black",
        textAlign: "center",
        padding:"5px",
        fontWeight: "bold" 
      },
    empty:{
      color:"red",
    padding:"500px"
    },
    content: {
      fontSize: 12,
      color: "black",
      textAlign: "left",
      // paddingLeft:"60px",
      padding:"5px"
    },
    comments:{
      fontSize:12,
      color:"black",
      marginTop:"2%"
    },
    emptys:{
        marginTop:"3%"
    },
    empty:{
    marginTop:"128%"
    },
    secondEmpty:{
     marginTop:"21%"
    },
    from:{
      fontSize: 12,
      color: "black",
      textAlign: "left",
      alignItems:"center",
      padding:"2px",
      paddingRight: "10px"
     },
   headers:{
    fontSize: 12,
    color: "black",
    textAlign: "left",
    alignItems:"center",
    padding:"4px",
    marginRight:"4px"
    },
   emptyModule:{
    marginTop:"7px"
   },
   re:{
fontSize:12,
color:"black",
textAlign:"left",
padding:"5px"
   },
    adress:{
      fontSize: 12,
      color: "black",
      textAlign: "left",
      
      // paddingLeft:"60px",
      padding:"5px",
      // fontWeight:"bold"
    },
    toDate:{
        marginTop:"1%",
    },
    description:{
      fontSize: 13,
      color: "black",
      textAlign: "left",
      // paddingLeft:"60px",
      padding:"5px",
    fontWeight:"bold"
    },
    dear:{
      fontSize: 13,
      color: "black",
      textAlign: "left",
      // paddingLeft:"60px",
      padding:"5px",
    fontWeight:"bold"
    },
    signature: {
      fontSize: 16,
      color: "black",
      textAlign: "left",
      fontStyle: "italic",
    },
    heading: {
      fontSize: 24,
      textAlign: 'center',
      fontFamily: 'Oswald'
    },
    pageInitial:{
      marginTop:"100px"
    },
    afterfrom:{
      marginTop:"6%"
    },
    formempty:{
      marginTop:"45%"
    },anotherempty:{
      marginTop:"8%"
    },images:{
width:"200px",
height:"100px"

    },
    fromBorder:{
       border:"1px solid black"
    },toBorder:{
      border:"1px solid black"

    },
    headerImage:{
      marginTop:"1%",
 paddingLeft:"70px",
    paddingRight:"50px",
    alignItems:"center",

      width:"470px",
      height:"160px",
      // backgroundColor:"yellow"
    }
  };
  const datas = {
    // title:"Complaince Request Notice",
     logo: imageBlob,
          letterContent: [
      // { type: 'text',text: "",style:styles.emptys }, // Empty line for spacing
      { type: 'text', text: groupAdress, style: styles.title },
     
      { type: 'text', text: "Compliance Request", style: styles.title },
      { type: 'text', text: " "  }, 
      // { type: 'text',text: dateTimeText, style:styles.from },
      // { type: 'text',text: "",  }, // Empty line for spacing
      // { type: 'text',text: "",  },
      
      {style:styles.fromBorder},
      
      {type: 'text', text: "",  style:styles.formempty},
      // {type: 'text', text: "",  style:styles.anotherempty},
      // { type: 'text',text: "",  style:styles.secondEmpty},
          
      { type: 'text',text: "From :", style: styles.from  } ,
      { type: 'text', text: groupAdress, style: styles.from },

      { type: "text", text: footerAdress, style: styles.from },

      // {type: 'text',text: ""+ `${complainceDetails.createdByDetails?.firstName ||""}`,style: styles.from, },
      // {type: 'text',text: ""+ `${complainceDetails.address?.address || ""}`,style: styles.from,},
      // { type: 'text',text: `${complainceDetails.createdByDetails?.city || ""}, ${complainceDetails.createdByDetails?.zipcode || ""}`, style: styles.from },
     
             // {style:styles.toBorder},

      { type: 'text',text: "",  style:styles.afterfrom},
      { type: 'text',text: "To :", style: styles.headers  },
      {
        type: 'text',
        text: `${complainceDetails?.createdToDetails?.firstName || ""}${complainceDetails?.createdToDetails?.lastName || ""}`,
        style: styles.headers
      },
      { 
        type: 'text',
        text:`${complainceDetails?.createdToDetails?.addressForCommunication ?? complainceDetails?.address?.address ?? ""}`,
        style: styles.headers
      },
      {
        type: 'text',
        text: `${complainceDetails?.createdToDetails?.city || ""}${complainceDetails?.createdToDetails?.zipcode ? `, ${complainceDetails?.createdToDetails?.zipcode}` : ""}`,
        style: styles.headers
      },
      
            
      {type: 'text', text: "",  style:styles.empty},
      { type: 'text',text: "",  style:styles.secondEmpty},
      { type: 'text',text: "",  style:styles.toDate},
      //   imageBlob? 
      // {type: 'image',src:imageBlob, style:styles.headerImage  },
      ...(imageBlob ? 
        [{ type: 'image', src: imageBlob, style: styles.headerImage }] : 
        [{ type: 'text', text: "", style: styles.headerImage }]
      ),
      { type: 'text', text: groupAdress, style: styles.title },
     
      { type: 'text', text: "Compliance Request", style: styles.title },
      {type:'text',text:dateTimeText,style: styles.content},
      { type: 'text',text: "",  style:styles.emptyModule},

      // {type:'text',text:"To",style: styles.content},
      {
        type: 'text',
        text: `${complainceDetails?.createdToDetails?.firstName || ""}${complainceDetails?.createdToDetails?.lastName || ""}`,
        style: styles.headers
      },
      { 
        type: 'text',
        text:`${complainceDetails?.createdToDetails?.addressForCommunication ?? complainceDetails?.address?.address ?? ""}`,
        style: styles.headers
      },
      {
        type: 'text',
        text: `${complainceDetails?.createdToDetails?.city || ""}${complainceDetails?.createdToDetails?.zipcode ? `, ${complainceDetails?.createdToDetails?.zipcode}` : ""}`,
        style: styles.headers
      },
      { type: 'text',text: "",  style:styles.emptyModule},


      {
        type: 'text',
        text: `RE:${complainceDetails?.createdToDetails?.addressForCommunication ?? complainceDetails?.address?.address ?? ""}`,
        style: styles.re
      },
      { type: 'text',text: "",  style:styles.emptyModule},


      {type: 'text', text: "Dear Resident,",style: styles.dear},

      // {type: 'text', text: "Dear "  + `${(complainceDetails.createdToDetails?.firstName || "")}`, style: styles.content },
      // {type: 'text',text:"You have previously asked to adress the following matter with your property.",style: styles.content},
      { 
        type: 'text', 
        text: `This is to inform you about the compliance request: ${complainceDetails.crId}_${moment(complainceDetails.createdDateAndTime).format('MM/DD/YYYY hh:mm A')},`, 
        style: styles.content 
      },
          { type: 'text',text: "" + `${complainceDetails.description || ""}`+".", style: styles.description,},
    { type: 'text',text: "If u have any questions,feel that you have recieved this letter in error,or would  like to request a variance,please feel free to contact our office.", style: styles.content},
   {type: 'text',text:"Thank you in advance for your attention to this matter.", style: styles.content},
    {type: 'text', text: "",  }, 
    {type: 'text', text: "", style: styles.content },
    {type: 'text', text: "", style: styles.content },
      
      {type: 'text', text: "Sincerely,", style: styles.adress },
      { 
        type: "text", 
        text: `${footerAdress} (computer generated letter)`, 
        style: styles.adress 
      },
            // {text: ""+ complainceDetails.address?.address + "",style: styles.adress,fontSize: 15 ,},
      // { text: `${complainceDetails.createdByDetails?.city || ""}, ${complainceDetails.createdByDetails?.zipcode || ""}`, style: styles.adress, fontSize: 15 },    
      //  {type: 'text',text: footerContent , style: styles.adress}
        
      {type: 'text', text: "", },
      // {type: 'text', text: "",  style:styles.empty},
      // { type: 'text',text: "",  style:styles.secondEmpty},
      // {type: 'image',src:fileImage,style:styles.images },

    ]
  };
  if (Array.isArray(fileImage) && fileImage.length > 0) {
    datas.letterContent.push({ type: 'text', text: "Attachments :", style: styles.content });

    fileImage.forEach((fileImages, index) => {
      const trimmedFileName = fileImages.file.substring(12);
      datas.letterContent.push({ type: 'text', text: trimmedFileName, style: styles.content });
      datas.letterContent.push({ type: 'image', src: `${BASE_URL_IMAGE}/${fileImages.file}`, style: styles.images, alt: `Attachment ${index + 1}` });
    });
  } else {
    // Handle the case where fileImage is an empty array
    datas.letterContent.push({ type: 'text', text: "", style: styles.content });
  }
  
  // Add comments if generalCommentDetails array is not empty
  if (includeComments && generalCommentDetails.length > 0) {
    // Add the "Comments" heading before looping through comments
    datas.letterContent.push({ type: 'text', text: "Comments :", style: styles.content });

    generalCommentDetails.forEach((commentDetail) => {
      // Combine the name and comment into a single line
      const combinedText = `@${commentDetail.createdByName} : ${commentDetail.comments}`;
      datas.letterContent.push({ type: 'text', text: combinedText, style: styles.content });
  });
}
return (
    <PDFDocument data={datas} />
  );
};
const handleClickPdf = async () => {
  try {
    const pdfData = handleRenderPDF();
    const blob = await pdf(pdfData).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};
// general comments
const handleEditClick = (index, generalCommentsId) => {
  console.log("Edit button clicked", index, generalCommentsId);
  setEditModes((prevModes) =>
    prevModes.map((mode, i) => (i === index ? true : mode))
  );
  setCommentsId(generalCommentsId);
  console.log("CommentsId set:", generalCommentsId);
  getGeneralCommentsById();
};

const handleInputChange = (e) => {
  setGeneralCommentsData(e.target.value);
};
const handleInputChangeAdd = (e) => {
  setGeneralCommentsDataAdd(e.target.value);
};
const handleCancelClick = () => {
 
  getGeneralComments();
  setEditModes(false);
};

const getGeneralComments = async () => {
  try {
    setIsLoading(true);
    const storedToken = token;

    console.log(storedToken);

    const response = await axios.get(
      `${BASE_URL}${GET_ALL_GENERAL_COMMENTS}/${complainceId}`,
      {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      }
    );
    console.log(response.data);

    if (response.status === 200) {
      const data = response.data;
      setGeneralCommentDetails(data);
      setEditModes(Array(data.length).fill(false));
      setIsLoading(false);
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    // Handle error
    console.error("Error fetching data:", error);
  }
};

useEffect(() => {
  getGeneralComments();
}, []);
const onSubmitGeneral = (data,e) => {

  setIsLoading(true);

  // if (generalCommentsData == "" || generalCommentsData == null) {
  //   setError("comments", {
  //     type: "manual",
  //     message: COMMENTS_REQUIRED_MESSAGE,
  //   });
  //   setIsLoading(false);
  //   return;
  // }
  if (typeof generalCommentsDataAdd !== 'string' || generalCommentsDataAdd.trim() === '') {
    setErrorGeneral("comments", {
      type: "manual",
      message: COMMENTS_REQUIRED_MESSAGE,
    });
    setIsLoading(false);
    return;
  }
  const postData = {
    comments: generalCommentsDataAdd,
    referenceId: complainceId,
  };
  request({ url: GENERAL_COMMENTS, method: "post", data: postData })
    .then((res) => {
      console.log(res);
      if (res.status == 200) {
        getGeneralComments();
        // postData = {};
        console.log("Success");
        setIsLoading(false);
        setGeneralCommentsDataAdd("")  
        // e.target.reset();
        // formRef.current.reset(); 
        setTimeout(() => {
          //  navigate(-1);
        }, 1000);
      }
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response) {
        console.log("error.response.status" + error.response.status);
      }
    });
};

const getGeneralCommentsById = async () => {
  try {
    const storedToken = token;

    console.log(storedToken);

    const response = await axios.get(
      `${BASE_URL}${GENERAL_COMMENTS}/${commentsId}`,
      {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      }
    );
    console.log(response);

    if (response.status === 200) {
      const data = response.data;
      console.log(response.data);
      setGeneralCommentsData(data.comments);

      setIsLoading(false);
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    // Handle error
    console.error("Error fetching data:", error);
  }
};

useEffect(() => {
  getGeneralCommentsById();
}, [commentsId]);

const submitUpdate = (data) => {
  console.log("Test");
  setIsLoading(true);


  if (generalCommentsData.trim() == "" || generalCommentsData.trim() == null) {
    setErrorGeneral("editComments", {
      type: "manual",
      message: COMMENTS_REQUIRED_MESSAGE,
    });
    setIsLoading(false);
    return;
  }
  if (generalCommentsData.trim().length < 2) {
    setErrorGeneral("editComments", {
      type: "manual",
      message: MIN_LENGTH_VALIDATE_MESSAGE,
    });
    setIsLoading(false);
    return;
  }

  if (generalCommentsData.trim().length > 1000) {
    setErrorGeneral("editComments", {
      type: "manual",
      message: MAX_LENGTH_THOUSAND,
    });
    setIsLoading(false);
    return;
  }
  const putData = {
    comments: generalCommentsData.trim(),
    referenceId: commentsId,
  };
  request({
    url: `${GENERAL_COMMENTS}/${commentsId}`,
    method: "put",
    data: putData,
  })
    .then((res) => {
      console.log(res);
      if (res.status == 200) {
        console.log("Success");
        setIsLoading(false);
        getGeneralComments();
      }
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response) {
        console.log("error.response.status" + error.response.status);
      }
    });
};
const handleDeleteClickOpen = (generalCommentsId) => {
  setDeleteOpen(true);

  setCommentsId(generalCommentsId);
  getGeneralCommentsById();
};
const onSubmitDelete = () => {
  request({ url: `${GENERAL_COMMENTS}/${commentsId}`, method: "delete" })
    .then((res) => {
      console.log(res);
      getGeneralComments();
    })
    .catch((error) => {
      console.log(error);
    });
};

const okCancelButtonActions = (
  <>
    <CustomButton
      fieldType="submit"
      buttonName="Ok"
      click={handleDeleteClose}
    />
    <CustomButton
      fieldType="button"
      buttonName="Cancel"
      click={handleDeleteClose}
    />
  </>
)

const deleteModel = (
  <form
    onSubmit={handleSubmitGeneral(onSubmitDelete)}
    className="space-y-4 md:space-y-6 "
  >
    <DialogContent dividers>
      <Typography> Are you sure you want to delete comment?</Typography>
    </DialogContent>
    <DialogActions>{okCancelButtonActions}</DialogActions>
  </form>
);
return (
    <div>
      {loading && <CustomLoading/>}
      {/* <CustomViewWithExport data={data} onClick={handleClick} exportedBy={true} handleExportss={handleClickPdf} checked={includeComments}
      handleCheckBoxChange={handleCheckboxChange}/> */}
      <CustomViewReportHeading onClick={handleClick} 
      handleExportss={handleClickPdf} 
       title={"Compliance Request"} checked={includeComments}
       handleCheckBoxChange={handleCheckboxChange} exportedBy={true}>
              {/* <CustomViewWithTooltip data={data}  /> */}
              <CustomViewWithMore data={data}  />

      <br/>

      <CustomReviewTable columns={viewDataTable} data={reviewComments} actions={false}/>


        {/* GeneralComments */}
        <br />

<>
<CustomHeading title="General Comments" />
<CustomToastContainer />

<CustomModel
  title=" Delete Comments"
  submit={onSubmitDelete}
  content={deleteModel}
  action={okCancelButtonActions}
  openStatus={deleteOpen}
  closeStatus={handleDeleteClose}
  iconCloseStatus={handleDeleteClose}
  reset={resetGeneral}
  modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }} />
  <Card>
  {/* <form
    onSubmit={handleSubmitGeneral(onSubmitGeneral)}
    className="space-y-4 md:space-y-6 "
  >
    <CardContent>
      <CustomInputField
        fieldName="comments"
        // requiredErrorMessage={COMMENTS_REQUIRED_MESSAGE}
        minLength={2}
        minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
        maxLength={1000}
        maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
        fieldId="comments"
        fieldType="text"
        fieldLabel="Write a Comments"
        fieldControl={controlGeneral}
        fieldError={errorsGeneral}
        fieldRequiredIcon="*"
        multiline={true}
        numberOfRows={2}
        value={generalCommentsDataAdd}
        onInput={handleInputChangeAdd} />

      <div class="text-center mt-10">
        <CustomButton fieldType="submit" buttonName="Post" />
        {isLoading && <CustomLoading />}
      </div>
    </CardContent>
  </form> */}
  <CardContent>
    {generalCommentDetails  && generalCommentDetails.length > 0 ? (
      generalCommentDetails.map((comment, index) => (
        <div key={comment.generalCommentsId}>
          <section className="grid md:grid-cols-1 xl:grid-cols-1 gap-3 mt-6 ml-16">
            <p>
              <span className="text-primary" style={{ float: "left" }}>
                @ {comment.createdByName}{" "}
              </span>
              <span style={{ float: "right" }}>


                {!editModes[index] && comment.createdById === HomeLoginId && (
                  <>
                   
                  </>
                )}
                {moment(comment.createdDateAndTime).format(
                  "MM/DD/YYYY hh:mm:ss a"
                )}
              </span>
              {editModes[index] ? (
                <form
                  onSubmit={handleSubmitGeneral(submitUpdate)}
                  className="space-y-4 md:space-y-6 "
                >
                  <CardContent>
                    <CustomInputField
                      fieldName="editComments"


                      fieldId="editComments"
                      fieldType="text"
                      // fieldLabel="Write a Comments"
                      fieldControl={controlGeneral}
                      fieldError={errorsGeneral}
                      // fieldRequiredIcon="*"
                      multiline={true}
                      numberOfRows={2}
                      value={generalCommentsData}
                      onInput={handleInputChange} />

                    <div class="text-center mt-8 space-x-1">
                      <CustomButton
                        fieldType="submit"
                        buttonName="Save" />

                      <CustomButton
                        fieldType="button"
                        buttonName="Cancel"
                        click={handleCancelClick} />
                      {/* {isLoading && <CustomLoading />} */}
                    </div>
                  </CardContent>
                </form>
              ) : (
                <p className="mt-8 mb-8">{comment.comments}</p>
              )}
            </p>
          </section>

          <Divider />
        </div>
      ))
      ) : (
        <div className="text-center mt-10">
          <p>No comments to display</p>
        </div>
      )}
  </CardContent>
</Card>
</>
</CustomViewReportHeading>
{/*upto here general comments  */}
    </div>
  );
}

export default ArcMcBmHomeActivityCrView