import CustomViewPage from "../../../components/CustomViewPage";
import CustomButton from "../../../components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../../../components/CustomInputField";
import {
  CATEGORY_REQUIRED_MESSAGE,
  COMMENTS_REQUIRED_MESSAGE,
  DESCRIPTION_REQUIRED_MESSAGE,
  MAX_LENGTH_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
  REVIEW_COMMENT_REQUIRED_MESSAGE,
  STATUS_REQUIRED_MESSAGE,
} from "../../../utills/ApplicationConstants";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CustomHeading from "../../../components/CustomHeading";
import { FileUploader } from "react-drag-drop-files";
import { useEffect, useMemo, useState } from "react";
import CustomMaterialTable from "../../../components/CustomMaterialTable";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL, COMPLIANCE_REQUEST_BY_ID, COMPLIANCE_REVIEW, GENERAL_COMMENTS, GET_ALL_GENERAL_COMMENTS } from "../../../utills/ApplicationRouting";
import moment from "moment";
import { DeleteOutline, EditNoteOutlined, FileDownloadOutlined } from "@mui/icons-material";
import { request } from "../../../services/AxiosConfig";
import CustomLoading from "../../../components/CustomLoading";
import CustomPascalChange from "../../../components/CustomPascalChange";
import CustomToastContainer from "../../../components/CustomToastContainer";
import CustomViewHeading from "../../../components/CustomViewHeading";
import CustomViewWithTooltip from "../../../components/CustomViewWithTooltip";
import AttachmentList from "../../../components/AttachmentList";
import AttachmentListsView from "../../../components/AttachmentListsView";
import CustomModel from "../../../components/CustomModel";
import CustomLateFees from "../../../components/CustomLateFees";
import CustomMaterialWithoutRoutes from "../../../components/CustomMaterialWithoutRoutes";
import CustomReviewTable from "../../../components/CustomReviewTableWithoutActions";
import CustomViewWithMore from "../../../components/CustomViewWithMore";
import CustomGeneralComments from "../../../components/CustomGeneralComments";
import CustomFile from "../../../components/CustomFile";


function ArcComplianceView() {
    const navigate = useNavigate();
    const { token ,userDetails,userLoginDetails} = useSelector(state => state.hybridhoa);
    const [complianceRequestData,setComplianceRequestData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {complainceId} = useParams();
    const [reviewComment,setReviewComment]=useState('');
    const [fileUrl, setFileUrl] = useState([]);
    // const [createdDateAndTimes,setCreatedDateAndTimes]=useState("");
    const [status,setStatus]=useState("");
    const [address,setAddress] = useState("");
    const [addressFrCommunication,setAddressFrComunication] = useState("");
    const [fineApply,setFineApply] = useState(false);
    const [fileError, setFileError] = useState("");

    const [selectedStatus,setSelectedStatus]=useState("")
    const [statuss,setStatuss]=useState("");
    const [fineDetails,setFineDetails]=useState([])
  const [homeDues,setHomeDues]=useState(0);
    const [showFullDescription, setShowFullDescription] = useState(false); 
    const [expandedRows, setExpandedRows] = useState({});
const [reviewCommentss,setReviewCommentss]=useState("")
const handleInputreviewComments = (e) => {
  setReviewCommentss(e.target.value);
};
  const toggleDescription = (rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };
    const HomeLoginId= userDetails.homeOwnerLoginId;
    console.log(HomeLoginId,"testHhoa");
    const {
      handleSubmit,
      control,
      formState: { errors },
      setValue,
      setError,
      reset
    } = useForm();
    const {
      handleSubmit: handleSubmitGeneral,
      control: controlGeneral,
      formState: { errors: errorsGeneral },
      setValue: setValueGeneral,
      setError: setErrorGeneral,
      watch: watchGeneral,
      clearErrors : clearErrorsGeneral
    } = useForm();

    const onSubmit = async (data) => {

      if (!status) {
        setError("status", {
          type: "manual",
          message: STATUS_REQUIRED_MESSAGE ,
        });
        return;
      }
      if (typeof reviewCommentss !== 'string' || reviewCommentss.trim() === '') {
        setError("reviewComment", {
          type: "manual",
          message: DESCRIPTION_REQUIRED_MESSAGE,
        });
        setIsLoading(false);
        return;
      }
      setIsLoading(true)
      const formData = new FormData();
      formData.append('status',status);
    
      formData.append('comments',reviewCommentss);
      formData.append('role','ROLE_ARC');
     file.forEach((file) => {
        formData.append('files', file);
        });
      console.log('formData:', formData);
    
      const storedToken = token
    
      console.log(storedToken);
       const headers = {
      // 'Content-Type': 'application/json',
      Authorization: `Bearer ${storedToken}`,
    };
      // return;
      
      
      request({url:`${BASE_URL}${COMPLIANCE_REVIEW}/${complainceId}`, method:"put", data:formData, headers}).then(res=>{
    // setIsLoading(false); 
    console.log(res);
    if(res.status==200){
      
      setIsLoading(false); 
      getComplianceRequestDetailsById();
      setReviewCommentss("")
    }else{
      setIsLoading(false)
    }
    }).catch(error=>{
    console.log(error.response);
    setIsLoading(false); 
    if (error.response) {
    
      console.log("error.response.status" + error.response.status);
     
    
    }  
    });
    };

    const getComplianceRequestDetailsById = async () => {
      try {
            const storedToken = token
  
        console.log(storedToken);
        
          const response= await axios.get(`${BASE_URL}${COMPLIANCE_REQUEST_BY_ID}/${complainceId}`,{
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
                console.log(response.data); 
  
        if (response.status === 200) {
          const data = response.data.compliance;
          const lateFees = response.data.crfinehistory;
          const totalDueAmount = lateFees.reduce((accumulator, current) => (accumulator) + (+current['dueAmount']), 0);
  
  console.log("homeDues",totalDueAmount);
  setHomeDues(totalDueAmount)
  const pendingLateFees = lateFees.filter(fee => fee.status === 'PENDING');
  setFineDetails(pendingLateFees);
          console.log("fin Apply",data.isFineApplicable);
          const createdFrAddress = data.createdToDetails?.addressForCommunication
          const createdAddress = data.address.address;
          setComplianceRequestData(data);
          setAddress(createdAddress);
          setFineApply(data.isFineApplicable);
          setAddressFrComunication(createdFrAddress)
          setSelectedStatus(data.status)
          setIsLoading(false);
          setFileUrl(data.attachments);
          const formattedDate = moment(data.createdDateAndTime).format(
            "MM/DD/YYYY hh:mm A"
          );
          // setCreatedDateAndTimes(formattedDate);
          setReviewComment(data.reviewComments)
          setStatus(data.status)
          setStatuss(data.status)
        } else {
          throw new Error('Failed to fetch data');
        }
       
      } catch (error) {
        // Handle error
        console.error("Error fetching data:", error);
      }
    };
  
  
  
  useEffect(()=>{
    getComplianceRequestDetailsById();
  },[complainceId])
  const [generalCommentDetails, setGeneralCommentDetails] = useState("");
  const [editModes, setEditModes] = useState(
    Array.isArray(generalCommentDetails)
      ? Array(generalCommentDetails.length).fill(false)
      : []
  );
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [generalCommentsData, setGeneralCommentsData] = useState("");
  const [generalCommentsDataAdd, setGeneralCommentsDataAdd] = useState("");

  const [commentsId, setCommentsId] = useState("");
  const [file, setFile] = useState([]);

  const handleFileChange = (newFiles) => {
    setFile(newFiles);
  };
  const handleEditClick = (index, generalCommentsId) => {
   
    console.log("Edit button clicked", index, generalCommentsId);
    setEditModes((prevModes) =>
      prevModes.map((mode, i) => (i === index ? true : mode))
    );
    getComplianceRequestDetailsById();
    setCommentsId(generalCommentsId);
    console.log("CommentsId set:", generalCommentsId);
    getGeneralCommentsById();
  

  };

  const handleInputChange = (e) => {
    setGeneralCommentsData(e.target.value);
  };

  const handleCancelClick = () => {

    clearErrorsGeneral("editComments");
    getGeneralComments();

    getComplianceRequestDetailsById()

     getGeneralComments();

    setEditModes(false);
    setValue('editComments',"")
  };
  const handleInputChangeAdd = (e) => {
    setGeneralCommentsDataAdd(e.target.value);
  };
  const getGeneralComments = async () => {
    try {
     
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${GET_ALL_GENERAL_COMMENTS}/${complainceId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setGeneralCommentDetails(data);
        setEditModes(Array(data.length).fill(false));
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getGeneralComments();
  }, []);
  const onSubmitComments = (data,e) => {
   


    if (typeof generalCommentsDataAdd !== 'string' || generalCommentsDataAdd.trim() === '') {
      setErrorGeneral("comments", {
        type: "manual",
        message: COMMENTS_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const postData = {
      comments: generalCommentsDataAdd.trim(),
      referenceId: complainceId,
    };
    request({ url: GENERAL_COMMENTS, method: "post", data: postData })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          getGeneralComments();
          getComplianceRequestDetailsById()
          console.log("Success");
          setIsLoading(false);
          setGeneralCommentsDataAdd("")
          // e.target.reset();
          // setTimeout(() => {
          //   navigate(-1);
          // }, 1000);
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const getGeneralCommentsById = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${GENERAL_COMMENTS}/${commentsId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        console.log(response.data);
        setGeneralCommentsData(data.comments);
        setStatus(data.status);

        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  // useEffect(() => {
  //   getGeneralCommentsById();
  // }, [commentsId]);

  useEffect(() => {
    if(commentsId){
    getGeneralCommentsById();}
  }, [commentsId]);

  useEffect(()=>{
    if (generalCommentsData.trim().length > 1000) {
      setErrorGeneral("editComments", {
          type: "manual",
          message: MAX_LENGTH_THOUSAND,
      });
      setIsLoading(false);
      return;
  }

  
  },[generalCommentsData])

  const submitUpdate = (data) => {
    setIsLoading(true);
    
    const putData = {
      comments: generalCommentsData.trim(),
      referenceId: commentsId,
    };

    if (generalCommentsData == "" || generalCommentsData == null) {
      setErrorGeneral("editComments", {
        type: "manual",
        message: COMMENTS_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (generalCommentsData.trim().length < 2) {
      setErrorGeneral("editComments", {
        type: "manual",
        message: MIN_LENGTH_VALIDATE_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (generalCommentsData.trim().length > 1000) {
      setErrorGeneral("editComments", {
        type: "manual",
        message: MAX_LENGTH_THOUSAND,
      });
      setIsLoading(false);
      return;
    }
    request({
      url: `${GENERAL_COMMENTS}/${commentsId}`,
      method: "put",
      data: putData,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");
          setIsLoading(false);
          getGeneralComments();
          getComplianceRequestDetailsById()

        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const handleDeleteClickOpen = (generalCommentsId) => {
    getComplianceRequestDetailsById()
    setDeleteOpen(true);
   
    setCommentsId(generalCommentsId);
    getGeneralCommentsById()
   
    
  };
  const onSubmitDelete = () => {
    request({ url: `${GENERAL_COMMENTS}/${commentsId}`, method: "delete" })
      .then((res) => {
        getComplianceRequestDetailsById()
        console.log(res);
        getGeneralComments();
        

      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleClick =() =>{
          navigate(-1)
      }
      const convertToPascalCase = (str) => {
        // Check if the input string is undefined or empty
        if (str === undefined || str === '') return '';
    
        // Remove underscores and convert string to Pascal case
        return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
    };
      const handleToggleDescriptionLinked = () => {
        setShowFullDescription(!showFullDescription);
      };
      const handleToggleDescription = () => {
        setShowFullDescription(!showFullDescription);
      };
      // console.log(complianceRequestData);
      const renderDescription = () => {
        console.log("inside render ");
        if (complianceRequestData.description && !showFullDescription) {
        console.log("inside render ",complianceRequestData.description);
      
          return (
            <>
            
              {complianceRequestData.description.slice(0, 250)}
              {complianceRequestData.description.length > 250 && (
                <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Read More</Button>
              )}
            </>
          );
        } else {
        console.log("inside render else ",complianceRequestData.description);
      
          return (
            <>
              {complianceRequestData.description}
              <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Less</Button>
            </>
          );
        }
      };
      
      const fileList = fileUrl.map(file => ({
        
        docname: file.file.substring(12), // Assuming file name is the document name
        fileUrl: file.file, // Assuming file location is the file URL
        documentnamed: file.file, // Assuming file name is the document name
        uploadedTimes:  moment(file.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"), // Assuming uploadedTimes is available
      })); 
  

      const viewCrFineTable = useMemo((rowData) => [
        {
          accessorKey: "dueDate",
          header: "Fine Added Date",
          accessorFn: (row) => moment(row.dueDate).format("MM/DD/YYYY"),
        },
        {
          accessorKey: "dueAmount",
          header: "Amount",
          size: 20,
        },
        // {
        //   accessorKey: "status",
        //   header: "Status",
        //   size: 20,
        //   Cell: ({ row }) => {
        //     const status = row.original.status;
        //     return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
        //   },
        // },
        {
          accessorFn: row => row.description,
          id: 'description',
          header: 'Description',
          Cell: ({ row }) => {
            const comments = row.original.description;
            const isExpanded = expandedRows[row.id];
            const shouldShowMoreButton = comments && comments.length > 28;
      
            return (
              <div>
                {isExpanded ? comments : comments.slice(0, 28) + (shouldShowMoreButton ? '...' : '')}
                {shouldShowMoreButton && (
                  <Button
                    size="small"
                    onClick={() => toggleDescription(row.id)}
                    style={{ marginLeft: '10px' }}
                  >
                    {isExpanded ? 'Show Less' : 'Show More'}
                  </Button>
                )}
              </div>
            );
          },
        }
      ]);
      // const data = {
      //   pageTitle:"Compliance Request",
      //   buttons :[
      //     {
      //       buttonTitle:"Back",
      //       onClick : handleClick
      //     },
         
      
      //   ],
      //   content : [
      //     {
      //       topic :'Compliance Request Id',
      //       description :complianceRequestData.crId
      //     },
      //     {
      //       topic :'Created By',
      //       description: `${
      //         complianceRequestData.createdByDetails?.firstName || ''
      //       } ${
      //         complianceRequestData.createdByDetails?.lastName || ''
      //       }`,
      //     },
      //     {
      //       topic :'Created Date Time',
      //       description : moment(complianceRequestData.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a") 
      //     },
      //     {
      //       topic :'Category/Subject',
      //       description : `${complianceRequestData.categoryDetails?.category}`
      //     },
      //     {
      //       topic :'ByLaws Topic',
      //       description :`${complianceRequestData.bylawDetails?.topic ?? ""}`
      //     },
      //     {
      //       topic :'Audit Date Time',
      //       description : moment(complianceRequestData.auditDateAndTime).format("MM/DD/YYYY hh:mm:ss a")
      //     },
      //     {
      //       topic :'Status',
      //       description: CustomPascalChange(statuss),
      //     },
      //     {
      //       topic :'Created For',
      //       description: `${
      //         complianceRequestData.createdToDetails?.firstName || ''
      //       } ${
      //         complianceRequestData.createdToDetails?.lastName || ''
      //       }`,
      //     },
      //     {
      //       topic :'Address For Communication',
      //       description : addressFrCommunication =='null' ? '': addressFrCommunication
      //     },
      //     {
      //       topic :'Address',
      //       description : address =='null' ? '': address
      //     },
      //     {
      //       topic :'Is Fine Applicable',
      //       description : fineApply ?'Yes': 'No'
      //     },
      //     {
      //       topic :'Violation Description',
      //       description :complianceRequestData.description
      //     },
      //     {
      //       topic: "Attachments",
      //       component: "AttachmentList",
      //       props: {
      //         fileUrl: fileUrl,
      //         documentName: fileUrl,
      //         docname: fileUrl,
      //         uploadedTimes: createdDateAndTimes,
      //       },
      //     },
      //      ]
      // }
      const data = [
        {
            groupName: 'Compliance Request Details',
            items: [
                { label: 'Compliance Request Id', data: `${complianceRequestData?.crId || ""}`, text: 'Unique identifier for the compliance request' },
                { label: 'Created Date Time', data: `${moment(complianceRequestData?.crCreatedDate || complianceRequestData?.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss A") || ""}`, text: 'The date and time when the compliance request was created' },
                { label: 'Category', data: `${complianceRequestData.categoryDetails?.category || ""}`, text: 'The category or subject of the compliance request' },
                { label: 'Description', data: renderDescription(), text: 'Detailed description of the compliance issue' },
                { label: 'ByLaws Topic', data: `${complianceRequestData.bylawDetails?.topic || ""}`, text: 'The topic of the bylaw related to the compliance issue' },
                { label: 'ByLaws Description', data: `${complianceRequestData.bylawDetails?.description || ""}`, text: 'The description of the bylaw related to the compliance issue' },

              ],
        },
        {
            groupName: 'Audit and Status Details',
            items: [
                { label: 'Audit Date Time', data: `${moment(complianceRequestData?.auditDateAndTime).format("MM/DD/YYYY hh:mm:ss A") || ""}`, text: 'The date and time of the last audit.' },
                { label: 'Status', data: `${convertToPascalCase(status)}`, text: 'Current status of the compliance request (e.g., Open, Closed)' },
                // { label: 'Is Fine Applicable', data: `${fineApply ? 'Yes' : 'No'}`, text: 'Indicates if a fine is applicable for this compliance request.' },
            ],
        },
        {
            groupName: 'Attachment Details',
            items: [
                { label: 'Attachment', data: <AttachmentListsView fileList={fileList} />, text: 'List of Document attached to compliance request' },
            ],
        },
        {
            groupName: 'Created By Details',
            items: [
                { label: 'Firstname', data: `${complianceRequestData.createdByDetails?.firstName || ""}`, text: 'First name of the person who created compliance request' },
                { label: 'Lastname', data: `${complianceRequestData.createdByDetails?.lastName || ""}`, text: 'Last name of the person who created compliance request' },
                { label: 'Email', data: `${complianceRequestData.createdByDetails?.email || ""}`, text: 'Email address of the person who created compliance request' },
                

                { label: 'Address', data: `${complianceRequestData?.createdByHomeAddress || ""}`, text: 'Home address of the person who created compliance request' },
                // { label: 'Address for communication', data: `${complianceRequestData.createdByDetails?.addressForCommunication || ""}`, text: 'Home address of the person who created compliance request' },

                { label: 'Contact', data: `${complianceRequestData.createdByDetails?.contact || ""}`, text: 'Contact number of the person who created compliance request' },
            ],
        },
        {
            groupName: 'Created To Details',
            items: [
                { label: 'Firstname', data: `${complianceRequestData.createdToDetails?.firstName || ""}`, text: 'First name of the person or entity to whom the compliance request was created' },
                { label: 'Lastname', data: `${complianceRequestData.createdToDetails?.lastName || ""}`, text: 'Last name of the person or entity to whom the compliance request was created' },
                { label: 'Email', data: `${complianceRequestData.createdToDetails?.email || ""}`, text: 'Email address of the person or entity to whom the compliance request was created' },
                { label: 'Address', data: `${complianceRequestData.address?.address || ""}`, text: 'Home address of the person or entity to whom the compliance request was created' },
                // { label: 'Address for communication', data: `${complianceRequestData.createdToDetails?.addressForCommunication || ""}`, text: 'Home address of the person who created compliance request' },

                { label: 'Contact', data: `${complianceRequestData.createdToDetails?.contact || ""}`, text: 'Contact number of the person or entity to whom the compliance request was created' },
            ],
        },
    ];
    
    const crFineTable=()=>{
      return <CustomMaterialWithoutRoutes columns={viewCrFineTable} data={fineDetails} />
    }
    if (
      moment().diff(moment( complianceRequestData?.crCreatedDate || complianceRequestData?.createdDateAndTime ), 'days') >= 30 &&
      HomeLoginId !== complianceRequestData.createdToDetails?.homeOwnerLoginId &&
      ( complianceRequestData.status === "ACKNOWLEDGED"  || (complianceRequestData.status === "NOT RESOLVED") || complianceRequestData.status === "PENDING") ||
      homeDues !== 0
    ) {
      data.splice(5, 0, {
          groupName: 'Compliance Request Fine Details',
          items: [
            ...(moment().diff(moment(complianceRequestData?.crCreatedDate || complianceRequestData?.createdDateAndTime), 'days') >= 30 &&
                  HomeLoginId !== complianceRequestData.createdToDetails?.homeOwnerLoginId && (complianceRequestData.status === "ACKNOWLEDGED"  || (complianceRequestData.status === "NOT RESOLVED" ) || complianceRequestData.status === "PENDING")
                  ? [
                    { label: 'Compliance Fine Applied', data: `${complianceRequestData.isFineApplicable? 'Yes' : 'No'}` ,text: 'Indicates if a fine is applicable for this compliance request.'}, 
                      {
                          label: 'Apply Fine',
                          data: <CustomLateFees
                              groupId={complianceRequestData.createdToDetails?.groupId}
                              homeOwnerDetailsId={complianceRequestData.createdToDetails?.homeOwnerDetailsId}
                              homeOwnerLoginId={complianceRequestData.createdToDetails?.homeOwnerLoginId}
                              homeId={complianceRequestData.address?.homeId}
                              complainceId={complianceRequestData?.complainceId}
                              getData={getComplianceRequestDetailsById}
                          />
                      }
                  ]
                  : []
              ),
              ...(homeDues !== 0
                  ? [
                      {
                          label: "Compliance Request Fine",
                          data: `${parseFloat(homeDues).toFixed(2)}`,
                          text: "The Compliance Request Fine is for raised compliance that has not been resolved or responded to by a particular homeowner."
                      },{
                        label:"Compliance Request Fine History",
                        data:crFineTable()
                      }
                  ]
                  : []
              ),
             
          ],
      });
    }
    
  
      
    
      const viewDataTable = useMemo((rowData) => [
        // {
        //   accessorKey: "serialNo",
        //   header: "S.No",
        //   size: 20,
        //   Cell: ({ row }) => row.index + 1,
        // },
        {
          accessorKey: "createdDateAndTime",
          header: "Assigned DateTime",
          accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY HH:mm:ss A z"),
          size: 20
        },
        {
          accessorKey: "assignedToName",
          header: "Assigned To",
          size: 20
       
        },
        {
          accessorKey: "status",
          header: "Review OutCome", 
          size: 20
        },
        //       {
        //   accessorKey: "createdDateAndTime",
        //   header: "Review DateTime",
        //   accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY HH:mm:ss A z"),
        //   size: 20
        // },
        // {
        //   accessorKey: "comments",
        //   header: "Review Comments",
        //   size: 20
        // },
      ]);
      const handleDeleteClose = () => {
        getComplianceRequestDetailsById()
        setDeleteOpen(false);
      };
      const okCancelButtonActions = (
        <>
          
          <CustomButton
            fieldType="button"
            buttonName="Cancel"
            click={handleDeleteClose}
          />
          <CustomButton
            fieldType="submit"
            buttonName="Ok"
            click={handleDeleteClose}
          />
        </>
      );
      const deleteModel = (
        <form
          onSubmit={handleSubmitGeneral(onSubmitDelete)}
          className="space-y-4 md:space-y-6 "
        >
          <DialogContent dividers>
            <Typography> Are you sure you want to delete comment?</Typography>
          </DialogContent>
          <DialogActions>{okCancelButtonActions}</DialogActions>
        </form>
      );
      

    return (
      <div>
        {/* <CustomViewPage data={data}/> */}
        <CustomViewHeading onClick={handleClick}  title={"View Compliance Request"}
         data={data} fileList={fileList} description={(complianceRequestData?.description || "")}>


      {/* <CustomViewWithTooltip data={data}  /> */}
      <CustomViewWithMore data={data}  />

     </ CustomViewHeading>
        <CustomToastContainer />
        <Divider/>
        {isLoading && <CustomLoading />}
        {(selectedStatus !== "CLOSED") && (
          <Card>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-4 md:space-y-6 "
          >
            <CardContent>
              <section class="grid md:grid-cols-2 xl:grid-cols-2 gap-5">
 
  
              <Controller
                  name="status"
                  control={control}
                  // rules={{ required: STATUS_REQUIRED_MESSAGE }}
                  render={({ field, fieldState }) => (
                    <FormControl
                      variant="standard"
                      fullWidth
                      error={fieldState.invalid}
                                          >
                      <InputLabel id="demo-simple-select-standard-label">
                        Please  Select Status <span style={{ color: "red" }}> *</span>
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={status}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          setStatus(e.target.value)
                                                }}
                        label="Please  Select Status"
                      >
                        <MenuItem value="PENDING" >
                        Pending
                        </MenuItem>
                        <MenuItem value="ACKNOWLEDGED" disabled={
                          selectedStatus === "PENDING" ||
                          selectedStatus === "RESOLVED" ||
                          selectedStatus === "ACKNOWLEDGED" ||
                          selectedStatus === "NOT RESOLVED" ||
                          selectedStatus === "CLOSED"
                        }>Acknowledged</MenuItem>
                        <MenuItem value="CLOSED" >Closed</MenuItem>
                        <MenuItem value="RESOLVED" disabled={
                          selectedStatus === "PENDING" ||
                          selectedStatus === "RESOLVED" ||
                          selectedStatus === "ACKNOWLEDGED" ||
                          selectedStatus === "Not Resolved" ||
                          selectedStatus === "CLOSED"
                        }>
                        Resolved
                        </MenuItem>
                        <MenuItem value="NOT RESOLVED">
                        Not Resolved
                        </MenuItem>
                      </Select>
                      <FormHelperText style={{ color: "#D32F2F" }}>
                        {fieldState.invalid ? fieldState.error?.message : ""}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
  
  
                                <CustomInputField
                  fieldName="reviewComment"
                  requiredErrorMessage={REVIEW_COMMENT_REQUIRED_MESSAGE}
                  minLength={2}
                  minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                  maxLength={1000}
                  maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
                  fieldId="reviewComment"
                  fieldType="text"
                  fieldLabel="Review Comment"
                  fieldControl={control}
                  fieldError={errors}
                  fieldRequiredIcon="*"
                  multiline={true}
                  numberOfRows={1}
                  value={reviewCommentss}
                  onInput={handleInputreviewComments}
                />
                 <CustomFile 
        handleFileChange={handleFileChange}
        token={token}
        fileError={fileError}
        file={file}
      />
              </section>
              <div class="text-center mt-10">
                <CustomButton fieldType="submit" buttonName="Update" />
              </div>
            </CardContent>
          </form>
        </Card>
         )}
         <Divider/>
         <br />
         <CustomReviewTable
            columns={viewDataTable}
            data={reviewComment}
            /> 
         
        <CustomGeneralComments/>
      </div>
    )
  }

export default ArcComplianceView