import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import axios from 'axios';
import { useSelector } from "react-redux";
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import InsertDriveFileOutlined from '@mui/icons-material/InsertDriveFileOutlined';
import DvrIcon from '@mui/icons-material/Dvr';
import NotificationsActiveOutlined from '@mui/icons-material/NotificationsActiveOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import { BASE_URL, DASHBOARD_PRESIST } from "../../utills/ApplicationRouting";

const Card = ({ cardData, index }) => {
  const renderIcon = () => {
    switch (cardData?.name) {
      case "Contact Details":
        return <PhoneEnabledIcon />;
      case "Documents":
        return <InsertDriveFileOutlined />;
      case "Maintenance Requests by Homeowner":
      case "Maintenance Requests by General User":
      case "Maintenance Requests":
        return <HandymanOutlinedIcon />;
      case "Compliance Requests":
      case "Compliance Requests By Homeowner":
      case "Compliance Requests By ARC":
        return <DvrIcon />;
      case "Notifications":
        return <NotificationsActiveOutlined />;
      case "List of calendar events":
        return <CalendarMonthIcon />;
      case "Card Balance Summary":
        return <MonetizationOnIcon />;
      default:
        return null;
    }
  };

  return (
    <Draggable draggableId={`card-${index}`} index={index}>
      {(provided, snapshot) => (
        <Link to={cardData?.linkTo} title={cardData?.tooltip}>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`draggable-container ${snapshot.isDragging ? "dragging" : ""}`}
          >
            <div className="flex items-center p-10 bg-white rounded-lg shadow-lg">
              <div
                className={`inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-${cardData?.color}-600 bg-${cardData?.color}-100 rounded-full mr-6 relative`}
                style={{ backgroundColor: cardData?.color }}
              >
                <span style={{ fontSize: '24px', color: 'inherit' }}>
                  {renderIcon()}
                  {cardData?.badgeContent !== 0 && cardData?.badgeContent && (
                    <span
                      style={{
                        display: 'inline-block',
                        fontSize: '11px',
                        top: '11px',
                        right: '10px',
                        backgroundColor: '#cc0000',
                        color: 'white',
                        borderRadius: '50%',
                        width: '17px',
                        height: '17px',
                        textAlign: 'center',
                        lineHeight: '17px',
                        position: 'absolute',
                      }}
                    >
                      {cardData?.badgeContent > 9 ? '9+' : cardData?.badgeContent}
                    </span>
                  )}
                </span>
              </div>
              <div>
                <div className="max-w-xs max-h-100 mx-auto">
                  <span className="block text-xm md:text-xs lg:text-xl font-bold break-all">{cardData?.footer}</span>
                </div>
                <span className="block text-2xl font-bold">{cardData?.count}</span>
                <span className="block text-gray-500 text-xs mt-2">{cardData?.name}</span>
              </div>
            </div>
          </div>
        </Link>
      )}
    </Draggable>
  );
};

const CardLayout = ({ cardData, type }) => {
  const [order, setOrder] = useState([]);
  const [isNewOrder, setIsNewOrder] = useState(false);
  const { token, roles, userLoginDetails, homeId } = useSelector((state) => state.hybridhoa);
  const homeOwnerLoginId = userLoginDetails.homeOwnerLoginId;

  const fetchOrder = async (url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200 && response.data.length > 0) {
        const parsedValue = JSON.parse(response.data[0].value); // Parse the value string
        setOrder(parsedValue);
        setIsNewOrder(false);
      } else {
        // If data is empty or not valid, initialize order with default values
        const defaultOrder = cardData.map((_, index) => index);
        setOrder(defaultOrder);
        setIsNewOrder(true);
      }
    } catch (error) {
      console.error('Error fetching card order:', error);
      // If there's an error, initialize order with default values
      const defaultOrder = cardData.map((_, index) => index);
      setOrder(defaultOrder);
      setIsNewOrder(true);
    }
  };

  useEffect(() => {
    if (homeId && homeId.length === 0){
    if (roles.includes("ROLE_MC") || roles.includes("ROLE_GU") || roles.includes("ROLE_ARC") || roles.includes("ROLE_BM")) {
      fetchOrder(`${BASE_URL}${DASHBOARD_PRESIST}/${roles[0]}`);
    }
    } else if (roles.includes("ROLE_HO") || roles.includes("ROLE_ARC") || roles.includes("ROLE_BM")) {
      fetchOrder(`${BASE_URL}${DASHBOARD_PRESIST}/${roles[0]}/${homeId}`);
    }
  }, [type, token, cardData, roles, homeId]);

  
    const saveCardOrder = (updatedOrder) => {
      const payload = {
        type,
        role: roles[0], // Assuming the first role is what you need
        value: JSON.stringify(updatedOrder) // Convert the array to a JSON string
      };
    
      // Include homeId if it's not null, otherwise include homeOwnerLoginId
      if (homeId && homeId.length > 0) {
        payload.homeId = homeId;
      } else {
        payload.homeOwnerLoginId = homeOwnerLoginId; // Send homeOwnerLoginId if homeId is null or empty
      }

    const url = isNewOrder ? `${BASE_URL}${DASHBOARD_PRESIST}` : `${BASE_URL}${DASHBOARD_PRESIST}/${homeOwnerLoginId}`;
    const requestMethod = isNewOrder ? axios.post : axios.put;

    requestMethod(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => {
      console.log('Card order saved successfully:', response.data);
      setIsNewOrder(false);
    })
    .catch(error => {
      console.error('Error saving card order:', error);
    });
  };

  const moveCard = (result) => {
    if (!result.destination) return;

    const updatedOrder = [...order];
    const [movedCard] = updatedOrder.splice(result.source.index, 1);
    updatedOrder.splice(result.destination.index, 0, movedCard);

    setOrder(updatedOrder);
    saveCardOrder(updatedOrder);
  };

  const orderedCards = order.length > 0 ? order.map((index) => cardData[index]) : cardData;

  return (
    <DragDropContext onDragEnd={moveCard}>
      <Droppable droppableId="cardLayout" direction="horizontal">
        {(provided) => (
          <section
            className="grid md:grid-cols-2 xl:grid-cols-3 gap-6"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {orderedCards.map((card, index) => (
              <Card key={index} index={index} cardData={card} />
            ))}
            {provided.placeholder}
          </section>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default CardLayout;
