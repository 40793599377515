import React, { useEffect, useMemo, useState } from "react";
import { json, useNavigate, useParams } from "react-router-dom";
import CustomViewPage from "../../../components/CustomViewPage";
import CustomHeading from "../../../components/CustomHeading";
import {
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  Divider,
  Typography,Button,
} from "@mui/material";
import CustomInputField from "../../../components/CustomInputField";
import {
  COMMENTS_REQUIRED_MESSAGE,
  DESCRIPTION_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_THOUSAND,
  MAX_LENGTH_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
} from "../../../utills/ApplicationConstants";
import CustomButton from "../../../components/CustomButton";
import { useForm } from "react-hook-form";
import CustomMaterialTable from "../../../components/CustomMaterialTable";
import CustomModel from "../../../components/CustomModel";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL, DOCUMENT, DOCUMENT_REVIEW_UPDATE, GENERAL_COMMENTS, GET_ALL_GENERAL_COMMENTS } from "../../../utills/ApplicationRouting";
import moment from "moment/moment";
import { DeleteOutline, EditNoteOutlined, FileDownloadOutlined } from "@mui/icons-material";
import GeneralComments from "../../generalcomments/GeneralComments";
import { request } from "../../../services/AxiosConfig";
import CustomLoading from "../../../components/CustomLoading";
import CustomToastContainer from "../../../components/CustomToastContainer";
import CustomMaterialWithoutRoutes from "../../../components/CustomMaterialWithoutRoutes";
import CustomViewPageExpand from "../../../components/CustomViewPageExpand";
import CustomReviewTableWithoutActions from "../../../components/CustomReviewTableWithoutActions";
import CustomViewWithTooltip from "../../../components/CustomViewWithTooltip";
import CustomViewHeading from "../../../components/CustomViewHeading";
import AttachmentListsView from "../../../components/AttachmentListsView";
import dayjs from "dayjs";
import CustomReviewTable from "../../../components/CustomReviewTable";
import CustomMainButton from "../../../components/CustomMainButton";
function McBmArcDocumentView() {
  const { token, roles,userLoginDetails,userDetails } = useSelector((state) => state.hybridhoa);
  const {documentId} = useParams();
  const [documentData,setDocumentData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [approveOpen, setApproveOpen] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);
  const [abstainOpen, setAbstainOpen] = useState(false);
  const [generalCommentDetails, setGeneralCommentDetails] = useState("");
  const [editModes, setEditModes] = useState(Array.isArray(generalCommentDetails) ? Array(generalCommentDetails.length).fill(false) : []);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [generalCommentsData,setGeneralCommentsData] = useState('');
  const [commentsId,setCommentsId] = useState('');
  const [firstNameData,setFirstNameData] =useState('');
  const [lastNameData,setLastNameData] = useState('');
  const [viewData,setViewData] = useState([]);
  const [fileUrl, setFileUrl] = useState([]);
  const [createdDateAndTime, setCreatedDateAndTime] = useState("");
  // const[hide,setHide]=useState(false)
  const[status,setStatus]=useState('')
  const isAnotherUserLoggedIn = userLoginDetails && userLoginDetails !== "";
  const HomeLoginId= userDetails.homeOwnerLoginId;
  const [generalCommentsDataAdd, setGeneralCommentsDataAdd] = useState("");
  const [showFullDescription, setShowFullDescription] = useState(false); 


  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  const handleApproveClickOpen = () => {
    setApproveOpen(true);
  };
  const handleApproveClose = (status) => {
    if (status == 200) {
      setApproveOpen(false);
    }
  };
  const handleApproveIconClose = () => {
    setApproveOpen(false);
  };

  const handleRejectClickOpen = () => {
    setRejectOpen(true);
  };
  const handleRejectClose = (status) => {
    if (status == 200) {
      setRejectOpen(false);
    }
  };
  const handleRejectIconClose = () => {
    setRejectOpen(false);
  };
  const handleAbstainClickOpen = () => {
    setAbstainOpen(true);
};
const handleAbstainClose = (status) => {
  if (status === 200) {
      setAbstainOpen(false);
  }
};
const handleAbstainIconClose = () => {
  setAbstainOpen(false);
};

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
    setError
  } = useForm();
 
  const onSubmitReject = (data) => {

    if (!data.rejectDescription) {
      setError("rejectDescription", {
        type: "manual",
        message:DESCRIPTION_REQUIRED_MESSAGE,
      });
      return;
    }else if (data.rejectDescription.length < 2 || data.rejectDescription.length > 1000) {
      setError("rejectDescription", {
        type: "manual",
        message: data.rejectDescription.length < 2
          ? MIN_LENGTH_VALIDATE_MESSAGE
          : MAX_LENGTH_THOUSAND,
      });
      return;
    }
    const rejectData = {
      status : "REJECTED",
      comments : data.rejectDescription
    }
    setIsLoading(true);
    request({ url: `${DOCUMENT_REVIEW_UPDATE}/${documentId}`, method: "put", data: rejectData })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
        handleRejectClose(res.status)
          getDocumentDetailsById();
          console.log("Success");
          setIsLoading(false);
       
          
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const onSubmitAbstain = (data) => {
    if (!data.abstainDescription) {
      setError("abstainDescription", {
        type: "manual",
        message:DESCRIPTION_REQUIRED_MESSAGE,
      });
      return;
    }else if (data.abstainDescription.length < 2 || data.abstainDescription.length > 1000) {
      setError("abstainDescription", {
        type: "manual",
        message: data.abstainDescription.length < 2
          ? MIN_LENGTH_VALIDATE_MESSAGE
          : MAX_LENGTH_THOUSAND,
      });
      return;
    }
    setIsLoading(true);
    const abstainData = {
        status: "Abstained",
        comments: data.abstainDescription || "I am okay if everyone is okay",
    };
    request({
        url: `${DOCUMENT_REVIEW_UPDATE}/${documentId}`,
        method: "put",
        data: abstainData,
    })
    .then((res) => {
        console.log(res);
        if (res.status === 200) {
            handleAbstainClose(res.status);
            getDocumentDetailsById();
            console.log("Success");
            setIsLoading(false);
        }
    })
    .catch((error) => {
      setIsLoading(false);
        console.log(error.response);
        if (error.response) {
            console.log("error.response.status" + error.response.status);
        }
    });
  };


const getDocumentDetailsById = async () => {
  try {
    setIsLoading(true);
    const storedToken = token;

    const response = await axios.get(`${BASE_URL}${DOCUMENT}/${documentId}`, {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    });

    if (response.status === 200) {
      const data = response.data;
      const createdDetails = data.createdByDetails;
      setViewData(data.reviewComments);

      console.log("review commtnsa "+JSON.stringify(data.reviewComments));
      console.log("createddetails"+JSON.stringify(createdDetails));
      console.log("data"+JSON.stringify(data));
//       // Check if any comment is for the current user and is not pending
//       const createdForValues  = data.reviewComments.map(comment => 
//         comment.createdFor === userLoginDetails.homeOwnerLoginId &&
//         comment.status === "PENDING"
//       );
//       const anyTrue = createdForValues.includes(true);
// console.log("anyTrue",anyTrue);
//       setHide(anyTrue); // Set hide based on the condition
      setStatus(data.status)
      setFirstNameData(createdDetails.firstName);
      setLastNameData(createdDetails.lastName);
      setFileUrl(data.attachments);
      const formattedDate = moment(data.createdDateAndTime).format("MM/DD/YYYY hh:mm A");
      setCreatedDateAndTime(formattedDate);
      setDocumentData(data);

      setIsLoading(false);
    } else {
      setIsLoading(false);
      throw new Error('Failed to fetch data');
    }
  } catch (error) {
    setIsLoading(false);
    console.error("Error fetching data:", error);
  }
};



useEffect(()=>{
  getDocumentDetailsById();
},[])

// useEffect(()=>{

// console.log("hide",hide);
// },[hide])

const fullName = firstNameData + " " + lastNameData;
const fileList = fileUrl.map(file => ({
  docname: file.file.substring(12), // Assuming file name is the document name
  fileUrl: file.file, // Assuming file location is the file URL
  documentnamed: file.file, // Assuming file name is the document name
  uploadedTimes: moment(file.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"), // Assuming uploadedTimes is available
  }));
  

const handleEditClick = (index, generalCommentsId) => {
  console.log("Edit button clicked", index, generalCommentsId);
  setEditModes((prevModes) =>
    prevModes.map((mode, i) => (i === index ? true : mode))
  );
  setCommentsId(generalCommentsId);
  console.log("CommentsId set:", generalCommentsId);
  getGeneralCommentsById(); 
};

const handleInputChange = (e) => {
  setGeneralCommentsData(e.target.value);
};

const handleCancelClick = () => {

  getGeneralComments();
  setEditModes(false);
};

const getGeneralComments = async () => {
  try {
    setIsLoading(true);
    const storedToken = token;

    console.log(storedToken);

    const response = await axios.get(
      `${BASE_URL}${GET_ALL_GENERAL_COMMENTS}/${documentId}`,
      {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      }
    );
    console.log(response.data);

    if (response.status === 200) {
      const data = response.data;
      setGeneralCommentDetails(data);
      setEditModes(Array(data.length).fill(false));
      setIsLoading(false);
    } else {
      setIsLoading(false);
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    // Handle error
    setIsLoading(false);
    console.error("Error fetching data:", error);
  }
};

useEffect(() => {
  getGeneralComments();
}, []);
const handleInputChangeAdd = (e) => {
  setGeneralCommentsDataAdd(e.target.value);
};
const onSubmit = (data,e) => {
  

  if (typeof generalCommentsDataAdd !== 'string' || generalCommentsDataAdd.trim() === '') {
    setError("comments", {
      type: "manual",
      message: COMMENTS_REQUIRED_MESSAGE,
    });
    setIsLoading(false);
    return;
  }
  setIsLoading(true);
  const postData = {
    comments:generalCommentsDataAdd ,
    referenceId: documentId,
  };
  // if (data.comments == '' || data.comments == null) {
  //   setError("comments", {
  //     type: "manual",
  //     message: COMMENTS_REQUIRED_MESSAGE,
  //   });
  //   setIsLoading(false);
  //   return;
  // }
  request({ url: GENERAL_COMMENTS, method: "post", data: postData })
    .then((res) => {
      console.log(res);
      if (res.status == 200) {
      
        getGeneralComments();
        console.log("Success");
        setIsLoading(false);
        // e.target.reset();
        setGeneralCommentsDataAdd("")  

       
        
      }
    })
    .catch((error) => {
      setIsLoading(false);
      console.log(error.response);
      if (error.response) {
        console.log("error.response.status" + error.response.status);
      }
    });
};



const getGeneralCommentsById = async () => {
  try {
    setIsLoading(true);
    const storedToken = token;

    console.log(storedToken);

    const response = await axios.get(
      `${BASE_URL}${GENERAL_COMMENTS}/${commentsId}`,
      {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      }
    );
    console.log(response);

    if (response.status === 200) {
      const data = response.data;
      console.log(response.data);
     setGeneralCommentsData(data.comments);
    
      setIsLoading(false);
    } else {
      setIsLoading(false);
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    // Handle error
    setIsLoading(false);
    console.error("Error fetching data:", error);
  }
};

useEffect(() => {
  getGeneralCommentsById();
}, [commentsId]);


const handleDeleteClose = () => {
  setDeleteOpen(false);
};

const onSubmitDelete = () => {
  request({url:`${GENERAL_COMMENTS}/${commentsId}`,method:"delete"}).then(res=>{
    console.log(res);
getGeneralComments();

  }).catch(error=>{
console.log(error);
  });
};

const onSubmitApprove =(data) =>{
  if (!data.approveDescription) {
    setError("approveDescription", {
      type: "manual",
      message:DESCRIPTION_REQUIRED_MESSAGE,
    });
    return;
  }else if (data.approveDescription.length < 2 || data.approveDescription.length > 1000) {
    setError("approveDescription", {
      type: "manual",
      message: data.approveDescription.length < 2
        ? MIN_LENGTH_VALIDATE_MESSAGE
        : MAX_LENGTH_THOUSAND,
    });
    return;
  }
  setIsLoading(true);
  const postData = {
    status : "APPROVED",
    comments : data.approveDescription
  }
  request({ url: `${DOCUMENT_REVIEW_UPDATE}/${documentId}`, method: "put", data: postData })
    .then((res) => {
      console.log(res);
      if (res.status == 200) {
      handleApproveClose(res.status)
        getDocumentDetailsById();
        console.log("Success");
        setIsLoading(false);
      }
    })
    .catch((error) => {
      setIsLoading(false);
      console.log(error.response);
      if (error.response) {
        console.log("error.response.status" + error.response.status);
      }
    });
}

const okCancelButtonActions = (
  <>
    <CustomButton
      fieldType="submit"
      buttonName="Ok"
      click={handleDeleteClose}
    />
    <CustomButton
      fieldType="button"
      buttonName="Cancel"
      click={handleDeleteClose}
    />
  </>
);

const deleteModel = (
  <form
    onSubmit={handleSubmit(onSubmitDelete)}
    className="space-y-4 md:space-y-6 "
  >
    <DialogContent dividers>
      <Typography> Are you sure you want to delete comment?</Typography>
    </DialogContent>
    <DialogActions>{okCancelButtonActions}</DialogActions>
  </form>
);


const convertToPascalCase = (str) => {
  // Check if the input string is undefined or empty
  if (str === undefined || str === '' || str === null) return '';

  // Remove underscores and convert string to Pascal case
  return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};

const handleToggleDescription = () => {
  setShowFullDescription(!showFullDescription);
};
useEffect(() => {
  window.scrollTo(0, 0);
}, [showFullDescription]);
useEffect(() => {
  window.scrollTo(0, 0);
}, [viewData]);
console.log(documentData);
const renderDescription = () => {
  console.log("inside render ");
  if (documentData.description && !showFullDescription) {
  console.log("inside render ",documentData.description);

    return (
      <>
      
        {documentData.description.slice(0, 250)}
        {documentData.description.length > 250 && (
          <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Read More</Button>
        )}
      </>
    );
  } else {
  console.log("inside render else ",documentData.description);

    return (
      <>
        {documentData.description}
        <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Less</Button>
      </>
    );
  }
};

// const today = new Date();
// today.setHours(0, 0, 0, 0); 
const today = dayjs().startOf('day');
console.log("documentDate"+documentData.publishDate);
const publishDate = dayjs(documentData.publishDate);
console.log("today "+today);
console.log("publishdate "+publishDate);



const rowActions = ({ rowData })=>{
  // const isDisabled = ["PUBLISHED", "REJECTED", "APPROVED","PENDING PUBLISH"].includes(documentData.status);  
const isDisabled = documentData.publishDate && new Date() >= new Date(documentData.publishDate);
const tooltipText = isDisabled ?"The publish date has passed, so you can't change your opinion.":"";

  return(
  <div>
  {/* Conditionally render the buttons based on the document status */}
  <div className="text-center flex space-x-3">
    {console.log("stautusss"+JSON.stringify(rowData.status))}
  {roles != "ROLE_ARC" && rowData.createdFor===userLoginDetails.homeOwnerLoginId && (!documentData.publishDate <= today ) &&(
    <>
     <CustomMainButton
          fieldType="submit"
          buttonName="Approve"
          click={handleApproveClickOpen}
          disabled={isDisabled }
          tooltipText={tooltipText}
          tooltipPlacement="right"
        
        
        // disabled={(documentData.status ==="PUBLISHED") ||(documentData.status ==="REJECTED") || (documentData.status ==="APPROVED")}
        //      tooltipText={(documentData.status != "PUBLISHED") ||(documentData.status != "REJECTED") ||(documentData.status !="APPROVED") ? "" : "You have reached the 51% criteria"}

             
      /> &nbsp;
      <CustomMainButton
        fieldType="submit"
        buttonName="Reject"
        disabled={isDisabled }
          tooltipText={tooltipText}
          tooltipPlacement="right"
        // disabled={documentData.status === ("PUBLISHED" ||"REJECTED" || "APPROVED")}
        //      tooltipText={documentData.status !== ("PUBLISHED" ||"REJECTED" || "APPROVED") ? "" : "You have reached the 51% criteria"}
        click={handleRejectClickOpen}
      />
       <CustomMainButton
    fieldType="submit"
    buttonName="Abstain"
    click={handleAbstainClickOpen}
    disabled={isDisabled }
          tooltipText={tooltipText}
          tooltipPlacement="right"
    // tooltipText={documentData.status !== ("PUBLISHED" ||"REJECTED" || "APPROVED") ? "" : "You have reached the 51% criteria"}
    //         tooltipPlacement="right"
    // disabled={documentData.status === ("PUBLISHED" ||"REJECTED" || "APPROVED")}

    />
      <br />
      <br />
    </>
  )}
</div>

</div>
  )
};

// const data = [
//   {
//     groupName:'Review Document',
//     items : [
//       {
//         label: "Category",
//         // description: documentData.category,
//         data :convertToPascalCase(documentData.category)
//       },
//       {
//         label: "Description",
//         data: renderDescription(),
//       },
//       {
//         label: "Created By",
//         data: fullName,
//       },
      
//       {
//         label: "Created Date Time", 
//         data: moment(documentData.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
//       },
//       {
//         label: "Publish Date",
//         data: documentData.publishDate,
//       },
//       {
//         label: "Status",
//         // data: !documentData.isPublic && documentData.category === "PUBLIC"
//         //   ? (documentData.status === "PENDING"
//         //       ? `${convertToPascalCase(documentData.status)} (Document will be published when it reaches 51% criteria)`
//         //       : convertToPascalCase(documentData.status))
//         //   : ( documentData.status === "PENDING PUBLISH" || documentData.status === "PENDING"
//         //       ? `${convertToPascalCase(documentData.status)} (Document will be published when it reaches the publish date)`
//         //       : convertToPascalCase(documentData.status))
//         data: !documentData.isPublic && documentData.category === "PUBLIC" && documentData.status === "PENDING" 
//         ? `${convertToPascalCase(documentData.status)} (Document will be published when it reaches 51% criteria)` 
//         : (documentData.status === "PENDING" || documentData.status === "PENDING PUBLISH" 
//         ?`${convertToPascalCase(documentData.status)} (Document will be published when it reaches the publish date)`
//         : convertToPascalCase(documentData.status))
//       },
//       {
//         label:"Document Type",
//         data: (documentData.category === "CONFIDENTIAL" 
//         ? "Confidential"
//         : documentData.isPublic 
//           ? "General Document - This will be Published General Document to Homeowners" 
//           : "Need 51% Approval from Board Member")
//       },    
//       // {
//       //   label: "Attachments",
//       //   data:(
//       //     <AttachmentListsView fileList={fileList} />
//       //   )},
       
    
//     ],

//   },
//   {
//     groupName: 'Attachment Details',
//     items: [
//         { label: 'Attachment', data: <AttachmentListsView fileList={fileList} />, text: 'List of Document attached to Document' }
//     ]
// }

 
// ];

  const data = [
    {
      groupName:'Document Details',
      items : [
        {
          label: "Category",
          // description: documentData.category,
          data :convertToPascalCase(documentData.category),
          text: 'The category or subject of the Document' 
        },
        {
          label: "Description",
          data: renderDescription(),
          text : 'Detailed description of the Document'
        },
        // {
        //   label: "Created By",
        //   data: fullName,
        // },
        
        {
          label: "Created Date Time", 
          data: moment(documentData.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
          text: 'The date when the Document was created.'
        },
        {
          label: "Publish Date",
          data: documentData.publishDate,
          text: 'The date when the Document will be published.'
        },
        {
          label: "Status",
          // data: !documentData.isPublic && documentData.category === "PUBLIC"
          //   ? (documentData.status === "PENDING"
          //       ? `${convertToPascalCase(documentData.status)} (Document will be published when it reaches 51% criteria)`
          //       : convertToPascalCase(documentData.status))
          //   : ( documentData.status === "PENDING PUBLISH" || documentData.status === "PENDING"
          //       ? `${convertToPascalCase(documentData.status)} (Document will be published when it reaches the publish date)`
          //       : convertToPascalCase(documentData.status))
          data: !documentData.isPublic && documentData.category === "PUBLIC" && documentData.status === "PENDING" 
          ? `${convertToPascalCase(documentData.status)} (Document will be published when it reaches 51% criteria)` 
          : (documentData.status === "PENDING" || documentData.status === "PENDING PUBLISH" 
          ?`${convertToPascalCase(documentData.status)} (Document will be published when it reaches the publish date)`
          : convertToPascalCase(documentData.status)),
          text: 'Current status of the Document.'
        },
        {
          label:"Document Type",
          data: (documentData.category === "CONFIDENTIAL" 
          ? "Confidential"
          : documentData.isPublic 
            ? "General Document - This will be Published General Document to Homeowners" 
            : "Need 51% Approval from Board Member"),
            text: 'Type of Document and its requirement.',
        },    
        // {
        //   label: "Attachments",
        //   data:(
        //     <AttachmentListsView fileList={fileList} />
        //   )},
         
      
      ],

    },
    {
      groupName: 'Created By Details',
      items: [
          { label: 'Firstname', data: firstNameData || "", text: 'First name of the person who created Document' },
          { label: 'Lastname', data: lastNameData|| "", text: 'Last name of the person who created Document' },
          { label: 'Email', data: `${documentData.createdByDetails?.email || ""}`, text: 'Email address of the person who created Document' },
          

          { label: 'Address', data: `${documentData?.createdByHomeAddress || ""}`, text: 'Home address of the person who created Document' },
          { label: 'Address for communication', data: `${documentData.createdByDetails?.addressForCommunication || ""}`, text: 'Home address of the person who created Document' },

          { label: 'Contact', data: `${documentData.createdByDetails?.contact || ""}`, text: 'Contact number of the person who created Document' },
      ],
  },

    {
      groupName: 'Attachment Details',
      items: [
          { label: 'Attachment', data: <AttachmentListsView fileList={fileList} />, text: 'List of Document attached to Document' }
      ]
  }

   
  ];

  const viewDataTable = useMemo((rowData) => [

    // {
    //   accessorKey: "createdDateAndTime",
    //   header: "Assigned DateTime",
    //   size: 20,
    //   accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY hh:mm a"),
     
    // },
    {
      accessorKey: "name",
      header: "Name",
      size: 20,
    },
    {
      accessorKey: "status",
      header: "Review OutCome",
      size: 20,
    },
    {
      accessorKey: "auditDateAndTime",
      header: "Review DateTime",
      size: 20,
      accessorFn: (row) => moment(row.auditDateAndTime  ).format("MM/DD/YYYY hh:mm a"),
    },
    // {
    //   accessorKey: "comments",
    //   header: "Review Comments",
    //   size: 20,
    
    // }
  ]);

  const approveActionButton = (
    <CustomButton
      fieldType="submit"
      buttonName="Approve"
      click={handleApproveClose}
    />
  );
  const approveModel = (
    <>
      <form
        onSubmit={handleSubmit(onSubmitApprove)}
        className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography gutterBottom>
            <CustomInputField
              fieldName="approveDescription"
              // requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
              fieldId="approveDescription"
              // minLength={2}
              // minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              // maxLength={1000}
              // maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldLabel="Description"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
            />
          </Typography>
        </DialogContent>
        <DialogActions>{approveActionButton}</DialogActions>
      </form>
    </>
  );

  const rejectActionButton = (
    <CustomButton
      fieldType="submit"
      buttonName="Reject"
      click={handleApproveClose}
    />
  );
  const rejectModel = (
    <>
      <form
        onSubmit={handleSubmit(onSubmitReject)}
        className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography gutterBottom>
            <CustomInputField
              fieldName="rejectDescription"
              // requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
              fieldId="rejectDescription"
              // minLength={2}
              // minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              // maxLength={1000}
              // maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldLabel="Description"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
            />
          </Typography>
        </DialogContent>
        <DialogActions>{rejectActionButton}</DialogActions>
      </form>
    </>
  );

  const abstainActionButton = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Abstain"
        click={handleAbstainClose}
      ></CustomButton>
      {isLoading && <CustomLoading />}
    </>
  );
  const abstainModel = (
    <>
      <form
        onSubmit={handleSubmit(onSubmitAbstain)}
        className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography gutterBottom>
            <CustomInputField
              fieldName="abstainDescription"
              // requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
              fieldId="abstainDescription"
              // minLength={2}
              // minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              // maxLength={1000}
              // maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldLabel="Description"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
            />
          </Typography>
        </DialogContent>
        <DialogActions>{abstainActionButton}</DialogActions>
      </form>
    </>
);

  return (
    <div>
      {isLoading && <CustomLoading />}
      <CustomViewHeading onClick={handleClick} title={"Document"} data={data} fileName={`Document_${documentData.documentId}.pdf`}  fileList={fileList} >
      <CustomViewWithTooltip data={data}  />
      <Divider />
      <CustomToastContainer />
      
      {
      documentData.category === "CONFIDENTIAL" || documentData.isPublic == true  ?
       ""
      :
      <CustomReviewTable columns={viewDataTable} data={viewData} rowActions={rowActions}/>
      }
      

      <br />
      {/* {roles === "ROLE_BM" ? ( */}
        {/* <>
          <CustomButton
            fieldType="submit"
            buttonName="Approve"
            click={handleApproveClickOpen}
          /> &nbsp;
          <CustomButton
            fieldType="submit"
            buttonName="Reject "
            click={handleRejectClickOpen}
          />
          <br />
          <br />
        </> */}
  


       {/* ) : null} */}
       </CustomViewHeading>
      <CustomModel
        title="Approve Document"
        submit={onSubmitApprove}
        content={approveModel}
        action={approveActionButton}
        openStatus={approveOpen}
        closeStatus={handleApproveClose}
        iconCloseStatus={handleApproveIconClose}
        reset={reset}
      />

      <CustomModel
        title="Reject Document"
        submit={onSubmitReject}
        content={rejectModel}
        action={rejectActionButton}
        openStatus={rejectOpen}
        closeStatus={handleRejectClose}
        iconCloseStatus={handleRejectIconClose}
        reset={reset}
      />

<CustomModel
    title="Abstain Document"
    submit={onSubmitAbstain}
    content={abstainModel}
    action={abstainActionButton}
    openStatus={abstainOpen}
    closeStatus={handleAbstainClose}
    iconCloseStatus={handleAbstainIconClose}
    reset={reset}
/>

      
{/* <CustomHeading title="General Comments" /> */}
      {/* <CustomModel
        title=" Delete Comments"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        reset ={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      /> */}
      {/* <Card> */}
        {/* <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
            <CustomInputField
              fieldName="comments"
              // requiredErrorMessage={COMMENTS_REQUIRED_MESSAGE}
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldId="comments"
              fieldType="text"
              fieldLabel="Write a Comments"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
              value={generalCommentsDataAdd}
              onInput={handleInputChangeAdd}
            />

            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Post" />
              {isLoading && <CustomLoading />}
            </div>
          </CardContent>
        </form> */}
        {/* <CardContent>
          {generalCommentDetails &&
            generalCommentDetails.map((comment, index) => (
              <div key={comment.generalCommentsId}>
                <section className="grid md:grid-cols-1 xl:grid-cols-1 gap-3 mt-6 ml-16">
                  <p>
                    <span className="text-primary" style={{ float: "left" }}>
                    @ {comment.createdByName}{" "}
                    </span>
                    <span style={{ float: "right" }}>
                    

                      {!editModes[index] && comment.createdById === HomeLoginId &&  (
                        <>
                          {index === 0 && (
                            <>
                            
                                <EditNoteOutlined color="primary"  onClick={() => {
                                    handleEditClick(index, comment.generalCommentsId)
                                    console.log(comment.generalCommentsId)
                                }
                                  }/>
                                <DeleteOutline color="error" onClick={() => {
                                    handleDeleteClickOpen( comment.generalCommentsId)
                                    console.log(comment.generalCommentsId)
                                }
                                  }/>
                          
                            </>
                          )}
                        </>
                      )}
                        {moment(comment.createdDateAndTime).format(
                        "MM/DD/YYYY hh:mm:ss a"
                      )}
                    </span>
                    {editModes[index]? (
                    <form
                    onSubmit={handleSubmit(submitUpdate)}
                    className="space-y-4 md:space-y-6 "
                  >
                    <CardContent>
                      <CustomInputField
                        fieldName="editComments"
                      
                        fieldId="editComments"
                        fieldType="text"
                        // fieldLabel="Write a Comments"
                        fieldControl={control}
                        fieldError={errors}
                        // fieldRequiredIcon="*"
                        multiline={true}
                        numberOfRows={2}
                        value={generalCommentsData}
                        onInput={handleInputChange}
                      />
    
                      <div class="text-center mt-8 space-x-1">
                        
                        <CustomButton fieldType="submit" buttonName="Save" />

                        <CustomButton fieldType="button" buttonName="Cancel"  click={handleCancelClick}/>
                        {isLoading && <CustomLoading />}
                      </div>
                    </CardContent>
                  </form>
                  ) : (
                    <p className="mt-8 mb-8">{comment.comments}</p>
                  )}
                  </p>

                 
                </section>
        
                <Divider />
              </div>
            ))}
        </CardContent> */}
      {/* </Card> */}
    </div>
  );
}

export default McBmArcDocumentView;
