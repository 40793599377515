import React, { useEffect, useState } from "react";
import CustomHeading from "../../../components/CustomHeading";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, FormHelperText } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  ADDRESS_FOR_COMMUNICATION_INVALID_MESSAGE,
  ADDRESS_FOR_COMMUNICATION_REQUIRED_MESSAGE,
  ADDRESS_REQUIRED_MESSAGE,
  CITY_INVALID_MESSAGE,
  CITY_REQUIRED_MESSAGE,
  CONTACT_REQUIRED_MESSAGE,
  EMAIL_INVALID_MESSAGE,
  EMAIL_REQUIRED_MESSAGE,
  FIRST_NAME_INVALID_MESSAGE,
  FIRST_NAME_REQUIRED_MESSAGE,
  GENDER_REQUIRED_MESSAGE,
  LAST_NAME_INVALID_MESSAGE,
  LAST_NAME_REQUIRED_MESSAGE,
  MAX_LENGTH_THOUSAND,
  MAX_LENGTH_TWO_FIFTY,
  MAX_LENGTH_VALIDATE_MESSAGE,
  MIN_LENGTH_VALIDATE_MESSAGE,
  PHONE_REQUIRED_MESSAGE,
  STATE_INVALID_MESSAGE,
  STATE_REQUIRED_MESSAGE,
  ZIPCODE_INVALID_MESSAGE,
  ZIPCODE_MAX_LENGTH_MESSAGE,
  ZIPCODE_MIN_LENGTH_MESSAGE,
  ZIPCODE_REQUIRED_MESSAGE, MIN_LENGTH_VALIDATE_ONE,FAX_INVALID_MESSAGE,
  MAX_LENGTH_VALIDATE_MESSAGE_TEN,
} from "../../../utills/ApplicationConstants";
import {
  ALPHABETIC_WITH_SPECIAL_CHARACTERS,
  ALPHABETIC_WITH_UNDERSCORE,
  ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN,
  EMAIL_REGEX_PATTERNS,
  ONLY_ALPHABETIC,
  ONLY_DIGITS,FAX_REGEX_PATTERN,
} from "../../..//utills/ApplicationRegex";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import CustomInputField from "../../../components/CustomInputField";
import CustomCheckBox from "../../../components/CustomCheckBox";
import CustomRadioButton from '../../../components/CustomRadioButton';
import { request } from "../../../services/AxiosConfig";
import { BASE_URL, PROFILE } from "../../../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import CustomLoading from "../../../components/CustomLoading";
import CustomToastContainer from "../../../components/CustomToastContainer";
import CustomPhoneInput from "../../../components/CustomPhoneInput";
import { isValidPhoneNumber } from "react-phone-number-input";

function GuProfile() {

  const [profileData, setProfileData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { token, roles } = useSelector((state) => state.hybridhoa);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipcode] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [addressFrCommunication, setAddressFrCommunication] = useState("");
  const [isSameAsHomeAddress, setIsSameAsHomeAddress] = useState(false);
  const [gender, setGender] = useState("");
  const [originalAddressForCommunication, setOriginalAddressForCommunication] = useState("");
  const [contact, setContact] = useState("");
  const [userRole, setUserRole] = useState("");
 const [fax, setFax] = useState("");
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };
  const handleChangePassword = () => {
    navigate("/changepassword");
  };

  const getProfileDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${PROFILE}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setProfileData(data);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setCity(data.city);
        setState(data.state);
        setZipcode(data.zipCode);
        setEmail(data.email);
        setGender(data.gender);
        setAddress(data.addressForCommunication);
        setValue("phoneNumber", data.contact)
        setContact(data.contact);
        setIsLoading(false);
        setFax(data.fax)
        setValue("home", data.home)
        setValue("work", data.work)
        


        if (roles == "ROLE_GU") {
          setUserRole("ROLE_GU")
        } else if (roles == "ROLE_MC") {
          setUserRole("ROLE_MC")

        }
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getProfileDetails();
  }, []);

  const {
    handleSubmit,
    control,
    reset,
    setError,
    clearErrors, setValue,
    formState: { errors },
  } = useForm();

  const [response, setResponse] = useState([])

  const handleInputChange = (e) => {
    setFirstName(e.target.value);
  };
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  }

  const handleStateChange = (e) => {
    setState(e.target.value);
  }

  const handleZipCode = (e) => {
    setZipcode(e.target.value);
  }

  const handleFaxChange = (e) => {
    setFax(e.target.value);
  };

  const handleRadioChange = (value) => {
    setGender(value)
  }

  const handleAddressChange = (e) => {
    setAddress(e.target.value)
  }
  const handleRoleChange = (roles) => {

    console.log(userRole);
    console.log("inside role change");
    if (userRole == "ROLE_GU") {
      navigate('/dashboard-gu');
    } else if (userRole == "ROLE_MC") {
      navigate('/dashboard-mc')

    }

  };

  const onSubmit = (data) => {

    console.log("inside");
    const isValidPhoneNumberForCountry = isValidPhoneNumber(data.phoneNumber);

    if (!isValidPhoneNumberForCountry) {
      setIsLoading(false);
      return;
    }
    console.log("inside");

    setIsLoading(true);


    if (typeof firstName !== 'string' || firstName.trim() === '') {
      setError("firstName", {
        type: "manual",
        message: FIRST_NAME_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (typeof lastName !== 'string' || lastName.trim() === '') {
      setError("lastName", {
        type: "manual",
        message: LAST_NAME_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (typeof city !== 'string' || city.trim() === '') {
      setError("city", {
        type: "manual",
        message: CITY_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (typeof state !== 'string' || state.trim() === '') {
      setError("state", {
        type: "manual",
        message: STATE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (typeof zipCode !== 'string' || zipCode.trim() === '') {
      setError("zipCode", {
        type: "manual",
        message: ZIPCODE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (typeof address !== 'string' || address.trim() === '') {
      setError("address", {
        type: "manual",
        message: ADDRESS_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    } if (typeof gender !== 'string' || gender.trim() === '') {
      setError("gender", {
        type: "manual",
        message: GENDER_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    // if (!firstName|| firstName.trim() === null) {
    //   setError("firstName", {
    //     type: "manual",
    //     message: FIRST_NAME_REQUIRED_MESSAGE,
    //   });
    //   setIsLoading(false);
    //   return;
    // }
    // if (!lastName|| lastName.trim() === null) {
    //   setError("lastName", {
    //     type: "manual",
    //     message: LAST_NAME_REQUIRED_MESSAGE,
    //   });
    //   setIsLoading(false);
    //   return;
    // }
    // if (!city || city.trim() === null) {
    //   setError("city", {
    //     type: "manual",
    //     message: CITY_REQUIRED_MESSAGE,
    //   });
    //   setIsLoading(false);
    //   return;
    // }
    //  if (!state || state.trim() === '') {
    //     setError("state", {
    //         type: "manual",
    //         message: STATE_REQUIRED_MESSAGE,
    //     });
    //     setIsLoading(false);
    //     return;
    // }
    //   if (!zipCode || zipCode.trim() === '') {
    //     setError("zipCode", {
    //         type: "manual",
    //         message: ZIPCODE_REQUIRED_MESSAGE,
    //     });
    //     setIsLoading(false);
    //     return;
    // }
    // if (!address|| address.trim() === null) {

    //   setError("address", {
    //     type: "manual",
    //     message: ADDRESS_REQUIRED_MESSAGE,
    //   });
    //   setIsLoading(false);
    //   return;
    // }

    // if (!gender || gender.trim() === null) {
    //   setError("gender", {
    //     type: "manual",
    //     message: GENDER_REQUIRED_MESSAGE,
    //   });
    //   setIsLoading(false);
    //   return;
    // }

    const postData = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      city: city.trim(),
      state: state.trim(),
      zipCode: zipCode.trim(),
      email: email,
      address: address,

      gender: gender,
      contact: data.phoneNumber,
      role: userRole,
      fax:fax,
      work: data.work,
      home: data.home,

    }

    request({ url: PROFILE, method: "post", data: postData }).then(res => {
      console.log(res);
      if (res.status == 200) {
        console.log("Success");
        setIsLoading(false);
        setTimeout(() => {
          handleRoleChange()
        }, 4000);

      }
    }).catch(error => {
      console.log(error.response);
      if (error.response) {
        setIsLoading(false);
        console.log("error.response.status" + error.response.status);


      }
    });

  };



  return (
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomHeading title="Edit Profile" />
        <CustomToastContainer />

        <div class="md:w-full md:pl-4 sm:text-left md:text-right sm:w-full text-lg font-sans mb-2 flex space-x-2 justify-end">
        <CustomButton
            fieldType="button"
            buttonName="Change Password"
            click={handleChangePassword}
          />
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleClick}
          />
          
        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          {isLoading && (
            <CustomLoading />
          )}
          <CardContent>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomHeading title="Owner Details" />
              <CustomInputField
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={FIRST_NAME_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldName="firstName"
                fieldId="firstName"
                fieldType="text"
                fieldLabel="First Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={firstName}
                onInput={handleInputChange}
                tooltipMessage=" Update your first name here. This is how we'll address you in communications and personalize your experience on our platform"
              />

              <CustomInputField
                fieldName="lastName"

                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={LAST_NAME_INVALID_MESSAGE}
                minLength={1}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_ONE}
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldId="lastName"
                fieldType="text"
                fieldLabel="Last Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={lastName}
                onInput={handleLastNameChange}
                tooltipMessage=" Update your last name here. This is how we'll address you in communications and personalize your experience on our platform"
              />
            </section>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomHeading title="Gender" />
              <CustomRadioButton
                fieldName="gender"
                fieldControl={control}
                fieldError={errors}
                labelName="Gender"

                valueOne="Female"
                labelOne="Female"
                valueTwo="male"
                labelTwo="Male"
                value={gender}
                onChange={handleRadioChange}
              />
            </section>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomHeading title="Address" />
              <CustomInputField
                fieldName="address"
                fieldId="address"
                fieldType="text"
                fieldLabel="Address"
                fieldControl={control}
                fieldError={errors}
                fieldPattern={ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN}
                patternErrorMessage={ADDRESS_FOR_COMMUNICATION_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={250}
                maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
                fieldRequiredIcon="*"
                // disabled={true}
                value={address}
                onInput={handleAddressChange}
                tooltipMessage="Enter your address"
              />

              <CustomInputField
                fieldName="city"

                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={CITY_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldId="city"
                fieldType="text"
                fieldLabel="City"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={city}
                onInput={handleCityChange}
                tooltipMessage="The name of the city where the user is located"
              />
              <CustomInputField
                fieldName="state"

                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={STATE_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldId="state"
                fieldType="text"
                fieldLabel="State"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={state}
                onInput={handleStateChange}
                tooltipMessage="The name of the sate where the user is located"
              />

              <CustomInputField
                fieldName="zipCode"

                fieldPattern={ONLY_DIGITS}
                patternErrorMessage={ZIPCODE_INVALID_MESSAGE}
                minLength={5}
                minLengthErrorMessage={ZIPCODE_MIN_LENGTH_MESSAGE}
                maxLength={5}
                maxLengthErrorMessage={ZIPCODE_MAX_LENGTH_MESSAGE}
                fieldId="zipCode"
                fieldType="text"
                fieldLabel="ZipCode"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={zipCode}
                onInput={handleZipCode}
                tooltipMessage="zipcode"
              />



            </section>


            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomHeading title="Contact Details" />
              <CustomInputField
                fieldName="email"

                fieldPattern={EMAIL_REGEX_PATTERNS}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                fieldId="email"
                fieldType="text"
                fieldLabel="Email"
                fieldControl={control}
                fieldError={errors}
                // fieldRequiredIcon="*"
                value={email}
                disabled={true}
                tooltipMessage=" your current email address. This is how we'll communicate with you and keep you informed about important updates and notifications"
              />
              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: PHONE_REQUIRED_MESSAGE,
                  validate: {
                    isValidPhoneNumber: value => isValidPhoneNumber(value) || "Invalid phone number"
                  }
                }}
                render={({ field, fieldState }) => (
                  <>
                    <CustomPhoneInput
                      fieldControl={control}
                      label="Phone Number"
                      clearErrors={clearErrors}
                      country="US"
                      value={field.value}
                      tooltipMessage=" Enter your current phone number. This is how we'll communicate with you and keep you informed about important updates and notifications"
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      error={fieldState.invalid ? fieldState.error : null}
                      helperText={fieldState.invalid ? fieldState.error?.message : ''}
                    />
                    <div>
                      <Controller
                        name="home"
                        control={control}
                        rules={{
                          validate: {
                            isValidPhoneNumber: value => !value || isValidPhoneNumber(value) || "Please provide a valid contact number"
                          }
                        }}
                        render={({ field, fieldState }) => (
                          <>
                            <CustomPhoneInput
                              isRequiredIcon={false}
                              isRequired={false}
                              fieldControl={control}
                              label="Home"
                              clearErrors={clearErrors}
                              country="US"
                              fieldError={errors}
                              value={field.value}
                              tooltipMessage=" Enter your current home contact number. This is how we'll communicate with you and keep you informed about important updates and notifications"
                              onChange={(value) => {
                                field.onChange(value);
                              }}
                            />
                          </>
                        )}
                      />
                      {errors.home && (
                        <FormHelperText style={{ color: "#D32F2F" }}>
                          {errors.home.message}
                        </FormHelperText>
                      )}
                    </div>
                    <div>
                      <Controller
                        name="work"
                        control={control}
                        rules={{
                          validate: {
                            isValidPhoneNumber: value => !value || isValidPhoneNumber(value) || "Please provide a valid contact number"
                          }
                        }}
                        render={({ field, fieldState }) => (
                          <>
                            <CustomPhoneInput
                              isRequiredIcon={false}
                              isRequired={false}
                              fieldControl={control}
                              label="work"
                              clearErrors={clearErrors}
                              country="US"
                              fieldError={errors}
                              value={field.value}
                              tooltipMessage=" Enter your current work number. This is how we'll communicate with you and keep you informed about important updates and notifications"
                              onChange={(value) => {
                                field.onChange(value);
                              }}
                            />
                          </>
                        )}
                      />
                      {errors.home && (
                        <FormHelperText style={{ color: "#D32F2F" }}>
                          {errors.home.message}
                        </FormHelperText>
                      )}
                    </div>
                    <CustomInputField
                      fieldName="fax"
                      // requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
                      fieldPattern={FAX_REGEX_PATTERN}
                      patternErrorMessage={FAX_INVALID_MESSAGE}
                      maxLength={10}
                      maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE_TEN}
                      validator={false}
                      value={fax}
                      fieldId="fax"
                      fieldType="text"
                      fieldLabel="Fax"
                      fieldControl={control}
                      fieldError={errors}
                      tooltipMessage=" Enter your fax number. This is how we'll communicate with you and keep you informed about important updates and notifications"
                    // fieldRequiredIcon="*"
                    onInput={handleFaxChange}
                    />




                  </>
                )}
              />

            </section>
            <br />
            <br />



            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Update" />
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}

export default GuProfile