import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { ClearIcon } from "@mui/x-date-pickers";
import { Tooltip, IconButton, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

const CustomAutoCompleteEdit = ({
  fieldName,
  requiredErrorMessage,
  fieldId,
  fieldLabel,  
  fieldControl,
  fieldError,
  fieldTrigger,
  value,
  onChange,
  defaultValue,
  fieldRequiredIcon,
  disabled,
  options,
  getOptionLabel,
  validator,
  isRequired=true,
  invalidOption,
  tooltipMessage
}) => {
  const CustomInputLabel = ({ label, requiredIcon }) => (
    <label>
      {label}
      {requiredIcon && <span style={{ color: "red" }}> {requiredIcon} </span>}
    </label>
  );

  const { setValue, control, trigger, setError } = useForm({
    defaultValues: {
      [fieldName]: defaultValue || "",
    },
  });

  // Update the value prop when defaultValue changes
  useEffect(() => {
    setValue(fieldName, defaultValue || "");
  }, [defaultValue, setValue, fieldName]);

  const [isEmpty, setIsEmpty] = useState(defaultValue === undefined || defaultValue === "");
  const [touched, setTouched] = useState(false);

  // useEffect(() => {
  //   setIsEmpty(defaultValue === undefined || defaultValue === "");
  // }, [defaultValue]);

  useEffect(() => {
    const fetchData = async () => {
      await setValue(fieldName, value || "");
      setIsEmpty(value == null || value === "");
      
    };
    fetchData();
  }, [value, setValue, fieldName]);

 

  // const validateAutoComplete = async (value) => {
  //   const isValid =
  //     (touched && value !== undefined && value !== "") ||
  //     (!touched && requiredErrorMessage);
  
  //   console.log("value", value);
    
  //   return isValid;
  // };
  const validateAutoComplete = (value) => {

    // If the field is not required and the value is undefined or empty, return true
    if (!isRequired && (value === undefined || value === "")) {
      return true;
  }

    // If the value is not in options or starts with whitespace, return the invalidOption message
    const startsWithSpace = /^\s/.test(value);
    if ((value !==null && value !=="" && !options.some(option => option.value === value)) || startsWithSpace) {
        console.log(!options.some(option => option.value))
        console.log(value)
      return invalidOption;
    }

    // If the field is required and the value is empty, return the required error message
    if (isRequired && (value === undefined || value === "")) {
        return requiredErrorMessage;
    }

    // If the value is valid, return true
    return true;
};
  


  useEffect(() => {
    console.log("first", isEmpty);
  }, [isEmpty]); 

  useEffect(() => {
    // Trigger validation when the value changes and the field is touched
    if (touched && isRequired) {
      trigger(fieldName);
    }
}, [value, trigger, fieldName, touched, isRequired]);

  return (
    <div className="flex items-center w-full md:w-1/2">
    <div className="flex-1 ">
    <Controller
      name={fieldName}
      control={fieldControl}
      rules={{
        required:isRequired ? requiredErrorMessage : '',
        validate: validateAutoComplete,
      }}
      disabled={disabled}
      render={({ field, fieldState }) => (
        <>
          {options && (
          <Autocomplete
          {...field}
          options={options}
          
          getOptionLabel={(option) => {
            // Use the correct property based on your data structure
            return option.address || option.topic || option.category || null;
          }}
          value={options.find((option) => option.value === field.value) || null}
          onChange={(event, newValue) => {
            if (newValue) {
              setValue(fieldName, newValue.value || "", {
                shouldDirty: true,
              });
              setError(fieldName, "");

    if (newValue === null || newValue === "") {
      // Manually removed value, update isEmpty
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
            }
        
            if (onChange) {
              onChange(event, newValue);
            }
          }}
          onInputChange={(event, newInputValue) => {
            const isInputInOptions = options.some((option) => option.value === newInputValue);
          
            if (!isInputInOptions) {
              // Clear the field or display an error message
              // For example, you can clear the field and set an error message
              setValue(fieldName, "", { shouldDirty: true });
              setIsEmpty(true);
              setError(fieldName, "Please select from the available options.");
            } else {
              setValue(fieldName, newInputValue, { shouldDirty: true });
              setIsEmpty(false);
              setError(fieldName, null); // Clear any previous error message
            }
          }}
          
          // onKeyDown={(event) => {
          //   if ((event.key === 'Backspace' || event.key === 'Delete') && field.value) {
          //     event.preventDefault();
          //   }
          // }}
          onBlur={() => {
            // If an option is selected, mark the field as not touched
            if (field.value) {
              setTouched(false);
            } else {
              setTouched(true); // Mark the field as touched on blur if no option is selected
              trigger(fieldName); // Trigger validation on blur
            }
          }}
          isOptionEqualToValue={(option, value) => option.value === value}  // Customize equality test
          renderInput={(params) => (
            <TextField
              {...params}
              {...field}
              label={
                <CustomInputLabel
                  label={fieldLabel}
                  requiredIcon={fieldRequiredIcon}
                />
              }
              margin="dense"
              variant="standard"
              error={fieldState.invalid && isEmpty}
              helperText={
                fieldState.invalid && isEmpty
                  ? fieldState.error?.message || requiredErrorMessage
                  : ""
              }
            />
          )}
          clearOnBlur={false}
          clearIcon={<ClearIcon />}
        />
          )}
        </>
      )}
    />
    </div>
      {tooltipMessage && (
        <Tooltip
          title={
            <Typography sx={{ fontSize: "14px" }}>{tooltipMessage}</Typography>
          }
          placement="right"
          arrow
        >
          <IconButton size="small">
            <InfoOutlined />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default CustomAutoCompleteEdit;
