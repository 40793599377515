import React, { useEffect, useState } from 'react'
import CustomTooltipField from '../../components/CustomTooltipField';
import { BASE_URL, TEMPLATE } from '../../utills/ApplicationRouting';
import { request } from '../../services/AxiosConfig';
import { FOOTER_REQUIRED_MESSAGE, HEADER_REQUIRED_MESSAGE, MAX_LENGTH_THOUSAND, MIN_LENGTH_VALIDATE_MESSAGE } from '../../utills/ApplicationConstants'
import { useNavigate, useParams } from 'react-router-dom';
import CustomHeading from '../../components/CustomHeading';
import CustomToastContainer from '../../components/CustomToastContainer';
import CustomButton from '../../components/CustomButton';
import { Card, CardContent } from '@mui/material';
import { useForm } from 'react-hook-form';
import CustomCheckBox from '../../components/CustomCheckBox';
import CustomLoading from '../../components/CustomLoading';
import { useSelector } from 'react-redux';
import axios from 'axios';

function Templateedit() {
    const {
        handleSubmit,
        control,
        reset,
        setError,
        formState: { errors },
      } = useForm({
        shouldUnregister: true, // Add this option
      });
      const { token } = useSelector((state) => state.hybridhoa);

      const { reportTemplateId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [active, setIsActive] = useState(false);
    const [templateId, setTemplateId] = useState("");
    const [headerData, setHeaderData] = useState("");
    const [footerData, setFooterData] = useState("");
    const [editActive, setEditActive] = useState([]);
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(-1);
      };
      const handleEditCheckBoxChange = (e) => {
        console.log(e.target.checked);
        setEditActive(e.target.checked);
      };
      const headerChange = (e) => {
        setHeaderData(e.target.value);
      };
      const footerChange = (e) => {
        setFooterData(e.target.value);
      };
    const getRenterDetailsById = async () => {
        try {
          setIsLoading(true);
          const storedToken = token;
    
          console.log(storedToken);
    
          const response = await axios.get(`${BASE_URL}${TEMPLATE}/${reportTemplateId}`, {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });
          console.log(response.data);
    
          if (response.status === 200) {
            const data = response.data;
            console.log(data);
            setIsLoading(false);
            setHeaderData(data.header);
            setFooterData(data.footer);
            setEditActive(data.isActive);
            console.log(data.isActive);
           
          } else {
            throw new Error("Failed to fetch data");
          }
        } catch (error) {
          // Handle error
          console.error("Error fetching data:", error);
        }
      };
    
      useEffect(() => {
        getRenterDetailsById();
      }, [reportTemplateId]);
      const submitEdit = () => {
        setIsLoading(true);
        const putData = {
          header: headerData.trim(),
          footer: footerData.trim(),
          isActive: editActive,
        };
    
        if (headerData.trim() == "" || headerData.trim() == null) {
          setError("editheader", {
            type: "manual",
            message: HEADER_REQUIRED_MESSAGE,
          });
          setIsLoading(false);
          return;
        }
        if (footerData.trim() == "" || footerData.trim() == null) {
          setError("editFooter", {
            type: "manual",
            message: FOOTER_REQUIRED_MESSAGE,
          });
          setIsLoading(false);
          return;
        }
    
        request({ url: `${TEMPLATE}/${reportTemplateId}`, method: "put", data: putData })
          .then((res) => {
            setIsLoading(false);
            console.log(res);
            if (res.status == 200) {
              setIsLoading(false);
              setTimeout(()=>{
                navigate(-1);
            },4000)
            //   getTemplateDetails();
            //   handleEditClose(res.status);
            }
          })
          .catch((error) => {
            console.log(error.response);
            setIsLoading(false);
            if (error.response) {
              console.log("error.response.status" + error.response.status);
            }
          });
      };
  return (
    <div>
    <div class="flex flex-col md:flex-row">
      <CustomHeading title="Edit Template " />
      <CustomToastContainer />
      <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
        <CustomButton
          fieldType="button"
          buttonName="Back"
          click={handleClick}
        />
      </div>
    </div>
    <Card>
      <form
        onSubmit={handleSubmit(submitEdit)}
        className="space-y-4 md:space-y-6 "
      >
        <CardContent>
          <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
          
          <CustomTooltipField
              fieldName="editheader"
              fieldId="editheader"          
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldLabel="Header"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
              value={headerData}
              onInput={headerChange}
            />

                 <CustomTooltipField

              fieldName="editFooter"
              fieldId="editFooter"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldLabel="Footer"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
              value={footerData}
              onInput={footerChange}
            />
             <CustomCheckBox
              fieldName="editActive"
              control={control}
              checked={editActive}
              labelName="Is Active"
              onChange={handleEditCheckBoxChange}
            />
          </section>
          <div class="text-center mt-10">
            <CustomButton fieldType="submit" buttonName="Create" />
            {isLoading && <CustomLoading />}

          </div>
        </CardContent>
      </form>
    </Card>
  </div>
  )
}

export default Templateedit
