import React, { useEffect, useMemo, useState } from "react";
import CustomHeading from "../../../components/CustomHeading";
import { ListAlt } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL, GUMAINTENANCE_REQUEST_LIST } from "../../../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import CustomMaterialWithoutActons from "../../../components/CustomMaterialWithoutActons";
import CustomLoading from "../../../components/CustomLoading";



function GuMaintenanceList() {
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(true);
    const { token } = useSelector(state => state.hybridhoa);
    const [GuMaintenanceRequestDetails, setGuMaintenanceRequestDetails] = useState([]);
    const [open, setOpen] = useState(false);
    const handleViewClick = (selectedRow) => {
      navigate(`/gumaintenance/${selectedRow.row.original.maintenanceId}`);
    };


      const getGuMaintananceRequestDetails = async () => {
        try {
              const storedToken = token
    
          console.log(storedToken);
          
            const response= await axios.get(`${BASE_URL}${GUMAINTENANCE_REQUEST_LIST}`,{
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });
                  // console.log(response.data+" hiiiiiiiiiiiiiiiiiiiiii "); 
    
          if (response.status === 200) {
            const data = response.data;
            console.log(data);
            setGuMaintenanceRequestDetails(data);
            setIsLoading(false);
    
          } else {
            setIsLoading(false);
            throw new Error('Failed to fetch data');
          }
         
        } catch (error) {
          // Handle error
          setIsLoading(false);
          console.error("Error fetching data:", error);
        }
      };

      
useEffect(()=>{
  getGuMaintananceRequestDetails();
},[])
   
 
 
   const maintenanceRequestTable = useMemo((rowData) => [
     {
       accessorKey: "mrId",
       header: "Maintenance Request Id",
       size: 20,
     },
     {
      accessorKey: "occupation",
      header: "Job Category",
      size: 20,
      accessorFn: (row) => {
        if (row.jobDetails && row.jobDetails.occupation) {
          return `${row.jobDetails.occupation}`;
        } else {
          return 'N/A'; // or return an empty string: return '';
        }
      },
    },
    {
      accessorKey: "topic",
      header: "Description",
      accessorFn: (row) => {
       const description = row.topic?row.topic:"";
       if (description && description.length > 28) {
         return description.slice(0, 28) + "...";
       }
       return description;
     }
    },
     {
      accessorKey: "Created By",
      header: "FirstName",
      size: 20,
      accessorFn: (row) =>
        `${row.createdByDetails?.firstName??""} `,
     
    },
    {
      accessorKey: "LastName",
      header: "LastName",
      size: 20,
      accessorFn: (row) =>
        `${row.createdByDetails?.lastName??""} `,
     
    },
    
    {
      accessorKey: "address",
      header: " Address",
      size: 20,
      accessorFn: (row) =>
        `${row.homeDetails?.address??""} `,
     
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 20,
      Cell: ({ row }) => {
       const status = row.original.status;
       return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
     },
   
    },
    {
      accessorKey: "createdDateAndTime",
      header: "Created Date",
      size: 20,
      accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY hh:mm A"),
    },
     {
       accessorKey: "isUrgent",
       
       accessorFn: (row) => (row && row.isUrgent ? "Yes" : "No"),
       header: "Urgent?",
       size: 20,
     },
    //  {
    //    accessorKey: "occupation",
    //    header: "Job Category",
    //    size: 20,
    //    Cell: ({ row }) => row.original.jobDetails? row.original.jobDetails.occupation : 'N/A',
       
    //  },
   
    
   
  
   
   
    
   ]);
   const rowActions = [
    {
      label: "View",
      icon: <ListAlt color="primary" />,
      click: handleViewClick,
    },
   
  ]

  
   return (
     <div>
       {isLoading && <CustomLoading/>}
       <CustomHeading title="Maintenance Requests" />
 
      
       <CustomMaterialWithoutActons
         columns={maintenanceRequestTable}
         data={GuMaintenanceRequestDetails}
        //  rowActions = {rowActions}
        //  isLoading={isLoading}

         onRowClick = {handleViewClick}
       />
     </div>
   );
 }

export default GuMaintenanceList