import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import { useSelector } from "react-redux";
import axios from "axios";

import { InfoOutlined } from "@mui/icons-material";
import { Tooltip, IconButton } from "@mui/material";
import dayjs from "dayjs";
import { ADD_MAINTENANCE, ADD_MAINTENANCE_MC, BASE_URL, BYLAW, CATEGORY, EDIT_MAINTENANCE, HOMES_DETAILS, MAINTENANCE_REQUEST_EDIT_BY_ID } from "../../../utills/ApplicationRouting";
import { BYLAW_REQUIRED_MESSAGE, CATEGORY_INVALID_MESSAGE, CATEGORY_REQUIRED_MESSAGE, HOME_ADDRESS_INVALID_MESSAGE, HOME_ADDRESS_REQUIRED_MESSAGE, LEASE_END_DATE_REQUIRED_GREATER_MESSAGE, MAX_LENGTH_FIVE_THOUSAND, MAX_LENGTH_THOUSAND, MIN_LENGTH_VALIDATE_MESSAGE, REQUEST_TYPE_REQUIRED_MESSAGE, WHAT_NEED_ATTENTION_REQUIRED_MESSAGE } from "../../../utills/ApplicationConstants";
import { request } from "../../../services/AxiosConfig";
import CustomHeading from "../../../components/CustomHeading";
import CustomButton from "../../../components/CustomButton";
import CustomAutoCompleteEdit from "../../../components/CustomAutoCompleteEdit";
import CustomInputField from "../../../components/CustomInputField";
import CustomFile from "../../../components/CustomFile";
import CustomDatePicker from "../../../components/CustomDatePicker";
import CustomRadioButton from "../../../components/CustomRadioButton";
import CustomLoading from "../../../components/CustomLoading";
import CustomAutoComplete from "../../../components/CustomAutoComplete";
import CustomToastContainer from "../../../components/CustomToastContainer";
import CustomTooltipField from "../../../components/CustomTooltipField";
import CustomFileEdit from "../../../components/CustomFileEdit";

function McMaintenanceEdit() {
  const [file, setFile] = useState([]);
  const fileTypes = ["JPG", "PNG", "JPEG", "PDF"];
  const navigate = useNavigate();
  const { maintenanceId } = useParams();
  const [maintenanceRequestData, setMaitenanceRequestData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [attention, setAttention] = useState("");
  const [description, setDescription] = useState("");
  const { token, homeId } = useSelector((state) => state.hybridhoa);
  const [isUrgent, setIsUrgent] = useState(false);
  const [isSafety, setIsSafety] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [byLawDetails, setByLawDetails] = useState([]);
  const [selectedHomeId, setSelectedHomeId] = useState(null);
  const [homeAddresses, setHomeAddresses] = useState([]);
  const [crId, setCrId] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [byLawDescription, setByLawDescription] = useState("");
  const [selectedByLawTopic, setSelectedByLawTopic] = useState("");
  const [fileData, setFileData] = useState([]);
  const [requestType, setRequestType] = useState("");
  const [selectedBylawId, setSelectedBylawId] = useState(null);
  const [bylawTopic, setBylawTopic] = useState("");
  const [categorys, setCategorys] = useState("");
  const [bylawsUpdated, setBylawsUpdated] = useState(true);
  const [fileError, setFileError] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [homeDetails,setHomeDetails]=useState('');
  const minDate = dayjs().startOf("day");
  const maxDate = dayjs().add(1, "year").endOf("day");
  const [selectedHomeAddress,setSelectedHomeAddress]=useState('');
  const [toDateDisabled, setToDateDisabled] = useState(true);

  
  const handleViewClick = () => {
    navigate(-1);
  };

  const handleFileChange = (file) => {
    setFile(file);
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
    watch,
  } = useForm();

  useEffect(() => {
    const selectedBylaw = watch("selectedBylaw");

    if (!selectedBylaw || selectedBylaw === "") {
      setByLawDescription("");
      // setIsBylawFieldEmpty(true);
    } else {
      // Find the matching byLaw from byLawDetails
      const matchingByLaw = byLawDetails.find(
        (byLaw) => byLaw.byLawId === selectedBylaw
      );

      if (matchingByLaw) {
        setByLawDescription(matchingByLaw.description);
        // setIsBylawFieldEmpty(false);
      } else {
        // Handle the case where no matching byLaw is found
        setByLawDescription("");
        // setIsBylawFieldEmpty(true);
      }
    }
  }, [watch("selectedBylaw"), byLawDetails]);

  const getHomes = async () => {
    try {
      const storedToken = token;

      

      const response = await axios.get(`${BASE_URL}${HOMES_DETAILS}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setHomeAddresses(data);
        
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getHomes();
  }, []);

  const getCategory = async () => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setCategoryDetails(data);
        setIsLoading(false);
        // Adjust the delay time as needed (in milliseconds)
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const handleCategoryChange = (event, newValue) => {
    if (newValue) {
      const selectedCategoryId = newValue.value;

      // Set the selected homeId to the state
      setValue("category", selectedCategoryId);

      // You can also do other operations if needed

      console.log("selectedCategoryId:", selectedCategoryId);
      console.log("Home Details:", homeAddresses);
    } else {
      setValue("category", null); // Reset to null if no category is selected
    }
  };

  const handleFromDateChange = (selectedFromDate) => {
    setStartDate(selectedFromDate);
    if(selectedFromDate){
    setToDateDisabled(false)
    }
  };
  
  
  const handleendDateChange = (selectedEndDate) => {
    setEndDate(dayjs(selectedEndDate));
  };

  useEffect(() => {
    // Check if start date is greater than end date
    if (dayjs.isDayjs(startDate) && dayjs.isDayjs(endDate) && startDate.isAfter(endDate)) {
      console.log("Start date is after end date");
      setEndDate(null); // Reset end date if start date is after end date
    }
  }, [startDate, endDate]);

  const getMaintenanceRequestDetailsById = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${MAINTENANCE_REQUEST_EDIT_BY_ID}/${maintenanceId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setMaitenanceRequestData(data);
        setAttention(response.data.topic);
        setDescription(response.data.description);
        setFileData(data.attachments);
        setRequestType(data.type);
         setStartDate(dayjs(data.startDate));
         setEndDate(dayjs(data.endDate));
        setValue("selectyourRequesttype", data.type);
        if (data.isUrgent == 1) {
          setIsUrgent(true);
        }
        if (data.isSafty == 1) {
          setIsSafety(true);
        }
        setIsLoading(false);
        setCrId(data.category);

        const matchingHome = homeAddresses.find((home) => home.homeId === data.homeId);
        console.log("matchingHome", matchingHome);
        
        
        if (matchingHome) {
          setSelectedHomeAddress(matchingHome.address);
          setValue("selectedOption", matchingHome.homeId);
          const { name } = matchingHome;
          const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";
          setHomeDetails(selectedHomeDescription);
        } else {
          setSelectedHomeAddress(""); // Handle when matching home is not found
          setValue("selectedOption", ""); // Reset to an empty string if no matching home is found
          setHomeDetails("");
        }

        const matchingByLaw = byLawDetails.find(
          (byLaw) => byLaw.byLawId === data.bylawId
        );
        console.log("matchingByLaw", matchingByLaw);
        console.log("byLawDetails", byLawDetails);

        if (matchingByLaw) {
          setBylawTopic(matchingByLaw.topic);
          setValue("selectedBylaw", matchingByLaw.byLawId);
          setByLawDescription(matchingByLaw.description);
          console.log("Bylaw: " + matchingByLaw.byLawId);
        } else {
          setValue("selectedBylaw", ""); // Reset to an empty string if no matching bylaw is found
          setByLawDescription("");
        }

        const matchingCategory = categoryDetails.find(
          (category) => category.categoryId === data.category
        );
        console.log("matchingCategory", matchingCategory);
        console.log("categoryDetails", categoryDetails);
        if (matchingCategory) {
          setCategorys(matchingCategory.category);
          console.log("category", matchingCategory.categoryId);
          setValue("category", matchingCategory.categoryId);
        } else {
          setCategorys("");
          setValue("category", "");
        }
      } else {
        setIsLoading(false);

        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getMaintenanceRequestDetailsById();
  }, [maintenanceId, byLawDetails, categoryDetails, setValue]);

  useEffect(() => {
    console.log("selectyourRequesttype", requestType);
  }, [requestType]);



  const renderedAddresses = homeAddresses.map((home, index) => ({
    address: home.address, 
    value: home.homeId,   
    key: index,
  }));

  const handleByLawChange = (event, newValue) => {
    if (newValue && newValue.value !== undefined && newValue.value !== null) {
      const selectedByLawId = newValue.value;
      setValue("selectedBylaw", selectedByLawId);
      const selectedByLawDescription = byLawDetails.find(
        (byLaw) => byLaw.byLawId === selectedByLawId
      )?.description;

      setByLawDescription(selectedByLawDescription);

      // Update the state here
      setBylawTopic(newValue);
    } else {
      // Clear the state values
      setByLawDescription("");
      setValue("selectedBylaw", null);

      // Update the state here
      setBylawTopic(null);
    }
  };

  const getBylaw = async () => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${BYLAW}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        console.log("byLaw data", data);
        setByLawDetails(data);
        setBylawsUpdated(data.length > 0);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getBylaw();
  }, []);

  const renderedCategory = categoryDetails
    .filter((category) => category.type === "MR") // Filter categories with type 'CR'
    .map((category, index) => ({
      category: category.category,
      value: category.categoryId,
      key: index,
    }));

  const renderedBylaw = byLawDetails.map((ByLaw, index) => ({
    topic: ByLaw.topic,
    value: ByLaw.byLawId,
    key: index,
  }));

  const handleRadioButtonChange = (value) => {
    setRequestType(value);
    // Other logic as needed
  };
  useEffect(() => {
                  const selectedHome = watch("selectedOption");
                
                  if (!selectedHome || selectedHome === "") {
                    setHomeDetails("");
                    // setIsBylawFieldEmpty(true);
                  } else {
                    // Find the matching byLaw from byLawDetails
                    const matchingHome = homeAddresses.find(
                      (home) => home.homeId === selectedHome
                    );
                    console.log(matchingHome);
                
                    if (matchingHome) {
                      const {name} = matchingHome;
                      const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";
                      setHomeDetails(selectedHomeDescription);
                
                      // setIsBylawFieldEmpty(false);
                    } else {
                      // Handle the case where no matching byLaw is found
                      setHomeDetails("");
                      // setIsBylawFieldEmpty(true);
                    }
                  }
                }, [watch("selectedOption"), homeDetails]);
  const handleHomeChange = (event, newValue) => {
                  if (newValue && newValue.value !== undefined && newValue.value !== null) {
                      const selectedHomeId = newValue.value;
                      const selectedHome = homeAddresses.find(
                          (home) => home.homeId === selectedHomeId
                      );
                      const homeOwner = selectedHome;
                
                      if (homeOwner) {
                          const { name } = homeOwner;
                          const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";
                          console.log("Selected Home Description:", selectedHomeDescription);
                
                          // Set the selected home details to the state if selectedHomeDescription is not null
                        
                              setHomeDetails(selectedHomeDescription);
                
                          // Use setValue to set the selected homeId in the react-hook-form
                          setValue("selectedOption", selectedHomeId);
                
                          // You can also do other operations if needed
                          console.log("Selected Home:", selectedHomeId);
                          console.log("Home Details:", homeAddresses);
                      } else {
                          setHomeDetails("");
                          // setValue("selectedOption", null);
                          setValue("selectedOption", selectedHomeId);
                      }
                  } else {
                      // Handle the case when the selected option is removed (e.g., by pressing backspace)
                      setHomeDetails("");
                
                      // Use setValue to clear the selected homeId in the react-hook-form
                      setValue("selectedOption", null);
                  }
                };

  useEffect(() => {
    console.log("selectedCategory", selectedCategory);
    console.log("selectedCategory", selectedCategoryId);
  }, [selectedCategory, selectedCategoryId]);

  const onSubmit = (data) => {
    if (attention.trim() === "") {
      setError("whatneedsattention", {
        type: "manual",
        message: WHAT_NEED_ATTENTION_REQUIRED_MESSAGE,
      });
      return;
    }
    

    const totalFileSize = file.reduce((acc, file) => acc + file.size, 0);
    const maxSizeInMB = 10;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
  
    if (totalFileSize > maxSizeInBytes) {
      // setFileError(`Total file size should not exceed ${maxSizeInMB}MB.`);
      return;
    }
  
    // Check file types
    const allowedFileTypes = ["image/jpg", "image/png", "image/jpeg", "application/pdf"];
    const invalidFiles = file.filter(file => !allowedFileTypes.includes(file.type));
  
    if (invalidFiles.length > 0) {
      // setFileError("Some files have invalid file types. Allowed types are JPG, PNG, JPEG, PDF.");
      return;
    }

   

    if (startDate && endDate) { // Checks if both dates are not null or empty
      if (endDate < startDate) {
        setError("endDate", {
          type: "manual",
          message: LEASE_END_DATE_REQUIRED_GREATER_MESSAGE,
        });
        return;
      }
    }
    const formData = new FormData();
    formData.append("topic", attention);
    formData.append("homeId", data.selectedOption);
    formData.append("category", data.category);
    formData.append("description", description);
    file.forEach((file) => {
      formData.append('files', file);
      });
    formData.append("bylawId", data.selectedBylaw);
    formData.append("isUrgent", isUrgent ? "true" : "false");
    formData.append("isSafty", isSafety ? "true" : "false");
    formData.append("role", "ROLE_HO");
    formData.append("type", data.selectyourRequesttype);
    if (startDate && dayjs(startDate).isValid()) {
      formData.append('startDate', dayjs(startDate).format("YYYY-MM-DD"));
    } else {
      formData.append('startDate', ''); 
    }
    
    // Check if endDate is valid
    if (endDate && dayjs(endDate).isValid()) {
      formData.append('endDate', dayjs(endDate).format("YYYY-MM-DD"));
    } else {
      formData.append('endDate', ''); 
    }
    console.log("homeId:", attention);
    console.log("category:", selectedCategoryId);
    console.log("description:", description);
    console.log("file:", file);
    console.log("bylawId:", selectedBylawId);
    console.log("role:", "ROLE_HO");

    console.log("formData:", formData);

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    // return;

    request({
      url: `${EDIT_MAINTENANCE}${maintenanceId}`,
      method: "put",
      data: formData,
      headers,
    })
      .then((res) => {
        setIsLoading(true);
        console.log(res);
        if (res.status == 200) {
          setIsLoading(true);
          setTimeout(() => {
            navigate(-1);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const renderTooltip = (message) => (
    <Tooltip title={<Typography sx={{ fontSize: "14px" }}>{message}</Typography>} placement="right">
    <IconButton>
    <InfoOutlined />
    </IconButton>
    </Tooltip>
    );

  return (
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomHeading title="Edit Maintenance" />

        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleViewClick}
          />
        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
            <CustomAutoCompleteEdit
              fieldName="selectedOption"
              requiredErrorMessage = {HOME_ADDRESS_REQUIRED_MESSAGE}
              id="selectId"
              options={renderedAddresses}
              fieldLabel="Select Home Address"
              fieldControl={control}
              error={errors}
              setValue={setValue}
              fieldRequiredIcon="*" 
              onChange={handleHomeChange}
              value={selectedHomeAddress}
              invalidOption={HOME_ADDRESS_INVALID_MESSAGE}
              tooltipMessage={"Please select the home address of the homeowner you are creating the maintenance for."}
               />
                 <CustomInputField
                  fieldName="homeOwnerDetails"
                  fieldId="homeOwnerDetails"
                  fieldType="text"
                  fieldLabel="HomeOwner Name"
                  fieldControl={control}
                  fieldError={errors}
                  disabled={true}
                  value={homeDetails}
                  tooltipMessage={"This field displays the name of the homeowner based on the selected home address."}
                />
              <CustomTooltipField
                fieldName="whatneedsattention"
                // requiredErrorMessage={WHAT_NEED_ATTENTION_REQUIRED_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={1000}
                maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
                fieldId="whatneedsattention"
                fieldType="text"
                fieldLabel="What needs attention?"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                multiline={true}
                numberOfRows={1}
                value={attention}
                onInput={(event) => {
                  setAttention(event.target.value);
                }}
                tooltipMessage={"Please provide a brief description of what needs attention."}
              />

              <CustomTooltipField
                fieldName="moreInformation"
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={5000}
                maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
                fieldId="moreInformation"
                fieldType="text"
                fieldLabel="More Information"
                fieldControl={control}
                fieldError={errors}
                multiline={true}
                numberOfRows={1}
                value={description}
                onInput={(event) => {
                  setDescription(event.target.value);
                }}
                tooltipMessage={"You can provide additional information or details here if needed."}
              />
                              <div className="flex items-center md:w-1/2 ">
  <div className="w-full ">
              <FormControlLabel
                control={
                  <Switch
                    checked={isUrgent}
                    onChange={(event) => setIsUrgent(event.target.checked)}
                  />
                }
                label="Is this issue urgent?: "
              >
                {" "}
              </FormControlLabel>
              </div>         <div>{renderTooltip("Check this if the issue requires immediate attention.")}</div></div>
              <div className="flex items-center md:w-1/2 ">
  <div className="w-full ">
              <FormControlLabel
                control={
                  <Switch
                    checked={isSafety}
                    onChange={(event) => setIsSafety(event.target.checked)}
                  />
                }
                label="Is this issue actively causing property damage or a threat to personal safety?: "
              >
                {" "}
              </FormControlLabel>
              </div>         <div>{renderTooltip("Is this issue actively causing property damage or a threat to personal safety?")}</div></div>      

              <CustomAutoCompleteEdit
                fieldName="category"
                requiredErrorMessage={CATEGORY_REQUIRED_MESSAGE}
                id="selectId"
                options={renderedCategory}
                fieldLabel="Select Category/Subject"
                fieldControl={control}
                error={errors}
                setValue={setValue}
                fieldRequiredIcon="*"
                onChange={handleCategoryChange}
                value={categorys}
                invalidOption={CATEGORY_INVALID_MESSAGE}
                tooltipMessage={"Please select the category or subject that best fits your request."}
              />

              {/* <CustomAutoCompleteEdit
                isRequired={false}
                fieldName="selectedBylaw"
                // requiredErrorMessage={BYLAW_REQUIRED_MESSAGE}
                id="selectId"
                options={renderedBylaw}
                fieldLabel="Select Bylaw"
                fieldControl={control}
                error={errors}
                setValue={setValue}
                onChange={handleByLawChange}
                value={bylawTopic}
                invalidOption={BYLAW_REQUIRED_MESSAGE}
              /> */}

<div className="h-1/2">
{!bylawsUpdated ? (<Tooltip title="The bylaw in your association is not updated, so please contact the group admin." placement="bottom">
      <span>
        <CustomAutoComplete
          fieldName="selectedBylaw"
          id="selectId"
          options={renderedBylaw}
          fieldLabel="Select Bylaw"
          fieldControl={control}
          setValue={setValue}
          onChange={handleByLawChange}
          value={bylawTopic}
          isRequired={false}
          invalidOption={BYLAW_REQUIRED_MESSAGE}
          bylawNull="no bylaw"
          disabled={!bylawsUpdated}
        />
      </span>
    </Tooltip>) : <CustomAutoComplete
          fieldName="selectedBylaw"
          id="selectId"
          options={renderedBylaw}
          fieldLabel="Select Bylaw"
          fieldControl={control}
          setValue={setValue}
          onChange={handleByLawChange}
          value={bylawTopic}
          isRequired={false}
          invalidOption={BYLAW_REQUIRED_MESSAGE}
          bylawNull="no bylaw"
          tooltipMessage={"Please select the relevant bylaw if applicable."}
        />
      }
    </div>

              <CustomInputField
                fieldName="bylawDescription"
                fieldId="bylawDescription"
                fieldType="text"
                fieldLabel="Bylaw Description"
                fieldControl={control}
                fieldError={errors}
                disabled={true}
                value={byLawDescription}
                multiline={true}
                numberOfRows={2}
                tooltipMessage={"This field displays the description of the selected bylaw."}
              />

              {/* <FormLabel>
                Attach Documents
                <Tooltip
                  title={`Please note that uploading a new document will override the existing one`}
                >
                  <IconButton>
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
                <FileUploader
                  handleChange={handleFileChange}
                  name="file"
                  types={fileTypes}
                  // required
                />
                {fileData ? <p>{fileData}</p> : <p>No file selected</p>}
              </FormLabel> */}
             <CustomFileEdit downloadFiles={true} fileData={fileData} handleFileChange={handleFileChange} token={token} file={file}/>

              <CustomRadioButton
              
              fieldName="selectyourRequesttype"
              fieldControl={control}
              fieldError={errors}
              labelName="Select your Request type"
              requiredErrorMessage={REQUEST_TYPE_REQUIRED_MESSAGE}
              valueOne="Request for Home Maintenance "
              labelOne="Request for Home Maintenance"
              valueTwo="Request for Common area Maintenance"
              labelTwo="Request for Common area Maintenance"
              value={requestType}
              onChange={handleRadioButtonChange}
              tooltipMessage={"Please select the type of request you are making."}
            />
              <CustomDatePicker
                fieldName="startDate"
                label="Start Date"
                control={control}
                // requiredErrorMessage={LEASE_START_DATE_REQUIRED_MESSAGE}
                minDate={minDate}
                maxDate={maxDate}
                value={startDate}
                onChange={(date) => handleFromDateChange(date)}
                // fieldRequiredIcon="*"
                tooltipMessage={"Please select the start date for your request."}
              />
  
  <CustomDatePicker
  fieldName="endDate"
  label="End Date"
  control={control}
  minDate={startDate ? dayjs(startDate).add(1, 'day') : null}
  maxDate={maxDate}
  disabled={toDateDisabled}
  onChange={handleendDateChange}
  value={endDate}
  tooltipMessage={"Please select the end date for your request, if applicable."}
/>
            </section>
          
            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Update" />
              {isLoading && <CustomLoading />}
            </div>
          </CardContent>
        </form>
      </Card>
      <CustomToastContainer />
    </div>
  );
}
export default McMaintenanceEdit;
