import React, { useEffect, useRef, useState } from "react";
import CustomHeading from "../../components/CustomHeading";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, FormHelperText } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  ADDRESS_FOR_COMMUNICATION_INVALID_MESSAGE,
  ADDRESS_FOR_COMMUNICATION_REQUIRED_MESSAGE,
  CITY_INVALID_MESSAGE,
  CITY_REQUIRED_MESSAGE,
  CONTACT_REQUIRED_MESSAGE,
  EMAIL_INVALID_MESSAGE,
  FIRST_NAME_INVALID_MESSAGE,
  FIRST_NAME_REQUIRED_MESSAGE,
  GENDER_REQUIRED_MESSAGE,
  LAST_NAME_INVALID_MESSAGE,
  LAST_NAME_REQUIRED_MESSAGE,
  MAX_LENGTH_TWO_FIFTY,
  MAX_LENGTH_VALIDATE_MESSAGE,
  MIN_LENGTH_VALIDATE_MESSAGE,
  MIN_LENGTH_VALIDATE_ONE,
  PHONE_REQUIRED_MESSAGE,
  STATE_INVALID_MESSAGE,
  STATE_REQUIRED_MESSAGE,
  ZIPCODE_INVALID_MESSAGE,
  ZIPCODE_MAX_LENGTH_MESSAGE,
  ZIPCODE_MIN_LENGTH_MESSAGE,
  ZIPCODE_REQUIRED_MESSAGE, FAX_INVALID_MESSAGE,
  ADDRESS_REQUIRED_MESSAGE,
  MAX_LENGTH_VALIDATE_MESSAGE_TEN,
} from "../../utills/ApplicationConstants";
import {
  ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN,
  EMAIL_REGEX_PATTERNS, FAX_REGEX_PATTERN,
  ONLY_ALPHABETIC,
  ONLY_DIGITS,
} from "../../utills/ApplicationRegex";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import CustomInputField from "../../components/CustomInputField";
import CustomCheckBox from "../../components/CustomCheckBox";
import CustomRadioButton from "../../components/CustomRadioButton";
import axios from "axios";
import { BASE_URL, PROFILE } from "../../utills/ApplicationRouting";
import { useDispatch, useSelector } from "react-redux";
import { request } from "../../services/AxiosConfig";
import Loading from "react-loading";
import "react-toastify/dist/ReactToastify.css";
import CustomToastContainer from "../../components/CustomToastContainer";
import CustomLoading from "../../components/CustomLoading";
import { isValidPhoneNumber } from 'react-phone-number-input';
import CustomPhoneInput from "../../components/CustomPhoneInput";

function Profile() {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { token, roles,homeId } = useSelector((state) => state.hybridhoa);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipcode] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [addressFrCommunication, setAddressFrCommunication] = useState("");
  const [isSameAsHomeAddress, setIsSameAsHomeAddress] = useState(false);
  const [gender, setGender] = useState("");
  const [originalAddressForCommunication, setOriginalAddressForCommunication] = useState("");
  const [contact, setContact] = useState("");
  const [fax, setFax] = useState("");
  const addressForCommunicationRef = useRef(null);
const [isExternalUser, setIsExternalUser] = useState("");
  const dispatch = useDispatch();
  const handleClick = () => {
    navigate(-1);
  };
  const handleChangePassword = () => {
    navigate("/changepassword");
  };

  const {
    handleSubmit,
    control,
    reset,
    setError,
    clearErrors, formState,
    formState: { errors }, setValue
  } = useForm();

  const getProfileDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;
      console.log(storedToken);
      const storedRoles = roles
      console.log(storedRoles);
      // console.log(localStorage.getItem(data[0].ROLE));
      const response = await axios.get(`${BASE_URL}${PROFILE}?homeId=${homeId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setProfileData(data);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setCity(data.city);
        setState(data.state);
        setZipcode(data.zipCode);
        setEmail(data.email);
        setGender(data.gender);
        setAddress(data.address);
        setAddressFrCommunication(data.addressForCommunication);
        setOriginalAddressForCommunication(data.addressForCommunication);
        setContact(data.contact);
        setFax(data.fax);
        setValue("phoneNumber", data.contact)
        setValue("home", data.home)
        setValue("work", data.work)
        setIsLoading(false);
        setIsExternalUser(data.isExternalUser)

        if (data.address === data.addressForCommunication) {
          setIsSameAsHomeAddress(true);
        } else {
          setIsSameAsHomeAddress(false);
        }
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getProfileDetails();
  }, [homeId]);

  const handleInputChange = (e) => {
    setFirstName(e.target.value);
  };
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };
  const handleFaxChange = (e) => {
    setFax(e.target.value);
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  }
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  }
  const handleStateChange = (e) => {
    setState(e.target.value);
  }

  const handleZipCode = (e) => {
    setZipcode(e.target.value);
  }


  const handleAddressCommunication = (e) => {
    setAddressFrCommunication(e.target.value);

  }

  const handleRadioChange = (value) => {
    setGender(value)
  }

  const handleCheckBoxChange = (e) => {
    setIsSameAsHomeAddress(e.target.checked);
    if (e.target.checked) {
      setAddressFrCommunication(address);
      clearErrors("addressForCommunication");
     
    } else {
      setAddressFrCommunication(originalAddressForCommunication);
    
    }
 
  };
  const handleRoleChange = (role) => {

    // Perform role-based navigation logic

    if (roles == "ROLE_HO") {
      navigate('/dashboard-ho');
    } else if (roles == "ROLE_BM") {
      navigate('/dashboard-bm');
    } else if (roles == "ROLE_ARC") {
      navigate('/dashboard-arc');
    } else if (roles == "ROLE_GU") {
      navigate('/dashboard-gu');
    } else if (roles == "ROLE_MC") {
      navigate('/dashboard-mc')
    }

  };
console.log(errors,"errors");
  const onSubmit = (data) => {


    console.log("address for communication",addressFrCommunication);
    console.log("is external use ",isExternalUser);
    const isValidPhoneNumberForCountry = isValidPhoneNumber(data.phoneNumber);
    

    if (!isValidPhoneNumberForCountry) {
      setIsLoading(false);
      return;
    }
    if (typeof firstName !== 'string' || firstName.trim() === '') {
      setError("firstName", {
        type: "manual",
        message: FIRST_NAME_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (typeof lastName !== 'string' || lastName.trim() === '') {
      setError("lastName", {
        type: "manual",
        message: LAST_NAME_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (typeof city !== 'string' || city.trim() === '') {
      setError("city", {
        type: "manual",
        message: CITY_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (typeof state !== 'string' || state.trim() === '') {
      setError("state", {
        type: "manual",
        message: STATE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (typeof zipCode !== 'string' || zipCode.trim() === '') {
      setError("zipCode", {
        type: "manual",
        message: ZIPCODE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (!isExternalUser && (typeof addressFrCommunication !== 'string' || addressFrCommunication.trim() === '')) {
      setError("addressForCommunication", {
        type: "manual",
        message: ADDRESS_FOR_COMMUNICATION_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (typeof address !== 'string' || address.trim() === '') {
      setError("address", {
        type: "manual",
        message: ADDRESS_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (gender === '' || gender === null) {
      setError("gender", {
        type: "manual",
        message: GENDER_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const postData = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      city: city.trim(),
      state: state.trim(),
      zipCode: zipCode.trim(),
      email: email.trim(),
      address: address.trim(),
      // addressForCommunication: addressFrCommunication.trim()||"",
      addressForCommunication: addressFrCommunication ? addressFrCommunication.trim() : "",
      gender: gender,
      contact: data.phoneNumber,
      work: data.work,
      home: data.home,
      fax: fax,

    }

    request({ url: PROFILE, method: "post", data: postData }).then(res => {
      console.log(res);
      if (res.status == 200) {
        console.log("Success");
        setIsLoading(false);
        setTimeout(() => {
          handleRoleChange()
        }, 4000);

      }
    }).catch(error => {
      console.log(error.response);
      if (error.response) {
        setIsLoading(false);
        console.log("error.response.status" + error.response.status);


      }
    });

  };


  return (
    <div>
     <div class="flex flex-col md:flex-row md:justify-between">
  <CustomHeading title="Edit Profile" />
  <CustomToastContainer />

  <div class="md:w-full md:pl-4 sm:text-left md:text-right sm:w-full text-lg font-sans mb-2 flex space-x-2 justify-end">
  <CustomButton
      fieldType="button"
      buttonName="Change Password"
      click={handleChangePassword}
    />
    <CustomButton
      fieldType="button"
      buttonName="Back"
      click={handleClick}
    />
   
  </div>
</div>

      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          
          className="space-y-4 md:space-y-6 "
        >
          {isLoading && (
            <CustomLoading />
          )}
          <CardContent>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomHeading title="Owner Details" />
              <CustomInputField
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={FIRST_NAME_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldName="firstName"
                fieldId="firstName"
                fieldType="text"
                fieldLabel="First Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={firstName}
                onInput={handleInputChange}
                tooltipMessage=" Update your first name here. This is how we'll address you in communications and personalize your experience on our platform"
              />

              <CustomInputField
                fieldName="lastName"

                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={LAST_NAME_INVALID_MESSAGE}
                minLength={1}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_ONE}
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldId="lastName"
                fieldType="text"
                fieldLabel="Last Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={lastName}
                onInput={handleLastNameChange}
                tooltipMessage=" Update your last name here. This is how we'll address you in communications and personalize your experience on our platform"

              />
            </section>


            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomHeading title="Gender" />

              <CustomRadioButton
                fieldName="gender"
                fieldControl={control}
                fieldError={errors}
                labelName="Select Gender"

                valueOne="Female"
                labelOne="Female"
                valueTwo="male"
                labelTwo="Male"
                value={gender}
                onChange={handleRadioChange}
              />

            </section>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomHeading title="Address" />

              {isExternalUser === false  ?
              <>
              <CustomInputField
                  fieldName="address"
                  fieldId="address"
                  fieldType="text"
                  fieldLabel="Address"
                  fieldControl={control}
                  fieldError={errors}
                  disabled={true}
                  value={address}
                  tooltipMessage="Enter your address" />

                  <CustomCheckBox
                    fieldName="sameAsHomeAddress"
                    control={control}
                    defaultValue={false}
                    labelName=" Same as Home Address"
                    onChange={handleCheckBoxChange}
                    checked={isSameAsHomeAddress} />
                    
                    <CustomInputField
               
                    fieldName="addressForCommunication"
                    fieldId="addressForCommunication"
                    fieldPattern={ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN}
                    patternErrorMessage={ADDRESS_FOR_COMMUNICATION_INVALID_MESSAGE}
                    minLength={2}
                    minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                    maxLength={250}
                    maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
                    fieldType="text"
                    fieldLabel="Address For Communications"
                    fieldRequiredIcon="*"
                    fieldControl={control}
                    fieldError={errors}
                    value={addressFrCommunication}
                    disabled={isSameAsHomeAddress}
                    onInput={handleAddressCommunication}
                    shrink={true}
                    tooltipMessage="Enter your current residential address here" />
                    </>
              : <>
              <CustomInputField
                  fieldName="address"
                  fieldId="address"
                  fieldType="text"
                  fieldLabel="Address"
                  fieldControl={control}
                  fieldError={errors}
                  value={address}
                  onInput={handleAddressChange}
                  tooltipMessage="Enter your address" />
                  
                    </>
                    }
              <CustomInputField
                fieldName="city"

                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={CITY_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldId="city"
                fieldType="text"
                fieldLabel="City"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={city}
                onInput={handleCityChange}
                tooltipMessage="The name of the city where the user is located"

              />
              <CustomInputField
                fieldName="state"

                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={STATE_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldId="state"
                fieldType="text"
                fieldLabel="State"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={state}
                onInput={handleStateChange}
                tooltipMessage="The name of the state where the user is located"

              />
              <CustomInputField
                fieldName="zipCode"

                fieldPattern={ONLY_DIGITS}
                patternErrorMessage={ZIPCODE_INVALID_MESSAGE}
                minLength={5}
                minLengthErrorMessage={ZIPCODE_MIN_LENGTH_MESSAGE}
                maxLength={5}
                maxLengthErrorMessage={ZIPCODE_MAX_LENGTH_MESSAGE}
                fieldId="zipCode"
                fieldType="text"
                fieldLabel="ZipCode"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={zipCode}
                onInput={handleZipCode}
                tooltipMessage="Zipcode"

              />
            </section>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomHeading title="Contact Details" />

              <CustomInputField
                fieldName="email"

                fieldPattern={EMAIL_REGEX_PATTERNS}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                fieldId="email"
                fieldType="text"
                fieldLabel="Email"
                fieldControl={control}
                fieldError={errors}
                // fieldRequiredIcon="*"
                value={email}
                disabled={true}
                tooltipMessage=" your current email address. This is how we'll communicate with you and keep you informed about important updates and notifications"
              />


              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: PHONE_REQUIRED_MESSAGE,
                  validate: {
                    isValidPhoneNumber: value => isValidPhoneNumber(value) || "Invalid phone number"
                  }
                }}
                render={({ field, fieldState }) => (
                  <>
                    <CustomPhoneInput
                      fieldControl={control}
                      label="Phone Number"
                      clearErrors={clearErrors}
                      country="US"
                      value={field.value}
                      tooltipMessage=" Enter your current Phone number. This is how we'll communicate with you and keep you informed about important updates and notifications"
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      error={fieldState.invalid ? fieldState.error : null}
                      helperText={fieldState.invalid ? fieldState.error?.message : ''}
                    />
                 <div>
  <Controller
    name="home"
    control={control}
    rules={{
      validate: {
        isValidPhoneNumber: value => !value || isValidPhoneNumber(value) || "Please provide a valid contact number"
      }
    }}
    render={({ field, fieldState }) => (
      <>
        <CustomPhoneInput
          isRequiredIcon={false}
          isRequired={false}
          fieldControl={control}
          label="Home"
          clearErrors={clearErrors}
          country="US"
          fieldError={errors.home}
          value={field.value}
          tooltipMessage=" Enter your current Home contact. This is how we'll communicate with you and keep you informed about important updates and notifications"
          onChange={(value) => {
            field.onChange(value);
          }}
        />
      </>
    )}
  />
  {errors.home && (
    <FormHelperText style={{ color: "#D32F2F" }}>
      {errors.home.message}
    </FormHelperText>
  )}
</div>
<div>
  <Controller
    name="work"
    control={control}
    rules={{
      validate: {
        isValidPhoneNumber: value => !value || isValidPhoneNumber(value) || "Please provide a valid contact number"
      }
    }}
    render={({ field, fieldState }) => (
      <>
        <CustomPhoneInput
          isRequiredIcon={false}
          isRequired={false}
          fieldControl={control}
          label="Work"
          clearErrors={clearErrors}
          country="US"
          fieldError={errors.work}
          value={field.value}
          tooltipMessage=" Enter your current work number. This is how we'll communicate with you and keep you informed about important updates and notifications"
          onChange={(value) => {
            field.onChange(value);
          }}
        />
      </>
    )}
  />
  {errors.work && (
    <FormHelperText style={{ color: "#D32F2F" }}>
      {errors.work.message}
    </FormHelperText>
  )}
</div>

                    <CustomInputField
                      fieldName="fax"
                      // requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
                      fieldPattern={FAX_REGEX_PATTERN}
                      patternErrorMessage={FAX_INVALID_MESSAGE}
                      maxLength={10}
                      maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE_TEN}
                      validator={false}
                      fieldId="fax"
                      fieldType="text"
                      fieldLabel="Fax"
                      value={fax}
                      fieldControl={control}
                      fieldError={errors}
                      tooltipMessage=" Enter your fax number. This is how we'll communicate with you and keep you informed about important updates and notifications"
                      onInput={handleFaxChange}
                      
                    // fieldRequiredIcon="*"
                    />


                  </>
                )}
              />

            </section>
            <br />




            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Update" />
            </div>
          </CardContent>
          {/* )} */}

        </form>
      </Card>
    </div>
  );
}

export default Profile;
