import React, { useEffect, useState } from 'react'
import CustomHeading from '../../components/CustomHeading'
import CustomToastContainer from '../../components/CustomToastContainer'
import CustomButton from '../../components/CustomButton'
import { useNavigate, useParams } from 'react-router-dom';
import {
    Autocomplete,
    Card,
    CardContent,
    DialogActions,
    DialogContent,
    FormControl,
    FormLabel,
    TextField,
    Typography,
  } from "@mui/material";
import CustomInputField from '../../components/CustomInputField';
import { DESCRIPTION_REQUIRED_MESSAGE, MAX_LENGTH_THOUSAND, MAX_LENGTH_TWO_FIFTY, MIN_EIGHT_LENGTH_VALIDATE_MESSAGE, MIN_LENGTH_VALIDATE_MESSAGE, TOPIC_REQUIRED_MESSAGE } from '../../utills/ApplicationConstants';
import { BASE_URL, DISCUSSION } from '../../utills/ApplicationRouting';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { request } from '../../services/AxiosConfig';
import CustomTooltipField from '../../components/CustomTooltipField';
import CustomLoading from '../../components/CustomLoading';

function DiscussionBoardEdit() {
    const { token, homeId,roles } = useSelector((state) => state.hybridhoa);

    const {
        handleSubmit: handleSubmitModel,
        control: controlModal,
        formState: { errors: errorsModal },
        setValue: setValueModal,
        setError: setErrorModal,
        reset: resetModal,
      } = useForm();
      const [topic, setTopic] = useState('')
      const [description, setDescription] = useState('')
      const [selectedDiscussionId, setSelectedDiscussionId] = useState(null);
      const[isLoading,setIsLoading]=useState(false)
    const { discussionId } = useParams();
    const navigate = useNavigate();

  const handleViewClick = () => {
    navigate(-1);
  };

  const getDisussionDetailsById = async () => {
    try {
      setIsLoading(true)
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${DISCUSSION}/${discussionId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        // setTemplateDetails(data);
        setTopic(data.topic)
        setDescription(data.description);
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getDisussionDetailsById();
  }, [discussionId])
  const handleTopicChange = (event) => {
    setTopic(event.target.value)
  }
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value)
  }
  const submitEdit = () => {
    const trimmedTopic = topic ? topic.trim() : '';
    const trimmedDescription = description ? description.trim() : '';

    const putData = {

      topic: trimmedTopic.trim(),
      description: trimmedDescription.trim(),
      role: roles[0]
    };

    if (!trimmedTopic) {
      setErrorModal("topic", {
        type: "manual",
        message: TOPIC_REQUIRED_MESSAGE,
      });
      // setIsLoading(false);
      return;
    }
    if (!trimmedDescription) {
      setErrorModal("description", {
        type: "manual",
        message: DESCRIPTION_REQUIRED_MESSAGE,
      });
      // setIsLoading(false);
      return;
    }

    setIsLoading(true);

    request({ url: `${DISCUSSION}/${discussionId}`, method: "put", data: putData })
      .then((res) => {
        // setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          setIsLoading(false);
          //   getTemplateDetails();
         
         setTimeout(()=>{
            navigate('/discussionboard')
         },[3000])
        //   reset();
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        // if (error.response) {
        //   console.log("error.response.status" + error.response.status);
        // }
        if (error.response && error.response.data) {
          const message = error.response.data.message;
          toast.error(message, { position: "top-center" });
          console.log("error.response.status" + error.response.status);
        } else {
          console.error(error); // Log the entire error for further debugging
          toast.error("An error occurred while processing your request.");
        }
      });
  };
  return (
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomHeading title="Discussion Board" />
        {isLoading && <CustomLoading />}

        <CustomToastContainer />
        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleViewClick}
          />
        </div>
      </div>
      <Card>
      <form
        onSubmit={handleSubmitModel(submitEdit)}
        className="space-y-4 md:space-y-6 "
      >
         <CardContent>
         <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
            <CustomTooltipField
              fieldName="topic"
              //   requiredErrorMessage={TOPIC_REQUIRED_MESSAGE}
              // fieldPattern={ONLY_ALPHABETIC_WITH_WHITE_SPACE}
              // patternErrorMessage={ALPHABETS_ARE_ALLOWED}
              fieldId="topic"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={250}
              maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
              fieldLabel="Topic"
              fieldControl={controlModal}
              fieldError={errorsModal}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
              value={topic}
              onInput={handleTopicChange}
              tooltipMessage={"Topic for the discussionBoard"}
            />

            <CustomTooltipField
              fieldName="description"
              //   requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
              fieldId="description"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldLabel="Description"
              fieldControl={controlModal}
              fieldError={errorsModal}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
              value={description}
              onInput={handleDescriptionChange}
              tooltipMessage={"Description for the discussionBoard"}

            />
</section>
<div class="text-center mt-10">
<CustomButton fieldType="submit" buttonName="Update" />
</div>
</CardContent>
  
      </form>
      </Card>
    </div>
  )
}

export default DiscussionBoardEdit
