import CustomHeading from '../../../components/CustomHeading'
import CustomButton from '../../../components/CustomButton'
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useMemo, useState,useRef } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomTooltipFiled from '../../../components/CustomTooltipField'
import JoditEditor from "jodit-react";

import {
    Autocomplete,
    Card,
    CardContent,
    FormControl,
    FormLabel,
    TextField,
  InputLabel,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Typography,
    Box,
    Tooltip,
    IconButton,
  } from "@mui/material";
import CustomLoading from '../../../components/CustomLoading';
import CustomInputField from '../../../components/CustomInputField';
import {
    DESCRIPTION_MAX_LENGTH_VALIDATE_MESSAGE,
    DESCRIPTION_MIN_LENGTH_VALIDATE_MESSAGE,
    DESCRIPTION_REQUIRED_MESSAGE,
    MAX_LENGTH_FIVE_THOUSAND,
    MAX_LENGTH_THOUSAND,
    MAX_LENGTH_TWO_FIFTY,
    MIN_EIGHT_LENGTH_VALIDATE_MESSAGE,
    MIN_LENGTH_VALIDATE_MESSAGE,
  
    NOTIFICATION_REQUIRED_MESSAGE,
    NOTIFICATION_TYPE_MESSAGE,
    PUBLISH_DATE_REQUIRED_MESSAGE,
    REQUEST_TYPE_REQUIRED_MESSAGE,
    
    SUBJECT_MAX_LENGTH_VALIDATE_MESSAGE,
    SUBJECT_MIN_LENGTH_VALIDATE_MESSAGE,
    SUBJECT_REQUIRED_MESSAGE,
  } from "../../../utills/ApplicationConstants";
import CustomNotificationRadioButton from '../../../components/CustomNotificationRadioButton';
import CustomDatePicker from '../../../components/CustomDatePicker';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { BASE_URL, GROUP_CONFIG, NOTIFICATION } from '../../../utills/ApplicationRouting';
import axios from "axios";
import { request } from '../../../services/AxiosConfig';
import CustomModel from '../../../components/CustomModel';
import { InfoOutlined } from '@mui/icons-material';
import CustomRadioButton from '../../../components/CustomRadioButton';
import CustomToastContainer from '../../../components/CustomToastContainer';

function McBmArcNotificationAdd() {

  const [content, setContent] = useState(""); // Initialize content state with an empty string

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        watch,
       reset,

        setError,
      } = useForm();
      console.log(errors);
      const editorRef = useRef(null);

    const [publishContent, setPublishContent] = useState("No");
    const [publishContentHo, setPublishContentHo] = useState("No");
  const [publishDated, setPublishDated] = useState("");
  const [notificationType, setNotiFicationType] = useState(false);

    const [minDate, setMinDate] = useState(null);
    const maxDate = dayjs().add(1, "year").endOf("day");

    const notificationTypes = watch('notificationType'); // Default to false
    // useEffect(() => {
    //   const newMinDate = notificationTypes === "true"
    //     ? dayjs().startOf('day')  // If notificationType requires today's date
    //     : dayjs().add(1, 'day').startOf('day');  // If notificationType requires starting from tomorrow
    
    //   setMinDate(newMinDate);
    
    //   // Check if the selected publish date is invalid based on the new notification type's constraints
    //   const selectedPublishDate = dayjs(publishDate);
    //   if (publishDate && (selectedPublishDate.isBefore(newMinDate) || selectedPublishDate.isAfter(maxDate))) {
    //     setPublishDate(""); // Clear the selected date if it's outside the allowed range
    //     setValue('publishdate', ""); // Clear the form field managed by react-hook-form
    //   } else {
    //     setValue('publishdate', publishDate); // Retain the existing date if it's valid
    //   }
    // }, [notificationTypes, setValue, publishDate, maxDate]);
    useEffect(() => {
      const newMinDate = notificationTypes === "true"
        ? dayjs().startOf('day')  // If notificationType requires today's date
        : dayjs().add(1, 'day').startOf('day');  // If notificationType requires starting from tomorrow
    
      setMinDate(newMinDate);
    
      // Clear both the form value and the actual input value of the date picker
      setPublishDated("");  // Reset the state that stores the selected date
      // setValue('publishdate', null);  // Reset the form field
      // document.querySelector('input[name="publishdate"]').value = "";  // Manually clear the visible input field
    }, [notificationTypes, setValue]);
    
    
      

  const [boardMember,setBoardMember]=useState("")
  const [hoMember,setHoMember]=useState("")
  const [publishDateData, setPublishDateData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { token, userLoginDetails, roles } = useSelector(
    (state) => state.hybridhoa
  );
  const navigate = useNavigate();

    const [groupConfigurationDetails, setGroupConfigurationDetails] = useState(
        []
      );
    const handleViewClick = () => {
        navigate(-1);
      };

      const handleContentChange = (newContent) => {
        setContent(newContent);
      };   

      const config = useMemo(
        () => ({
          readonly: false,
          showCharsCounter: false,
          showWordsCounter: false,
          showXPathInStatusbar: false,
          removeButtons: ['file', 'image', 'video', 'spellcheck', 'speechRecognize','preview','print','source','about'],
          showPlaceholder: false,
        }),
        []
      );
    
      const isEmptyContent = (content) => {
        if (!content) return true; // Check for undefined or null content
        // Strip HTML tags and check if content is empty or just spaces
        console.log(content);
    
        const strippedContent = content.replace(/<\/?[^>]+(>|$)/g, "").trim();
        const finalData = strippedContent.replace(/&nbsp;/g, "").trim(); 
        console.log(finalData.length);
        return finalData.length === 0;
      };
      const getGroupConfigurationDetails = async () => {
        try {
          
          const storedToken = token;
    
          console.log(storedToken);
    
          const response = await axios.get(`${BASE_URL}${GROUP_CONFIG}`, {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });
          console.log(response);
    
          if (response.status === 200) {
            const data = response.data;
            setGroupConfigurationDetails(data);
    
            setIsLoading(false);
          setContent(data.content || ""); // Ensure content is set to "" if response.data.content is undefined
              
          } else {
            setIsLoading(false);
            throw new Error("Failed to fetch data");
          }
        } catch (error) {
          setIsLoading(false);
          console.error("Error fetching data:", error);
        }
      };
      useEffect(() => {
        // Assuming data is your array of objects
        const item = groupConfigurationDetails.find(
          (item) => item.topic === 
          // "Publish a notification to homeowners with 51% approval"
            "Publish a notification to homeowners with 51% approval"

        );
        const itemHo = groupConfigurationDetails.find(
          (item) =>
            item.topic ===
            // "Allow board members, ARC users, and MC users  to publish general notifications to homeowners"
               "Allow board members, ARC users, and MC users to publish general notifications to homeowners"
        );
        if (item) {
          setPublishContent(item.content);
          setBoardMember(itemHo.content === "Yes" ? true : false);
           console.log("TestCase", item.content);
        }
        if (itemHo) {
          setPublishContentHo(itemHo.content);
          setHoMember(item.content === "Yes" ? true : false);
          console.log("TestCase", itemHo.content);
        }
      }, [groupConfigurationDetails, publishContent, publishContentHo]);
      useEffect(() => {
        getGroupConfigurationDetails();
      }, []);


      const handlePublishDateChange = (selectedPublishDate) => {
        setPublishDated(selectedPublishDate);
        console.log("hi");
      };

      const onSubmit = (data) => {
       

        if (!data.notificationType) {
            setNotiFicationType(true);
            return;
        }
    
        const descriptions = data.editorContent; // Check if data and data.description are defined
        console.log("descriptions", descriptions);

        const trimmedSubject = data.subject && data.subject.trim();
        if (!trimmedSubject) {
            setError("subject", {
                type: "manual",
                message: SUBJECT_REQUIRED_MESSAGE,
            });
            return;
        }
        
        if (!descriptions) {
            setError("editorContent", {
                type: "manual",
                message: DESCRIPTION_REQUIRED_MESSAGE,
            });
            return;
        }
    
        const cleanedData = descriptions.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
        const finalData = cleanedData.replace(/&nbsp;/g, " ");
        const trimData = finalData.trim();
        console.log(finalData);
    
        if (trimData.length < 2) {
            setError("editorContent", {
                type: "manual",
                message: DESCRIPTION_MIN_LENGTH_VALIDATE_MESSAGE,
            });
            return;
        }
    
        if (trimData.length > 1000) {
            setError("editorContent", {
                type: "manual",
                message: DESCRIPTION_MAX_LENGTH_VALIDATE_MESSAGE,
            });
            return;
        }
    
        // Trim leading and trailing white spaces from the subject
        
       
        if (!data.publishdate) {
          setError("publishdate", {
              type: "manual",
              message: PUBLISH_DATE_REQUIRED_MESSAGE,
          });
          return;
      }
  
      setIsLoading(true)
        const postData = {
            subject: trimmedSubject,
            description: data.editorContent.replace(/<\/?[^>]+(>|$)/g, ""),
            role: roles[0],
            isGeneral: data.notificationType,
            isHidden: false,
            publishDate: dayjs(publishDated).format("YYYY-MM-DD"),
            publishToHo: boardMember,
            publishToBoard: hoMember,
        };
        console.log(trimmedSubject);
        console.log(data.editorContent);
        console.log(data.notificationType);
        console.log(roles[0]);
        setIsLoading(true);
    
        request({ url: NOTIFICATION, method: "post", data: postData })
            .then((res) => {
                console.log(res);
                
                setIsLoading(false)
                if (res.status === 200) {
                    console.log("Success");
                    setIsLoading(false);
                    setTimeout(() => {
                        navigate(-1);
                    }, 4000);
    
                    if (roles === "ROLE_ARC") {
                        getARCNotificationDetails();
                    } else if (roles === "ROLE_BM") {
                        getBmNotificationDetails();
                    } else {
                        getMcNotificationDetails();
                    }
                    setValue("subject", "");
                    setValue("editorContent", "");
                    setValue("notificationType", "");
                    setPublishDateData(dayjs(data.publishDate));
                    handleAddClose(res.status);
                }
            })
            .catch((error) => {
              console.log(error.response);
              setIsLoading(false);
              if (error.response) {
                console.log("error.response.status" + error.response.status);
              }
            });
    };
    const handleNotificationTypeClose = () => {
        setNotiFicationType(false); // Close the modal
      };

    const notificationTypeActions = (
        <>
          <CustomButton
            fieldType="button"
            buttonName="Ok"
            click={handleNotificationTypeClose}
          />
        </>
      );

    const notificationTypeModel = (
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 md:space-y-6">
          <DialogContent dividers>
            <Typography>
              Notification Type is Not Configured. Please Contact Group Admin.
            </Typography>
          </DialogContent>
          <DialogActions>{notificationTypeActions}</DialogActions>
        </form>
      );

      const renderTooltip = (message) => (
        <Tooltip title={<Typography sx={{ fontSize: "14px" }}>{message}</Typography>} placement="right">
          <IconButton>
            <InfoOutlined />
          </IconButton>
        </Tooltip>
      );
    
  return (
    <div>
   


        <CustomModel
        title="Notification Type Not Configured"
        // submit={onSubmitNotificationType}
        content={notificationTypeModel}
        action={notificationTypeActions}
        openStatus={notificationType}
        closeStatus={handleNotificationTypeClose}
        iconCloseStatus={handleNotificationTypeClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      />



        <div class="flex flex-col md:flex-row">
        <CustomHeading title="Add Notification" />
        <CustomToastContainer />

        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleViewClick}
          />
        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
             <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
             <CardContent>
  <CustomTooltipFiled
              fieldName="subject"
              // requiredErrorMessage={SUBJECT_REQUIRED_MESSAGE}
              fieldId="subject"
              minLength={8}
              minLengthErrorMessage={SUBJECT_MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={250}
              maxLengthErrorMessage={SUBJECT_MAX_LENGTH_VALIDATE_MESSAGE}
              fieldLabel="Subject"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
               tooltipMessage={"Enter the subject of the notification"}
            />
<br/>
<div className="flex items-center md:w-1/2 ">
<div className="w-full ">


<Controller
  name="editorContent"
  control={control}
  defaultValue=""
  rules={{
    // required: DESCRIPTION_REQUIRED_MESSAGE,
    // validate: value => {
    //   const strippedValue = value.replace(/(<([^>]+)>)/gi, '');
    //   console.log("value",value);
    //   if (strippedValue.length < 2 || strippedValue.length > 1000) {
    //     return 'The Description field length should be a minimum of 2 and a maximum of 1000 characters';
    //   }
    //   return true;
    // }
  }}
  render={({ field }) => (
    <>
      <InputLabel htmlFor="editorContent">
      Description <span style={{ color: "red" }}>*</span>
    </InputLabel>
    <JoditEditor
  ref={editorRef}
  value={content} // Ensure content state is passed to value prop
  config={config}
  onBlur={field.onBlur} // Handle blur event
  onChange={(newContent) => {
    // const cleanedContent = newContent.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
    // const finalContent = cleanedContent.replace(/&nbsp;/g, " "); // Replace &nbsp; with space
    // console.log("Final content:", finalContent);

    handleContentChange(newContent); // Call your content handler
    field.onChange(newContent); // Pass cleaned content to the field
  }}
/>

    {errors.editorContent && (
      <span className="text-red-500">
        {errors.editorContent.message}

      </span>

    )}
    </>
   ) }
   />
   </div>
  <div>
    {renderTooltip("Enter the  description ")}
  </div>
</div>
<br/>
<CustomNotificationRadioButton
      fieldName="notificationType"
      fieldControl={control} // Assuming 'control' is the control object from react-hook-form
      publishContent={publishContent}
      publishContentHo={publishContentHo}
      requiredErrorMessage={NOTIFICATION_TYPE_MESSAGE}
      labelName="Notification Type"
      valueOne={false}
      labelOne={
        
         ' Need 51% Approval from Board Member'
         
     
      }
      valueTwo={true}
      labelTwo={
        
         ' General Notification - This will be Publish General Notification to Homeowners'
         
      }
      // value={selectedValue} // Pass the selected value
      // onChange={handleRadioButtonChange} // Handle the change event
      tooltipMessage={"Select the  notification Type"}
    />
<br/>
         
            <CustomDatePicker
              fieldName="publishdate"
              label="Publish Date"
              control={control}
              // requiredErrorMessage={PUBLISH_DATE_REQUIRED_MESSAGE}
              minDate={minDate}
              maxDate={maxDate}
              value={publishDated}
              onChange={handlePublishDateChange}
              fieldRequiredIcon="*"
              tooltipMessage={"Select the date when the notification should be published"}
            />
           
                
                 
            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Create" />
              {isLoading && <CustomLoading />}
            </div>
        </CardContent>
          </section>
        </form>
      </Card>
    </div>
  )
}

export default McBmArcNotificationAdd