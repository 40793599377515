import { Delete, EditNoteOutlined, ListAlt } from "@mui/icons-material";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {  useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import { BASE_URL, CATEGORY, MAINTENANCE_REQUEST_MC } from "../../../utills/ApplicationRouting";
import CustomButton from "../../../components/CustomButton";
import CustomHeading from "../../../components/CustomHeading";
import CustomMaterialTable from "../../../components/CustomMaterialTable";
import CustomLoading from "../../../components/CustomLoading";


function McMaintenanceList() {
  const { token,roles } = useSelector(state => state.hybridhoa);
  const [maintenanceRequestDetails,setMaintenanceRequestDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [categoryDetails, setCategoryDetails] = useState([]);

      
    const navigate = useNavigate();
 
    const handleViewClick = (selectedRow) => {
 navigate(`/mcmaintenance/${selectedRow.row.original.maintenanceId}`);
    };

    const getCategory = async () => {
      try {
        const storedToken = token;
    
        const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
    
        if (response.status === 200) {
          const allCategories = response.data;
    
          // Filter categories based on conditions
          // const filteredCategories = allCategories.filter(category => {
          //   return (
          //     (category.isEventForArc && roles.includes('ROLE_ARC')) ||
          //     (!category.isEventForArc && roles.includes('ROLE_BM'))
          //   );
          // });
    
          setCategoryDetails(allCategories); // Set the filtered data to the state
          setIsLoading(false);
        } else {
          setIsLoading(false);
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        // Handle error
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    };
    
    useEffect(() => {
      getCategory();
    }, []);
    const handleEditClick = (selectedRow) => {
      console.log("sel",selectedRow);
      if(selectedRow.original.role !== "ROLE_HO") {
navigate(`/mcmaintenancerequestedit/${selectedRow.original.maintenanceId}`);
      }
  }
   const getMaintananceRequestDetails = async () => {
    try {
          const storedToken = token

      console.log(storedToken);
      
        const response= await axios.get(`${BASE_URL}${MAINTENANCE_REQUEST_MC}`,{
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
              console.log(response.data); 

      if (response.status === 200) {
        const data = response.data;
        setMaintenanceRequestDetails(data);
        setIsLoading(false);

      } else {
        setIsLoading(false);
        throw new Error('Failed to fetch data');
      }
     
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };



useEffect(()=>{
  getMaintananceRequestDetails();
},[])
 

const maintenanceRequestTable = useMemo((rowData) => [
  {
    accessorKey: "mrId",
    header: "Id",
    size: 20,
  },
  {
    accessorKey: "categoryDetails.category",
    header: "Category",
    Cell: ({ row }) => {
      if (row.original.categoryDetails === null || row.original.categoryDetails === undefined) {
        return "";
      } else {
        return row.original.categoryDetails.category || "";
      }
    },
  },
  {
    accessorKey: "description",
    header: "Description",
    accessorFn: ( row ) => row.topic?row.topic:"",
  },
  //  {
  //    accessorKey: "isUrgent",

  //    accessorFn: (row) => (row && row.isUrgent ? "Yes" : "No"),
  //    header: "Urgent?",
  //  },
  // {
  //   accessorKey: "category",
  //   header: "Category",
  //   Cell: ({ row }) =>
  //     row.original.categoryDetails
  //       ? row.original.categoryDetails.category
  //       : "N/A",
  // },
 
  {
    accessorKey: "status",
    header: "Status",
    Cell: ({ row }) => {
      const status = row.original.status;
      if(status==="PENDING_IN_ARC"){
        return "Pending In ARC"
      }else{
      return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
      }
    },
  },
  
  // {
  //   accessorKey: "homeDetails.address",
  //   header: "Created By",
  //   cell: ({ row }) => {
  //     const address = row.original.createdByDetails?.homeDetails?.address;
  //     return address || "N/A";
  //   },},
  {
    accessorKey: "startDate",
    header: "Start Date",
    accessorFn: (row) => {
      const startDate = moment(row?.startDate);
      return startDate.isValid() ? startDate.format("MM/DD/YYYY") : "N/A";
    }
  },
  {
    accessorKey: "endDate",
    header: "End Date",
    accessorFn: (row) => {
      const endDate = moment(row?.endDate);
      return endDate.isValid() ? endDate.format("MM/DD/YYYY") : "N/A";
    }
  },
  {
    accessorKey: "isUrgent",
    
    accessorFn: (row) => (row && row.isUrgent ? "Yes" : "No"),
    header: "Is Urgent?",
    size: 20,
  },
    ]);

    const rowActions = (row) => {
      const actions = [];
  
      if (row.original.status !== "APPROVED" && row.original.status !== "REJECTED") {
        actions.push({
          
              label: "Edit",
              icon: <EditNoteOutlined color="primary" />,
              click: () => handleEditClick(row),
            
        });
      }
  
      // if (actions.length === 0) {
      //   actions.push({
      //     label: "N/A",
      //     icon: null, // You can add an icon if you want, or keep it null
      //     click: () => {}, // No action on click
      //   });
      // }
  
      return actions;
    };

  const handleAddClick = () => {
    navigate("/maintenancerequestmcadd");
  };

  const tableActionButtons =  (
    <CustomButton
      fieldType="button"
      buttonName="Create"
      click={handleAddClick}
    />
  );
 
 
  
   return (
     <div>
      {isLoading && <CustomLoading/>}
       <CustomHeading title="Maintenance Request" />
 
      
       <CustomMaterialTable
         columns={maintenanceRequestTable}
         data={maintenanceRequestDetails}
         rowActions={rowActions}
        //  isLoading={isLoading}
         actionButtons={tableActionButtons}
         onRowClick = {handleViewClick}
       />
     </div>
   );
 }

export default McMaintenanceList