import React, { useEffect, useState } from "react";
import CustomButton from "../../../components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import CustomInputField from "../../../components/CustomInputField"
import { MAX_LENGTH_FIVE_THOUSAND, MAX_LENGTH_THOUSAND, MIN_LENGTH_VALIDATE_MESSAGE, WHAT_NEED_ATTENTION_REQUIRED_MESSAGE,MORE_INFORMATION_REQUIRED_MESSAGE } from "../../../utills/ApplicationConstants"
import {
    Card,
    CardContent,
    FormControlLabel,
    FormLabel,
    Switch,
    Typography,
} from "@mui/material";
import CustomHeading from "../../../components/CustomHeading";
import { FileUploader } from "react-drag-drop-files";
import { useSelector } from "react-redux";
import axios from "axios";
import { ADD_MAINTENANCE, BASE_URL, EDIT_MAINTENANCE, EXTERNAL_OCCUPATION, EXTERNAL_USER, MAINTENANCE_REQUEST_EDIT_BY_ID } from "../../../utills/ApplicationRouting"
import { request } from "../../../services/AxiosConfig";
import CustomToastContainer from "../../../components/CustomToastContainer";
import CustomLoading from "../../../components/CustomLoading"
import { InfoOutlined } from "@mui/icons-material";
import {Tooltip,IconButton,} from "@mui/material";
import CustomFile from "../../../components/CustomFile";
import CustomTooltipField from "../../../components/CustomTooltipField";
import CustomFileEdit from "../../../components/CustomFileEdit";

function MaintenanceRequestEditGeneralUser() {
    const [file, setFile] = useState([]);
    const fileTypes = ["JPG", "PNG", "JPEG", "PDF"];
    const navigate = useNavigate();
    const { maintenanceId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [attention, setAttention] = useState('')
    const [description, setDescription] = useState('')
    const { token } = useSelector(state => state.hybridhoa);
    const [isUrgent, setIsUrgent] = useState(false)
    const [isSafety, setIsSafety] = useState(false)
    const [categoryDetails, setCategoryDetails] = useState([]);
    const [fileData, setFileData] = useState([]);
    const [categorys, setCategorys] = useState('')
    const [externalUser, setExternalUser] = useState([]);
    const [externalUserNameatFirst, setExternalUserNameatFirst] = useState('');
    const [externalOccupationId, setExternalOccupationId] = useState('');
    const [externalUserId, setExternalUserId] = useState('');
    const [fileError, setFileError] = useState("");


    const handleViewClick = () => {
        navigate(-1);
    };

    const handleFileChange = (file) => {
        setFile(file);
    };
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue, setError } = useForm();

    const getCategory = async () => {
        try {
            const storedToken = token;

            const response = await axios.get(`${BASE_URL}${EXTERNAL_OCCUPATION}`, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            console.log(response.data);

            if (response.status === 200) {
                const data = response.data;
                setCategoryDetails(data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
                throw new Error("Failed to fetch data");
            }
        } catch (error) {
            // Handle error
            setIsLoading(false);
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getCategory();
    }, []);


    const getExternalUser = async () => {
        try {
            setIsLoading(true);
            const storedToken = token;


            const response = await axios.get(`${BASE_URL}${EXTERNAL_USER}`, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            console.log(response.data);

            if (response.status === 200) {
                const data = response.data;
                setExternalUser(data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
                throw new Error('Failed to fetch data');
            }

        } catch (error) {
            // Handle error
            setIsLoading(false);
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getExternalUser();
    }, []);

   

    const getMaintenanceRequestDetailsById = async () => {
        try {
            setIsLoading(true);
            const storedToken = token

            const response = await axios.get(`${BASE_URL}${MAINTENANCE_REQUEST_EDIT_BY_ID}/${maintenanceId}`, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            console.log(response.data);

            if (response.status === 200) {
                const data = response.data;
                setAttention(response.data.topic)
                setDescription(response.data.description)
                setFileData(data.attachments);
                if (data.isUrgent == 1) {
                    setIsUrgent(true)
                }
                if (data.isSafty == 1) {
                    setIsSafety(true)
                }
                setIsLoading(false);
                setExternalOccupationId(response.data.jobId)
                setExternalUserId(response.data.externalUserId)



                const matchingCategory = categoryDetails.find((category) => category.externalOccupationId === data.jobDetails.externalOccupationId);
                if (matchingCategory) {
                    setCategorys(matchingCategory.occupation)
                    setValue('category', matchingCategory.externalOccupationId)
                } else {
                    setCategorys('')
                    setValue('category', '')
                }


                const matchingExternalUser = externalUser.find((category) => category.loginData.externalOccupationId === data.jobDetails.externalOccupationId);
                if (matchingExternalUser) {
                    setExternalUserNameatFirst(`${matchingExternalUser.userDetails.firstName} ${matchingExternalUser.userDetails.lastName}`);
                } else {
                    setExternalUserNameatFirst('');
                }



            } else {
                throw new Error('Failed to fetch data');
            }

        } catch (error) {
            // Handle error
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getMaintenanceRequestDetailsById();
    }, [maintenanceId, categoryDetails, externalUser, setValue])



    const onSubmit = (data) => {
        if (!attention) {
            setError("whatneedsattention", {
                type: "manual",
                message: WHAT_NEED_ATTENTION_REQUIRED_MESSAGE,
            });
            return;
        }
        
        const totalFileSize = file.reduce((acc, file) => acc + file.size, 0);
    const maxSizeInMB = 10;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
  
    if (totalFileSize > maxSizeInBytes) {
      // setFileError(`Total file size should not exceed ${maxSizeInMB}MB.`);
      return;
    }
  
    // Check file types
    const allowedFileTypes = ["image/jpg", "image/png", "image/jpeg", "application/pdf"];
    const invalidFiles = file.filter(file => !allowedFileTypes.includes(file.type));
  
    if (invalidFiles.length > 0) {
      // setFileError("Some files have invalid file types. Allowed types are JPG, PNG, JPEG, PDF.");
      return;
    }


        // if (!description) {
        //     setError("moreInformation", {
        //         type: "manual",
        //         message: MORE_INFORMATION_REQUIRED_MESSAGE,
        //     });
        //     return;
        // }
        const formData = new FormData();
       
          formData.append('topic', attention);
          formData.append('description', description);
          file.forEach((file) => {
            formData.append('files', file);
            });
          formData.append('isUrgent', isUrgent ? 'true' : 'false');
          formData.append('isSafty', isSafety ? 'true' : 'false');
          formData.append('role', 'ROLE_GU');
          formData.append('jobId', externalOccupationId);
          formData.append('externalUserId', externalUserId);

        // console.log('homeId:', attention);
        // console.log('description:', description);
        // console.log('file:', file);
        // console.log('isUrgent', isUrgent ? 'true' : 'false');
        // console.log('isSafty', isSafety ? 'true' : 'false');
        // console.log('role:', 'ROLE_BM');
        // console.log('jobId', externalOccupationId);
        // console.log('externalUserId', externalUserId);

        setTimeout(()=>{
            navigate(-1)
          },2000)



        console.log('formData:', formData);


        const headers = {
            'Content-Type': 'multipart/form-data'
        };
        // return;

        request({ url: `${EDIT_MAINTENANCE}${maintenanceId}`, method: "put", data: formData, headers }).then(res => {
            setIsLoading(false);
            console.log(res);
            if (res.status == 200) {

                setIsLoading(false);

            }
        }).catch(error => {
            console.log(error.response);
            setIsLoading(false);
            if (error.response) {

                console.log("error.response.status" + error.response.status);


            }
        });
    };

    const renderTooltip = (message) => (
        <Tooltip title={<Typography sx={{ fontSize: "14px" }}>{message}</Typography>} placement="right">
        <IconButton>
        <InfoOutlined />
        </IconButton>
        </Tooltip>
        );

    return (
        <div>
            <div class="flex flex-col md:flex-row">
                <CustomHeading title="Edit Maintenance" />

                <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
                    <CustomButton
                        fieldType="button"
                        buttonName="Back"
                        click={handleViewClick}
                    />
                </div>
            </div>
            <Card>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="space-y-4 md:space-y-6 "
                >
                    <CardContent>
                        <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">

                            <CustomTooltipField
                                fieldName="whatneedsattention"
                                // requiredErrorMessage={WHAT_NEED_ATTENTION_REQUIRED_MESSAGE}
                                minLength={2}
                                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                                maxLength={1000}
                                maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
                                fieldId="whatneedsattention"
                                fieldType="text"
                                fieldLabel="What needs attention?"
                                fieldControl={control}
                                fieldError={errors}
                                fieldRequiredIcon="*"
                                multiline={true}
                                numberOfRows={1}
                                value={attention}
                                onInput={(event) => { setAttention(event.target.value) }}
                                tooltipMessage={"Please provide a brief description of what needs attention"}
                            />

                            <CustomTooltipField
                                fieldName="moreInformation"
                                minLength={2}
                                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                                maxLength={5000}
                                maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
                                fieldId="moreInformation"
                                fieldType="text"
                                fieldLabel="More Information"
                                fieldControl={control}
                                fieldError={errors}
                                multiline={true}
                                numberOfRows={1}
                                value={description}
                                onInput={(event) => { setDescription(event.target.value) }}
                                tooltipMessage={"You can provide additional information or details here if needed"}
                            />
                             <div className="flex items-center md:w-1/2 ">
  <div className="w-full ">
                            <FormControlLabel control={<Switch checked={isUrgent} onChange={(event) => setIsUrgent(event.target.checked)} />} label="Is this issue urgent?" > </FormControlLabel></div>         <div>{renderTooltip("Check this if the issue requires immediate attention.")}</div></div>
                            <div className="flex items-center md:w-1/2 ">
  <div className="w-full ">
                            <FormControlLabel control={<Switch checked={isSafety} onChange={(event) => setIsSafety(event.target.checked)} />} label="Is this issue actively causing property damage or a threat to personal safety? " > </FormControlLabel>
                            </div>         <div>{renderTooltip("Is this issue actively causing property damage or a threat to personal safety?")}</div></div>        


                            <CustomTooltipField
                                fieldName="Job-Category"
                                fieldId="Job-Category"
                                fieldType="text"
                                fieldLabel="Job-category"
                                fieldControl={control}
                                fieldError={errors}
                                multiline={true}
                                numberOfRows={1}
                                disabled={true}
                                value={categorys}
                                tooltipMessage={"Please select the Job category that best fits your request"}
                            />

                            <CustomTooltipField
                                fieldName="External User Name"
                                fieldId="moreInformation"
                                fieldType="text"
                                fieldLabel="External User Name"
                                fieldControl={control}
                                fieldError={errors}
                                multiline={true}
                                numberOfRows={1}
                                disabled={true}
                                value={externalUserNameatFirst}
                                tooltipMessage={"Please select the External User Name that best fits your request"}
                            />
                            {/* <Controller
                                name="externalOccupation"
                                control={control}
                                rules={{ required: CATEGORY_REQUIRED_MESSAGE }}
                                render={({ field, fieldState: { error } }) => (
                                    <FormControl fullWidth error={Boolean(error)}>
                                        <Autocomplete
                                            fullWidth
                                            {...field}
                                            id="combo-box-demo"
                                            options={renderedCategory}
                                            getOptionLabel={(option) => option.category}
                                            disabled={true}
                                            // onChange={(event, newValue) => setCategorys(newValue ? newValue.category : '')}
                                            value={renderedCategory.find(option => option.category === categorys) || null}
                                            onChange={(event, newValue) => handleCategoryChange(event, newValue)}
                                            freeSolo
                                            onInputChange={(event, newInputValue) => {
                                                if (!newInputValue.trim()) {
                                                    setError("externalOccupation", {
                                                        type: "required",
                                                        message: "Field is required",
                                                    });
                                                } else if (!renderedCategory.some((option) => option.category === newInputValue)) {
                                                    setError("externalOccupation", {
                                                        type: "manualInputError",
                                                        message: "Invalid address. Please select from the provided options.",
                                                    });
                                                } else {
                                                    clearErrors("externalOccupation");
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={
                                                        <span>
                                                            Select Job-Category<span style={{ color: "red" }}> * </span>
                                                        </span>
                                                    }
                                                    variant="standard"
                                                    error={Boolean(errors.externalOccupation) && !field.value}
                                                    helperText={Boolean(errors.externalOccupation) ? errors.externalOccupation.message : ""}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                )}
                            />

                            <Controller
                                name="externalUser"
                                control={control}
                                rules={{ required: CATEGORY_REQUIRED_MESSAGE }}
                                render={({ field, fieldState: { error } }) => (
                                    <FormControl fullWidth error={Boolean(error)}>
                                        <Autocomplete
                                            fullWidth
                                            {...field}
                                            id="combo-box-demo"
                                            options={externalUserName}
                                            getOptionLabel={(option) => {
                                                const label = option.category;
                                                console.log(label); 
                                                console.log(nameExternal);// Add this line
                                                return label;
                                            }} // Ensure this returns a string
                                            // value={externalUserNameatFirst}
                                            value={nameExternal.find(option => option.category === externalUserNameatFirst) || null}
                                            onChange={(event, newValue) => {
                                                setValue("externalUser", newValue, { shouldDirty: true });
                                            }}
                                            // Enable freeSolo to allow manual input
                                            freeSolo
                                            // Validate manually entered value
                                            onInputChange={(event, newInputValue) => {
                                                if (!newInputValue.trim()) {
                                                    setError("externalUser", {
                                                        type: "required",
                                                        message: "Field is required",
                                                    });
                                                } else if (!externalUserName.some((option) => option.category === newInputValue)) {
                                                    setError("externalUser", {
                                                        type: "manualInputError",
                                                        message: "Invalid address. Please select from the provided options.",
                                                    });
                                                } else {
                                                    clearErrors("externalUser");
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={
                                                        <span>
                                                            Select External User Name<span style={{ color: "red" }}> * </span>
                                                        </span>
                                                    }
                                                    variant="standard"
                                                    error={Boolean(error) && !field.value}
                                                    helperText={Boolean(error) ? error.message : ""}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                )}
                            /> */}



                            {/* <FormLabel>
                                Attach Documents<Tooltip
        title={`Please note that uploading a new document will override the existing one`}
      >
        <IconButton>
          <InfoOutlined />
        </IconButton>
      </Tooltip>
                                <FileUploader
                                    handleChange={handleFileChange}
                                    name="file"
                                    types={fileTypes}
                                // required
                                />
                                {fileData ? <p>{fileData}</p> : <p>No file selected</p>}
                            </FormLabel> */}
                           <CustomFileEdit downloadFiles={true} fileData={fileData} handleFileChange={handleFileChange} token={token} file={file}/>
                        </section>

                        <div class="text-center mt-10">
                            <CustomButton fieldType="submit" buttonName="Update" />
                            {isLoading && <CustomLoading />}
                        </div>
                    </CardContent>
                </form>
            </Card>
            <CustomToastContainer />
        </div>
    );
}
export default MaintenanceRequestEditGeneralUser