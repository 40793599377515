import React, { useEffect, useMemo, useState } from "react";
import CustomHeading from "../../../components/CustomHeading";
import CustomMaterialTable from "../../../components/CustomMaterialTable";
import { json, useNavigate, useParams } from "react-router-dom";
import {
  EditNoteOutlined,
  ListAlt,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import CustomModel from "../../../components/CustomModel";
import { Controller, useForm } from "react-hook-form";
import {
  CATEGORY_REQUIRED_MESSAGE,
  DESCRIPTION_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_THOUSAND,
  MAX_LENGTH_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
  PUBLISH_DATE_REQUIRED_MESSAGE,
  DOCUMENT_TYPE_INVALID_MESSAGE,
  REQUEST_TYPE_REQUIRED_MESSAGE,
  ATTACHMENT_REQUIRED_MESSAGE,
} from "../../../utills/ApplicationConstants";
import {
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Card,
  List,
  CardContent,
} from "@mui/material";
import CustomInputField from "../../../components/CustomInputField";
import { FileUploader } from "react-drag-drop-files";
import CustomButton from "../../../components/CustomButton";
import CustomDatePicker from "../../../components/CustomDatePicker";
import CustomRadioButton from "../../../components/CustomRadioButton";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  BASE_URL,
  DOCUMENT,
  GET_ALL_ARC_DOCUMENT,
  GET_ALL_BM_DOCUMENT,
  GET_ALL_MC_DOCUMENT,
} from "../../../utills/ApplicationRouting";
import moment from "moment";
import { request } from "../../../services/AxiosConfig";
import CustomToastContainer from "../../../components/CustomToastContainer";
import CustomLoading from "../../../components/CustomLoading";
import { InfoOutlined, Delete } from "@mui/icons-material";
import {Tooltip,IconButton,ListItemText, ListItemSecondaryAction, ListItem} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import CustomPublishDatePicker from "../../../components/CustomPublishDate";
import CustomPdfFileEdit from "../../../components/CustomePdfFileEdit";


function McBmArcUnHideDocument() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openSendDoc, setOpenSendDoc] = useState(false);
  const [file, setFile] = useState([]);
  const [radioShow, setRadioShow] = useState(false);
  const [hideOpen, setHideOpen] = useState(false);
  const fileTypes = ["PDF"];
  const [fileError, setFileError] = useState("");
  const { token, roles, userDetails, userLoginDetails } = useSelector(
    (state) => state.hybridhoa
  );
  const [documentDetails, setDocumentDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [criteria, setCriteria] = useState("");
  const [publishDate, setPublishDate] = useState("");

  const minDate = dayjs().add(1,"day").startOf("day");
  const maxDate = dayjs().add(1, "year").endOf("day");
  // const [selectedRow, setSelectedRow] = useState(null);
  const [categoryValue, setCategoryValue] = useState("");
  const { documentId } = useParams();
  const [updateRes, setUpdateRes] = useState([]);
  const selectedRow = updateRes;
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [publishDateData, setPublishDateData] = useState("");
  const [descriptionData, setDescriptionData] = useState("");
  const [fileData, setFileData] = useState([]);
  const [fileOld, setFileOld] = useState([]);
  const [radioData, setRadioData] = useState("");
  const [editRadioShow, setEditRadioShow] = useState(false);
  const [PublishDateWarning, setPublishDateWarning] = useState(false);
  const [isHidden, setisHidden] = useState(false);
  const [hideSelectedRow, setHideSelectedRow] = useState("");
  const [publics,setPublics]=useState("")
  const [boardMember,setBoardMember]=useState("")
  const [hoMember,setHoMember]=useState("")
  const [documentType, setDocumentType] = useState(false);

  const handleFileChange = (file) => {
    setFile(file);
    setFileData(`Selected File: ${file.name}`);
  };
  const handleEditFileChange = (file) => {
    setFile(file);
    setFileError("");
      // setFileData(`Selected File: ${file.name}`);
  };

  const handleFileOldChange = (fileOld) => {
    console.log("fileold"+fileOld);
    setFileOld(fileOld);
  }
  const handleCategoryChange = (event) => {
    setRadioShow(event.target.value);
    setSelectedCategory(event.target.value);
    console.log(event.target.value);
  };
  const handleViewClick = (selectedRow) => {
    navigate(-1);
  };

  const handleAddClickOpen = () => {
    setOpen(true);
  };
  const handleAddClose = (status) => {
    if (status == 200) {
      setOpen(false);
    }
  };
  const handleIconClose = () => {
    setOpen(false);
    setSelectedCategory("");
    setRadioShow("");
    setCriteria("");
    setFile("");
  };

  const handleEditClose = (status) => {
    if (status == 200) {
      setOpenEdit(false);
    }
  };
  const handleEditIconClose = () => {
    setOpenEdit(false);
    setCategoryValue("");
    setEditRadioShow("");
    
    setFileData("");
  };

  const handleSendDocClose = (status) => {
    if (status == 200) {
      setOpenSendDoc(false);
    }
  };
  const handleSendDocIconClose = () => {
    setOpenSendDoc(false);
  };

  const handleHideClickOpen = (selectedRow) => {
    setHideOpen(true);
    setHideSelectedRow(selectedRow);
    console.log(selectedRow.documentId);
  };

  const handleHideClose = () => {
    setHideOpen(false);
  };
  const handleRadioChange = (e) => {
    setCriteria(e.target.value);
  };

  const handleEditPublish = (selectedPublishData) => {
    setPublishDateData(selectedPublishData);
  };
  const handleEditDescription = (e) => {
    setDescriptionData(e.target.value);
  };

  const handleEditCategory = (e) => {
    setEditRadioShow(e.target.value);
    setCategoryValue(e.target.value);
    setValue("editDocumentType", ""); 
    clearErrors("editDocumentType");
  };

  useEffect(()=>{
    if(categoryValue==="PUBLIC"){
      setPublics(true)
    }else{
      setPublics(false)
    }
  },[categoryValue])

  const handleEditRadio = (newValue) => {
    console.log("Checked value:", newValue)
    setRadioData(newValue);
  };

  const handlePublishDateChange = (selectedPublishDate) => {
    setPublishDate(selectedPublishDate);
    console.log("hi");
  };

  const {
    handleSubmit,
    control,
    reset,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();

  const getDocumentDetails = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GET_ALL_ARC_DOCUMENT}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;

        setDocumentDetails(data);
        setPublishDateWarning(false);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const getDocumentDetailsByBm = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GET_ALL_BM_DOCUMENT}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setDocumentDetails(data);
        setIsLoading(false);
        setPublishDateWarning(true);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const getDocumentDetailsByMc = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GET_ALL_MC_DOCUMENT}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setDocumentDetails(data);
        setIsLoading(false);
        setPublishDateWarning(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

 
  const handleDocumentTypeClose = () => {
    setDocumentType(false); // Close the modal
  };
  const documentTypeActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Ok"
        click={handleDocumentTypeClose}
      />
    </>
  );
  const documentTypeModel = (
    <form  className="space-y-4 md:space-y-6">
      <DialogContent dividers>
        <Typography>
          Document Type is Not Configured. Please Contact Group Admin.
        </Typography>
      </DialogContent>
      <DialogActions>{documentTypeActions}</DialogActions>
    </form>
  );

  useEffect(() => {
    const fetchData = async () => {

      {roles.includes('ROLE_BM')   ? ( 
        await getDocumentDetailsByBm()) 
        :
        roles.includes('ROLE_ARC') ? (
        await getDocumentDetails()) 
        :
        await getDocumentDetailsByMc()}
      };
      getRenterDetailsById(documentId);

    fetchData();
  }, [roles]);

  const onSubmit = (data) => {
    if (!data.description) {
      setError("description", {
        type: "manual",
        message: DESCRIPTION_REQUIRED_MESSAGE,
      });
      return;
    }
    const formData = new FormData();
    formData.append("category", selectedCategory);
    formData.append("description", data.description);
    if (selectedCategory === "PUBLIC") {
      formData.append("isPublic", data.documentType);
    } else {
      formData.append("isPublic", false);
    }
    formData.append("file", file);
    formData.append("publishDate", dayjs(publishDate).format("YYYY-MM-DD"));
    formData.append("isHidden", false);
    formData.append("role", roles[0]);

    console.log("description", data.description);
    console.log("category", selectedCategory);
    console.log("isPublic", data.documentType);
    console.log("publishDate", dayjs(publishDate).format("YYYY-MM-DD"));
    console.log("file:", file);
    // return;
    // if (!selectedCategory) {
    //   setError("category", {
    //     type: "manual",
    //     message: CATEGORY_REQUIRED_MESSAGE,
    //   });
    //   return;
    // }
    // return;
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    request({ url: DOCUMENT, method: "post", data: formData, headers })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");
        
            {roles.includes('ROLE_BM')   ? ( 
               getDocumentDetailsByBm()) 
              :
              roles.includes('ROLE_ARC') ? (
             getDocumentDetails()) 
              :
             getDocumentDetailsByMc()}
            
        
          setIsLoading(false);
          handleAddClose(res.status);
          setSelectedCategory("");
          setRadioShow("");
          setCriteria("");
          setFile("");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const getRenterDetailsById = async (id) => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${DOCUMENT}/${id}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        console.log(response.data);
        setPublics(data.isPublic)
        setisHidden(data.isHidden);
        setUpdateRes(data.documentId);
        setCategoryValue(data.category);
        setPublishDateData(dayjs(data.publishDate));
        setDescriptionData(data.description);
        // const modifiedAttachments = data.attachments.map(attachment => ({
        //   ...attachment,
        //   file: attachment.file.substring(12)
        // }));
        // setFileData(modifiedAttachments);
        setFileData(data.attachments);
        console.log("file date"+fileData);
        setRadioData(data.isPublic);
        setIsLoading(false);
        setBoardMember(data.publishToBoard);
        setHoMember(data.publishToHo);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const fileList = fileData.map((file, index) => ({
    docname: file.file.substring(file.file.lastIndexOf('/') + 1), // Extracting file name
    fileUrl: file.file, // File URL
    uploadedTimes: moment(file.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"), // Formatting date
    index: index,
  }));
  const handleAddFile = (file) => {
    const newFile = {
      file: URL.createObjectURL(file),
      createdDateAndTime: new Date().toISOString(), // Assuming you want to set the current date and time
    };
    setFileData([...fileData, newFile]);
  };
  const handleRemoveFile = (index) => {
    const newFileData = fileData.filter((_, i) => i !== index);
    setFileData(newFileData);
  };

  const handleEditClickOpen = (selectedRow) => {
    setOpenEdit(true);

    console.log("selectedRow:", selectedRow);
    console.log("selectedRow.id:", selectedRow.original.documentId);
    setSelectedRowId(selectedRow.original.documentId);
    console.log(selectedRow.documentId);
    getRenterDetailsById(selectedRow.original.documentId);
  };

  const handleSendDocClickOpen = (selectedRow) => {
    setOpenSendDoc(true);

    console.log("selectedRow:", selectedRow);
    console.log("selectedRow.id:", selectedRow.original.documentId);
    setSelectedRowId(selectedRow.original.documentId);
    console.log(selectedRow.documentId);
    getRenterDetailsById(selectedRow.original.documentId);
  };

  const onSubmitEdit = (data) => {
    if (descriptionData.trim() == "" || descriptionData == null) {
      setError("description", {
        type: "manual",
        message: DESCRIPTION_REQUIRED_MESSAGE,
      });
      return;
    }
    
    const publishDate = dayjs(publishDateData);
    const currentDate = dayjs();

    if (publishDate.isBefore(currentDate, 'day')) {
        setError("publishdate", {
            type: "manual",
            message: "Please select a future date",
        });
        setIsLoading(false);
        return;
    }
    const formData = new FormData();
    formData.append("category", categoryValue);
    formData.append("description", descriptionData);
    if (categoryValue  === "PUBLIC") {
      console.log("insife public");
      formData.append("isPublic", radioData);
    } else {
      console.log("insife public else ");

      formData.append("isPublic", false);
    }
    formData.append("file", file);
    formData.append("publishDate", dayjs(publishDateData).format("YYYY-MM-DD"));
    formData.append("isHidden", isHidden);
    formData.append("role", roles[0]);

    console.log("description", descriptionData);
    console.log("category", categoryValue);
    console.log("isPublic", radioData);
    console.log("publishDate", dayjs(publishDateData).format("YYYY-MM-DD"));
    console.log("file:", file);
    // return;
 
    console.log(formData);
    request({
      url: `${DOCUMENT}/${selectedRow}`,
      method: "put",
      data: formData,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          setIsLoading(false);
          handleEditClose(res.status);
          // setEditRadioShow('');
          // setCategoryValue('');
          // setFileData('')
          {roles.includes('ROLE_BM')   ? ( 
            getDocumentDetailsByBm()) 
           :
           roles.includes('ROLE_ARC') ? (
          getDocumentDetails()) 
           :
          getDocumentDetailsByMc()}
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };
  const onSubmitSendDoc = (data) => {
    setIsLoading(true);
    if (categoryValue === "PUBLIC" && (!hoMember && !boardMember)) {
      setDocumentType(true);
      setIsLoading(false);
      return;
  }

    if (!categoryValue) {
      setError("lastName", {
        type: "manual",
        message: CATEGORY_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (descriptionData.trim() == "" || descriptionData == null) {
      setError("description", {
        type: "manual",
        message: DESCRIPTION_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }


    const publishDate = dayjs(publishDateData);
    const currentDate = dayjs();

    if (publishDate.isBefore(currentDate, 'day')) {
        setError("publishdate", {
            type: "manual",
            message: "Please select a date in the future",
        });
        setIsLoading(false);
        return;
    }

    if(file.length ===0 && fileOld.length ===0){

      setFileError(ATTACHMENT_REQUIRED_MESSAGE);
      setIsLoading(false);
      return;
      }
  const totalFileSize = file.reduce((acc, file) => acc + file.size, 0);
  const maxSizeInMB = 10;
  const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
  const allowedFileTypes = ["application/pdf"];
    const invalidFiles = file.filter(file => !allowedFileTypes.includes(file.type));
  
    if (invalidFiles.length > 0) {
      setFileError("Some files have invalid file types. Allowed only PDF file type.");
      setIsLoading(false);
      return;

    }
    const oversizedFiles = file.filter(file => file.size > maxSizeInBytes);
if (oversizedFiles.length > 0) {
  console.log("file sixe is morrrr");
  // setFileError("Some files exceed the maximum allowed size of 10 MB.");
  setIsLoading(false);
  return;
}
if ((radioData === "" || radioData === null)&&categoryValue === "PUBLIC") {
  setError("editDocumentType", {
      type: "manual",
      message: DOCUMENT_TYPE_INVALID_MESSAGE,
  });
  setIsLoading(false);
  return;
}


    const formData = new FormData();
    formData.append("category", categoryValue);
    formData.append("description", descriptionData.trim());
    if (categoryValue === "PUBLIC") {
      formData.append("isPublic", radioData);
    } else {
      formData.append("isPublic", false);
    }
    file.forEach((file) => {
      formData.append('files', file);
    });
    formData.append("publishDate", dayjs(publishDateData).format("YYYY-MM-DD"));
    formData.append("isHidden", false);
    formData.append("role", roles[0]);

    console.log("description", descriptionData);
    console.log("category", categoryValue);
    console.log("isPublic", data.editDocumentType);
    console.log("publishDate", dayjs(publishDateData).format("YYYY-MM-DD"));
    console.log("file:", fileData);
    // return;
    setIsLoading(true);
    request({
      url: `${DOCUMENT}/${selectedRow}`,
      method: "put",
      data: formData,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          setIsLoading(false);
          setIsLoading(false);
          handleSendDocClose(res.status);
          if(roles=="ROLE_ARC"){
            getDocumentDetails()
          }else if (roles=="ROLE_BM"){
            getDocumentDetailsByBm()
          }else if (roles=="ROLE_MC"){
            getDocumentDetailsByMc()
          }
          setTimeout(() => {
            navigate(-1);
          }, 3000); 

          // {
          //   roles === "ROLE_ARC"
          //     ? getDocumentDetails()
          //     : roles === "ROLE_BM"
          //     ? getDocumentDetailsByBm()
          //     : getDocumentDetailsByMc();
          // }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };
  const onSubmitHide = (data) => {
    const DocumentId = hideSelectedRow.documentId;
    const formData = new FormData();
    formData.append("isHidden", true);

    request({ url: `${DOCUMENT}/${DocumentId}`, method: "put", data: formData })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          setIsLoading(false);

          if(roles=="ROLE_ARC"){
            getDocumentDetails()
          }else if (roles=="ROLE_BM"){
            getDocumentDetailsByBm()
          }else if (roles=="ROLE_MC"){
            getDocumentDetailsByMc()
          }
          // {
          //   roles === "ROLE_ARC"
          //     ? getDocumentDetails()
          //     : roles === "ROLE_BM"
          //     ? getDocumentDetailsByBm()
          //     : getDocumentDetailsByMc();
          // }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '' || str === null) return '';
  
    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };
  

  const documentTable = useMemo((rowData) => [
    {
      accessorKey: "serialNo",
      header: "#",
      size: 20,
      Cell: ({ row }) => row.index + 1,
    },
    {
      accessorKey: "category",
      header: "Category",
      size: 20,
      accessorFn: (row) => convertToPascalCase(row.category),
    },
    // {
    //   accessorKey: "description",
    //   header: "Description",
    // },
    {
      accessorKey: "description",
      header: "Description",
      accessorFn: (row) => {
        const description = row && row.description;
        if (description && description.length > 28) {
          return description.slice(0, 28) + "...";
        }
        return description;
      }
      // Cell: ({ row }) => {
      //   const description = row.original.description;
      //   return description.length > 28 ? `${description.slice(0, 28)}...` : description
      // },
    },
    {
      accessorKey: "uploadedBy",
      header: "Uploaded By",
      accessorFn: (row) => `${row.createdByDetails? row.createdByDetails.firstName : ""} ${row.createdByDetails? row.createdByDetails.lastName : ""} `,
      sortDescFirst: true, 
   
    },
    {
      accessorKey: "createdDateAndTime",
      header: "Created Date",
      accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),
    },
   

    {
      accessorKey: "publishDate",
      header: "Publish Date",
      Cell: ({ row }) => {
        const publishDate = row.original.publishDate;
        const status = row.original.status;
        const isPublic = row.original.isPublic;
        const category = row.original.category;
  
        const isWithinOneWeek = moment(publishDate).isSameOrBefore(moment().add(1, 'weeks'));
        const shouldHighlight = PublishDateWarning &&  category !== "CONFIDENTIAL" && status == "PENDING"  && !isPublic && isWithinOneWeek;
        const dateStyle = { color: shouldHighlight ? 'red' : 'inherit' };
  
        return (
          <span style={dateStyle}>
            {moment(publishDate).format("MM/DD/YYYY")}
          </span>
        );
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 20,
      accessorFn: (row) => convertToPascalCase(row.status),
    },
    {
      accessorKey: "Document Type",
      header: "Document Type",
      size: 20,
      accessorFn: (row) => 
      row.category === "CONFIDENTIAL" 
        ? "N/A"
        : row.isPublic 
          ? "General Document - This will be Published General Document to Homeowners" 
          : "Need 51% Approval from Board Member",
      
    },
    {
      accessorKey: "isHidden",
      header: "Is Hidden",
      size: 20,
      accessorFn: (row) =>row.createdBy == userLoginDetails.homeOwnerLoginId ? (row.isHidden ? 'Yes':'No') : "N/A",
    },
  ]);



  const rowActions = (row) => {
    const actions = [
      // {
      //   label: "View",
      //   icon: <ListAlt color="primary" />,
      //   click: () => handleViewClick(row),
      // },
    ];
    if (
      row.original.createdBy == userLoginDetails.homeOwnerLoginId &&
      !row.original.isHidden && row.original.status !== "APPROVED" && row.original.status!=="REJECTED"
    ) {
      actions.push({
        label: "Hide",
        icon: <VisibilityOutlined color="primary" />,
        click: () => handleHideClickOpen(row.original),
      });
    }
    if (
      row.original.createdBy == userLoginDetails.homeOwnerLoginId &&
      row.original.isHidden && row.original.status !== "APPROVED" 
    ) {
      actions.push({
        label: "UnHide",
        icon: <VisibilityOffOutlined color="primary" />,
        click: () => handleSendDocClickOpen(row),
      });
    }

    if (
      row.original.createdBy == userLoginDetails.homeOwnerLoginId && row.original.status !== "APPROVED" && row.original.status!=="REJECTED" && (row.original.status=== "PENDING PUBLISH" && row.original.isPublic===true && row.original.category ==="PUBLIC") || (row.original.status=== "PENDING" && row.original.isPublic===false && row.original.category ==="PUBLIC")|| (row.original.status=== "PENDING" && row.original.isPublic===false && row.original.category ==="CONFIDENTIAL")
    ) {
      actions.push({
        label: "Edit",
        icon: <EditNoteOutlined color="primary" />,
        click: () => handleEditClickOpen(row),
      });
    }
    return actions;
  };

  
  const addActionButton = (
    <>
    <CustomButton fieldType="submit" buttonName="Add" click={handleAddClose} />
    {isLoading && <CustomLoading/>}
    </>
    
  );


  const editActionButton = (
    <CustomButton
      fieldType="submit"
      buttonName="Update"
      click={handleEditClose}
    >
      {isLoading && <CustomLoading />}
    </CustomButton>
  );


  const sendDocActionButton = (
    <CustomButton
      fieldType="submit"
      buttonName="Resend"
      click={handleSendDocClose}
    >
      {isLoading && <CustomLoading />}
    </CustomButton>
  );


 

  return (
    <div>
         <div class="flex flex-col md:flex-row">
        <CustomHeading title="Show Document" />
        <CustomToastContainer />

        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleViewClick}
          />
        </div>
      </div>
      {/* <Card> */}
      <Card>
    <form
        onSubmit={handleSubmit(onSubmitSendDoc)}
        className="space-y-4 md:space-y-6 "
      >
        <CardContent>
          <Typography gutterBottom>
          <div className="flex items-center w-full md:w-1/2">
      <div className="flex-1 ">
            <Controller
              name="category"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl
                  variant="standard"
                  fullWidth
                  error={fieldState.invalid}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Select Category<span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={categoryValue}
                    onChange={(e) => {
                      handleEditCategory(e);
                      setValue("editDocumentType", ""); 
                      clearErrors("editDocumentType");
                    }}
                    label="Please Select Category"
                  >
                    <MenuItem value="PUBLIC">Public</MenuItem>
                    <MenuItem value="CONFIDENTIAL">
                      <ListItemText primary="Confidential" />
                      <ListItemSecondaryAction>
                        <Tooltip title="confidential Documents Did not shows to Home Owners" arrow>
                          <IconButton edge="end" size="small">
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </MenuItem>
                  </Select>
                  <FormHelperText style={{ color: "#D32F2F" }}>
                    {fieldState.invalid ? fieldState.error?.message : ""}
                  </FormHelperText>
                </FormControl>
              )}
            />
            </div>
     
        <Tooltip
          title={
            <Typography sx={{ fontSize: "14px" }}>{"Select Category"}</Typography>
          }
          placement="right"
          arrow
        >
          <IconButton size="small">
            <InfoOutlined />
          </IconButton>
        </Tooltip>
     
    </div>

            {/* <br />
            <br /> */}
            {categoryValue === "PUBLIC" || editRadioShow === "PUBLIC" ? (
              <CustomRadioButton
                fieldName="editDocumentType"
                fieldControl={control}
                fieldError={errors}
                // requiredErrorMessage={REQUEST_TYPE_REQUIRED_MESSAGE}
                labelName="Document Type"
                valueOne={true}
                labelOne={
                  hoMember ? (
                    "General Document - This will be Publish General Document to Homeowners" ) :''

                }
                valueTwo={false}
                labelTwo={
                  boardMember ? ("Need 51% Approval from Board Member"):''

                }
                value={radioData}
                onChange={handleEditRadio}
              />
            ) : null}

<section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
<CustomDatePicker
      fieldName="publishdate"
      label="Publish Date"
      control={control}
      minDate={minDate}
      maxDate={maxDate}
      error={errors}
      value={publishDateData}
      onChange={handleEditPublish}
      fieldRequiredIcon="*"
      validate={publishDateData} 
      tooltipMessage={"Select Publish Date"}
      // pastDateErrorMessage="Please select a date in the future"
  />
</section>

            <CustomInputField
              fieldName="description"
              fieldId="description"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldLabel="Description"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
              value={descriptionData}
              onInput={handleEditDescription}
              tooltipMessage={"Provide Description for the Document"}
            />

            {/* <br /> */}
            <br />
            {/* <FormLabel>
        Upload Document (PDF Format only){" "}
        <Tooltip title="Please note that uploading a new document will override the existing one">
          <IconButton>
            <InfoOutlined />
          </IconButton>
        </Tooltip>
        <FileUploader
          handleChange={handleAddFile}
          name="file"
          types={fileTypes}
        />
      </FormLabel> */}

      {/* <List>
        {fileList.length > 0 ? (
          fileList.map((file) => (
            <ListItem key={file.index}>
              <ListItemText
                primary={file.docname}
                secondary={`Uploaded on: ${file.uploadedTimes}`}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => handleRemoveFile(file.index)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))
        ) : (
          <ListItem>
            <ListItemText primary="No file selected" />
          </ListItem>
        )}
      </List> */}
             <CustomPdfFileEdit 
             downloadFiles={true} 
             fileError={fileError}
             fileData={fileData} 
             handleFileChange={handleEditFileChange} 
             token={token} 
             file={file}
             onFileOldChange={handleFileOldChange}
             isRequired={true} />
          </Typography>
        
        <div class="text-center mt-10">
        <CustomButton
      fieldType="submit"
      buttonName="Resend"
      click={handleSendDocClose}
    >
      
    </CustomButton>
    {isLoading && <CustomLoading />}
    
        {/* <DialogActions>{sendDocActionButton}</DialogActions> */}
        </div>
        </CardContent>
      </form>
      </Card>
      <CustomModel
        title="Document Type Not Configured"
        // submit={onSubmitNotificationType}
        content={documentTypeModel}
        action={documentTypeActions}
        openStatus={documentType}
        closeStatus={handleDocumentTypeClose}
        iconCloseStatus={handleDocumentTypeClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      />
      {/* </Card> */}
    </div>
  );
}

export default McBmArcUnHideDocument;
