import React, { useState } from 'react'
import CustomInputField from '../components/CustomInputField'
import { EMAIL_INVALID_MESSAGE, EMAIL_REQUIRED_MESSAGE } from '../utills/ApplicationConstants'
import { EMAIL_REGEX_PATTERN } from '../utills/ApplicationRegex'
import { useForm } from 'react-hook-form';
import CustomButton from '../components/CustomButton';
import { request } from '../services/AxiosConfig';
import { LINK_EMAIL } from '../utills/ApplicationRouting';

function LinkEmail() {
    const {
        handleSubmit,
        control,
        formState: { errors },
        setError, clearErrors,
      } = useForm();

    const [isLoading,setIsLoading]=useState(false);


      const onSubmit = (data) => {

     
    //     const formData = new FormData();
       
    //     formData.append('email',data.email);
    
    // const headers = {
    //   'Content-Type': 'multipart/form-data'
    // }; 
    
    request({url:`${LINK_EMAIL}/${data.email}`, method:"get"}).then(res=>{
      setIsLoading(false); 
      console.log(res);
      if(res.status==200){
        
  
        setIsLoading(false); 
        setTimeout(()=>{
        navigate('/');
      },1000)
  
        setFile('')
    }
    }).catch(error=>{
      console.log(error.res);
      setIsLoading(false); 
      if (error.response) {
     
        console.log("error.response.status" + error.response.status);
       
    
      }  
    });
      };
  return (

    <>
     <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full text-lg font-sans mb-2">
     <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-4 md:space-y-6 "
          >
         
      <div>
         <CustomInputField
                fieldName="email"
                requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
                fieldPattern={EMAIL_REGEX_PATTERN}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                validator={false}
                fieldId="email"
                fieldType="text"
                fieldLabel="Email"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
              />
    </div>
    <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Add" />
              {isLoading && <CustomLoading/>}
            </div>
      
            </form>
    </div>
    </>
  
  )
}

export default LinkEmail