// import { DialogActions, DialogContent, Divider, Menu, MenuItem, TextField, Typography } from '@mui/material';
// import { Box } from '@mui/system';
// import React, { useEffect, useState } from 'react'
// import SendIcon from '@mui/icons-material/Send';
// import AddIcon from '@mui/icons-material/Add';
// import CustomModel from '../../components/CustomModel';
// import CustomInputField from '../../components/CustomInputField';
// import CustomButton from '../../components/CustomButton';
// import { ALPHABETS_ARE_ALLOWED, DESCRIPTION_REQUIRED_MESSAGE, MAX_LENGTH_HUNDRED, MAX_LENGTH_THOUSAND, MAX_LENGTH_TWO_FIFTY, MIN_LENGTH_VALIDATE_MESSAGE, TOPIC_REQUIRED_MESSAGE } from '../../utills/ApplicationConstants';
// import { useSelector } from 'react-redux';
// import CustomLoading from '../../components/CustomLoading';
// import { ALL_DISCUSSION, BASE_URL, DISCUSSION, DISCUSSION_COMMENTS } from '../../utills/ApplicationRouting';
// import { request } from '../../services/AxiosConfig';
// import { set, useForm } from 'react-hook-form';
// import { ONLY_ALPHABETIC_WITH_WHITE_SPACE } from '../../utills/ApplicationRegex';
// import CustomToastContainer from '../../components/CustomToastContainer';
// import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify';

// import { DeleteTwoTone, EditTwoTone } from '@mui/icons-material';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import moment from 'moment';
// import { IconButton, Collapse } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import CustomHeading from '../../components/CustomHeading';
// import { showRowSelectionColumn } from 'material-react-table';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// function Discussionboard() {
//   const { token, roles, userLoginDetails } = useSelector((state) => state.hybridhoa);
//   const [isLoading, setIsLoading] = useState(false)
//   const [topic, setTopic] = useState('')
//   const [description, setDescription] = useState('')
//   const [time, setTime] = useState('')
//   const [discussionId, setDiscussionId] = useState('')
//   const [discussionData, setDiscussionData] = useState([])
//   const [discussionIdEdit, setDiscussionIdEdit] = useState('')
//   const [editCommentsMessage, setEditCommentsMessage] = useState('')
//   const [isEditing, setIsEditing] = useState(false);
//   const [selectedDiscussionId, setSelectedDiscussionId] = useState(null);
//   const [selectedDiscussionDeleteId, setSelectedDiscussionDeleteId] = useState(null);
//   const [selectedTopic, setSelectedTopic] = useState(null);
//   const [isHovering, setIsHovering] = useState(null)
//   const [selectedDescription, setSelectedDescription] = useState(null);
//   const [commentsDiscussionId, setCommentsDiscussionId] = useState(null)
//   const [createdDateAndTimes, setCreatedDateAndTimes] = useState(null)
//   const [commentMessage, setCommentMessage] = useState("");
//   const [showSendIcon, setShowSendIcon] = useState(false);
//   const [discussionIdDelete, setDiscussionIdDelete] = useState(null);
//   const {
//     handleSubmit,
//     control,
//     reset,
//     setError,
//     formState: { errors },
//   } = useForm();
//   // const {
//   //   handleSubmit:handleSubmitModel,
//   //   control: controlModal,
//   //   formState: { errors: errorsModal },
//   //   setValue: setValueModal,
//   //   setError: setErrorModal,
//   //   reset: resetModal,
//   //   watch: watchModal,
//   // } = useForm();
//   const {
//     handleSubmit: handleSubmitModel,
//     control: controlModal,
//     formState: { errors: errorsModal },
//     setValue: setValueModal,
//     setError: setErrorModal,
//     reset: resetModal,
//   } = useForm();
//   const [open, setOpen] = useState(false)
//   const [editOpen, setEditOpen] = useState(false);
//   const [deleteOpen, setDeleteOpen] = useState(false);
//   const [showStartPanel, setShowStartPanel] = useState(false);
//   const [showMidPanel, setShowMidPanel] = useState(false);
//   const [showArrow,setShowArrow]=useState(false)
//   const handleAddClickOpen = () => {
//     setOpen(true);
//   };
//   const handleAddClose = (status) => {
//     if (status === 200) {
//       setOpen(false);
//     }
//   };
//   const handleIconClose = () => {
//     setOpen(false);
//     // reset();
//   };
//   const handleEditClose = (status) => {
//     if (status === 200) {
//       setEditOpen(false);
//     }
//   };
//   const handleEditIconClose = () => {
//     setEditOpen(false);
//     resetModal();
//   };
//   useEffect(() => {
//     // Add the 'no-scroll' class to the body when the component mounts
//     document.body.classList.add('no-scroll');

//     // Remove the 'no-scroll' class from the body when the component unmounts
//     return () => {
//       document.body.classList.remove('no-scroll');
//     };
//   }, []);
  
//   const getDisussionDetails = async () => {
//     setIsLoading(true)
//     try {
//       const storedToken = token;

//       console.log(storedToken);

//       const response = await axios.get(`${BASE_URL}${ALL_DISCUSSION}${roles}`, {
//         headers: {
//           Authorization: `Bearer ${storedToken}`,
//         },
//       });
//       console.log(response);

//       if (response.status === 200) {
//         const data = response.data;
//         // setTemplateDetails(data);
//         setIsLoading(false)
//         setDiscussionData(data)
//         setIsLoading(false);
//       } else {
//         throw new Error("Failed to fetch data");
//       }
//     } catch (error) {
//       // Handle error
//       console.error("Error fetching data:", error);
//     }
//   };

//   const handleTopicChange = (event) => {
//     setTopic(event.target.value)
//   }
//   const handleDescriptionChange = (event) => {
//     setDescription(event.target.value)
//   }

//   const handleDeleteClose = () => {
//     setDeleteOpen(false);
//   };
//   useEffect(() => {
//     getDisussionDetails();
//   }, []);
//   const onSubmit = (data) => {
//     setIsLoading(true);
//     const headers = {
//       Authorization: `Bearer ${token}`,
//     };
//     const topic = data.topic ? data.topic.trim() : '';
//     const description = data.description ? data.description.trim() : '';
//     const postData = {
//       topic: topic,
//       description: description,
//       role: roles[0],
//     };

//     if (!postData.topic) {
//       setError("topic", {
//         type: "manual",
//         message: TOPIC_REQUIRED_MESSAGE,
//       });
//       setIsLoading(false);
//       return;
//     }
//     if (!postData.description) {
//       setError("description", {
//         type: "manual",
//         message: DESCRIPTION_REQUIRED_MESSAGE,
//       });
//       setIsLoading(false);
//       return;
//     }
//     request({ url: DISCUSSION, method: "post", data: postData, headers })
//       .then((res) => {
//         console.log(res);
//         if (res.status == 200) {
//           console.log("Success");
//           getDisussionDetails();
//           setIsLoading(false);
//           handleAddClose(res.status);
//           reset();
//         }
//       })
//       .catch((error) => {
//         console.log(error.response);
//         if (error.response) {
//           setIsLoading(false);
//           console.log("error.response.status" + error.response.status);
//         }
//       });
//   };

//   const submitEdit = () => {
//     setIsLoading(true);
//     const trimmedTopic = topic ? topic.trim() : '';
//     const trimmedDescription = description ? description.trim() : '';

//     const putData = {

//       topic: trimmedTopic.trim(),
//       description: trimmedDescription.trim(),
//       role: roles[0]
//     };

//     if (!trimmedTopic) {
//       setErrorModal("topic", {
//         type: "manual",
//         message: TOPIC_REQUIRED_MESSAGE,
//       });
//       setIsLoading(false);
//       return;
//     }
//     if (!trimmedDescription) {
//       setErrorModal("description", {
//         type: "manual",
//         message: DESCRIPTION_REQUIRED_MESSAGE,
//       });
//       setIsLoading(false);
//       return;
//     }


//     request({ url: `${DISCUSSION}/${selectedDiscussionId}`, method: "put", data: putData })
//       .then((res) => {
//         setIsLoading(false);
//         console.log(res);
//         if (res.status == 200) {
//           setIsLoading(false);
//           //   getTemplateDetails();
//           getDisussionDetails();
//           getDisussionDetailsById()
//           handleEditClose(res.status);
//           reset();
//         }
//       })
//       .catch((error) => {
//         console.log(error.response);
//         setIsLoading(false);
//         // if (error.response) {
//         //   console.log("error.response.status" + error.response.status);
//         // }
//         if (error.response && error.response.data) {
//           const message = error.response.data.message;
//           toast.error(message, { position: "top-center" });
//           console.log("error.response.status" + error.response.status);
//         } else {
//           console.error(error); // Log the entire error for further debugging
//           toast.error("An error occurred while processing your request.");
//         }
//       });
//   };
//   const onSubmitDelete = () => {
//     // return;
//     request({ url: `${DISCUSSION}/${selectedDiscussionDeleteId}`, method: "delete" })
//       .then((res) => {
//         console.log(res);
//         if (res.status === 200) {
//           setEditCommentsMessage('')
//           // getTemplateDetails();
//           getDisussionDetails();

//           getDisussionDetailsById();
//           getDisussionDetailsByIdComments();
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };


//   // State to store the ID of the selected discussion

//   // Function to handle opening the modal and setting the selected discussion ID
//   const handleEdit = (discussionId) => {
//     console.log("discussionId", discussionId);
//     setEditOpen(true)
//     setSelectedDiscussionId(discussionId);

//     // Call a function to fetch details of the selected discussion using its ID

//     // Open your edit modal here
//   }
//   const handleDelete = (discussionId) => {
//     console.log("discussionId", discussionId);
//     setDeleteOpen(true)
//     setSelectedDiscussionDeleteId(discussionId);

//     // Call a function to fetch details of the selected discussion using its ID

//     // Open your edit modal here
//   }
//   const addActionButton = (
//     <CustomButton fieldType="submit" buttonName="Add" click={handleAddClose}>
//     </CustomButton>
//   );
//   const addModel = (
//     <>
//       <form
//         onSubmit={handleSubmit(onSubmit)}
//         className="space-y-4 md:space-y-6 "
//       >
//         <DialogContent dividers>
//           <Typography gutterBottom>
//             <CustomInputField
//               fieldName="topic"
//               // requiredErrorMessage={TOPIC_REQUIRED_MESSAGE}
//               // fieldPattern={ONLY_ALPHABETIC_WITH_WHITE_SPACE}
//               // patternErrorMessage={ALPHABETS_ARE_ALLOWED}
//               fieldId="topic"
//               minLength={2}
//               minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
//               maxLength={250}
//               maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
//               fieldLabel="Topic"
//               fieldControl={control}
//               fieldError={errors}
//               fieldRequiredIcon="*"
//               multiline={true}
//               numberOfRows={2}
//             />

//             <CustomInputField
//               fieldName="description"
//               // requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
//               fieldId="description"
//               minLength={2}
//               minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
//               maxLength={1000}
//               maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
//               fieldLabel="Description"
//               fieldControl={control}
//               fieldError={errors}
//               fieldRequiredIcon="*"
//               multiline={true}
//               numberOfRows={2}
//             />


//           </Typography>
//         </DialogContent>
//         <DialogActions>{addActionButton}</DialogActions>
//       </form>
//     </>
//   );
//   const getDisussionDetailsById = async () => {
//     try {
//       const storedToken = token;

//       console.log(storedToken);

//       const response = await axios.get(`${BASE_URL}${DISCUSSION}/${selectedDiscussionId}`, {
//         headers: {
//           Authorization: `Bearer ${storedToken}`,
//         },
//       });
//       console.log(response);

//       if (response.status === 200) {
//         const data = response.data;
//         // setTemplateDetails(data);
//         setTopic(data.topic)
//         setDescription(data.description);
//         setIsLoading(false);
//       } else {
//         throw new Error("Failed to fetch data");
//       }
//     } catch (error) {
//       // Handle error
//       console.error("Error fetching data:", error);
//     }
//   };
//   useEffect(() => {
//     getDisussionDetailsById();
//   }, [selectedDiscussionId, selectedDiscussionDeleteId]);

//   // const [createdBy,setCreatedBy]=useState('')
//   const [userLoginId, setUserLoginId] = useState(null)
//   const handleMessageChange = (event) => {
//     setCommentMessage(event.target.value)
//     setIsEditing(false);
//     setShowSendIcon(event.target.value.trim().length > 0);
//   }

//   const handleKeyDown = (event) => {
//     if (event.key === 'Enter') {
//       isEditing ? handleEditMessage(event) : handleAddMessage(event);
//     }

//   };
//   useEffect(() => {
//     // Automatically select the first topic when the component mounts
//     if (discussionData.length > 0) {
//       const initialTopic = discussionData[0];
//       handleTopicClick(initialTopic.topic, initialTopic.description, initialTopic.discussionId, initialTopic.createdDateAndTime);
//     }
//   }, [discussionData]);
//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth > 600) {
//         setShowStartPanel(true);
//         setShowMidPanel(true);
//         setShowArrow(false)
//       } else {
//         setShowStartPanel(true);
//         setShowMidPanel(false);
//         setShowArrow(true)
//       }
//     };

//     window.addEventListener('resize', handleResize);
//     handleResize();

//     return () => window.removeEventListener('resize', handleResize);
//   }, []);
//   const handleTopicClick = (topic, description, discussionId, createdDateAndTime, createdBy) => {
//     setSelectedTopic(topic);
//     setSelectedDescription(description);
//     setCommentsDiscussionId(discussionId);
//     setCreatedDateAndTimes(createdDateAndTime);
    
//     setIsEditing(false);
//     setShowStartPanel(true);
//     setShowMidPanel(true);
  
//     // Check screen size
//     if (window.innerWidth <= 600) {
//       // If screen size is below 600 pixels, hide the start panel and show the mid panel
//       setShowStartPanel(false);
//       setShowMidPanel(true);
//     }

    
//   }

//   const handleBackClick = () => {
//     setShowMidPanel(false);
//     setShowStartPanel(true)
//   }
//   const formattedDate = createdDateAndTimes ? moment(createdDateAndTimes).format('MM/DD/YYYY HH:mm A') : ""; const [replies, setReplies] = useState([])
//   const getDisussionDetailsByIdComments = async () => {
//     try {
//       const storedToken = token;
//       const userLogin = userLoginDetails.homeOwnerLoginId
//       setUserLoginId(userLogin);
//       console.log("UserDetailsOf", userLoginDetails.homeOwnerLoginId);
//       console.log(storedToken);

//       const response = await axios.get(`${BASE_URL}${DISCUSSION_COMMENTS}/${commentsDiscussionId}`, {
//         headers: {
//           Authorization: `Bearer ${storedToken}`,
//         },
//       });
//       console.log(response);

//       if (response.status === 200) {
//         const data = response.data;
//         // setTemplateDetails(data);
//         // setTopic(data.topic)
//         setReplies(data)
//         setIsLoading(false);
//       } else {
//         throw new Error("Failed to fetch data");
//       }
//     } catch (error) {
//       // Handle error
//       console.error("Error fetching data:", error);
//     }
//   };
//   useEffect(() => {
//     getDisussionDetailsByIdComments()
//   }, [commentsDiscussionId])
//   const handleAddMessage = async (e) => {
//     const trimmedMessage = commentMessage.trim();
//     if (!trimmedMessage) {
//       // Optionally, show some feedback to the user that the message cannot be empty.
//       return; // Exit the function if the message is empty or whitespace.
//     }
//     setIsEditing(false);
//     e.preventDefault();
//     const payload = {
//       role: roles[0],
//       discussionId: commentsDiscussionId,
//       comments: commentMessage.trim()
//     };

//     try {
//       const response = await axios.post(`${BASE_URL}${DISCUSSION_COMMENTS}`, payload, {
//         headers: {
//           Authorization: `Bearer ${token}`
//         }
//       });
//       if (response.status == 200) {
//         // showSendIcon(false);
//         setCommentMessage('');
//         setShowSendIcon(false);

//         getDisussionDetailsByIdComments();
//         getDisussionDetailsById();
//       }
//       // console.log('Message added successfully:', response.data);
//       // Optionally, you can handle any success actions here
//     } catch (error) {
//       console.error('Error adding message:', error);
//       // Optionally, you can handle error scenarios here
//     }
//   };

//   const handleEditMessageChange = (event) => {
//     setEditCommentsMessage(event.target.value);

//   };
//   const handleEditClick = (item, index) => {
//     // Set the comment message state to the edited comment
//     // setCommentMessage(item.comments);
//     console.log("Clicked item:", item);
//     handleClose(index);
//     setIsEditing(true)
//     // setAnchorEl(e.currentTarget)
//     setEditCommentsMessage(item.comments)

//     console.log("editedComments",);
//     setDiscussionIdEdit(item.discussionCommentsId)
//     // Close the menu

//   };
//   const handleDeleteClick = async (item, index) => {
//     // Set the comment message state to the edited comment
//     // setCommentMessage(item.comments);
//     console.log("Clicked item:", item);
//     handleClose(index);

//     // setIsEditing(true)
//     // setAnchorEl(e.currentTarget)
//     // setEditCommentsMessage(item.comments)
//     // setOpen(true)
//     console.log("editedComments",);
//     // setDiscussionIdDelete(item.discussionCommentsId)
//     // Close the menu
//     // handleClose();
//     // e.preventDefault();
//     const payload = {
//       role: roles[0],
//       // discussionId: commentsDiscussionId,
//       // comments: commentMessage
//     };
//     console.log("token", token);

//     try {
//       const response = await axios.delete(`${BASE_URL}${DISCUSSION_COMMENTS}/${item.discussionCommentsId}`, {

//         headers: {
//           Authorization: `Bearer ${token}`
//         }
//       });
//       if (response.status == 200) {
//         // showSendIcon(false);
//         setCommentMessage('');
//         setEditCommentsMessage('');
//         setShowSendIcon(false);
//         setIsEditing(false)
//         getDisussionDetails()
//         getDisussionDetailsByIdComments();
//         getDisussionDetailsById()
//       }
//       // console.log('Message added successfully:', response.data);
//       // Optionally, you can handle any success actions here
//     } catch (error) {
//       console.error('Error adding message:', error);
//       // Optionally, you can handle error scenarios here
//     }
//   };
//   // const getDisussionDetailsByIdCommentsReply = async () => {
//   //   try {
//   //     const storedToken = token;
//   // const userLogin=userLoginDetails.homeOwnerLoginId
//   // setUserLoginId(userLogin);
//   // console.log("UserDetailsOf",userLoginDetails.homeOwnerLoginId);
//   //     console.log(storedToken);

//   //     const response = await axios.get(`${BASE_URL}${DISCUSSION_COMMENTS}/${discussionIdEdit}`, {
//   //       headers: {
//   //         Authorization: `Bearer ${storedToken}`,
//   //       },
//   //     });
//   //     console.log(response);

//   //     if (response.status === 200) {
//   //       const data = response.data;
//   //       // setTemplateDetails(data);
//   //       // setTopic(data.topic)
//   //       setReplies(data)
//   //       setIsLoading(false);
//   //     } else {
//   //       throw new Error("Failed to fetch data");
//   //     }
//   //   } catch (error) {
//   //     // Handle error
//   //     console.error("Error fetching data:", error);
//   //   }
//   // };
//   // useEffect(()=>{
//   //   getDisussionDetailsByIdCommentsReply()
//   // },[discussionIdEdit])
//   const handleEditMessage = async (e) => {
//     const trimmedMessage = editCommentsMessage.trim();
//     if (!trimmedMessage) {
//       // Optionally, show some feedback to the user that the message cannot be empty.
//       return; // Exit the function if the message is empty or whitespace.
//     }
//     e.preventDefault()
//     const payload = {
//       role: roles[0],
//       discussionId: commentsDiscussionId,
//       comments: editCommentsMessage.trim()
//     };

//     try {
//       const response = await axios.put(`${BASE_URL}${DISCUSSION_COMMENTS}/${discussionIdEdit}`, payload, {
//         headers: {
//           Authorization: `Bearer ${token}`
//         }
//       });
//       if (response.status == 200) {
//         // showSendIcon(false);
//         getDisussionDetailsByIdComments();
//         getDisussionDetailsById()
//         setShowSendIcon(false);
//         setIsEditing(false)
//         setEditCommentsMessage('');
//         commentMessage('');

//         // reset();
//       }
//       // console.log('Message added successfully:', response.data);
//       // Optionally, you can handle any success actions here
//     } catch (error) {
//       if (error.response && error.response.data) {
//         const message = error.response.data.message;
//         toast.error(message, { position: "top-center" })
//         getDisussionDetailsByIdComments();
//         getDisussionDetailsById()

//         setEditCommentsMessage('');
//         reset();
//         console.log("error.response.status" + error.response.status);
//       } else {
//         console.error(error); // Log the entire error for further debugging
//         // toast.error("An error occurred while processing your request.");
//       }    // Optionally, you can handle error scenarios here
//     }
//   };
//   const editActionButton = (
//     <CustomButton
//       fieldType="submit"
//       buttonName="Update"
//       click={handleEditClose}
//     />
//   );

//   const editModel = (
//     <>
//       <form
//         onSubmit={handleSubmitModel(submitEdit)}
//         className="space-y-4 md:space-y-6 "
//       >
//         <DialogContent dividers>
//           <Typography gutterBottom>
//             <CustomInputField
//               fieldName="topic"
//               //   requiredErrorMessage={TOPIC_REQUIRED_MESSAGE}
//               // fieldPattern={ONLY_ALPHABETIC_WITH_WHITE_SPACE}
//               // patternErrorMessage={ALPHABETS_ARE_ALLOWED}
//               fieldId="topic"
//               minLength={2}
//               minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
//               maxLength={250}
//               maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
//               fieldLabel="Topic"
//               fieldControl={controlModal}
//               fieldError={errorsModal}
//               fieldRequiredIcon="*"
//               multiline={true}
//               numberOfRows={2}
//               value={topic}
//               onInput={handleTopicChange}
//             />

//             <CustomInputField
//               fieldName="description"
//               //   requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
//               fieldId="description"
//               minLength={2}
//               minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
//               maxLength={1000}
//               maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
//               fieldLabel="Description"
//               fieldControl={controlModal}
//               fieldError={errorsModal}
//               fieldRequiredIcon="*"
//               multiline={true}
//               numberOfRows={2}
//               value={description}
//               onInput={handleDescriptionChange}
//             />


//           </Typography>
//         </DialogContent>
//         <DialogActions>{editActionButton}</DialogActions>
//       </form>
//     </>
//   );

//   const okCancelButtonActions = (
//     <>

//       <CustomButton
//         fieldType="button"
//         buttonName="No"
//         click={handleDeleteClose}
//       />
//       <CustomButton
//         fieldType="submit"
//         buttonName="Yes"
//         click={handleDeleteClose}
//       />
//     </>
//   );

//   const deleteModel = (
//     <form
//       onSubmit={handleSubmit(onSubmitDelete)}
//       className="space-y-4 md:space-y-6 "
//     >
//       <DialogContent dividers>
//         <Typography>
//           {" "}
//           Are you sure  to delete this topic ?
//         </Typography>
//       </DialogContent>
//       <DialogActions>{okCancelButtonActions}</DialogActions>
//     </form>
//   );
//   const [expanded, setExpanded] = useState(false);
//   const [expandeds, setExpandeds] = useState(false)
//   const handleExpandClick = () => {
//     setExpanded(!expanded);
//   };
//   const handleExpandClicks = () => {
//     setExpandeds(!expandeds);
//   };
//   const [anchorElArray, setAnchorElArray] = useState(Array(replies.length).fill(null));

//   const handleMenuClick = (event, index) => {

//     const newAnchorElArray = [...anchorElArray];
//     newAnchorElArray[index] = event.currentTarget;
//     setAnchorElArray(newAnchorElArray);
//   };

//   const handleClose = (index) => {
//     const newAnchorElArray = [...anchorElArray];
//     newAnchorElArray[index] = null;
//     setAnchorElArray(newAnchorElArray);
//   };
 
//   const [searchTerm, setSearchTerm] = useState('');
//   return (

//     <div class="py-7" style={{ height: "", overflow: "", marginTop: "" }} >
//       <div class="">
//         <CustomHeading title="Discussion Board" style={{marginTop:"5px"}}/>
//         <CustomToastContainer />
//         <div class="flex min-h-screen w-full bg-primary-bg  justifyContent-center  " >
//         {showStartPanel && (
//           //  <div className="start-panel" style={{backgroundColor:""}}>
//         <div class={`max-w-[60%] max-h-[250px] flex flex-col  ${window.innerWidth <= 600 ? 'w-full mx-auto ' : ''}`}>
//         {/* // <div class={`max-w-[60%] max-h-[200px] flex flex-col min-h-[700px] md:min-h-[600px] lg:min-h-[500px] xl:min-h-[500px] xs:min-h-[600px] ${window.innerWidth <= 600 ? 'w-full mx-auto' : ''}`}> */}
// <div class={`border-r border-r-slate-400 ${window.innerWidth <= 600 ? 'h-full ml-[-5px]' : ''}`}>
//             <div class="relative flex items-center px-1">
//               {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="absolute ml-2 h-5 w-5 text-slate-400">
//         <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
//       </svg> */}
//               <input type="text" placeholder="Search" class="w-full rounded-md border border-gray-300  py-2 pl-9 pr-3 text-slate-900 focus:outline-none" onChange={(e) => setSearchTerm(e.target.value)} />
//             </div>

//             {/* <div class="mt-1 flex gap-2 overflow-hidden">
//      </div> */}
//             {/* <div class="mt-1 flex items-center"  style={{ backgroundColor: "red" }}> */}
//             <div class=" flex  justify-between px-2 py-2" >
//               <div>
//                 <p class="font-small mr-auto">Add Topic</p> {/* "mr-auto" pushes this to the left */}
//               </div>
//               <div class="pr-2">

//                 <AddIcon style={{ fontSize: 20, color: 'gray' }} type="submit" onClick={handleAddClickOpen} />

//               </div>
//             </div>
//             {/* </div> */}
//             <Divider style={{}} />
//             {/* <!meesages start/> */}
//             <div className="mt-1 flex flex-col gap-7 overflow-y-auto" style={{ maxHeight: 'calc(3 * (156px + 2 * 2px) + 2 * 2px)' }}>
//               <div className="px-3 py-2 justify-center items-center">
//                 {discussionData
//                   .filter(item => item.topic.toLowerCase().includes(searchTerm.toLowerCase()))
//                   .length > 0 ? (
//                   discussionData
//                     .filter(item => item.topic.toLowerCase().includes(searchTerm.toLowerCase()))
//                     .map((item, index) => (
//                       <div
//                         key={item.discussionId}
//                         className={`flex items-center gap-2 rounded-md px-2 py-1 transition-colors duration-300 ${selectedTopic === item.topic ? '' : 'hover:bg-light'
//                           }`}
//                         style={{ backgroundColor: selectedTopic === item.topic ? '#045F9F' : undefined }}
//                         onClick={() => handleTopicClick(item.topic, item.description, item.discussionId, item.createdDateAndTime, item.createdBy)}
//                       >
//                         <div className="w-full overflow-hidden text-left flex-grow">
//                           <div className="flex justify-between">
//                             <p className={`flex truncate text-sm font-medium ${selectedTopic === item.topic ? 'text-white' : 'text-black'
//                               }`}>
//                               {item.topic.length > 30 ? `${item.topic.substring(0, 20)}...` : item.topic}
//                             </p>
//                             {/* <p className="{`truncate text-sm font-medium ${
//     selectedTopic === item.topic ? 'text-white' : 'text-black'
//   }`}">
//                   </p> */}
//                           </div>
//                           <p className="flex truncate text-sm text-slate-400">
//                             {item.description.length > 15 ? `${item.description.substring(0, 15)}...` : item.description}
//                             {userLoginId === item.createdBy && (
//                               <span className="ml-auto flex gap-1">
//                                 <EditTwoTone className={`flex truncate text-sm font-medium ${selectedTopic === item.topic ? 'text-white' : 'text-grey'
//                                   }`} onClick={(e) => {
//                                     e.stopPropagation();
//                                     handleEdit(item.discussionId);
//                                   }} />
//                                 <DeleteTwoTone className={`flex truncate text-sm font-medium ${selectedTopic === item.topic ? 'text-white' : 'text-grey'
//                                   }`} onClick={(e) => {
//                                     e.stopPropagation();
//                                     handleDelete(item.discussionId);
//                                   }} />
//                               </span>
//                             )}
//                           </p>
//                           <p className={`flex truncate text-sm font-medium ${selectedTopic === item.topic ? 'text-white' : 'text-grey'
//                             }`}>                    {moment(item.createdDateAndTime).format('MM/DD/YYYY HH:mm A')}
//                           </p>
//                           <Divider className="mt-1 px-2 py-2" />
//                         </div>
//                       </div>
//                     ))
//                 ) : (
//                   <p>No Data Found</p>
//                 )
//                 }
//               </div>
//             </div>

//             {/* <!meesages End/> */}
//           </div>
//            </div>
//         )}
//           {/* <!Middle start/> */}
//           {showMidPanel && (
//             //  <div className="mid-panel" style={{backgroundColor:""}}>
//           <div class="w-full max-h-fullscreen flex flex-col mt-none">
//             {/* <div style={{ height: 'calc(80vh - 100px)' }} class="overflow-y-auto mb-2"> */}
//             <div class="flex flex-col overscroll-contain flex-1 overflow-y-scroll overflow-x-hidden px-2  min-h-[700px] md:min-h-[500px] lg:min-h-[500px] xl:min-h-[500px] xs:min-h-[500px]" >
//             {discussionData.length > 0 && (
//               <div class="flex  items-center gap-2 px-1 py-1  ">
//                 {/* <div class="h-[42px] w-[42px] shrink-0 rounded-full">
//         <img src="https://picsum.photos/750/740" class="h-full w-full rounded-full object-cover" alt="" />
//       </div> */}
//      {showArrow && ( 
//       <div >
//       <ArrowBackIcon onClick={handleBackClick}/>
//       </div>
// )}
//                 <div class="ml-2">
//                   {/* <h2 class="text-base text-blue-300">{selectedTopic}</h2>

//       <h2 class="text-base text-black-200">{selectedDescription}</h2> */}
//                   <h6 class="text-sm font-bold">Topic :</h6>
//                   <h2 className="text-base text-blue-300">
//                     {selectedTopic?.length > 30 ? selectedTopic.substring(0, 30) : selectedTopic}
//                     {selectedTopic?.length > 30 && (
//                       <IconButton onClick={handleExpandClick}>
//                         <ExpandMoreIcon />
//                       </IconButton>
//                     )}
//                   </h2>
//                   <Collapse in={expanded} timeout="auto" unmountOnExit>
//                     <h2 className="text-base text-blue-300 break-all">
//                       {selectedTopic?.length > 30 && selectedTopic}
//                     </h2>
//                   </Collapse>
//                   <h6 class="text-sm font-bold">Description :</h6>

//                   <h4 className="text-base text-black-200 break-all">
//                     {selectedDescription?.length > 60 ? selectedDescription.substring(0, 30) : selectedDescription}
//                     {selectedDescription?.length > 60 && (
//                       <IconButton onClick={handleExpandClicks}>
//                         <ExpandMoreIcon />
//                       </IconButton>
//                     )}
//                   </h4>
//                   <Collapse in={expandeds} timeout="auto" unmountOnExit>
//                     <h5 className="text-base text-black-200 break-all">
//                       {selectedDescription?.length > 30 && selectedDescription}
//                     </h5>
//                   </Collapse>

//                   <p class="text-xs text-slate-400">{formattedDate}</p>
//                 </div>

//               </div>
//             )}
//             <div class="border-b border-b-slate-700"></div>
//             {/* <div class="flex flex-col gap-2 px-2"> */}

//               <div  class=" mb-1 " >
//                 <div class=" mt-1">
//                   {replies.map((item, index) => (
//                     <div key={index} className={` flex ml-${userLoginId === item.createdBy ? 'auto' : '0'} ${userLoginId === item.createdBy ? 'text-left' : 'text-left'}  ${userLoginId === item.createdBy ? 'flex-row' : 'flex-row'}`}>
//                       {/* {userLoginId === item.createdDateAndTime ? 'text-right':'text-left'} */}

//                       {/* {userLoginId === item.createdBy && <span class="text-blue-300 text-base">@you</span>} */}
//                       {/* {userLoginId !== item.createdBy && <span class="text-blue-300 text-base">@XYZ</span>} */}
//                       <div className={`flex flex-col  ${userLoginId === item.createdBy ? 'justify-end' : 'justify-start'}`}>
//                         <div class="mr-2 ml-2 mb-2 mt-2 ">
//                           {userLoginId !== item.createdBy ? (
//                             <div class="flex flex-wrap">
                              
//                               <div className={`px-1 py-1 h-[30px] w-[30px] rounded-full flex items-center justify-center`} style={{ backgroundColor: "#045F9F" }}>
//                                 <span className="text-white font-bold text-xs">{item.createdByDetails.firstName.charAt(0)}</span>
//                               </div>
//                               <div class="">
//                                 <span className="ml-2  text-white font-bold text-xs" style={{ color: "#045F9F" }}>{item.createdByDetails.firstName}</span>
//                               </div>
//                               <div class="ml-2">
                                
//                                 <span className={` text-xs ${userLoginId === item.createdBy ? 'text-right' : 'text-left'}`} style={{ color: userLoginId === item.createdBy ? 'grey' : 'grey' }}>
//                                   {moment(item.createdDateAndTime).format('MM-DD-YYYY HH:mm A')}
//                                 </span>
//                                 <span class="mr-1">
//                                   {item.isEdited ? <span className='text-xs' style={{ color: 'blue', marginLeft: '5px' }}>Edited</span> : null}

//                                 </span>

//                               </div>
//                             </div>
//                           ) :
//                             (
//                               <div class="flex flex-wrap">

                                 
                                   
//                               <div className={`px-1 py-1 h-[30px] w-[30px] rounded-full flex items-center justify-center`} style={{ backgroundColor: "#045F9F" }}>
//                                 <span className="text-white font-bold text-xs">{item.createdByDetails.firstName.charAt(0)}</span>
//                               </div>
//                                 <span className=" ml-2 text-white font-bold text-xs" style={{ color: "#045F9F" }}>{item.createdByDetails.firstName}</span>

//                                 <span className=" ml-2 text-xs text-right" style={{ color: userLoginId === item.createdBy ? 'grey' : 'grey' }}>
//                                   {moment(item.createdDateAndTime).format('MM-DD-YYYY HH:mm A')}
//                                 </span>

//                                   {item.isEdited ? <span className='text-xs' style={{ color: 'blue', marginLeft: '5px' }}>Edited</span> : null}

                               
//                               </div>

//                             )}
//                         </div>
//                         <div className={`flex  w-full  p-2  ${userLoginId === item.createdBy ? 'justify-start' : 'justify-start'} items-center`} 
//                         style={{  wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>

//                         <div className="flex flex-wrap w-full">
//           <span
//              className="px-2 py-2 ml-1 mr-1  rounded-md text-center text-xs sm:text-sm md:text-lg"
//              style={{
//           color: 'black', 
//           backgroundColor: isHovering === index ? 'grey' : (userLoginId === item.createdBy ? '' : '')
//         }}
//         onMouseEnter={() => setIsHovering(index)}
//         onMouseLeave={() => setIsHovering(null)}
//       >
//         {item.comments}
//       </span>
//     </div>

//  {userLoginId === item.createdBy && (
//                             <IconButton aria-label="more" aria-controls={`menu-${index}`} aria-haspopup="true" onClick={(event) => handleMenuClick(event, index)}>
//                               <MoreVertIcon />
//                             </IconButton>

//                           )}
//                         </div>

//                       </div>

//                       <Menu
//                         id={`menu-${index}`}
//                         anchorEl={anchorElArray[index]}
//                         open={Boolean(anchorElArray[index])}
//                         onClose={() => handleClose(index)}
//                       >
//                         <MenuItem onClick={() => handleEditClick(item, index)}>Edit</MenuItem>
//                         <MenuItem onClick={() => handleDeleteClick(item, index)}>Delete</MenuItem>
//                       </Menu>
//                       {/* {userLoginId === item.createdBy && (
//   <span style={{ color: userLoginId === item.createdBy ? 'green' : 'red' }}>
//     {moment(item.createdDateAndTime).format('YYYY-MM-DD HH:mm:ss')}
//   </span>
// )} */}




//                     </div>
//                   ))}
//                 </div>

//               </div>
//               </div>
//               {/* flex fixed bottom-0  m-2 "style={{ width: 'calc(60% - 50px)' }} */}
//               {discussionData.length > 0 && (
//   <div className=" flex  flex-1">
//       <div className="mt-7  ml-2 mr-2 w-full">
//       <TextField
//   id="outlined-multiline-flexible"
//   label="Message"
//   multiline
//   maxRows={2}
//   value={isEditing ? editCommentsMessage : commentMessage}
//   onChange={isEditing ? handleEditMessageChange : handleMessageChange}
//   onKeyDown={handleKeyDown}
//   variant="outlined"
//   fullWidth
// />
//       </div>
//       <div className="mt-7">
//         <SendIcon
//           style={{ fontSize: 45, color: '#045F9F', cursor: isEditing || commentMessage ? 'pointer' : 'not-allowed' }}
//           onClick={isEditing ? handleEditMessage : commentMessage ? handleAddMessage : null}
//         />
//       </div>
//   </div>
// )}

//             {/* </div> */}

//           </div>
//           // </div>
//           )}
//           {/* <!middle end/> */}
//         </div>

//         <CustomModel
//           title="Add"
//           submit={onSubmit}
//           content={addModel}
//           action={addActionButton}
//           openStatus={open}
//           closeStatus={handleAddClose}
//           iconCloseStatus={handleIconClose}
//           reset={reset}
//         />
//         <CustomModel
//           title="Edit"
//           submit={submitEdit}
//           content={editModel}
//           action={editActionButton}
//           openStatus={editOpen}
//           closeStatus={handleEditClose}
//           iconCloseStatus={handleEditIconClose}
//           reset={resetModal}
//         />
//         <CustomModel
//           title=" Delete Discussion Board"
//           submit={onSubmitDelete}
//           content={deleteModel}
//           action={okCancelButtonActions}
//           openStatus={deleteOpen}
//           closeStatus={handleDeleteClose}
//           iconCloseStatus={handleDeleteClose}
//           reset={reset}
//           modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
//         />
//         {isLoading && <CustomLoading />}
//       </div>

//     </div>

//   );
// };
  



// export default Discussionboard
// // import { DialogActions, DialogContent, Divider, Menu, MenuItem, TextField, Typography } from '@mui/material';
// // import { Box } from '@mui/system';
// // import React, { useEffect, useState } from 'react'
// // import SendIcon from '@mui/icons-material/Send';
// // import AddIcon from '@mui/icons-material/Add';
// // import CustomModel from '../../components/CustomModel';
// // import CustomInputField from '../../components/CustomInputField';
// // import CustomButton from '../../components/CustomButton';
// // import { ALPHABETS_ARE_ALLOWED, DESCRIPTION_REQUIRED_MESSAGE, MAX_LENGTH_HUNDRED, MAX_LENGTH_THOUSAND, MAX_LENGTH_TWO_FIFTY, MIN_LENGTH_VALIDATE_MESSAGE, TOPIC_REQUIRED_MESSAGE } from '../../utills/ApplicationConstants';
// // import { useSelector } from 'react-redux';
// // import CustomLoading from '../../components/CustomLoading';
// // import { ALL_DISCUSSION, BASE_URL, DISCUSSION, DISCUSSION_COMMENTS } from '../../utills/ApplicationRouting';
// // import { request } from '../../services/AxiosConfig';
// // import { set, useForm } from 'react-hook-form';
// // import { ONLY_ALPHABETIC_WITH_WHITE_SPACE } from '../../utills/ApplicationRegex';
// // import CustomToastContainer from '../../components/CustomToastContainer';
// // import axios from 'axios';
// // import { ToastContainer, toast } from 'react-toastify';

// // import { DeleteTwoTone, EditTwoTone } from '@mui/icons-material';
// // import MoreVertIcon from '@mui/icons-material/MoreVert';
// // import moment from 'moment';
// // import { IconButton, Collapse } from '@mui/material';
// // import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// // import CustomHeading from '../../components/CustomHeading';
// // function Discussionboard() {
// //   const { token, roles, userLoginDetails } = useSelector((state) => state.hybridhoa);
// //   const [isLoading, setIsLoading] = useState(false)
// //   const [topic, setTopic] = useState('')
// //   const [description, setDescription] = useState('')
// //   const [time, setTime] = useState('')
// //   const [discussionId, setDiscussionId] = useState('')
// //   const [discussionData, setDiscussionData] = useState([])
// //   const [discussionIdEdit, setDiscussionIdEdit] = useState('')
// //   const [editCommentsMessage, setEditCommentsMessage] = useState('')
// //   const [isEditing, setIsEditing] = useState(false);
// //   const [selectedDiscussionId, setSelectedDiscussionId] = useState(null);
// //   const [selectedDiscussionDeleteId, setSelectedDiscussionDeleteId] = useState(null);
// //   const [selectedTopic, setSelectedTopic] = useState(null);
// //   const [isHovering, setIsHovering] = useState(null)
// //   const [selectedDescription, setSelectedDescription] = useState(null);
// //   const [commentsDiscussionId, setCommentsDiscussionId] = useState(null)
// //   const [createdDateAndTimes, setCreatedDateAndTimes] = useState(null)
// //   const [commentMessage, setCommentMessage] = useState("");
// //   const [showSendIcon, setShowSendIcon] = useState(false);
// //   const [discussionIdDelete, setDiscussionIdDelete] = useState(null);
// //   const {
// //     handleSubmit,
// //     control,
// //     reset,
// //     setError,
// //     formState: { errors },
// //   } = useForm();
// //   // const {
// //   //   handleSubmit:handleSubmitModel,
// //   //   control: controlModal,
// //   //   formState: { errors: errorsModal },
// //   //   setValue: setValueModal,
// //   //   setError: setErrorModal,
// //   //   reset: resetModal,
// //   //   watch: watchModal,
// //   // } = useForm();
// //   const {
// //     handleSubmit: handleSubmitModel,
// //     control: controlModal,
// //     formState: { errors: errorsModal },
// //     setValue: setValueModal,
// //     setError: setErrorModal,
// //     reset: resetModal,
// //   } = useForm();
// //   const [open, setOpen] = useState(false)
// //   const [editOpen, setEditOpen] = useState(false);
// //   const [deleteOpen, setDeleteOpen] = useState(false);
// //   const handleAddClickOpen = () => {
// //     setOpen(true);
// //   };
// //   const handleAddClose = (status) => {
// //     if (status === 200) {
// //       setOpen(false);
// //     }
// //   };
// //   const handleIconClose = () => {
// //     setOpen(false);
// //     // reset();
// //   };
// //   const handleEditClose = (status) => {
// //     if (status === 200) {
// //       setEditOpen(false);
// //     }
// //   };
// //   const handleEditIconClose = () => {
// //     setEditOpen(false);
// //     resetModal();
// //   };
// //   useEffect(() => {
// //     // Add the 'no-scroll' class to the body when the component mounts
// //     document.body.classList.add('no-scroll');

// //     // Remove the 'no-scroll' class from the body when the component unmounts
// //     return () => {
// //       document.body.classList.remove('no-scroll');
// //     };
// //   }, []);
// //   const getDisussionDetails = async () => {
// //     setIsLoading(true)
// //     try {
// //       const storedToken = token;

// //       console.log(storedToken);

// //       const response = await axios.get(`${BASE_URL}${ALL_DISCUSSION}${roles}`, {
// //         headers: {
// //           Authorization: `Bearer ${storedToken}`,
// //         },
// //       });
// //       console.log(response);

// //       if (response.status === 200) {
// //         const data = response.data;
// //         // setTemplateDetails(data);
// //         setIsLoading(false)
// //         setDiscussionData(data)
// //         setIsLoading(false);
// //       } else {
// //         throw new Error("Failed to fetch data");
// //       }
// //     } catch (error) {
// //       // Handle error
// //       console.error("Error fetching data:", error);
// //     }
// //   };

// //   const handleTopicChange = (event) => {
// //     setTopic(event.target.value)
// //   }
// //   const handleDescriptionChange = (event) => {
// //     setDescription(event.target.value)
// //   }

// //   const handleDeleteClose = () => {
// //     setDeleteOpen(false);
// //   };
// //   useEffect(() => {
// //     getDisussionDetails();
// //   }, []);
// //   const onSubmit = (data) => {
// //     setIsLoading(true);
// //     const headers = {
// //       Authorization: `Bearer ${token}`,
// //     };
// //     const topic = data.topic ? data.topic.trim() : '';
// //     const description = data.description ? data.description.trim() : '';
// //     const postData = {
// //       topic: topic,
// //       description: description,
// //       role: roles[0],
// //     };

// //     if (!postData.topic) {
// //       setError("topic", {
// //         type: "manual",
// //         message: TOPIC_REQUIRED_MESSAGE,
// //       });
// //       setIsLoading(false);
// //       return;
// //     }
// //     if (!postData.description) {
// //       setError("description", {
// //         type: "manual",
// //         message: DESCRIPTION_REQUIRED_MESSAGE,
// //       });
// //       setIsLoading(false);
// //       return;
// //     }
// //     request({ url: DISCUSSION, method: "post", data: postData, headers })
// //       .then((res) => {
// //         console.log(res);
// //         if (res.status == 200) {
// //           console.log("Success");
// //           getDisussionDetails();
// //           setIsLoading(false);
// //           handleAddClose(res.status);
// //           reset();
// //         }
// //       })
// //       .catch((error) => {
// //         console.log(error.response);
// //         if (error.response) {
// //           setIsLoading(false);
// //           console.log("error.response.status" + error.response.status);
// //         }
// //       });
// //   };

// //   const submitEdit = () => {
// //     setIsLoading(true);
// //     const trimmedTopic = topic ? topic.trim() : '';
// //     const trimmedDescription = description ? description.trim() : '';

// //     const putData = {

// //       topic: trimmedTopic.trim(),
// //       description: trimmedDescription.trim(),
// //       role: roles[0]
// //     };

// //     if (!trimmedTopic) {
// //       setErrorModal("topic", {
// //         type: "manual",
// //         message: TOPIC_REQUIRED_MESSAGE,
// //       });
// //       setIsLoading(false);
// //       return;
// //     }
// //     if (!trimmedDescription) {
// //       setErrorModal("description", {
// //         type: "manual",
// //         message: DESCRIPTION_REQUIRED_MESSAGE,
// //       });
// //       setIsLoading(false);
// //       return;
// //     }


// //     request({ url: `${DISCUSSION}/${selectedDiscussionId}`, method: "put", data: putData })
// //       .then((res) => {
// //         setIsLoading(false);
// //         console.log(res);
// //         if (res.status == 200) {
// //           setIsLoading(false);
// //           //   getTemplateDetails();
// //           getDisussionDetails();
// //           getDisussionDetailsById()
// //           handleEditClose(res.status);
// //           reset();
// //         }
// //       })
// //       .catch((error) => {
// //         console.log(error.response);
// //         setIsLoading(false);
// //         // if (error.response) {
// //         //   console.log("error.response.status" + error.response.status);
// //         // }
// //         if (error.response && error.response.data) {
// //           const message = error.response.data.message;
// //           toast.error(message, { position: "top-center" });
// //           console.log("error.response.status" + error.response.status);
// //         } else {
// //           console.error(error); // Log the entire error for further debugging
// //           toast.error("An error occurred while processing your request.");
// //         }
// //       });
// //   };
// //   const onSubmitDelete = () => {
// //     // return;
// //     request({ url: `${DISCUSSION}/${selectedDiscussionDeleteId}`, method: "delete" })
// //       .then((res) => {
// //         console.log(res);
// //         if (res.status === 200) {
// //           setEditCommentsMessage('')
// //           // getTemplateDetails();
// //           getDisussionDetails();

// //           getDisussionDetailsById();
// //           getDisussionDetailsByIdComments();
// //         }
// //       })
// //       .catch((error) => {
// //         console.log(error);
// //       });
// //   };


// //   // State to store the ID of the selected discussion

// //   // Function to handle opening the modal and setting the selected discussion ID
// //   const handleEdit = (discussionId) => {
// //     console.log("discussionId", discussionId);
// //     setEditOpen(true)
// //     setSelectedDiscussionId(discussionId);

// //     // Call a function to fetch details of the selected discussion using its ID

// //     // Open your edit modal here
// //   }
// //   const handleDelete = (discussionId) => {
// //     console.log("discussionId", discussionId);
// //     setDeleteOpen(true)
// //     setSelectedDiscussionDeleteId(discussionId);

// //     // Call a function to fetch details of the selected discussion using its ID

// //     // Open your edit modal here
// //   }
// //   const addActionButton = (
// //     <CustomButton fieldType="submit" buttonName="Add" click={handleAddClose}>
// //     </CustomButton>
// //   );
// //   const addModel = (
// //     <>
// //       <form
// //         onSubmit={handleSubmit(onSubmit)}
// //         className="space-y-4 md:space-y-6 "
// //       >
// //         <DialogContent dividers>
// //           <Typography gutterBottom>
// //             <CustomInputField
// //               fieldName="topic"
// //               // requiredErrorMessage={TOPIC_REQUIRED_MESSAGE}
// //               // fieldPattern={ONLY_ALPHABETIC_WITH_WHITE_SPACE}
// //               // patternErrorMessage={ALPHABETS_ARE_ALLOWED}
// //               fieldId="topic"
// //               minLength={2}
// //               minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
// //               maxLength={250}
// //               maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
// //               fieldLabel="Topic"
// //               fieldControl={control}
// //               fieldError={errors}
// //               fieldRequiredIcon="*"
// //               multiline={true}
// //               numberOfRows={2}
// //             />

// //             <CustomInputField
// //               fieldName="description"
// //               // requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
// //               fieldId="description"
// //               minLength={2}
// //               minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
// //               maxLength={1000}
// //               maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
// //               fieldLabel="Description"
// //               fieldControl={control}
// //               fieldError={errors}
// //               fieldRequiredIcon="*"
// //               multiline={true}
// //               numberOfRows={2}
// //             />


// //           </Typography>
// //         </DialogContent>
// //         <DialogActions>{addActionButton}</DialogActions>
// //       </form>
// //     </>
// //   );
// //   const getDisussionDetailsById = async () => {
// //     try {
// //       const storedToken = token;

// //       console.log(storedToken);

// //       const response = await axios.get(`${BASE_URL}${DISCUSSION}/${selectedDiscussionId}`, {
// //         headers: {
// //           Authorization: `Bearer ${storedToken}`,
// //         },
// //       });
// //       console.log(response);

// //       if (response.status === 200) {
// //         const data = response.data;
// //         // setTemplateDetails(data);
// //         setTopic(data.topic)
// //         setDescription(data.description);
// //         setIsLoading(false);
// //       } else {
// //         throw new Error("Failed to fetch data");
// //       }
// //     } catch (error) {
// //       // Handle error
// //       console.error("Error fetching data:", error);
// //     }
// //   };
// //   useEffect(() => {
// //     getDisussionDetailsById();
// //   }, [selectedDiscussionId, selectedDiscussionDeleteId]);

// //   // const [createdBy,setCreatedBy]=useState('')
// //   const [userLoginId, setUserLoginId] = useState(null)
// //   const handleMessageChange = (event) => {
// //     setCommentMessage(event.target.value)
// //     setIsEditing(false);
// //     setShowSendIcon(event.target.value.trim().length > 0);
// //   }

// //   const handleKeyDown = (event) => {
// //     if (event.key === 'Enter') {
// //       isEditing ? handleEditMessage(event) : handleAddMessage(event);
// //     }

// //   };
// //   useEffect(() => {
// //     // Automatically select the first topic when the component mounts
// //     if (discussionData.length > 0) {
// //       const initialTopic = discussionData[0];
// //       handleTopicClick(initialTopic.topic, initialTopic.description, initialTopic.discussionId, initialTopic.createdDateAndTime);
// //     }
// //   }, [discussionData]);
// //   const handleTopicClick = (topic, description, discussionId, createdDateAndTime, createdBy) => {
// //     setSelectedTopic(topic);
// //     setSelectedDescription(description);
// //     setCommentsDiscussionId(discussionId);
// //     setCreatedDateAndTimes(createdDateAndTime);
// //     setIsEditing(false);
// //     // setCreatedBy(createdBy.trim())

// //   };
// //   const formattedDate = createdDateAndTimes ? moment(createdDateAndTimes).format('MM/DD/YYYY HH:mm A') : ""; const [replies, setReplies] = useState([])
// //   const getDisussionDetailsByIdComments = async () => {
// //     try {
// //       const storedToken = token;
// //       const userLogin = userLoginDetails.homeOwnerLoginId
// //       setUserLoginId(userLogin);
// //       console.log("UserDetailsOf", userLoginDetails.homeOwnerLoginId);
// //       console.log(storedToken);

// //       const response = await axios.get(`${BASE_URL}${DISCUSSION_COMMENTS}/${commentsDiscussionId}`, {
// //         headers: {
// //           Authorization: `Bearer ${storedToken}`,
// //         },
// //       });
// //       console.log(response);

// //       if (response.status === 200) {
// //         const data = response.data;
// //         // setTemplateDetails(data);
// //         // setTopic(data.topic)
// //         setReplies(data)
// //         setIsLoading(false);
// //       } else {
// //         throw new Error("Failed to fetch data");
// //       }
// //     } catch (error) {
// //       // Handle error
// //       console.error("Error fetching data:", error);
// //     }
// //   };
// //   useEffect(() => {
// //     getDisussionDetailsByIdComments()
// //   }, [commentsDiscussionId])
// //   const handleAddMessage = async (e) => {
// //     const trimmedMessage = commentMessage.trim();
// //     if (!trimmedMessage) {
// //       // Optionally, show some feedback to the user that the message cannot be empty.
// //       return; // Exit the function if the message is empty or whitespace.
// //     }
// //     setIsEditing(false);
// //     e.preventDefault();
// //     const payload = {
// //       role: roles[0],
// //       discussionId: commentsDiscussionId,
// //       comments: commentMessage.trim()
// //     };

// //     try {
// //       const response = await axios.post(`${BASE_URL}${DISCUSSION_COMMENTS}`, payload, {
// //         headers: {
// //           Authorization: `Bearer ${token}`
// //         }
// //       });
// //       if (response.status == 200) {
// //         // showSendIcon(false);
// //         setCommentMessage('');
// //         setShowSendIcon(false);

// //         getDisussionDetailsByIdComments();
// //         getDisussionDetailsById();
// //       }
// //       // console.log('Message added successfully:', response.data);
// //       // Optionally, you can handle any success actions here
// //     } catch (error) {
// //       console.error('Error adding message:', error);
// //       // Optionally, you can handle error scenarios here
// //     }
// //   };

// //   const handleEditMessageChange = (event) => {
// //     setEditCommentsMessage(event.target.value);

// //   };
// //   const handleEditClick = (item, index) => {
// //     // Set the comment message state to the edited comment
// //     // setCommentMessage(item.comments);
// //     console.log("Clicked item:", item);
// //     handleClose(index);
// //     setIsEditing(true)
// //     // setAnchorEl(e.currentTarget)
// //     setEditCommentsMessage(item.comments)

// //     console.log("editedComments",);
// //     setDiscussionIdEdit(item.discussionCommentsId)
// //     // Close the menu

// //   };
// //   const handleDeleteClick = async (item, index) => {
// //     // Set the comment message state to the edited comment
// //     // setCommentMessage(item.comments);
// //     console.log("Clicked item:", item);
// //     handleClose(index);

// //     // setIsEditing(true)
// //     // setAnchorEl(e.currentTarget)
// //     // setEditCommentsMessage(item.comments)
// //     // setOpen(true)
// //     console.log("editedComments",);
// //     // setDiscussionIdDelete(item.discussionCommentsId)
// //     // Close the menu
// //     // handleClose();
// //     // e.preventDefault();
// //     const payload = {
// //       role: roles[0],
// //       // discussionId: commentsDiscussionId,
// //       // comments: commentMessage
// //     };
// //     console.log("token", token);

// //     try {
// //       const response = await axios.delete(`${BASE_URL}${DISCUSSION_COMMENTS}/${item.discussionCommentsId}`, {

// //         headers: {
// //           Authorization: `Bearer ${token}`
// //         }
// //       });
// //       if (response.status == 200) {
// //         // showSendIcon(false);
// //         setCommentMessage('');
// //         setEditCommentsMessage('');
// //         setShowSendIcon(false);
// //         setIsEditing(false)
// //         getDisussionDetails()
// //         getDisussionDetailsByIdComments();
// //         getDisussionDetailsById()
// //       }
// //       // console.log('Message added successfully:', response.data);
// //       // Optionally, you can handle any success actions here
// //     } catch (error) {
// //       console.error('Error adding message:', error);
// //       // Optionally, you can handle error scenarios here
// //     }
// //   };
// //   // const getDisussionDetailsByIdCommentsReply = async () => {
// //   //   try {
// //   //     const storedToken = token;
// //   // const userLogin=userLoginDetails.homeOwnerLoginId
// //   // setUserLoginId(userLogin);
// //   // console.log("UserDetailsOf",userLoginDetails.homeOwnerLoginId);
// //   //     console.log(storedToken);

// //   //     const response = await axios.get(`${BASE_URL}${DISCUSSION_COMMENTS}/${discussionIdEdit}`, {
// //   //       headers: {
// //   //         Authorization: `Bearer ${storedToken}`,
// //   //       },
// //   //     });
// //   //     console.log(response);

// //   //     if (response.status === 200) {
// //   //       const data = response.data;
// //   //       // setTemplateDetails(data);
// //   //       // setTopic(data.topic)
// //   //       setReplies(data)
// //   //       setIsLoading(false);
// //   //     } else {
// //   //       throw new Error("Failed to fetch data");
// //   //     }
// //   //   } catch (error) {
// //   //     // Handle error
// //   //     console.error("Error fetching data:", error);
// //   //   }
// //   // };
// //   // useEffect(()=>{
// //   //   getDisussionDetailsByIdCommentsReply()
// //   // },[discussionIdEdit])
// //   const handleEditMessage = async (e) => {
// //     const trimmedMessage = editCommentsMessage.trim();
// //     if (!trimmedMessage) {
// //       // Optionally, show some feedback to the user that the message cannot be empty.
// //       return; // Exit the function if the message is empty or whitespace.
// //     }
// //     e.preventDefault()
// //     const payload = {
// //       role: roles[0],
// //       discussionId: commentsDiscussionId,
// //       comments: editCommentsMessage.trim()
// //     };

// //     try {
// //       const response = await axios.put(`${BASE_URL}${DISCUSSION_COMMENTS}/${discussionIdEdit}`, payload, {
// //         headers: {
// //           Authorization: `Bearer ${token}`
// //         }
// //       });
// //       if (response.status == 200) {
// //         // showSendIcon(false);
// //         getDisussionDetailsByIdComments();
// //         getDisussionDetailsById()
// //         setShowSendIcon(false);
// //         setIsEditing(false)
// //         setEditCommentsMessage('');
// //         commentMessage('');

// //         // reset();
// //       }
// //       // console.log('Message added successfully:', response.data);
// //       // Optionally, you can handle any success actions here
// //     } catch (error) {
// //       if (error.response && error.response.data) {
// //         const message = error.response.data.message;
// //         toast.error(message, { position: "top-center" })
// //         getDisussionDetailsByIdComments();
// //         getDisussionDetailsById()

// //         setEditCommentsMessage('');
// //         reset();
// //         console.log("error.response.status" + error.response.status);
// //       } else {
// //         console.error(error); // Log the entire error for further debugging
// //         // toast.error("An error occurred while processing your request.");
// //       }    // Optionally, you can handle error scenarios here
// //     }
// //   };
// //   const editActionButton = (
// //     <CustomButton
// //       fieldType="submit"
// //       buttonName="Update"
// //       click={handleEditClose}
// //     />
// //   );

// //   const editModel = (
// //     <>
// //       <form
// //         onSubmit={handleSubmitModel(submitEdit)}
// //         className="space-y-4 md:space-y-6 "
// //       >
// //         <DialogContent dividers>
// //           <Typography gutterBottom>
// //             <CustomInputField
// //               fieldName="topic"
// //               //   requiredErrorMessage={TOPIC_REQUIRED_MESSAGE}
// //               // fieldPattern={ONLY_ALPHABETIC_WITH_WHITE_SPACE}
// //               // patternErrorMessage={ALPHABETS_ARE_ALLOWED}
// //               fieldId="topic"
// //               minLength={2}
// //               minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
// //               maxLength={250}
// //               maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
// //               fieldLabel="Topic"
// //               fieldControl={controlModal}
// //               fieldError={errorsModal}
// //               fieldRequiredIcon="*"
// //               multiline={true}
// //               numberOfRows={2}
// //               value={topic}
// //               onInput={handleTopicChange}
// //             />

// //             <CustomInputField
// //               fieldName="description"
// //               //   requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
// //               fieldId="description"
// //               minLength={2}
// //               minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
// //               maxLength={1000}
// //               maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
// //               fieldLabel="Description"
// //               fieldControl={controlModal}
// //               fieldError={errorsModal}
// //               fieldRequiredIcon="*"
// //               multiline={true}
// //               numberOfRows={2}
// //               value={description}
// //               onInput={handleDescriptionChange}
// //             />


// //           </Typography>
// //         </DialogContent>
// //         <DialogActions>{editActionButton}</DialogActions>
// //       </form>
// //     </>
// //   );

// //   const okCancelButtonActions = (
// //     <>

// //       <CustomButton
// //         fieldType="button"
// //         buttonName="No"
// //         click={handleDeleteClose}
// //       />
// //       <CustomButton
// //         fieldType="submit"
// //         buttonName="Yes"
// //         click={handleDeleteClose}
// //       />
// //     </>
// //   );

// //   const deleteModel = (
// //     <form
// //       onSubmit={handleSubmit(onSubmitDelete)}
// //       className="space-y-4 md:space-y-6 "
// //     >
// //       <DialogContent dividers>
// //         <Typography>
// //           {" "}
// //           Are you sure  to delete this topic ?
// //         </Typography>
// //       </DialogContent>
// //       <DialogActions>{okCancelButtonActions}</DialogActions>
// //     </form>
// //   );
// //   const [expanded, setExpanded] = useState(false);
// //   const [expandeds, setExpandeds] = useState(false)
// //   const handleExpandClick = () => {
// //     setExpanded(!expanded);
// //   };
// //   const handleExpandClicks = () => {
// //     setExpandeds(!expandeds);
// //   };
// //   const [anchorElArray, setAnchorElArray] = useState(Array(replies.length).fill(null));

// //   const handleMenuClick = (event, index) => {

// //     const newAnchorElArray = [...anchorElArray];
// //     newAnchorElArray[index] = event.currentTarget;
// //     setAnchorElArray(newAnchorElArray);
// //   };

// //   const handleClose = (index) => {
// //     const newAnchorElArray = [...anchorElArray];
// //     newAnchorElArray[index] = null;
// //     setAnchorElArray(newAnchorElArray);
// //   };

// //   const [searchTerm, setSearchTerm] = useState('');
// //   return (

// //     <div class="" style={{ height: "", overflow: "", marginTop: "" }} >
// //       <CustomHeading title="Discussion Board" />
// //       <CustomToastContainer />
// //       <div class="flex min-h-screen w-full bg-primary-bg">
// //         <div class="max-w-[50%] max-h-[500px] flex flex-col border-r border-r-slate-400" style={{}}>

// //           <div class="relative flex items-center px-1">
// //             {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="absolute ml-2 h-5 w-5 text-slate-400">
// //         <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
// //       </svg> */}
// //             <input type="text" placeholder="Search" class="w-full rounded-md border border-gray-300  py-2 pl-9 pr-3 text-slate-900 focus:outline-none" onChange={(e) => setSearchTerm(e.target.value)} />
// //           </div>

// //           {/* <div class="mt-1 flex gap-2 overflow-hidden">
// //      </div> */}
// //           {/* <div class="mt-1 flex items-center"  style={{ backgroundColor: "red" }}> */}
// //           <div class=" flex  justify-between px-2 py-2" >
// //             <div>
// //               <p class="font-small mr-auto">Add Topic</p> {/* "mr-auto" pushes this to the left */}
// //             </div>
// //             <div class="pr-2">

// //               <AddIcon style={{ fontSize: 20, color: 'gray' }} type="submit" onClick={handleAddClickOpen} />

// //             </div>
// //           </div>
// //           {/* </div> */}
// //           <Divider style={{}} />
// //           {/* <!meesages start/> */}
// //           <div className="mt-1 flex flex-col gap-7 overflow-y-auto" style={{ maxHeight: 'calc(3 * (146px + 2 * 2px) + 2 * 2px)' }}>
// //             <div className="px-3 py-2 justify-center items-center">
// //               {discussionData
// //                 .filter(item => item.topic.toLowerCase().includes(searchTerm.toLowerCase()))
// //                 .length > 0 ? (
// //                 discussionData
// //                   .filter(item => item.topic.toLowerCase().includes(searchTerm.toLowerCase()))
// //                   .map((item, index) => (
// //                     <div
// //                       key={item.discussionId}
// //                       className={`flex items-center gap-2 rounded-md px-2 py-1 transition-colors duration-300 ${selectedTopic === item.topic ? '' : 'hover:bg-light'
// //                         }`}
// //                       style={{ backgroundColor: selectedTopic === item.topic ? '#045F9F' : undefined }}
// //                       onClick={() => handleTopicClick(item.topic, item.description, item.discussionId, item.createdDateAndTime, item.createdBy)}
// //                     >
// //                       <div className="overflow-hidden text-left flex-grow">
// //                         <div className="flex justify-between">
// //                           <p className={`flex truncate text-sm font-medium ${selectedTopic === item.topic ? 'text-white' : 'text-black'
// //                             }`}>
// //                             {item.topic.length > 30 ? `${item.topic.substring(0, 20)}...` : item.topic}
// //                           </p>
// //                           {/* <p className="{`truncate text-sm font-medium ${
// //     selectedTopic === item.topic ? 'text-white' : 'text-black'
// //   }`}">
// //                   </p> */}
// //                         </div>
// //                         <p className="flex truncate text-sm text-slate-400">
// //                           {item.description.length > 15 ? `${item.description.substring(0, 10)}...` : item.description}
// //                           {userLoginId === item.createdBy && (
// //                             <span className="ml-auto flex gap-1">
// //                               <EditTwoTone className={`flex truncate text-sm font-medium ${selectedTopic === item.topic ? 'text-white' : 'text-grey'
// //                                 }`} onClick={(e) => {
// //                                   e.stopPropagation();
// //                                   handleEdit(item.discussionId);
// //                                 }} />
// //                               <DeleteTwoTone className={`flex truncate text-sm font-medium ${selectedTopic === item.topic ? 'text-white' : 'text-grey'
// //                                 }`} onClick={(e) => {
// //                                   e.stopPropagation();
// //                                   handleDelete(item.discussionId);
// //                                 }} />
// //                             </span>
// //                           )}
// //                         </p>
// //                         <p className={`flex truncate text-sm font-medium ${selectedTopic === item.topic ? 'text-white' : 'text-grey'
// //                           }`}>                    {moment(item.createdDateAndTime).format('MM/DD/YYYY HH:mm A')}
// //                         </p>
// //                         <Divider className="mt-1 px-2 py-2" />
// //                       </div>
// //                     </div>
// //                   ))
// //               ) : (
// //                 <p>No Data Found</p>
// //               )
// //               }
// //             </div>
// //           </div>

// //           {/* <!meesages End/> */}
// //         </div>
// //         {/* <!Middle start/> */}

// //         <div class="w-full max-h-fullscreen flex flex-col mt-none">
// //         <div className="flex flex-col overscroll-contain flex-1 overflow-y-scroll overflow-x-hidden px-2 max-h-screen">

// //           {discussionData.length > 0 && (
// //             <div class="flex ">
// //               {/* <div class="h-[42px] w-[42px] shrink-0 rounded-full">
// //         <img src="https://picsum.photos/750/740" class="h-full w-full rounded-full object-cover" alt="" />
// //       </div> */}

// //               <div class="ml-2">
// //                 {/* <h2 class="text-base text-blue-300">{selectedTopic}</h2>

// //       <h2 class="text-base text-black-200">{selectedDescription}</h2> */}
// //                 <h6 class="text-sm font-bold">Topic :</h6>
// //                 <h2 className="text-base text-blue-300">
// //                   {selectedTopic?.length > 30 ? selectedTopic.substring(0, 30) : selectedTopic}
// //                   {selectedTopic?.length > 30 && (
// //                     <IconButton onClick={handleExpandClick}>
// //                       <ExpandMoreIcon />
// //                     </IconButton>
// //                   )}
// //                 </h2>
// //                 <Collapse in={expanded} timeout="auto" unmountOnExit>
// //                   <h2 className="text-base text-blue-300 break-all">
// //                     {selectedTopic?.length > 30 && selectedTopic}
// //                   </h2>
// //                 </Collapse>
// //                 <h6 class="text-sm font-bold">Description :</h6>

// //                 <h4 className="text-base text-black-200 break-all">
// //                   {selectedDescription?.length > 60 ? selectedDescription.substring(0, 30) : selectedDescription}
// //                   {selectedDescription?.length > 60 && (
// //                     <IconButton onClick={handleExpandClicks}>
// //                       <ExpandMoreIcon />
// //                     </IconButton>
// //                   )}
// //                 </h4>
// //                 <Collapse in={expandeds} timeout="auto" unmountOnExit>
// //                   <h5 className="text-base text-black-200 break-all">
// //                     {selectedDescription?.length > 30 && selectedDescription}
// //                   </h5>
// //                 </Collapse>

// //                 <p class="text-xs text-slate-400">{formattedDate}</p>
// //               </div>

// //             </div>
// //           )}
// //           <div class="border-b border-b-slate-700"></div>
// //           {/* <div class="flex flex-col gap-2 px-2"> */}




// //             {replies.map((item, index) => (
// //               <div key={index} className={` flex  gap-2 ml-${userLoginId === item.createdBy ? 'auto' : '0'} ${userLoginId === item.createdBy ? 'text-left' : 'text-left'}  ${userLoginId === item.createdBy ? 'flex-row' : 'flex-row'}`}>
// //                 {/* {userLoginId === item.createdDateAndTime ? 'text-right':'text-left'} */}

// //                 {/* {userLoginId === item.createdBy && <span class="text-blue-300 text-base">@you</span>} */}
// //                 {/* {userLoginId !== item.createdBy && <span class="text-blue-300 text-base">@XYZ</span>} */}
// //                 <div className={`flex flex-col  ${userLoginId === item.createdBy ? 'justify-end' : 'justify-start'}`}>
// //                   <div class="m-2">
// //                     {userLoginId !== item.createdBy ? (
// //                       <div class="flex flex-wrap">

// //                         <div className={`px-1 py-1 h-[30px] w-[30px] rounded-full flex items-center justify-center`} style={{ backgroundColor: "#045F9F" }}>
// //                           <span className="text-white font-bold text-xs">{item.createdByDetails.firstName.charAt(0)}</span>
// //                         </div>
// //                         <div class="">
// //                           <span className="ml-2  text-white font-bold text-xs" style={{ color: "#045F9F" }}>{item.createdByDetails.firstName}</span>
// //                         </div>
// //                         <div class="ml-2">

// //                           <span className={` text-xs ${userLoginId === item.createdBy ? 'text-right' : 'text-left'}`} style={{ color: userLoginId === item.createdBy ? 'grey' : 'grey' }}>
// //                             {moment(item.createdDateAndTime).format('MM-DD-YYYY HH:mm A')}
// //                           </span>
// //                           <span class="mr-1">
// //                             {item.isEdited ? <span className='text-xs' style={{ color: 'blue', marginLeft: '5px' }}>Edited</span> : null}

// //                           </span>

// //                         </div>
// //                       </div>
// //                     ) :
// //                       (
// //                         <div class="flex flex-wrap">



// //                           <div className={`px-1 py-1 h-[30px] w-[30px] rounded-full flex items-center justify-center`} style={{ backgroundColor: "#045F9F" }}>
// //                             <span className="text-white font-bold text-xs">{item.createdByDetails.firstName.charAt(0)}</span>
// //                           </div>
// //                           <span className=" ml-2 text-white font-bold text-xs" style={{ color: "#045F9F" }}>{item.createdByDetails.firstName}</span>

// //                           <span className=" ml-2 text-xs text-right" style={{ color: userLoginId === item.createdBy ? 'grey' : 'grey' }}>
// //                             {moment(item.createdDateAndTime).format('MM-DD-YYYY HH:mm A')}
// //                           </span>

// //                           {item.isEdited ? <span className='text-xs' style={{ color: 'blue', marginLeft: '5px' }}>Edited</span> : null}


// //                         </div>

// //                       )}
// //                   </div>
// //                   <div className={`flex w-full  p-2 ${userLoginId === item.createdBy ? 'justify-start' : 'justify-start'} items-center`}>

// //                     <div className="flex flex-wrap w-full ">
// //                       <div>
// //                         <p
// //                           className="p-2 ml-7  break-all  text-balance rounded-md text-start text-sm sm:text-sm md:text-md lg:text-lg "
// //                           style={{
// //                             color: 'black', // Text color is always black
// //                             backgroundColor: isHovering === index ? 'grey' : (userLoginId === item.createdBy ? '' : '')
// //                           }}
// //                           onMouseEnter={() => setIsHovering(index)}
// //                           onMouseLeave={() => setIsHovering(null)}
// //                         >
// //                           {item.comments}
// //                         </p>
// //                       </div>
// //                     </div>

// //                     {userLoginId === item.createdBy && (
// //                       <IconButton aria-label="more" aria-controls={`menu-${index}`} aria-haspopup="true" onClick={(event) => handleMenuClick(event, index)}>
// //                         <MoreVertIcon />
// //                       </IconButton>

// //                     )}
// //                   </div>

// //                 </div>

// //                 <Menu
// //                   id={`menu-${index}`}
// //                   anchorEl={anchorElArray[index]}
// //                   open={Boolean(anchorElArray[index])}
// //                   onClose={() => handleClose(index)}
// //                 >
// //                   <MenuItem onClick={() => handleEditClick(item, index)}>Edit</MenuItem>
// //                   <MenuItem onClick={() => handleDeleteClick(item, index)}>Delete</MenuItem>
// //                 </Menu>
// //                 {/* {userLoginId === item.createdBy && (
// //   <span style={{ color: userLoginId === item.createdBy ? 'green' : 'red' }}>
// //     {moment(item.createdDateAndTime).format('YYYY-MM-DD HH:mm:ss')}
// //   </span>
// // )} */}




// //               </div>
// //             ))}


// //           </div>

// //           {/* flex fixed bottom-0  m-2 "style={{ width: 'calc(60% - 50px)' }} */}
// //           {discussionData.length > 0 && (

// //             <div className="flex  flex-1">
// //               <div className=" ml-2 mr-2 w-full">
// //                 <TextField
// //                   id="outlined-multiline-flexible"
// //                   label="Message"
// //                   multiline
// //                   maxRows={2}
// //                   value={isEditing ? editCommentsMessage : commentMessage}
// //                   onChange={isEditing ? handleEditMessageChange : handleMessageChange}
// //                   onKeyDown={handleKeyDown}
// //                   variant="outlined"
// //                   fullWidth
// //                 />
// //               </div>
// //               <div className="mt-0">
// //                 <SendIcon
// //                   style={{ fontSize: 45, color: '#045F9F', cursor: isEditing || commentMessage ? 'pointer' : 'not-allowed' }}
// //                   onClick={isEditing ? handleEditMessage : commentMessage ? handleAddMessage : null}
// //                 />
// //               </div>
// //             </div>

// //           )}

// //           {/* </div> */}

// //         </div>

// //         {/* <!middle end/> */}
// //       </div>

// //       <CustomModel
// //         title="Add"
// //         submit={onSubmit}
// //         content={addModel}
// //         action={addActionButton}
// //         openStatus={open}
// //         closeStatus={handleAddClose}
// //         iconCloseStatus={handleIconClose}
// //         reset={reset}
// //       />
// //       <CustomModel
// //         title="Edit"
// //         submit={submitEdit}
// //         content={editModel}
// //         action={editActionButton}
// //         openStatus={editOpen}
// //         closeStatus={handleEditClose}
// //         iconCloseStatus={handleEditIconClose}
// //         reset={resetModal}
// //       />
// //       <CustomModel
// //         title=" Delete Discussion Board"
// //         submit={onSubmitDelete}
// //         content={deleteModel}
// //         action={okCancelButtonActions}
// //         openStatus={deleteOpen}
// //         closeStatus={handleDeleteClose}
// //         iconCloseStatus={handleDeleteClose}
// //         reset={reset}
// //         modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
// //       />
// //       {isLoading && <CustomLoading />}
// //     </div>

// //   )
// // }

// // export default Discussionboard


import { DialogActions, DialogContent, Divider, Menu, MenuItem, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import CustomModel from '../../components/CustomModel';
import CustomInputField from '../../components/CustomInputField';
import CustomButton from '../../components/CustomButton';
import { ALPHABETS_ARE_ALLOWED, DESCRIPTION_REQUIRED_MESSAGE, MAX_LENGTH_HUNDRED, MAX_LENGTH_THOUSAND, MAX_LENGTH_TWO_FIFTY, MIN_LENGTH_VALIDATE_MESSAGE, TOPIC_REQUIRED_MESSAGE } from '../../utills/ApplicationConstants';
import { useSelector } from 'react-redux';
import CustomLoading from '../../components/CustomLoading';
import { ALL_DISCUSSION, BASE_URL, DISCUSSION, DISCUSSION_COMMENTS } from '../../utills/ApplicationRouting';
import { request } from '../../services/AxiosConfig';
import { set, useForm } from 'react-hook-form';
import { ONLY_ALPHABETIC_WITH_WHITE_SPACE } from '../../utills/ApplicationRegex';
import CustomToastContainer from '../../components/CustomToastContainer';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import { DeleteTwoTone, EditTwoTone } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import { IconButton, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomHeading from '../../components/CustomHeading';
import { showRowSelectionColumn } from 'material-react-table';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
function Discussionboard() {
  const { token, roles, userLoginDetails } = useSelector((state) => state.hybridhoa);
  const [isLoading, setIsLoading] = useState(false)
  const [topic, setTopic] = useState('')
  const [description, setDescription] = useState('')
  const [time, setTime] = useState('')
  const [discussionId, setDiscussionId] = useState('')
  const [discussionData, setDiscussionData] = useState([])
  const [discussionIdEdit, setDiscussionIdEdit] = useState('')
  const [editCommentsMessage, setEditCommentsMessage] = useState('')
  const [isEditing, setIsEditing] = useState(false);
  const [selectedDiscussionId, setSelectedDiscussionId] = useState(null);
  const [selectedDiscussionDeleteId, setSelectedDiscussionDeleteId] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [isHovering, setIsHovering] = useState(null)
  const [selectedDescription, setSelectedDescription] = useState(null);
  const [commentsDiscussionId, setCommentsDiscussionId] = useState(null)
  const [createdDateAndTimes, setCreatedDateAndTimes] = useState(null)
  const [commentMessage, setCommentMessage] = useState("");
  const [showSendIcon, setShowSendIcon] = useState(false);
  const [discussionIdDelete, setDiscussionIdDelete] = useState(null);
  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm();
  // const {
  //   handleSubmit:handleSubmitModel,
  //   control: controlModal,
  //   formState: { errors: errorsModal },
  //   setValue: setValueModal,
  //   setError: setErrorModal,
  //   reset: resetModal,
  //   watch: watchModal,
  // } = useForm();
  const {
    handleSubmit: handleSubmitModel,
    control: controlModal,
    formState: { errors: errorsModal },
    setValue: setValueModal,
    setError: setErrorModal,
    reset: resetModal,
  } = useForm();
  const [open, setOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [showStartPanel, setShowStartPanel] = useState(false);
  const [showMidPanel, setShowMidPanel] = useState(false);
  const [showArrow, setShowArrow] = useState(false)
  const [selectedBackgroundDiscussionId, setSelectedBackgroundDiscussionId] = useState(null)
  const navigate=useNavigate()
  const handleAddClickOpen = () => {
   navigate('/discussionboardadd')
  };
  const handleAddClose = (status) => {
    if (status === 200) {
      setOpen(false);
    }
  };
  const handleIconClose = () => {
    setOpen(false);
    // reset();
  };
  const handleEditClose = (status) => {
    if (status === 200) {
      setEditOpen(false);
    }
  };
  const handleEditIconClose = () => {
    setEditOpen(false);
    resetModal();
  };
  useEffect(() => {
    // Add the 'no-scroll' class to the body when the component mounts
    document.body.classList.add('no-scroll');

    // Remove the 'no-scroll' class from the body when the component unmounts
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  const getDisussionDetails = async () => {
  
    try {
      const storedToken = token;
      setIsLoading(true)
      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${ALL_DISCUSSION}${roles}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        // setTemplateDetails(data);
        setIsLoading(false)
        setDiscussionData(data)
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getDisussionDetails();
  }, []);
  const handleTopicChange = (event) => {
    setTopic(event.target.value)
  }
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value)
  }

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };
  
  const onSubmit = (data) => {
    setIsLoading(true);
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const topic = data.topic ? data.topic.trim() : '';
    const description = data.description ? data.description.trim() : '';
    const postData = {
      topic: topic,
      description: description,
      role: roles[0],
    };

    if (!postData.topic) {
      setError("topic", {
        type: "manual",
        message: TOPIC_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (!postData.description) {
      setError("description", {
        type: "manual",
        message: DESCRIPTION_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    request({ url: DISCUSSION, method: "post", data: postData, headers })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");
          getDisussionDetails();
          setIsLoading(false);
          handleAddClose(res.status);
          reset();
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const submitEdit = () => {
    const trimmedTopic = topic ? topic.trim() : '';
    const trimmedDescription = description ? description.trim() : '';

    const putData = {

      topic: trimmedTopic.trim(),
      description: trimmedDescription.trim(),
      role: roles[0]
    };

    if (!trimmedTopic) {
      setErrorModal("topic", {
        type: "manual",
        message: TOPIC_REQUIRED_MESSAGE,
      });
      // setIsLoading(false);
      return;
    }
    if (!trimmedDescription) {
      setErrorModal("description", {
        type: "manual",
        message: DESCRIPTION_REQUIRED_MESSAGE,
      });
      // setIsLoading(false);
      return;
    }

    setIsLoading(true);

    request({ url: `${DISCUSSION}/${selectedDiscussionId}`, method: "put", data: putData })
      .then((res) => {
        // setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          setIsLoading(false);
          //   getTemplateDetails();
          getDisussionDetails();
          getDisussionDetailsById()
          handleEditClose(res.status);
         
          reset();
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        // if (error.response) {
        //   console.log("error.response.status" + error.response.status);
        // }
        if (error.response && error.response.data) {
          const message = error.response.data.message;
          toast.error(message, { position: "top-center" });
          console.log("error.response.status" + error.response.status);
        } else {
          console.error(error); // Log the entire error for further debugging
          toast.error("An error occurred while processing your request.");
        }
      });
  };
  const onSubmitDelete = () => {
    // return;
    setIsLoading(true)
    request({ url: `${DISCUSSION}/${selectedDiscussionDeleteId}`, method: "delete" })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setSelectedTopic(" ")
          setCreatedDateAndTimes(null)
          setSelectedDescription(" ")
          // setCreatedDateAndTimes(" ")
          getDisussionDetails();
          setEditCommentsMessage(' ')
          // getTemplateDetails();
          
          setIsLoading(false)
          getDisussionDetailsById();
          getDisussionDetailsByIdComments();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  // State to store the ID of the selected discussion

  // Function to handle opening the modal and setting the selected discussion ID
  const handleEdit = (discussionId) => {
    console.log("discussionId", discussionId);
    // setEditOpen(true)
    // setSelectedDiscussionId(discussionId);
 navigate(`/discussionboardedit/${discussionId}`)
    // Call a function to fetch details of the selected discussion using its ID

    // Open your edit modal here
  }
  const handleDelete = (discussionId) => {
    console.log("discussionId", discussionId);
    setDeleteOpen(true)
    setSelectedDiscussionDeleteId(discussionId);
    
    // Call a function to fetch details of the selected discussion using its ID

    // Open your edit modal here
  }
  const addActionButton = (
    <CustomButton fieldType="submit" buttonName="Add" click={handleAddClose}>
    </CustomButton>
  );
  const addModel = (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography gutterBottom>
            <CustomInputField
              fieldName="topic"
              // requiredErrorMessage={TOPIC_REQUIRED_MESSAGE}
              // fieldPattern={ONLY_ALPHABETIC_WITH_WHITE_SPACE}
              // patternErrorMessage={ALPHABETS_ARE_ALLOWED}
              fieldId="topic"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={250}
              maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
              fieldLabel="Topic"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
            />

            <CustomInputField
              fieldName="description"
              // requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
              fieldId="description"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldLabel="Description"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
            />


          </Typography>
        </DialogContent>
        <DialogActions>{addActionButton}</DialogActions>
      </form>
    </>
  );
  const getDisussionDetailsById = async () => {
    try {
      setIsLoading(true)
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${DISCUSSION}/${selectedDiscussionId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        // setTemplateDetails(data);
        setTopic(data.topic)
        setDescription(data.description);
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getDisussionDetailsById();
  }, [selectedDiscussionId, selectedDiscussionDeleteId]);
  
  // const [createdBy,setCreatedBy]=useState('')
  const [userLoginId, setUserLoginId] = useState(null)
  const handleMessageChange = (event) => {
    setCommentMessage(event.target.value)
    setIsEditing(false);
    setShowSendIcon(event.target.value.trim().length > 0);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      isEditing ? handleEditMessage(event) : handleAddMessage(event);
    }

  };
  useEffect(() => {
    // Automatically select the first topic when the component mounts
    if (discussionData.length > 0) {
      const initialTopic = discussionData[0];
      handleTopicClick(initialTopic.topic, initialTopic.description, initialTopic.discussionId, initialTopic.createdDateAndTime);
    }
  }, [discussionData]);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 600) {
        setShowStartPanel(true);
        setShowMidPanel(true);
        setShowArrow(false)
      } else {
        setShowStartPanel(true);
        setShowMidPanel(false);
        setShowArrow(true)
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const handleTopicClick = (topic, description, discussionId, createdDateAndTime, createdBy) => {
    setSelectedTopic(topic);
    setSelectedDescription(description);
    setCommentsDiscussionId(discussionId);
    setCreatedDateAndTimes(createdDateAndTime);

    setIsEditing(false);
    setShowStartPanel(true);
    setShowMidPanel(true);
setSelectedBackgroundDiscussionId(discussionId)
    // Check screen size
    if (window.innerWidth <= 600) {
      // If screen size is below 600 pixels, hide the start panel and show the mid panel
      setShowStartPanel(false);
      setShowMidPanel(true);
    }


  }
     
  const handleBackClick = () => {
    setShowMidPanel(false);
    setShowStartPanel(true)
  }
  const formattedDate = createdDateAndTimes ? moment(createdDateAndTimes).format('MM/DD/YYYY hh:mm A') : ""; 
  const [replies, setReplies] = useState([])
  const getDisussionDetailsByIdComments = async () => {
    try {
      const storedToken = token;
      const userLogin = userLoginDetails.homeOwnerLoginId
      setUserLoginId(userLogin);
      console.log("UserDetailsOf", userLoginDetails.homeOwnerLoginId);
      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${DISCUSSION_COMMENTS}/${commentsDiscussionId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        // setTemplateDetails(data);
        // setTopic(data.topic)
        setReplies(data)
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getDisussionDetailsByIdComments()
  }, [commentsDiscussionId])
  const handleAddMessage = async (e) => {
    const trimmedMessage = commentMessage.trim();
    if (!trimmedMessage) {
      // Optionally, show some feedback to the user that the message cannot be empty.
      return; // Exit the function if the message is empty or whitespace.
    }
    setIsEditing(false);
    e.preventDefault();
    const payload = {
      role: roles[0],
      discussionId: commentsDiscussionId,
      comments: commentMessage.trim()
    };

    try {
      const response = await axios.post(`${BASE_URL}${DISCUSSION_COMMENTS}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.status == 200) {
        // showSendIcon(false);
        setCommentMessage('');
        setShowSendIcon(false);

        getDisussionDetailsByIdComments();
        getDisussionDetailsById();
      }
      // console.log('Message added successfully:', response.data);
      // Optionally, you can handle any success actions here
    } catch (error) {
      console.error('Error adding message:', error);
      // Optionally, you can handle error scenarios here
    }
  };

  const handleEditMessageChange = (event) => {
    setEditCommentsMessage(event.target.value);

  };
  const handleEditClick = (item, index) => {
    // Set the comment message state to the edited comment
    // setCommentMessage(item.comments);
    console.log("Clicked item:", item);
    handleClose(index);
    setIsEditing(true)
    // setAnchorEl(e.currentTarget)
    setEditCommentsMessage(item.comments)

    console.log("editedComments",);
    setDiscussionIdEdit(item.discussionCommentsId)
    // Close the menu

  };
  const handleDeleteClick = async (item, index) => {
    // Set the comment message state to the edited comment
    // setCommentMessage(item.comments);
    console.log("Clicked item:", item);
    handleClose(index);

    // setIsEditing(true)
    // setAnchorEl(e.currentTarget)
    // setEditCommentsMessage(item.comments)
    // setOpen(true)
    console.log("editedComments",);
    // setDiscussionIdDelete(item.discussionCommentsId)
    // Close the menu
    // handleClose();
    // e.preventDefault();
    const payload = {
      role: roles[0],
      // discussionId: commentsDiscussionId,
      // comments: commentMessage
    };
    console.log("token", token);

    try {
      const response = await axios.delete(`${BASE_URL}${DISCUSSION_COMMENTS}/${item.discussionCommentsId}`, {

        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.status == 200) {
        // showSendIcon(false);
        setCommentMessage('');
        setEditCommentsMessage('');
        setShowSendIcon(false);
        setIsEditing(false)
        // getDisussionDetails()
        getDisussionDetailsByIdComments();
        getDisussionDetailsById()
      }
      // console.log('Message added successfully:', response.data);
      // Optionally, you can handle any success actions here
    } catch (error) {
      console.error('Error adding message:', error);
      // Optionally, you can handle error scenarios here
    }
  };
  // const getDisussionDetailsByIdCommentsReply = async () => {
  //   try {
  //     const storedToken = token;
  // const userLogin=userLoginDetails.homeOwnerLoginId
  // setUserLoginId(userLogin);
  // console.log("UserDetailsOf",userLoginDetails.homeOwnerLoginId);
  //     console.log(storedToken);

  //     const response = await axios.get(`${BASE_URL}${DISCUSSION_COMMENTS}/${discussionIdEdit}`, {
  //       headers: {
  //         Authorization: `Bearer ${storedToken}`,
  //       },
  //     });
  //     console.log(response);

  //     if (response.status === 200) {
  //       const data = response.data;
  //       // setTemplateDetails(data);
  //       // setTopic(data.topic)
  //       setReplies(data)
  //       setIsLoading(false);
  //     } else {
  //       throw new Error("Failed to fetch data");
  //     }
  //   } catch (error) {
  //     // Handle error
  //     console.error("Error fetching data:", error);
  //   }
  // };
  // useEffect(()=>{
  //   getDisussionDetailsByIdCommentsReply()
  // },[discussionIdEdit])
  const handleEditMessage = async (e) => {
    const trimmedMessage = editCommentsMessage.trim();
    if (!trimmedMessage) {
      // Optionally, show some feedback to the user that the message cannot be empty.
      return; // Exit the function if the message is empty or whitespace.
    }
    e.preventDefault()
    const payload = {
      role: roles[0],
      discussionId: commentsDiscussionId,
      comments: editCommentsMessage.trim()
    };

    try {
      const response = await axios.put(`${BASE_URL}${DISCUSSION_COMMENTS}/${discussionIdEdit}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.status == 200) {
        // showSendIcon(false);
        getDisussionDetailsByIdComments();
        getDisussionDetailsById()
        setShowSendIcon(false);
        setIsEditing(false)
        setEditCommentsMessage('');
        commentMessage('');

        // reset();
      }
      // console.log('Message added successfully:', response.data);
      // Optionally, you can handle any success actions here
    } catch (error) {
      if (error.response && error.response.data) {
        const message = error.response.data.message;
        toast.error(message, { position: "top-center" })
        getDisussionDetailsByIdComments();
        getDisussionDetailsById()

        setEditCommentsMessage('');
        reset();
        console.log("error.response.status" + error.response.status);
      } else {
        console.error(error); // Log the entire error for further debugging
        // toast.error("An error occurred while processing your request.");
      }    // Optionally, you can handle error scenarios here
    }
  };
  const editActionButton = (
    <CustomButton
      fieldType="submit"
      buttonName="Update"
      click={handleEditClose}
    />
  );

  const editModel = (
    <>
      <form
        onSubmit={handleSubmitModel(submitEdit)}
        className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography gutterBottom>
            <CustomInputField
              fieldName="topic"
              //   requiredErrorMessage={TOPIC_REQUIRED_MESSAGE}
              // fieldPattern={ONLY_ALPHABETIC_WITH_WHITE_SPACE}
              // patternErrorMessage={ALPHABETS_ARE_ALLOWED}
              fieldId="topic"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={250}
              maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
              fieldLabel="Topic"
              fieldControl={controlModal}
              fieldError={errorsModal}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
              value={topic}
              onInput={handleTopicChange}
            />

            <CustomInputField
              fieldName="description"
              //   requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
              fieldId="description"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldLabel="Description"
              fieldControl={controlModal}
              fieldError={errorsModal}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
              value={description}
              onInput={handleDescriptionChange}
            />


          </Typography>
        </DialogContent>
        <DialogActions>{editActionButton}</DialogActions>
      </form>
    </>
  );

  const okCancelButtonActions = (
    <>

      <CustomButton
        fieldType="button"
        buttonName="No"
        click={handleDeleteClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Yes"
        click={handleDeleteClose}
      />
    </>
  );

  const deleteModel = (
    <form
      onSubmit={handleSubmit(onSubmitDelete)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography>
          {" "}
          Are you sure  to delete this topic ?
        </Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );
  const [expanded, setExpanded] = useState(false);
  const [expandeds, setExpandeds] = useState(false)
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleExpandClicks = () => {
    setExpandeds(!expandeds);
  };
  const [anchorElArray, setAnchorElArray] = useState(Array(replies.length).fill(null));

  const handleMenuClick = (event, index) => {

    const newAnchorElArray = [...anchorElArray];
    newAnchorElArray[index] = event.currentTarget;
    setAnchorElArray(newAnchorElArray);
  };

  const handleClose = (index) => {
    const newAnchorElArray = [...anchorElArray];
    newAnchorElArray[index] = null;
    setAnchorElArray(newAnchorElArray);
  };

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 750) {
        // Define event handlers
        let currentScrollPosition = 0;
  
        const handleFocusIn = () => {
          currentScrollPosition = window.scrollY;
          const activeElement = document.activeElement;
  
          if (activeElement) {
            const modal = activeElement.closest('.modal');
            if (modal) {
              modal.scrollIntoView({ behavior: 'smooth' });
            } else {
              activeElement.scrollIntoView({ behavior: 'smooth' });
            }
          }
        };
  
        const handleFocusOut = () => {
          window.scrollTo({ top: currentScrollPosition, behavior: 'smooth' });
        };
  
        const handleModalOpen = () => {
          currentScrollPosition = window.scrollY;
        };
  
        const handleModalClose = () => {
          window.scrollTo({ top: currentScrollPosition, behavior: 'smooth' });
        };
  
        // Add event listeners
        document.addEventListener('focusin', handleFocusIn);
        document.addEventListener('focusout', handleFocusOut);
  
        // Add event listeners for modal open and close
        const modals = document.querySelectorAll('.modal');
        modals.forEach(modal => {
          modal.addEventListener('show.bs.modal', handleModalOpen);
          modal.addEventListener('hide.bs.modal', handleModalClose);
        });
  
        // Cleanup event listeners on component unmount or resize
        return () => {
          document.removeEventListener('focusin', handleFocusIn);
          document.removeEventListener('focusout', handleFocusOut);
  
          modals.forEach(modal => {
            modal.removeEventListener('show.bs.modal', handleModalOpen);
            modal.removeEventListener('hide.bs.modal', handleModalClose);
          });
        };
      }
    };
  
    // Initial check and set up resize listener
    handleResize();
    window.addEventListener('resize', handleResize);
  
    // Cleanup resize listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

  return (


//     <div class="pb-13" style={{ height: "", overflow: "", marginTop: "" }} >
     
//         <CustomHeading title="Discussion Board" style={{ marginTop: "5px" }} />
//         <CustomToastContainer />
//         <div class="flex h-full w-full bg-primary-bg  justify-start  " >
//           {showStartPanel && (
//             //  <div className="start-panel" style={{backgroundColor:""}}>
//             <div class={`max-w-full max-h-full flex flex-col  ${window.innerWidth <= 600 ? 'w-full mx-auto ' : ''}`}>
//               {/* // <div class={`max-w-[60%] max-h-[200px] flex flex-col min-h-[700px] md:min-h-[600px] lg:min-h-[500px] xl:min-h-[500px] xs:min-h-[600px] ${window.innerWidth <= 600 ? 'w-full mx-auto' : ''}`}> */}
//               <div class={`border-r border-r-slate-400 ${window.innerWidth <= 600 ? 'h-full ml-[5px]' : ''}`}>
//                 <div class="relative flex items-center px-1 ">
//                   {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="absolute ml-2 h-5 w-5 text-slate-400">
//         <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
//       </svg> */}
//                   <input type="text" placeholder="Search" class="w-full rounded-md border border-gray-300  py-2 pl-9 pr-3 text-slate-900 focus:outline-none" onChange={(e) => setSearchTerm(e.target.value)} />
//                 </div>

//                 {/* <div class="mt-1 flex gap-2 overflow-hidden">
//      </div> */}
//                 {/* <div class="mt-1 flex items-center"  style={{ backgroundColor: "red" }}> */}
//                 <div class=" flex  justify-between px-0 py-2 " >
//                   <div>
//                     <p class="font-small mr-auto">Add Topic</p> {/* "mr-auto" pushes this to the left */}
//                   </div>
//                   <div class="pr-2">

//                     <AddIcon style={{ fontSize: 20, color: 'gray' }} type="submit" onClick={handleAddClickOpen} />

//                   </div>
//                 </div>
//                 {/* </div> */}
//                 <Divider style={{}} />
//                 {/* <!meesages start/> */}
//                 <div className="mt-1 flex flex-col gap-7 overflow-y-auto " style={{ maxHeight: 'calc(3 * (156px + 2 * 2px) + 2 * 2px)' }}>
//                   <div className="px-1 py-2 justify-center items-center ">
//                     {discussionData
//                       .filter(item => item.topic.toLowerCase().includes(searchTerm.toLowerCase()))
//                       .length > 0 ? (
//                       discussionData
//                         .filter(item => item.topic.toLowerCase().includes(searchTerm.toLowerCase()))
//                         .map((item, index) => (
//                           <div
//                             key={item.discussionId}
//                             className={`flex items-center gap-2 rounded-md px-2 py-1 transition-colors duration-300 ${selectedTopic === item.topic ? '' : 'hover:bg-light'
//                               }`}
//                             style={{ backgroundColor: selectedTopic === item.topic ? '#045F9F' : undefined }}
//                             onClick={() => handleTopicClick(item.topic, item.description, item.discussionId, item.createdDateAndTime, item.createdBy)}
//                           >
//                             <div className="w-full overflow-hidden text-left flex-grow">
//                               <div className="flex justify-between">
//                                 <p className={`flex truncate text-sm font-medium ${selectedTopic === item.topic ? 'text-white' : 'text-black'
//                                   }`}>
//                                   {item.topic.length > 30 ? `${item.topic.substring(0, 20)}...` : item.topic}
//                                 </p>
//                                 {/* <p className="{`truncate text-sm font-medium ${
//     selectedTopic === item.topic ? 'text-white' : 'text-black'
//   }`}">
//                   </p> */}
//                               </div>
//                               <p className="flex truncate text-sm text-slate-400">
//                                 {item.description.length > 15 ? `${item.description.substring(0, 15)}...` : item.description}
//                                 {userLoginId === item.createdBy && (
//                                   <span className="ml-auto flex gap-1">
//                                     <EditTwoTone className={`flex truncate text-sm font-medium ${selectedTopic === item.topic ? 'text-white' : 'text-grey'
//                                       }`} onClick={(e) => {
//                                         e.stopPropagation();
//                                         handleEdit(item.discussionId);
//                                       }} />
//                                     <DeleteTwoTone className={`flex truncate text-sm font-medium ${selectedTopic === item.topic ? 'text-white' : 'text-grey'
//                                       }`} onClick={(e) => {
//                                         e.stopPropagation();
//                                         handleDelete(item.discussionId);
//                                       }} />
//                                   </span>
//                                 )}
//                               </p>
//                               <p className={`flex truncate text-sm font-medium ${selectedTopic === item.topic ? 'text-white' : 'text-grey'
//                                 }`}>                    {moment(item.createdDateAndTime).format('MM/DD/YYYY HH:mm A')}
//                               </p>
//                               <Divider className="mt-1 px-2 py-2" />
//                             </div>
//                           </div>
//                         ))
//                     ) : (
//                       <p>No Data Found</p>
//                     )
//                     }
//                   </div>
//                 </div>

//                 {/* <!meesages End/> */}
//               </div>
//             </div>
//           )}
//           {/* <!Middle start/> */}
//           {showMidPanel && (
//             //  <div className="mid-panel" style={{backgroundColor:""}}>
//             <div class="w-full max-h-full flex flex-col mt-none">
//               {/* <div style={{ height: 'calc(80vh - 100px)' }} class="overflow-y-auto mb-2"> */}
//               {/* <div class="flex flex-col px-2  min-h-[700px] md:min-h-[500px] lg:min-h-[500px] xl:min-h-[500px] xs:min-h-[500px]" > */}
//               {discussionData.length > 0 && (
//                 <div class="flex  items-center gap-2 px-1 py-1 ">
//                   {/* <div class="h-[42px] w-[42px] shrink-0 rounded-full">
//         <img src="https://picsum.photos/750/740" class="h-full w-full rounded-full object-cover" alt="" />
//       </div> */}
//                   {showArrow && (
//                     <div >
//                       <ArrowBackIcon onClick={handleBackClick} />
//                     </div>
//                   )}
//                   <div class="ml-2">
//                     {/* <h2 class="text-base text-blue-300">{selectedTopic}</h2>

//       <h2 class="text-base text-black-200">{selectedDescription}</h2> */}
//                     <h6 class="text-sm font-bold">Topic :</h6>
//                     <h2 className="text-base text-blue-300">
//                       {selectedTopic?.length > 30 ? selectedTopic.substring(0, 30) : selectedTopic}
//                       {selectedTopic?.length > 30 && (
//                         <IconButton onClick={handleExpandClick}>
//                           <ExpandMoreIcon />
//                         </IconButton>
//                       )}
//                     </h2>
//                     <Collapse in={expanded} timeout="auto" unmountOnExit>
//                       <h2 className="text-base text-blue-300 break-all">
//                         {selectedTopic?.length > 30 && selectedTopic}
//                       </h2>
//                     </Collapse>
//                     <h6 class="text-sm font-bold">Description :</h6>

//                     <h4 className="text-base text-black-200 break-all">
//                       {selectedDescription?.length > 60 ? selectedDescription.substring(0, 30) : selectedDescription}
//                       {selectedDescription?.length > 60 && (
//                         <IconButton onClick={handleExpandClicks}>
//                           <ExpandMoreIcon />
//                         </IconButton>
//                       )}
//                     </h4>
//                     <Collapse in={expandeds} timeout="auto" unmountOnExit>
//                       <h5 className="text-base text-black-200 break-all">
//                         {selectedDescription?.length > 30 && selectedDescription}
//                       </h5>
//                     </Collapse>

//                     <p class="text-xs text-slate-400">{formattedDate}</p>
//                   </div>

//                 </div>
//               )}
//               <div class="border-b border-b-slate-700"></div>
//               {/* <div class="flex flex-col gap-2 px-2"> */}

//               <div
//       className="flex flex-col overscroll-contain flex-1 overflow-y-scroll overflow-x-hidden px-0"
//       style={{
//         maxHeight:
//           window.innerWidth < 500
//             ? 'calc(3 * (146px + 2 * 2px) + 2 * 2px)'
//             : 'calc(3 * (156px + 2 * 2px) + 2 * 2px)',
//         minHeight:
//           window.innerWidth < 500
//             ? 'calc(3 * (146px + 2 * 2px) + 2 * 2px)'
//             : 'calc(3 * (156px + 2 * 2px) + 2 * 2px)',
//       }}
//     >                {replies.map((item, index) => (
//                   <div key={index} className={` flex ${userLoginId === item.createdBy ? 'text-left' : 'text-left'}  ${userLoginId === item.createdBy ? 'flex-row' : 'flex-row'}`}>
//                     {/* {userLoginId === item.createdDateAndTime ? 'text-right':'text-left'} */}

//                     {/* {userLoginId === item.createdBy && <span class="text-blue-300 text-base">@you</span>} */}
//                     {/* {userLoginId !== item.createdBy && <span class="text-blue-300 text-base">@XYZ</span>} */}
//                     <div className={`flex flex-col  ${userLoginId === item.createdBy ? 'justify-start' : 'justify-start'}`}>
//                       <div class="mr-2 ml-2 mb-2 mt-2 ">
//                         {userLoginId !== item.createdBy ? (
//                           <div class="flex flex-wrap">

//                             <div className={`px-1 py-1 h-[30px] w-[30px] rounded-full flex items-center justify-center`} style={{ backgroundColor: "#045F9F" }}>
//                               <span className="text-white font-bold text-xs">{item.createdByDetails.firstName.charAt(0)}</span>
//                             </div>
//                             <div class="">
//                               <span className="ml-2  text-white font-bold text-xs" style={{ color: "#045F9F" }}>{item.createdByDetails.firstName}</span>
//                             </div>
//                             <div class="ml-2">

//                               <span className={` text-xs ${userLoginId === item.createdBy ? 'text-right' : 'text-left'}`} style={{ color: userLoginId === item.createdBy ? 'grey' : 'grey' }}>
//                                 {moment(item.createdDateAndTime).format('MM-DD-YYYY HH:mm A')}
//                               </span>
//                               <span class="mr-1">
//                                 {item.isEdited ? <span className='text-xs' style={{ color: 'blue', marginLeft: '5px' }}>Edited</span> : null}

//                               </span>

//                             </div>
//                           </div>
//                         ) :
//                           (
//                             <div class="flex flex-wrap">



//                               <div className={`px-1 py-1 h-[30px] w-[30px] rounded-full flex items-center justify-center`} style={{ backgroundColor: "#045F9F" }}>
//                                 <span className="text-white font-bold text-xs">{item.createdByDetails.firstName.charAt(0)}</span>
//                               </div>
//                               <span className=" ml-2 text-white font-bold text-xs" style={{ color: "#045F9F" }}>{item.createdByDetails.firstName}</span>

//                               <span className=" ml-2 text-xs text-right" style={{ color: userLoginId === item.createdBy ? 'grey' : 'grey' }}>
//                                 {moment(item.createdDateAndTime).format('MM-DD-YYYY HH:mm A')}
//                               </span>

//                               {item.isEdited ? <span className='text-xs' style={{ color: 'blue', marginLeft: '5px' }}>Edited</span> : null}


//                             </div>

//                           )}
//                       </div>
//                       <div className={`flex  w-full  p-2  ${userLoginId === item.createdBy ? 'justify-start' : 'justify-start'} items-center`}
//                         style={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>

//                         <div className="flex flex-wrap w-full">
//                           <span
//                             className="px-2 py-2 ml-1 mr-1  rounded-md text-center text-xs sm:text-sm md:text-lg"
//                             style={{
//                               color: 'black',
//                               backgroundColor: isHovering === index ? 'grey' : (userLoginId === item.createdBy ? '' : '')
//                             }}
//                             onMouseEnter={() => setIsHovering(index)}
//                             onMouseLeave={() => setIsHovering(null)}
//                           >
//                             {item.comments}
//                           </span>
//                         </div>

//                         {userLoginId === item.createdBy && (
//                           <IconButton aria-label="more" aria-controls={`menu-${index}`} aria-haspopup="true" onClick={(event) => handleMenuClick(event, index)}>
//                             <MoreVertIcon />
//                           </IconButton>

//                         )}
//                       </div>

//                     </div>

//                     <Menu
//                       id={`menu-${index}`}
//                       anchorEl={anchorElArray[index]}
//                       open={Boolean(anchorElArray[index])}
//                       onClose={() => handleClose(index)}
//                     >
//                       <MenuItem onClick={() => handleEditClick(item, index)}>Edit</MenuItem>
//                       <MenuItem onClick={() => handleDeleteClick(item, index)}>Delete</MenuItem>
//                     </Menu>
//                     {/* {userLoginId === item.createdBy && (
//   <span style={{ color: userLoginId === item.createdBy ? 'green' : 'red' }}>
//     {moment(item.createdDateAndTime).format('YYYY-MM-DD HH:mm:ss')}
//   </span>
// )} */}




//                   </div>
//                 ))}
//               </div>



//               {/* flex fixed bottom-0  m-2 "style={{ width: 'calc(60% - 50px)' }} */}
              
//               {discussionData.length > 0 && (
//                 <div className=" flex  flex-1 pb-4 ">
//                   <div className="mt-2 flex   ml-2 mr-0 w-full ">
//                     <TextField
//                       id="outlined-multiline-flexible"
//                       label="Message"
//                       multiline
//                       maxRows={2}
//                       value={isEditing ? editCommentsMessage : commentMessage}
//                       onChange={isEditing ? handleEditMessageChange : handleMessageChange}
//                       onKeyDown={handleKeyDown}
//                       variant="outlined"
//                       fullWidth
//                     />
                 
//                   </div>
//                   <div class="ml-1 mt-2">
//                   <SendIcon
//                       style={{ fontSize: 45, color: '#045F9F', cursor: isEditing || commentMessage ? 'pointer' : 'not-allowed' }}
//                       onClick={isEditing ? handleEditMessage : commentMessage ? handleAddMessage : null}
//                     className=' '/>
//                   </div>
//                 </div>
//               )}
//               {/* </div> */}

//             </div>
//             // </div>
//           )}
//           {/* <!middle end/> */}
//         </div>

//         <CustomModel
//           title="Add"
//           submit={onSubmit}
//           content={addModel}
//           action={addActionButton}
//           openStatus={open}
//           closeStatus={handleAddClose}
//           iconCloseStatus={handleIconClose}
//           reset={reset}
//         />
//         <CustomModel
//           title="Edit"
//           submit={submitEdit}
//           content={editModel}
//           action={editActionButton}
//           openStatus={editOpen}
//           closeStatus={handleEditClose}
//           iconCloseStatus={handleEditIconClose}
//           reset={resetModal}
//         />
//         <CustomModel
//           title=" Delete Discussion Board"
//           submit={onSubmitDelete}
//           content={deleteModel}
//           action={okCancelButtonActions}
//           openStatus={deleteOpen}
//           closeStatus={handleDeleteClose}
//           iconCloseStatus={handleDeleteClose}
//           reset={reset}
//           modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
//         />
//         {isLoading && <CustomLoading />}

//     </div>
<div className="pb-13 flex flex-col ">
<CustomHeading title="Discussion Board" style={{ marginTop: "5px" }} />
<CustomToastContainer />
<div className="flex w-full bg-primary-bg">
  {showStartPanel && (
    <div className="w-full pb-10 max-w-sm">
      <div className="flex justify-center">
        <input
          type="text"
          placeholder="Search"
          className="w-full max-w-lg rounded-md border border-gray-300 py-2 px-3 text-slate-900 focus:outline-none"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="flex justify-between px-2 py-2">
        <p className="text-sm font-small">Add Topic</p>
        <AddIcon style={{ fontSize: 20, color: 'gray' }} type="submit" onClick={handleAddClickOpen} />
      </div>
      <Divider />
      <div className="overflow-y-auto" style={{ maxHeight: 'calc(90vh - 200px)' }}>
        {discussionData.filter(item => item.topic.toLowerCase().includes(searchTerm.toLowerCase())).length > 0 ? (
          discussionData.filter(item => item.topic.toLowerCase().includes(searchTerm.toLowerCase())).map((item, index) => (
            <div
              key={item.discussionId}
              className={`flex items-center gap-2 rounded-md px-2 py-1 transition-colors duration-300 ${selectedBackgroundDiscussionId === item.topic ? '' : 'hover:bg-light'}`}
              style={{ backgroundColor: selectedBackgroundDiscussionId === item.discussionId ? '#045F9F' : undefined }}
              onClick={() => handleTopicClick(item.topic, item.description, 
                item.discussionId, item.createdDateAndTime, item.createdBy)}
            >
              <div className="w-full overflow-hidden text-left flex-grow">
                <div className="flex justify-between">
                  <p className={`flex truncate text-sm font-medium ${selectedBackgroundDiscussionId === item.discussionId ? 'text-white' : 'text-black'}`}>
                    {item.topic.length > 30 ? `${item.topic.substring(0, 20)}...` : item.topic}
                  </p>
                </div>
                <p className="flex truncate text-sm text-slate-400">
                  {item.description.length > 15 ? `${item.description.substring(0, 15)}...` : item.description}
                  {userLoginId === item.createdBy && (
                    <span className="ml-auto flex gap-1">
                      <EditTwoTone className={`flex truncate text-sm font-medium ${selectedBackgroundDiscussionId === item.discussionId ? 'text-white' : 'text-grey'}`} onClick={(e) => {
                        e.stopPropagation();
                        handleEdit(item.discussionId);
                      }} />
                      <DeleteTwoTone className={`flex truncate text-sm font-medium ${selectedBackgroundDiscussionId === item.discussionId ? 'text-white' : 'text-grey'}`} onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(item.discussionId);
                      }} />
                    </span>
                  )}
                </p>
                <p className={`flex truncate text-sm font-medium ${selectedBackgroundDiscussionId === item.discussionId ? 'text-white' : 'text-grey'}`}>
                  {moment(item.createdDateAndTime).format('MM/DD/YYYY hh:mm A')}
                </p>
                <Divider className="mt-1 px-2 py-2" />
              </div>
            </div>
          ))
        ) : (
          <p>No Data Found</p>
        )}
      </div>
    </div>
  )}
<div className="ml-2 border-l border-gray-300 flex-grow mb-4"></div>
  {showMidPanel && (
    <div className="w-full">

      <div className="flex  px-2 py-1">
      {showArrow && (
        <ArrowBackIcon onClick={handleBackClick} />
      )}
        <div className="ml-2">
          <h6 className="text-sm font-bold">Topic :</h6>
          <h2 className="text-base text-blue-300">
            {selectedTopic?.length > 30 ? selectedTopic.substring(0, 30) : selectedTopic}
            {selectedTopic?.length > 30 && (
              <IconButton onClick={handleExpandClick}>
                <ExpandMoreIcon />
              </IconButton>
            )}
          </h2>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <h2 className="text-base text-blue-300 break-all">
              {selectedTopic?.length > 30 && selectedTopic}
            </h2>
          </Collapse>
          <h6 className="text-sm font-bold">Description :</h6>
          <h4 className="text-base text-black-200 break-all">
            {selectedDescription?.length > 60 ? selectedDescription.substring(0, 30) : selectedDescription}
            {selectedDescription?.length > 60 && (
              <IconButton onClick={handleExpandClicks}>
                <ExpandMoreIcon />
              </IconButton>
            )}
          </h4>
          <Collapse in={expandeds} timeout="auto" unmountOnExit>
            <h5 className="text-base text-black-200 break-all">
              {selectedDescription?.length > 30 && selectedDescription}
            </h5>
          </Collapse>
          <p className="text-xs text-slate-400">{formattedDate}</p>
        </div>
      </div>
      <div className="border-b border-b-slate-700"></div>
      <div className="overflow-y-scroll" style={{ maxHeight: 'calc(65vh - 50px - 50px)', minHeight: 'calc(65vh - 50px - 50px)' }}>
        {replies.map((item, index) => (
          <div key={index} className={`flex ${userLoginId === item.createdBy ? 'text-left' : 'text-left'} ${userLoginId === item.createdBy ? 'flex-row' : 'flex-row'}`}>
            <div className={`flex flex-col ${userLoginId === item.createdBy ? 'justify-start' : 'justify-start'}`}>
              <div className="mr-2 ml-2 mb-2 mt-2">
                {userLoginId !== item.createdBy ? (
                  <div className="flex flex-wrap">
                    <div className={`px-1 py-1 h-[30px] w-[30px] rounded-full flex items-center justify-center`} style={{ backgroundColor: "#045F9F" }}>
                      <span className="text-white font-bold text-xs">{item.createdByDetails?.firstName?.charAt(0) || ""}</span>
                    </div>
                    <div className="">
                      <span className="ml-2 text-white font-bold text-xs" style={{ color: "#045F9F" }}>{item.createdByDetails.firstName}</span>
                    </div>
                    <div className="ml-2">
                      <span className={`text-xs ${userLoginId === item.createdBy ? 'text-right' : 'text-left'}`} style={{ color: userLoginId === item.createdBy ? 'grey' : 'grey' }}>
                        {moment(item.createdDateAndTime).format('MM-DD-YYYY hh:mm A')}
                      </span>
                      <span className="mr-1">
                        {item.isEdited ? <span className='text-xs' style={{ color: 'blue', marginLeft: '5px' }}>Edited</span> : null}
                      </span>
                   
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-wrap">
                      <div className={`px-1 py-1 h-[30px] w-[30px] rounded-full flex items-center justify-center`} style={{ backgroundColor: "#045F9F" }}>
                        <span className="text-white font-bold text-xs">{item.createdByDetails.firstName.charAt(0)}</span>
                      </div>
                      <span className=" ml-2 text-white font-bold text-xs" style={{ color: "#045F9F" }}>{item.createdByDetails.firstName}</span>
                      <span className=" ml-2 text-xs text-right" style={{ color: userLoginId === item.createdBy ? 'grey' : 'grey' }}>
                        {moment(item.createdDateAndTime).format('MM-DD-YYYY hh:mm A')}
                      </span>
                      {item.isEdited ? <span className='text-xs' style={{ color: 'blue', marginLeft: '5px' }}>Edited</span> : null}
                    </div>
                  )}
                </div>
                <div className={`flex w-full p-2 ${userLoginId === item.createdBy ? 'justify-start' : 'justify-start'} items-center`} style={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                  <div className="flex flex-wrap w-full">
                    <span
                      className="px-2 py-2 ml-1 mr-1 rounded-md text-center text-xs sm:text-sm md:text-lg"
                      style={{
                        color: 'black',
                        backgroundColor: isHovering === index ? 'grey' : (userLoginId === item.createdBy ? '' : '')
                      }}
                      onMouseEnter={() => setIsHovering(index)}
                      onMouseLeave={() => setIsHovering(null)}
                    >
                      {item.comments}
                    </span>
                  </div>
                  {userLoginId === item.createdBy && (
                    <IconButton aria-label="more" aria-controls={`menu-${index}`} aria-haspopup="true" onClick={(event) => handleMenuClick(event, index)}>
                      <MoreVertIcon />
                    </IconButton>
                  )}
                </div>
              </div>
              <Menu
                id={`menu-${index}`}
                anchorEl={anchorElArray[index]}
                open={Boolean(anchorElArray[index])}
                onClose={() => handleClose(index)}
              >
                <MenuItem onClick={() => handleEditClick(item, index)}>Edit</MenuItem>
                <MenuItem onClick={() => handleDeleteClick(item, index)}>Delete</MenuItem>
              </Menu>
            </div>
          ))}
        </div>
        <div className="flex justify-between px-2 md:pb-8">
          <div className="w-full">
            <TextField
              id="outlined-multiline-flexible"
              label="Message"
              multiline
              maxRows={2}
              value={isEditing ? editCommentsMessage : commentMessage}
              onChange={isEditing ? handleEditMessageChange : handleMessageChange}
              onKeyDown={handleKeyDown}
              variant="outlined"
              fullWidth
            />
          </div>
          <div className="ml-2 mt-2">
            <SendIcon
              style={{ fontSize: 45, color: '#045F9F', 
                cursor: (isEditing || commentMessage) && discussionData.length > 0 ? 'pointer' : 'not-allowed', }}
                onClick={(isEditing || commentMessage) && discussionData.length > 0 ? (isEditing ? handleEditMessage : handleAddMessage) : null}            />
          </div>
          {/* <div className="ml-2 mt-2">
            <SendIcon
              style={{ fontSize: 45, color: '#045F9F', cursor: isEditing || commentMessage ? 'pointer' : 'not-allowed' }}
              onClick={isEditing ? handleEditMessage : commentMessage ? handleAddMessage : null}
            />
          </div> */}
        </div>
      </div>
    )}
  </div>
  <CustomModel
    title="Add"
    submit={onSubmit}
    content={addModel}
    action={addActionButton}
    openStatus={open}
    closeStatus={handleAddClose}
    iconCloseStatus={handleIconClose}
    reset={reset}
  />
  <CustomModel
    title="Edit"
    submit={submitEdit}
    content={editModel}
    action={editActionButton}
    openStatus={editOpen}
    closeStatus={handleEditClose}
    iconCloseStatus={handleEditIconClose}
    reset={resetModal}
  />
  <CustomModel
    title=" Delete Discussion Board"
    submit={onSubmitDelete}
    content={deleteModel}
    action={okCancelButtonActions}
    openStatus={deleteOpen}
    closeStatus={handleDeleteClose}
    iconCloseStatus={handleDeleteClose}
    reset={reset}
    modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
  />
  {isLoading && <CustomLoading />}
</div>


  );
};




export default Discussionboard
// import { DialogActions, DialogContent, Divider, Menu, MenuItem, TextField, Typography } from '@mui/material';
// import { Box } from '@mui/system';
// import React, { useEffect, useState } from 'react'
// import SendIcon from '@mui/icons-material/Send';
// import AddIcon from '@mui/icons-material/Add';
// import CustomModel from '../../components/CustomModel';
// import CustomInputField from '../../components/CustomInputField';
// import CustomButton from '../../components/CustomButton';
// import { ALPHABETS_ARE_ALLOWED, DESCRIPTION_REQUIRED_MESSAGE, MAX_LENGTH_HUNDRED, MAX_LENGTH_THOUSAND, MAX_LENGTH_TWO_FIFTY, MIN_LENGTH_VALIDATE_MESSAGE, TOPIC_REQUIRED_MESSAGE } from '../../utills/ApplicationConstants';
// import { useSelector } from 'react-redux';
// import CustomLoading from '../../components/CustomLoading';
// import { ALL_DISCUSSION, BASE_URL, DISCUSSION, DISCUSSION_COMMENTS } from '../../utills/ApplicationRouting';
// import { request } from '../../services/AxiosConfig';
// import { set, useForm } from 'react-hook-form';
// import { ONLY_ALPHABETIC_WITH_WHITE_SPACE } from '../../utills/ApplicationRegex';
// import CustomToastContainer from '../../components/CustomToastContainer';
// import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify';

// import { DeleteTwoTone, EditTwoTone } from '@mui/icons-material';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import moment from 'moment';
// import { IconButton, Collapse } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import CustomHeading from '../../components/CustomHeading';
// function Discussionboard() {
//   const { token, roles, userLoginDetails } = useSelector((state) => state.hybridhoa);
//   const [isLoading, setIsLoading] = useState(false)
//   const [topic, setTopic] = useState('')
//   const [description, setDescription] = useState('')
//   const [time, setTime] = useState('')
//   const [discussionId, setDiscussionId] = useState('')
//   const [discussionData, setDiscussionData] = useState([])
//   const [discussionIdEdit, setDiscussionIdEdit] = useState('')
//   const [editCommentsMessage, setEditCommentsMessage] = useState('')
//   const [isEditing, setIsEditing] = useState(false);
//   const [selectedDiscussionId, setSelectedDiscussionId] = useState(null);
//   const [selectedDiscussionDeleteId, setSelectedDiscussionDeleteId] = useState(null);
//   const [selectedTopic, setSelectedTopic] = useState(null);
//   const [isHovering, setIsHovering] = useState(null)
//   const [selectedDescription, setSelectedDescription] = useState(null);
//   const [commentsDiscussionId, setCommentsDiscussionId] = useState(null)
//   const [createdDateAndTimes, setCreatedDateAndTimes] = useState(null)
//   const [commentMessage, setCommentMessage] = useState("");
//   const [showSendIcon, setShowSendIcon] = useState(false);
//   const [discussionIdDelete, setDiscussionIdDelete] = useState(null);
//   const {
//     handleSubmit,
//     control,
//     reset,
//     setError,
//     formState: { errors },
//   } = useForm();
//   // const {
//   //   handleSubmit:handleSubmitModel,
//   //   control: controlModal,
//   //   formState: { errors: errorsModal },
//   //   setValue: setValueModal,
//   //   setError: setErrorModal,
//   //   reset: resetModal,
//   //   watch: watchModal,
//   // } = useForm();
//   const {
//     handleSubmit: handleSubmitModel,
//     control: controlModal,
//     formState: { errors: errorsModal },
//     setValue: setValueModal,
//     setError: setErrorModal,
//     reset: resetModal,
//   } = useForm();
//   const [open, setOpen] = useState(false)
//   const [editOpen, setEditOpen] = useState(false);
//   const [deleteOpen, setDeleteOpen] = useState(false);
//   const handleAddClickOpen = () => {
//     setOpen(true);
//   };
//   const handleAddClose = (status) => {
//     if (status === 200) {
//       setOpen(false);
//     }
//   };
//   const handleIconClose = () => {
//     setOpen(false);
//     // reset();
//   };
//   const handleEditClose = (status) => {
//     if (status === 200) {
//       setEditOpen(false);
//     }
//   };
//   const handleEditIconClose = () => {
//     setEditOpen(false);
//     resetModal();
//   };
//   useEffect(() => {
//     // Add the 'no-scroll' class to the body when the component mounts
//     document.body.classList.add('no-scroll');

//     // Remove the 'no-scroll' class from the body when the component unmounts
//     return () => {
//       document.body.classList.remove('no-scroll');
//     };
//   }, []);
//   const getDisussionDetails = async () => {
//     setIsLoading(true)
//     try {
//       const storedToken = token;

//       console.log(storedToken);

//       const response = await axios.get(`${BASE_URL}${ALL_DISCUSSION}${roles}`, {
//         headers: {
//           Authorization: `Bearer ${storedToken}`,
//         },
//       });
//       console.log(response);

//       if (response.status === 200) {
//         const data = response.data;
//         // setTemplateDetails(data);
//         setIsLoading(false)
//         setDiscussionData(data)
//         setIsLoading(false);
//       } else {
//         throw new Error("Failed to fetch data");
//       }
//     } catch (error) {
//       // Handle error
//       console.error("Error fetching data:", error);
//     }
//   };

//   const handleTopicChange = (event) => {
//     setTopic(event.target.value)
//   }
//   const handleDescriptionChange = (event) => {
//     setDescription(event.target.value)
//   }

//   const handleDeleteClose = () => {
//     setDeleteOpen(false);
//   };
//   useEffect(() => {
//     getDisussionDetails();
//   }, []);
//   const onSubmit = (data) => {
//     setIsLoading(true);
//     const headers = {
//       Authorization: `Bearer ${token}`,
//     };
//     const topic = data.topic ? data.topic.trim() : '';
//     const description = data.description ? data.description.trim() : '';
//     const postData = {
//       topic: topic,
//       description: description,
//       role: roles[0],
//     };

//     if (!postData.topic) {
//       setError("topic", {
//         type: "manual",
//         message: TOPIC_REQUIRED_MESSAGE,
//       });
//       setIsLoading(false);
//       return;
//     }
//     if (!postData.description) {
//       setError("description", {
//         type: "manual",
//         message: DESCRIPTION_REQUIRED_MESSAGE,
//       });
//       setIsLoading(false);
//       return;
//     }
//     request({ url: DISCUSSION, method: "post", data: postData, headers })
//       .then((res) => {
//         console.log(res);
//         if (res.status == 200) {
//           console.log("Success");
//           getDisussionDetails();
//           setIsLoading(false);
//           handleAddClose(res.status);
//           reset();
//         }
//       })
//       .catch((error) => {
//         console.log(error.response);
//         if (error.response) {
//           setIsLoading(false);
//           console.log("error.response.status" + error.response.status);
//         }
//       });
//   };

//   const submitEdit = () => {
//     setIsLoading(true);
//     const trimmedTopic = topic ? topic.trim() : '';
//     const trimmedDescription = description ? description.trim() : '';

//     const putData = {

//       topic: trimmedTopic.trim(),
//       description: trimmedDescription.trim(),
//       role: roles[0]
//     };

//     if (!trimmedTopic) {
//       setErrorModal("topic", {
//         type: "manual",
//         message: TOPIC_REQUIRED_MESSAGE,
//       });
//       setIsLoading(false);
//       return;
//     }
//     if (!trimmedDescription) {
//       setErrorModal("description", {
//         type: "manual",
//         message: DESCRIPTION_REQUIRED_MESSAGE,
//       });
//       setIsLoading(false);
//       return;
//     }


//     request({ url: `${DISCUSSION}/${selectedDiscussionId}`, method: "put", data: putData })
//       .then((res) => {
//         setIsLoading(false);
//         console.log(res);
//         if (res.status == 200) {
//           setIsLoading(false);
//           //   getTemplateDetails();
//           getDisussionDetails();
//           getDisussionDetailsById()
//           handleEditClose(res.status);
//           reset();
//         }
//       })
//       .catch((error) => {
//         console.log(error.response);
//         setIsLoading(false);
//         // if (error.response) {
//         //   console.log("error.response.status" + error.response.status);
//         // }
//         if (error.response && error.response.data) {
//           const message = error.response.data.message;
//           toast.error(message, { position: "top-center" });
//           console.log("error.response.status" + error.response.status);
//         } else {
//           console.error(error); // Log the entire error for further debugging
//           toast.error("An error occurred while processing your request.");
//         }
//       });
//   };
//   const onSubmitDelete = () => {
//     // return;
//     request({ url: `${DISCUSSION}/${selectedDiscussionDeleteId}`, method: "delete" })
//       .then((res) => {
//         console.log(res);
//         if (res.status === 200) {
//           setEditCommentsMessage('')
//           // getTemplateDetails();
//           getDisussionDetails();

//           getDisussionDetailsById();
//           getDisussionDetailsByIdComments();
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };


//   // State to store the ID of the selected discussion

//   // Function to handle opening the modal and setting the selected discussion ID
//   const handleEdit = (discussionId) => {
//     console.log("discussionId", discussionId);
//     setEditOpen(true)
//     setSelectedDiscussionId(discussionId);

//     // Call a function to fetch details of the selected discussion using its ID

//     // Open your edit modal here
//   }
//   const handleDelete = (discussionId) => {
//     console.log("discussionId", discussionId);
//     setDeleteOpen(true)
//     setSelectedDiscussionDeleteId(discussionId);

//     // Call a function to fetch details of the selected discussion using its ID

//     // Open your edit modal here
//   }
//   const addActionButton = (
//     <CustomButton fieldType="submit" buttonName="Add" click={handleAddClose}>
//     </CustomButton>
//   );
//   const addModel = (
//     <>
//       <form
//         onSubmit={handleSubmit(onSubmit)}
//         className="space-y-4 md:space-y-6 "
//       >
//         <DialogContent dividers>
//           <Typography gutterBottom>
//             <CustomInputField
//               fieldName="topic"
//               // requiredErrorMessage={TOPIC_REQUIRED_MESSAGE}
//               // fieldPattern={ONLY_ALPHABETIC_WITH_WHITE_SPACE}
//               // patternErrorMessage={ALPHABETS_ARE_ALLOWED}
//               fieldId="topic"
//               minLength={2}
//               minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
//               maxLength={250}
//               maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
//               fieldLabel="Topic"
//               fieldControl={control}
//               fieldError={errors}
//               fieldRequiredIcon="*"
//               multiline={true}
//               numberOfRows={2}
//             />

//             <CustomInputField
//               fieldName="description"
//               // requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
//               fieldId="description"
//               minLength={2}
//               minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
//               maxLength={1000}
//               maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
//               fieldLabel="Description"
//               fieldControl={control}
//               fieldError={errors}
//               fieldRequiredIcon="*"
//               multiline={true}
//               numberOfRows={2}
//             />


//           </Typography>
//         </DialogContent>
//         <DialogActions>{addActionButton}</DialogActions>
//       </form>
//     </>
//   );
//   const getDisussionDetailsById = async () => {
//     try {
//       const storedToken = token;

//       console.log(storedToken);

//       const response = await axios.get(`${BASE_URL}${DISCUSSION}/${selectedDiscussionId}`, {
//         headers: {
//           Authorization: `Bearer ${storedToken}`,
//         },
//       });
//       console.log(response);

//       if (response.status === 200) {
//         const data = response.data;
//         // setTemplateDetails(data);
//         setTopic(data.topic)
//         setDescription(data.description);
//         setIsLoading(false);
//       } else {
//         throw new Error("Failed to fetch data");
//       }
//     } catch (error) {
//       // Handle error
//       console.error("Error fetching data:", error);
//     }
//   };
//   useEffect(() => {
//     getDisussionDetailsById();
//   }, [selectedDiscussionId, selectedDiscussionDeleteId]);

//   // const [createdBy,setCreatedBy]=useState('')
//   const [userLoginId, setUserLoginId] = useState(null)
//   const handleMessageChange = (event) => {
//     setCommentMessage(event.target.value)
//     setIsEditing(false);
//     setShowSendIcon(event.target.value.trim().length > 0);
//   }

//   const handleKeyDown = (event) => {
//     if (event.key === 'Enter') {
//       isEditing ? handleEditMessage(event) : handleAddMessage(event);
//     }

//   };
//   useEffect(() => {
//     // Automatically select the first topic when the component mounts
//     if (discussionData.length > 0) {
//       const initialTopic = discussionData[0];
//       handleTopicClick(initialTopic.topic, initialTopic.description, initialTopic.discussionId, initialTopic.createdDateAndTime);
//     }
//   }, [discussionData]);
//   const handleTopicClick = (topic, description, discussionId, createdDateAndTime, createdBy) => {
//     setSelectedTopic(topic);
//     setSelectedDescription(description);
//     setCommentsDiscussionId(discussionId);
//     setCreatedDateAndTimes(createdDateAndTime);
//     setIsEditing(false);
//     // setCreatedBy(createdBy.trim())

//   };
//   const formattedDate = createdDateAndTimes ? moment(createdDateAndTimes).format('MM/DD/YYYY HH:mm A') : ""; const [replies, setReplies] = useState([])
//   const getDisussionDetailsByIdComments = async () => {
//     try {
//       const storedToken = token;
//       const userLogin = userLoginDetails.homeOwnerLoginId
//       setUserLoginId(userLogin);
//       console.log("UserDetailsOf", userLoginDetails.homeOwnerLoginId);
//       console.log(storedToken);

//       const response = await axios.get(`${BASE_URL}${DISCUSSION_COMMENTS}/${commentsDiscussionId}`, {
//         headers: {
//           Authorization: `Bearer ${storedToken}`,
//         },
//       });
//       console.log(response);

//       if (response.status === 200) {
//         const data = response.data;
//         // setTemplateDetails(data);
//         // setTopic(data.topic)
//         setReplies(data)
//         setIsLoading(false);
//       } else {
//         throw new Error("Failed to fetch data");
//       }
//     } catch (error) {
//       // Handle error
//       console.error("Error fetching data:", error);
//     }
//   };
//   useEffect(() => {
//     getDisussionDetailsByIdComments()
//   }, [commentsDiscussionId])
//   const handleAddMessage = async (e) => {
//     const trimmedMessage = commentMessage.trim();
//     if (!trimmedMessage) {
//       // Optionally, show some feedback to the user that the message cannot be empty.
//       return; // Exit the function if the message is empty or whitespace.
//     }
//     setIsEditing(false);
//     e.preventDefault();
//     const payload = {
//       role: roles[0],
//       discussionId: commentsDiscussionId,
//       comments: commentMessage.trim()
//     };

//     try {
//       const response = await axios.post(`${BASE_URL}${DISCUSSION_COMMENTS}`, payload, {
//         headers: {
//           Authorization: `Bearer ${token}`
//         }
//       });
//       if (response.status == 200) {
//         // showSendIcon(false);
//         setCommentMessage('');
//         setShowSendIcon(false);

//         getDisussionDetailsByIdComments();
//         getDisussionDetailsById();
//       }
//       // console.log('Message added successfully:', response.data);
//       // Optionally, you can handle any success actions here
//     } catch (error) {
//       console.error('Error adding message:', error);
//       // Optionally, you can handle error scenarios here
//     }
//   };

//   const handleEditMessageChange = (event) => {
//     setEditCommentsMessage(event.target.value);

//   };
//   const handleEditClick = (item, index) => {
//     // Set the comment message state to the edited comment
//     // setCommentMessage(item.comments);
//     console.log("Clicked item:", item);
//     handleClose(index);
//     setIsEditing(true)
//     // setAnchorEl(e.currentTarget)
//     setEditCommentsMessage(item.comments)

//     console.log("editedComments",);
//     setDiscussionIdEdit(item.discussionCommentsId)
//     // Close the menu

//   };
//   const handleDeleteClick = async (item, index) => {
//     // Set the comment message state to the edited comment
//     // setCommentMessage(item.comments);
//     console.log("Clicked item:", item);
//     handleClose(index);

//     // setIsEditing(true)
//     // setAnchorEl(e.currentTarget)
//     // setEditCommentsMessage(item.comments)
//     // setOpen(true)
//     console.log("editedComments",);
//     // setDiscussionIdDelete(item.discussionCommentsId)
//     // Close the menu
//     // handleClose();
//     // e.preventDefault();
//     const payload = {
//       role: roles[0],
//       // discussionId: commentsDiscussionId,
//       // comments: commentMessage
//     };
//     console.log("token", token);

//     try {
//       const response = await axios.delete(`${BASE_URL}${DISCUSSION_COMMENTS}/${item.discussionCommentsId}`, {

//         headers: {
//           Authorization: `Bearer ${token}`
//         }
//       });
//       if (response.status == 200) {
//         // showSendIcon(false);
//         setCommentMessage('');
//         setEditCommentsMessage('');
//         setShowSendIcon(false);
//         setIsEditing(false)
//         getDisussionDetails()
//         getDisussionDetailsByIdComments();
//         getDisussionDetailsById()
//       }
//       // console.log('Message added successfully:', response.data);
//       // Optionally, you can handle any success actions here
//     } catch (error) {
//       console.error('Error adding message:', error);
//       // Optionally, you can handle error scenarios here
//     }
//   };
//   // const getDisussionDetailsByIdCommentsReply = async () => {
//   //   try {
//   //     const storedToken = token;
//   // const userLogin=userLoginDetails.homeOwnerLoginId
//   // setUserLoginId(userLogin);
//   // console.log("UserDetailsOf",userLoginDetails.homeOwnerLoginId);
//   //     console.log(storedToken);

//   //     const response = await axios.get(`${BASE_URL}${DISCUSSION_COMMENTS}/${discussionIdEdit}`, {
//   //       headers: {
//   //         Authorization: `Bearer ${storedToken}`,
//   //       },
//   //     });
//   //     console.log(response);

//   //     if (response.status === 200) {
//   //       const data = response.data;
//   //       // setTemplateDetails(data);
//   //       // setTopic(data.topic)
//   //       setReplies(data)
//   //       setIsLoading(false);
//   //     } else {
//   //       throw new Error("Failed to fetch data");
//   //     }
//   //   } catch (error) {
//   //     // Handle error
//   //     console.error("Error fetching data:", error);
//   //   }
//   // };
//   // useEffect(()=>{
//   //   getDisussionDetailsByIdCommentsReply()
//   // },[discussionIdEdit])
//   const handleEditMessage = async (e) => {
//     const trimmedMessage = editCommentsMessage.trim();
//     if (!trimmedMessage) {
//       // Optionally, show some feedback to the user that the message cannot be empty.
//       return; // Exit the function if the message is empty or whitespace.
//     }
//     e.preventDefault()
//     const payload = {
//       role: roles[0],
//       discussionId: commentsDiscussionId,
//       comments: editCommentsMessage.trim()
//     };

//     try {
//       const response = await axios.put(`${BASE_URL}${DISCUSSION_COMMENTS}/${discussionIdEdit}`, payload, {
//         headers: {
//           Authorization: `Bearer ${token}`
//         }
//       });
//       if (response.status == 200) {
//         // showSendIcon(false);
//         getDisussionDetailsByIdComments();
//         getDisussionDetailsById()
//         setShowSendIcon(false);
//         setIsEditing(false)
//         setEditCommentsMessage('');
//         commentMessage('');

//         // reset();
//       }
//       // console.log('Message added successfully:', response.data);
//       // Optionally, you can handle any success actions here
//     } catch (error) {
//       if (error.response && error.response.data) {
//         const message = error.response.data.message;
//         toast.error(message, { position: "top-center" })
//         getDisussionDetailsByIdComments();
//         getDisussionDetailsById()

//         setEditCommentsMessage('');
//         reset();
//         console.log("error.response.status" + error.response.status);
//       } else {
//         console.error(error); // Log the entire error for further debugging
//         // toast.error("An error occurred while processing your request.");
//       }    // Optionally, you can handle error scenarios here
//     }
//   };
//   const editActionButton = (
//     <CustomButton
//       fieldType="submit"
//       buttonName="Update"
//       click={handleEditClose}
//     />
//   );

//   const editModel = (
//     <>
//       <form
//         onSubmit={handleSubmitModel(submitEdit)}
//         className="space-y-4 md:space-y-6 "
//       >
//         <DialogContent dividers>
//           <Typography gutterBottom>
//             <CustomInputField
//               fieldName="topic"
//               //   requiredErrorMessage={TOPIC_REQUIRED_MESSAGE}
//               // fieldPattern={ONLY_ALPHABETIC_WITH_WHITE_SPACE}
//               // patternErrorMessage={ALPHABETS_ARE_ALLOWED}
//               fieldId="topic"
//               minLength={2}
//               minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
//               maxLength={250}
//               maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
//               fieldLabel="Topic"
//               fieldControl={controlModal}
//               fieldError={errorsModal}
//               fieldRequiredIcon="*"
//               multiline={true}
//               numberOfRows={2}
//               value={topic}
//               onInput={handleTopicChange}
//             />

//             <CustomInputField
//               fieldName="description"
//               //   requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
//               fieldId="description"
//               minLength={2}
//               minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
//               maxLength={1000}
//               maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
//               fieldLabel="Description"
//               fieldControl={controlModal}
//               fieldError={errorsModal}
//               fieldRequiredIcon="*"
//               multiline={true}
//               numberOfRows={2}
//               value={description}
//               onInput={handleDescriptionChange}
//             />


//           </Typography>
//         </DialogContent>
//         <DialogActions>{editActionButton}</DialogActions>
//       </form>
//     </>
//   );

//   const okCancelButtonActions = (
//     <>

//       <CustomButton
//         fieldType="button"
//         buttonName="No"
//         click={handleDeleteClose}
//       />
//       <CustomButton
//         fieldType="submit"
//         buttonName="Yes"
//         click={handleDeleteClose}
//       />
//     </>
//   );

//   const deleteModel = (
//     <form
//       onSubmit={handleSubmit(onSubmitDelete)}
//       className="space-y-4 md:space-y-6 "
//     >
//       <DialogContent dividers>
//         <Typography>
//           {" "}
//           Are you sure  to delete this topic ?
//         </Typography>
//       </DialogContent>
//       <DialogActions>{okCancelButtonActions}</DialogActions>
//     </form>
//   );
//   const [expanded, setExpanded] = useState(false);
//   const [expandeds, setExpandeds] = useState(false)
//   const handleExpandClick = () => {
//     setExpanded(!expanded);
//   };
//   const handleExpandClicks = () => {
//     setExpandeds(!expandeds);
//   };
//   const [anchorElArray, setAnchorElArray] = useState(Array(replies.length).fill(null));

//   const handleMenuClick = (event, index) => {

//     const newAnchorElArray = [...anchorElArray];
//     newAnchorElArray[index] = event.currentTarget;
//     setAnchorElArray(newAnchorElArray);
//   };

//   const handleClose = (index) => {
//     const newAnchorElArray = [...anchorElArray];
//     newAnchorElArray[index] = null;
//     setAnchorElArray(newAnchorElArray);
//   };

//   const [searchTerm, setSearchTerm] = useState('');
//   return (

//     <div class="" style={{ height: "", overflow: "", marginTop: "" }} >
//       <CustomHeading title="Discussion Board" />
//       <CustomToastContainer />
//       <div class="flex min-h-screen w-full bg-primary-bg">
//         <div class="max-w-[50%] max-h-[500px] flex flex-col border-r border-r-slate-400" style={{}}>

//           <div class="relative flex items-center px-1">
//             {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="absolute ml-2 h-5 w-5 text-slate-400">
//         <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
//       </svg> */}
//             <input type="text" placeholder="Search" class="w-full rounded-md border border-gray-300  py-2 pl-9 pr-3 text-slate-900 focus:outline-none" onChange={(e) => setSearchTerm(e.target.value)} />
//           </div>

//           {/* <div class="mt-1 flex gap-2 overflow-hidden">
//      </div> */}
//           {/* <div class="mt-1 flex items-center"  style={{ backgroundColor: "red" }}> */}
//           <div class=" flex  justify-between px-2 py-2" >
//             <div>
//               <p class="font-small mr-auto">Add Topic</p> {/* "mr-auto" pushes this to the left */}
//             </div>
//             <div class="pr-2">

//               <AddIcon style={{ fontSize: 20, color: 'gray' }} type="submit" onClick={handleAddClickOpen} />

//             </div>
//           </div>
//           {/* </div> */}
//           <Divider style={{}} />
//           {/* <!meesages start/> */}
//           <div className="mt-1 flex flex-col gap-7 overflow-y-auto" style={{ maxHeight: 'calc(3 * (146px + 2 * 2px) + 2 * 2px)' }}>
//             <div className="px-3 py-2 justify-center items-center">
//               {discussionData
//                 .filter(item => item.topic.toLowerCase().includes(searchTerm.toLowerCase()))
//                 .length > 0 ? (
//                 discussionData
//                   .filter(item => item.topic.toLowerCase().includes(searchTerm.toLowerCase()))
//                   .map((item, index) => (
//                     <div
//                       key={item.discussionId}
//                       className={`flex items-center gap-2 rounded-md px-2 py-1 transition-colors duration-300 ${selectedTopic === item.topic ? '' : 'hover:bg-light'
//                         }`}
//                       style={{ backgroundColor: selectedTopic === item.topic ? '#045F9F' : undefined }}
//                       onClick={() => handleTopicClick(item.topic, item.description, item.discussionId, item.createdDateAndTime, item.createdBy)}
//                     >
//                       <div className="overflow-hidden text-left flex-grow">
//                         <div className="flex justify-between">
//                           <p className={`flex truncate text-sm font-medium ${selectedTopic === item.topic ? 'text-white' : 'text-black'
//                             }`}>
//                             {item.topic.length > 30 ? `${item.topic.substring(0, 20)}...` : item.topic}
//                           </p>
//                           {/* <p className="{`truncate text-sm font-medium ${
//     selectedTopic === item.topic ? 'text-white' : 'text-black'
//   }`}">
//                   </p> */}
//                         </div>
//                         <p className="flex truncate text-sm text-slate-400">
//                           {item.description.length > 15 ? `${item.description.substring(0, 10)}...` : item.description}
//                           {userLoginId === item.createdBy && (
//                             <span className="ml-auto flex gap-1">
//                               <EditTwoTone className={`flex truncate text-sm font-medium ${selectedTopic === item.topic ? 'text-white' : 'text-grey'
//                                 }`} onClick={(e) => {
//                                   e.stopPropagation();
//                                   handleEdit(item.discussionId);
//                                 }} />
//                               <DeleteTwoTone className={`flex truncate text-sm font-medium ${selectedTopic === item.topic ? 'text-white' : 'text-grey'
//                                 }`} onClick={(e) => {
//                                   e.stopPropagation();
//                                   handleDelete(item.discussionId);
//                                 }} />
//                             </span>
//                           )}
//                         </p>
//                         <p className={`flex truncate text-sm font-medium ${selectedTopic === item.topic ? 'text-white' : 'text-grey'
//                           }`}>                    {moment(item.createdDateAndTime).format('MM/DD/YYYY HH:mm A')}
//                         </p>
//                         <Divider className="mt-1 px-2 py-2" />
//                       </div>
//                     </div>
//                   ))
//               ) : (
//                 <p>No Data Found</p>
//               )
//               }
//             </div>
//           </div>

//           {/* <!meesages End/> */}
//         </div>
//         {/* <!Middle start/> */}

//         <div class="w-full max-h-fullscreen flex flex-col mt-none">
//         <div className="flex flex-col overscroll-contain flex-1 overflow-y-scroll overflow-x-hidden px-2 max-h-screen">

//           {discussionData.length > 0 && (
//             <div class="flex ">
//               {/* <div class="h-[42px] w-[42px] shrink-0 rounded-full">
//         <img src="https://picsum.photos/750/740" class="h-full w-full rounded-full object-cover" alt="" />
//       </div> */}

//               <div class="ml-2">
//                 {/* <h2 class="text-base text-blue-300">{selectedTopic}</h2>

//       <h2 class="text-base text-black-200">{selectedDescription}</h2> */}
//                 <h6 class="text-sm font-bold">Topic :</h6>
//                 <h2 className="text-base text-blue-300">
//                   {selectedTopic?.length > 30 ? selectedTopic.substring(0, 30) : selectedTopic}
//                   {selectedTopic?.length > 30 && (
//                     <IconButton onClick={handleExpandClick}>
//                       <ExpandMoreIcon />
//                     </IconButton>
//                   )}
//                 </h2>
//                 <Collapse in={expanded} timeout="auto" unmountOnExit>
//                   <h2 className="text-base text-blue-300 break-all">
//                     {selectedTopic?.length > 30 && selectedTopic}
//                   </h2>
//                 </Collapse>
//                 <h6 class="text-sm font-bold">Description :</h6>

//                 <h4 className="text-base text-black-200 break-all">
//                   {selectedDescription?.length > 60 ? selectedDescription.substring(0, 30) : selectedDescription}
//                   {selectedDescription?.length > 60 && (
//                     <IconButton onClick={handleExpandClicks}>
//                       <ExpandMoreIcon />
//                     </IconButton>
//                   )}
//                 </h4>
//                 <Collapse in={expandeds} timeout="auto" unmountOnExit>
//                   <h5 className="text-base text-black-200 break-all">
//                     {selectedDescription?.length > 30 && selectedDescription}
//                   </h5>
//                 </Collapse>

//                 <p class="text-xs text-slate-400">{formattedDate}</p>
//               </div>

//             </div>
//           )}
//           <div class="border-b border-b-slate-700"></div>
//           {/* <div class="flex flex-col gap-2 px-2"> */}




//             {replies.map((item, index) => (
//               <div key={index} className={` flex  gap-2 ml-${userLoginId === item.createdBy ? 'auto' : '0'} ${userLoginId === item.createdBy ? 'text-left' : 'text-left'}  ${userLoginId === item.createdBy ? 'flex-row' : 'flex-row'}`}>
//                 {/* {userLoginId === item.createdDateAndTime ? 'text-right':'text-left'} */}

//                 {/* {userLoginId === item.createdBy && <span class="text-blue-300 text-base">@you</span>} */}
//                 {/* {userLoginId !== item.createdBy && <span class="text-blue-300 text-base">@XYZ</span>} */}
//                 <div className={`flex flex-col  ${userLoginId === item.createdBy ? 'justify-end' : 'justify-start'}`}>
//                   <div class="m-2">
//                     {userLoginId !== item.createdBy ? (
//                       <div class="flex flex-wrap">

//                         <div className={`px-1 py-1 h-[30px] w-[30px] rounded-full flex items-center justify-center`} style={{ backgroundColor: "#045F9F" }}>
//                           <span className="text-white font-bold text-xs">{item.createdByDetails.firstName.charAt(0)}</span>
//                         </div>
//                         <div class="">
//                           <span className="ml-2  text-white font-bold text-xs" style={{ color: "#045F9F" }}>{item.createdByDetails.firstName}</span>
//                         </div>
//                         <div class="ml-2">

//                           <span className={` text-xs ${userLoginId === item.createdBy ? 'text-right' : 'text-left'}`} style={{ color: userLoginId === item.createdBy ? 'grey' : 'grey' }}>
//                             {moment(item.createdDateAndTime).format('MM-DD-YYYY HH:mm A')}
//                           </span>
//                           <span class="mr-1">
//                             {item.isEdited ? <span className='text-xs' style={{ color: 'blue', marginLeft: '5px' }}>Edited</span> : null}

//                           </span>

//                         </div>
//                       </div>
//                     ) :
//                       (
//                         <div class="flex flex-wrap">



//                           <div className={`px-1 py-1 h-[30px] w-[30px] rounded-full flex items-center justify-center`} style={{ backgroundColor: "#045F9F" }}>
//                             <span className="text-white font-bold text-xs">{item.createdByDetails.firstName.charAt(0)}</span>
//                           </div>
//                           <span className=" ml-2 text-white font-bold text-xs" style={{ color: "#045F9F" }}>{item.createdByDetails.firstName}</span>

//                           <span className=" ml-2 text-xs text-right" style={{ color: userLoginId === item.createdBy ? 'grey' : 'grey' }}>
//                             {moment(item.createdDateAndTime).format('MM-DD-YYYY HH:mm A')}
//                           </span>

//                           {item.isEdited ? <span className='text-xs' style={{ color: 'blue', marginLeft: '5px' }}>Edited</span> : null}


//                         </div>

//                       )}
//                   </div>
//                   <div className={`flex w-full  p-2 ${userLoginId === item.createdBy ? 'justify-start' : 'justify-start'} items-center`}>

//                     <div className="flex flex-wrap w-full ">
//                       <div>
//                         <p
//                           className="p-2 ml-7  break-all  text-balance rounded-md text-start text-sm sm:text-sm md:text-md lg:text-lg "
//                           style={{
//                             color: 'black', // Text color is always black
//                             backgroundColor: isHovering === index ? 'grey' : (userLoginId === item.createdBy ? '' : '')
//                           }}
//                           onMouseEnter={() => setIsHovering(index)}
//                           onMouseLeave={() => setIsHovering(null)}
//                         >
//                           {item.comments}
//                         </p>
//                       </div>
//                     </div>

//                     {userLoginId === item.createdBy && (
//                       <IconButton aria-label="more" aria-controls={`menu-${index}`} aria-haspopup="true" onClick={(event) => handleMenuClick(event, index)}>
//                         <MoreVertIcon />
//                       </IconButton>

//                     )}
//                   </div>

//                 </div>

//                 <Menu
//                   id={`menu-${index}`}
//                   anchorEl={anchorElArray[index]}
//                   open={Boolean(anchorElArray[index])}
//                   onClose={() => handleClose(index)}
//                 >
//                   <MenuItem onClick={() => handleEditClick(item, index)}>Edit</MenuItem>
//                   <MenuItem onClick={() => handleDeleteClick(item, index)}>Delete</MenuItem>
//                 </Menu>
//                 {/* {userLoginId === item.createdBy && (
//   <span style={{ color: userLoginId === item.createdBy ? 'green' : 'red' }}>
//     {moment(item.createdDateAndTime).format('YYYY-MM-DD HH:mm:ss')}
//   </span>
// )} */}




//               </div>
//             ))}


//           </div>

//           {/* flex fixed bottom-0  m-2 "style={{ width: 'calc(60% - 50px)' }} */}
//           {discussionData.length > 0 && (

//             <div className="flex  flex-1">
//               <div className=" ml-2 mr-2 w-full">
//                 <TextField
//                   id="outlined-multiline-flexible"
//                   label="Message"
//                   multiline
//                   maxRows={2}
//                   value={isEditing ? editCommentsMessage : commentMessage}
//                   onChange={isEditing ? handleEditMessageChange : handleMessageChange}
//                   onKeyDown={handleKeyDown}
//                   variant="outlined"
//                   fullWidth
//                 />
//               </div>
//               <div className="mt-0">
//                 <SendIcon
//                   style={{ fontSize: 45, color: '#045F9F', cursor: isEditing || commentMessage ? 'pointer' : 'not-allowed' }}
//                   onClick={isEditing ? handleEditMessage : commentMessage ? handleAddMessage : null}
//                 />
//               </div>
//             </div>

//           )}

//           {/* </div> */}

//         </div>

//         {/* <!middle end/> */}
//       </div>

//       <CustomModel
//         title="Add"
//         submit={onSubmit}
//         content={addModel}
//         action={addActionButton}
//         openStatus={open}
//         closeStatus={handleAddClose}
//         iconCloseStatus={handleIconClose}
//         reset={reset}
//       />
//       <CustomModel
//         title="Edit"
//         submit={submitEdit}
//         content={editModel}
//         action={editActionButton}
//         openStatus={editOpen}
//         closeStatus={handleEditClose}
//         iconCloseStatus={handleEditIconClose}
//         reset={resetModal}
//       />
//       <CustomModel
//         title=" Delete Discussion Board"
//         submit={onSubmitDelete}
//         content={deleteModel}
//         action={okCancelButtonActions}
//         openStatus={deleteOpen}
//         closeStatus={handleDeleteClose}
//         iconCloseStatus={handleDeleteClose}
//         reset={reset}
//         modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
//       />
//       {isLoading && <CustomLoading />}
//     </div>

//   )
// }

// export default Discussionboard