import React, { useState, useEffect } from 'react';
import { Tooltip, IconButton, Typography, List, ListItem, FormLabel } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from '@mui/icons-material/Clear';
import axios from "axios";
import { BASE_URL_IMAGE } from '../utills/ApplicationRouting';

function CustomFileInvoiceAdd({ handleFileChange, token, fileError, file = [], downloadFiles = false, required, isRequired = false }) {
  const fileTypes = ["jpg", "jpeg", "pdf", "doc", "docx", "xls", "xlsx", "tif", "tiff"];
  const [files, setFiles] = useState(file);

  useEffect(() => {
    setFiles(file);
  }, [file]);

  const handleFileChangeInternal = (newFiles) => {
    const updatedFiles = [...files, ...newFiles];
    setFiles(updatedFiles);
    handleFileChange(updatedFiles); // Pass the updated file list to the parent component
  };

  const removeFile = (fileToRemove) => {
    const updatedFiles = files.filter(f => f !== fileToRemove);
    setFiles(updatedFiles);
    handleFileChange(updatedFiles);
  };

  const handleFileDataClick = async (fileData, token) => {
    if (!fileData) return; // If no file is selected, do nothing

    const getContentType = (fileName) => {
      const extension = fileName.split(".").pop().toLowerCase();

      switch (extension) {
        case "pdf":
          return "application/pdf";
        case "jpg":
        case "jpeg":
          return "image/jpeg";
        case "png":
          return "image/png";
        case "csv":
          return "text/csv";
        case "doc":
          return "application/msword";
        case "docx":
          return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        case "xls":
          return "application/vnd.ms-excel";
        case "xlsx":
          return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        case "tif":
        case "tiff":
          return "image/tiff";
        default:
          return "application/octet-stream";
      }
    };

    try {
      const response = await axios.get(`${BASE_URL_IMAGE}/${fileData}`, {
        responseType: "blob",
      });

      const contentType = getContentType(fileData);
      const fileBlob = new Blob([response.data], { type: contentType });

      // Preview if supported (for images and PDFs)
      if (contentType.startsWith("image/") || contentType === "application/pdf") {
        displayPreview(fileBlob, fileData);
      } else {
        // Download for other file types
        downloadFile(fileBlob, fileData);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const displayPreview = (fileBlob, fileName) => {
    const filePreview = URL.createObjectURL(fileBlob);
    window.open(filePreview);
  };

  const downloadFile = (fileBlob, fileName) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(fileBlob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <FormLabel>
        Attach Documents{" "}
        {isRequired && <span className='text-red-500'>*</span>}
        {files?.length > 0 && (
          <div className='w-1/2'>
            <List>
              {files.map((file, index) => (
                <ListItem key={index}>
                  <Typography>{file.name}</Typography>
                  {file.size > 10 * 1024 * 1024 && <span className="text-red-600 text-sm ml-5">Attachment size cannot exceed 10 MB</span>}
                  {!fileTypes.includes(file.name.split('.').pop().toLowerCase()) && <span className="text-red-600 text-sm ml-5">Please provide a valid document</span>}
                  <IconButton>
                    <ClearIcon sx={{ color: "red" }} onClick={(event) => { event.preventDefault(); removeFile(file); }} />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </div>
        )}

        <FileUploader
          handleChange={handleFileChangeInternal}
          name="file"
          multiple
        //   types={fileTypes}
        
        />
        <p className='text-sm'>Allowed file types: {fileTypes.join(", ").toUpperCase()}</p>
        {fileError && <p className="text-red-600 text-sm">{fileError}</p>}
      </FormLabel>
    </div>
  );
}

export default CustomFileInvoiceAdd;
