import React, { useEffect, useMemo, useState } from "react";
import CustomHeading from "../../components/CustomHeading";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import { useNavigate } from "react-router-dom";
import { ListAlt } from "@mui/icons-material";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import { BASE_URL, HO_URL_EVENT } from "../../utills/ApplicationRouting";
import CustomMaterialWithoutActons from "../../components/CustomMaterialWithoutActons";
import CustomLoading from "../../components/CustomLoading";

function EventList() {
  const navigate = useNavigate();
  const [eventDetails,setEventDetails] = useState([]);
  const [isLoading,setIsLoading]=useState(true);
  const { token } = useSelector(state => state.hybridhoa);
  const handleViewClick = (selectedRow)=>{
      navigate (`/event/${selectedRow.row.original.eventId}`);
  }


  const getEventDetails = async () => {
    try {
      setIsLoading(true);
          const storedToken = token

      console.log(storedToken);
      
        const response= await axios.get(`${BASE_URL}${HO_URL_EVENT}`,{
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
              console.log(response.data); 

      if (response.status === 200) {
        const data = response.data;
        setEventDetails(data);
        setIsLoading(false);
      

      } else {
        throw new Error('Failed to fetch data');
      }
     
    } catch (error) {
      // Handle error
      setIsLoading(true);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(()=>{
    getEventDetails();
  },[])
    
    
      const eventTable = useMemo((rowData) => [
        {
          accessorKey: "serialNo",
          header: "#",
          size: 20,
          Cell:({row})=>row.index+1
        },
        {
          accessorKey: "topic",
          header: "Topic Of Event",
          accessorFn: (row) => {
            const topic = row && row.topic;
            if (topic && topic.length > 28) {
              return topic.slice(0, 28) + "...";
            }
            return topic;
          }
        },
        {
          accessorKey: "description",
          header: "Description",
          accessorFn: (row) => {
            const description = row && row.description;
            if (description && description.length > 28) {
              return description.slice(0, 28) + "...";
            }
            return description;
          }
        },
          {
            accessorKey: "categoryDetails.category",
            header: "Category",
            Cell: ({ row }) => {
              if (row.original.categoryDetails === null || row.original.categoryDetails === undefined) {
                return "N/A";
              } else {
                return row.original.categoryDetails.category || "N/A";
              }
            },
          },
        {
          accessorKey: "dateAndTime",
          header: "Event Date&Time",
          accessorFn: (row) => moment(row.dateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
        },
        {
          accessorKey: "eventAddress",
          header: "Event Address",
          accessorFn: (row) => {
            const eventAddress = row && row.eventAddress;
            if (eventAddress && eventAddress.length > 28) {
              return eventAddress.slice(0, 28) + "...";
            }
            return eventAddress;
          }
       
        }, 
        // {
        //   accessorKey: "publishDate",
        //   header: "Publishdate",
        //   accessorFn : (row) => {
        //     const publishDate = moment(row.publishDate);
        //     if (publishDate.isValid()) {
        //       return publishDate.format("MM/DD/YYYY");
        //     } else {
        //       return ""; 
        //     }
        //   }
        // },
        // {
        //   accessorKey: "status",
        //   header: "Status",
       
        // },
       
          
      ]);
      // const rowActions = [
      //   {
      //     label: "View",
      //     icon: <ListAlt color="primary" />,
      //     click:(row)=> handleViewClick(row),
      //   },
       
      // ]
   
     
     
      return (
        <div>
          {isLoading && <CustomLoading />}
          <CustomHeading title="Events" />
    
         
          <CustomMaterialWithoutActons
            columns={eventTable}
            data={eventDetails}
            // rowActions={rowActions}
            isLoading={isLoading}
            onRowClick = {handleViewClick}
         />
        </div>
      );
    }

export default EventList