import {  EditNoteOutlined, ListAlt } from "@mui/icons-material";
import React, { useEffect, useMemo, useState } from "react";
import {  useNavigate } from "react-router-dom";
import CustomHeading from "../../../components/CustomHeading";
import CustomMaterialTable from "../../../components/CustomMaterialTable";
import CustomButton from "../../../components/CustomButton";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL, MAINTENANCE_REQUEST_GENERAL_USER } from "../../../utills/ApplicationRouting";
import moment from "moment";
import CustomLoading from "../../../components/CustomLoading";

function MaintenanceRequestListGeneralUser() {
  const { token } = useSelector(state => state.hybridhoa);
  const [maintenanceRequestDetails,setMaintenanceRequestDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 
  const [homeOwnerLoginIdForEdit,setHomeOwnerLoginIdForEdit]=useState([])
    const navigate = useNavigate();
 


    useEffect(() => {
      const persistRoot = JSON.parse(localStorage.getItem("persist:root"));
  
      if (persistRoot && persistRoot.userLoginDetails && typeof persistRoot.userLoginDetails === 'string') {
        const userLoginDetailsObject = JSON.parse(persistRoot.userLoginDetails);
  
        if (userLoginDetailsObject && userLoginDetailsObject.homeOwnerLoginId) {
          const homeOwnerLoginId = userLoginDetailsObject.homeOwnerLoginId;
          console.log('Home Owner Login ID:', homeOwnerLoginId);
  
          setHomeOwnerLoginIdForEdit(homeOwnerLoginId);
        } else {
          console.log('Home Owner Login ID not found in userLoginDetails.');
        }
      }
    }, []);

    const handleViewClick = (selectedRow) => {
      navigate(`/maintenancerequestViewgeneraluser/${selectedRow.row.original.maintenanceId}`);
    };
    

    const handleEditClick = (selectedRow) => {
      navigate(`/maintenancerequestEditgeneraluser/${selectedRow.original.maintenanceId}`);
    };
   const getMaintananceRequestDetails = async () => {
    try {
      setIsLoading(true);
          const storedToken = token

      console.log(storedToken);
      
        const response= await axios.get(`${BASE_URL}${MAINTENANCE_REQUEST_GENERAL_USER}`,{
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
              console.log(response.data); 

      if (response.status === 200) {
        const data = response.data;
        console.log(data.createdBy);
        setMaintenanceRequestDetails(data);
        setIsLoading(false);

      } else {
        setIsLoading(false);
        throw new Error('Failed to fetch data');
      }
     
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };



useEffect(()=>{
  getMaintananceRequestDetails();
},[])



const handleAddClick = () => {
    navigate("/maintenancerequestAddgeneraluser");
  };

 
  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '') return '';
  
    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };
  

  const maintenanceRequestTable = useMemo((rowData) => [
    {
      accessorKey: "mrId",
      header: "Id",
      size: 20,
    },
   
   
   //  {
   //    accessorKey: "category",
   //    header: "Category",
   //    size: 20,
   //    Cell: ({ row }) => {
   //     console.log("row.original", row.original);
   //     const eventCategory = categoryDetails.find((category) => category.categoryId === row.original.category);
   //     console.log("eventCategory", row.original.category);
   //     return eventCategory ? eventCategory.category : 'N/A';
   //   },
   //  }, 
   {
    accessorKey: "jobDetails.occupation",
    header: "Job Category",
    accessorFn: (row) => {
      console.log("row",row);
      
      if (row.jobDetails === null || row.jobDetails === undefined) {
        return "N/A";
      } else {
        return row.jobDetails.occupation || "N/A";
      }
    },
  },
   {
     accessorKey: "topic",
     header: "Description",
     accessorFn: (row) => {
      const description = row.topic?row.topic:"";
      if (description && description.length > 28) {
        return description.slice(0, 28) + "...";
      }
      return description;
    }
   },
  //  {
  //    accessorKey: "createdByDetails.firstName",
  //    header: "Firstname",
  //    accessorFn: (row) => {
  //      console.log("row",row)
  //      return row.createdByDetails.firstName;
  //    }
  //  },
  //  {
  //    accessorKey: "createdByDetails.lastName",
  //    header: "Lastname",
  //    accessorFn: (row) => {
  //      console.log("row",row)
  //      return row.createdByDetails.lastName;
  //    }
  //  },
  //  {
  //    accessorKey: "homeDetails.address",
  //    header: "Address",
  //    accessorFn: (row) => {
  //      console.log("row",row)
  //      return row.homeDetails.address;
  //    }
  //  },
   {
     accessorKey: "status",
     header: "Status",
     Cell: ({ row }) => {
       const status = row.original.status;
       return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
     },
   },
  
          {
      accessorKey: "createdDateAndTime",
      header: "Created Date",
     
       // const formattedDate = moment(value).format("MM/DD/YYYY");
       // return formattedDate;
       accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),

     
    },
    
    
   {
     accessorKey: "isUrgent",
     
     accessorFn: (row) => (row && row.isUrgent ? "Yes" : "No"),
     header: "Is Urgent?",
     size: 20,
   },
  ]);

   const rowActions = (selectedRow) => {
    const actions = [
      // {
      //   label: "View",
      //   icon: <ListAlt color="primary" />,
      //   click: () => handleViewClick(selectedRow),
      // },
    ];
  
    // Check if createdBy is the same as homeOwnerLoginIdForEdit
    if (selectedRow.original.createdBy === homeOwnerLoginIdForEdit && 
      selectedRow.original.status !== "CLOSED" && selectedRow.original.status !== "COMPLETED") {
    actions.push({
      label: "Edit",
      icon: <EditNoteOutlined color="primary" />,
      click: () => handleEditClick(selectedRow),
    });
  }
  
  
    return actions;
  };
 
   const tableActionButtons = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Create"
        click={handleAddClick}
      />
    </>
  );
  
   return (
     <div>
      {isLoading && <CustomLoading/>}
       <CustomHeading title="Maintenance Request" />
 
      
       <CustomMaterialTable
         columns={maintenanceRequestTable}
         data={maintenanceRequestDetails}
         rowActions={rowActions}
         actionButtons={tableActionButtons}
        //  isLoading={isLoading}
         onRowClick = {handleViewClick}
       />
     </div>
   );
 }

export default MaintenanceRequestListGeneralUser;