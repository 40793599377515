// export const BASE_URL="http://10.64.26.139:3032/api/v1";
// export const BASE_URL="http://10.64.16.87:3032/api/v1";

// export const BASE_URL_IMAGE = "http://localhost:3032/api/v1/file";
// export const BASE_URL = "https://apiliveupprod.hybridhoaonline.com/hhoa-user-portal/api/v1";
// export const BASE_URL_IMAGE = "https://apiliveupprod.hybridhoaonline.com/hhoa-user-portal/api/v1/file";
export const BASE_URL = "https://userapi.hybridhoa.com/hhoa-user-portal/api/v1";
export const BASE_URL_IMAGE = "https://userapi.hybridhoa.com/hhoa-user-portal/api/v1/file";


// export const BASE_URL="http://localhost:3032/api/v1";

// export const BASE_URL="http://localhost:3032/api/v1";
// export const BASE_URL="http://10.64.26.139:3032/api/v1";



// export const BASE_URL_IMAGE = "http://localhost:3032/api/v1/file";


// REACT_APP_CARD_URL = "http://localhost:4041/#/subscription?addcard=success"

// export const CARD_URL = "http://localhost:4043/#/paymentmethod?addcard=success"
// export const CARD_URL = "https://userliveprod.hybridhoaonline.com/#/paymentmethod?addcard=success"
export const CARD_URL = "https://user.hybridhoa.com/#/paymentmethod?addcard=success"

export const LOGIN="/auth/adminlogin";
export const FORGOT="/forgot";
export const REQUEST_FOR_ACCESS="/requestforaccess"
export const FORGOT_CHANGE="/forgetpassword/verify"
export const FORGOT_PASSWORD="/forgetpassword";
export const FORGOT_PASSWORD_EMAIL="/forgetpassword/email/";
export const COMPLIANCE_REQUEST = "/complaince/ho";

export const SINGLE_HO = "/complaince/singleho"

export const COMPLIANCE_LINK="/complaince/link"

export const COMPLIANCE_REQUEST_BY_ID = "/complaince";

export const COMPLIANCE_REQUEST_EDIT_BY_ID = "/complaince/edit";

export const RAISED_BY_ARC_CR_REQUEST_HO = "/complaince/ho/arc";

export const RAISED_BY_HO_CR_REQUEST_ARC= "/complaince/arc/ho";

export const ARC_COMPLIANCE_REQUEST = "/complaince/arc";

export const MC_COMPLIANCE_REQUEST = "/complaince/mc";


export const COMPLIANCE_REVIEW="/complaince/review"

export const ADD_MAINTENANCE ="/maintenance"

export const ADD_MAINTENANCE_MC ="/maintenance/mc"

export const EDIT_MAINTENANCE ="/maintenance/"

export const TEMPLATE ="/reporttemplate";

export const MAINTENANCE_REQUEST = "/maintenance/ho";
export const MAINTENANCE_REQUEST_MC = "/maintenance/mc";

export const MAINTENANCE_REQUEST_MC_HO= "/maintenance/mc/ho";


export const MAINTENANCE_REQUEST_BM = "/maintenance/bm/ho";

export const GUMAINTENANCE_REQUEST_LIST= "/maintenance/gu/bm";

export const MAINTENANCE_REVIEW_COMMANENTS="/maintenance/review/"
export const DASHBOARD = "/dashboard";
export const DASHBOARD_ARC = "/arc";
export const DASHBOARD_HO = "/ho";
export const DASHBOARD_BM = "/bm";
export const DASHBOARD_GU = "/gu";
export const DASHBOARD_MC = "/mc";
export const CHART = "/chart";

export const MAINTENANCE_REQUEST_SINGLE = "/maintenance/singleho";


export const RENTER = "/renter";
export const RENTER_HO = "/renter/home";
export const RENTER_MC = "/renter/mc";

export const FAQ = "/faq";

export const DOCUMENT = "/document";

export const INVOICE = "/invoice";


export const REVIEW_INVOICE = "/invoice/review";

export const EVENT = "/event";

export const EVENT_CANCEL = "/event/cancel";

export const NOTIFICATION = "/notification";

export const HO_DOCUMENT = "/document/ho?isArchived=false";

export const PROFILE = "/profile";

export const CHANGE_PASSWORD = "/changepassword";

export const HOMES = "/homes";

export const HOMES_DETAILS = "/homes";

export const CATEGORY = "/category";

export const BYLAW = "/bylaw";

export const HO_URL_NOTIFICATION = "/notification/ho";

export const HO_URL_EVENT = "/event/ho";

export const ARC_EVENT = "/event/arc";

export const MC_EVENT = "/event/mc";

export const BM_EVENT = "/event/bm";

export const GENERAL_COMMENTS = "/generalcomments";

export const GET_ALL_GENERAL_COMMENTS = "/generalcomments/all";

export const GET_ALL_ARC_DOCUMENT = "/document/arc?isArchived=false";

export const GET_ALL_BM_DOCUMENT = "/document/bm?isArchived=false";

export const GET_ALL_MC_DOCUMENT = "/document/mc?isArchived=false";

export const GET_ALL_HO_DOCUMENT = "/document/ho?isArchived=false";

export const GET_ALL_ARCHIVED_HO_DOCUMENT = "/document/ho?isArchived=true";

export const GET_ALL_ARCHIVED_ARC_DOCUMENT = "/document/arc?isArchived=true";

export const GET_ALL_ARCHIVED_BM_DOCUMENT = "/document/bm?isArchived=true";

export const GET_ALL_ARCHIVED_MC_DOCUMENT = "/document/mc?isArchived=true";

export const GET_ALL_HO_NOTIFICATION_ARCHIVED = "/notification/ho?isArchived=true";

export const DOCUMENT_REVIEW_UPDATE = "/document/review";

export const NOTIFICATION_REVIEW_UPDATE = "/notification/decision"

export const EVENT_REVIEW_UPDATE = "/event/decision";

export const REQUEST_REPORT = "/report/crmr";

export const CONTACT_VERIFY_SEND="/contact/send"

export const CONTACT_VERIFY="/contact/verify/" 

export const EMAIL_VERIFY="/verifyEmail/" 

export const REVIEW_MAINTENANCE="/maintenance/review/"

export const DECISION_MAINTENANCE="/maintenance/decision/"

export const AR_REPORT = "/report/ar";

export const GET_ALL_BM_NOTIFICATION = "/notification/bm?isArchived=false";

export const GET_ALL_ARC_NOTIFICATION = "/notification/arc?isArchived=false";

export const GET_ALL_MC_NOTIFICATION = "/notification/mc?isArchived=false";

export const GET_ALL_HO_NOTIFICATION = "/notification/ho?isArchived=false";

export const GET_ALL_GU_NOTIFICATION = "/notification/gu?isArchived=false";

export const GET_ALL_HO_ARCHIVED_NOTIFICATION = "/notification/ho?isArchived=true";

export const GET_ALL_GU_ARCHIVED_NOTIFICATION = "/notification/gu?isArchived=true";

export const GET_ALL_ARCHIVED_BM_NOTIFICATION = "/notification/bm?isArchived=true";

export const GET_ALL_ARCHIVED_ARC_NOTIFICATION = "/notification/arc?isArchived=true";

export const GET_ALL_ARCHIVED_MC_NOTIFICATION = "/notification/mc?isArchived=true";

export const HOME_OWNER_DUES = "/homeownerdues";


export const HOME_REPORT="/report/home";


export const MAINTENANCE_REQUEST_GENERAL_USER="/maintenance/bm/gu";

export const MAINTENANCE_REQUEST_BY_ID='/maintenance';

export const MAINTENANCE_REQUEST_EDIT_BY_ID='/maintenance/edit';


export const EXTERNAL_OCCUPATION="/externaloccupation";

export const EXTERNAL_USER="/externaluser";

export const DISCUSSION="/discussion";

export const ALL_DISCUSSION="/discussion?role=";

 export const DISCUSSION_COMMENTS="/discussion/comments";

export const REIMBURSE_DUE = "/reimburseddue";

export const GROUP_CONFIG="/groupconfig";

export const USER_DUE= "/homeownerdues";

export const CARD_DETAILS = "/homeownerdues/addcard";

export const GET_CARD_DETAILS = "/homeownerdues/getcard";

export const CARD_DELETE = "/homeownerdues/deletecard";

export const PAYMENT = "/homeownerdues/completepayment";

export const PAYMENT_HISTORY = "/homeownerdues/paid";

export const LINK_EMAIL = "/requestforaccess/getemailverification/";

export const COMPLIANCE_LATE_PAY="/complaince/assigndues"

export const DELETE_FILE="/file/delete/"

export const DASHBOARD_PRESIST="/presist"

export const CARD_DETAILS_REDIRECT ="/homeownerdues/addcard/details";

export const GET_ACTIVE_GIF="/customer/active"