import React from 'react'
import { Slide, ToastContainer } from 'react-toastify'

function CustomToastContainer() {
  return (
   <ToastContainer
   position="top-center" 
   autoClose={3000}
   limit={3} 
   transition={Slide} 
   closeButton={false} 
    />
  )
}

export default CustomToastContainer