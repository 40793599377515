import React, { useEffect, useMemo, useState } from "react";
import CustomHeading from "../../components/CustomHeading";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import { useNavigate } from "react-router-dom";
import { ListAlt } from "@mui/icons-material";
import axios from "axios";
import { BASE_URL, GET_ALL_GU_NOTIFICATION, GET_ALL_HO_ARCHIVED_NOTIFICATION, GET_ALL_HO_NOTIFICATION, HO_URL_NOTIFICATION } from "../../utills/ApplicationRouting";
import { useSelector } from "react-redux";
import moment from "moment";
import CustomMaterialWithoutActons from "../../components/CustomMaterialWithoutActons";
import CustomLoading from "../../components/CustomLoading";
function ArchivedNotification() {
  const navigate = useNavigate();
  const [notificationDetails,setNotificationDetails] = useState([]);
  const [isLoading,setIsLoading]=useState(true);
  const { token,roles } = useSelector(state => state.hybridhoa);
  const handleViewClick = (selectedRow)=>{
      navigate (`/archivednotification/${selectedRow.row.original.notificationId}`)
  }
  const getNotificationDetails = async () => {
    try {
      setIsLoading(true);
          const storedToken = token

      console.log(storedToken);
      
        const response= await axios.get(`${BASE_URL}${GET_ALL_HO_ARCHIVED_NOTIFICATION}`,{
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
              console.log(response.data); 

      if (response.status === 200) {
        const data = response.data;
        setNotificationDetails(data);
        setIsLoading(false);
      

      } else {
        setIsLoading(false);
        throw new Error('Failed to fetch data');
      }
     
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const getGuNotificationDetails = async () => {
    try {
          const storedToken = token

      console.log(storedToken);
      
        const response= await axios.get(`${BASE_URL}${GET_ALL_GU_NOTIFICATION}`,{
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
              console.log(response.data); 

      if (response.status === 200) {
        const data = response.data;
        setNotificationDetails(data);
        setIsLoading(false);
      

      } else {
        setIsLoading(false);
        throw new Error('Failed to fetch data');
      }
     
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(()=>{
    getNotificationDetails();
  },[])

  // useEffect(() => {
  //   const fetchData = async () => {

  //     {roles.includes('ROLE_HO')   ? ( 
  //       await getNotificationDetails()) 
  //       :
       
  //       await getGuNotificationDetails()
  //       }
  //     };

  //   fetchData();
  // }, [roles]);
 
  
    const notificationTable = useMemo((rowData) => [
      {
        accessorKey: "serialNo",
        header: "#",
        size: 20,
        Cell: ({row})=>row.index+1
      },
      {
          accessorKey: "subject",
          header: "Subject",
        },
        {
          accessorKey: "createdDateAndTime",
          header: "Created Date",
          accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),
        },
        {
          accessorKey: "createdBy",
          header: "Created By",
          Cell: ({ row }) =>
          `${row.original.createdByDetails.firstName} ${row.original.createdByDetails.lastName}`,
        },
     
        
    ]);

//      const rowActions = [
//    {
//      label: "View",
//      icon: <ListAlt color="primary" />,
//      click:(row)=> handleViewClick(row),
//    },
  
//  ]
 
   
   
    return (
      <div>
         {isLoading && <CustomLoading/>}
        <CustomHeading title="Archived Notification" />
  
       
        <CustomMaterialWithoutActons
          columns={notificationTable}
          data={notificationDetails}
          // rowActions={rowActions}
          // isLoading={isLoading}
          onRowClick = {handleViewClick}
       />
      </div>
    );
  }

export default ArchivedNotification