import { useSelector } from "react-redux";
import CustomViewPage from "../../components/CustomViewPage";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL, HOME_OWNER_DUES } from "../../utills/ApplicationRouting";
import moment from "moment";
import CustomLoading from "../../components/CustomLoading";
import CustomViewHeading from "../../components/CustomViewHeading";
import CustomViewWithTooltip from "../../components/CustomViewWithTooltip"

function McBmArReportView() {
  const navigate = useNavigate();
  const { homeOwnerDuesId } = useParams();
  const { token } = useSelector((state) => state.hybridhoa);
  const [isLoading, setIsLoading] = useState(false);
  const [dueData, setDueData] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [homeOwnerDetails,setHomeOwnerDetails]=useState()
const[title,setTitle]=useState("")
const[homeData,setHomeData]=useState('')
  const handleClick = () => {
    navigate(-1);
  };
  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '' || str === null) return '';
  
    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const getNotificationDetailsById = async () => {
    try {
      setIsLoading(true);

      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${HOME_OWNER_DUES}/${homeOwnerDuesId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response);

      if (response.status === 200) {
        setIsLoading(false);

        const data = response.data.homeOwnerDues[0];
        setHomeData(data)
        const homeOwnerDetailss = data.homeOwnerDetails;
        setHomeOwnerDetails(homeOwnerDetailss)
        const homeDetails = data.homeDetails;
        if (homeOwnerDetailss) {
          setFirstName(homeOwnerDetails.firstName || "");
          setLastName(homeOwnerDetails.lastName || "");
        }
       setTitle(data.title)
        setAddress(homeDetails.address);
        setDueData(data);
        console.log("data", data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false)
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getNotificationDetailsById();
  }, [homeOwnerDuesId]);

  const fullName = firstName + " " + lastName;

  // const data = {
  //   pageTitle: "AR Report",
  //   buttons: [
  //     {
  //       buttonTitle: "Back",
  //       onClick: handleClick,
  //     },
  //   ],
  //   content: [
  //     {
  //       topic: "Title",
  //       description: homeData.title,
  //     },
  //     {
  //       topic: "Created By",
  //       description : `${homeData.createdByDetails?.firstName || ""} ${homeData.createdByDetails?.lastName ||""}`,
  //     },
  //     {
  //       topic: "Created By Address",
  //       description : homeData.createdByHomeAddress,
  //     },
  //     {
  //       topic: "Created Date Time",
  //       description: moment(homeData.createdDateAndTime??"").format("MM/DD/YYYY hh:mm:ss a"),
  //     },
  //     {
  //       topic: "Description",
  //       description: homeData.description,
  //     },
  //     {
  //       topic: "Due Type",
  //       description: homeData.dueType,
  //     },
  //     {
  //       topic: "Status",
  //       description: convertToPascalCase(homeData.status) ,
  //     },
  //     {
  //       topic: "Amount",
  //       description: homeData.dueAmount,
  //     },
  //     {
  //       topic: "HO Name",
  //       description : `${homeData.homeOwnerDetails?.firstName??""} 
  //       ${homeData.homeOwnerDetails?.lastName??""}`,
  //     },
  //     // {
  //     //   topic: "Home Address",
  //     //   description: `${homeData.homeOwnerDetails?.address ?? ""}`,
  //     // },
  //     {
  //       topic: "Home Address",
  //       description: `${homeData.homeDetails?.address ?? ""}`,
  //     },
  //   ],
  // };
  const data = [
    {
      groupName: 'Due Report  Details',
      items: [
        { label: 'Title', data:`${homeData.title?? ""}`, text: 'Title entered while creating Due' },
        { label: 'Description', data:
          `${homeData.description?? ""}`
        , text: 'Description regards while creating Due' },
        { label: 'Due Date',  data:
          // `${moment(homeData.dueDate).format(
          // "MM/DD/YYYY hh:mm:ss A")}`
          homeData.dueDate ? `${moment(homeData.dueDate).format("MM/DD/YYYY")}` : ''
          , text:'Date for the Payment' },

         { label: 'Due Type', data:`${homeData.dueType?? ""}`, text: 'Type of the Due'},
        { label: 'Status', data:convertToPascalCase(homeData.status?? ""), text: 'Status Of the Due'},
        {label:'Total Amount',data:`${homeData.dueAmount?? ""}`,text:'TotalAmount of the Due'},
        {label:'waived Off Amount ',data:`${homeData.waivedOffAmount?? ""}`,text:'waived Off Amount from the Due'}

        
        ],
     },
    
     {
      groupName: 'Home Owner  Details',
      items: [
        { label: 'First Name', data:`${homeData.homeOwnerDetails?.firstName ?? ""} `, text: 'FirstName of the HomeOwner' },
        { label: 'Last Name', data:`${homeData.homeOwnerDetails?.lastName ?? ""} `, text: 'LastName of the HomeOwner' },
        { label: 'Email', data:`${homeData.homeOwnerDetails?.email ?? ""} `, text: 'Email of the HomeOwner' },
        { label: 'Contact', data:`${homeData.homeOwnerDetails?.contact ?? ""} `, text: 'Contact of the HomeOwner' },
        { label: 'City', data:`${homeData.homeOwnerDetails?.city ?? ""} `, text: 'City of the HomeOwner' },
        { label: 'Address', data:`${homeData.homeOwnerDetails?.addressForCommunication ?? ""} `, text: 'Address of the HomeOwner' },
],
     },
    
    
  ];
  return (
    <div>
            {isLoading && <CustomLoading/>}
            <CustomViewHeading onClick={handleClick} data={data}  title={"Due Report View "} description={(homeData.description)}>
      <CustomViewWithTooltip data={data}  />
      </CustomViewHeading>
      {/* <CustomViewPage data={data} exportedBy={true} /> */}
    </div>
  );
}

export default McBmArReportView;
