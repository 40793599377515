import React from 'react';
import CustomCard from '../../components/CustomCard';
import CustomHeading from '../../components/CustomHeading';
import { FaHouseChimney, FaRegChessQueen, FaUser, FaUserTie } from "react-icons/fa6";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { IoHomeOutline } from "react-icons/io5";

function UserPage() {
  const cardData = [
    {
      name: 'Board Member',
      icon: <FaRegChessQueen size={50} color='#045F9F' />,
      color: 'red',
      role: 'ROLE_BM',
    },
    {
      name: 'ARC',
      icon:<FaUserTie size={50} color='#045F9F' />,
      color: 'blue',
      role: 'ROLE_ARC',
    },
    {
      name: 'Home Owner',
      icon: <FaHouseChimney size={50} color='#045F9F'/>,
      color: 'green',
      role: 'ROLE_HO',
    },
  ];

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '90vh', maxWidth: '100%' }}>
      <div className=' text-center'>
       <h3 className='font-bold'>User Roles</h3>
        <div >
          <CustomCard cardData={cardData} />
        </div>
      </div>
      <br />
    </div>
  );
}

export default UserPage;
