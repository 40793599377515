import { Card, CardContent, FormControlLabel, Checkbox } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomInputField from './CustomInputField';
import CustomButton from './CustomButton';
import { AMOUNT_REGEX } from '../utills/ApplicationRegex';
import { AMOUNT_INVALID_MESSAGES, AMOUNT_REQUIRED_MESSAGE, DESCRIPTION_REQUIRED_MESSAGE, MAX_LENGTH_FIVE_THOUSAND, MIN_LENGTH_VALIDATE_MESSAGE } from '../utills/ApplicationConstants';
import { useForm } from 'react-hook-form';
import { request } from '../services/AxiosConfig';
import { BASE_URL, COMPLIANCE_LATE_PAY, COMPLIANCE_REQUEST_BY_ID, HOMES_DETAILS } from '../utills/ApplicationRouting';
import axios from 'axios';
import { useSelector } from 'react-redux';

function CustomLateFees({ groupId, homeOwnerDetailsId, homeOwnerLoginId, homeId, complainceId, getData }) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
    setError,
    watch,  // To watch the checkbox value
  } = useForm();

  const { token } = useSelector((state) => state.hybridhoa);
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [isFineApplicable, setIsFineApplicable] = useState(false);
  const [disable, setDisable] = useState(true);
  const [isManualFine,setIsManualFine]=useState(false);

  const getComplianceRequestDetailsById = async () => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${COMPLIANCE_REQUEST_BY_ID}/${complainceId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (response.status === 200) {
        const data = response.data.compliance;
        setIsFineApplicable(data.isManualFine);
        
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getComplianceRequestDetailsById();
  }, [complainceId]);

  useEffect(() => {
    if (isFineApplicable) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [isFineApplicable]);



  const handleFineApplicableChange = (event) => {
    const newValue = event.target.checked;
    setIsFineApplicable(newValue);
    setIsManualFine(newValue)
    clearErrors(); // Clear any validation errors when the checkbox changes
    setAmount("")
    setDescription("")
  };

  const handleAmountChange = (event) => {
    const inputValue = event.target.value;
    setAmount(inputValue);
  };

  useEffect(()=>{
console.log("amount",amount);
console.log("isFineapply",isFineApplicable)
  },[amount,isFineApplicable])

  const onSubmit = async (data) => {
    // if (!isFineApplicable && !isManualFine) {
    //   setError('isManualFine', {
    //     type: 'manual',
    //     message: 'Please check the box to apply a fine.',
    //   });
    //   return;
    // }

    if (isFineApplicable && amount.trim()=="") {
        setError('amount', {
          type: 'manual',
          message: AMOUNT_REQUIRED_MESSAGE,
        });
        return;
      }

      if (isFineApplicable && description.trim()==="") {
        setError('description', {
          type: 'manual',
          message: DESCRIPTION_REQUIRED_MESSAGE,
        });
        return;
      }
  


    const today = new Date();
    const payload = {
      complainceId: complainceId,
      isManualFine: isFineApplicable,
    };

    if (isFineApplicable) {
      payload.dueAmount = amount;
      payload.title = "compliance request fine";
      payload.description = description;
      payload.dueDate = today;
    }

    try {
      const res = await request({
        url: `${COMPLIANCE_LATE_PAY}/${complainceId}`,
        method: "post",
        data: payload,
      });

      if (res.status === 200) {
        if (amount !== "") {
          getData();
        }
        getComplianceRequestDetailsById()
      }
    } catch (error) {
      console.error("Error:", error.response);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <section>
          <FormControlLabel
            control={
              <Checkbox
                checked={isFineApplicable}
                onChange={handleFineApplicableChange}
              />
            }
            label="Apply Fine Manually to Homeowner"
          />
        

          {isFineApplicable && (
            <>
              <CustomInputField
                fieldName="amount"
                requiredErrorMessage={AMOUNT_REQUIRED_MESSAGE}
                fieldId="amount"
                fieldType="text"
                fieldLabel="Amount"
                fieldPattern={AMOUNT_REGEX}
                patternErrorMessage={AMOUNT_INVALID_MESSAGES}
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                onInput={handleAmountChange}
                value={amount}
                disabled={disable}
                tooltipMessage="Enter the amount for the Compliance Request Fine to be charged."
              />
              <CustomInputField
                fieldName="description"
                requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={5000}
                maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
                validator={false}
                fieldId="description"
                fieldType="text"
                fieldLabel="Description"
                fieldControl={control}
                value={description}
                fieldError={errors}
                multiline={3}
                disabled={disable}
                fieldRequiredIcon="*"
                onInput={(e) => setDescription(e.target.value)}
                tooltipMessage="Provide a description for the Compliance Request Fine."
              />
            </>
          )}
        </section>
        <div className="text-center mt-10">
          <CustomButton fieldType="submit" buttonName="Submit" />
        </div>
      </form>
    </div>
  );
}

export default CustomLateFees;
