import React, { useEffect, useState } from 'react'
import CustomHeading from '../../components/CustomHeading'
import { Card, CardContent, Divider, Typography } from '@mui/material'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BASE_URL, FAQ } from '../../utills/ApplicationRouting';
import CustomLoading from '../../components/CustomLoading';

function Faq() {
  const { token } = useSelector(state => state.hybridhoa);
  const [complianceRequestData,setComplianceRequestData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getFaq = async () => {
    try {
      setIsLoading(true);
          const storedToken = token

      console.log(storedToken);
      
        const response= await axios.get(`${BASE_URL}${FAQ}`,{
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
              console.log(response.data); 

      if (response.status === 200) {
        const data = response.data;
        setComplianceRequestData(data);
        setIsLoading(false);

      } else {
        setIsLoading(false);
        throw new Error('Failed to fetch data');
      }
     
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
     
    }
  };



useEffect(()=>{
  getFaq();
},[])
  
  return (
    <div>
       
        <Card>
            <CardContent>
            <CustomHeading title="Frequently Asked Questions"/>
            {isLoading && <CustomLoading />}
            <Divider/>
            <br/>
            {/* <Typography>{complianceRequestData.content}</Typography> */}
            <div dangerouslySetInnerHTML={{ __html: complianceRequestData.content }}></div>
            </CardContent>
        </Card>
    </div>
  )
}

export default Faq