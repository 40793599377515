import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomViewPage from "../../../components/CustomViewPage";
import CustomHeading from "../../../components/CustomHeading";
import {
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
  Button,
} from "@mui/material";
import CustomInputField from "../../../components/CustomInputField";
import {
  COMMENTS_REQUIRED_MESSAGE,
  DESCRIPTION_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_THOUSAND,
  MAX_LENGTH_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
} from "../../../utills/ApplicationConstants";
import CustomButton from "../../../components/CustomButton";
import { useForm } from "react-hook-form";
import CustomMaterialTable from "../../../components/CustomMaterialTable";
import CustomModel from "../../../components/CustomModel";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL, CATEGORY, EVENT, EVENT_REVIEW_UPDATE } from "../../../utills/ApplicationRouting";
import moment from "moment/moment";
import CustomLoading from "../../../components/CustomLoading";
import { request } from "../../../services/AxiosConfig";
import CustomToastContainer from "../../../components/CustomToastContainer";
import CustomViewWithTooltip from "../../../components/CustomViewWithTooltip";
import CustomViewHeading from "../../../components/CustomViewHeading";
import AttachmentList from "../../../components/AttachmentList";
import dayjs from "dayjs";
import CustomViewWithMore from "../../../components/CustomViewWithMore";


function McBmArcEventView() {
  const { token,roles,userLoginDetails } = useSelector(state => state.hybridhoa);
  const [eventData,setEventData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [approveOpen, setApproveOpen] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);
  const [viewData, setViewData] = useState([]);
  const [firstNameData, setFirstNameData] = useState("");
  const [lastNameData, setLastNameData] = useState("");
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [selectedCategory,setSelectedCategory]=useState('')
  const [crId,setCrId]=useState('')
  const navigate = useNavigate();
  const {eventId} = useParams();
  const [eventCategory, setEventCategory] = useState('');
  const [showFullDescription, setShowFullDescription] = useState(false); 
  const [showFulltopic, setShowFulltopic] = useState(false);
  const [showFullAddress, setShowFullAddress] = useState(false);
  const [createdrole, setcreatedrole] = useState("");

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const handleClick = () => {
    navigate(-1);
  };

  const handleApproveClickOpen = () => {
    setApproveOpen(true);
  };
  const handleApproveClose = (status) => {
    if (status == 200) {
      setApproveOpen(false);
    }
  };
  const handleApproveIconClose = () => {
    setApproveOpen(false);
  };

  const handleRejectClickOpen = () => {
    setRejectOpen(true);
  };
  const handleRejectClose = (status) => {
    if (status ==200) {
      setRejectOpen(false);
    }
  };
  const handleRejectIconClose = () => {
    setRejectOpen(false);
  };

  const getCategory = async () => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setCategoryDetails(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);

        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);

      // Handle error
      console.error("Error fetching data:", error);
    }
  };
  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const handleToggletopic = () => {
    setShowFulltopic(!showFulltopic);
  };
  const handleToggleAddress = () => {
    setShowFullAddress(!showFullAddress);
  };
  const renderDescription = () => {
    console.log("inside render ");
    if (eventData.description && !showFullDescription) {
    console.log("inside render ",eventData.description);
  
      return (
        <>
        
          {eventData.description.slice(0, 250)}
          {eventData.description.length > 250 && (
            <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Read More</Button>
          )}
        </>
      );
    } else {
    console.log("inside render else ",eventData.description);
  
      return (
        <>
          {eventData.description}
          <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Less</Button>
        </>
      );
    }
  };

  const renderTopic = () => {
    console.log("inside render ");
    if (eventData.topic && !showFulltopic) {
    console.log("inside render ",eventData.topic);
  
      return (
        <>
        
          {eventData.topic.slice(0, 250)}
          {eventData.topic.length > 250 && (
            <Button onClick={handleToggletopic} style={{fontSize:"12px"}}>Read More</Button>
          )}
        </>
      );
    } else {
    console.log("inside render else ",eventData.topic);
  
      return (
        <>
          {eventData.topic}
          <Button onClick={handleToggletopic} style={{fontSize:"12px"}}>Less</Button>
        </>
      );
    }
  };
  const renderAddress = () => {
    console.log("inside render ");
    if (eventData.eventAddress && !showFullAddress) {
    console.log("inside render ",eventData.eventAddress);
  
      return (
        <>
        
          {eventData.eventAddress.slice(0, 250)}
          {eventData.eventAddress.length > 250 && (
            <Button onClick={handleToggleAddress} style={{fontSize:"12px"}}>Read More</Button>
          )}
        </>
      );
    } else {
    console.log("inside render else ",eventData.eventAddress);
  
      return (
        <>
          {eventData.eventAddress}
          <Button onClick={handleToggleAddress} style={{fontSize:"12px"}}>Less</Button>
        </>
      );
    }
  };

  useEffect(() => {
    getCategory();

  }, []);

  const eventdateandtime = dayjs(eventData.dateAndTime);
  const now = dayjs();

  const renderedCategory = categoryDetails.map((category, index) => ({
    label: category.category,
    categoryId: category.categoryId,
    key: index,
  }));

  const getEventDetailsById = async () => {
    try {
      setIsLoading(true);
          const storedToken = token

      console.log(storedToken);
      
        const response= await axios.get(`${BASE_URL}${EVENT}/${eventId}`,{
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
              console.log(response.data); 

      if (response.status === 200) {
        const data = response.data;
        const createdDetails = data.createdByDetails;

        // setFirstNameData(createdDetails.firstName);
        // setLastNameData(createdDetails.lastName);
        setViewData(data.reviewsComments);
        setEventData(data);
        const matchingCategory=categoryDetails.find((category)=>category.categoryId===data.category);
        console.log("matchingCategory",matchingCategory)
        console.log("categoryDetails",categoryDetails)
        setCrId(response.data.crId)
        setSelectedCategory(matchingCategory.category)
        setIsLoading(false);
        setcreatedrole(response.data.role);
        console.log("userlogin data "+JSON.stringify(userLoginDetails));
        console.log("roles data "+JSON.stringify());
        // if(data.role){

        // }

      } else {
        setIsLoading(false);

        throw new Error('Failed to fetch data');
      }
     
    } catch (error) {
      setIsLoading(false);

      // Handle error
      console.error("Error fetching data:", error);
    }
  };



useEffect(()=>{
  getEventDetailsById();
},[eventId,categoryDetails])

// const fullName = firstNameData + " " + lastNameData;

const approveSubmit = (data) => {
  const postData = {
    status: "ACCEPTED",
   
  };
  request({
    url: `${EVENT_REVIEW_UPDATE}/${eventId}`,
    method: "put",
    data: postData,
  })
    .then((res) => {
      console.log(res);
      if (res.status == 200) {
        handleApproveClose(res.status);
        getEventDetailsById();
        console.log("Success");
        setIsLoading(false);
      }else{
        setIsLoading(false);

      }
    })
    .catch((error) => {
      console.log(error.response);
      setIsLoading(false);

      if (error.response) {
        console.log("error.response.status" + error.response.status);
      }
    });
};
const rejectSubmit = (data) => {
  const rejectData = {
    status: "REJECTED",
   
  };
  request({
    url: `${EVENT_REVIEW_UPDATE}/${eventId}`,
    method: "put",
    data: rejectData,
  })
    .then((res) => {
      console.log(res);
      if (res.status == 200) {
        handleRejectClose(res.status);
        getEventDetailsById();
        console.log("Success");
        setIsLoading(false);
      }else{
        
        setIsLoading(false);

      }
    })
    .catch((error) => {
      console.log(error.response);
      setIsLoading(false);

      if (error.response) {
        console.log("error.response.status" + error.response.status);
      }
    });
};

const roleWithoutPrefix = createdrole.substring("ROLE_".length);
console.log("role"+roleWithoutPrefix);
let role;
if (roleWithoutPrefix === "BM") {
role = "Board Member";
} else if (roleWithoutPrefix === "MC") {
role = "Management Company";
} else {
role = roleWithoutPrefix;
}
  const data = [
    {
      groupName:'Event Details',
      items : [
      {
        label: "Topic of Event",
        data: renderTopic(),
        text: 'The Topic of the event.',
         
      },
      { label: "Description",
       data: renderDescription(),
       text: 'A brief description of the event.',
      },
      {
        label: "Category",
        data: selectedCategory,
       text: 'A Category of the event.',

      },
      {
        label: "Event Date Time",
        data: moment(eventData.dateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
        text: 'Date and Time of the event.',
       
      },

      {
        label: "Created Date Time",
        data: moment(eventData.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
        text: 'The date when the event was created.',
      },
      // {
      //   label: "Publish Date & Time",
      //   data: moment(eventData.publishDate).format("MM/DD/YYYY hh:mm:ss a"),
      // },
      
      ...(eventData.status === "CANCELED" ? [{
        label: "Status",
        data: eventData.status,
        text: 'Current status of the event.',
      }] : []),
      {
        label: "For Home Owners",
        data: eventData.isCommunityEvent ? 'Yes' : 'No',
        text:'The event is for Home Owners',
      },
      {
        label: "Created By",
        data: role,
        text: 'The role of the person who created the event.' ,
      },
    ],
   
  },
  {
  groupName:'Event Location',
  items : [
  { label: "Event Address",
  data: renderAddress(),
  text:'The Address of the event',
}
  ],
}
  ];
  const data2 = [
    {
      groupName:'Event Details',
      items : [
      {
        label: "Topic of Event",
        data: eventData.topic,
      },
      { label: "Description",
       data: eventData.description,
      },
      {
        label: "Category",
        data: selectedCategory,
      },
      {
        label: "Event Date Time",
        data: moment(eventData.dateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
       
      },

      {
        label: "Created Date Time",
        data: moment(eventData.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
      },
      // {
      //   label: "Publish Date & Time",
      //   data: moment(eventData.publishDate).format("MM/DD/YYYY hh:mm:ss a"),
      // },
      
      ...(eventData.status === "CANCELED" ? [{
        label: "Status",
        data: eventData.status,
      }] : []),
      {
        label: "For Home Owners",
        data: eventData.isCommunityEvent ? 'Yes' : 'No',
      },
      {
        label: "Created By",
        data: role,
      },
    ],
   
  },
  {
  groupName:'Event Location',
  items : [
  { label: "Event Address",
  data: eventData.eventAddress,}
  ],
}
  ];
  const viewDataTable = useMemo((rowData) => [
    {
      accessorKey: "name",
      header: "Commented By",
      size: 20,
    },
    // {
    //   accessorKey: "role",
    //   header: "Role",
    //   size: 20,
    // },
  
    {
      accessorKey: "auditDateAndTime",
      header: "Review DateTime",
      size: 20,
      accessorFn: (row) => moment(row.auditDateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
     
    },
    {
      accessorKey: "status",
      header: "Review Status",
      size: 20,
    },
    // {
    //   accessorKey: "comments",
    //   header: "Review Comments",
    //   size: 20,
    // },
  ]);


  return (
    <div>
        {isLoading && <CustomLoading/>}
        <CustomViewHeading onClick={handleClick} title={"Event"} data={data2} fileName={`Event_${eventData.eventId}.pdf`}description={(eventData?.description || "")}>
        {/* <CustomViewHeading onClick={handleClick} > */}
        <CustomViewWithMore data={data}  />
        <Divider />
      <CustomToastContainer />
      <br />
      <br />
      {!eventData.isCommunityEvent &&
      <>
      {eventData.auditBy == userLoginDetails.homeOwnerLoginId ?
      <CustomMaterialTable columns={viewDataTable} data={viewData} />
      :
      null
     }
      <br />
      {eventData.status != "CANCELED" && (!eventdateandtime.isBefore(now)) &&(eventData.role==roles || eventData.auditBy == userLoginDetails.homeOwnerLoginId) && eventData.reviewsComments.some(comment => comment.createdFor == userLoginDetails.homeOwnerLoginId) ?
      <>
        <div style={{ display: 'flex', gap: '10px' }}>
        <CustomButton
          fieldType="submit"
          buttonName="Yes, I'm in"
          click={approveSubmit}
        />
        <CustomButton
          fieldType="submit"
          buttonName="No, thanks"
          click={rejectSubmit}
        />
      </div>   
         <br />
         <br />
       </>
       :
       null
      }
      </>
      }
      
     
  
      </CustomViewHeading>
      
    </div>
  );
}

export default McBmArcEventView;
