import {createSlice} from '@reduxjs/toolkit';

export const hybridhoa = createSlice({

    name:'hybridhoa',
    initialState: {
        token:null,
        sideNavState:true,
        userLoginDetails:null,
        userDetails:null,
        roles:[],
        homeId:[],
        homeDetails:[],
        userRoles:[],
        pageIndexes: {},
        groupNames:null,
        selectedTotalAmount:0,
        remainingDueAmount:0
      },
      reducers: {
       
        setToken: (state, action) => {
          state.token = action.payload
        },
        setSideNavState: (state, action) => {
          state.sideNavState = action.payload
        },
        setUserLoginDetails :(state, action) => {
          state.userLoginDetails = action.payload
        },
        setUserDetails :(state, action) => {
          state.userDetails = action.payload
        },
        setHomeDetails : (state, action) =>{
          state.homeDetails=action.payload
         },
        setRoles :(state, action) => {
          state.roles = action.payload
        },
        setGroupNames :(state, action) => {
          state.groupNames = action.payload
        },
        setUserRoles :(state, action) => {
          state.userRoles = action.payload
        },
        setHomeId : (state, action) =>{
          state.homeId=action.payload
         },
         setPageIndex: (state, action) => { 
          const { url, pageIndex } = action.payload;
          state.pageIndexes[url] = pageIndex; 
        },
        setSelectedTotalAmount: (state, action) =>{
          state.selectedTotalAmount=action.payload
         },
         setRemainingDueAmount: (state, action) =>{
          state.remainingDueAmount=action.payload
         },
        clearAuthToken: (state) => {
          state.token = null;
        },
                 
      }
    })
   
    export const { setToken,setHomeDetails,setGroupNames,setUserRoles,setSideNavState,setRoles,setHomeId,clearAuthToken,setUserLoginDetails,setUserDetails,setPageIndex,setSelectedTotalAmount,setRemainingDueAmount } = hybridhoa.actions
    
    export default hybridhoa.reducer