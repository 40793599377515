import React from 'react'
import ForgetChangePassword from '../../components/forgotpassword/ForgotChangepassword'




function ForgotChange() {
  return (
    <div className="bg-gray-50 pr-2 pl-2">
    <div className="container mx-auto flex flex-col lg:flex-row">

     <ForgetChangePassword/>
      
    </div>
  </div>
  )
}

export default ForgotChange