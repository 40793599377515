import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../../components/CustomInputField";
import { Tooltip, IconButton } from "@mui/material";
import {
  BYLAW_REQUIRED_MESSAGE,
  CATEGORY_INVALID_MESSAGE,
  CATEGORY_REQUIRED_MESSAGE,
  DESCRIPTION_REQUIRED_MESSAGE,
  HOME_ADDRESS_INVALID_MESSAGE,  
  HOME_ADDRESS_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_THOUSAND,
  MAX_LENGTH_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
} from "../../utills/ApplicationConstants";
import {
  Autocomplete,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  TextField,
  Box,Typography,
} from "@mui/material";
import CustomHeading from "../../components/CustomHeading";
import { FileUploader } from "react-drag-drop-files";
import CustomAutoComplete from "../../components/CustomAutoComplete";
import CustomInputFieldExpand from '../../components/CustomInputFieldExpand'
import CustomTooltipField from "../../components/CustomTooltipField";

import {
  BASE_URL,
  BYLAW,
  CATEGORY,
  COMPLIANCE_REQUEST_BY_ID,
  HOMES,
  HOMES_DETAILS,
} from "../../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import { request } from "../../services/AxiosConfig";
import CustomToastContainer from "../../components/CustomToastContainer";
import CustomLoading from "../../components/CustomLoading";
import CustomFile from "../../components/CustomFile";

function AddComplianceRequest() {
  const [file, setFile] = useState([]);
  const [homes, setHomes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [homeAddresses, setHomeAddresses] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [byLawDetails, setByLawDetails] = useState([]);
  const [byLawDescription, setByLawDescription] = useState("");
  const [homeDetails, setHomeDetails] = useState("");
  const [selectedHomeId, setSelectedHomeId] = useState(null);
  const [selectedBylawValue, setSelectedBylawValue] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [homeOwnerDetails, setHomeOwnerDetails] = useState("");
  const { token,homeId,userRoles } = useSelector((state) => state.hybridhoa);
  const [filteredOption, setFilteredHomeAddresses] = useState("");
  const [bylawsUpdated, setBylawsUpdated] = useState(true);
  const [fileError, setFileError] = useState("");
  const fileTypes = ["JPG", "PNG", "JPEG", "PDF"];
  const navigate = useNavigate();


  const handleViewClick = () => {
    navigate(-1);
  };

  const handleFileChange = (newFiles) => {
    setFile(newFiles);
  };

  const getHomes = async () => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${HOMES_DETAILS}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);
      console.log(homeId);
      const data = response.data;
      if (response.status === 200) {
        const filteredHomeAddresses = data.filter(home => {
          console.log("Checking home:", home);
          return homeId.includes(home.homeId);
        });
        

        setHomeAddresses(data);
        setHomeOwnerDetails(data.homeOwnerDetails);
        setFilteredHomeAddresses(filteredHomeAddresses);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getHomes();
  }, []);


  const handleHomeChange = (event, newValue) => {
    if (newValue && newValue.value !== undefined && newValue.value !== null) {
        const selectedHomeId = newValue.value;
        const selectedHome = homeAddresses.find(
            (home) => home.homeId === selectedHomeId
        );
        const homeOwner = selectedHome;

        if (homeOwner) {
            const { name } = homeOwner;
            const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";
            console.log("Selected Home Description:", selectedHomeDescription);

            // Set the selected home details to the state if selectedHomeDescription is not null
          
                setHomeDetails(selectedHomeDescription);

            // Use setValue to set the selected homeId in the react-hook-form
            setValue("selectedOption", selectedHomeId);

            // You can also do other operations if needed
            console.log("Selected Home:", selectedHome);
            console.log("Home Details:", homeAddresses);
        } else {
            setHomeDetails("");
            // setValue("selectedOption", null);
            setValue("selectedOption", selectedHomeId);
        }
    } else {
        // Handle the case when the selected option is removed (e.g., by pressing backspace)
        setHomeDetails("");

        // Use setValue to clear the selected homeId in the react-hook-form
        setValue("selectedOption", null);
    }
};


  const getCategory = async () => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setCategoryDetails(data); // Set the fetched data to the state
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const handleCategoryChange = (event, newValue) => {
    if (newValue && newValue.value !== undefined && newValue.value !== null) {
      const selectedCategoryId = newValue.value;
      const selectedHomeDescription = byLawDetails.find(
        (home) => home.homeId === selectedHomeId
      )?.description;

      // Set the selected homeId to the state
      setValue("category", selectedCategoryId);

      // You can also do other operations if needed

      console.log("selectedCategoryId:", selectedCategoryId);
      console.log("Home Details:", homeAddresses);
    } else {
      setValue("category", null);
    }
  };

  const getBylaw = async () => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${BYLAW}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setByLawDetails(data);
        // setByLawDescription(data.description);
        setBylawsUpdated(data.length > 0);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getBylaw();
  }, []);

  const handleByLawChange = (event, newValue) => {
    if (newValue && newValue.value !== undefined && newValue.value !== null) {
      const selectedByLawId = newValue.value;
      setValue("selectedBylaw", selectedByLawId);
      const selectedByLawDescription = byLawDetails.find(
        (byLaw) => byLaw.byLawId === selectedByLawId
      )?.description;

      setByLawDescription(selectedByLawDescription);

      // Update the state here
      setSelectedBylawValue(newValue);
    } else {
      // Clear the state values
      setByLawDescription("");
      setValue("selectedBylaw", null);

      // Update the state here
      setSelectedBylawValue(null);
    }
  };

  useEffect(() => {
    console.log("byLawDescription", byLawDescription);
  }, [byLawDescription]);

  useEffect(() => {
    console.log("homeAddresses", homeAddresses);
  }, [homeAddresses]);

  const renderedAddresses = homeAddresses
    .filter((home) => !filteredOption.includes(home)) // Exclude filteredHomeAddresses
    .map((home, index) => ({
      address: home.address,
      value: home.homeId,
      key: index,
    }));

  const renderedCategory = categoryDetails
    .filter((category) => category.type === "CR") // Filter categories with type 'CR'
    .map((category, index) => ({
      category: category.category,
      value: category.categoryId,
      key: index,
    }));

  const renderedBylaw = byLawDetails.map((ByLaw, index) => ({
    topic: ByLaw.topic,
    value: ByLaw.byLawId,
    key: index,
  }));

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  console.log(errors);

  useEffect(() => {
    const selectedBylaw = watch("selectedBylaw");

    if (!selectedBylaw || selectedBylaw === "") {
      setByLawDescription("");
      // setIsBylawFieldEmpty(true);
    } else {
      // Find the matching byLaw from byLawDetails
      const matchingByLaw = byLawDetails.find(
        (byLaw) => byLaw.byLawId === selectedBylaw
      );

      if (matchingByLaw) {
        setByLawDescription(matchingByLaw.description);
        // setIsBylawFieldEmpty(false);
      } else {
        // Handle the case where no matching byLaw is found
        setByLawDescription("");
        // setIsBylawFieldEmpty(true);
      }
    }
  }, [watch("selectedBylaw"), byLawDetails]);

  useEffect(() => {
    const selectedHome = watch("selectedOption");

    if (!selectedHome || selectedHome === "") {
      setHomeDetails("");
      // setIsBylawFieldEmpty(true);
    } else {
      // Find the matching byLaw from byLawDetails
      const matchingHome = homeAddresses.find(
        (home) => home.homeId === selectedHome
      );
      console.log(matchingHome);

      if (matchingHome) {
        const {name} = matchingHome;
        const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";
        setHomeDetails(selectedHomeDescription);

        // setIsBylawFieldEmpty(false);
      } else {
        // Handle the case where no matching byLaw is found
        setHomeDetails("");
        // setIsBylawFieldEmpty(true);
      }
    }
  }, [watch("selectedOption"), homeDetails]);

  useEffect(()=>{
    console.log("file",file);
  },[file])

 

  const onSubmit = async (data) => {

    const totalFileSize = file.reduce((acc, file) => acc + file.size, 0);
    const maxSizeInMB = 10;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
  
    if (totalFileSize > maxSizeInBytes) {
      // setFileError(`Total file size should not exceed ${maxSizeInMB}MB.`);
      return;
    }
  
    // Check file types
    const allowedFileTypes = ["image/jpg", "image/png", "image/jpeg", "application/pdf"];
    const invalidFiles = file.filter(file => !allowedFileTypes.includes(file.type));
  
    if (invalidFiles.length > 0) {
      // setFileError("Some files have invalid file types. Allowed types are JPG, PNG, JPEG, PDF.");
      return;
    }

    console.log("selectedOption:", data.selectedOption);
    const formData = new FormData();
    formData.append("homeId", data.selectedOption);

    formData.append("category", data.category);
    formData.append("description", data.violationDescription);
    file.forEach((file) => {
      formData.append('files', file);
    });
    formData.append("bylawId", data.selectedBylaw);
    formData.append("role", "ROLE_HO");
    formData.append("createdHomeId",homeId);
    // console.log('homeId:', selectedHomeId);
    // console.log('category:', selectedCategoryId);
    // console.log('description:', data.violationDescription);
    // console.log('file:', file);
    // console.log('bylawId:', selectedBylawId);
    // console.log('role:', 'ROLE_HO');

    console.log("formData:", formData);
    setIsLoading(true);

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    // return;

    await request({
      url: COMPLIANCE_REQUEST_BY_ID,
      method: "post",
      data: formData,
      headers,
    })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          setIsLoading(false);
          setTimeout(() => {
            navigate(-1);
          }, 1000); // Adjust the delay time as needed (in milliseconds)
        }else{
        setIsLoading(false);

        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  return (
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomHeading title="Add Compliance" />
        <CustomToastContainer />

        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleViewClick}
          />
        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
          <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
  {/* {homeOwnerDetails !== "" && homeOwnerDetails !== null && ( */}
  <CustomAutoComplete
    fieldName="selectedOption"
    requiredErrorMessage={HOME_ADDRESS_REQUIRED_MESSAGE}
    id="selectId"
    options={renderedAddresses}
    fieldLabel="Select Home Address"
    fieldControl={control}
    error={errors}
    setValue={setValue}
    fieldRequiredIcon="*"
    onChange={handleHomeChange}
    invalidOption={HOME_ADDRESS_INVALID_MESSAGE}
    tooltipMessage={"Please select the home address of the homeowner you are creating the compliance for."}
  />
  {/* )} */}
  <CustomTooltipField
    fieldName="homeOwnerDetails"
    fieldId="homeOwnerDetails"
    fieldType="text"
    fieldLabel="HomeOwner Name"
    fieldControl={control}
    fieldError={errors}
    disabled={true}
    value={homeDetails}
    multiline={true}
    numberOfRows={2}
    tooltipMessage={"This field displays the name of the homeowner based on the selected home address."}
  />

  <CustomAutoComplete
    fieldName="category"
    requiredErrorMessage={CATEGORY_REQUIRED_MESSAGE}
    id="selectId"
    options={renderedCategory}
    fieldLabel="Select Category/Subject"
    fieldControl={control}
    error={errors}
    setValue={setValue}
    fieldRequiredIcon="*"
    onChange={handleCategoryChange}
    invalidOption={CATEGORY_INVALID_MESSAGE}
    tooltipMessage={"Please select the category or subject relevant to the compliance issue."}
  />

  <CustomTooltipField
    fieldName="violationDescription"
    requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
    minLength={2}
    minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
    maxLength={5000}
    maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
    fieldId="violationDescription"
    fieldType="text"
    fieldLabel="Violation Description"
    fieldControl={control}
    fieldError={errors}
    fieldRequiredIcon="*"
    multiline={true}
    numberOfRows={2}
    tooltipMessage={"Provide a detailed description of the violation or compliance issue."}
  />

  <div className="h-1/2">
    {!bylawsUpdated ? (
      <Tooltip title="The bylaw in your association is not updated, so please contact the group admin." placement="bottom">
        <span>
          <CustomAutoComplete
            fieldName="selectedBylaw"
            id="selectId"
            options={renderedBylaw}
            fieldLabel="Select Bylaw"
            fieldControl={control}
            setValue={setValue}
            onChange={handleByLawChange}
            value={selectedBylawValue}
            isRequired={false}
            invalidOption={BYLAW_REQUIRED_MESSAGE}
            bylawNull="no bylaw"
            disabled={!bylawsUpdated}
            tooltipMessage={"Select the bylaw that pertains to the compliance issue, or contact the admin if bylaws are not updated."}
          />
        </span>
      </Tooltip>
    ) : (
      <CustomAutoComplete
        fieldName="selectedBylaw"
        id="selectId"
        options={renderedBylaw}
        fieldLabel="Select Bylaw"
        fieldControl={control}
        setValue={setValue}
        onChange={handleByLawChange}
        value={selectedBylawValue}
        isRequired={false}
        invalidOption={BYLAW_REQUIRED_MESSAGE}
        bylawNull="no bylaw"
        tooltipMessage={"Please select the relevant bylaw if applicable"}
      />
    )}
  </div>
  {byLawDescription ? (
    <Box>
      <Typography style={{ fontSize: "16px", color: "grey" }}>Bylaw Description</Typography>
      <CustomInputFieldExpand
        fieldName="bylawDescription"
        fieldId="bylawDescription"
        fieldType="text"
        fieldControl={control}
        fieldError={errors}
        disabled={true}
        multiline={true}
        value={byLawDescription}
      />
    </Box>
  ) : <p />}

<CustomFile 
        handleFileChange={handleFileChange}
        token={token}
        fileError={fileError}
        file={file}
      />
</section>

            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Submit" />
              {isLoading && <CustomLoading />}
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}

export default AddComplianceRequest;
