import {
  Delete,
  DeleteOutline,
  EditNoteOutlined,
  ListAlt,
} from "@mui/icons-material";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CustomHeading from "../../components/CustomHeading";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import CustomButton from "../../components/CustomButton";
import CustomModel from "../../components/CustomModel";
import { DialogActions, DialogContent, Typography } from "@mui/material";
import axios from "axios";
import { BASE_URL, RENTER, RENTER_HO } from "../../utills/ApplicationRouting";
import { useSelector } from "react-redux";
import { request } from "../../services/AxiosConfig";
import CustomToastContainer from "../../components/CustomToastContainer";
import moment from "moment";
import AttachFileIcon from '@mui/icons-material/AttachFile';


function RenterList() {
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const navigate = useNavigate();
  const { token,homeId } = useSelector((state) => state.hybridhoa);
  const [renterDetails, setRenterDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleViewClick = (selectedRow) => {
    navigate(`/renter/${selectedRow.row.original.renterId}`);
  };
  const handleAddClick = () => {
    navigate("/renteradd");
  };
  const handleEditClick = (selectedRow) => {
    navigate(`/renteredit/${selectedRow.original.renterId}`);
  };

  const handleDeleteClickOpen = (selectedRow) => {
    setDeleteOpen(true);
    console.log(selectedRow.renterId);
    setSelectedRow(selectedRow);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const getRenterDetails = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${RENTER_HO}/${homeId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setRenterDetails(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(true);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getRenterDetails();
  }, [homeId]);

  const onSubmitDelete = () => {
    const renterId = selectedRow.renterId;
    console.log(selectedRow.renterId);
    // return;
    request({ url: `${RENTER}/${renterId}`, method: "delete" })
      .then((res) => {
        console.log(res);
        getRenterDetails();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  const renterInformationTable = useMemo((rowData) => [
    //  {
    //    accessorKey: "serialNo",
    //    header: "Serial No",
    //    size: 20,
    //    Cell : ({row}) => row.index+1
    //  },
    {
      accessorKey: "firstName",
      header: "Firstname",
      accessorFn: (row) => truncateText(row.firstName, 40),
    },
    {
      accessorKey: "lastName",
      header: "Lastname",
      accessorFn: (row) => truncateText(row.lastName, 40),
    },
    {
      accessorKey: "email",
      header: "Email",
    },
    {
      accessorKey: "address",
      header: "Address",
      accessorFn: (row) => truncateText(row.address, 40),
    },
    {
      accessorKey: "isActive",

      accessorFn: (row) => (row && row.isActive ? "Yes" : "No"),
      header: "Active",
    },
    {
      accessorKey: "leaseStartDate",
      header: "Lease Start Date",
      accessorFn: (row) => moment(row.leaseStartDate).format("MM/DD/YYYY"),
    },
    {
      accessorKey: "leaseEndDate",
      header: "Lease End Date",
      accessorFn: (row) => moment(row.leaseEndDate).format("MM/DD/YYYY"),
    },
    {
      accessorKey: "fileUrl",
      header: "Attachment",
      accessorFn: (row) => {
        return row.attachments && row.attachments.length > 0 ? <AttachFileIcon /> : "N/A";
      },
    },
    
  ]);

  const rowActions = (row) => {
    const actions = [
        {
            label: "Delete",
            icon: <DeleteOutline color="error" />,
            click: () => handleDeleteClickOpen(row.original),
        },
    ];

    if (row.original.isActive) {
        actions.unshift({
            label: "Edit",
            icon: <EditNoteOutlined color="primary" />,
            click: () => handleEditClick(row),
        });
    }

    return actions;
};


  const tableActionButtons = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Add"
        click={handleAddClick}
      />
    </>
  );

  const okCancelButtonActions = (
    <>
    
      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleDeleteClose}
      />
        <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleDeleteClose}
      />
    </>
  );

  const deleteModel = (
    <form
      onSubmit={handleSubmit(onSubmitDelete)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography>
          {" "}
          Are you sure you want to delete this renter information ?
        </Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );

  return (
    <div>
      <CustomHeading title="Renter Information" />
      <CustomToastContainer />

      <CustomModel
        title=" Delete Renter"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      />

      <CustomMaterialTable
        columns={renterInformationTable}
        data={renterDetails}
        rowActions={rowActions}
        actionButtons={tableActionButtons}
        isLoading={isLoading}
        onRowClick = {handleViewClick}
      />
    </div>
  );
}

export default RenterList;
