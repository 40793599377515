import React, { useEffect, useState } from "react";
import axios from 'axios';
import "chart.js/auto";
import "./../../../index.css";
import CardLayout from "./../../../components/dashboard/CardLayout";
import CustomLoading from "./../../../components/CustomLoading";
import ChartLayout from "./../../../components/dashboard/ChartLayout";
import CustomHeading from "./../../../components/CustomHeading";
import logo from "./../../../assets/img/logo.png";
import { HandymanOutlined, InsertDriveFileOutlined, NotificationsActiveOutlined } from "@mui/icons-material";
import { BASE_URL, CHART, DASHBOARD, DASHBOARD_ARC } from "../../../utills/ApplicationRouting";
import { useSelector } from 'react-redux';
import DvrIcon from '@mui/icons-material/Dvr';
import GroupsIcon from '@mui/icons-material/Groups';
import CardLayoutContact from "../../../components/dashboard/CardLayoutContact";

function ArcDashboard() {
  
  const { token, homeId } = useSelector((state) => state.hybridhoa);
  const [isLoading, setIsLoading] = useState(true);
  const [datas, setDatas] = useState({});
  const [chartData, setChartData] = useState({});



    const getArcDashboardData = async () => {
      try {
        setIsLoading(true)
        const storedToken = token;

        // Fetch dashboard data
        const response1 = await axios.get(`${BASE_URL}${DASHBOARD}${DASHBOARD_ARC}/${homeId}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        if (response1.status === 200) {
          setDatas(response1.data);
        } else {
          throw new Error("Failed to fetch dashboard data");
        }

        // Fetch chart data
        const response2 = await axios.get(`${BASE_URL}${DASHBOARD}${CHART}${DASHBOARD_ARC}/${homeId}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        if (response2.status === 200) {
          setChartData(response2.data);
        } else {
          setIsLoading(false);
          throw new Error("Failed to fetch chart data");
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };


    useEffect(() => {
      getArcDashboardData();
  }, [token]);

  if (isLoading) {
    return <div><CustomLoading /></div>;
  }

  const cardDatas=([
    {
      count: datas.documentCount,
      badgeContent: datas.documentUnread,
      name: "Documents",
      icon: <InsertDriveFileOutlined />,
      color: ["#fee2e2"],
      linkTo: "/mcbmarcdocument",
      tooltip: "Click to view the list of Documents",
    },
    {
      count: datas.maintenanceCount,
      badgeContent: datas.maintenanceUnread,
      name: "Maintenance Requests",
      icon: <HandymanOutlined />,
      color: ["#bbddff"],
      linkTo: "/homemaintenance",
      tooltip: "Click to view the list of Maintenance requests",
    },
    {
      count: datas.complainceCountByHO,
      badgeContent: datas.complainceUnread,
      name: "Compliance Requests By Homeowner",
      icon: <DvrIcon />,
      color: ["#c2f6c2"],
      linkTo: "/raisedbyho",
      tooltip: "Click to view the list of compliance Requests Raised by Homeowner",
    },
    {
      count: datas.complainceCountByARC,
      name: "Compliance Requests By ARC",
      badgeContent: datas.complainceUnread,
      icon: <GroupsIcon />,
      color: "#ffffcc",
      linkTo: "/arccompliancerequest",
      tooltip: "Click to view the list of ARC Compliance requests",
    },
    {
      count: datas.notificationCount,
      badgeContent: datas.notificationUnread,
      name: "Notifications",
      icon: <NotificationsActiveOutlined />,
      color: "#ffd8eb",
      linkTo: "/mcbmarcnotification",
      tooltip: "Click to view the list of Notifications",
    },
  ]);


  const chartDatas= ([
    {
      title: "Maintenance",
      tooltip: "Maintenance Request Status",
      card: [
        {
          count: chartData.maintenanceApprovedCount,
          title: "Approved",
        },
        {
          count:chartData.maintenanceAckCount ,
          title: "Acknowledged",
        },
        {
          count: chartData.maintenancePendingApprovedCount,
          title: "Pending approval",
        },
        {
          count: chartData.maintenancePendingCount,
          title: "Pending",

        },
        {
          count: chartData.maintenanceRejectedCount,
          title: "Rejected",
        },
      ],
      data: {
        labels: ["Approved", "Pending", "Acknowledged", "Pending Approval", "Rejected"],
        datasets: [
          {
            data: [chartData.maintenanceApprovedCount, chartData.maintenancePendingCount, chartData.maintenanceAckCount, chartData.maintenancePendingApprovedCount, chartData.maintenanceRejectedCount],
            backgroundColor: ["#9cd96c", "#1e90ff", "#6cd9a9", "#c970db", "#ff4da6"],
            hoverBackgroundColor: ["#74c533", "#006ad1", "#33c585", "#b233cb", "#ff0080"],
          },
        ],
      },
    },
    {
      title: "Compliance",
      tooltip: "HO Compliance Request Status",
      card: [
        {
          count: chartData.complaincePendingCount,
          title: "Pending",
        },
        {
          count: chartData.complainceAckCount,
          title: "Acknowledged",
        },
        {
          count: chartData.complainceResolvedCount,
          title: "Resolved",
        },
        {
          count: chartData.complainceClosedCount,
          title: "Closed",
        },
        {
            count: chartData.complainceNotResolvedCount,
            title: " Not resolved",
            
          },
      ],
      data: {
        labels: ["Pending", "Acknowledged", "Resolved", "Closed","Not resolved"],
        datasets: [
          {
            data: [chartData.complaincePendingCount, chartData.complainceAckCount, chartData.complainceResolvedCount, chartData.complainceClosedCount,chartData.complainceNotResolvedCount,],
            backgroundColor: ["#ff794d", "#9cd96c", "#6cd9a9", "#00a5b3","#00bfff"],
            hoverBackgroundColor: ["#ff531a", "#74c533", "#33c585", "#007680","#87cefa"],
          },
        ],
      },
    },
    // Add more chart data as needed
  ]);

  const chartOptions = {
    responsive: true,
  };

  return (
    <div>
      <CustomHeading title="Dashboard" />
      <br />
      <CardLayoutContact />
      <br />
      <CardLayout cardData={cardDatas} type="dashboardARC" />
      <br />
      <br />
      <ChartLayout chartData={chartDatas} chartOptions={chartOptions} />
      <div className="fixed bottom-0 right-0 m-4">
        <a href="https://hybridhoa.com" target="blank">
          <img src={logo} width={100} height={100} alt="logo" />
        </a>
      </div>
    </div>
  );
}

export default ArcDashboard;