import React, { useState } from "react";
import CustomHeading from "../../components/CustomHeading";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  Card,
  CardContent,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Popover,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Info } from "@mui/icons-material";
import { PASSWORD_REGEX } from "../../utills/ApplicationRegex";
import { CONFIRM_PASSWORD_REQUIRED_MESSAGE, CURRENT_AND_NEW_PASSWORD_CHECKING, CURRENT_PASSWORD_REQUIRED_MESSAGE, NEW_PASSWORD_CURRENT_PASSWORD_CHECKING, NEW_PASSWORD_REQUIRED_MESSAGE } from "../../utills/ApplicationConstants";
import { request } from "../../services/AxiosConfig";
import { CHANGE_PASSWORD } from "../../utills/ApplicationRouting";
import CustomLoading from "../../components/CustomLoading";
import CustomToastContainer from "../../components/CustomToastContainer";
import { useDispatch } from "react-redux";
import { clearAuthToken } from "../../redux/Hybridhoa";



function ChangePassword() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [infoPopover, setInfoPopover] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleClick = () => {
    navigate(-1);
  };

  const handleInfoClick = (event) => {
    setInfoPopover(event.currentTarget);
  };

  const handleInfoClose = () => {
    setInfoPopover(null);
  };

  const { handleSubmit, control, reset, setError } = useForm();

  const passwordValidation = (value) => {
    const regex = PASSWORD_REGEX;
    return regex.test(value) || "Please provide valid format ";
  };

  const onSubmit = (data) => {
    setIsLoading(true);

    if (!data.currentPassword || data.currentPassword.trim() === "") {
      setError("currentPassword", {
        type: "manual",
        message: "Current Password is required",
      });
      setIsLoading(false);
      return;
    }
    if (data.currentPassword === data.newPassword) {
      setError("newPassword", {
        type: "manual",
        message: CURRENT_AND_NEW_PASSWORD_CHECKING,
      });
      setIsLoading(false);
      return;
    }

    if (data.newPassword !== data.confirmPassword) {
      setError("confirmPassword", {
        type: "manual",
        message: NEW_PASSWORD_CURRENT_PASSWORD_CHECKING,
      });
      setIsLoading(false);
      return;
    }
    console.log(data.currentPassword);

    const postData = {
      previousPassword: data.currentPassword.trim(),
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword
    }
    request({ url: CHANGE_PASSWORD, method: "post", data: postData }).then(res => {
      setIsLoading(false);
      console.log(res);
      if (res.status == 200) {

        setIsLoading(false);
        setTimeout(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("reportData");
          localStorage.removeItem("arData");
          localStorage.removeItem("homeActivity");
          // localSt
          // Dispatch an action to clear token in Redux state
          dispatch(clearAuthToken());
          navigate('/')
        }, 3000)
      }
    }).catch(error => {
      console.log(error.response);
      setIsLoading(false);
      if (error.response) {

        console.log("error.response.status" + error.response.status);


      }
    });
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row">
        <CustomHeading title="Change Password" />
        <CustomToastContainer />

        <div className="md:pl-4 sm:text-left md:text-right sm:w-full md:w-full text-lg font-sans mb-2 space-x-2">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleClick}
          />
        </div>
      </div>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>

          <CardContent>
            {/* <CardContent> */}
            <div class="grid xl:grid-cols-2">
              <section class="flex flex-col  mt-10">

                <div style={{ position: 'relative' }}>
                  <Controller
                    name="currentPassword"
                    control={control}
                    rules={{ required: CURRENT_PASSWORD_REQUIRED_MESSAGE }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type={showPassword ? "text" : "password"}
                        variant="standard"
                        label="Current Password"
                        size="small"
                        sx={{ width: '100%' }}
                        error={!!fieldState?.error}
                        helperText={fieldState?.error?.message}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
                <br />

                <div style={{ position: 'relative' }}>
                  <Controller
                    name="newPassword"
                    control={control}
                    rules={{
                      required: NEW_PASSWORD_REQUIRED_MESSAGE,
                      validate: passwordValidation,
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type={newPassword ? "text" : "password"}
                        variant="standard"
                        label="New Password"
                        size="small"
                        sx={{ width: '100%' }}
                        error={!!fieldState?.error}
                        helperText={fieldState?.error?.message}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setNewPassword(!newPassword)}
                                edge="end"
                              >
                                {newPassword ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </IconButton>
                              <IconButton onClick={handleInfoClick} edge="end">
                                <Info />
                              </IconButton>
                              <Popover
                                open={Boolean(infoPopover)}
                                anchorEl={infoPopover}
                                onClose={handleInfoClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                <Typography style={{ padding: "10px" }}>
                                  <ul
                                    style={{
                                      paddingLeft: "20px",
                                      margin: "0",
                                      listStyleType: "circle",
                                    }}
                                  >
                                    <li>Your Password must contain at least 8 to 128 characters</li>
                                    <li>Include at least one uppercase letter, one lowercase letter, one digit, and one special symbol</li>
                                    <li>{"Please avoid using the following symbols in your password: [ ] { } \\ "}</li>
                                  </ul>
                                </Typography>
                              </Popover>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
                <br />

                <div style={{ position: 'relative' }}>
                  <Controller
                    name="confirmPassword"
                    control={control}
                    rules={{
                      required: CONFIRM_PASSWORD_REQUIRED_MESSAGE,
                      validate: passwordValidation,
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type={confirmPassword ? "text" : "password"}
                        variant="standard"
                        label="Confirm Password"
                        size="small"
                        sx={{ width: '100%' }}
                        error={!!fieldState?.error}
                        helperText={fieldState?.error?.message}
                        onPaste={(e) => e.preventDefault()}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setConfirmPassword(!confirmPassword)}
                                edge="end"
                              >
                                {confirmPassword ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
                <br />

              </section>
            </div>

            <div className="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Update" />
              {isLoading && <CustomLoading />}
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}

export default ChangePassword;
