// import React, { useEffect, useMemo, useState } from "react";
// import CustomHeading from "../../../components/CustomHeading";
// import CustomMaterialTable from "../../../components/CustomMaterialTable";
// import { useNavigate } from "react-router-dom";
// import {
//   DeleteOutline,
//   EditNoteOutlined,
//   ListAlt,
//   VisibilityOffOutlined,
//   VisibilityOutlined,
// } from "@mui/icons-material";
// import CustomModel from "../../../components/CustomModel";
// import { Controller, useForm } from "react-hook-form";
// import {
//   DESCRIPTION_REQUIRED_MESSAGE,
//   MAX_LENGTH_FIVE_THOUSAND,
//   MAX_LENGTH_TWO_FIFTY,
//   MIN_EIGHT_LENGTH_VALIDATE_MESSAGE,
//   MIN_LENGTH_VALIDATE_MESSAGE,
//   NOTIFICATION_MAX_LENGTH_VALIDATE_MESSAGE,
//   NOTIFICATION_REQUIRED_MESSAGE,
//   NOTIFICATION_TYPE_MESSAGE,
//   REQUEST_TYPE_REQUIRED_MESSAGE,
//   SUBJECT_REQUIRED_MESSAGE,
// } from "../../../utills/ApplicationConstants";
// import {
//   DialogActions,
//   DialogContent,
//   FormControlLabel,
//   InputLabel,
//   Typography,
// } from "@mui/material";
// import CustomInputField from "../../../components/CustomInputField";
// import CustomButton from "../../../components/CustomButton";
// import CustomRadioButton from "../../../components/CustomRadioButton";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { useSelector } from "react-redux";
// import axios from "axios";
// import {
//   BASE_URL,
//   BASE_URLS,
//   GET_ALL_ARC_NOTIFICATION,
//   GET_ALL_BM_NOTIFICATION,
//   GET_ALL_MC_NOTIFICATION,
//   GROUP_CONFIG,
//   NOTIFICATION,
// } from "../../../utills/ApplicationRouting";
// import moment from "moment";
// import { request } from "../../../services/AxiosConfig";
// import CustomToastContainer from "../../../components/CustomToastContainer";
// import CustomLoading from "../../../components/CustomLoading";
// import CustomNotificationRadioButton from "../../../components/CustomNotificationRadioButton";

// function McBmArcNotificationList() {
//   const navigate = useNavigate();
//   const [open, setOpen] = useState(false);
//   const [openEdit, setOpenEdit] = useState(false);
//   const [openSendDoc, setOpenSendDoc] = useState(false);
//   const [hideOpen, setHideOpen] = useState(false);
//   const [deleteOpen, setDeleteOpen] = useState(false);
//   const [userRoles, setUserRoles] = useState("");
//   const { token, userLoginDetails, roles } = useSelector(
//     (state) => state.hybridhoa
//   );
//   const [notificationDetails, setNotificationDetails] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [submitLoading, setSubmitLoading] = useState(false);
//   const [selectedRows, setSelectedRow] = useState(null);
//   const [generalNotify, setGeneralNotify] = useState("");
//   const [updateRes, setUpdateRes] = useState([]);
//   const selectedRow = updateRes;
//   const [selectedRowId, setSelectedRowId] = useState(null);
//   const [subjectData, setSubjectData] = useState("");
//   const [radioData, setRadioData] = useState(false);
//   const [descriptionData, setDescriptionData] = useState("");
//   const [hideSelectedRow, setHideSelectedRow] = useState("");
//   const [notificataionGroup, setNotificationGroup] = useState("");
//   const [groupConfigurationDetails, setGroupConfigurationDetails] = useState(
//     []
//   );
//   const [publishContent, setPublishContent] = useState("No");
//   const [publishContentHo, setPublishContentHo] = useState("No");
//   const [select, setSelect] = useState(false);
//   const [selectHo, setSelectHo] = useState(false);
//   const [notificationType, setNotiFicationType] = useState(false);
//   const getGroupConfigurationDetails = async () => {
//     try {
//       setIsLoading(true);
//       const storedToken = token;

//       console.log(storedToken);

//       const response = await axios.get(`${BASE_URL}${GROUP_CONFIG}`, {
//         headers: {
//           Authorization: `Bearer ${storedToken}`,
//         },
//       });
//       console.log(response);

//       if (response.status === 200) {
//         const data = response.data;
//         setGroupConfigurationDetails(data);

//         setIsLoading(false);
//       } else {
//         throw new Error("Failed to fetch data");
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };
//   useEffect(() => {
//     // Assuming data is your array of objects
//     const item = groupConfigurationDetails.find(
//       (item) => item.topic === "Publish To Home Owners with 51% approval"
//     );
//     const itemHo = groupConfigurationDetails.find(
//       (item) =>
//         item.topic ===
//         "Allow Board member/ARC/MC users to publish general notification to homeowners"
//     );
//     if (item) {
//       setPublishContent(item.content);
//       console.log("TestCase", item.content);
//     }
//     if (itemHo) {
//       setPublishContentHo(itemHo.content);
//       console.log("TestCase", itemHo.content);
//     }
//   }, [groupConfigurationDetails, publishContent, publishContentHo]);
//   useEffect(() => {
//     getGroupConfigurationDetails();
//   }, []);
//   // const handleRadioButtonChange = (event) => {
//   //   const { value } = event.target;
//   //   // Handle radio button change here
//   //   // if (event.target.name === 'notificationType') {
//   //     if (value === 'option1') {
//   //       setPublishContent('Yes');
//   //       setPublishContentHo('No');
//   //     } else if (value === 'option2') {
//   //       setPublishContent('No');
//   //       setPublishContentHo('Yes');
//   //     }
//   //   // }
//   // };
//   console.log(roles[0]);
//   const onPublishContentChange = () => {
//     console.log("selected");
//     if (!select) {
//       setSelect(true);
//       setSelectHo(false);
//     } else {
//       setSelect(false); // Deselect if already selected
//     }
//   };
//   const onPublishContentHoChange = () => {
//     console.log("selectedHo");

//     if (!selectHo) {
//       setSelectHo(true);
//       setSelect(false);
//     } else {
//       setSelectHo(false); // Deselect if already selected
//     }
//   };

//   //  {roles.includes('BM')   ? (
//   const handleViewClick = (selectedRow) => {
//     navigate(`/mcbmarcnotification/${selectedRow.original.notificationId}`);
//   };

//   const handleAddClickOpen = () => {
//     setOpen(true);
//   };
//   const handleAddClose = (status) => {
//     if (status == 200) {
//       setOpen(false);
//     }
//   };
//   const handleIconClose = () => {
//     setOpen(false);
//   };

//   const handleEditClose = (status) => {
//     if (status == 200) {
//       setOpenEdit(false);
//     }
//   };
//   const handleEditIconClose = () => {
//     setOpenEdit(false);
//   };

//   const handleSendDocClickOpen = (selectedRow) => {
//     setOpenSendDoc(true);
//     console.log("selectedRow:", selectedRow);
//     console.log("selectedRow.id:", selectedRow.original.notificationId);
//     setSelectedRowId(selectedRow.original.notificationId);
//     console.log(selectedRow.notificationId);
//     getNotificationDetailsById(selectedRow.original.notificationId);
//   };
//   const handleSendDocClose = (status) => {
//     if (status == 200) {
//       setOpenSendDoc(false);
//     }
//   };
//   const handleSendDocIconClose = () => {
//     setOpenSendDoc(false);
//   };

//   const handleHideClickOpen = (selectedRow) => {
//     setHideOpen(true);
//     setHideSelectedRow(selectedRow);
//     console.log(selectedRow.notificationId);
//   };
//   const handleHideClose = () => {
//     setHideOpen(false);
//   };

//   const handleDeleteClickOpen = (selectedRow) => {
//     setDeleteOpen(true);
//     setSelectedRow(selectedRow);
//   };
//   const handleDeleteClose = () => {
//     setDeleteOpen(false);
//   };

//   const handleRadioChange = (e) => {
//     setGeneralNotify(e.target.value);
//   };
//   const handleEditSubject = (e) => {
//     setSubjectData(e.target.value);
//   };

//   const handleEditRadio = (newValue) => {
//     setRadioData(newValue);
//   };

//   const handleContentChange = (editor) => {
//     const newContent = editor.getData();
//     setDescriptionData(newContent);
//   };

//   const {
//     handleSubmit,
//     control,
//     reset,
//     setValue,
//     setError,
//     formState: { errors },
//   } = useForm();

//   const getBmNotificationDetails = async () => {
//     try {
//       const storedToken = token;

//       console.log(storedToken);

//       const response = await axios.get(
//         `${BASE_URL}${GET_ALL_BM_NOTIFICATION}`,
//         {
//           headers: {
//             Authorization: `Bearer ${storedToken}`,
//           },
//         }
//       );
//       console.log(response.data);

//       if (response.status === 200) {
//         const data = response.data;
//         setNotificationDetails(data);
//         setIsLoading(false);
//       } else {
//         throw new Error("Failed to fetch data");
//       }
//     } catch (error) {
//       // Handle error
//       console.error("Error fetching data:", error);
//     }
//   };

//   const getARCNotificationDetails = async () => {
//     try {
//       const storedToken = token;

//       console.log(storedToken);

//       const response = await axios.get(
//         `${BASE_URL}${GET_ALL_ARC_NOTIFICATION}`,
//         {
//           headers: {
//             Authorization: `Bearer ${storedToken}`,
//           },
//         }
//       );
//       console.log(response.data);

//       if (response.status === 200) {
//         const data = response.data;
//         setNotificationDetails(data);
//         setIsLoading(false);
//       } else {
//         throw new Error("Failed to fetch data");
//       }
//     } catch (error) {
//       // Handle error
//       console.error("Error fetching data:", error);
//     }
//   };

//   const getMcNotificationDetails = async () => {
//     try {
//       const storedToken = token;

//       console.log(storedToken);

//       const response = await axios.get(
//         `${BASE_URL}${GET_ALL_MC_NOTIFICATION}`,
//         {
//           headers: {
//             Authorization: `Bearer ${storedToken}`,
//           },
//         }
//       );
//       console.log(response.data);

//       if (response.status === 200) {
//         const data = response.data;
//         setNotificationDetails(data);
//         setIsLoading(false);
//       } else {
//         throw new Error("Failed to fetch data");
//       }
//     } catch (error) {
//       // Handle error
//       console.error("Error fetching data:", error);
//     }
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       {
//         roles.includes("ROLE_BM")
//           ? await getBmNotificationDetails()
//           : roles.includes("ROLE_ARC")
//           ? await getARCNotificationDetails()
//           : await getMcNotificationDetails();
//       }
//     };

//     fetchData();
//   }, [roles]);

//   const onSubmit = (data) => {
//     if (!data.notificationType) {
//       // alert("Notification Type is Not Configured Please Contact Group Admin");
//       setNotiFicationType(true);
//       return;
//     }
//     const postData = {
//       subject: data.subject,
//       description: data.editorContent,
//       role: roles[0],
//       isGeneral: data.notificationType,
//       isHidden: false,
//     };
//     console.log(data.subject);
//     console.log(data.editorContent);
//     console.log(data.notificationType);
//     console.log(roles[0]);
//     setIsLoading(true);
//     request({ url: NOTIFICATION, method: "post", data: postData })
//       .then((res) => {
//         console.log(res);
//         if (res.status == 200) {
//           console.log("Success");
//          setIsLoading(false);

//           {
//             roles == "ROLE_ARC"
//               ? getARCNotificationDetails()
//               : roles == "ROLE_BM"
//               ? getBmNotificationDetails()
//               : getMcNotificationDetails();
//           }
//           setValue("subject","")
//           setValue("editorContent","")
//           setValue("notificationType","")
//           handleAddClose(res.status);

//         }
//       })
//       .catch((error) => {
//         console.log(error.response);
//         if (error.response) {
//           setIsLoading(false);
//           console.log("error.response.status" + error.response.status);
//         }
//       });
//   };

//   const getNotificationDetailsById = async (id) => {
//     try {
//       setIsLoading(true);
//       const storedToken = token;

//       console.log(storedToken);

//       const response = await axios.get(`${BASE_URL}${NOTIFICATION}/${id}`, {
//         headers: {
//           Authorization: `Bearer ${storedToken}`,
//         },
//       });
//       console.log(response.data);

//       if (response.status === 200) {
//         const data = response.data;
//         console.log(response.data);
//         setUpdateRes(data.notificationId);
//         setSubjectData(data.subject);
//         setRadioData(data.isGeneral);
//         setUserRoles(data.role);
//         console.log(data.isGeneral);
//         setDescriptionData(data.description);

//         setIsLoading(false);
//       } else {
//         throw new Error("Failed to fetch data");
//       }
//     } catch (error) {
//       // Handle error
//       console.error("Error fetching data:", error);
//     }
//   };
//   const handleEditClickOpen = (selectedRow) => {
//     setOpenEdit(true);

//     console.log("selectedRow:", selectedRow);
//     console.log("selectedRow.id:", selectedRow.original.notificationId);
//     setSelectedRowId(selectedRow.original.notificationId);
//     console.log(selectedRow.notificationId);
//     getNotificationDetailsById(selectedRow.original.notificationId);
//   };
//   const onSubmitEdit = () => {
//     const putData = {
//       subject: subjectData,
//       description: descriptionData,
//       role: roles[0],
//       isGeneral: radioData,
//       // isHidden: false,
//     };

//     if (subjectData === "" || subjectData == null) {
//       setError("editSubject", {
//         type: "manual",
//         message: SUBJECT_REQUIRED_MESSAGE,
//       });
//       return;
//     }
//     if (descriptionData === "" || descriptionData == null) {
//       setError("editEditorContent", {
//         type: "manual",
//         message: DESCRIPTION_REQUIRED_MESSAGE,
//       });
//       return;
//     }

//     if (radioData === "" || radioData === null) {
//       setError("notificationType", {
//         type: "manual",
//         message: NOTIFICATION_TYPE_MESSAGE,
//       });
//       setIsLoading(false);
//       return;
//     }
//     console.log(subjectData);
//     console.log(descriptionData);
//     console.log(radioData);

//     request({
//       url: `${NOTIFICATION}/${selectedRow}`,
//       method: "put",
//       data: putData,
//     })
//       .then((res) => {
//         console.log(res);
//         if (res.status == 200) {
//           setIsLoading(false);
//           handleEditClose(res.status);
//           getBmNotificationDetails();
//         }
//       })
//       .catch((error) => {
//         console.log(error.response);
//         if (error.response) {
//           console.log("error.response.status" + error.response.status);
//         }
//       });
//   };
//   const onSubmitSendDoc = (data) => {
//     const unHideData = {
//       subject: subjectData,
//       description: descriptionData,
//       role: roles[0],
//       isGeneral: radioData,
//       isHidden: false,
//     };
//     if (subjectData == "" || subjectData == null) {
//       setError("editSubject", {
//         type: "manual",
//         message: SUBJECT_REQUIRED_MESSAGE,
//       });
//       return;
//     }
//     request({
//       url: `${NOTIFICATION}/${selectedRow}`,
//       method: "put",
//       data: unHideData,
//     })
//       .then((res) => {
//         console.log(res);
//         if (res.status == 200) {
//           setIsLoading(false);
//           handleSendDocClose(res.status);
//           getBmNotificationDetails();
//         }
//       })
//       .catch((error) => {
//         console.log(error.response);
//         if (error.response) {
//           console.log("error.response.status" + error.response.status);
//         }
//       });
//   };
//   const onSubmitHide = (data) => {
//     const NotificationId = hideSelectedRow.notificationId;
//     const hideData = {
//       isHidden: true,
//     };

//     request({
//       url: `${NOTIFICATION}/${NotificationId}`,
//       method: "put",
//       data: hideData,
//     })
//       .then((res) => {
//         console.log(res);
//         if (res.status == 200) {
//           setIsLoading(false);
//           getBmNotificationDetails();
//         }
//       })
//       .catch((error) => {
//         console.log(error.response);
//         if (error.response) {
//           console.log("error.response.status" + error.response.status);
//         }
//       });
//   };
//   const onSubmitDelete = () => {
//     const NotificationId = selectedRows.notificationId;
//     console.log(selectedRows.notificationId);
//     // return;
//     request({ url: `${NOTIFICATION}/${NotificationId}`, method: "delete" })
//       .then((res) => {
//         console.log(res);
//         getBmNotificationDetails();
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   const convertToPascalCase = (str) => {
//     // Check if the input string is undefined or empty
//     if (str === undefined || str === "" || str === null) return "";

//     // Remove underscores and convert string to Pascal case
//     return str
//       .replace(/_/g, " ")
//       .toLowerCase()
//       .replace(/\b\w/g, (char) => char.toUpperCase());
//   };
//   const notificationTable = useMemo((rowData) => [
//     {
//       accessorKey: "serialNo",
//       header: "#",
//       size: 20,
//       Cell: ({ row }) => row.index + 1,
//     },
//     {
//       accessorKey: "subject",
//       header: "Subject",
//     },
//     {
//       accessorKey: "createdDateAndTime",
//       header: "Created Date",
//       accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),
//     },
//     {
//       accessorKey: "createdBy",
//       header: "Created By",
//       Cell: ({ row }) => {
//         if (row.original.createdByDetails && row.original.createdByDetails.firstName && row.original.createdByDetails.lastName) {
//           return `${row.original.createdByDetails.firstName} ${row.original.createdByDetails.lastName}`;
//         } else {
//           return "";
//         }
//       }
//     },

//     {
//       accessorKey: "status",
//       header: "Status",
//       accessorFn: (row) => convertToPascalCase(row.status),
//     },
//     {
//       accessorKey: "isHidden",
//       header: "Hidden",
//       accessorFn: (row) => (row && row.isHidden ? "Yes" : "No"),
//     },
//   ]);

//   const rowActions = (row) => {
//     const actions = [
//       {
//         label: "View",
//         icon: <ListAlt color="primary" />,
//         click: () => handleViewClick(row),
//       },
//       // {
//       //   label: "Delete",
//       //   icon: <DeleteOutline color="error" />,
//       //   click: () => handleDeleteClickOpen(row.original),
//       // },
//     ];

//     if (
//       row.original.createdBy == userLoginDetails.homeOwnerLoginId &&
//       row.original.status !== "PUBLISHED" &&
//       row.original.status !== "APPROVED" &&
//       row.original.status !== "REJECTED"
//     ) {
//       actions.push({
//         label: "Edit",
//         icon: <EditNoteOutlined color="primary" />,
//         click: () => handleEditClickOpen(row),
//       });
//     }
//     if (
//       row.original.createdBy == userLoginDetails.homeOwnerLoginId &&
//       !row.original.isHidden &&
//       row.original.status !== "PUBLISHED" &&
//       row.original.status !== "APPROVED" &&
//       row.original.status !== "REJECTED"
//     ) {
//       actions.push({
//         label: "Hide",
//         icon: <VisibilityOutlined color="primary" />,
//         click: () => handleHideClickOpen(row.original),
//       });
//     }
//     if (
//       row.original.createdBy == userLoginDetails.homeOwnerLoginId &&
//       row.original.isHidden &&
//       row.original.status !== "PUBLISHED" &&
//       row.original.status !== "APPROVED" &&
//       row.original.status !== "REJECTED"
//     ) {
//       actions.push({
//         label: "Unhide",
//         icon: <VisibilityOffOutlined color="primary" />,
//         click: () => handleSendDocClickOpen(row),
//       });
//     }

//     if (row.original.createdBy == userLoginDetails.homeOwnerLoginId ) {
//       actions.push({
//         label: "Delete",
//         icon: <DeleteOutline color="error" />,
//         click: () => handleDeleteClickOpen(row.original),
//       });
//     }



//     return actions;
//   };
//   const tableActionButtons = (
//     <>
//       <CustomButton
//         fieldType="button"
//         buttonName="Create"
//         click={handleAddClickOpen}
//       />
//     </>
//   );

//   const addActionButton = (
//     <>
//       <CustomButton
//         fieldType="submit"
//         buttonName="Create"
//         click={handleAddClose}
//       ></CustomButton>
//       {isLoading && <CustomLoading />}
//     </>
//   );
//   const addModel = (
//     <>
//       <form
//         onSubmit={handleSubmit(onSubmit)}
//         className="space-y-4 md:space-y-6 "
//       >
//         <DialogContent dividers>
//           <Typography gutterBottom>
//             <CustomInputField
//               fieldName="subject"
//               requiredErrorMessage={SUBJECT_REQUIRED_MESSAGE}
//               fieldId="subject"
//               minLength={8}
//               minLengthErrorMessage={MIN_EIGHT_LENGTH_VALIDATE_MESSAGE}
//               maxLength={250}
//               maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
//               fieldLabel="Subject"
//               fieldControl={control}
//               fieldError={errors}
//               fieldRequiredIcon="*"
//               multiline={true}
//               numberOfRows={2}
//             />
//             <br />
//             <br />

//             <Controller
//               name="editorContent"
//               control={control}
//               rules={{
//                 required: DESCRIPTION_REQUIRED_MESSAGE,
//                 minLengthErrorMessage:NOTIFICATION_MAX_LENGTH_VALIDATE_MESSAGE,
//               }}
//               render={({ field }) => (
//                 <>
//                   <InputLabel htmlFor="editorContent">
//                     Description <span style={{ color: "red" }}>*</span>
//                   </InputLabel>
//                   <CKEditor
//                     editor={ClassicEditor}
//                     onChange={(event, editor) => {
//                       const data = editor.getData();
//                       field.onChange(data);
//                     }}
//                   />
//                   {errors.editorContent && (
//                     <span className="text-red-500">
//                       {errors.editorContent.message}
//                     </span>
//                   )}
//                 </>
//               )}
//             />
//             <br />

//             {/* <CustomNotificationRadioButton
//         fieldNameOption1="option1"
//         fieldNameOption2="option2"
//         publishContent={publishContent}
//         publishContentHo={publishContentHo}
//        onPublishContentChange={onPublishContentChange}
//        onPublishContentHoChange={onPublishContentHoChange}
//       /> */}
//             <CustomNotificationRadioButton
//               fieldName="notificationType"
//               fieldControl={control} // Assuming 'control' is the control object from react-hook-form
//               publishContent={publishContent}
//               publishContentHo={publishContentHo}
//               requiredErrorMessage={NOTIFICATION_TYPE_MESSAGE}
//               labelName="Notification Type"
//               valueOne={false}
//               labelOne="Need 51% Approval from Board Member"
//               valueTwo={true}
//               labelTwo="General Notification - This will be Publish General Notification to Homeowners"
//               // value={selectedValue} // Pass the selected value
//               // onChange={handleRadioButtonChange} // Handle the change event
//             />
//           </Typography>
//         </DialogContent>
//         <DialogActions>{addActionButton}</DialogActions>
//       </form>
//     </>
//   );

//   const editActionButton = (
//     <>
//       <CustomButton
//         fieldType="submit"
//         buttonName="Update"
//         click={handleEditClose}
//       ></CustomButton>
//       {isLoading && <CustomLoading />}
//     </>
//   );
//   const editModel = (
//     <>
//       <form
//         onSubmit={handleSubmit(onSubmitEdit)}
//         className="space-y-4 md:space-y-6 "
//       >
//         <DialogContent dividers>
//           <Typography gutterBottom>
//             <CustomInputField
//               fieldName="editSubject"
//               fieldId="editSubject"
//               minLength={2}
//               minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
//               maxLength={250}
//               maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
//               fieldLabel="Subject"
//               fieldControl={control}
//               fieldError={errors}
//               fieldRequiredIcon="*"
//               multiline={true}
//               numberOfRows={2}
//               value={subjectData}
//               onInput={handleEditSubject}
//             />
//             <br />
//             <br />
//             <Controller
//               name="editEditorContent"
//               control={control}
//               defaultValue=""
//               // rules={{
//               //   required: DESCRIPTION_REQUIRED_MESSAGE,
//               // }}
//               render={({ field }) => (
//                 <>
//                   <InputLabel htmlFor="editorContent">
//                     Description <span style={{ color: "red" }}>*</span>
//                   </InputLabel>
//                   <CKEditor
//                     editor={ClassicEditor}
//                     data={descriptionData}
//                     onChange={(event, editor) => {
//                       const newContent = editor.getData();
//                       field.onChange(newContent); // Update the field value in the form state
//                       handleContentChange(editor); // Call your custom handler
//                     }}
//                   />
//                   {errors.editEditorContent && (
//                     <span className="text-red-500">
//                       {errors.editEditorContent.message}
//                     </span>
//                   )}
//                 </>
//               )}
//             />

//             <br />

//             {/* <CustomRadioButton
//               fieldName="editNotificationType"
//               fieldControl={control}
//               fieldError={errors}
//               labelName="Notification Type"
//               valueOne={true}
//               labelOne="General Document - This will be Publish General Document to Homeowners"
//               valueTwo={false}
//               labelTwo="Need 51% Approval from Board Member"
//               value={radioData}
//               onChange={handleEditRadio}
//             /> */}
//             <CustomNotificationRadioButton
//               fieldName="notificationType"
//               fieldControl={control} // Assuming 'control' is the control object from react-hook-form
//               publishContent={publishContent}
//               publishContentHo={publishContentHo}
//               labelName="Notification Type"
//               valueOne={false}
//               labelOne="Need 51% Approval from Board Member"
//               valueTwo={true}
//               labelTwo="General Notification - This will be Publish General Notification to Homeowners  "
//               value={radioData} // Pass the selected value
//               onChange={handleEditRadio} // Handle the change event
//             />
//           </Typography>
//         </DialogContent>
//         <DialogActions>{editActionButton}</DialogActions>
//       </form>
//     </>
//   );

//   const sendDocActionButton = (
//     <>
//       <CustomButton
//         fieldType="submit"
//         buttonName="Resend"
//         click={handleSendDocClose}
//       ></CustomButton>
//       {isLoading && <CustomLoading />}
//     </>
//   );
//   const sendDocModel = (
//     <>
//       <form
//         onSubmit={handleSubmit(onSubmitSendDoc)}
//         className="space-y-4 md:space-y-6 "
//       >
//         <DialogContent dividers>
//           <Typography gutterBottom>
//             <CustomInputField
//               fieldName="editSubject"
//               fieldId="editSubject"
//               minLength={2}
//               minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
//               maxLength={250}
//               maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
//               fieldLabel="Subject"
//               fieldControl={control}
//               fieldError={errors}
//               fieldRequiredIcon="*"
//               multiline={true}
//               numberOfRows={2}
//               value={subjectData}
//               onInput={handleEditSubject}
//             />
//             <br />
//             <br />
//             <Controller
//               name="editEditorContent"
//               control={control}
//               defaultValue=""
//               rules={{
//                 required: DESCRIPTION_REQUIRED_MESSAGE,
//                 minLengthErrorMessage:NOTIFICATION_MAX_LENGTH_VALIDATE_MESSAGE,
//               }}
//               render={({ field }) => (
//                 <>
//                   <InputLabel htmlFor="editorContent">
//                     Description <span style={{ color: "red" }}>*</span>
//                   </InputLabel>
//                   <CKEditor
//                     editor={ClassicEditor}
//                     data={descriptionData}
//                     onChange={(event, editor) => {
//                       const newContent = editor.getData();
//                       field.onChange(newContent); // Update the field value in the form state
//                       handleContentChange(editor); // Call your custom handler
//                     }}
//                   />
//                   {errors.editEditorContent && (
//                     <span className="text-red-500">
//                       {errors.editEditorContent.message}
//                     </span>
//                   )}
//                 </>
//               )}
//             />
//             <br />

//             <CustomRadioButton
//               fieldName="editNotificationType"
//               fieldControl={control}
//               fieldError={errors}
//               labelName="Notification Type"
//               valueOne={true}
//               labelOne="General Document - This will be Publish General Document to Homeowners"
//               valueTwo={false}
//               labelTwo="Need 51% Approval from Board Member"
//               value={radioData}
//               onChange={handleEditRadio}
//             />
//           </Typography>
//         </DialogContent>
//         <DialogActions>{sendDocActionButton}</DialogActions>
//       </form>
//     </>
//   );

//   const okCancelButtonActions = (
//     <>
//       <CustomButton
//         fieldType="button"
//         buttonName="No"
//         click={handleHideClose}
//       />
//       <CustomButton
//         fieldType="submit"
//         buttonName="Yes"
//         click={handleHideClose}
//       />
//     </>
//   );

//   const hideModel = (
//     <form
//       onSubmit={handleSubmit(onSubmitHide)}
//       className="space-y-4 md:space-y-6 "
//     >
//       <DialogContent dividers>
//         <Typography> Do you want to hide this notification ?</Typography>
//       </DialogContent>
//       <DialogActions>{okCancelButtonActions}</DialogActions>
//     </form>
//   );

//   const deleteButtonActions = (
//     <>
//       <CustomButton
//         fieldType="submit"
//         buttonName="Ok"
//         click={handleDeleteClose}
//       />
//       <CustomButton
//         fieldType="button"
//         buttonName="Cancel"
//         click={handleDeleteClose}
//       />
//     </>
//   );

//   const deleteModel = (
//     <form
//       onSubmit={handleSubmit(onSubmitDelete)}
//       className="space-y-4 md:space-y-6 "
//     >
//       <DialogContent dividers>
//         <Typography>
//           {" "}
//           Are you sure you want to delete this notification ?
//         </Typography>
//       </DialogContent>
//       <DialogActions>{deleteButtonActions}</DialogActions>
//     </form>
//   );
//   const handleNotificationTypeClose = () => {
//     setNotiFicationType(false); // Close the modal
//   };

//   const notificationTypeActions = (
//     <>
//       <CustomButton
//         fieldType="button"
//         buttonName="Ok"
//         click={handleNotificationTypeClose}
//       />
//     </>
//   );

//   const notificationTypeModel = (
//     <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 md:space-y-6">
//       <DialogContent dividers>
//         <Typography>
//           Notification Type is Not Configured. Please Contact Group Admin.
//         </Typography>
//       </DialogContent>
//       <DialogActions>{notificationTypeActions}</DialogActions>
//     </form>
//   );
//   return (
//     <div>
//       <CustomHeading title="Notifications" />
//       <CustomToastContainer />

//       <CustomMaterialTable
//         columns={notificationTable}
//         data={notificationDetails}
//         rowActions={rowActions}
//         actionButtons={tableActionButtons}
//         isLoading={isLoading}
//       />
//       <CustomModel
//         title="Create Notification"
//         submit={onSubmit}
//         content={addModel}
//         action={addActionButton}
//         openStatus={open}
//         closeStatus={handleAddClose}
//         iconCloseStatus={handleIconClose}
//         reset={reset}
//       />

//       <CustomModel
//         title="Edit Notification"
//         submit={onSubmitEdit}
//         content={editModel}
//         action={editActionButton}
//         openStatus={openEdit}
//         closeStatus={handleEditClose}
//         iconCloseStatus={handleEditIconClose}
//         reset={reset}
//       />

//       <CustomModel
//         title="Send Notification"
//         submit={onSubmitSendDoc}
//         content={sendDocModel}
//         action={sendDocActionButton}
//         openStatus={openSendDoc}
//         closeStatus={handleSendDocClose}
//         iconCloseStatus={handleSendDocIconClose}
//         reset={reset}
//       />

//       <CustomModel
//         title=" Hide Notification"
//         submit={onSubmitHide}
//         content={hideModel}
//         action={okCancelButtonActions}
//         openStatus={hideOpen}
//         closeStatus={handleHideClose}
//         iconCloseStatus={handleHideClose}
//         reset={reset}
//         modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
//       />

//       <CustomModel
//         title="Delete Notification"
//         submit={onSubmitDelete}
//         content={deleteModel}
//         action={deleteButtonActions}
//         openStatus={deleteOpen}
//         closeStatus={handleDeleteClose}
//         iconCloseStatus={handleDeleteClose}
//         reset={reset}
//         modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
//       />

//       <CustomModel
//         title="Notification Type Not Configured"
//         // submit={onSubmitNotificationType}
//         content={notificationTypeModel}
//         action={notificationTypeActions}
//         openStatus={notificationType}
//         closeStatus={handleNotificationTypeClose}
//         iconCloseStatus={handleNotificationTypeClose}
//         reset={reset}
//         modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
//       />
//     </div>
//   );
// }

// export default McBmArcNotificationList;

import React, { useEffect,useRef, useMemo, useState } from "react";
import CustomHeading from "../../../components/CustomHeading";
import CustomMaterialTable from "../../../components/CustomMaterialTable";
import { useNavigate,useParams } from "react-router-dom";
import CustomTooltipFiled from "../../../components/CustomTooltipField";

import {
    DeleteOutline,
    DescriptionSharp,
    EditNoteOutlined,
    InfoOutlined,
    ListAlt,
    VisibilityOffOutlined,
    VisibilityOutlined,
} from "@mui/icons-material";
import CustomModel from "../../../components/CustomModel";
import { Controller, useForm } from "react-hook-form";
import {
    DESCRIPTION_MAX_LENGTH_VALIDATE_MESSAGE,
    DESCRIPTION_MIN_LENGTH_VALIDATE_MESSAGE,
    DESCRIPTION_REQUIRED_MESSAGE,
    MAX_LENGTH_FIVE_THOUSAND,
    MAX_LENGTH_THOUSAND,
    MAX_LENGTH_TWO_FIFTY,
    MIN_EIGHT_LENGTH_VALIDATE_MESSAGE,
    MIN_LENGTH_VALIDATE_MESSAGE,

    NOTIFICATION_REQUIRED_MESSAGE,
    NOTIFICATION_TYPE_MESSAGE,
    PUBLISH_DATE_REQUIRED_MESSAGE,
    REQUEST_TYPE_REQUIRED_MESSAGE,

    SUBJECT_MAX_LENGTH_VALIDATE_MESSAGE,
    SUBJECT_MIN_LENGTH_VALIDATE_MESSAGE,
    SUBJECT_REQUIRED_MESSAGE,
} from "../../../utills/ApplicationConstants";
import {
    DialogActions,
    DialogContent,
    FormControlLabel,
    InputLabel,
    Typography,Card,CardContent,
    Tooltip,
    IconButton,
} from "@mui/material";
import CustomInputField from "../../../components/CustomInputField";
import CustomButton from "../../../components/CustomButton";
import CustomRadioButton from "../../../components/CustomRadioButton";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useSelector } from "react-redux";
import axios from "axios";
import {
    BASE_URL,
    BASE_URLS,
    GET_ALL_ARC_NOTIFICATION,
    GET_ALL_BM_NOTIFICATION,
    GET_ALL_MC_NOTIFICATION,
    GROUP_CONFIG,
    NOTIFICATION,
} from "../../../utills/ApplicationRouting";
import moment from "moment";
import { request } from "../../../services/AxiosConfig";
import CustomToastContainer from "../../../components/CustomToastContainer";
import CustomLoading from "../../../components/CustomLoading";
import CustomNotificationRadioButton from "../../../components/CustomNotificationRadioButton";
import CustomPublishDatePicker from "../../../components/CustomPublishDate";
import dayjs from "dayjs";
import CustomDatePicker from "../../../components/CustomDatePicker";
import { FaGalacticSenate } from "react-icons/fa6";
import JoditEditor from "jodit-react";
function McBmArcNotificationtEdit() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openSendDoc, setOpenSendDoc] = useState(false);
    const [hideOpen, setHideOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [userRoles, setUserRoles] = useState("");
    const { token, userLoginDetails, roles } = useSelector(
        (state) => state.hybridhoa
    );

     

    const {
      handleSubmit,
      control,
      reset,
      watch,
      setValue,
      setError,
      data,
      formState: { errors },
  } = useForm();

    const [notificationDetails, setNotificationDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [selectedRows, setSelectedRow] = useState(null);
    const [generalNotify, setGeneralNotify] = useState("");
    const [updateRes, setUpdateRes] = useState([]);
    const selectedRow = updateRes;
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [subjectData, setSubjectData] = useState("");
    const [radioData, setRadioData] = useState(false);
    const [descriptionData, setDescriptionData] = useState("");
    const [hideSelectedRow, setHideSelectedRow] = useState("");
    const [notificataionGroup, setNotificationGroup] = useState("");
    const [groupConfigurationDetails, setGroupConfigurationDetails] = useState(
        []
    );
    const [publishContent, setPublishContent] = useState("No");
    const [publishContentHo, setPublishContentHo] = useState("No");
    const [select, setSelect] = useState(false);
    const [selectHo, setSelectHo] = useState(false);
    const [notificationType, setNotiFicationType] = useState(false);
    const [publishDate, setPublishDate] = useState("");
    const [minDate, setMinDate] = useState(null);

            // isGeneral: radioData,
    const notificationTypes = watch("notificationType" ); // Default to false
    console.log("notificationTypessjhgfshgsfhgsf",notificationTypes);
      
    const maxDate = dayjs().add(1, "year").endOf("day");
    const [publishDateData, setPublishDateData] = useState("");
    const [boardMember,setBoardMember]=useState(false)
    const [hoMember,setHoMember]=useState(false)
    const {notificationId} = useParams();
    const [content, setContent] = useState(""); // Initialize content state with an empty string
    const editorRef = useRef(null);


    
    const handleClick = () => {
        navigate(-1);
      };

    const getGroupConfigurationDetails = async () => {
        try {
            setIsLoading(true);
            const storedToken = token;

            console.log(storedToken);

            const response = await axios.get(`${BASE_URL}${GROUP_CONFIG}`, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            console.log(response);

            if (response.status === 200) {
                const data = response.data;
                setGroupConfigurationDetails(data);

                setIsLoading(false);
            } else {
              setIsLoading(false);
                throw new Error("Failed to fetch data");
            }
        } catch (error) {
          setIsLoading(false);
            console.error("Error fetching data:", error);
        }
    };
    // useEffect(() => {
    //     // Assuming data is your array of objects
    //     const item = groupConfigurationDetails.find(
    //         (item) => item.topic === "Publish notification to Home Owners with 51% approval"
    //     );
    //     const itemHo = groupConfigurationDetails.find(
    //         (item) =>
    //             item.topic ===
    //             "Allow Board member/ARC/MC users to publish general notification to homeowners"
    //     );
    //     if (item) {
    //         setPublishContent(item.content);
    //         setBoardMember(itemHo.content === "Yes" ? true : false);
  
  
    //         console.log("TestCase", item.content);
    //       }
    //       if (itemHo) {
    //         setPublishContentHo(itemHo.content);
    //         setHoMember(item.content === "Yes" ? true : false);
  
  
    //         console.log("TestCase", itemHo.content);
    //       }
    // }, [groupConfigurationDetails, publishContent, publishContentHo]);
    useEffect(() => {
        getGroupConfigurationDetails();
    }, []);
 
 

    const handleEditClose = (status) => {
        if (status == 200) {
            setOpenEdit(false);
        }
    };
    const handleEditPublish = (selectedPublishData) => {
        setPublishDateData(selectedPublishData);
    };

    const handleEditSubject = (e) => {
        setSubjectData(e.target.value);
    };

    const handleEditRadio = (newValue) => {
        setRadioData(newValue);
        setPublishDateData("")
    };
// const notificationTypes=watch("notificationType")
//     useEffect(() => {
//       const newMinDate = notificationTypes === "true"
//         ? dayjs().startOf('day')  // If notificationType requires today's date
//         : dayjs().add(1, 'day').startOf('day');  // If notificationType requires starting from tomorrow
    
//       setMinDate(newMinDate);
    
//       // Clear both the form value and the actual input value of the date picker
//       setPublishDated("");  // Reset the state that stores the selected date
//       // setValue('publishdate', null);  // Reset the form field
//       // document.querySelector('input[name="publishdate"]').value = "";  // Manually clear the visible input field
//     }, [notificationTypes, setValue]);

   
    const handleContentChange = (newContent) => {
        setContent(newContent);
      };
    
      const config = useMemo(
        () => ({
          readonly: false,
          showCharsCounter: false,
          showWordsCounter: false,
          showXPathInStatusbar: false,
          // removeButtons: ['file', 'image', 'video', 'spellcheck', 'speechRecognize'],
          removeButtons: ['file', 'image', 'video', 'spellcheck', 'speechRecognize','preview','print','source','about'],
          showPlaceholder: false,
        }),
        []
      );
    
      const isEmptyContent = (content) => {
        if (!content) return true; // Check for undefined or null content
        // Strip HTML tags and check if content is empty or just spaces
        console.log(content);
    
        const strippedContent = content.replace(/<\/?[^>]+(>|$)/g, "").trim();
        const finalData = strippedContent.replace(/&nbsp;/g, "").trim(); 
        console.log(finalData.length);
        return finalData.length === 0;
      };
    


   
    const getNotificationDetailsById = async (id) => {
        try {
            setIsLoading(true);
            const storedToken = token;

            console.log(storedToken);

            const response = await axios.get(`${BASE_URL}${NOTIFICATION}/${notificationId}`, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            console.log(response.data);

            if (response.status === 200) {
                const data = response.data;
                console.log(response.data);
                setUpdateRes(data.notificationId);
                setSubjectData(data.subject);
                setRadioData(data.isGeneral);
                setPublishDateData(dayjs(data.publishDate));
                setUserRoles(data.role);
                setBoardMember(data.publishToBoard)
                console.log("teste radio",data.publishToBoard);
                setHoMember(data.publishToHo)
                console.log(data.isGeneral);
                setDescriptionData(data.description);
                console.log(data.description);
                setIsLoading(false);
          setContent(data.content || ""); // Ensure content is set to "" if response.data.content is undefined

            } else {
              setIsLoading(false);
                throw new Error("Failed to fetch data");
            }
        } catch (error) {
            // Handle error
            setIsLoading(false);
            console.error("Error fetching data:", error);
        }
    };

    useEffect(()=>{
        getNotificationDetailsById();
      },[notificationId])

    
    //   useEffect(() => {
    //     const newMinDate = radioData === "true"
    //       ? dayjs().startOf('day')  // If notificationType requires today's date
    //       : dayjs().add(1, 'day').startOf('day');  // If notificationType requires starting from tomorrow
      
    //     setMinDate(newMinDate);
    // setPublishDateData("")
    //     // Clear both the form value and the actual input value of the date picker
    //     // setPublishDated("");  // Reset the state that stores the selected date
    //     // setValue('publishdate', null);  // Reset the form field
    //     // document.querySelector('input[name="publishdate"]').value = "";  // Manually clear the visible input field
    //   }, [radioData, setValue]);
   const minDatechange= radioData === "true"? dayjs().startOf('day')  // If notificationType requires today's date
         : dayjs().add(1, 'day').startOf('day'); 
    const onSubmitEdit = (data) => {
        // if (!data.notificationType) {
        //     // alert("Notification Type is Not Configured Please Contact Group Admin");
        //     setNotiFicationType(true);
        //     return;
        // }

        // Trim leading and trailing white spaces from subjectData
        const trimmedSubjectData = subjectData.trim();

       

        if (trimmedSubjectData === "") {
            setError("editSubject", {
                type: "manual",
                message: SUBJECT_REQUIRED_MESSAGE,
            });
            return;
        }
        const descriptions = descriptionData; // Check if data and data.description are defined
        console.log("descriptions", descriptions);

        if (!descriptions) {
            setError("editorContent", {
                type: "manual",
                message: DESCRIPTION_REQUIRED_MESSAGE,
            });
            return;
        }

        const cleanedData = descriptions.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
        const finalData = cleanedData.replace(/&nbsp;/g, " ");
        const trimData = finalData.trim()
        console.log(finalData);

        if (trimData.length < 2) {
            setError("editorContent", {
                type: "manual",
                message: DESCRIPTION_MIN_LENGTH_VALIDATE_MESSAGE,
            });
            return;
        }

        if (trimData.length > 1000) {
            setError("editorContent", {
                type: "manual",
                message: DESCRIPTION_MAX_LENGTH_VALIDATE_MESSAGE,
            });
            return;
        }

        if (radioData === "" || radioData === null) {
            setError("notificationType", {
                type: "manual",
                message: NOTIFICATION_TYPE_MESSAGE,
            });
            setIsLoading(false);
            return;
        }
        if (!publishDateData) {
          setError("publishdate", {
              type: "manual",
              message: PUBLISH_DATE_REQUIRED_MESSAGE,
          });
          return;
      }
        console.log(trimmedSubjectData);
        console.log(descriptionData);
        console.log(radioData);
        setIsLoading(true)
        const putData = {
            subject: trimmedSubjectData,
            description: trimData,
            role: roles[0],
            isGeneral: radioData,
            isHidden: false,
            publishDate: dayjs(publishDateData).format("YYYY-MM-DD"),
        };
        request({
            url: `${NOTIFICATION}/${selectedRow}`,
            method: "put",
            data: putData,
        })
            .then((res) => {
                console.log(res);
                if (res.status === 200) {
                    setIsLoading(false);
                                   
                        setTimeout(() => {
                navigate(-1);
              }, 4000);
                }
            })
            .catch((error) => {
                console.log(error.response);
                setIsLoading(false)
                if (error.response) {
                  setIsLoading(false)

                    console.log("error.response.status" + error.response.status);
                }
            });
    };
    const handleNotificationTypeClose = () => {
        setNotiFicationType(false); // Close the modal
      };
   
    const notificationTypeActions = (
        <>
          <CustomButton
            fieldType="button"
            buttonName="Ok"
            click={handleNotificationTypeClose}
          />
        </>
      );

    const notificationTypeModel = (
        <form onSubmit={handleSubmit(onSubmitEdit)} className="space-y-4 md:space-y-6">
          <DialogContent dividers>
            <Typography>
              Notification Type is Not Configured. Please Contact Group Admin.
            </Typography>
          </DialogContent>
          <DialogActions>{notificationTypeActions}</DialogActions>
        </form>
      );

      const renderTooltip = (message) => (
        <Tooltip title={<Typography sx={{ fontSize: "14px" }}>{message}</Typography>} placement="right">
          <IconButton>
            <InfoOutlined />
          </IconButton>
        </Tooltip>
      );

    return (
        <div>

{/* <CustomModel
        title="Notification Type Not Configured"
        // submit={onSubmitNotificationType}
        content={notificationTypeModel}
        action={notificationTypeActions}
        openStatus={notificationType}
        closeStatus={handleNotificationTypeClose}
        iconCloseStatus={handleNotificationTypeClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      /> */}

        <div class="flex flex-col md:flex-row">
      <CustomHeading title="Notification Edit" />

      <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
        <CustomButton
          fieldType="button"
          buttonName="Back"
          click={handleClick}
        />
      </div>
    </div>
    <Card>
    <form
                onSubmit={handleSubmit(onSubmitEdit)}
                className="space-y-4 md:space-y-6 "
            ><CardContent>
                
            
                        <CustomTooltipFiled
                            fieldName="editSubject"
                            fieldId="editSubject"
                            minLength={8}
                            minLengthErrorMessage={SUBJECT_MIN_LENGTH_VALIDATE_MESSAGE}
                            maxLength={250}
                            maxLengthErrorMessage={SUBJECT_MAX_LENGTH_VALIDATE_MESSAGE}
                            fieldLabel="Subject"
                            fieldControl={control}
                            fieldError={errors}
                            fieldRequiredIcon="*"
                            multiline={true}
                            numberOfRows={2}
                            value={subjectData}
                            onInput={handleEditSubject}
               tooltipMessage={"Enter the subject of the notification"}
                     
                     />
                        <br />
                        
                        <div className="flex items-center md:w-1/2 ">
<div className="w-full ">

                        <Controller
                            name="editorContent"
                            control={control}
                            defaultValue=""
                            rules={{
                                // required: DESCRIPTION_REQUIRED_MESSAGE,
                                // validate: value => {
                                //   const strippedValue = value.replace(/(<([^>]+)>)/gi, '');
                                //   console.log("value",value);
                                //   if (strippedValue.length < 2 || strippedValue.length > 1000) {
                                //     return 'The Description field length should be a minimum of 2 and a maximum of 1000 characters';
                                //   }
                                //   return true;
                                // }
                            }}
                            render={({ field }) => (
                                <>
                                    <InputLabel htmlFor="editorContent">
                                        Description <span style={{ color: "red" }}>*</span>
                                    </InputLabel>
                                    {/* <CKEditor
                                        editor={ClassicEditor}
                                        data={descriptionData}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            const cleanedData = data.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
                                            const finalData = cleanedData.replace(/&nbsp;/g, " "); // Replace &nbsp; with space
                                            console.log("data", finalData);
                                            field.onChange(finalData);
                                            console.log(finalData)
                                            setDescriptionData(data)
                                        }}
                                    /> */}
                 <JoditEditor
  ref={editorRef}
  value={descriptionData} // Ensure descriptionData is passed to value prop
  config={config} // Add your custom configuration for Jodit if needed
  onBlur={field.onBlur} // Handle blur event if necessary
  onChange={(newContent) => {
    const cleanedContent = newContent.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
    const finalContent = cleanedContent.replace(/&nbsp;/g, " "); // Replace &nbsp; with space
    console.log("Cleaned content:", finalContent);
    
    field.onChange(finalContent); // Pass cleaned content to the form field
    setDescriptionData(newContent); // Update descriptionData state with the raw editor content
  }}
/>
                   {errors.editorContent && (
                                        <span className="text-red-500">
                                            {errors.editorContent.message}
                                        </span>
                                    )}
                                </>
                            )}
                        />
                        <br />

                        </div>
  <div>
    {renderTooltip("Enter the detailed description of the notification")}
  </div>
</div>

                        {/* <CustomRadioButton
              fieldName="editNotificationType"
              fieldControl={control}
              fieldError={errors}
              labelName="Notification Type"
              valueOne={true}
              labelOne="General Document - This will be Publish General Document to Homeowners"
              valueTwo={false}
              labelTwo="Need 51% Approval from Board Member"
              value={radioData}
              onChange={handleEditRadio}
            /> */}
                            <CustomRadioButton
      fieldName="notificationType"
      fieldControl={control}
     
      labelName="Notification Type"
      valueOne={false}
      labelOne={
        boardMember ? (
          
           " Need 51% Approval from Board Member"
            
        ) : ''
      }
      valueTwo={true}
      labelTwo={
        hoMember ? (
          
          "  General Notification - This will be Publish General Notification to Homeowners"
            
        ) : ''
      }
      value={radioData}
      onChange={handleEditRadio}
      tooltipMessage={"Select the notification type"}
    />
                
                    <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">


                        <CustomDatePicker
                            fieldName="publishdate"
                            label="Publish Date"
                            control={control}
                            minDate={minDatechange}
                            maxDate={maxDate}
                            error={errors}
                            value={publishDateData}
                            onChange={handleEditPublish}
                            fieldRequiredIcon="*"
                            validate={publishDateData}
                            pastDateErrorMessage="Please select a date in the future"
               tooltipMessage={"Select the date when the notification should be published"}
                      
                      />
                    </section>
                    <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Update" />
              {isLoading && <CustomLoading />}
            </div>
                </CardContent>
            </form>
            
    </Card>
    <CustomToastContainer />
   
  </div>
       

    );
}

export default McBmArcNotificationtEdit;