import React, { useEffect, useMemo, useState } from "react";
import CustomHeading from "../../../components/CustomHeading";
import CustomMaterialTable from "../../../components/CustomMaterialTable";
import { useNavigate } from "react-router-dom";
import { ListAlt} from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { BASE_URL, GET_ALL_ARCHIVED_ARC_DOCUMENT, GET_ALL_ARCHIVED_BM_DOCUMENT, GET_ALL_ARCHIVED_MC_DOCUMENT } from "../../../utills/ApplicationRouting";
import axios from "axios";
import moment from "moment";
import CustomMaterialWithoutActons from "../../../components/CustomMaterialWithoutActons";
import CustomLoading from "../../../components/CustomLoading";
function McBmArcArchivedDocumentList() {
    const navigate = useNavigate();
    const { token, roles} = useSelector(
      (state) => state.hybridhoa
    );
    const {
      handleSubmit,
      control,
      reset,
      setError,
      formState: { errors },
    } = useForm();
    const [documentDetails, setDocumentDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const handleViewClick = (selectedRow)=>{
        navigate (`/mcbmarcarchiveddocumentview/${selectedRow.row.original.documentId}`);
    }

    const getDocumentDetails = async () => {
      try {
        setIsLoading(true);
        const storedToken = token;
  
        console.log(storedToken);
  
        const response = await axios.get(`${BASE_URL}${GET_ALL_ARCHIVED_ARC_DOCUMENT}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        console.log(response.data);
  
        if (response.status === 200) {
          const data = response.data;
  
          setDocumentDetails(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        // Handle error
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    };
  
    const getDocumentDetailsByBm = async () => {
      try {
        setIsLoading(true);
        const storedToken = token;
  
        console.log(storedToken);
  
        const response = await axios.get(`${BASE_URL}${GET_ALL_ARCHIVED_BM_DOCUMENT}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        console.log(response.data);
  
        if (response.status === 200) {
          const data = response.data;
          setDocumentDetails(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        // Handle error
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    };
  
    const getDocumentDetailsByMc = async () => {
      try {
        setIsLoading(true);
        const storedToken = token;
  
        console.log(storedToken);
  
        const response = await axios.get(`${BASE_URL}${GET_ALL_ARCHIVED_MC_DOCUMENT}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        console.log(response.data);
  
        if (response.status === 200) {
          const data = response.data;
          setDocumentDetails(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        setIsLoading(false);
        // Handle error
        console.error("Error fetching data:", error);
      }
    };
  
    useEffect(() => {
      const fetchData = async () => {
        if (roles === "ROLE_ARC") {
          await getDocumentDetails();
        } else if (roles === "ROLE_BM") {
          await getDocumentDetailsByBm();
        } else if (roles === "ROLE_MC") {
          await getDocumentDetailsByMc();
        }
      };
  
      fetchData();
    }, [roles]);
   
    
  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '' || str === null) return '';
  
    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };
  
      
    const documentTable = useMemo((rowData) => [
      {
        accessorKey: "serialNo",
        header: "#",
        size: 20,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "category",
        header: "Category",
        size: 20,
        accessorFn: (row) => convertToPascalCase(row.category),
      },
      {
        accessorKey: "description",
        header: "Description",
        size: 20,
      },
      {
        accessorKey: "createdDateAndTime",
        header: "Created Date",
        accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),

      },
      {
        accessorKey: "uploadedBy",
        header: "Uploaded By",
        accessorFn: (row) => `${row.createdByDetails? row.createdByDetails.firstName : ""} ${row.createdByDetails? row.createdByDetails.lastName : ""} `,
        sortDescFirst: true, 
     
      },
  
      {
        accessorKey: "publishDate",
        header: "Publish Date",
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 20,
        accessorFn: (row) => convertToPascalCase(row.status),
      },
    ]);
  
        // const rowActions = [
        //   {
        //     label: "View",
        //     icon: <ListAlt color="primary" />,
        //     click: (row)=>handleViewClick(row),
        //   },
                 
        // ]
     
       
       
        return (
          <div>
            {isLoading && <CustomLoading />}
            <CustomHeading title=" Archived Document" />
      
           
            <CustomMaterialWithoutActons
              columns={documentTable}
              data={documentDetails}
              // rowActions ={rowActions}
              onRowClick = {handleViewClick}
           />
          </div>
        );
      }

export default McBmArcArchivedDocumentList