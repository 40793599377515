import CustomViewPage from "../../components/CustomViewPage";
import CustomViewPageExpand from "../../components/CustomViewPageExpand";

import CustomButton from "../../components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../../components/CustomInputField";
import {
  COMMENTS_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_HUNDRED,
  MAX_LENGTH_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
  REVIEW_COMMENT_REQUIRED_MESSAGE,
  STATUS_REQUIRED_MESSAGE,
} from "../../utills/ApplicationConstants";
import {
  Autocomplete,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,Button,
} from "@mui/material";
import CustomHeading from "../../components/CustomHeading";
import { FileUploader } from "react-drag-drop-files";
import { useEffect, useMemo, useState } from "react";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  BASE_URL,
  COMPLIANCE_REQUEST,
  COMPLIANCE_REQUEST_BY_ID,
  COMPLIANCE_REVIEW,
  GENERAL_COMMENTS,
  GET_ALL_GENERAL_COMMENTS,
} from "../../utills/ApplicationRouting";
import {
  DeleteOutline,
  EditNoteOutlined,
  FileDownloadOutlined,
} from "@mui/icons-material";
import { request } from "../../services/AxiosConfig";
import CustomLoading from "../../components/CustomLoading";
import CustomToastContainer from "../../components/CustomToastContainer";
import CustomModel from "../../components/CustomModel";
import CustomPascalChange from "../../components/CustomPascalChange";
import moment from 'moment';
import 'moment-timezone';
import CustomViewWithTooltip from "../../components/CustomViewWithTooltip";
import AttachmentList from "../../components/AttachmentList";
import CustomViewHeading from "../../components/CustomViewHeading";
import AttachmentListsView from "../../components/AttachmentListsView";
import CustomLateFees from "../../components/CustomLateFees";
import CustomReviewTable from "../../components/CustomReviewTable";
import CustomReviewTableWithoutActions from "../../components/CustomReviewTableWithoutActions";
import CustomViewWithMore from "../../components/CustomViewWithMore";
import CustomGeneralComments from "../../components/CustomGeneralComments";
import CustomFile from "../../components/CustomFile";
function ComplianceRequestView() {
  const { token,userDetails } = useSelector((state) => state.hybridhoa);
  const [complianceRequestData, setComplianceRequestData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reviewComment, setReviewComment] = useState("");
  const [status,setStatus]=useState("");
  const [showFullDescription, setShowFullDescription] = useState(false); 
  const[fileImage,setFileImage]=useState([])

  const [address,setAddress] = useState("");
  const [addressFrCommunication,setAddressFrComunication] = useState("");
  const [fineApply,setFineApply] = useState("");
  const [generalCommentsDataAdd, setGeneralCommentsDataAdd] = useState("");
  const [selectedStatus,setSelectedStatus]=useState("")
  const [statuss,setStatuss]=useState("")
  const { complainceId } = useParams();
  const navigate = useNavigate();
  const {
    handleSubmit: handleSubmitReview,
    control: controlReview,
    formState: { errors : errorsReview },
    setValue : setValueReview,
    setError : setErrorReview,
    reset : resetReview,
  } = useForm();

  const {
    handleSubmit: handleSubmitGeneral,
    control: controlGeneral,
    formState: { errors: errorsGeneral },
    setValue: setValueGeneral,
    setError: setErrorGeneral,
    watch: watchGeneral,
    clearErrors: clearErrorsGeneral,
    reset:resetGeneral
  } = useForm();


  const [generalCommentDetails, setGeneralCommentDetails] = useState("");
  const [editModes, setEditModes] = useState(
    Array.isArray(generalCommentDetails)
      ? Array(generalCommentDetails.length).fill(false)
      : []
  );
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [generalCommentsData, setGeneralCommentsData] = useState("");
  const [commentsId, setCommentsId] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [createdDateAndTimes, setCreatedDateAndTimes] = useState("");
  const [createdByDetails,setCreatedByDetails]=useState("");
  const [createdToDetails,setCreatedToDetails]=useState("");
  const [homeDues,setHomeDues]=useState(0);
  const [reviewCommentss,setReviewCommentss]=useState("")
  const [fileError, setFileError] = useState("");


  const [file, setFile] = useState([]);

  const handleFileChange = (newFiles) => {
    setFile(newFiles);
  };

  const handleInputreviewComments = (e) => {
    setReviewCommentss(e.target.value);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };
  const HomeLoginId= userDetails.homeOwnerLoginId;

  const onSubmit = async (data) => {
    if (typeof reviewCommentss !== 'string' || reviewCommentss.trim() === '') {
      setErrorReview("reviewComment", {
        type: "manual",
        message: REVIEW_COMMENT_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("status", status);

    formData.append("comments", data.reviewComment);
    formData.append("role", "ROLE_HO");
    file.forEach((file) => {
      formData.append('files', file);
      });
    setIsLoading(true)
    console.log("formData:", formData);

    const storedToken = token;

    console.log(storedToken);
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${storedToken}`,
    };
    // return;

    request({
      url: `${BASE_URL}${COMPLIANCE_REVIEW}/${complainceId}`,
      method: "put",
      data: formData,
      headers,
    })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          setIsLoading(false);
          getComplianceRequestDetailsById();
          setReviewCommentss("")

        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const getComplianceRequestDetailsById = async () => {
    try {
        const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${COMPLIANCE_REQUEST_BY_ID}/${complainceId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        setIsLoading(false);

        const data = response.data.compliance;
        const lateFees = response.data.crfinehistory;
        const totalDueAmount = lateFees.reduce((accumulator, current) => (accumulator) + (+current['dueAmount']), 0);

console.log(totalDueAmount);
setHomeDues(totalDueAmount)
        const createdFrAddress = data.createdToDetails?.addressForCommunication
        const createdAddress = data.address.address;
        setCreatedByDetails(data.createdByDetails)
        setCreatedToDetails(data.createdToDetails)
        setStatus(data.status)
        setAddress(createdAddress);
        setFineApply(data.isFineApplicable);
        setAddressFrComunication(createdFrAddress)
        setComplianceRequestData(data);
        setSelectedStatus(data.status)
        setStatuss(data.status)
        setReviewComment(data.reviewComments);
        setFileUrl(data.fileUrl);
        setFileImage(data.attachments)
        const formattedDate = moment(data.createdDateAndTime).format(
          "MM/DD/YYYY hh:mm A"
        );
        setCreatedDateAndTimes(formattedDate);
        console.log(data.address.address);
        setAddress(data.address.address);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getComplianceRequestDetailsById();
  }, [complainceId]);

  const handleClick = () => {
    navigate(-1);
  };

  const handleEditClick = (index, generalCommentsId) => {
    console.log("Edit button clicked", index, generalCommentsId);
    setEditModes((prevModes) =>
      prevModes.map((mode, i) => (i === index ? true : mode))
    );
    setCommentsId(generalCommentsId);
    console.log("CommentsId set:", generalCommentsId);
    getGeneralCommentsById();
  };

  const handleInputChange = (e) => {
    setGeneralCommentsData(e.target.value);
};


const handleCancelClick = () => {
  clearErrorsGeneral("editComments");
  getGeneralComments();
  setEditModes(false);
  setValueGeneral('editComments',"")
};
  const handleInputChangeAdd = (e) => {
    setGeneralCommentsDataAdd(e.target.value);
  };
  const getGeneralComments = async () => {
    try {
     
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${GET_ALL_GENERAL_COMMENTS}/${complainceId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setGeneralCommentDetails(data);
        setEditModes(Array(data.length).fill(false));
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getGeneralComments();
  }, []);
  const onSubmitComments = (data,e) => {
   


    if (typeof generalCommentsDataAdd !== 'string' || generalCommentsDataAdd.trim() === '') {
      setErrorGeneral("comments", {
        type: "manual",
        message: COMMENTS_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    // setIsLoading(true);
    const postData = {
      comments: generalCommentsDataAdd.trim(),
      referenceId: complainceId,
    };
    request({ url: GENERAL_COMMENTS, method: "post", data: postData })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          getGeneralComments();
          console.log("Success");
          setIsLoading(false);
          setGeneralCommentsDataAdd("")
          // e.target.reset();
          // setTimeout(() => {
          //   navigate(-1);
          // }, 1000);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const getGeneralCommentsById = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${GENERAL_COMMENTS}/${commentsId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        console.log(response.data);
        setGeneralCommentsData(data.comments);
        setStatus(data.status);

        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  // useEffect(() => {
  //   getGeneralCommentsById();
  // }, [commentsId]);

  useEffect(() => {
    if(commentsId){
    getGeneralCommentsById();}
  }, [commentsId]);

  useEffect(()=>{
    if (generalCommentsData.trim().length > 1000) {
      setErrorGeneral("editComments", {
          type: "manual",
          message: MAX_LENGTH_THOUSAND,
      });
      setIsLoading(false);
      return;
  }
  },[generalCommentsData])

  const submitUpdate = (data) => {
    setIsLoading(true);

    // Perform validation checks again before submission
    if (generalCommentsData.trim().length === 0) {
        setErrorGeneral("editComments", {
            type: "manual",
            message: COMMENTS_REQUIRED_MESSAGE,
        });
        setIsLoading(false);
        return;
    }
    if (generalCommentsData.trim().length < 2) {
        setErrorGeneral("editComments", {
            type: "manual",
            message: MIN_LENGTH_VALIDATE_MESSAGE,
        });
        setIsLoading(false);
        return;
    }
    if (generalCommentsData.trim().length > 1000) {
        setErrorGeneral("editComments", {
            type: "manual",
            message: MAX_LENGTH_THOUSAND,
        });
        setIsLoading(false);
        return;
    }

    const putData = {
        comments: generalCommentsData.trim(),
        referenceId: commentsId,
    };

    request({
        url: `${GENERAL_COMMENTS}/${commentsId}`,
        method: "put",
        data: putData,
    })
    .then((res) => {
        if (res.status === 200) {
            setIsLoading(false);
            getGeneralComments();
        }
    })
    .catch((error) => {
        console.log(error.response);
        if (error.response) {
            console.log("error.response.status" + error.response.status);
        }
        setIsLoading(false);
    });
};

  const handleDeleteClickOpen = (generalCommentsId) => {
    setDeleteOpen(true);

    setCommentsId(generalCommentsId);
    getGeneralCommentsById();
  };
  const onSubmitDelete = () => {
    request({ url: `${GENERAL_COMMENTS}/${commentsId}`, method: "delete" })
      .then((res) => {
        console.log(res);
        getGeneralComments();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  // console.log(complianceRequestData);
  const renderDescription = () => {
    console.log("inside render ");
    if (complianceRequestData.description && !showFullDescription) {
    console.log("inside render ",complianceRequestData.description);
        
        return (
          <>
        
            {complianceRequestData.description.slice(0, 250)}
          {complianceRequestData.description.length > 250 && (
            <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Read More</Button>
          )}
          </>
        );
      } else {
    console.log("inside render else ",complianceRequestData.description);
        
        return (
          <>
            {complianceRequestData.description}
          <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Less</Button>
          </>
        );
    }
  };
  
  // const data = {
  //   pageTitle: "Compliance Request",
  //   buttons: [
  //     {
  //       buttonTitle: "Back",
  //       onClick: handleClick,
  //     },
  //   ],
  //   content: [
  //     {
  //       topic: "Compliance Request Id",
  //       description: `${
  //         complianceRequestData?.crId || ""
  //       }`
  //     },
  //     {
  //       topic: "Created By",
  //       description: `${
  //         complianceRequestData.createdByDetails?.firstName || ""
  //       } ${complianceRequestData.createdByDetails?.lastName || ""}`,
  //     },
  //     {
  //       topic: "Created Date Time",
  //       description: moment(complianceRequestData.createdDateAndTime).format(
  //         "MM/DD/YYYY hh:mm:ss A"
  //       ),
  //     },
  //     {
  //       topic: "Category ",
  //       description: `${complianceRequestData.categoryDetails?.category}`,
  //     },
  //     {
  //       topic: "ByLaws Topic",
  //       description: complianceRequestData.bylawDetails?.topic ?? "",
  //     },      
  //     {
  //       topic: "Audit Date Time",
  //       description: moment(complianceRequestData.auditDateAndTime).format(
  //         "MM/DD/YYYY hh:mm:ss A"
  //       ),
  //     },
  //     {
  //       topic: "Status",
  //       description: CustomPascalChange(statuss),
  //     },
  //     {
  //       topic: "Created To",
  //       description: `${
  //         complianceRequestData.createdToDetails?.firstName || "Home Owner"
  //       } ${
  //         complianceRequestData.createdToDetails?.lastName || "not activated"
  //       }`,
  //     },
  //     {
  //       topic :'Address For Communication',
  //       description : addressFrCommunication =='null' ? '': addressFrCommunication
  //     },
  //     {
  //       topic :'Address',
  //       description : address =='null' ? '': address
  //     },
  //     {
  //       topic :'Is Fine Applicable',
  //       description : fineApply ?'Yes': 'No'
  //     },
  //     {
  //       topic: "Description",
  //       description: renderDescription(),
  //     },
  //     {
  //       topic: "Attachments",
  //       component: "AttachmentList",
  //       props: {
  //         fileUrl: fileUrl,
  //         documentName: fileUrl,
  //         docname: fileUrl,
  //         uploadedTimes: createdDateAndTimes,
  //       },
  //     },
  //   ],
  // };

useEffect(()=>{
  console.log("fileurl",fileUrl);
},[fileUrl])


let formattedContact = "";
if (createdToDetails?.contact) {
  formattedContact = createdToDetails.contact.replace(/^\+\d+\s?(\d{3})(\d{4})(\d{3})$/, "$1-$2-$3");
}
  // const attachmentData = {
  //   props: {
  //     fileUrl: fileUrl,
  //         documentName: fileUrl,
  //         docname: fileUrl,
  //         uploadedTimes: createdDateAndTimes,
  //   }
  // };
  const fileList = fileImage.map(file => ({
    docname: file.file.substring(12), // Assuming file name is the document name
    fileUrl: file.file, // Assuming file location is the file URL
    documentnamed: file.file, // Assuming file name is the document name
    uploadedTimes: moment(file.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"), // Assuming uploadedTimes is available
    }));

  const data = [
    {
      groupName: 'Compliance Request Details',
      items: [
        { label: 'Compliance Request Id', data: `${complianceRequestData?.crId || ""}`, text: 'Unique identifier for the compliance request' },
        { label: 'Created Date Time', data: `${moment(complianceRequestData?.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a") || ""}`, text: 'The date and time when the compliance request was created' },
        { label: 'Category', data: `${complianceRequestData.categoryDetails?.category || ""}`, text: 'The category or subject of the compliance request' },
        { label: 'Description', data: renderDescription(), text: 'Detailed description of the compliance issue' },
        { label: 'ByLaws Topic', data: `${complianceRequestData.bylawDetails?.topic || ""}`, text: 'The topic of the bylaw related to the compliance issue' },
      { label: 'ByLaws Description', data: `${complianceRequestData.bylawDetails?.description || ""}`, text: 'The description of the bylaw related to the compliance issue' },

      ],
    },
    {
      groupName: 'Audit and Status Details',
      items: [
        { label: 'Audit Date Time', data: `${moment(complianceRequestData?.auditDateAndTime).format("MM/DD/YYYY hh:mm:ss a") || ""}`, text: 'The date and time of the last audit' },
        { label: 'Status', data: `${CustomPascalChange(statuss)}`, text: 'Current status of the compliance request (e.g., Open, Closed)' },
        
      
      ],
    },

    
    {
      groupName: 'Attachment Details',
      items: [
        
        { label: 'Attachment', data: (
          <AttachmentListsView fileList={fileList} />
        ), text: 'List of documents attached to the compliance request' },
      ],
    },
    {
      groupName: 'Created To Details',
      items: [
        {
          label: 'Firstname',
          label2: 'Lastname',
          data: `${createdToDetails?.firstName || ""}`,
          text: 'First name of the person or entity to whom the compliance request was created',
          data2: `${createdToDetails?.lastName || ""}`
        },
        { label: 'Email', data: `${createdToDetails?.email || ""}`, text: 'Email address of the person or entity to whom the compliance request was created' },
        { label: 'Address', data:`${complianceRequestData.address?.address ?? ""}`, text: 'Home address of the person or entity to whom the compliance request was created' },
        { label: 'Contact', data: `${formattedContact}`, text: 'Contact number of the person or entity to whom the compliance request was created' },
        // { label: 'Contact Category', data: "", text: 'Category or type of contact information provided' },
      ],
    },
  ];

  console.log("complainceid",complainceId)
  
//   if (
//     moment().diff(moment(complianceRequestData?.createdDateAndTime), 'days') >= 30 &&
//     HomeLoginId !== complianceRequestData.createdToDetails?.homeOwnerLoginId &&
//     (complianceRequestData.status === "PENDING" || complianceRequestData.status === "ACKNOWLEDGED") ||
//     homeDues !== 0
// ) {
//     data.splice(2, 0, {
//         groupName: 'Compliance Request Fine Details',
//         items: [
//             // ...(moment().diff(moment(complianceRequestData?.createdDateAndTime), 'days') >= 30 &&
//             //     HomeLoginId !== complianceRequestData.createdToDetails?.homeOwnerLoginId &&
//             //     (complianceRequestData.status === "PENDING" || complianceRequestData.status === "ACKNOWLEDGED")
//             //     ? [
//             //         {
//             //             label: 'Fine Details',
//             //             data: <CustomLateFees
//             //                 groupId={complianceRequestData.createdToDetails?.groupId}
//             //                 homeOwnerDetailsId={complianceRequestData.createdToDetails?.homeOwnerDetailsId}
//             //                 homeOwnerLoginId={complianceRequestData.createdToDetails?.homeOwnerLoginId}
//             //                 homeId={complianceRequestData.address?.homeId}
//             //                 complainceId={complainceId}
//             //             />
//             //         }
//             //     ]
//             //     : []
//             // ),
//             ...(homeDues !== 0
//                 ? [
//                     {
//                         label: "Late Fees",
//                         data: `${homeDues}`,
//                         text: "The late fees are for raised compliance that has not been resolved or responded to by a particular homeowner."
//                     }
//                 ]
//                 : []
//             ),
//         ],
//     });
// }

  const viewDataTable = useMemo((rowData) => [
    // {
    //   accessorKey: "serialNo",
    //   header: "S.No",
    //   size: 20,
    //   Cell: ({row}) => row.index+1
    // },
    // {
    //   accessorKey: "createdDateAndTime",
    //   header: "Assigned DateTime",
    //   size: 20,
    //   accessorFn: (row) => moment(createdDateAndTimes).format("MM/DD/YYYY HH:mm:ss A z"),
    // },
    {
      accessorKey: "assignedToName",
      header: "Assigned To",
      size: 20,
    },
    {
      accessorKey: "status",
      header: "Review OutCome",
      size: 20,
      Cell: ({ row }) => {
        const status = row.original.status;
        return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
      },
    },
    {
      accessorKey: "auditDateAndTime",
      header: "Review DateTime",
      size: 20,
      accessorFn: (row) => moment(row.auditDateAndTime).format("MM/DD/YYYY HH:mm:ss A z"),
    },
    // {
    //   accessorKey: "comments",
    //   header: "Review Comments",
    //   size: 20,
    // },
  ]);

  // // const isStatusPending = data.content.find((item) => item.topic === "Status").description !== "ACKNOWLEDGED" || data.content.find((item) => item.topic === "Status").description !=="Closed";
  // const statusItem = data.content.find((item) => item.topic === "Status");

  // // Check if statusItem is defined and has a description property
  // const statusAcknowledged =
  //   statusItem &&
  //   statusItem.description &&
  //   statusItem.description.toUpperCase() === "ACKNOWLEDGED";
  // const statusNotResolved =
  //   statusItem &&
  //   statusItem.description &&
  //   statusItem.description.toUpperCase() === "NOT RESOLVED";
  // const statusResolved =
  //   statusItem &&
  //   statusItem.description &&
  //   statusItem.description.toUpperCase() === "RESOLVED";

 

  // console.log(statusAcknowledged);

  const okCancelButtonActions = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleDeleteClose}
      />
      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleDeleteClose}
      />
    </>
  );

  const deleteModel = (
    <form
      onSubmit={handleSubmitGeneral(onSubmitDelete)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography> Are you sure you want to delete comment?</Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );

  return (
    <div>
      {isLoading && <CustomLoading />}
      {/* <CustomViewPageExpand data={data} /> */}
      <CustomViewHeading onClick={handleClick}  title={"Compliance Request"} data={data} fileList={fileList} description={(complianceRequestData.description)}>
      <CustomViewWithMore data={data}  />
     
      <Divider />
      <CustomToastContainer />
      {selectedStatus !== "CLOSED" && (
  <Card>
    <form onSubmit={handleSubmitReview(onSubmit)} className="space-y-4 md:space-y-6 ">
      <CardContent>
        <section class="grid md:grid-cols-2 xl:grid-cols-2 gap-5">
          <Controller
            name="status"
            control={controlReview}
            render={({ field, fieldState }) => (
              <FormControl variant="standard" fullWidth error={fieldState.invalid}>
                <InputLabel id="demo-simple-select-standard-label">
                  Please Select Status<span className="text-red-500">*</span>
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={status}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    setStatus(e.target.value); // Ensure status is controlled independently
                  }}
                  label="Please  Select Status"
                >
                  <MenuItem value="PENDING" disabled>Pending</MenuItem>
                  <MenuItem value="ACKNOWLEDGED" disabled>Acknowledged</MenuItem>
                  <MenuItem value="CLOSED" disabled>Closed</MenuItem>
                  <MenuItem value="RESOLVED" disabled>Resolved</MenuItem>
                  <MenuItem value="NOT RESOLVED">Not Resolved</MenuItem>
                </Select>
                <FormHelperText style={{ color: "#D32F2F" }}>
                  {fieldState.invalid ? fieldState.error?.message : ""}
                </FormHelperText>
              </FormControl>
            )}
          />

          <CustomInputField
            fieldName="reviewComment"
            requiredErrorMessage={REVIEW_COMMENT_REQUIRED_MESSAGE}
            minLength={2}
            minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
            maxLength={500}
            maxLengthErrorMessage={MAX_LENGTH_FIVE_HUNDRED}
            fieldId="reviewComment"
            fieldType="text"
            fieldLabel="Review Comment"
            fieldControl={controlReview}
            fieldError={errorsReview}
            fieldRequiredIcon="*"
            multiline={true}
            numberOfRows={1}
            value={reviewCommentss}
            onInput={handleInputreviewComments}
          />
 <CustomFile 
        handleFileChange={handleFileChange}
        token={token}
        fileError={fileError}
        file={file}
      />
        </section>
        <div class="text-center mt-10">
          <CustomButton fieldType="submit" buttonName="Update" />
        </div>
      </CardContent>
    </form>
  </Card>
)}
      <Divider />
      <br />
      <CustomReviewTableWithoutActions columns={viewDataTable} data={reviewComment} />

      <br />
      <br />
      {/* <CustomHeading title="Comments" />
      <CustomModel
        title=" Delete Comments"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        reset={resetGeneral}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      /> */}
      {/* <Card>
      <form
  onSubmit={handleSubmitGeneral(onSubmitComments)}

>
  <CardContent>
    <div className="flex items-center space-x-4">
      <CustomInputField
        fieldName="comments"
        // requiredErrorMessage={COMMENTS_REQUIRED_MESSAGE}
        minLength={2}
        minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
        maxLength={1000}
        maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
        fieldId="comments"
        fieldType="text"
        fieldLabel="Write a Comment"
        fieldControl={controlGeneral}
        fieldError={errorsGeneral}
        fieldRequiredIcon="*"
        multiline={true}
        numberOfRows={2}
        value={generalCommentsDataAdd}
        onInput={handleInputChangeAdd}
        className="flex-grow"
      />
      <CustomButton fieldType="submit" buttonName="Post" />
      {/* {isLoading && <CustomLoading />} 
    </div>
  </CardContent>
</form>
        <CardContent>
        {generalCommentDetails &&
  generalCommentDetails.map((comment, index) => (
    <div key={comment.generalCommentsId}>
      <section className="grid md:grid-cols-1 xl:grid-cols-1 gap-3 ml-5">
        <p>
          <span className="text-primary" style={{ float: "left" }}>
            @{comment.createdByName}
          </span>
          <span style={{ float: "right" }}>
            {!editModes[index] && comment.createdById === HomeLoginId && (
              <>
                {index === 0 && (
                  <>
                    <EditNoteOutlined
                      color="primary"
                      onClick={() => {
                        handleEditClick(index, comment.generalCommentsId);
                        console.log(comment.generalCommentsId);
                      }}
                    />
                    <DeleteOutline
                      color="error"
                      onClick={() => {
                        handleDeleteClickOpen(comment.generalCommentsId);
                        console.log(comment.generalCommentsId);
                      }}
                    />
                  </>
                )}
              </>
            )}
            {moment(comment.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a")}
          </span>

          {editModes[index] ? (
            <form
              onSubmit={handleSubmitGeneral(submitUpdate)}
              className="space-y-4 md:space-y-6"
            >
              <CardContent>
                <CustomInputField
                  fieldName="editComments"
                  fieldId="editComments"
                  fieldType="text"
                  fieldControl={controlGeneral}
                  fieldError={errorsGeneral}
                  multiline={true}
                  numberOfRows={2}
                  value={generalCommentsData}
                  onInput={handleInputChange}
                />
                <div className="flex justify-center mt-8 space-x-1">
                  <CustomButton fieldType="submit" buttonName="Save" />
                  <CustomButton
                    fieldType="button"
                    buttonName="Cancel"
                    click={handleCancelClick}
                  />
                  {isLoading && <CustomLoading />}
                </div>
              </CardContent>
            </form>
          ) : (
            <p className="mt-8 mb-2 break-words">{comment.comments}</p>
          )}
        </p>
      </section>

      <Divider />
    </div>
  ))}

        </CardContent>
      </Card> */}

      <CustomGeneralComments />
      </CustomViewHeading>
    </div>
  );
}

export default ComplianceRequestView;
