import React, { useEffect, useState } from 'react'
import CustomHeading from './CustomHeading';
import CustomModel from './CustomModel';
import { Card, CardContent, DialogActions, DialogContent, Divider, Typography } from '@mui/material';
import CustomInputField from './CustomInputField';
import CustomButton from './CustomButton';
import { DeleteOutline, EditNoteOutlined } from '@mui/icons-material';
import CustomLoading from './CustomLoading';
import { BASE_URL, GENERAL_COMMENTS, GET_ALL_GENERAL_COMMENTS } from '../utills/ApplicationRouting';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { request } from '../services/AxiosConfig';
import { COMMENTS_REQUIRED_MESSAGE, MAX_LENGTH_THOUSAND, MIN_LENGTH_VALIDATE_MESSAGE } from '../utills/ApplicationConstants';
import moment from 'moment';

function CustomGeneralComments() {


                  const { token,userDetails,userLoginDetails } = useSelector((state) => state.hybridhoa);
                
                  const [isLoading, setIsLoading] = useState(true);
                  
                  const [status,setStatus]=useState("");
                 
                  const HomeLoginId= userDetails.homeOwnerLoginId;
                
                  const [generalCommentsDataAdd, setGeneralCommentsDataAdd] = useState("");
               
                  const { complainceId, maintenanceId } = useParams();

                 
                  const activeId = complainceId || maintenanceId;
                  
                
                  const {
                    handleSubmit: handleSubmitGeneral,
                    control: controlGeneral,
                    formState: { errors: errorsGeneral },
                    setValue: setValueGeneral,
                    setError: setErrorGeneral,
                    watch: watchGeneral,
                    clearErrors: clearErrorsGeneral,
                    reset:resetGeneral
                  } = useForm();
                
                
                  const [generalCommentDetails, setGeneralCommentDetails] = useState("");
                  const [editModes, setEditModes] = useState(
                    Array.isArray(generalCommentDetails)
                      ? Array(generalCommentDetails.length).fill(false)
                      : []
                  );
                  const [deleteOpen, setDeleteOpen] = useState(false);
                  const [generalCommentsData, setGeneralCommentsData] = useState("");
                  const [commentsId, setCommentsId] = useState("");
                 
                  const handleDeleteClose = () => {
                    setDeleteOpen(false);
                  };
                 
                  const handleEditClick = (index, generalCommentsId) => {
                    console.log("Edit button clicked", index, generalCommentsId);
                    setEditModes((prevModes) =>
                      prevModes.map((mode, i) => (i === index ? true : mode))
                    );
                    setCommentsId(generalCommentsId);
                    console.log("CommentsId set:", generalCommentsId);
                    getGeneralCommentsById();
                  };
                
                  const handleInputChange = (e) => {
                    setGeneralCommentsData(e.target.value);
                };
                
                
                const handleCancelClick = () => {
                  clearErrorsGeneral("editComments");
                  getGeneralComments();
                  setEditModes(false);
                  setValueGeneral('editComments',"")
                };
                  const handleInputChangeAdd = (e) => {
                    setGeneralCommentsDataAdd(e.target.value);
                  };
                  const getGeneralComments = async () => {
                    try {
                     
                      const storedToken = token;
                
                      console.log(storedToken);
                
                      const response = await axios.get(
                        `${BASE_URL}${GET_ALL_GENERAL_COMMENTS}/${activeId}`,
                        {
                          headers: {
                            Authorization: `Bearer ${storedToken}`,
                          },
                        }
                      );
                      console.log(response.data);
                
                      if (response.status === 200) {
                        const data = response.data;
                        setGeneralCommentDetails(data);
                        setEditModes(Array(data.length).fill(false));
                        setIsLoading(false);
                      } else {
                        throw new Error("Failed to fetch data");
                      }
                    } catch (error) {
                      // Handle error
                      console.error("Error fetching data:", error);
                    }
                  };
                
                  useEffect(() => {
                    getGeneralComments();
                  }, []);
                  const onSubmitComments = (data,e) => {
                   
                
                
                    if (typeof generalCommentsDataAdd !== 'string' || generalCommentsDataAdd.trim() === '') {
                      setErrorGeneral("comments", {
                        type: "manual",
                        message: COMMENTS_REQUIRED_MESSAGE,
                      });
                      setIsLoading(false);
                      return;
                    }
                    // setIsLoading(true);
                    const postData = {
                      comments: generalCommentsDataAdd.trim(),
                      referenceId: activeId,
                    };
                    request({ url: GENERAL_COMMENTS, method: "post", data: postData })
                      .then((res) => {
                        console.log(res);
                        if (res.status == 200) {
                          getGeneralComments();
                          console.log("Success");
                          setIsLoading(false);
                          setGeneralCommentsDataAdd("")
                          // e.target.reset();
                          // setTimeout(() => {
                          //   navigate(-1);
                          // }, 1000);
                        }
                      })
                      .catch((error) => {
                        console.log(error.response);
                        if (error.response) {
                          console.log("error.response.status" + error.response.status);
                        }
                      });
                  };
                
                  const getGeneralCommentsById = async () => {
                    try {
                      const storedToken = token;
                
                      console.log(storedToken);
                
                      const response = await axios.get(
                        `${BASE_URL}${GENERAL_COMMENTS}/${commentsId}`,
                        {
                          headers: {
                            Authorization: `Bearer ${storedToken}`,
                          },
                        }
                      );
                      console.log(response);
                
                      if (response.status === 200) {
                        const data = response.data;
                        console.log(response.data);
                        setGeneralCommentsData(data.comments);
                        setStatus(data.status);
                
                        setIsLoading(false);
                      } else {
                        throw new Error("Failed to fetch data");
                      }
                    } catch (error) {
                      // Handle error
                      console.error("Error fetching data:", error);
                    }
                  };
                
                  // useEffect(() => {
                  //   getGeneralCommentsById();
                  // }, [commentsId]);
                
                  useEffect(() => {
                    if(commentsId){
                    getGeneralCommentsById();}
                  }, [commentsId]);
                
                



                  
                                  

                                  
                                  const submitUpdate = (data) => {
                                    setIsLoading(true);
                                  
                                    // Perform validation checks again before submission
                                    if (generalCommentsData.trim().length === 0) {
                                      setErrorGeneral("editComments", {
                                        type: "manual",
                                        message: COMMENTS_REQUIRED_MESSAGE,
                                      });
                                      setIsLoading(false);
                                      return;
                                    }
                                    // if (generalCommentsData.trim().length < 2) {
                                    //   setErrorGeneral("editComments", {
                                    //     type: "manual",
                                    //     message: MIN_LENGTH_VALIDATE_MESSAGE,
                                    //   });
                                    //   setIsLoading(false);
                                    //   return;
                                    // }
                                    // if (generalCommentsData.trim().length > 1000) {
                                    //   setErrorGeneral("editComments", {
                                    //     type: "manual",
                                    //     message: MAX_LENGTH_THOUSAND,
                                    //   });
                                    //   setIsLoading(false);
                                    //   return;
                                    // }
                                  
                                    const putData = {
                                      comments: generalCommentsData.trim(),
                                      referenceId: commentsId,
                                    };
                                  
                                    request({
                                      url: `${GENERAL_COMMENTS}/${commentsId}`,
                                      method: "put",
                                      data: putData,
                                    })
                                      .then((res) => {
                                        if (res.status === 200) {
                                          setIsLoading(false);
                                          getGeneralComments();
                                        }
                                      })
                                      .catch((error) => {
                                        console.log(error.response);
                                        if (error.response) {
                                          console.log("error.response.status" + error.response.status);
                                        }
                                        setIsLoading(false);
                                      });
                                  };
                                  
                
                  const handleDeleteClickOpen = (generalCommentsId) => {
                    setDeleteOpen(true);
                
                    setCommentsId(generalCommentsId);
                    getGeneralCommentsById();
                  };
                  const onSubmitDelete = () => {
                    request({ url: `${GENERAL_COMMENTS}/${commentsId}`, method: "delete" })
                      .then((res) => {
                        console.log(res);
                        getGeneralComments();
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  };
                
                 
                
                  const okCancelButtonActions = (
                    <>
                      
                      <CustomButton
                        fieldType="button"
                        buttonName="Cancel"
                        click={handleDeleteClose}
                      />
                      <CustomButton
                        fieldType="submit"
                        buttonName="Ok"
                        click={handleDeleteClose}
                      />
                    </>
                  );
                
                  const deleteModel = (
                    <form
                      onSubmit={handleSubmitGeneral(onSubmitDelete)}
                      className="space-y-4 md:space-y-6 "
                    >
                      <DialogContent dividers>
                        <Typography> Are you sure you want to delete comment?</Typography>
                      </DialogContent>
                      <DialogActions>{okCancelButtonActions}</DialogActions>
                    </form>
                  );
                

  return (
    <div>
                  <CustomHeading title="Comments" />
      <CustomModel
        title=" Delete Comments"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        reset={resetGeneral}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      />
      <Card>
      <form
  onSubmit={handleSubmitGeneral(onSubmitComments)}

>
  <CardContent>
    <div className="flex items-center space-x-4">
      <CustomInputField
        fieldName="comments"
        // requiredErrorMessage={COMMENTS_REQUIRED_MESSAGE}
        minLength={2}
        minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
        maxLength={1000}
        maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
        fieldId="comments"
        fieldType="text"
        fieldLabel="Write a Comment"
        fieldControl={controlGeneral}
        fieldError={errorsGeneral}
        fieldRequiredIcon="*"
        multiline={true}
        numberOfRows={2}
        value={generalCommentsDataAdd}
        onInput={handleInputChangeAdd}
        className="flex-grow"
      />
      <CustomButton fieldType="submit" buttonName="Post" />
      {/* {isLoading && <CustomLoading />} */}
    </div>
  </CardContent>
</form>
        <CardContent>
        {generalCommentDetails &&
  generalCommentDetails.map((comment, index) => (
    <div key={comment.generalCommentsId}>
      <section className="grid md:grid-cols-1 xl:grid-cols-1 gap-3 ml-5">
        <p>
          <span className="text-primary" style={{ float: "left" }}>
            @{comment.createdByName}
          </span>
          <span style={{ float: "right" }}>
            {!editModes[index] && (comment.createdById === HomeLoginId || comment.createdById === userLoginDetails.homeOwnerLoginId) && (
              <>
                {index === 0 && (
                  <>
                    <EditNoteOutlined
                      color="primary"
                      onClick={() => {
                        handleEditClick(index, comment.generalCommentsId);
                        console.log(comment.generalCommentsId);
                      }}
                    />
                    <DeleteOutline
                      color="error"
                      onClick={() => {
                        handleDeleteClickOpen(comment.generalCommentsId);
                        console.log(comment.generalCommentsId);
                      }}
                    />
                  </>
                )}
              </>
            )}
            {moment(comment.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a")}
          </span>

          {editModes[index] ? (
            <form
              onSubmit={handleSubmitGeneral(submitUpdate)}
              className="space-y-4 md:space-y-6"
            >
              <CardContent>
                <CustomInputField
                  fieldName="editComments"
                  fieldId="editComments"
                  fieldType="text"
                  minLength={2}
                  minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                  maxLength={1000}
                  maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
                  fieldControl={controlGeneral}
                  fieldError={errorsGeneral}
                  multiline={true}
                  numberOfRows={2}
                  value={generalCommentsData}
                  onInput={handleInputChange}
                />
                <div className="flex justify-center mt-8 space-x-1">
                  <CustomButton fieldType="submit" buttonName="Save" />
                  <CustomButton
                    fieldType="button"
                    buttonName="Cancel"
                    click={handleCancelClick}
                  />
                  {isLoading && <CustomLoading />}
                </div>
              </CardContent>
            </form>
          ) : (
            <p className="mt-8 mb-2 break-words">{comment.comments}</p>
          )}
        </p>
      </section>

      <Divider />
    </div>
  ))}

        </CardContent>
      </Card>
    </div>
  )
}

export default CustomGeneralComments