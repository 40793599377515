import React, { useEffect, useMemo, useState } from "react";
import CustomHeading from "../../components/CustomHeading";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import { useNavigate } from "react-router-dom";
import { ListAlt } from "@mui/icons-material";
import { useSelector } from "react-redux";
import {
  BASE_URL,
  GET_ALL_HO_DOCUMENT,
  HO_DOCUMENT,
} from "../../utills/ApplicationRouting";
import axios from "axios";
import moment from "moment";
import CustomMaterialWithoutActons from "../../components/CustomMaterialWithoutActons";
import CustomLoading from "../../components/CustomLoading";

function DocumentList() {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.hybridhoa);
  const [documentDetails, setDocumentDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [fileUrl, setFileUrl] = useState([]);
  const handleViewClick = (selectedRow) => {
    navigate(`/document/${selectedRow.row.original.documentId}`);
  };

  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === "" || str === null) return "";

    // Remove underscores and convert string to Pascal case
    return str
      .replace(/_/g, " ")
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const getDocumentDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GET_ALL_HO_DOCUMENT}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        console.log("data" + JSON.stringify(data));
        setDocumentDetails(data);
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(true);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDocumentDetails();
  }, []);

  const documentTable = useMemo((rowData) => [
    {
      accessorKey: "serialNo",
      header: "#",
      size: 20,
      Cell: ({ row }) => row.index + 1,
      sortDescFirst: true,
    },
    {
      accessorKey: "category",
      header: "Category",
      size: 20,
      accessorFn: (row) => convertToPascalCase(row.category),
    },
    {
      accessorKey: "description",
      header: "Description",
      size: 20,
      accessorFn: (row) => {
        const description = row && row.description;
        if (description && description.length > 28) {
          return description.slice(0, 28) + "...";
        }
        return description;
      },
    },
    {
      accessorKey: "Attachments",
      header: "Attachment",
      accessorFn: (row) => {
        // Check if row and row.attachments are valid
        if (row && row.attachments && Array.isArray(row.attachments)) {
          return row.attachments
            .map(attachment => {
              // Ensure 'file' exists and is long enough to apply substring
              if (attachment.file && attachment.file.length > 12) {
                // Get the substring starting from the 12th character
                const fileString = attachment.file.substring(12);
                console.log("Substring:", fileString); // Debug log
                // Split by comma (will not affect anything if no commas exist)
                return fileString.split(',');  
              } else {
                console.log("Invalid file or too short:", attachment.file);  // Debug log
                return [];
              }
            });
        } else {
          console.log("Invalid row or attachments array");  // Debug log
          return [];
        }
      }
      
    },

    //   {
    //     accessorKey: "createdDateAndTime",
    //     header: "Created Date",
    //     accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),
    //   },
    // {
    //   accessorKey: "uploadedBy",
    //   header: "Uploaded By",
    //   accessorFn: (row) => `${row.createdByDetails? row.createdByDetails.firstName : ""} ${row.createdByDetails? row.createdByDetails.lastName : ""} `,
    //   sortDescFirst: true,

    // },
    // {
    //     accessorKey: "publishDate",
    //     header: "Publish Date",
    //   },
    //   {
    //     accessorKey: "status",
    //     header: "Status",
    //     size: 20,
    //     accessorFn: (row) => convertToPascalCase(row.status),
    //   },
  ]);

  // const rowActions = [
  //   {
  //     label: "View",
  //     icon: <ListAlt color="primary" />,
  //     click: (row)=>handleViewClick(row),
  //   },

  // ]

  return (
    <div>
      {isLoading && <CustomLoading />}
      <CustomHeading title="Documents" />

      <CustomMaterialWithoutActons
        columns={documentTable}
        data={documentDetails}
        // rowActions ={rowActions}
        isLoading={isLoading}
        onRowClick={handleViewClick}
      />
    </div>
  );
}

export default DocumentList;
