import axios from 'axios';
import React, { useEffect,useMemo, useState } from 'react'
import { useForm,Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL,MAINTENANCE_REQUEST_BY_ID, GENERAL_COMMENTS, GET_ALL_GENERAL_COMMENTS, MAINTENANCE_REQUEST,MAINTENANCE_REVIEW_COMMANENTS } from '../../../utills/ApplicationRouting';
import { DeleteOutline, EditNoteOutlined, FileDownloadOutlined } from '@mui/icons-material';
import CustomViewPage from '../../../components/CustomViewPage';
import CustomHeading from '../../../components/CustomHeading';
import { Card, CardContent, DialogActions, DialogContent, Divider, Typography,FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select, } from '@mui/material';
import CustomInputField from '../../../components/CustomInputField';
import { COMMENTS_REQUIRED_MESSAGE, MAX_LENGTH_THOUSAND, MIN_LENGTH_VALIDATE_MESSAGE,  MAX_LENGTH_FIVE_THOUSAND,REVIEW_COMMENT_REQUIRED_MESSAGE,
  STATUS_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_HUNDRED, } from '../../../utills/ApplicationConstants';
import CustomButton from '../../../components/CustomButton';
import moment from 'moment';
import CustomLoading from '../../../components/CustomLoading';
import CustomToastContainer from '../../../components/CustomToastContainer';
import { request } from '../../../services/AxiosConfig';
import CustomModel from '../../../components/CustomModel';
import CustomMaterialTable from "../../../components/CustomMaterialTable";
import { FileUploader } from "react-drag-drop-files";
import Tooltip from "@mui/material/Tooltip";
import AttachmentList from '../../../components/AttachmentList';
import CustomViewHeading from '../../../components/CustomViewHeading';
import CustomViewWithTooltip from '../../../components/CustomViewWithTooltip';
import AttachmentListsView from '../../../components/AttachmentListsView';
import CustomFile from '../../../components/CustomFile';
import CustomReviewTableWithoutActions from '../../../components/CustomReviewTableWithoutActions';
import CustomViewWithMore from '../../../components/CustomViewWithMore';
import CustomGeneralComments from '../../../components/CustomGeneralComments';


function BmArcMaintenanceView() {
    const navigate = useNavigate();
    const {maintenanceId} = useParams();
    const [maintenanceRequestData,setMaitenanceRequestData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { token,userDetails } = useSelector(state => state.hybridhoa);
    const {
      handleSubmit: handleSubmitGeneral,
      control: controlGeneral,
      formState: { errors: errorsGeneral },
      setValue: setValueGeneral,
      setError: setErrorGeneral,
      reset: reset,
      watch: watchGeneral,
      clearErrors:clearErrorsGeneral,
    } = useForm();
    
    const {
      handleSubmit: handleSubmitReview,
      control: controlReview,
      formState: { errors: errorsReview },
      setValue: setValueReview,
      setError: setErrorReview,
      reset: resetReview,
      watch: watchReview,
    } = useForm();
    // const [generalCommentDetails, setGeneralCommentDetails] = useState("");
    // const [editModes, setEditModes] = useState(Array.isArray(generalCommentDetails) ? Array(generalCommentDetails.length).fill(false) : []);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [generalCommentsData,setGeneralCommentsData] = useState('');
    const [commentsId,setCommentsId] = useState('');

    const [selectedStatus, setSelectedStatus] = useState("");
    const [file, setFile] = useState([]);
    const fileTypes = ["JPG", "PNG", "JPEG","PDF"];
    const [errorMessage, setErrorMessage] = useState('');
    const maxSize=1;
    const [reviewComment,setReviewComment]=useState([])
    const [status,setStatus]=useState("");
    const [review, setReview] = useState("");
  const [generalCommentDetails, setGeneralCommentDetails] = useState("");
  const[fileImage,setFileImage]=useState([])
    

    const [fileUrl,setFileUrl]=useState("");
const [createdDateAndTime,setCreatedDateAndTime]=useState("");
const [generalCommentsDataAdd, setGeneralCommentsDataAdd] = useState("");
const [editModes, setEditModes] = useState(
  Array.isArray(generalCommentDetails)
    ? Array(generalCommentDetails.length).fill(false)
    : []
);
const HomeLoginId= userDetails.homeOwnerLoginId;

    const onSubmits = (data) => {};

  
      const handleClick =() =>{
          navigate(-1)
      }
      const handleDeleteClose = () => {
        setDeleteOpen(false);
      };

      const getMaintenanceRequestDetailsById = async () => {
        try {
          setIsLoading(true);
              const storedToken = token
    
          console.log(storedToken);
          
            const response= await axios.get(`${BASE_URL}${MAINTENANCE_REQUEST_BY_ID}/${maintenanceId}`,{
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });
                  console.log(response.data); 
    
          if (response.status === 200) {
            const data = response.data;
            console.log("mrIds", data.mrId);
            setMaitenanceRequestData(data);
            setIsLoading(false);

            setSelectedStatus(data.status);
            setStatus(data.status);
            setValueReview("status",data.status)
            setReviewComment(data.auditList);
            setFileImage(data.attachments)
            setFileUrl(data.fileUrl);

            const formattedDate = moment(data.createdDateAndTime).format("MM/DD/YYYY hh:mm A");
            setCreatedDateAndTime(formattedDate)

          } else {
            setIsLoading(false);
            throw new Error('Failed to fetch data');
          }
         
        } catch (error) {
          // Handle error
          setIsLoading(false);
          console.error("Error fetching data:", error);
        }
      };
    
    
    
    useEffect(()=>{
      getMaintenanceRequestDetailsById();
    },[maintenanceId])

    const handleFileChange = (file) => {
      setFile(file);
    };

    const onSubmit = (data) => {
      const totalFileSize = file.reduce((acc, file) => acc + file.size, 0);
      const maxSizeInMB = 10;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    
      if (totalFileSize > maxSizeInBytes) {
        // setFileError(`Total file size should not exceed ${maxSizeInMB}MB.`);
        return;
      }
    
      // Check file types
      const allowedFileTypes = ["image/jpg", "image/png", "image/jpeg", "application/pdf"];
      const invalidFiles = file.filter(file => !allowedFileTypes.includes(file.type));
    
      if (invalidFiles.length > 0) {
        // setFileError("Some files have invalid file types. Allowed types are JPG, PNG, JPEG, PDF.");
        return;
      }
      const formData = new FormData();
  formData.append('status',data.status);

  formData.append('comments',review);
  file.forEach((file) => {
    formData.append('files', file);
    });
  formData.append('role','ROLE_BM');

    
      // Debugging console.log statements
      console.log('jsonData:', formData);
    
      
    
      // Sending POST request using Axios
      request({ url: `${MAINTENANCE_REVIEW_COMMANENTS}${maintenanceId}`, method: "put", data: formData })
        .then(res => {
          // setIsLoading(false);
          console.log("inside submit");
          console.log(res);
          if (res.status === 200) {
            // If the update is successful, fetch the updated data and update the review comments state
            getUpdatedReviewComments();
            setReview('');
           
          }
        })
        .catch(error => {
          console.log(error.response);
          if (error.response) {
            console.log("error.response.status" + error.response.status);
          }
        });
    };
    
    const getUpdatedReviewComments = async () => {
      try {
        // Fetch the updated review comments data
        const storedToken = token;
        const response = await axios.get(`${BASE_URL}${MAINTENANCE_REQUEST_BY_ID}/${maintenanceId}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
    
        if (response.status === 200) {
          const data = response.data;
          setReviewComment(data.auditList); // Update the review comments state with the updated data
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (error) {
        console.error("Error fetching updated data:", error);
      }
    };

    
    const convertToPascalCase = (str) => {
      // Check if the input string is undefined or empty
      if (str === undefined || str === '') return '';
    
      // Remove underscores and convert string to Pascal case
      return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
    };
    
  
    // const data = {
    //   pageTitle: "View Maintenance",
    //   buttons: [
    //     {
    //       buttonTitle: "Back",
    //       onClick: handleClick,
    //     },
    //   ],
    //   content: [
    //     {
    //       topic: "MaintenanceRequest Id",
    //       description: maintenanceRequestData.mrId,
    //     },
    //     {
    //       topic: "What needs attention?",
    //       description: maintenanceRequestData.topic,
    //     },
    //     {
    //       topic: "More Information ",
    //       description:maintenanceRequestData.description,
    //     },
    //     {
    //       topic: "Requested Date Time",
    //       description: moment(maintenanceRequestData.createdDateAndTime).format("MM/DD/YYYY"),
    //     },
    //     {
    //       topic: "Updated Date Time",
    //       description: moment(maintenanceRequestData.auditDateAndTime).format("MM/DD/YYYY"),
    //     },
    //     {
    //       topic: "Is this issue urgent? ",
    //       description: maintenanceRequestData.isUrgent ? "Yes":"No",
    //     },  
    //     {
    //         topic: "Job-Category",
    //         description: maintenanceRequestData.jobDetails?.occupation||'Unknown',
    //       },

    //     {
    //       topic: "Is this issue causing a threat to personal safety?",
    //       description: maintenanceRequestData.isSafty ? "Yes":"No",
    //     },
    //     {
    //       topic: "Created By",
    //       description: `${
    //         maintenanceRequestData.createdByDetails?.firstName || 'Unknown'
    //       } ${
    //         maintenanceRequestData.createdByDetails?.lastName || 'Unknown'
    //       }`,
    //     },
    //     {
    //       topic: "Status",
    //       description:convertToPascalCase(maintenanceRequestData.status),
          
    //     },
     
    //     {
    //       topic: "Attachments",
    //       component: "AttachmentList", 
    //       props: {
    //         fileUrl: fileUrl,
    //         documentName: fileUrl,
    //         docname: fileUrl,
    //         uploadedTimes: createdDateAndTime,
    //       },
    //     },
    //   ],
    // };
    const fileList = fileImage.map(file => ({
      docname: file.file.substring(12), // Assuming file name is the document name
      fileUrl: file.file, // Assuming file location is the file URL
      documentnamed: file.file, // Assuming file name is the document name
      uploadedTimes: moment(file.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"), // Assuming uploadedTimes is available
      }));
     const data = [
    {
      groupName: 'Maintenance Details',
      items: [
        { label: 'MaintenanceRequest Id', data: `${maintenanceRequestData?.mrId || ""}`, text: 'Unique identifier for the maintenance request.' },
        { label: 'What needs attention?', data: `${maintenanceRequestData?.topic || ""}`, text: 'Description of the issue or topic that needs attention.' },
        { 
          label: 'More Information', 
          data: maintenanceRequestData?.description !== "undefined" ? maintenanceRequestData.description : " ", 
          text: 'Additional information or details about the maintenance request.' 
        },
        { label: 'Is this issue urgent?', data: `${maintenanceRequestData.isUrgent ? "Yes" : "No" || ""}`, text: 'Indicates whether the issue requires immediate attention (Yes/No).' },
        { label: 'Is this issue causing a threat to personal safety?', data: `${maintenanceRequestData.isSafety ? "Yes" : "No" || ""}`, text: 'Indicates whether the issue poses a threat to personal safety (Yes/No).' },
        { label: 'Status', data: `${convertToPascalCase(maintenanceRequestData?.status) || ""}`, text: 'Current status of the maintenance request.' },
        // { label: 'Request Type', data: `${maintenanceRequestData?.type || ""}`, text: 'Type or category of the maintenance request.' },
      ],
    },
    {
      groupName: 'Date Details',
      items: [
       
        { label: 'Requested Date', data: `${moment(maintenanceRequestData?.createdDateAndTime).format("MM/DD/YYYY") || "" }`, text: 'Date and time when the maintenance request was submitted.' },
        { label: 'Updated Date', data: `${moment(maintenanceRequestData?.auditDateAndTime).format("MM/DD/YYYY") || ""}`, text: 'Date and time when the maintenance request was last updated.' },
      ],
    },
    {
      groupName: 'Attachment Details',
      items: [
        { label: 'Attachment', data: (
          <AttachmentListsView fileList={fileList} />
          ), text: 'List of attached files/documents related to the maintenance request.' },
      ],
    },
  ];

    const handleReviewChange = (event) => {
      setReview(event.target.value);
    };
    const viewDataTable = useMemo((rowData) => [
      {
        accessorKey: "serialNo",
        header: "Id",
        size: 20,
        Cell: ({ row }) => row.index + 1,
      },
     
      {
        accessorKey: "name",
        header: "Assigned To",
        size: 20
     
      },
      {
        accessorKey: "status",
        header: "Review OutCome",
        size: 20
      },
            {
        accessorKey: "createdDateAndTime",
        header: "Review DateTime",
        size: 20,
        accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),
      },
      // {
      //   accessorKey: "comments",
      //   header: "Review Comments",
      //   size: 20
      // },
    ]);

    const handleEditClick = (index, generalCommentsId) => {
      console.log("Edit button clicked", index, generalCommentsId);
      setEditModes((prevModes) =>
        prevModes.map((mode, i) => (i === index ? true : mode))
      );
      setCommentsId(generalCommentsId);
      console.log("CommentsId set:", generalCommentsId);
      getGeneralCommentsById();
    };
  
    const handleInputChange = (e) => {
      setGeneralCommentsData(e.target.value);
    };
    const handleInputChangeAdd = (e) => {
      setGeneralCommentsDataAdd(e.target.value);
    };
    const handleCancelClick = () => {
      setValueGeneral('editComments',"")
      getGeneralComments();
      setEditModes(false);
      clearErrorsGeneral('')
    };
  
    const getGeneralComments = async () => {
      try {
        setIsLoading(true);
        const storedToken = token;
  
        console.log(storedToken);
  
        const response = await axios.get(
          `${BASE_URL}${GET_ALL_GENERAL_COMMENTS}/${maintenanceId}`,
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
        console.log(response.data);
  
        if (response.status === 200) {
          const data = response.data;
          setGeneralCommentDetails(data);
          setEditModes(Array(data.length).fill(false));
          setIsLoading(false);
        } else {
          setIsLoading(false);
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        // Handle error
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    };
  
    useEffect(() => {
      getGeneralComments();
    }, []);
    const onSubmitGeneral = (data,e) => {
    
      setIsLoading(true);
  
      // if (generalCommentsData == "" || generalCommentsData == null) {
      //   setError("comments", {
      //     type: "manual",
      //     message: COMMENTS_REQUIRED_MESSAGE,
      //   });
      //   setIsLoading(false);
      //   return;
      // }
      if (typeof generalCommentsDataAdd !== 'string' || generalCommentsDataAdd.trim() === '') {
        setErrorGeneral("comments", {
          type: "manual",
          message: COMMENTS_REQUIRED_MESSAGE,
        });
        setIsLoading(false);
        return;
      }
      const postData = {
        comments: generalCommentsDataAdd,
        referenceId: maintenanceId,
      };
      request({ url: GENERAL_COMMENTS, method: "post", data: postData })
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            getGeneralComments();
            // postData = {};
            console.log("Success");
            setIsLoading(false);
            setGeneralCommentsDataAdd("")  
            // e.target.reset();
            // formRef.current.reset(); 
            setTimeout(() => {
              //  navigate(-1);
            }, 1000);
          }
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response) {
            console.log("error.response.status" + error.response.status);
          }
        });
    };
  
    const getGeneralCommentsById = async () => {
      try {
        const storedToken = token;
  
        console.log(storedToken);
  
        const response = await axios.get(
          `${BASE_URL}${GENERAL_COMMENTS}/${commentsId}`,
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
        console.log(response);
  
        if (response.status === 200) {
          const data = response.data;
          console.log(response.data);
          setGeneralCommentsData(data.comments);
  
          setIsLoading(false);
        } else {
          setIsLoading(false);
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        // Handle error
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    };
  
    useEffect(() => {
      getGeneralCommentsById();
    }, [commentsId]);
  
    useEffect(()=>{
      if (generalCommentsData.trim().length > 1000) {
        setErrorGeneral("editComments", {
            type: "manual",
            message: MAX_LENGTH_THOUSAND,
        });
        setIsLoading(false);
        return;
    }
    },[generalCommentsData])

    const submitUpdate = (data) => {
      console.log("Test");
      setIsLoading(true);
    
  
      if (generalCommentsData.trim() == "" || generalCommentsData.trim() == null) {
        setErrorGeneral("editComments", {
          type: "manual",
          message: COMMENTS_REQUIRED_MESSAGE,
        });
        setIsLoading(false);
        return;
      }
      // if (generalCommentsData.trim().length < 2) {
      //   setErrorGeneral("editComments", {
      //     type: "manual",
      //     message: MIN_LENGTH_VALIDATE_MESSAGE,
      //   });
      //   setIsLoading(false);
      //   return;
      // }
  
      // if (generalCommentsData.trim().length > 1000) {
      //   setErrorGeneral("editComments", {
      //     type: "manual",
      //     message: MAX_LENGTH_THOUSAND,
      //   });
      //   setIsLoading(false);
      //   return;
      // }
      const putData = {
        comments: generalCommentsData.trim(),
        referenceId: commentsId,
      };
      request({
        url: `${GENERAL_COMMENTS}/${commentsId}`,
        method: "put",
        data: putData,
      })
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            console.log("Success");
            setIsLoading(false);
            getGeneralComments();
          }
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response) {
            console.log("error.response.status" + error.response.status);
          }
        });
    };
  
    const handleDeleteClickOpen = (generalCommentsId) => {
      setDeleteOpen(true);
  
      setCommentsId(generalCommentsId);
      getGeneralCommentsById();
    };
    const onSubmitDelete = () => {
      request({ url: `${GENERAL_COMMENTS}/${commentsId}`, method: "delete" })
        .then((res) => {
          console.log(res);
          getGeneralComments();
        })
        .catch((error) => {
          console.log(error);
        });
    };
  
    const okCancelButtonActions = (
      <>
        <CustomButton
          fieldType="button"
          buttonName="Cancel"
          click={handleDeleteClose}
        />
 <CustomButton
          fieldType="submit"
          buttonName="Ok"
          click={handleDeleteClose}
        />
      </>
    );
  
    const deleteModel = (
      <form
        onSubmit={handleSubmitGeneral(onSubmitDelete)}
        className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography> Are you sure you want to delete comment?</Typography>
        </DialogContent>
        <DialogActions>{okCancelButtonActions}</DialogActions>
      </form>
    );
  return (
    <>
     <CustomViewHeading onClick={handleClick} fileList={fileList} information={(maintenanceRequestData.description)}  title={"Maintenance Request"} data={data}attention={maintenanceRequestData.topic} >
      <CustomViewWithMore data={data}  />
    <div>
      {/* <CustomViewPage data={data} /> */}
        {isLoading && <CustomLoading />}

     
      <Divider />
      <br />
      {(selectedStatus !== "CLOSED") && (
        <>
          <Card>
            <form
              onSubmit={handleSubmitReview(onSubmit)}
              className="space-y-4 md:space-y-6 "
            >

              <CustomToastContainer />
              <CardContent>
                <section class="grid md:grid-cols-1 xl:grid-cols-2 gap-5">


                  <Controller
                    name="status"
                    control={controlReview}
                    rules={{ required: STATUS_REQUIRED_MESSAGE }}
                    render={({ field, fieldState }) => (
                      <FormControl
                        variant="standard"
                        fullWidth
                        error={fieldState.invalid}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Select Status <span style={{ color: 'red' }}>*</span>
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={field.value || status}
                          onChange={(value) => {
                            field.onChange(value);
                          } }
                          label="Select Status"
                        >
                          <MenuItem disabled value="IN PROGRESS">
                            In Progress
                          </MenuItem>
                          <MenuItem value="PENDING" >Pending</MenuItem>
                          {/* <MenuItem value="PENDING APPROVAL">Pending Approval</MenuItem> */}
                          <MenuItem  value="COMPLETED">Completed</MenuItem>
                          <MenuItem value="CLOSED">Closed</MenuItem>

                        </Select>
                        <FormHelperText style={{ color: "#D32F2F" }}>
                          {fieldState.invalid ? fieldState.error?.message : ""}
                        </FormHelperText>
                      </FormControl>
                    )} />

                  {/* <FormLabel>
                    Attach Documents
                    <FileUploader
                      handleChange={handleFileChange}
                      name="file"
                      types={fileTypes}
                      maxSize={maxSize} />
                    {file ? (
                      <p>Selected File: {file.name}</p>
                    ) : (
                      <p style={{ color: 'red' }}>{errorMessage}</p>
                    )}
                  </FormLabel> */}

<CustomFile file={file} handleFileChange={handleFileChange} token={token} tooltipMessage={"Upload supporting documents such as lease agreement, identity proof, etc."} />

                  <CustomInputField
                    fieldName="reviewComment"
                    requiredErrorMessage={REVIEW_COMMENT_REQUIRED_MESSAGE}
                    minLength={2}
                    minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                    maxLength={500}
                    maxLengthErrorMessage={MAX_LENGTH_FIVE_HUNDRED}
                    fieldId="reviewComment"
                    fieldType="text"
                    fieldLabel="Review Comment"
                    fieldControl={controlReview}
                    fieldError={errorsReview}
                    fieldRequiredIcon="*"
                    multiline={true}
                    numberOfRows={5}
                    value={review}
                    onInput={handleReviewChange} />




                </section>
                <div class="text-center mt-10">
                  <CustomButton fieldType="submit" buttonName="Update" />
                </div>
              </CardContent>
            </form>
          </Card>
        </>
      )}

      <Divider />
      <CustomReviewTableWithoutActions
        columns={viewDataTable}
        // data={viewData}
        data={reviewComment} />

      <br />
      <br />


    </div>
    {/* <div> */}
        {/* {isLoading && <CustomLoading />} */}
        {/* <CustomViewPage data={data} /> */}
        <CustomToastContainer />
        <br />

        {/* <CustomHeading title="General Comments" />
        <CustomModel
          title=" Delete Comments"
          submit={onSubmitDelete}
          content={deleteModel}
          action={okCancelButtonActions}
          openStatus={deleteOpen}
          closeStatus={handleDeleteClose}
          iconCloseStatus={handleDeleteClose}
          reset={reset}
          modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }} />
        <Card>
        <form
  onSubmit={handleSubmitGeneral(onSubmitGeneral)}
  className="space-y-4 md:space-y-6"
>
  <CardContent>
    <div className="flex items-center space-x-4">
      <CustomInputField
        fieldName="comments"
        // requiredErrorMessage={COMMENTS_REQUIRED_MESSAGE}
        minLength={2}
        minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
        maxLength={1000}
        maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
        fieldId="comments"
        fieldType="text"
        fieldLabel="Write a Comment"
        fieldControl={controlGeneral}
        fieldError={errorsGeneral}
        fieldRequiredIcon="*"
        multiline={true}
        numberOfRows={2}
        value={generalCommentsDataAdd}
        onInput={handleInputChangeAdd}
        className="flex-grow"
      />
      <CustomButton fieldType="submit" buttonName="Post" />
      {/* {isLoading && <CustomLoading />} 
    </div>
  </CardContent>
</form>

        <CardContent>
          {generalCommentDetails &&
            generalCommentDetails.map((comment, index) => (
              <div key={comment.generalCommentsId}>
                <section className="grid md:grid-cols-1 xl:grid-cols-1 gap-3 mt-6 ml-16">
                  <p>
                    <span className="text-primary " style={{ float: "left" }}>
                      @{comment.createdByName}{" "}
                    </span>
                    <span style={{ float: "right" }}>
                     
                      {!editModes[index] && comment.createdById === HomeLoginId && (
                        <>
                          {index === 0 && (
                            <>
                              {" "}
                        
                              <EditNoteOutlined
                                color="primary"
                                onClick={() => {
                                  handleEditClick(
                                    index,
                                    comment.generalCommentsId
                                  );
                                  console.log(comment.generalCommentsId);
                                }}
                              />{" "}
                           
                              <DeleteOutline
                                color="error"
                                onClick={() => {
                                  handleDeleteClickOpen(
                                    comment.generalCommentsId
                                  );
                                  console.log(comment.generalCommentsId);
                                }}
                              />
                            </>
                          )}
                        </>
                      )}
                       {moment(comment.createdDateAndTime).format(
                        "MM/DD/YYYY HH:mm:ss"
                      )}
                    </span>

                    {editModes[index] ? (
                      <form
                        onSubmit={handleSubmitGeneral(submitUpdate)}
                        className="space-y-4 md:space-y-6 "
                      >
                        <CardContent>
                          <CustomInputField
                            fieldName="editComments"
                            minLength={2}
                            minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                            maxLength={1000}
                            maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
                            fieldId="editComments"
                            fieldType="text"
                            // fieldLabel="Write a Comments"
                            fieldControl={controlGeneral}
                            errors={errorsGeneral}
                            // fieldRequiredIcon="*"
                            multiline={true}
                            numberOfRows={2}
                            value={generalCommentsData}
                            onInput={handleInputChange}
                          />

<div className="flex justify-center mt-8 space-x-1">
  <CustomButton
    fieldType="submit"
    buttonName="Save"
  />
  <CustomButton
    fieldType="button"
    buttonName="Cancel"
    click={handleCancelClick}
  />
  {isLoading && <CustomLoading />}
</div>
                        </CardContent>
                      </form>
                    ) : (
                      <p className="mt-8 break-words">
                        {comment.comments}
                      </p>
                    )}
                  </p>
                </section>

                <Divider />
              </div>
            ))}
        </CardContent>
        </Card>
        
       
      </div> */}
      <CustomGeneralComments />
      </CustomViewHeading>
      </>

  )
  
  
  }

export default BmArcMaintenanceView