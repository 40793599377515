import { FileDownload , InfoOutlined, ListAlt } from "@mui/icons-material";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Card,
  CardContent,
  FormControl,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomStyle } from "../../components/CustomStyle";
import CustomHeading from "../../components/CustomHeading";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import CustomDatePicker from "../../components/CustomDatePicker";
import CustomButton from "../../components/CustomButton";
import { Controller, useForm } from "react-hook-form";
import { pdf } from '@react-pdf/renderer';
import dayjs from "dayjs";
import {
  FROM_DATE_REQUIRED_MESSAGE,
  STATUS_REQUIRED_MESSAGE,
  TO_DATE_ERROR_MESSAGE,
  TO_DATE_REQUIRED_MESSAGE,
  TYPE_REQUIRED_MESSAGE,
} from "../../utills/ApplicationConstants";
import { BASE_URL, BASE_URL_IMAGE, GROUP_CONFIG, REQUEST_REPORT } from "../../utills/ApplicationRouting";
import { request } from "../../services/AxiosConfig";
import CustomAutoComplete from "../../components/CustomAutoComplete";
import moment from "moment";
import CustomLoading from "../../components/CustomLoading";
import CustomToastContainer from "../../components/CustomToastContainer";
import PDFDocumentList from "../../components/TemplateListpdf";
import { useSelector } from "react-redux";
import axios from "axios";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomMaterialWithoutActons from "../../components/CustomMaterialWithoutActons";
import LZString from 'lz-string';


function ArcRequestReport() {
  const [type, setType] = useState("");
  const { token } = useSelector((state) => state.hybridhoa);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
  } = useForm();
  const userRoles = useSelector((state) => state.hybridhoa.roles);
  console.log("Roles",userRoles);
  // const minDate = dayjs().subtract(3, "year").startOf("day");
  // const maxDate = dayjs().endOf("day");
  // const [toDateDisabled, setToDateDisabled] = useState(true);
  // const [toDateMinDate, setToDateMinDate] = useState(minDate);
  // const [toDateMaxDate, setToDateMaxDate] = useState(maxDate);
  // const [selectToDate, setToDate] = useState("");
  // const [selectFromDate, setFromDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedTypes, setSelectedType] = useState(null);
  const [listData, setListData] = useState([]);
  const [listDataComplaince,setListDataComplaince]=useState([])
  const [listDataMaintenance,setListDataMaintenance]=useState([])
  const [fileImage,setFileImage]=useState("")
  const [groupConfigurationDetails,setGroupConfigurationDetails]=useState([])
  const [logoUrl, setLogoUrl] = useState('');
  const [imageBlob,setImageBlob]=useState('');
  const[footerAdress,setFooterAdress]=useState('')
  const[groupAdress,setGroupAdress]=useState('')
  // const [toDate, setToDate] = useState("")
  // const [fromDate, setFromDate] = useState("")
  // const handleFromDateChange = (selectedFromDate) => {
  //   setToDate("")
  //   const newToDateMaxDate = dayjs(selectedFromDate)
  //     .add(3, "month").endOf("day");
  //   setToDateMaxDate(newToDateMaxDate);
  //   setToDateMinDate(selectedFromDate);
  //   setToDateDisabled(false);
  //   setFromDate(selectedFromDate);
 
  // };
  // const handleToDateChange = (selectedEndDate) => {
  //   setToDate(selectedEndDate);
  // };
  const minDate = dayjs().subtract(3, "year").startOf("day");
  const maxDate = dayjs().endOf("day");
  const [toDateDisabled, setToDateDisabled] = useState(true);
  const [toDateMinDate, setToDateMinDate] = useState(minDate);
  const [toDateMaxDate, setToDateMaxDate] = useState(maxDate);
  const [toDate, setToDate] = useState("")
  const [fromDate, setFromDate] = useState("")
  const [testImages,setTestImages]=useState('')
  const [selectedStatusOption, setSelectedStatusOption] = useState(null);
   const [complainceImage,setComplainceImage]=useState("");
   const handleFromDateChange = (selectedFromDate) => {
    // setListData([])
    setToDate("")
    const newToDateMaxDate = dayjs(selectedFromDate)
      .add(3, "month")
      .endOf("day");
    setToDateMaxDate(newToDateMaxDate);

    setToDateMinDate(selectedFromDate);
    setToDateDisabled(false);
    setFromDate(selectedFromDate);
    
  };

  const handleToDateChange = (selectedEndDate) => {
    setToDate(selectedEndDate);
    // setListData([])
  };
  const selectedType = [
    { label: "All", value: "ALL" },
    { label: "Compliance Request", value: "CR" },
    { label: "Maintenance Request", value: "MR" },
  ];
  const getGroupConfigurationDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GROUP_CONFIG}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        setGroupConfigurationDetails(data);
        const logoConfig = data.find(config => config.topic === 'Logo');
        console.log(logoConfig);
        if (logoConfig) {
          setLogoUrl(logoConfig.content);
          console.log(logoConfig.content);
        }
        const footer = data.find(config => config.topic === 'Contact Details');
        console.log(logoConfig);
        if (footer) {
          setFooterAdress(footer.content);
          console.log(footer.content);
        }
        const groupField = data.find(config => config.topic ==='Group Name');
        console.log(logoConfig);
        if (groupField) {
          setGroupAdress(groupField.content);
          console.log(groupField.content);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(()=>{
     getGroupConfigurationDetails()
  },[])
  const selectedStatus = [
     {label:"All"},
    { label: "Pending" },
    { label: "Acknowledged" },
    { label: "Pending Approval" },
    { label: "Not Resolved" },
    { label: "Rejected" },
    { label: "Approved" },
    { label: "Closed" },
    { label: "Resolved" },
  ];
  useEffect(() => {
    const savedFormData = JSON.parse(localStorage.getItem('ArcRequestReportFormData'));

    if (savedFormData) {
      const { roles, otherData } = savedFormData;

      // Check if userRoles includes at least one of the roles in savedFormData.roles
      const hasValidRole = roles.some(role => userRoles.includes(role));

      if (hasValidRole) {
        setValue('fromDate', otherData.data.fromDate);
        setValue('toDate', otherData.data.toDate);
        setValue('selectedStatus', otherData.data.selectedStatus);
        console.log('selectedStatusss', otherData.data.selectedStatus);
        // setValue('selectedType', otherData.data.selectedType); // Uncomment if you have a selectedType field

        setFromDate(dayjs(otherData.data.fromDate));
        setToDate(dayjs(otherData.data.toDate));
        setSelectedStatusOption(otherData.data.selectedStatus);
      }
    }
  }, [setValue, userRoles])

  const onSubmit = (data) => {
    setIsLoading(true);
    // const fromDate = dayjs(data.fromDate);
    // const toDate = dayjs(data.toDate);
    if (!data.fromDate) {
      setError("fromDate", {
        type: "manual",
        message: FROM_DATE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (!toDate) {
      setError("toDate", {
        type: "manual",
        message: TO_DATE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
if (toDate.isBefore(fromDate)) {
      setIsLoading(false)
      alert(TO_DATE_ERROR_MESSAGE);
      return;
    }
    const getTypeValue = (label) => {
      const selectedTypeObj = selectedType.find((type) => type.label === label);
      console.log("selectedTypeObj",selectedTypeObj);
      return selectedTypeObj ? selectedTypeObj.value : "";
    };
    const datas = {
      roles: userRoles,
      otherData: {
       data:data
      }
    };
     localStorage.setItem('ArcRequestReportFormData', JSON.stringify(datas));
    const postData = {
      from: dayjs(fromDate).format("YYYY-MM-DD"),
      to: dayjs(toDate).format("YYYY-MM-DD"),
      status: data.selectedStatus.label,
      type: "ALL",
    };
    console.log(dayjs(fromDate).format("YYYY-MM-DD"));
    console.log(dayjs(toDate).format("YYYY-MM-DD"));
    console.log(data.selectedStatus.label);
    // console.log(getTypeValue(data.selectedType.label));
    // return;
             
    request({ url: REQUEST_REPORT, method: "post", data: postData })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status === 200) {
          // let filteredData = [];
          // if (data.selectedType.label === "All") {
          //   filteredData = res.data.complaince.concat(res.data.maintenance);
          // } else if (data.selectedType.label === "Compliance Request") {
          //   filteredData = res.data.complaince;
          // } else if (data.selectedType.label === "Maintenance Request") {
          //   filteredData = res.data.maintenance;
          // }
          const filteredData=res.data.complaince
          const filteredDatas=res.data.maintenance
          // const complainDatas = {
          //   roles: userRoles,
          //   otherData: {
          //    complainData:filteredData,
          //    maintainData:filteredDatas
          //   }
          // };
          
        //   localStorage.setItem('ArcreportDatacomplaince', JSON.stringify(filteredData));
        //   localStorage.setItem('ArcreportDatamaintenance', JSON.stringify(filteredDatas));

        //   setListData(filteredData);
        //   setListDataComplaince(filteredData)
        //   setListDataMaintenance(filteredDatas)
        //   setComplainceImage(response.data.complaince[0].fileUrl);
        //  console.log("imagess",response.data.complaince[0].fileUrl);
        //   console.log("filter",filteredData);
            setListData(filteredData);

            console.log("filter",filteredData);

          setListDataComplaince(filteredData)
          setListDataMaintenance(filteredDatas)
          // setFileComplain(filteredData.attachments)
          
        
          // setFileComplain(filteredData.attachments)
         
         const compressedData = LZString.compress(JSON.stringify(filteredData));
         const compressedDatas = LZString.compress(JSON.stringify(filteredDatas));
         console.log("compress",compressedData)
         console.log("compress",compressedDatas)
        //  localStorage.setItem('ArcreportDatacomplaince', compressedData)
         localStorage.setItem('ArcreportDatacomplaince', compressedData)
        //  localStorage.setItem('ArcreportDatamaintenance', compressedDatas)
         localStorage.setItem('ArcreportDatamaintenance', compressedDatas);
         // localStorage.setItem('reportDatacomplaince', JSON.stringify(filteredData));
          // localStorage.setItem('reportDatamaintenance', JSON.stringify(filteredDatas));
          // console.log("filteree",filteredData.attachments);
          // setComplainceImage(response.data.complaince[0].fileUrl);
          // console.log("imagess",response.data.complaince[0].fileUrl);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  // image 
  // const getGroupAttachments=async () => {
  //   try {
  //     const storedToken = token;
  //     const response = await axios.get(`${BASE_URL_IMAGE}/${complainceImage}`, {
  //       headers: {
  //         Authorization: `Bearer ${storedToken}`,
  //       },
  //       responseType: "blob",
  //     });
  //     const blob = response.data; // Accessing the blob data from the response
  //     const fileURL = URL.createObjectURL(blob);
  //     setTestImages(fileURL);
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.error("Error fetching data:", error);
  //   }
  // };
  // useEffect(()=>{
  //   getGroupAttachments();
  // },[complainceImage])
  // useEffect(() => {
  //   // Retrieve data from local storage
  //   const storageData = localStorage.getItem('ArcreportDatamaintenance');
  //   if (storageData) {
  //     // Parse the JSON data
  //     const parsedData = JSON.parse(storageData);
  //     // Set the parsed data in the state
  //     setListDataMaintenance(parsedData);
  //     console.log("parsed",parsedData);
  //   }
  //   const storageDatas = localStorage.getItem('ArcreportDatacomplaince');
  //   if (storageDatas) {
  //     // Parse the JSON data
  //     const parsedData = JSON.parse(storageDatas);
  //     // Set the parsed data in the state
  //     setListDataComplaince(parsedData);
  //     console.log("parsed",parsedData);
  //   }
  // }, []);
  
  useEffect(() => {
    // Retrieve data from local storage
    const compressedData = localStorage.getItem('ArcreportDatamaintenance');
    const compressedDatas=localStorage.getItem('ArcreportDatacomplaince');
    console.log(localStorage.getItem('ArcreportDatacomplaince'),"kjfhkjh");
    console.log("comm",compressedDatas);
    
    if (compressedData) {
    try {
    // Decompress the data      console.log("insde  try",compressedDatas);
    console.log("innnnnn  try",compressedData);

    const decompressedData = LZString.decompress(compressedData);
    console.log("after  try",decompressedData);

    if (decompressedData) {
    // Parse the JSON data
    const parsedData = JSON.parse(decompressedData);
      // Set the parsed data in the state
      setListDataMaintenance(parsedData);
      // setFileComplain(parsedData.attachments)
      // console.log("testeee",parsedData.attachments);
      // console.log("parsed",parsedData);
    } else {
    console.error('Decompressed data is empty');
    }
    } catch (error) {
    console.error('Error during decompression or parsing:', error);
    }
    } else {
    console.error('No data found in localStorage for arReport');
    }

    if (compressedDatas) {
      console.log("before try",compressedDatas);

      try {
      // Decompress the data
      console.log("insde  try",compressedDatas);

      const decompressedData = LZString.decompress(compressedDatas);
      console.log("after  try",decompressedData);

      if (decompressedData) {
      // Parse the JSON data
      const parsedData = JSON.parse(decompressedData);
      // Set the parsed data in the state
      // Set the parsed data in the state
      setListDataComplaince(parsedData);
      console.log("parsed",parsedData);
      } else {
      console.error('Decompressed data is empty');
      }
      } catch (error) {
      console.error('Error during decompression or parsing:', error);
      }
      } else {
      console.error('No data found in localStorage for maintenance');
      }
    }, []);

  const getComplainceAttachments=async () => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL_IMAGE}/${logoUrl}`, {
        // headers: {
        //   Authorization: `Bearer ${storedToken}`,
        // },
        responseType: "blob",
      });
      const blob = response.data; // Accessing the blob data from the response
      const fileURL = URL.createObjectURL(blob);
      setImageBlob(fileURL);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(()=>{
    getComplainceAttachments()
  },[logoUrl])
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };
  const location = useLocation();
  const navigate = useNavigate();
  const handleViewClick = (selectedRow) => {

    navigate(`/mcbmarcreportmr/${selectedRow.row.original.maintenanceId}`);
  };
  const handleCrViewClick = (selectedRow) =>{
    
    navigate(`/mcbmarcreportcr/${selectedRow.row.original.complainceId}`);
   
  }
  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '') return '';
  
    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }
    const requestReportTable = useMemo((rowData) => [
      {
        accessorKey:"crId",
        header:"CrId"
      },
      {
        accessorKey:"description",
        header:"Description",
        accessorFn: (row) => {
          const description = row && row.description;
          if (description && description.length > 28) {
            return description.slice(0, 28) + "...";
          }
          return description;
        }
      },
      
      {
        accessorKey: "created By",
        header: "Created By",
        accessorFn: (row) => `${row.createdByDetails? row.createdByDetails.firstName : ""} ${row.createdByDetails? row.createdByDetails.lastName : ""} `,
        sortDescFirst: true,

      },
      {
        accessorKey: "createdByHomeAddress",
        header: "Address",
        sortDescFirst: true,

        // Cell: ({ row }) =>
        // `${row.original.address?.address|| ""}`,
      },
      
      
      {
        accessorKey: "createdTo",
        header: "Created To",
        accessorFn: (row) => `${row.createdToDetails? row.createdToDetails.firstName : ""} ${row.createdToDetails? row.createdToDetails.lastName : ""} `,
        
        sortDescFirst: true
      },
      {
        accessorKey: "created To Address",
        header: "Created To Address",
        accessorFn: (row) => row.address ? row.address.address || "" : "",

        sortDescFirst: true,
      },
      // {
      //   accessorKey: "type",
      //   header: "Type",
      //   accessorFn: ( row ) => {
      //     const selectedTypeLabel = selectedTypes?.label || "All";
      //     const crId = row.crId || "";
      //     const mrId = row.mrId || "";
      
      //     return selectedTypeLabel === "All"
      //       ? `${crId ? "Compliance Request" : ""} ${mrId ? "Maintenance Request" : ""}`.trim()
      //       : selectedTypeLabel === "Compliance Request"
      //       ? "Compliance Request"
      //       : selectedTypeLabel === "Maintenance Request"
      //       ? "Maintenance Request"
      //       : "";
      //   },
      //   sortDescFirst: true
      // },
      
      

      {
        accessorKey: "createdDateAndTime",
        header: "Created Date",
        size: 20,
      accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),
      },
      {
        accessorKey: "auditDateAndTime",
        header: "Audit Date",
        size: 20,
        accessorFn: (row) => moment(row.auditDateAndTime).format("MM/DD/YYYY"),
      },
      {
        accessorKey: "status",
        header: "Status",
        accessorFn: (row) => convertToPascalCase(row.status),
      },
    ]);
    const requestReportMRTable = useMemo((rowData) => [
      {
        accessorKey:"mrId",
        header:"MrId"
      },
      {
        accessorKey: "createdBy",
        header: "Created By",
        accessorFn: (row) => `${row.createdByDetails? row.createdByDetails.firstName : ""} ${row.createdByDetails? row.createdByDetails.lastName : ""} `,
        sortDescFirst: true,
      },
      {
        accessorKey: "createdAddress",
        header: "Created By Address",
        accessorFn: (row) => {
          return row.createdBydetails?.adressForCommunication ?? row.homeDetails?.address ?? "";
        } ,      
         sortDescFirst: true,
      },
      
      // {
      //   accessorKey: "createdTo",
      //   header: "Created To",
      //   Cell: ({ row }) =>
      //   `${row.original.createdToDetails?.firstName|| ""} ${row.original.createdToDetails?.lastName||""}`,
      // },
      // {
      //   accessorKey: "created To Address",
      //   header: "Created To Address",
      //   Cell: ({ row }) =>
      //   `${row.original.createdToDetails?.addressForCommunication || ""}`,
      // },
      // {
      //   accessorKey: "type",
      //   header: "Type",
      //   accessorFn: ( row ) => {
      //     const selectedTypeLabel = selectedTypes?.label || "All";
      //     const crId = row.crId || "";
      //     const mrId = row.mrId || "";
      
      //     return selectedTypeLabel === "All"
      //       ? `${crId ? "Compliance Request" : ""} ${mrId ? "Maintenance Request" : ""}`.trim()
      //       : selectedTypeLabel === "Compliance Request"
      //       ? "Compliance Request"
      //       : selectedTypeLabel === "Maintenance Request"
      //       ? "Maintenance Request"
      //       : "";
      //   },
      //   sortDescFirst: true
      // },
      
     {
        accessorKey: "createdDateAndTime",
        header: "Created Date",
        size: 20,
      accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),
      },
      {
        accessorKey: "auditDateAndTime",
        header: "Audit Date",
        size: 20,
        accessorFn: (row) => moment(row.auditDateAndTime).format("MM/DD/YYYY"),
      },
      {
        accessorKey: "status",
        header: "Status",
        accessorFn: (row) => convertToPascalCase(row.status),
      },
    ]);
  const rowActions = (row) => {
    const actions =[];
    
    if (row.original && row.original.maintenanceId) {
      actions.push({
        label: "View",
        icon: <ListAlt color="primary" />,
        click: () => handleViewClick(row),
      });
    }
    if (row.original && row.original.complainceId) {
      actions.push({
        label: "View",
        icon: <ListAlt color="primary" />,
        click: () => handleCrViewClick(row),
      });
    }
    return actions;
  };
  const renderImage = () => {
    if (imageBlob) {
      return { type: 'image', src: imageBlob, style: styles.headerImage };
    } else {
      return { type: 'image', src: '', style: styles.headerImage };
    }
  };
  const currentDate = moment(); // Get the current date
  let dateTimeText = currentDate.format("DD MMMM YYYY"); 
  const handleRenderPDF = () => {
    if (!listDataComplaince || listDataComplaince.length === 0) {
      console.error('No data available for PDF rendering.');
      return;
    }

    const styles = {
      title: {
        fontSize: 18,
        color: "black",
        textAlign: "center",
        padding: "5px",
      },
      emptys: {
        marginTop: "3%",
      },emptyModule:{
        marginTop:"7px"
       },
      content: {
        fontSize: 12,
      color: "black",
      textAlign: "left",
      // paddingLeft:"60px",
      padding:"5px"
      },
      re:{
        fontSize:12,
        color:"black",
        textAlign:"left",
        padding:"5px"
           },
           adress:{
            fontSize: 12,
            color: "black",
            textAlign: "left",
            
            // paddingLeft:"60px",
            padding:"5px",
            // fontWeight:"bold"
          },
      description: {
        fontSize: 15,
        color: "black",
        textAlign: "left",
        paddingLeft: "60px",
        padding: "5px",
        fontWeight: "bold",
      },
      signature: {
        fontSize: 14,
        color: "black",
        textAlign: "left",
        fontStyle: "italic",
      },
      re:{
        fontSize:12,
        color:"black",
        textAlign:"left",
        padding:"5px"
           },
      headers: {
        fontSize: 12,
        color: "black",
        textAlign: "left",
        alignItems: "center",
        padding: "4px",
      },
      heading: {
        fontSize: 20,
        textAlign: "center",
        fontFamily: "Oswald",
      },
      afterfrom: {
        marginTop: "8%",
      },
      from: {
        fontSize: 11,
        color: "black",
        textAlign: "left",
        alignItems: "center",
        padding: "2px",
      },
      empty: {
        marginTop: "128%",
      },
      secondEmpty: {
        marginTop: '30%',
       
      }, headerImage:{
        marginTop:"1%",
      paddingLeft:"70px",
      paddingRight:"50px",
      alignItems:"center",
        width:"470px",
        height:"160px",
        // backgroundColor:"yellow"
      },
      formempty: {
        marginTop: "49%",
      },
      fromBorder: {
        border: "1px solid black",
      },
      description:{
        fontSize: 13,
        color: "black",
        textAlign: "left",
        // paddingLeft:"60px",
        padding:"5px",
      fontWeight:"bold"
      },
      toBorder: {
        border: "1px solid black",
      },letterPad:{
        marginTop:"30%"
      },images:{
        width:"200px",
        height:"100px"
        
            }
    };
    const renderPageContent = (details) => {
      const letterContent = [];
    
      if (details && details.complainceId ) {
        // logo:imageBlob
       
        letterContent.push(
          // Section for Compliance Request Notice
          {
            type: "text",
            text: groupAdress,
            style: styles.title,
          },

          {
            type: "text",
            text: "Compliance Request",
            style: styles.title,
          },
          { type: "text", text: " " },
          // { type: 'text', text: dateTimeText, style:styles.from },
          { style: styles.fromBorder },
          { type: "text", text: "", style: styles.formempty },
          { type: "text", text: "From", style: styles.from },
          { type: 'text', text: groupAdress, style: styles.from },

          { type: "text", text: footerAdress, style: styles.from },
          // {type:'text',text:dateTimeText,style: styles.content},
          { type: 'text',text: "",  style:styles.emptyModule},

         
          { type: "text", text: "", style: styles.afterfrom },

          // { text: " "  },
          // { text: `${(details && details.createdByDetails && details.createdByDetails.firstName) || '' }`, style: styles.adress, fontSize: 15 },
          { type: "text", text: "To :", style: styles.headers },
          // { type: 'text', text: "From", style: styles.headers  },
          // { type: 'text', text: "From", style: styles.headers  },
          {
            type: "text",
            text: `${
              (details &&
               details.createdToDetails &&
               (details.createdToDetails.firstName || '') + 
               (details.createdToDetails.firstName && details.createdToDetails.lastName ? ' ' : '') +
               (details.createdToDetails.lastName || ''))
              || ""}`,
            style: styles.headers,
          },
          {
            type: "text",
            text: ` ${
              (details.createdToDetails?.addressForCommunication?? "") ||
              (details.address?.address?? "") ||
              " "
            }`,
            style: styles.headers,
          },
          {
            type: "text",
            text: `${
              (details &&
                details.createdToDetails &&
                details.createdToDetails.city) ||
              ""
            }${
              (details &&
                details.createdToDetails &&
                details.createdToDetails.city &&
                details.createdToDetails.zipcode) ? ", " : ""
            }${
              (details &&
                details.createdToDetails &&
                details.createdToDetails.zipcode) ||
              " "
            }`,
            style: styles.headers,
          },

          { type: "text", text: "", style: styles.empty },
          { type: "text", text: "", style: styles.secondEmpty },

          {type: 'image',src:imageBlob, style:styles.headerImage  },
          { type: 'text', text: groupAdress, style: styles.title },
          { type: 'text', text: "Compliance Request", style: styles.title },
          {type:'text',text:dateTimeText,style: styles.content},

          { type: 'text',text: "",  style:styles.emptyModule},
          {
            type: "text",
            text: `${
              (details &&
               details.createdToDetails &&
               (details.createdToDetails.firstName || '') + 
               (details.createdToDetails.firstName && details.createdToDetails.lastName ? ' ' : '') +
               (details.createdToDetails.lastName || ''))
              || ""}`,
            style: styles.headers,
          },

          {
            type: "text",
            text: ` ${
              (details.createdToDetails?.addressForCommunication?? "") ||
              (details.address?.address?? "") ||
              " "
            }`,
            style: styles.headers,
          },
          {
            type: "text",
            text: `${
              (details &&
                details.createdToDetails &&
                details.createdToDetails.city) ||
              ""
            }${
              (details &&
                details.createdToDetails &&
                details.createdToDetails.city &&
                details.createdToDetails.zipcode) ? ", " : ""
            }${
              (details &&
                details.createdToDetails &&
                details.createdToDetails.zipcode) ||
              " "
            }`,
            style: styles.headers,
          },
          { type: 'text',text: "",  style:styles.emptyModule},
          {
            type: 'text',
            text: `RE: ${
              (details.createdToDetails?.addressForCommunication?? "") ||
              (details.address?.address?? "") ||
              " "
            }`,
            style: styles.re
          },
          { type: 'text',text: "",  style:styles.emptyModule},
          {
            type: "text",
            text: "Dear Resident,",
            //  ${
            //   (details &&
            //     details.createdByDetails &&
            //     details.createdByDetails.firstName) ||
            //   " "
            // }`   
            
            style: styles.content,
            fontSize: 15,
          },
          // {
          //   type: "text",
          //   text: "You have previously asked to address the following matter with your property.",
          //   style: styles.content,
          // },
          {
            type: "text",
            text: `This is to inform you about the compliance request: ${
              details?.crId ?? ""
            }_${moment(details?.createdDateAndTime).format('MM/DD/YYYY hh:mm A')}`,
            style: styles.content,
          },
          {
            type: "text",
            text: `${details && details.description}`,
            style: styles.description,
            
          },
          {
            type: "text",
            text: "If you have any questions, feel that you have received this letter in error, or would like to request a variance, please feel free to contact our office.",
            style: styles.content,
          },
          {
            type: "text",
            text: "Thank you in advance for your attention to this matter.",
            style: styles.content,
          },
          { type: "text", text: "" },
          { type: "text", text: "", style: styles.content },
          { type: "text", text: "", style: styles.content },
          // {  type: 'text',text: "Sincerely,", style: styles.signature },
          // { type: 'text',text: footerContent , style: styles.adress}
          {type: 'text', text: "Sincerely,", style: styles.adress },
          { 
            type: "text", 
            text: `${footerAdress} (computer generated letter)`, 
            style: styles.adress 
          },
         
          // { type: "text", text: "", style: styles.empty },
          // { type: "text", text: "", style: styles.secondEmpty },
          // { type: "image", src: testImages }
        );
 }
 if (details.attachments && Array.isArray(details.attachments) && details.attachments.length > 0) {
  letterContent.push({ type: "text", text: "Attachments:", style: styles.content });

  details.attachments.forEach((attachment) => {
    const fileRemoval = attachment.file.substring(12);

    letterContent.push(
      { type: 'text', text: fileRemoval, style: styles.content },
      {
        type: 'image',
        src: `${BASE_URL_IMAGE}/${attachment.file}`,
        style: styles.images
      }
    );
  });
} else {
  letterContent.push({ type: "text", text: " ", style: styles.content });
}
      return letterContent;
    };
    
    //  const title=groupAdress;
    const logo = imageBlob;
    const data = listDataComplaince.map(details => ({
      letterContent: renderPageContent(details)
    }));

    return (
      <PDFDocumentList data={data} styles={styles} logo={logo} />
    );
  };
  
  // const currentDate = moment(); // Get the current date
  // let dateTimeText = currentDate.format("DD MMMM YYYY"); 
  const handleRenderPdf = () => {
    if (!listDataMaintenance || listDataMaintenance.length === 0) {
      console.error('No data available for PDF rendering.');
      return;
    }

    const styles = {
      title: {
        fontSize: 18,
        color: "black",
        textAlign: "center",
        padding: "5px",
      },images:{
        width:"200px",
        height:"100px"
        
            },
      emptys: {
        marginTop: "3%",
      },emptyModule:{
        marginTop:"7px"
       },
      content: {
        fontSize: 12,
      color: "black",
      textAlign: "left",
      // paddingLeft:"60px",
      padding:"5px"
      },
      re:{
        fontSize:12,
        color:"black",
        textAlign:"left",
        padding:"5px"
           },
           adress:{
            fontSize: 12,
            color: "black",
            textAlign: "left",
            
            // paddingLeft:"60px",
            padding:"5px",
            // fontWeight:"bold"
          },
      description: {
        fontSize: 15,
        color: "black",
        textAlign: "left",
        paddingLeft: "60px",
        padding: "5px",
        fontWeight: "bold",
      },
      signature: {
        fontSize: 14,
        color: "black",
        textAlign: "left",
        fontStyle: "italic",
      },
      re:{
        fontSize:12,
        color:"black",
        textAlign:"left",
        padding:"5px"
           },
      headers: {
        fontSize: 12,
        color: "black",
        textAlign: "left",
        alignItems: "center",
        padding: "4px",
      },
      heading: {
        fontSize: 20,
        textAlign: "center",
        fontFamily: "Oswald",
      },
      afterfrom: {
        marginTop: "8%",
      },
      from: {
        fontSize: 11,
        color: "black",
        textAlign: "left",
        alignItems: "center",
        padding: "2px",
      },
      empty: {
        marginTop: "128%",
      },
      secondEmpty: {
        marginTop: "30%",
      }, headerImage:{
        marginTop:"1%",
   paddingLeft:"70px",
      paddingRight:"50px",
      alignItems:"center",
  
        width:"470px",
        height:"160px",
        // backgroundColor:"yellow"
      },
      formempty: {
        marginTop: "49%",
      },
      fromBorder: {
        border: "1px solid black",
      },
      description:{
        fontSize: 13,
        color: "black",
        textAlign: "left",
        // paddingLeft:"60px",
        padding:"5px",
      fontWeight:"bold"
      },
      toBorder: {
        border: "1px solid black",
      },letterPad:{
        marginTop:"30%"
      },images:{
        width:"200px",
        height:"100px"
        
            }
    };
    const renderPageContent = (details) => {
      const letterContent = [];
    
     
    
      if (details && details.maintenanceId) {
        // logo:imageBlob
        letterContent.push(
          // Section for Compliance Request Notice
          {
            type: "text",
            text: groupAdress,
            style: styles.title,
          },
          {type: 'text', text: "Maintenance Request", style: styles.title },
          { text: " "  }, 
          // { text: dateTimeText, style:styles.adress },
          {style:styles.fromBorder},
          {type: 'text', text: "",  style:styles.formempty},
          { type: 'text',text: "From", style: styles.from },
          { type: 'text', text: groupAdress, style: styles.from },

          { type: "text", text: footerAdress, style: styles.from },

        //   { type: 'text',text: `${(details && details.createdByDetails && details.createdByDetails.firstName) || '' }`, style: styles.from },
        //  {  type: 'text',text: `${(details && details.address && details.address.address) || " " }`, style: styles.from },
        //   {  type: 'text',text: `${(details && details.createdByDetails && details.createdByDetails.city) || ""}, ${(details && details.createdByDetails && details.createdByDetails.zipcode) || " "}`, style: styles.from},
          { type: 'text',text: "",  style:styles.afterfrom},

          // { text: " "  },
          // { text: `${(details && details.createdByDetails && details.createdByDetails.firstName) || '' }`, style: styles.adress, fontSize: 15 },
          // { type: 'text',text: "To :", style: styles.headers  },
          {
            type: "text",
            text: `${
              (details &&
               details.createdByDetails &&
               (details.createdByDetails.firstName || '') + 
               (details.createdByDetails.firstName && details.createdByDetails.lastName ? ' ' : '') +
               (details.createdByDetails.lastName || ''))
              || ""}`,
            style: styles.headers,
          },
          {
            type: "text",
            text: ` ${
              (details.createdByDetails?.addressForCommunication?? "") ||
              (details.homeDetails?.address?? "") }`,
            style: styles.headers,
          },
          {
            type: "text",
            text: `${
              (details &&
                details.createdByDetails &&
                details.createdByDetails.city) ||
              ""
            }${
              details && details.createdByDetails && details.createdByDetails.city && details.createdByDetails.zipcode
                ? ", "
                : ""
            } ${
              (details &&
                details.createdByDetails &&
                details.createdByDetails.zipcode) ||
              " "
            }`,
            style: styles.headers,
          },
          {type: 'text', text: "",  style:styles.empty},
          { type: 'text',text: "",  style:styles.secondEmpty},
          // { type: 'text',text: "",  style:styles.letterPad},
          {type: 'image',src:imageBlob, style:styles.headerImage  },
          { type: 'text', text: groupAdress, style: styles.title },
          { type: 'text', text: "Maintenance Request", style: styles.title },
          {type:'text',text:dateTimeText,style: styles.content},

          { type: 'text',text: "",  style:styles.emptyModule},
          {
            type: "text",
            text: `${
              (details &&
               details.createdByDetails &&
               (details.createdByDetails.firstName || '') + 
               (details.createdByDetails.firstName && details.createdByDetails.lastName ? ' ' : '') +
               (details.createdByDetails.lastName || ''))
              || ""}`,
            style: styles.headers,
          },
          {
            type: "text",
            text:  ` ${
              (details.createdByDetails?.addressForCommunication?? "") ||
              (details.homeDetails?.address?? "") }`,
            style: styles.headers,
          },
          {
            type: "text",
            text: `${
              (details &&
                details.createdByDetails &&
                details.createdByDetails.city) ||
              ""
            }${
              details && details.createdByDetails && details.createdByDetails.city && details.createdByDetails.zipcode
                ? ", "
                : ""
            }${
              (details &&
                details.createdByDetails &&
                details.createdByDetails.zipcode) ||
              " "
            }`,
            style: styles.headers,
          },
          {
            type: 'text',
            text: `RE:  ${
              (details.createdByDetails?.addressForCommunication?? "") ||
              (details.homeDetails?.address?? "") }`,
            style: styles.re
          },
          { type: 'text',text: "",  style:styles.emptyModule},

          { type: 'text',text:"DearResident", style: styles.content },
          { type: 'text',text: "You have previously asked to address the following matter with your property.", style: styles.content },
          { type: 'text',text: `This is to inform you about the maintenance request: ${
            details?.mrId ?? ""
          }_${moment(details?.createdDateAndTime).format('MM/DD/YYYY hh:mm A')}`+",which has been "+convertToPascalCase(details.status), style: styles.content },
          {type: 'text',  text: `${details?.description ?? ''}`, style: styles.content, fontSize: 15 },
          {
            type: 'text',
            text: `${
              details && details.startDate && details.endDate
                ? "We have scheduled the desired start of the maintenance " + 
                  moment(details.startDate).format("MM/DD/YYYY") + 
                  " and desired end of maintenance on " + 
                  moment(details.endDate).format("MM/DD/YYYY") + 
                  ". Please ensure these dates are noted. Should any adjustments be needed, contact me at your earliest convenience."
                : ""
            }`,
            style: styles.content
          }
          ,
          { type: 'text',text: "If you have any questions, feel that you have received this letter in error, or would like to request a variance, please feel free to contact our office.", style: styles.content },
          { type: 'text',text: "Thank you in advance for your attention to this matter.", style: styles.content },
          {type: 'text', text: "",  }, 
          { type: 'text',text: "", style: styles.content },
          { type: 'text',text: "", style: styles.content },
          {type: 'text', text: "Sincerely,", style: styles.adress },
          { 
            type: "text", 
            text: `${footerAdress} (computer generated letter)`, 
            style: styles.adress 
          },
         
        );
      }
      if (details.attachments && Array.isArray(details.attachments) && details.attachments.length > 0) {
        letterContent.push({ type: "text", text: "Attachments:", style: styles.content });
      
        details.attachments.forEach((attachment) => {
          const fileRemoval = attachment.file.substring(12);
      
          letterContent.push(
            { type: 'text', text: fileRemoval, style: styles.content },
            {
              type: 'image',
              src: `${BASE_URL_IMAGE}/${attachment.file}`,
              style: styles.images
            }
          );
        });
      } else {
        letterContent.push({ type: "text", text: " ", style: styles.content });
      }
      return letterContent;
    };
    
    const logo = imageBlob;
    const data = listDataMaintenance.map(details => ({
      letterContent: renderPageContent(details)
    }));

    return (
      <PDFDocumentList data={data} styles={styles} logo={logo} />
    );
  };
  const handleExportData = async () => {
    // console.log("test",logoUrl);
    try {
      const pdfData = handleRenderPDF();
      const blob = await pdf(pdfData).toBlob();
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };
  const handleExportDataMaintenancepdf= async () => {
    // console.log("test",logoUrl);
    try {
      const pdfData = handleRenderPdf();
      const blob = await pdf(pdfData).toBlob();
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };
  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  const exportCSV = (data, fields, csvConfig) => {
    // Generate CSV headers from the fields array
    const headers = fields.map(field => csvConfig.useKeysAsHeaders ? field.header : field.accessor).join(csvConfig.fieldSeparator);
    // Generate CSV rows from the data
    const rows = data.map(row => {
      return fields.map(field => {
        const value = row[field.accessor];
        // Check if value is defined before accessing its properties
        if (value && value.includes && value.includes(csvConfig.fieldSeparator)) {
          return `"${value}"`;
        }
        return value;
      }).join(csvConfig.fieldSeparator);
    });
  
    const csvContent = [headers, ...rows].join('\n');
    return csvContent;
};


const csvConfigWithComplainceFilename = {
    ...csvConfig,
    filename: 'Request Report' 
  };
  const csvConfigWithMaintenanceFilename = {
    ...csvConfig,
    filename: 'Maintenance Report' 
  };

  const handleExportComplianceCsv = () => {
    const excludedProperties = [
      "homeId",
      "groupId",
      "homeOwnerDuesId",
      "auditBy",
      "title",
      "homeOwnerDetails",
      "requestForAccess",
      "id",
      // "createdBy",
      "isOnRent",
      "fileUrl",
      "homeOwnerLoginId",
      "homeOwnerDetailsId",
      "isLinked","isFineApplicable","isManualFine",

      "complainceId",
     "category",
     "address",
     "homeOwnerDues",
     "attachmentsCount",
     "crCreatedDate",
     "createdToName",
     "createdByHomeAddress",
      "reviewComments",
      "categoryDetails",
      "createdToDetails",
      "byLawDetails",
      "createdToDetails",
      "createdByName",
      "homeOwner",
      "bylawId",
      "parentId",
      "comments",
      "bylawDetails",
      "createdHomeId",
      "attachments",
      "dueAmount",
      "dueDate",
      "isAssigned","totalAmount","homeDetails","createdByDetails",
      "groupDuesId","specialAssType","dueFrequency","tite","role","categoryId",
     "maintenanceId", "externalUserId","jobId","isAssignedToBm","jobDetails","auditList",
    ];
  
   
  
    const formattedData = Object.values(listDataComplaince).map((item) => {
      const formattedDatetime = moment(item.createdDateAndTime).format(
        "MM/DD/YYYY HH:mm:ss"
      );
     
      const createdBy = item.createdByDetails
        ? `${item.createdByDetails.firstName} ${item.createdByDetails.lastName}`
        : '';
        const createdByAddress = item.createdByHomeAddress??"";
        const createdTo = item.createdToDetails
        ? `${item.createdToDetails.firstName} ${item.createdToDetails.lastName}`
        : '';
        const createdToAddress = item.address ? item.address.address : "";

        const type = item.complainceId ? "Compliance Request" : "Maintenance Request";
      const filteredItem = {
        ...item,
        createdBy:createdBy,
        createdByAddress:createdByAddress,
        createdDateAndTime: formattedDatetime,
        ...(createdTo && { createdTo }),
        createdToAddress:createdToAddress,
        type: type,
              };
  
      excludedProperties.forEach((property) => {
        delete filteredItem[property];
      });
  
      return filteredItem;
    });
  
    const csvConfigWithFilename = {
      ...csvConfig,
      filename: 'Request report' 
    };
  
    const csv = generateCsv(csvConfigWithFilename)(formattedData);
  download(csvConfigWithFilename)(csv);
  };
  const handleMaintenanceCsv = () => {
    const excludedProperties = [
      "homeId",
      "groupId",
      "homeOwnerDuesId",
      "auditBy",
      "attachmentsCount",
      "title",
      "homeOwnerDetails",
      "requestForAccess",
      "id",
      "isSafty","topic","isUrgent",
      // "createdBy",
      "isOnRent",
      "fileUrl",
      "homeOwnerLoginId",
      "homeOwnerDetailsId",
     "attachments",
      "complainceId",
     "category",
     "address",
     "createdToName",
     "createdByHomeAddress",
      "reviewComments",
      "categoryDetails",
      "createdToDetails",
      "byLawDetails",
      "createdToDetails",
      "createdByName",
      "homeOwner",
      "bylawId",
      "parentId",
      "comments",
      "bylawDetails",
      "crId",
      "assignedTo",
      "isAssigned","totalAmount","homeDetails","createdByDetails",
      "groupDuesId","specialAssType","dueFrequency","tite","role","categoryId",
     "maintenanceId", "externalUserId","jobId","isAssignedToBm","jobDetails","auditList",
    ];
  
   
  
    const formattedData = Object.values(listDataMaintenance).map((item) => {
      const formattedDatetime = moment(item.createdDateAndTime).format(
        "MM/DD/YYYY HH:mm:ss"
      );
     
      const createdBy = item.createdByDetails
        ? `${item.createdByDetails.firstName} ${item.createdByDetails.lastName}`
        : '';
        const createdByAddress = item.homeDetails
        ? `${item.homeDetails.address}`
        :  " ";
        const createdTo = item.createdToDetails
        ? `${item.createdToDetails.firstName} ${item.createdToDetails.lastName}`
        : '';
        const startDates=item.startDate || "";
        const endDates=item.endDate || "";
        const type = item.complainceId ? "Compliance Request" : "Maintenance Request";
      const filteredItem = {
        ...item,
        createdBy:createdBy,
        createdByAddress:createdByAddress,
        createdDateAndTime: formattedDatetime,
        startDate:startDates,
        endDate:endDates,
        // ...(createdTo && { createdTo }),
        type: type,
              };
  
      excludedProperties.forEach((property) => {
        delete filteredItem[property];
      });
  
      return filteredItem;
    });
  
    const csvConfigWithFilename = {
      ...csvConfig,
      filename: 'Request report' 
    };
  
    const csv = generateCsv(csvConfigWithFilename)(formattedData);
  download(csvConfigWithFilename)(csv);
  };
  const tableActionButtons = (
    <>
            {listDataComplaince && listDataComplaince.length > 0 && (

        <>
        <Button
            color="primary"
            variant="contained"
            onClick={() => handleExportComplianceCsv(listDataComplaince)}
            startIcon={<FileUploadIcon/>}
          >
            <span className={CustomStyle.containedButtonStyle}>
              Export All Data
            </span>
          </Button>
        <Button
        color="primary"
        variant="contained"
        onClick={handleExportData}
        startIcon={<FileUploadIcon/>}
      >
        <span className={CustomStyle.containedButtonStyle}>
          Export As Pdf
        </span>
      </Button>
</>
      )
    
    }
    
    </>
  );
  const tableActionButtonsMaintenance = (
    <>
            {listDataMaintenance && listDataMaintenance.length > 0 && (

        <>
        <Button
            color="primary"
            variant="contained"
            onClick={() => handleMaintenanceCsv(listDataMaintenance)}
            startIcon={<FileUploadIcon/>}
          >
            <span className={CustomStyle.containedButtonStyle}>
              Export All Data
            </span>
          </Button>
        <Button
        color="primary"
        variant="contained"
        onClick={handleExportDataMaintenancepdf}
        startIcon={<FileUploadIcon/>}
      >
        <span className={CustomStyle.containedButtonStyle}>
          Export As Pdf
        </span>
      </Button>
</>
      )
    
    }
    
    </>
  );

  return (
    <div>
      <CustomHeading title="Request Report" />
      <CustomToastContainer/>
      <Card>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomDatePicker
                fieldName="fromDate"
                label="From Date"
                control={control}
                requiredErrorMessage={FROM_DATE_REQUIRED_MESSAGE}
                minDate={minDate}
                maxDate={maxDate}
                onChange={(date) => handleFromDateChange(date)}
                value={fromDate}
                tooltipMessage="Select from date"

                fieldRequiredIcon="*"
              />

              <CustomDatePicker
                fieldName="toDate"
                label="To Date"
                control={control}
                requiredErrorMessage={TO_DATE_REQUIRED_MESSAGE}
                minDate={toDateMinDate}
                maxDate={toDateMaxDate}
                disabled={toDateDisabled}
                fieldRequiredIcon="*"
                value={toDate}
                tooltipMessage="Select to date"

                onChange={handleToDateChange}
              />
<div className="flex items-center md:w-1/2 w-full">
                  <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
              <Controller
                name="selectedStatus"
                control={control}
                rules={{ required: STATUS_REQUIRED_MESSAGE }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={Boolean(error)}>
                    <Autocomplete
                      fullWidth
                      {...field}
                      id="combo-box-demo"
                      options={selectedStatus}
                      getOptionLabel={(option) => option?.label || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      value={selectedStatusOption}
                      // onChange={(event, newValue) => {
                      //   setValue("selectedStatus", newValue, {
                      //     shouldDirty: true,
                      //   });
                      // }}
                      onChange={(event, newValue) => {
                        setValue("selectedStatus", newValue, { shouldDirty: true });
                        setSelectedStatusOption(newValue); // Update the state for selectedStatusOption
                      }}
      
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span>
                              Status <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          variant="standard"
                          error={Boolean(error) && !field.value}
                          helperText={
                            Boolean(error) && !field.value ? error.message : ""
                          }
                        />
                      )}
                    />
                  </FormControl>
                )}
              />
              <Tooltip
                      title={
                        <Typography sx={{ fontSize: "14px" }}>Please select the status</Typography>
                      }
                      placement="right"
                      arrow
                    >
                      <IconButton size="small">
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
              </div>
              </div>
              {/* <Controller
                name="selectedType"
                control={control}
                rules={{ required: TYPE_REQUIRED_MESSAGE }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={Boolean(error)}>
                    <Autocomplete
                      fullWidth
                      {...field}
                      id="combo-box-demo"
                      options={selectedType}
                      getOptionLabel={(option) => option?.label || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      onChange={(event, newValue) => {
                        setValue("selectedType", newValue, {
                          shouldDirty: true,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span>
                              Type <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          variant="standard"
                          error={Boolean(error) && !field.value}
                          helperText={
                            Boolean(error) && !field.value ? error.message : ""
                          }
                        />
                      )}
                    />
                  </FormControl>
                )}
              /> */}
            </section>
            <div class="text-left mt-10">
              <CustomButton fieldType="submit"  onClick={handleSubmit(onSubmit)} buttonName="Search" />
              {isLoading && <CustomLoading/>}
            </div>
          </form>
        </CardContent>
      </Card>
      <br />
      <br />

      {/* <CustomMaterialTable
        columns={requestReportTable}
        data={listData}
        rowActions={rowActions}
        actionButtons={tableActionButtons}
      /> */}
      <Accordion defaultExpanded>
       <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="font-medium">
         Compliance Request
        </AccordionSummary>
       <AccordionDetails>
        <CardContent>
        <CustomMaterialWithoutActons
        columns={requestReportTable}
        data={listDataComplaince}
        // rowActions={rowActions}
        onRowClick={handleCrViewClick}

        actionButtons={tableActionButtons}
      />
        </CardContent>
        </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
       <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="font-medium">
       Maintenance Request
        </AccordionSummary>
       <AccordionDetails>
        <CardContent>
        <CustomMaterialWithoutActons
        columns={requestReportMRTable}
        data={listDataMaintenance}
        // rowActions={rowActions}
        onRowClick={handleViewClick}

        actionButtons={tableActionButtonsMaintenance}
      />
        </CardContent>
        </AccordionDetails>
        </Accordion>
    </div>
  );
}

export default ArcRequestReport;
