// import {ListAlt } from "@mui/icons-material";
// import React, { useMemo, useState } from "react";
// import {  useNavigate } from "react-router-dom";
// import CustomHeading from "../../../components/CustomHeading";
// import CustomMaterialTable from "../../../components/CustomMaterialTable";

// function McRaisedByArcList() {
//     const [open, setOpen] = useState(false);
     
//     const navigate = useNavigate();
 
//    const handleViewClick = () => {
//      navigate("/compliancerequestview");
//    };
   
//    const data = [
//      {
//        complianceRequestId: 'CR_202311219485',
//        description: "testing one",
//        category: 'parking',
//        status: "Pending",
//        createdDate: "10/06/2023",
//        isFineApplicable:true,
//      },
//      {
//        complianceRequestId: 'CR_202311219485',
//        description: "testing one",
//        category: 'parking',
//        status: "Pending",
//        createdDate: "10/06/2023",
//        isFineApplicable:false,
//      },
//      {
//        complianceRequestId: 'CR_202311219485',
//        description: "testing one",
//        category: 'parking',
//        status: "Pending",
//        createdDate: "10/06/2023",
//        isFineApplicable:false,
//      },
//      {
//        complianceRequestId: 'CR_202311219485',
//        description: "testing one",
//        category: 'parking',
//        status: "Pending",
//        createdDate: "10/06/2023",
//        isFineApplicable:false,
//      },
//      {
//        complianceRequestId: 'CR_202311219485',
//        description: "testing one",
//        category: 'parking',
//        status: "Closed",
//        createdDate: "10/06/2023",
//        isFineApplicable:false,
//      },
//    ];
 
//    const complianceRequestTable = useMemo((rowData) => [
//      {
//        accessorKey: "complianceRequestId",
//        header: "Compliance RequestId",
//        size: 20,
//      },
//      {
//        accessorKey: "description",
//        header: "Description",
//        size: 20,
//      },
//      {
//        accessorKey: "category",
//        header: "Category",
//        size: 20,
    
//      },
//      {
//        accessorKey: "status",
//        header: "Status",
//        size: 20,
//      },
//      {
//        accessorKey: "createdDate",
//        header: "Created Date",
//        size: 20,
//      },
//      {
//        accessorKey: "isFineApplicable",
//        accessorFn: (row) => (row && row.isFineApplicable ? "Yes" : "No"),
//        header: "Is Fine Applicabable",
//        size: 20,
//      },
//    ]);


// const rowActions = [
//  {
//    label: "View",
//    icon: <ListAlt color="primary" />,
//    click: handleViewClick,
//  },
 
// ];
  
//    return (
//      <div>
//        <CustomHeading title="Compliance Request By ARC" />
 
      
//        <CustomMaterialTable
//          columns={complianceRequestTable}
//          data={data}
//          rowActions={rowActions}
//        />
//      </div>
//    );
//  }

// export default McRaisedByArcList
import { Delete, EditNoteOutlined, ListAlt } from "@mui/icons-material";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import {
//   BASE_URL,
//   CATEGORY,
//   COMPLIANCE_REQUEST,
// } from "../../utills/ApplicationRouting";
import axios from "axios";
import moment from "moment";
import { BASE_URL, COMPLIANCE_REQUEST_BY_ID,CATEGORY } from "../../../utills/ApplicationRouting";
import CustomButton from "../../../components/CustomButton";
import CustomHeading from "../../../components/CustomHeading";
import CustomMaterialTable from "../../../components/CustomMaterialTable";
import CustomLoading from "../../../components/CustomLoading";

function McRaisedByArcList() {
  const [open, setOpen] = useState(false);
  const { token } = useSelector((state) => state.hybridhoa);
  const [complianceRequestDetails, setComplianceRequestDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [status,setStatus]=useState('')

  const handleViewClick = (selectedRow) => {
    navigate(`/mcraisedbyarc/${selectedRow.row.original.complainceId}`);
  };
  const handleAddClick = () => {
    navigate("/arccompliancerequestadd");
  };
  const handleEditClick = (selectedRow) => {
    navigate(`/compliancerequestedit/${selectedRow.original.complainceId}`);
  };

  const getCategory = async () => {
    try {
      setIsLoading(true)
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (response.status === 200) {
        const allCategories = response.data;

        // Filter categories based on conditions
        // const filteredCategories = allCategories.filter(category => {
        //   return (
        //     (category.isEventForArc && roles.includes('ROLE_ARC')) ||
        //     (!category.isEventForArc && roles.includes('ROLE_BM'))
        //   );
        // });

        setCategoryDetails(allCategories); // Set the filtered data to the state
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const getComplianceRequestDetails = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${COMPLIANCE_REQUEST_BY_ID}/arc
      
      `, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setComplianceRequestDetails(data);
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getComplianceRequestDetails();
  }, []);

  const complianceRequestTable = useMemo((rowData) => [
    {
      accessorKey: "crId",
      header: "Compliance RequestId",
      size: 20,
    },
    {
      accessorKey: "categoryDetails.category",
      header: "Category",
      Cell: ({ row }) => {
        if (row.original.categoryDetails === null || row.original.categoryDetails === undefined) {
          return "N/A";
        } else {
          return row.original.categoryDetails.category || "N/A";
        }
      },
    },
    // {
    //   accessorKey: "description",
    //   header: "Description",
    // },
    {
      accessorKey: "description",
      header: "Description",
      accessorFn: (row) => {
        const description = row && row.description;
        if (description && description.length > 28) {
          return description.slice(0, 28) + "...";
        }
        return description;
      }
    },
    {
      accessorKey: "createdTo.firstName",
      header: "Firstname",
      Cell: ({ row }) =>
      `${row.original.createdToDetails?.firstName|| ""}`,
    },
    {
      accessorKey: "createdTo.lastName",
      header: "Lastname",
      Cell: ({ row }) =>
      ` ${row.original.createdToDetails?.lastName||""}`,
    },
    {
      accessorKey: "address.address",
      header: "Address",
      Cell: ({ row }) =>
      `${row.original.address?.address|| ""}`,
      }
    ,
    
    {
      accessorKey: "status",
      header: "Status",
      Cell: ({ row }) => {
        const status = row.original.status;
        return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
      },
    },
    {
      accessorKey: "createdDateAndTime",
      header: "Created Date",
      accessorFn: (row) => moment(row.crCreatedDate || row.createdDateAndTime).format("MM/DD/YYYY"),
    },
  ]);

  const rowActions = (row) => {
    const actions = [
      // {
      //   label: "View",
      //   icon: <ListAlt color="primary" />,
      //   click: () => handleViewClick(row),
      // },
    ];

    if (row.original.status !== "ACKNOWLEDGED") {
      actions.push({
        label: "Edit",
        icon: <EditNoteOutlined color="primary" />,
        click: () => handleEditClick(row),
      });
    }

    return actions;
  };

  const tableActionButtons = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Create"
        click={handleAddClick}
      />
    </>
  );

  return (
    <div>
      <CustomHeading title="Compliance Request" />
      {isLoading && <CustomLoading/>}
      <CustomMaterialTable
        columns={complianceRequestTable}
        data={complianceRequestDetails}
        // rowActions={rowActions}
        // actionButtons={tableActionButtons}
        // isLoading={isLoading}
        onRowClick = {handleViewClick}
      />
    </div>
  );
}

export default McRaisedByArcList;
