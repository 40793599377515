import CustomViewPage from "../../components/CustomViewPage";
import { useNavigate, useParams } from "react-router-dom";
import { ADD_MAINTENANCE, BASE_URL, BASE_URL_IMAGE, GENERAL_COMMENTS, GET_ALL_GENERAL_COMMENTS, GROUP_CONFIG } from "../../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import moment from "moment/moment";
import CustomLoading from "../../components/CustomLoading";
import PDFDocument from "../../components/Templatepdf";
import { pdf } from "@react-pdf/renderer";
import CustomViewPages from "../../components/CustomViewPages";
import { DeleteOutline, EditNoteOutlined } from '@mui/icons-material';
import {
  Controller,
  FormProvider,
  useForm,
  useFormState,
} from "react-hook-form";
import { COMMENTS_REQUIRED_MESSAGE, MAX_LENGTH_THOUSAND, MIN_LENGTH_VALIDATE_MESSAGE } from "../../utills/ApplicationConstants";
import CustomButton from "../../components/CustomButton";
import { Button, Card, CardContent, DialogActions, DialogContent, Divider, Tooltip, Typography } from "@mui/material";
import CustomHeading from "../../components/CustomHeading";
import CustomModel from "../../components/CustomModel";
import CustomInputField from "../../components/CustomInputField";
import { request } from "../../services/AxiosConfig";
import CustomToastContainer from "../../components/CustomToastContainer";
import CustomViewWithTooltip from "../../components/CustomViewWithTooltip";
import CustomViewReportHeading from "../../components/CustomViewReportHeading";
import AttachmentList from "../../components/AttachmentList";
import AttachmentListsView from "../../components/AttachmentListsView";
import CustomReviewTable from "../../components/CustomReviewTable";

function ArcMcBmRequestReportMrView() {
  const navigate = useNavigate();
  const { token,roles,userLoginDetails,userDetails } = useSelector((state) => state.hybridhoa);
  const {
   handleSubmit: handleSubmitGeneral,
   control: controlGeneral,
   formState: { errors: errorsGeneral },
   setValue: setValueGeneral,
   setError: setErrorGeneral,
   reset: resetGeneral,
   watch: watchGeneral,
 } = useForm();
 const [generalCommentsDataAdd, setGeneralCommentsDataAdd] = useState("");
 const [generalCommentDetails, setGeneralCommentDetails] = useState("");
 const [deleteOpen, setDeleteOpen] = useState(false);
 const [generalCommentsData,setGeneralCommentsData] = useState('');
 const [commentsId,setCommentsId] = useState('');
 const[isLoading,setIsLoading]=useState(false)
 const [editModes, setEditModes] = useState(
   Array.isArray(generalCommentDetails)
     ? Array(generalCommentDetails.length).fill(false)
     : []
 );
 const handleDeleteClose = () => {
   setDeleteOpen(false);
 };
 
 const HomeLoginId= userDetails.homeOwnerLoginId;
  const handleClick = () => {
    navigate(-1);
  };
  const [reviewComments,setReviewComments]=useState([])
  const [fileUrl, setFileUrl] = useState("");
  const [createdDateAndTime, setCreatedDateAndTime] = useState("");                                    
  const {maintenanceId}=useParams()
  const [complainceDetails,setComplainceDetails]=useState('')
  // const [loading,setIsLoading]=useState("")
  const [maintenanceRequestData, setMaitenanceRequestData] = useState('');

  const[byFirstName,setByFirstName]=useState("")
  const[byLastName,setByLastName]=useState("")
  const[byFullName,setBYFullName]=useState("")
  const [groupConfigurationDetails,setGroupConfigurationDetails]=useState([])
  const [logoUrl, setLogoUrl] = useState('');
  const [imageBlob,setImageBlob]=useState('');
  const [fileImage,setFileImage]=useState([])
  const [fileImages,setFileImages]=useState("")

  const [filePdf,setFilePdf]=useState("");
  const[footerAdress,setFooterAdress]=useState('')
  const[groupAdress,setGroupAdress]=useState('')
  const [includeComments, setIncludeComments] = useState(false);

  const handleCheckboxChange = (event) => {
    setIncludeComments(event.target.checked);
  };
  const getGroupConfigurationDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GROUP_CONFIG}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        setGroupConfigurationDetails(data);
        const logoConfig = data.find(config => config.topic === 'Logo');
        console.log(logoConfig);
        if (logoConfig) {
          setLogoUrl(logoConfig.content);
          console.log(logoConfig.content);
        }
        const footer = data.find(config => config.topic === 'Contact Details');
        console.log(logoConfig);
        if (footer) {
          setFooterAdress(footer.content);
          console.log(footer.content);
        }
        const groupField = data.find(config => config.topic ==='Group Name');
        console.log(logoConfig);
        if (groupField) {
          setGroupAdress(groupField.content);
          console.log(groupField.content);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(()=>{
     getGroupConfigurationDetails()
  },[])
  const getGroupAttachments=async () => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL_IMAGE}/${logoUrl}`, {
        // headers: {
        //   Authorization: `Bearer ${storedToken}`,
        // },
        responseType: "blob",
      });
      const blob = response.data; // Accessing the blob data from the response
      const fileURL = URL.createObjectURL(blob);
      setImageBlob(fileURL);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(()=>{
    getGroupAttachments()
  },[logoUrl])
  
  const getDocumentDetailsbyIdArc = async () => {
    console.log(token);
    try {
      setIsLoading(true);
      // console.log(documentId);
      const response = await axios.get(
        `${BASE_URL}${ADD_MAINTENANCE}/${maintenanceId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      console.log(response.data.renter);
      if (response.status === 200) {
        const data = response.data;
        const viewComments=response.data.reviewComments || []  ;
        setReviewComments(viewComments)
        setComplainceDetails(data);
        setByLastName(data.createdByDetails.lastName);
        setByFirstName(data.createdByDetails.firstName);
        setMaitenanceRequestData(data)
        setIsLoading(false);
        setFileUrl(data.fileUrl);
        setFileImage(data.attachments)
        setFilePdf(data.fileUrl)
        console.log(data.fileUrl);
        const formattedDate = moment(data.createdDateAndTime).format("MM/DD/YYYY hh:mm A");
        setCreatedDateAndTime(formattedDate)
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  const getDocumentDetailsbyIdBm = async () => {
    console.log(token);
    try {
      setIsLoading(true);
      // console.log(documentId);
      const response = await axios.get(
        `${BASE_URL}${ADD_MAINTENANCE}/${maintenanceId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      console.log(response.data.renter);
      if (response.status === 200) {
        const data = response.data;
        setFileImage(data.attachments)
        const viewComments=response.data.reviewComments || []  ;
        setReviewComments(viewComments)
        setComplainceDetails(data);
        setByLastName(data.createdByDetails.lastName);
        setByFirstName(data.createdByDetails.firstName);
        setIsLoading(false);
        setMaitenanceRequestData(data)
       

        setFileUrl(data.fileUrl);
        console.log(data.fileUrl);
        const formattedDate = moment(data.createdDateAndTime).format("MM/DD/YYYY hh:mm A");
        setCreatedDateAndTime(formattedDate)
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  const getDocumentDetailsbyIdMc = async () => {
    console.log(token);
    try {
      setIsLoading(true);
      // console.log(documentId);
      const response = await axios.get(
        `${BASE_URL}${ADD_MAINTENANCE}/${maintenanceId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      console.log(response.data.renter);
      if (response.status === 200) {
        const data = response.data;
        const viewComments=response.data.reviewComments || []  ;
        setReviewComments(viewComments)
        setComplainceDetails(data);
        setByLastName(data.createdByDetails.lastName);
        setByFirstName(data.createdByDetails.firstName);
        setIsLoading(false);
        setMaitenanceRequestData(data)
        setFileImage(data.attachments)

        setFileUrl(data.fileUrl);
        console.log(data.fileUrl);
        const formattedDate = moment(data.createdDateAndTime).format("MM/DD/YYYY hh:mm A");
        setCreatedDateAndTime(formattedDate)
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    setBYFullName(`${byFirstName} ${byLastName}`)
    const fetchData = async () => {

      {roles.includes('ROLE_BM')   ? ( 
        await getDocumentDetailsbyIdBm()) 
        :
        roles.includes('ROLE_ARC') ? (
        await getDocumentDetailsbyIdArc()) 
        :
        
        await getDocumentDetailsbyIdMc()}
      };

    fetchData();
  }, [maintenanceId,byFirstName,byLastName]);
  // const getMaintenanceAttachments=async () => {
  //   try {
  //     const response = await axios.get(`${BASE_URL_IMAGE}/${fileImage}`, {
  //       // headers: {
  //       //   Authorization: `Bearer ${storedToken}`,
  //       // },
  //       responseType: "blob",
  //     });
  //     const blob = response.data;
  //     const fileURL = URL.createObjectURL(blob);
  //     setFileImages(fileURL);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  // useEffect(()=>{
  //   getMaintenanceAttachments()
  // },[fileImage])
  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '') return '';

    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};
const [showFullDescription, setShowFullDescription] = useState(false);
const [showTopic,setShowTopic]=useState(false)
const fileList = fileImage.map(file => ({
  docname: file.file.substring(12), // Assuming file name is the document name
  fileUrl: file.file, // Assuming file location is the file URL
  documentnamed: file.file, // Assuming file name is the document name
  uploadedTimes:  moment(file.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"), // Assuming uploadedTimes is available
}));
const handleToggleDescription = () => {
  setShowFullDescription(!showFullDescription);
};
const handleToggleTopic = () => {
  setShowTopic(!showTopic);
};
const renderDescription = () => {
  const description = complainceDetails.description || ''; // Default to an empty string if null or undefined

  if (!showFullDescription) {
    return (
      <>
        {description.slice(0, 250)}
        {description.length > 250 && (
          <Button onClick={handleToggleDescription} style={{ fontSize: "12px" }}>Read More</Button>
        )}
      </>
    );
  } else {
    return (
      <>
        {description}
        <Button onClick={handleToggleDescription} style={{ fontSize: "12px" }}>Less</Button>
      </>
    );
  }
};

//  const renderDescription = () => {
//   console.log("inside render ");
//   if (complainceDetails.description && !showFullDescription) {
//   console.log("inside render ",complainceDetails.description);

//     return (
//       <>
      
//         {complainceDetails.description.slice(0, 250)}
//         {complainceDetails.description.length > 250 && (
//           <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Read More</Button>
//         )}
//       </>
//     );
//   } else {
//   console.log("inside render else ",complainceDetails.description);

//     return (
//       <>
//         {complainceDetails.description}
//         <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Less</Button>
//       </>
//     );
//   }
// };
const renderTopic = () => {
  console.log("inside render ");
  if (complainceDetails.topic && !showTopic) {
  console.log("inside render ",complainceDetails.topic);

    return (
      <>
      
        {complainceDetails.topic.slice(0, 250)}
        {complainceDetails.topic.length > 250 && (
          <Button onClick={handleToggleTopic} style={{fontSize:"12px"}}>Read More</Button>
        )}
      </>
    );
  } else {
  console.log("inside render else ",complainceDetails.topic);

    return (
      <>
        {complainceDetails.topic}
        <Button onClick={handleToggleTopic} style={{fontSize:"12px"}}>Less</Button>
      </>
    );
  }
};
const data = [
  
  {
    groupName: 'Maintenance  Details',
    items: [
      { label: 'Maintenance Request ID', data: `${ complainceDetails?.mrId || ""}`, text: 'Unique identifier for the maintenance request.' },
      { label: 'What needs attention?', data:renderTopic()  ,text: 'Description of the issue or topic that needs attention.'},
   
             //  { label: 'Description', data: renderDescription() ,text: 'Tooltip text 1'},
{ label: 'More Information?', data:renderDescription(),text: 'Additional information or details about the maintenance request.'},
{ label: 'Is this issue Urgent?', data:  `${complainceDetails.isUrgent ?'Yes':'No'}` ,text: 'Indicates whether the issue requires immediate attention (Yes/No).'},
{ label: 'Is this issue  causing a threat to personal safety??', data:  `${complainceDetails.isSafty ?'Yes':'No'}` ,text: 'Indicates whether the issue poses a threat to personal safety (Yes/No).'},
{
   label: 'Status',
              data: convertToPascalCase(complainceDetails.status ?? ""),
              text: 'Current status of the maintenance request.'
            },
{ label: 'Request Type', data:  `${complainceDetails?.type || "" }` ,text: 'Type or category of the maintenance request.'},


           
            ],
   },
   {
    groupName: 'Date Details',
    items: [
     
      {
        label: 'Start Date',
        data: complainceDetails.startDate && moment(complainceDetails.startDate).isValid() ? moment(complainceDetails.startDate).format("MM/DD/YYYY") : "" ,                     

        text: 'Date and time when the maintenance request was initiated.'
        },
        {
        label: 'End Date',
        data: complainceDetails.endDate && moment(complainceDetails.endDate).isValid() ? moment(complainceDetails.endDate).format("MM/DD/YYYY") : "" ,                     
        text: 'Date and time when the maintenance request is expected to be completed.'
        },
        // { label: 'Requested Date Time', data: `${moment(complainceDetails.createdDateAndTime).format(
        //   "MM/DD/YYYY hh:mm:ss A")}` ,text: 'Date and time when the maintenance request was submitted.'},
        //   { label: 'Updated Date Time', data: `${moment(complainceDetails.auditDateAndTime).format(
        //    "MM/DD/YYYY hh:mm:ss A")}` ,text: 'Date and time when the maintenance request was last updated.'}, 
    ],
  },
   {
    groupName: 'Created By Details',
    items: [
      { label: 'Firstname', data:`${complainceDetails.createdByDetails?.firstName??""}`,text:"First name of the person  whom created Maintenance request"},
      { label: 'Lastname', data:`${complainceDetails.createdByDetails?.lastName??""}`,text:"Last name of the person who created Maintenance request" },
      { label: 'Email', data:`${complainceDetails.createdByDetails?.email??""}`,text:"Email address of the person who created Maintenance request" },
      { label: 'Address', data: complainceDetails?.createdByDetails?.addressForCommunication || complainceDetails?.homeDetails?.address || "",text:"Home address of the person who created Maintenance request" },
      { label: 'contact', data:`${complainceDetails.createdByDetails?.contact??""}`,text:"Contact number of the person Who created Maintenance request " },
    ],
  },
  {
    groupName: 'Attachment Details',
items:[
  { label:'Attachment', data: (
    <AttachmentListsView fileList={fileList} />

    ), text: 'List of attached files/documents related to the maintenance request.'}, 
]  
  }
  ];
            
  const currentDate = moment(); // Get the current date
  let dateTimeText = currentDate.format("DD MMMM YYYY"); // Format the current date
  const handleRenderPDF = () => {
    
    const styles = {
      title: {
        fontSize: 12,
        color: "black",
        textAlign: "center",
        padding:"5px",
        fontWeight: "bold" 
      },
    empty:{
      color:"red",
    padding:"500px"
    },
    content: {
      fontSize: 12,
      color: "black",
      textAlign: "left",
      // paddingLeft:"60px",
      padding:"5px"
    },
    comments:{
      fontSize:12,
      color:"black",
      marginTop:"2%"
    },
    emptys:{
        marginTop:"3%"
    },
    empty:{
    marginTop:"128%"
    },
    secondEmpty:{
     marginTop:"21%"
    },
    from:{
      fontSize: 12,
      color: "black",
      textAlign: "left",
      alignItems:"center",
      padding:"2px",
     },
   headers:{
    fontSize: 12,
    color: "black",
    textAlign: "left",
    alignItems:"center",
    padding:"5px",
    
   },
   emptyModule:{
    marginTop:"7px"
   },
   re:{
fontSize:12,
color:"black",
textAlign:"left",
padding:"5px"
   },
    adress:{
      fontSize: 12,
      color: "black",
      textAlign: "left",
      
      // paddingLeft:"60px",
      padding:"5px",
      // fontWeight:"bold"
    },
    toDate:{
        marginTop:"1%",
    },
    description:{
      fontSize: 13,
      color: "black",
      textAlign: "left",
      // paddingLeft:"60px",
      padding:"5px",
    fontWeight:"bold"
    },
    dear:{
      fontSize: 13,
      color: "black",
      textAlign: "left",
      // paddingLeft:"60px",
      padding:"5px",
    fontWeight:"bold"
    },
    signature: {
      fontSize: 16,
      color: "black",
      textAlign: "left",
      fontStyle: "italic",
    },
    heading: {
      fontSize: 24,
      textAlign: 'center',
      fontFamily: 'Oswald'
    },
    pageInitial:{
      marginTop:"100px"
    },
    afterfrom:{
      marginTop:"7%"
    },
    formempty:{
      marginTop:"47%"
    },anotherempty:{
      marginTop:"8%"
    },images:{
width:"200px",
height:"100px"

    },
    fromBorder:{
       border:"1px solid black"
    },toBorder:{
      border:"1px solid black"

    },
    headerImage:{
      marginTop:"1%",
 paddingLeft:"70px",
    paddingRight:"50px",
    alignItems:"center",

      width:"470px",
      height:"160px",
      // backgroundColor:"yellow"
    }
  };
  const datas = {
    // title:"Complaince Request Notice",
    // hoaName:groupAdress,

    logo: imageBlob,
          letterContent: [
      { type: 'text', text: groupAdress, style: styles.title },

      { type: 'text', text: "Maintenance Request", style: styles.title },
      { type: 'text', text: " "  }, 
      
      {style:styles.fromBorder},
      
      {type: 'text', text: "",  style:styles.formempty},
          
      { type: 'text',text: "From :", style: styles.from  } ,
      { type: 'text', text: groupAdress, style: styles.from },

      { type: "text", text: footerAdress, style: styles.from },

      // {type: 'text',text: ""+ `${maintenanceRequestData.createdByDetails?.firstName || ""}`,style: styles.from, },
      // {type: 'text',text: ""+ `${maintenanceRequestData.address?.address || ""}`,style: styles.from,},
      // { type: 'text',text: `${maintenanceRequestData.createdByDetails?.city || ""}, ${maintenanceRequestData.createdByDetails?.zipcode || ""}`, style: styles.from },
     
             // {style:styles.toBorder},

      { type: 'text',text: "",  style:styles.afterfrom},
      // { type: 'text',text: "To :", style: styles.headers  },
      {
        type: 'text',
        text: `${maintenanceRequestData?.createdByDetails?.firstName || ""}${maintenanceRequestData?.createdByDetails?.lastName || ""}`,
        style: styles.headers
      },
      { 
        type: 'text',
        text:`${maintenanceRequestData?.createdByDetails?.addressForCommunication ?? maintenanceRequestData?.homeDetails?.address ?? ""}`,
        style: styles.headers
      },
      {
        type: 'text',
        text: `${maintenanceRequestData?.createdByDetails?.city || ""}${maintenanceRequestData?.createdByDetails?.zipcode ? `, ${maintenanceRequestData?.createdByDetails?.zipcode}` : ""}`,
        style: styles.headers
      },
      
      {type: 'text', text: "",  style:styles.empty},
      { type: 'text',text: "",  style:styles.secondEmpty},
      { type: 'text',text: "",  style:styles.toDate},
      ...(imageBlob ? 
        [{ type: 'image', src: imageBlob, style: styles.headerImage }] : 
        [{ type: 'text', text: "", style: styles.headerImage }]
      ),
      // {type: 'image',src:imageBlob, style:styles.headerImage  },
      { type: 'text', text: groupAdress, style: styles.title },
     
      { type: 'text', text: "Maintenance Request", style: styles.title },
      {type:'text',text:dateTimeText,style: styles.content},
      { type: 'text',text: "",  style:styles.emptyModule},
      {
        type: 'text',
        text: `${maintenanceRequestData?.createdByDetails?.firstName || ""}${maintenanceRequestData?.createdByDetails?.lastName || ""}`,
        style: styles.headers
      },
      { 
        type: 'text',
        text:`${maintenanceRequestData?.createdByDetails?.addressForCommunication ?? maintenanceRequestData?.homeDetails?.address ?? ""}`,
        style: styles.headers
      },
      {
        type: 'text',
        text: `${maintenanceRequestData?.createdByDetails?.city || ""}${maintenanceRequestData?.createdByDetails?.zipcode ? `, ${maintenanceRequestData?.createdByDetails?.zipcode}` : ""}`,
        style: styles.headers
      },
      {
        type: 'text',
        text: `RE: ${maintenanceRequestData?.createdByDetails?.addressForCommunication ?? maintenanceRequestData?.homeDetails?.address ?? ""}`,
        style: styles.re
      },
      {type: 'text', text: "Dear Resident,"  , style: styles.content },
      {type: 'text',text:"You have previously asked to adress the following matter with your property.",style: styles.content},
    {type: 'text', text: "This is to inform you about the maintenance request:"+ maintenanceRequestData.mrId+"_"+dateTimeText+",which has been "+ convertToPascalCase(maintenanceRequestData.status) , style: styles.content},
    { type: 'text',text: "" + `${maintenanceRequestData.description ||""}`, style: styles.description,},
    {
      type: 'text',
      text: maintenanceRequestData.startDate && maintenanceRequestData.endDate
        ? "We have scheduled the desired start of the maintenance " + 
          moment(maintenanceRequestData.startDate).format("MM/DD/YYYY") + 
          " and desired end of maintenance on " + 
          moment(maintenanceRequestData.endDate).format("MM/DD/YYYY") + 
          ". Please ensure these dates are noted. Should any adjustments be needed, contact me at your earliest convenience."
        : "",
      style: styles.description
    },
    
{ type: 'text',text: "If u have any questions,feel that you have recieved this letter in error,or would  like to request a variance,please feel free to contact our office.", style: styles.content},

   {type: 'text',text:"Thank you in advance for your attention to this matter.", style: styles.content},
    {type: 'text', text: "",  }, 
    {type: 'text', text: "", style: styles.content },
    {type: 'text', text: "", style: styles.content },
      
      
      {type: 'text', text: "Sincerely,", style: styles.adress },
      { 
        type: "text", 
        text: `${footerAdress} (computer generated letter)`, 
        style: styles.adress 
      },
      // {type: 'text', text: "", },
      // {type: 'text', text: "",  style:styles.empty},
      // { type: 'text',text: "",  style:styles.secondEmpty},
      // {type: 'image',src:fileImages,style:styles.images },

    ]
  };
  if (Array.isArray(fileImage) && fileImage.length > 0) {
    datas.letterContent.push({ type: 'text', text: "Attachments :", style: styles.content });

    fileImage.forEach((fileImages, index) => {
      const trimmedFileName = fileImages.file.substring(12);
      datas.letterContent.push({ type: 'text', text: trimmedFileName, style: styles.content });
      datas.letterContent.push({ type: 'image', src: `${BASE_URL_IMAGE}/${fileImages.file}`, style: styles.images, alt: `Attachment ${index + 1}` });
    });
  } else {
    // Handle the case where fileImage is an empty array
    datas.letterContent.push({ type: 'text', text: "", style: styles.content });
  }
  
  
  if (includeComments && generalCommentDetails.length > 0) {
    // Add the "Comments" heading before looping through comments
    datas.letterContent.push({ type: 'text', text: "Comments :", style: styles.content });

    generalCommentDetails.forEach((commentDetail) => {
      // Combine the name and comment into a single line
      const combinedText = `@${commentDetail.createdByName} : ${commentDetail.comments}`;
      datas.letterContent.push({ type: 'text', text: combinedText, style: styles.content });
  });
}
  return (
    <PDFDocument data={datas} />
  );
};
const handleClickPdf = async () => {
  console.log("test",logoUrl);
  try {
    const pdfData = handleRenderPDF();
    const blob = await pdf(pdfData).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};

  const viewDataTable = useMemo((rowData) => [
    {
      accessorKey: "serialNo",
      header: "Serial No",
      size: 20,
      Cell: ({ row }) => row.index + 1,

    },
    {
      accessorKey: "name",
      header: "Commented By",
      size: 20,
    },
    {
      accessorKey: "role",
      header: "Role",
      size: 20,
    },
    {
      accessorKey: "status",
      header: "Review Status",
      size: 20,
      accessorFn: (row) => convertToPascalCase(row.status),

    },
    {
      accessorKey: "auditDateAndTime",
      header: "Review DateTime",
      size: 20,
      accessorFn: (row) => moment(row.auditDateAndTime).format("MM/DD/YYYY hh:mm:ss a"),

    },
    // {
    //   accessorKey: "comments",
    //   header: "Review Comments",
    //   size: 20,
    // },
  ]);
  const handleEditClick = (index, generalCommentsId) => {
    console.log("Edit button clicked", index, generalCommentsId);
    setEditModes((prevModes) =>
      prevModes.map((mode, i) => (i === index ? true : mode))
    );
    setCommentsId(generalCommentsId);
    console.log("CommentsId set:", generalCommentsId);
    getGeneralCommentsById();
  };
  
  const handleInputChange = (e) => {
    setGeneralCommentsData(e.target.value);
  };
  const handleInputChangeAdd = (e) => {
    setGeneralCommentsDataAdd(e.target.value);
  };
  const handleCancelClick = () => {
   
    getGeneralComments();
    setEditModes(false);
  };
  
  const getGeneralComments = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;
  
      console.log(storedToken);
  
      const response = await axios.get(
        `${BASE_URL}${GET_ALL_GENERAL_COMMENTS}/${maintenanceId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);
  
      if (response.status === 200) {
        const data = response.data;
        setGeneralCommentDetails(data);
        setEditModes(Array(data.length).fill(false));
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };
  
  useEffect(() => {
    getGeneralComments();
  }, []);
  
  
  const getGeneralCommentsById = async () => {
    try {
      const storedToken = token;
  
      console.log(storedToken);
  
      const response = await axios.get(
        `${BASE_URL}${GENERAL_COMMENTS}/${commentsId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response);
  
      if (response.status === 200) {
        const data = response.data;
        console.log(response.data);
        setGeneralCommentsData(data.comments);
  
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };
  
  useEffect(() => {
    getGeneralCommentsById();
  }, [commentsId]);
  
  

  return (
    <div>
      {isLoading && <CustomLoading />}
      {/* <CustomViewPage data={data} exportedBy={true} /> */}
      {/* <CustomViewPages data={data} exportedBy={true} handleExportss={handleClickPdf} checked={includeComments}
      handleCheckBoxChange={handleCheckboxChange}/> */}
       <CustomViewReportHeading onClick={handleClick} 
      handleExportss={handleClickPdf} 
       title={"Maintenance Request"} checked={includeComments}
       handleCheckBoxChange={handleCheckboxChange} exportedBy={true}>
              <CustomViewWithTooltip data={data}  />
      <CustomToastContainer />

      <br/>
      {reviewComments.length > 0 &&
<CustomReviewTable columns={viewDataTable} data={reviewComments} actions={false}/>
}
<br/>

<>
<CustomHeading title="General Comments" />

  <Card>
  
  <CardContent>
    {generalCommentDetails && generalCommentDetails.length > 0 ? (
      generalCommentDetails.map((comment, index) => (
        <div key={comment.generalCommentsId}>
          <section className="grid md:grid-cols-1 xl:grid-cols-1 gap-3 mt-6 ml-16">
            <p>
              <span className="text-primary" style={{ float: "left" }}>
                @ {comment.createdByName}{" "}
              </span>
              <span style={{ float: "right" }}>


                
                {moment(comment.createdDateAndTime).format(
                  "MM/DD/YYYY hh:mm:ss a"
                )}
              </span>
              
                <p className="mt-8 mb-8">{comment.comments}</p>
              
            </p>
          </section>

          <Divider />
        </div>
      ))
      ) : (
        <div className="text-center mt-10">
          <p>No comments to display</p>
        </div>
      )}
  </CardContent>
</Card>
</>
</CustomViewReportHeading>
    </div>
  );
}

export default ArcMcBmRequestReportMrView;
