import { ListAlt } from "@mui/icons-material";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CustomHeading from "../../components/CustomHeading";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import { useSelector } from "react-redux";
import axios from "axios";
import { ARC_COMPLIANCE_REQUEST, BASE_URL, RAISED_BY_ARC_CR_REQUEST_HO } from "../../utills/ApplicationRouting";
import moment from "moment";
import CustomMaterialWithoutActons from "../../components/CustomMaterialWithoutActons";
import CustomLoading from "../../components/CustomLoading";

function RaisedByArcList() {
  const [open, setOpen] = useState(false);
  const { token, userLoginDetails, roles, homeId } = useSelector(state => state.hybridhoa);
  const [complianceRequestDetails, setComplianceRequestDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  //  const handleViewClick = (selectedRow) => {
  //    navigate(`/raisedbyarc/${selectedRow.row.original.complainceId}`);

  //  };
  // updated by Ranjith//
  const handleViewClick = (selectedRow) => {
    const complianceId = selectedRow.row.original.complainceId;


    if (roles.includes('ROLE_BM')) {
      navigate(`/raisedbyarcs/${complianceId}`);
    } else {
      navigate(`/raisedbyarc/${complianceId}`);

    }
  };
  // updated by Ranjith//
  const getComplianceRequestDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token
      console.log(storedToken);
      const response = await axios.get(`${BASE_URL}${ARC_COMPLIANCE_REQUEST}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setComplianceRequestDetails(data);
        setIsLoading(false);

      } else {
        throw new Error('Failed to fetch data');
      }

    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  const getComplianceRequestDetailsBm = async () => {
    try {
      const storedToken = token

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${RAISED_BY_ARC_CR_REQUEST_HO}?homeId=${homeId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setComplianceRequestDetails(data);
        setIsLoading(false);

      } else {
        throw new Error('Failed to fetch data');
      }

    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };
  const getComplianceRequestDetailsHo = async () => {
    try {
      setIsLoading(true);
      const storedToken = token

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${RAISED_BY_ARC_CR_REQUEST_HO}?homeId=${homeId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setComplianceRequestDetails(data);
        setIsLoading(false);

      } else {
        throw new Error('Failed to fetch data');
      }

    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };
  const getComplianceRequestDetailsMc = async () => {
    try {
      setIsLoading(true);
      const storedToken = token

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${RAISED_BY_ARC_CR_REQUEST_HO}?homeId=${homeId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setComplianceRequestDetails(data);
        setIsLoading(false);

      } else {
        throw new Error('Failed to fetch data');
      }

    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {

      {
        roles.includes('ROLE_BM') ? (
          await getComplianceRequestDetails())
        :
        roles.includes('ROLE_MC') ? (
          // await getComplianceRequestDetails()) 
          await getComplianceRequestDetailsMc())

          :
          roles.includes('ROLE_HO') ? (
            await getComplianceRequestDetailsHo())
            :
            await getComplianceRequestDetailsMc()
      }
    };

    fetchData();
  }, [roles, homeId]);


  const roleMap = {
    ROLE_ARC: "ARC",
    ROLE_BM: "Board Member",
    ROLE_MC: "Management Company"
  };
  const raisedByArcTable = useMemo((rowData) => [
    {
      accessorKey: "crId",
      header: "Id",
      size: 20,
    },
    {
      accessorKey: "category",
      header: "Category",
      accessorFn: (row) => `${row.categoryDetails ? row.categoryDetails.category : ""}  `,

      enableGlobalFilterModes: true,



    },
    {
      accessorKey: "description",
      header: "Description",
      accessorFn: (row) => {
        const description = row && row.description;
        if (description && description.length > 28) {
          return description.slice(0, 28) + "...";
        }
        return description;
      }
    },


    {
      accessorKey: "status",
      header: "Status",
      Cell: ({ row }) => {
        const status = row.original.status;
        return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
      },
    },
    {
      accessorKey: "createdDateAndTime",
      header: "Created Date",
      accessorFn: (row) => moment(row.crCreatedDate || row.createdDateAndTime).format("MM/DD/YYYY"),
    },
    {
      accessorKey: "role",
      header: "Created By",
      accessorFn: (row) => {
        const role = row?.role || "";
        return roleMap[role] || role;
      },
      enableGlobalFilterModes: true,
    }
  ]);

  const rowActions = [
    {
      label: "View",
      icon: <ListAlt color="primary" />,
      click: (row) => handleViewClick(row),
    },

  ]



  return (
    <div>
      {isLoading && <CustomLoading />}
      <CustomHeading title="Compliance Request By ARC" />


      <CustomMaterialWithoutActons
        columns={raisedByArcTable}
        data={complianceRequestDetails}
        //  rowActions={rowActions}
        isLoading={isLoading}
        onRowClick={handleViewClick}
      />
    </div>
  );
}

export default RaisedByArcList