import { ListAlt } from "@mui/icons-material";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {  useNavigate } from "react-router-dom";
import CustomHeading from "../../components/CustomHeading";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import { useSelector } from "react-redux";
import { BASE_URL, COMPLIANCE_REQUEST, RAISED_BY_HO_CR_REQUEST_ARC } from "../../utills/ApplicationRouting";
import axios from "axios";
import moment from "moment/moment";
import CustomMaterialWithoutActons from "../../components/CustomMaterialWithoutActons";
import CustomLoading from "../../components/CustomLoading";

function RaisedByHoList() {
    const [open, setOpen] = useState(false);
     
    const navigate = useNavigate();
    const { token, userLoginDetails,roles } = useSelector((state) => state.hybridhoa);
    const [notificationDetails, setNotificationDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
   const handleViewClick = (selectedRow) => {
     navigate(`/raisedbyho/${selectedRow.row.original.complainceId}`);
   };
   const getHoDetails = async () => {
    try {
      const storedToken = token;
      setIsLoading(true);
      console.log(storedToken);
        
      const response = await axios.get(`${BASE_URL}${RAISED_BY_HO_CR_REQUEST_ARC}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        setNotificationDetails(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(()=>{
    getHoDetails()
  },[])
    
 
   const raisedByArcTable = useMemo((rowData) => [
     {
       accessorKey: "crId",
       header: "Compliance RequestId",
       size: 20,

     },
     {
      accessorKey: "category By",
      header: "Category",
      Cell: ({ row }) => {
        if (row.original.categoryDetails === null || row.original.categoryDetails === undefined) {
          return "N/A";
        } else {
          return row.original.categoryDetails.category || "N/A";
        }
      },
    },
    {
      accessorKey: "created By",
      header: "Created By",
      accessorFn: (row) => {
        const firstName = row.createdByDetails?.firstName || "";
        const lastName = row.createdByDetails?.lastName || "";
        const fullName = `${firstName} ${lastName}`;
    
        // Check if the combined length is greater than 28 characters
        if (fullName.length > 28) {
          return fullName.slice(0, 28) + "...";
        }
        
        return fullName; // Return the full name if it's 28 characters or less
      },
      sortDescFirst: true,
    },
    
  
    {
      accessorKey: "createdByHomeAddress",
      header: "Address",
      accessorFn: (row) => {
        const fallbackAddress = row.createdByHomeAddress;
        if (fallbackAddress && fallbackAddress.length > 28) {
          console.log("workingggg");
          return fallbackAddress.slice(0, 28) + "...";
        }
        return fallbackAddress || "";  // Return the address or an empty string if it's null or undefined
      },
      sortDescFirst: true,
    },
    
    
    {
      accessorKey: "createdTo",
      header: "Created To",
      accessorFn: (row) => {
        const firstName = row.createdToDetails?.firstName || "";
        const lastName = row.createdToDetails?.lastName || "";
        const fullName = `${firstName} ${lastName}`;
        
        // Check if the full name length is greater than 28 characters
        if (fullName.length > 28) {
          return fullName.slice(0, 28) + "...";
        }
    
        return fullName; // Return the full name if it's 28 characters or less
      },
      sortDescFirst: true,
    },
    {
      header: "Created To Address",
      accessorFn: (row) => {
        // const communicationAddress = row.createdToDetails?.addressForCommunication;
        const fallbackAddress = row.address?.address ;
        const address =  fallbackAddress || "";
    
        // Check if the address length is greater than 28 characters
        if (address.length > 28) {
          return address.slice(0, 28) + "...";
        }
    
        return address; // Return the full address if it's 28 characters or less
      },
      sortDescFirst: true,
    },
     {
       accessorKey: "description",
       header: "Description",
       accessorFn: (row) => {
        const description = row && row.description;
        if (description && description.length > 28) {
          return description.slice(0, 28) + "...";
        }
        return description;
      }
     },
    
     {
       accessorKey: "status",
       header: "Status",
     },
           {
       accessorKey: "createdDateAndTime",
       header: "Created Date",
       accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),

     },
   ]);

  // const rowActions = [
  //    {
  //      label: "View",
  //      icon: <ListAlt color="primary" />,
  //      click:(row)=> handleViewClick(row),
  //    },
    
  //  ]
  
 
 
  
   return (
     <div>
       <CustomHeading title="Compliance Request By HO" />
 
       {isLoading && <CustomLoading />}
       
       <CustomMaterialWithoutActons
         columns={raisedByArcTable}
         data={notificationDetails}
        //  rowActions={rowActions}
         onRowClick ={handleViewClick}
         
       />
     </div>
   );
 }
export default RaisedByHoList