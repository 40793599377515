import React, { useRef,useEffect } from "react";
import logo from "../assets/img/logo.png";
import { useState } from "react";
import axios from "axios"; 
import { useDispatch, useSelector } from "react-redux";
import { clearAuthToken, setSideNavState } from "../redux/Hybridhoa";
import SideMenu from "./sidenav/SideMenu";
import { Link, useNavigate } from "react-router-dom";
import { ExitToAppOutlined, Person, PersonOutline } from "@mui/icons-material";
import { Avatar, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import { FaHouseChimney, FaRegChessQueen, FaUserTie } from "react-icons/fa6";
import {BASE_URL,BASE_URL_IMAGE,GROUP_CONFIG} from "../utills/ApplicationRouting";
import HomeDetailsSelect from "./HomeDetails";
import { setRoles } from "../redux/Hybridhoa";


const CustomHeader = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { token, roles,userDetails, userRoles } = useSelector((state) => state.hybridhoa);
  const [selectedRole, setSelectedRole] = useState(roles);
  const[image,setImage]= useState("");
  const [imageBlob, setImageBlob] = useState(null); 
  const { homeDetails } = useSelector((state) => state.hybridhoa);
  const [groupName,setGroupName] = useState(" ");

  const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

 
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
 
  const sidebarRef = useRef();

  const handleBackdropClick = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
    }
  };



  const stringToBlob = (content, type) => {
    const byteArray = new Uint8Array(content.length);
    for (let i = 0; i < content.length; i++) {
      byteArray[i] = content.charCodeAt(i);
    }
    return new Blob([byteArray], { type: type });
  };
  
  // Usage
  const handleMenuItemClick = () => {
    if (window.innerWidth <= 640) { // Small screen condition fixed by mohan
      setIsSidebarOpen(false);
    }
  };

  const dispatch = useDispatch();

  const handleLogout = () => {
    // Clear token from local storage
    localStorage.removeItem("token");
    localStorage.removeItem("reportDatacomplaince");
    localStorage.removeItem("reportDatamaintenance");
    localStorage.removeItem("ArcreportDatacomplaince");
    localStorage.removeItem("ArcreportDatamaintenance");
    localStorage.removeItem("arData");
    localStorage.removeItem("homeActivity");
    localStorage.removeItem("homeActivitys");
    localStorage.removeItem("arReport");
    localStorage.removeItem("ArReportFormData");
    localStorage.removeItem("RequestReportFormData");
    localStorage.removeItem("ArcRequestReportFormData");
    localStorage.removeItem("formData");
    
    dispatch(clearAuthToken());
navigate('/')
    
  };
  const yourText = userDetails.firstName;

  console.log("role",userRoles)

  const navigate = useNavigate()
  const dropdownRef = useRef();
  const handleRoleChange = (role) => {
    setSelectedRole(role);
    // Perform role-based navigation logic
    switch (role) {
      case 'ROLE_HO':
        console.log("HO");
        navigate('/dashboard-ho');
        break;
      case 'ROLE_ARC':
        console.log("arc");
       navigate('/dashboard-arc');
        break;
      case 'ROLE_BM':
        console.log("Arc");
        navigate('/dashboard-bm');
        break;
      // Add more cases for other roles as needed
      default:
        // Handle default case or show an error message
        break;
    }
    dispatch(setRoles([role]));
  };

  const getGroupConfigurationDetails = async () => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL}${GROUP_CONFIG}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
  
      if (response.status === 200) {
        const data = response.data;
        const item = data.find((item) => item.topic === 'Logo');
        const nameGroup = data.find((item) => item.topic === 'Header')
        console.log("data",nameGroup.content);
        const groupContent = nameGroup.content
        setGroupName(groupContent);
        // setGroupName(stripHtml(groupContent));
        if (item) {
          const imageUrl = item.content;
          setImage(imageUrl);
          console.log("Logo URL:", imageUrl);
        } else {
          console.error("Logo not found in response data");
        }
      } else {
        console.error("Failed to fetch data. Status:", response.status);
        console.error("Response:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Status code:", error.response.status);
      }
    }
  };
  

  useEffect(()=>{
    getGroupConfigurationDetails()
    console.log("working");
  },[])

  useEffect(() => {
    console.log("imageBlob:", imageBlob);
  }, [imageBlob]);
  
const getFile=async () => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL_IMAGE}/${image}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
        responseType: "blob",
      });
      const blob = response.data; // Accessing the blob data from the response
      const fileURL = URL.createObjectURL(blob);
      setImageBlob(fileURL);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  // useEffect(()=>{
  //   getFile()
  // },[image])
  useEffect(() => {
    if (image) {
      getFile();
    }
  }, [image]);
  

  // const loadImage = async (imageContent) => {
  //   try {
  //     const byteArray = new Uint8Array(imageContent.length);
  //     for (let i = 0; i < imageContent.length; i++) {
  //       byteArray[i] = imageContent.charCodeAt(i);
  //     }
  //     const blob = new Blob([byteArray], { type: "image/png" });
  //     setImageBlob(blob);
  //   } catch (error) {
  //     console.error("Error loading image:", error);
  //   }
  // };
  

  // const handleClickOutside = (event) => {
  //   if (
  //     dropdownRef.current &&
  //     !dropdownRef.current.contains(event.target)
  //   ) {
  //     setIsDropdownOpen(false);
  //   }
  // };
  // if (isDropdownOpen) {
  //   document.addEventListener("mousedown", handleClickOutside);
  // } else {
  //   // Remove event listener when the dropdown is closed
  //   document.removeEventListener("mousedown", handleClickOutside);
  // }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !sidebarRef.current.contains(event.target) &&
        !event.target.closest(".MuiMenu-list") // Check if the clicked target is a child of the dropdown options
      ) {
        setIsDropdownOpen(false);
      }
    };
  
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  const cardData = [
    {
      name: 'Board Member',
      icon: <FaRegChessQueen size={20} color='#045F9F' />,
      color: 'red',
      role: 'ROLE_BM',
    },
    {
      name: 'ARC',
      icon: <FaUserTie size={20} color='#045F9F' />,
      color: 'blue',
      role: 'ROLE_ARC',
    },
    {
      name: 'Home Owner',
      icon: <FaHouseChimney size={20} color='#045F9F'/>,
      color: 'green',
      role: 'ROLE_HO',
    },
  ];

  const filteredCardData = cardData.filter(item => userRoles.includes(item.role)); // Filter based on user's roles

  const options = filteredCardData.map((item) => (
    <MenuItem key={item.role} value={item.role} className="flex items-center">
      <div className="flex items-center">
        <span className="mr-2">{item.icon}</span>
        <span>{item.name}</span>
      </div>
    </MenuItem>
  ));

  
  return (
    <>
      <nav class="fixed top-0 z-50 w-full bg-white border-b border-gray-100 :bg-gray-800 drop-shadow-sm h-14">
        <div class="px-3 py-3 lg:px-5 lg:pl-3">
          <div class="flex items-center justify-between">
            <div class="flex items-center justify-start rtl:justify-end">
              <button
                onClick={toggleSidebar}
                type="button"
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 :texdarkt-gray-400"
              >
                <span class="sr-only">Open sidebar</span>
                <svg
                  class="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              {/* <a href="#" class="flex ms-2 md:me-24">
                <img src={logo} class="h-8 me-3" alt="HybridHOA Logo" />
              </a> */}
                            <a href="#" class="flex ms-2 md:me-24">
                <img src={logo} class="h-11 me-3 hidden md:block" alt="HybridHOA Logo" />
              </a>

            
              {/* <img src={image} alt="image" className="h-8 w-auto" /> */}
              {/* {imageBlob && (
  <Avatar src={URL.createObjectURL(imageBlob)} alt="Logo" className="h-8 w-auto" />

)} */}
                     {/* <img src={imageBlob} alt="image" class="h-8 me-3"/> */}

                     {/* <img src={imageBlob} alt="image" className="h-8 me-3 ml-[23px]" /> */}
                     <img src={imageBlob}  className="h-8 max-w-full me-3 ml-[23px] md:max-h-8" />


<p><b>{groupName}</b></p>

{/* <p><b dangerouslySetInnerHTML={{ __html: groupName }}></b></p> */}


            
                           {/* <a href="#" class="flex ms-4 md:me-24">
               <p className="text-sm font-sans">{roles[0] === 'ROLE_BM'  ? 'BOARD MEMBER': roles[0] === 'ROLE_ARC' ? 'ARC' : roles[0] === 'ROLE_HO' ?'HOME OWNER'  : ''}</p>
              </a> */}
              {/* <p className={`text-sm font-sans ${roles[0] === 'ROLE_HO' || roles[0] === 'ROLE_BM' || roles[0] === 'ROLE_ARC' ? 'ml-[810px]' : ''}`}>
  {roles[0] === 'ROLE_BM' ? 'BOARD MEMBER' : roles[0] === 'ROLE_ARC' ? 'ARC' : roles[0] === 'ROLE_HO' ? 'HOME OWNER' : ''}
</p> */}


<div className=" hidden md:block absolute right-20">


{/* { homeDetails && homeDetails.length !== 1 && (
        <div className="hidden md:block absolute right-48">
          <HomeDetailsSelect />
        </div>
      )} */}

     <div className="hidden md:hidden lg:flex absolute right-48 items-center ml-auto">
    {homeDetails.length > 1 && roles.includes('ROLE_HO') && (
      <div className="flex items-center">
        <span className="whitespace-nowrap">Home Address:</span>
        <div className="ml-2"><HomeDetailsSelect /></div>
      </div>
    )}
  </div>


   
   {userRoles.length === 1 ? (
  // Render the selected role directly without dropdown
  <span className="text-sm font-sans ml-4">
    {userRoles[0] === "ROLE_BM"
      ? "BOARD MEMBER"
      : userRoles[0] === "ROLE_ARC"
      ? "ARC"
      : userRoles[0] === "ROLE_HO"
      ? "HOME OWNER"
      : ""}
  </span>
) : (
  // Render the dropdown icon and options
  <Select
    value={selectedRole}
    onChange={(e) => handleRoleChange(e.target.value)}
    displayEmpty
    className="flex text-sm text-gray-700"
    sx={{
      minWidth: "100px",
      maxHeight: "40px",
      display: "flex",
      alignItems: "center",
      boxShadow: "none",
      ".MuiOutlinedInput-notchedOutline": { border: 0 },
      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        border: 0,
      },
      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: 0,
      },
    }}
    onClick={() => setIsDropdownOpen(false)} // Close dropdown after select Role
  >
    {options}
  </Select>
)}
            </div>
            </div>
            {/* <b>Logged in as :</b>  */}
            <div className="flex items-center">
              <div className="relative" ref={dropdownRef}>
                <button
                  type="button"
                  className="flex text-sm bg-gray-800 rounded-full  relative ml-15"
                  aria-expanded={isDropdownOpen}
                  onClick={toggleDropdown}
                >
                  <span className="sr-only">Open user menu</span>
                  <div className="w-10 h-10 -translate-x-14 -translate-y-6 bg-primary rounded-full absolute flex items-center justify-center">
{/*                   
                  <span className="text-white font-bold text-lg">
      {yourText.slice(0, 1).toUpperCase()} 
   </span>  */}


    {userDetails.firstName && ( // Check if userDetails.firstName exists and is not empty
    <span className="text-white font-bold text-lg ">
      {userDetails.firstName.slice(0, 1).toUpperCase()}
    </span>
  )}


                  </div>
                </button>
               
                {isDropdownOpen && (
                <div
                className={`absolute right-0 mt-2 w-48 rounded-md bg-white divide-y divide-gray-100 shadow-lg ${homeDetails.length > 1 ? 'h-44 lg:h-24 md:h-32' : 'h-22'}`}
                id="dropdown-user"
              >
              
              
                    <ul className="py-1" role="none">
                      <li>
                        {roles == "ROLE_GU" || roles == "ROLE_MC" ? (
                          <Link
                            to="/gumcprofile"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            role="menuitem"
                            onClick={() => setIsDropdownOpen(false)} // Close dropdown after clicking profile
                          >
                            <PersonOutline color="primary" /> Profile
                          </Link>
                        ) : (
                          <Link
                            to="/profile"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            role="menuitem"
                            onClick={() => setIsDropdownOpen(false)} // Close dropdown after clicking profile
                          >
                            <PersonOutline color="primary" /> Profile
                          </Link>
                        )}
                      </li>
                      <li className="md:hidden lg:hidden" > 
                        {
                          roles == "ROLE_ARC" || roles == "ROLE_BM" || roles == "ROLE_HO" ?
                          <Select
                          value={selectedRole}
                          onChange={(e) => handleRoleChange(e.target.value)}
                          displayEmpty
                          className="flex text-sx text-gray-700"
                          sx={{ minWidth: "100px",maxHeight:'40px', display: "flex", alignItems: "center",boxShadow: "none",
                          ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                         }}
                         onClick={() => setIsDropdownOpen(false)} // Close dropdown after select Role
                        >
                          {options}
                        </Select>
                        :
                        null
                        }

  </li>
   <li>
                        <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          role="menuitem"
                          onClick={handleLogout}
                        >
                          <ExitToAppOutlined color="primary" /> Logout
                        </a>
                      </li>
                      
                      {roles.includes('ROLE_HO') && homeDetails.length > 1 &&  (
    <li className="lg:hidden ">
      <div className="absolute">
        <div className="w-full flex items-center mt-2 ml-2">
          <HomeDetailsSelect className="flex-1" />
        </div>
      </div>
   
    </li>
  )}
                     
                    </ul>
                  </div>
                )}
              </div>
              </div>
          </div>
        </div>
      </nav>

      <aside
        ref={sidebarRef}
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } bg-white border-r border-gray-100 sm:translate-x-0 shadow-lg`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 overflow-y-auto bg-white text-red-500 ">
          <SideMenu onMenuItemClick={handleMenuItemClick} />
        </div> 
      </aside>

      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-30"
          onClick={handleBackdropClick}
        />
      )}
    </>
  );
};

export default CustomHeader;
