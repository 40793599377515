import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material';


const CustomOptions = ({ label, options, value, onChange, disabled, fieldRequiredIcon }) => {
  return (
    <FormControl variant="standard" className='w-60' disabled={disabled}>
      <InputLabel>{label}<span style={{ color: "red" }}>{fieldRequiredIcon}</span></InputLabel>

      <Select
        value={value}
        onChange={onChange}
        label={label}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={option.disabled} // Check the disabled property
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};


export default CustomOptions;

