import React, { useEffect, useState } from "react";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../../../components/CustomInputField";
import CustomInputFieldExpand  from"../../../components/CustomInputFieldExpand";

import {
  BYLAW_REQUIRED_MESSAGE,
  CATEGORY_INVALID_MESSAGE,  
  CATEGORY_REQUIRED_MESSAGE,
  CATEGORY_SUBJECT_REQUIRED_MESSAGE,
  CREATED_DATE_REQUIRED,
  DESCRIPTION_REQUIRED_MESSAGE,
  HOME_ADDRESS_INVALID_MESSAGE,
  HOME_ADDRESS_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_THOUSAND,
  MAX_LENGTH_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
  VIOLATION_DESCRIPTION_REQUIRED_MESSAGE,
} from "../../../utills/ApplicationConstants";
import {
  Autocomplete,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import CustomHeading from "../../../components/CustomHeading";
import { FileUploader } from "react-drag-drop-files";
import { request } from "../../../services/AxiosConfig";
import axios from "axios";
import { BASE_URL, BYLAW, CATEGORY, COMPLIANCE_REQUEST_BY_ID, HOMES, HOMES_DETAILS } from "../../../utills/ApplicationRouting";
import { useSelector } from "react-redux";
import CustomAutoComplete from "../../../components/CustomAutoComplete";
import CustomToastContainer from "../../../components/CustomToastContainer";
import { Tooltip, IconButton } from "@mui/material";
import CustomFile from "../../../components/CustomFile";
import CustomLoading from "../../../components/CustomLoading";
import CustomTooltipField from "../../../components/CustomTooltipField";

import CustomDatePicker from "../../../components/CustomDatePicker";

import { Box } from "@mui/system";
import dayjs from "dayjs";



function ArcComplianceAdd() {
  // const [file, setFile] = useState(null);
  // const fileTypes = ["JPG", "PNG", "JPEG","PDF"];
  // const navigate = useNavigate();

  // const handleViewClick = () => {
  //   navigate(-1);
  // };

  // const handleFileChange = (file) => {
  //   setFile(file);
  // };

  // const homeAddess = [
  //   { label: "HomeReq3" },
  //   { label: "PrimaryGroup5" },
  //   { label: "demoaddress" },
  //   { label: "cutomeraddress" },
  //   { label: "resend1" },
  //   { label: "resend2" },
  //   { label: "HomeEmailAddress1" },
  //   { label: "HomeEmailAddress" },
  //   { label: "assign email" },
  //   { label: "adresstharun" },
  // ];

  // const category = [
  //   { label: "Parking" },
  //   { label: "Electricity" },
  //   { label: "Grass" },
  //   { label: "Buliding" },
  //   { label: "Others" },
  // ];

  // const bylaw = [{ label: "testing bylaw" }];

  

  // const {
  //   handleSubmit,
  //   control,
  //   formState: { errors },
  //   setValue,
  // } = useForm();
  // const onSubmit = (data) => {};

  const [file, setFile] = useState([]);
    const [homes,setHomes]= useState([]);
    const [isLoading,setIsLoading]= useState(true);
    const [homeAddresses, setHomeAddresses] = useState([]);
    const [categoryDetails,setCategoryDetails] =useState([]);
    const [byLawDetails,setByLawDetails] = useState([]);
    const [byLawDescription,setByLawDescription] = useState('');
    const [selectedHomeId, setSelectedHomeId] = useState(null);
    const [selectedBylawId,setSelectedBylawId]= useState(null);
    const [selectedCategoryId,setSelectedCategoryId]= useState(null);
    const [homeDetails,setHomeDetails]=useState('');
    const [filteredOption,setFilteredHomeAddresses]=useState('')
    const [homeOwnerDetails,setHomeOwnerDetails]= useState('');
    const [selectedBylawValue, setSelectedBylawValue] = useState(null);
    const [fileError, setFileError] = useState("");
    const [bylawsUpdated, setBylawsUpdated] = useState(true);
    const { token, homeId,roles } = useSelector(state => state.hybridhoa);
    const [createdDate,setCreatedDate]=useState("");
  const fileTypes = ["JPG", "PNG", "JPEG","PDF"];
  const navigate = useNavigate();

  const handleViewClick = () => {
    navigate(-1);
  };

  const handleFileChange = (file) => {
    setFile(file);
  };


  const getHomes = async () => {
    try {
      const storedToken = token;
     
  
      const response = await axios.get(`${BASE_URL}${HOMES_DETAILS}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);
  
      if (response.status === 200) {
        const data = response.data;
        setHomeAddresses(data);
        setHomeOwnerDetails(data.homeOwnerDetails)
        const filteredHomeAddresses = data.filter(home => {
          console.log("Checking home:", home);
          return homeId.includes(home.homeId);
        });
        setFilteredHomeAddresses(filteredHomeAddresses); 
        setIsLoading(false);
      } else {
        setIsLoading(false);

        throw new Error('Failed to fetch data');
      }
  
    } catch (error) {
      // Handle error
      setIsLoading(false);

      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getHomes();
  }, []);


  const handleHomeChange = (event, newValue) => {
    if (newValue && newValue.value !== undefined && newValue.value !== null) {
      const selectedHomeId = newValue.value;
      const selectedHome = homeAddresses.find((home) => home.homeId === selectedHomeId);
      // const homeOwner = selectedHome.homeOwnerDetails;
  
      if (selectedHome) {
        const { name } = selectedHome;
        const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";
        console.log("Selected Home Description:", selectedHomeDescription);
  
        // Set the selected home details to the state
        setHomeDetails(selectedHomeDescription);
  
        // Use setValue to set the selected homeId in the react-hook-form
        setValue("selectedOption", selectedHomeId);
  
        // You can also do other operations if needed
        console.log("Selected Home:", selectedHome);
        console.log("Home Details:", homeAddresses);
        
      } else {
        setHomeDetails('');
        setValue("selectedOption", null);
      }
    } else {
      // Handle the case when the selected option is removed (e.g., by pressing backspace)
      setHomeDetails('');
  
      // Use setValue to clear the selected homeId in the react-hook-form
      setValue("selectedOption", null);
    }
  };
  

  

  const getCategory = async () => {
    try {
      const storedToken = token;
    
  
      const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);
  
      if (response.status === 200) {
        const data = response.data;
        setCategoryDetails(data); // Set the fetched data to the state
        setIsLoading(false);
      } else {
        setIsLoading(false);

        throw new Error('Failed to fetch data');
      }
  
    } catch (error) {
      setIsLoading(false);

      // Handle error
      console.error("Error fetching data:", error);
    }
  };
  
  useEffect(() => {
    getCategory();
  }, []);


  const handleCategoryChange = (event, newValue) => {
    if (newValue && newValue.value !== undefined && newValue.value !== null) {
      const selectedCategoryId = newValue.value;
      const selectedHomeDescription = byLawDetails.find(
        (home) => home.homeId === selectedHomeId
      )?.description;
  
      // Set the selected homeId to the state
      setValue("category", selectedCategoryId);
  
      // You can also do other operations if needed
  
      console.log("selectedCategoryId:", selectedCategoryId);
      console.log("Home Details:", homeAddresses);
    } else {
      setValue("category",null);
    }
  };
  
  

  const getBylaw = async () => {
    try {
      const storedToken = token;
    
  
      const response = await axios.get(`${BASE_URL}${BYLAW}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);
  
      if (response.status === 200) {
        const data = response.data;
        setByLawDetails(data); 
        setBylawsUpdated(data.length > 0);
        // setByLawDescription(data.description);
        setIsLoading(false);
      } else {
        setIsLoading(false);

        throw new Error('Failed to fetch data');
      }
  
    } catch (error) {
      setIsLoading(false);

      // Handle error
      console.error("Error fetching data:", error);
    }
  };
  
  useEffect(() => {
    getBylaw();
  }, []);

  const handleCreatedDateChange = (selectedDob) => {
    const date = new Date(selectedDob); // Convert to Date object
    let year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).slice(-2); // Add leading zero
    let day = ('0' + date.getDate()).slice(-2);          // Add leading zero
    let hours = ('0' + date.getHours()).slice(-2);       // Add leading zero
    let minutes = ('0' + date.getMinutes()).slice(-2);   // Add leading zero
    let seconds = ('0' + date.getSeconds()).slice(-2);   // Add leading zero
  
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  
    setCreatedDate(formattedDate); // Update the state with formatted date
  };

  const handleByLawChange = (event, newValue) => {
    if (newValue && newValue.value !== undefined && newValue.value !== null) {
      const selectedByLawId = newValue.value;
      setValue("selectedBylaw", selectedByLawId);
      const selectedByLawDescription = byLawDetails.find(
        (byLaw) => byLaw.byLawId === selectedByLawId
      )?.description;
  
      setByLawDescription(selectedByLawDescription);

      // Update the state here
      setSelectedBylawValue(newValue);
    } else {
      // Clear the state values
      setByLawDescription('');
      setValue("selectedBylaw", null);
  
      // Update the state here
      setSelectedBylawValue(null);
    }
  };
  useEffect(()=>{
    console.log("byLawDescription", byLawDescription)
  },[byLawDescription])

  const renderedAddresses = homeAddresses
  .filter(home => !filteredOption.includes(home)) // Exclude filteredHomeAddresses
  .map((home, index) => ({
    address: home.address, 
    value: home.homeId,   
    key: index,
  }));

  const renderedCategory = categoryDetails
    .filter(category => category.type === 'CR') // Filter categories with type 'CR'
    .map((category, index) => ({
      category: category.category,
      value: category.categoryId,
      key: index,
    }));

    const renderedBylaw = byLawDetails.map((ByLaw, index) => ({
      topic: ByLaw.topic,
      value: ByLaw.byLawId,
      key: index,
    }));
  const {
    handleSubmit,
    control,
    formState: { errors, dirtyFields }, 
    setValue,watch
  } = useForm();

  const today = dayjs(); // Current date as Day.js object
const maxDate = today; // Max date is today
const minDate = today.subtract(3, 'year'); // Min date is 3 years ago


  useEffect(() => {
    const selectedBylaw = watch('selectedBylaw');
  
  
      if (!selectedBylaw || selectedBylaw === "") {
        setByLawDescription('');
        // setIsBylawFieldEmpty(true);
      } else {
        // Find the matching byLaw from byLawDetails
        const matchingByLaw = byLawDetails.find((byLaw) => byLaw.byLawId === selectedBylaw);
  
        if (matchingByLaw) {
          setByLawDescription(matchingByLaw.description);
          // setIsBylawFieldEmpty(false);
        } else {
          // Handle the case where no matching byLaw is found
          setByLawDescription('');
          // setIsBylawFieldEmpty(true);
        }
      }
    
  }, [watch('selectedBylaw'), byLawDetails]);

  useEffect(() => {
    // Reset fileError when a valid file is selected
    if (file && file.size <= 10 * 1024 * 1024) {
      console.log("File:", file);
      const fileType = file.type && file.type.split("/")[1]; // Check if file.type exists
      console.log("File type:", fileType);
      if (fileType) {
        const fileExtension = fileType.toUpperCase().replace(/[^\w]/g, ""); // Remove special characters
        console.log("File extension:", fileExtension);
        if (fileTypes.includes(fileExtension)) {
          setFileError("");
        }
      }
    }
  }, [file, fileTypes]);

  useEffect(() => {
    const selectedHome = watch('selectedOption');
  
  
      if (!selectedHome || selectedHome === "") {
        setByLawDescription('');
        // setIsBylawFieldEmpty(true);
      } else {
        // Find the matching byLaw from byLawDetails
        const matchingHome = homeAddresses.find((home) => home.homeId === selectedHome);
  
        if (matchingHome) {
          const { name } = matchingHome;
          const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";
  setHomeDetails(selectedHomeDescription);
          // setIsBylawFieldEmpty(false);
        } else {
          // Handle the case where no matching byLaw is found
          setHomeDetails('');
          // setIsBylawFieldEmpty(true);
        }
      }
    
  }, [watch('selectedOption'), homeDetails]);
  useEffect(() => {
    // Reset fileError when a valid file is selected
    
    if(file.length !== 0){
    setFileError("")
    }
    }, [file])
  const onSubmit = (data) => {
    if(file.length===0){
      setFileError("Attachment is Required")
      return;
      }
    const totalFileSize = file.reduce((acc, file) => acc + file.size, 0);
    const maxSizeInMB = 10;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
  
    if (totalFileSize > maxSizeInBytes) {
      // setFileError(`Total file size should not exceed ${maxSizeInMB}MB.`);
      return;
    }
  
    // Check file types
    const allowedFileTypes = ["image/jpg", "image/png", "image/jpeg", "application/pdf"];
    const invalidFiles = file.filter(file => !allowedFileTypes.includes(file.type));
  
    if (invalidFiles.length > 0) {
      // setFileError("Some files have invalid file types. Allowed types are JPG, PNG, JPEG, PDF.");
      return;
    }
 
    const formData = new FormData();
    formData.append('homeId',data.selectedOption);
 
    formData.append('category',data.category);
    formData.append('description',data.violationDescription);
    // formData.append('file',file);
    file.forEach((file) => {
      formData.append('files', file);
      });
    formData.append('bylawId',data.selectedBylaw);
    formData.append('role',roles[0]);
formData.append("crCreatedDate", createdDate);

    setIsLoading(true);

    // console.log('homeId:', selectedHomeId);
    // console.log('category:', selectedCategoryId);
    // console.log('description:', data.violationDescription);
    // console.log('file:', file); 
    // console.log('bylawId:', selectedBylawId);
    // console.log('role:', 'ROLE_HO');

    console.log('formData:', formData);


const headers = {
  'Content-Type': 'multipart/form-data'
};
// return;


request({url:COMPLIANCE_REQUEST_BY_ID, method:"post", data:formData, headers}).then(res=>{
  setIsLoading(false); 
  console.log(res);
  if(res.status==200){
    
    setIsLoading(false); 
    setTimeout(() => {
      navigate(-1);
    }, 1000); // Adjust the delay time as needed (in milliseconds)
    
}
}).catch(error=>{
  console.log(error.response);
  setIsLoading(false); 
  if (error.response) {
    setIsLoading(false);
 
    console.log("error.response.status" + error.response.status);
   

  }  
});
  };


  return (
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomHeading title="Add Compliance" />
        <CustomToastContainer />
        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleViewClick}
          />
        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
                {/*  <Controller
                name="selectedOption"
                control={control} 
                rules={{ required: HOME_ADDRESS_REQUIRED_MESSAGE }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={Boolean(error)}>
                    <Autocomplete
                      fullWidth
                      {...field}
                      id="combo-box-demo"
                      options={homeAddess}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, newValue) => {
                        setValue("selectedOption", newValue, {
                          shouldDirty: true,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span>
                              Select Home Address <span style={{ color: 'red' }}>*</span>
                            </span>
                          }
                          variant="standard"
                          error={Boolean(error) && !field.value}
                          helperText={
                            Boolean(error) && !field.value ? error.message : ""
                          }
                        />
                      )}
                    />
                  </FormControl>
                )}
              />

          <Controller
                name="category"
                control={control}
                rules={{ required: CATEGORY_REQUIRED_MESSAGE }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={Boolean(error)}>
                     <Autocomplete
                      fullWidth
                      {...field}
                      id="combo-box-demo"
                      options={category}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, newValue) => {
                        setValue("category", newValue, {
                          shouldDirty: true,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                         
                          label= {
                            <span>
                              Select Category/Subject <span style={{ color: 'red' }}>*</span>
                            </span>
                          }
                          variant="standard"
                          error={Boolean(error) && !field.value}
                          helperText={
                            Boolean(error) && !field.value ? error.message : ""
                          }
                        />
                      )}
                    />
                  </FormControl>
                )}
              />

<CustomInputField
                fieldName="homeOwnerDetails"
                fieldId="homeOwnerDetails"
                fieldType="text"
                fieldLabel="HomeOwner Details"
                fieldControl={control}
                fieldError={errors}
                disabled={true}
              />

              <CustomInputField
                fieldName="violationDescription"
                requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={5000}
                maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
                fieldId="violationDescription"
                fieldType="text"
                fieldLabel="Violation Description"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                multiline={true}
                numberOfRows={1}
              />

             

             

             

              <Controller
                name="selectedBylaw"
                control={control}
                rules={{ required: BYLAW_REQUIRED_MESSAGE }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={Boolean(error)}>
                    <Autocomplete
                      fullWidth
                      {...field}
                      id="combo-box-demo"
                      options={bylaw}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, newValue) => {
                        setValue("selectedBylaw", newValue, {
                          shouldDirty: true,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span>
                              Select Bylaw <span style={{ color: 'red' }}>*</span>
                            </span>
                          }
                          variant="standard"
                          error={Boolean(error) && !field.value}
                          helperText={
                            Boolean(error) && !field.value ? error.message : ""
                          }
                        />
                      )}
                    />
                  </FormControl>
                )}
              />

              <CustomInputField
                fieldName="bylawDescription"
                fieldId="bylawDescription"
                fieldType="text"
                fieldLabel="Bylaw Description"
                fieldControl={control}
                fieldError={errors}
                disabled={true}
              />

<FormLabel>
              Attach Documents <span style={{color:'red'}}>*</span>
              <FileUploader 
              handleChange={handleFileChange}
              name="file"
              types={fileTypes}
              required
            />
            {file ? <p>Selected File: {file.name}</p> : <p>No file selected</p>}
            </FormLabel> */}
            {/* {homeOwnerDetails !=='' && homeOwnerDetails !== null && (  */}
               <CustomAutoComplete
              fieldName="selectedOption"
              requiredErrorMessage = {HOME_ADDRESS_REQUIRED_MESSAGE}
              id="selectId"
              options={renderedAddresses}
              fieldLabel="Select Home Address"
              fieldControl={control}
              error={errors}
              setValue={setValue}
              fieldRequiredIcon="*" 
              onChange={handleHomeChange}
              invalidOption={HOME_ADDRESS_INVALID_MESSAGE}
              tooltipMessage={"Please select the home address of the homeowner you are creating the compliance for."}
               />
               {/* )} */}

{/* {homeOwnerDetails !=='' && homeOwnerDetails !== null && (  */}
<CustomTooltipField
                fieldName="homeOwnerDetails"
                fieldId="homeOwnerDetails"
                fieldType="text"
                fieldLabel="HomeOwner Name"
                fieldControl={control}
                fieldError={errors}
                disabled={true}
                value={homeDetails}
                multiline={true}
                numberOfRows={2}
                tooltipMessage={"This field displays the name of the homeowner based on the selected home address."}
              />
              {/* )} */}

<CustomAutoComplete 
              fieldName="category"
              requiredErrorMessage = {CATEGORY_SUBJECT_REQUIRED_MESSAGE}
              id="selectId"
              options={renderedCategory}
              fieldLabel="Select Category/Subject"
              fieldControl={control}
              error={errors}
              setValue={setValue}
              fieldRequiredIcon="*"         
             onChange={handleCategoryChange}
             invalidOption={CATEGORY_INVALID_MESSAGE}
             tooltipMessage={"Please select the category or subject relevant to the compliance issue."}
              />


              <CustomTooltipField
                fieldName="violationDescription"
                requiredErrorMessage={VIOLATION_DESCRIPTION_REQUIRED_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={5000}
                maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
                fieldId="violationDescription"
                fieldType="text"
                fieldLabel="Violation Description"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*" 
                multiline={true}
                numberOfRows={2}
                tooltipMessage={"Provide a detailed description of the violation or compliance issue."}

              />



{/* <CustomAutoComplete
        fieldName="selectedBylaw"
        // requiredErrorMessage={BYLAW_REQUIRED_MESSAGE}
        isRequired={false}
        id="selectId"
        options={renderedBylaw}
        fieldLabel="Select Bylaw"
        fieldControl={control}
        error={errors}
        setValue={setValue}
        onChange={handleByLawChange}
        value={selectedBylawValue}
        // fieldRequiredIcon="*"
        invalidOption={BYLAW_REQUIRED_MESSAGE}
      /> */}

<div className="h-1/2">
{!bylawsUpdated ? (<Tooltip title="The bylaw in your association is not updated, so please contact the group admin." placement="bottom">
      <span>
        <CustomAutoComplete
          fieldName="selectedBylaw"
          id="selectId"
          options={renderedBylaw}
          fieldLabel="Select Bylaw"
          fieldControl={control}
          setValue={setValue}
          onChange={handleByLawChange}
          value={selectedBylawValue}
          isRequired={false}
          invalidOption={BYLAW_REQUIRED_MESSAGE}
          bylawNull="no bylaw"
          disabled={!bylawsUpdated}
          tooltipMessage={"Please select the relevant bylaw if applicable"}

        />
      </span>
    </Tooltip>) : <CustomAutoComplete
          fieldName="selectedBylaw"
          id="selectId"
          options={renderedBylaw}
          fieldLabel="Select Bylaw"
          fieldControl={control}
          setValue={setValue}
          onChange={handleByLawChange}
          value={selectedBylawValue}
          isRequired={false}
          invalidOption={BYLAW_REQUIRED_MESSAGE}
          bylawNull="no bylaw"
          tooltipMessage={"Please select the relevant bylaw if applicable"}

        />
      }
    </div>
    {byLawDescription ? (
    <Box>
      <Typography style={{ fontSize: "16px", color: "grey" }}>Bylaw Description</Typography>
      <CustomInputFieldExpand
        fieldName="bylawDescription"
        fieldId="bylawDescription"
        fieldType="text"
        fieldControl={control}
        fieldError={errors}
        disabled={true}
        multiline={true}
        value={byLawDescription}
      />
    </Box>
  ) : <p />}
{/* <CustomTooltipField
                fieldName="bylawDescription"
                fieldId="bylawDescription"
                fieldType="text"
                fieldLabel="Bylaw Description"
                fieldControl={control}
                fieldError={errors}
                disabled={true}
                value={byLawDescription}
                tooltipMessage={"text"}


              />
             
              {byLawDescription ? (
    <Box>
      <Typography style={{ fontSize: "16px", color: "grey" }}>Bylaw Description</Typography>
      <CustomInputFieldExpand
        fieldName="bylawDescription"
        fieldId="bylawDescription"
        fieldType="text"
        fieldControl={control}
        fieldError={errors}
        disabled={true}
        multiline={true}
        value={byLawDescription}
      />
    </Box>
  ) : <p />}

{/* <FormLabel>
              Attach Documents 
              <FileUploader 
              handleChange={handleFileChange}
              name="file"
              types={fileTypes}
              
            />
            {file ? <p>Selected File: {file.name}</p> : <p>No file selected</p>}
            {fileError && <p className="text-red-600 text-sm">{fileError}</p>}
            </FormLabel> */}

{/* <CustomFile file={file} handleFileChange={handleFileChange} token={token} fileError={fileError} /> */}
<CustomDatePicker
                fieldName="createdDate"
                requiredErrorMessage={CREATED_DATE_REQUIRED}
                label="Created Date"
                control={control}
                maxDate={maxDate}
                minDate={minDate}
                value={createdDate}
                fieldRequiredIcon="*"
                onChange={handleCreatedDateChange}
                tooltipMessage={"Select the created date for compliance."}
              />
<CustomFile file={file} handleFileChange={handleFileChange} token={token} fileError={fileError} tooltipMessage={"Upload supporting documents such as lease agreement, identity proof, etc."} required={true} isRequired={true}/>
             
            </section>
            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Create" />
              {isLoading && <CustomLoading />}

            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}

export default ArcComplianceAdd