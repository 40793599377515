import React, { useMemo, useState, useEffect } from "react";
import CustomHeading from "../../components/CustomHeading";
import CustomMaterialWithoutRoutes from "../../components/CustomMaterialWithoutRoutes";
import { useSelector } from "react-redux";
import { BASE_URL, BASE_URL_IMAGE, PAYMENT_HISTORY } from "../../utills/ApplicationRouting";
import axios from "axios";
import moment from "moment";
import { FileDownloadOutlined } from "@mui/icons-material";
import CustomMaterialTableActions from '../../components/CustomMaterialTableActions';
import { Button } from "@mui/material";
import CustomLoading from "../../components/CustomLoading";

function PaymentHistory() {
  const { token, homeId } = useSelector((state) => state.hybridhoa);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPaymentHistory = async () => {
      try {
        const response = await axios.get(`${BASE_URL}${PAYMENT_HISTORY}?homeId=${homeId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("history data" + JSON.stringify(response.data));
        if (response.status === 200) {
          const data = response.data;
          setPaymentHistory(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          throw new Error("Failed to fetch payment history");
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching payment history:", error);
      }
    };

    fetchPaymentHistory();
  }, [token, homeId]);

  const CustomCell = ({ cellData }) => {
    const [expanded, setExpanded] = useState(false);

    if (!cellData) return null;

    const content = expanded ? cellData : `${cellData.slice(0, 50)}`;

    return (
      <div style={{ wordBreak: 'break-word' }}>
        {content}
        {cellData.length > 50 && (
          <Button onClick={() => setExpanded(!expanded)}>
            {expanded ? "Show less" : "Show more"}
          </Button>
        )}
      </div>
    );
  };

  const handleViewClick = async (selectedRow) => {
    console.log("enetered billing");
    try {
      const storedToken = token;

      console.log(storedToken);
      console.log(selectedRow);

      const response = await axios.get(`${BASE_URL_IMAGE}/invoice/${selectedRow}`, {
        // headers: {
        //   Authorization: `Bearer ${storedToken}`,
        // },
        responseType: "blob",
      });
      // console.log("groupId",groupId);
      console.log(response.data);
      const fileBlob = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(fileBlob);
      window.open(fileURL);

    } catch (error) {
      setIsLoading(false);

      // Handle error
      console.error("Error fetching data:", error);
    }
    // console.log(selectedRow.original.invoice);
    // window.open(selectedRow.original.url)
  };

  const convertToPascalCase = (str) => {
    if (!str) return "";
    return str.replace(/_/g, " ").toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const paymentHistoryTable = useMemo(
    () => [
      {
        accessorKey: "serialNo",
        header: "#",
        size: 20,
        Cell: ({ row }) => row.index + 1,
        sortDescFirst: true,
      },
      {
        accessorKey: "title",
        header: "Title",
        Cell: ({ cell }) => <CustomCell cellData={cell.getValue()} />,
        // accessorFn: (row) => {
        //   const rowtitle = row.title?row.title:"";
        //   if (rowtitle && rowtitle.length > 28) {
        //     return rowtitle.slice(0, 28) + "...";
        //   }
        //   return rowtitle;
        // }
      },
      {
        accessorKey: "dueType",
        header: "Due Type",
        accessorFn: (row) => convertToPascalCase(row.dueType),
      },
      {
        accessorKey: "dueAmount",
        header: "Amount",
      },
      {
        accessorKey: "status",
        header: "Status",
        accessorFn: (row) => convertToPascalCase(row.status),
      },
      {
        accessorKey: "createdDateAndTime",
        header: "Paid Date",
        accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),
      },
      {
        accessorKey: "referenceNumber",
        header: "Reference Number",
        accessorFn: (row) => {
          try {
            const stripeResponseObj = JSON.parse(row.stripeResponse);
            return stripeResponseObj.referenceNumber || "";
          } catch (error) {
            console.error("Error parsing stripeResponse:", error);
            return "";
          }
        },
      },
      {
        accessorKey: "otherpaymentdetails",
        header: "Other Payment Details",
        accessorFn: (row) => {
          try {
            const stripeResponseObj = JSON.parse(row.stripeResponse);
            return stripeResponseObj.description || "";
          } catch (error) {
            console.error("Error parsing stripeResponse:", error);
            return "";
          }
        },
      },
      {
        accessorKey: "offlineinvoicenumber",
        header: "Offline Invoice Number",
        accessorFn: (row) => {
          try {
            const stripeResponseObj = JSON.parse(row.stripeResponse);
            return stripeResponseObj.invoiceNumber || "";
          } catch (error) {
            console.error("Error parsing stripeResponse:", error);
            return "";
          }
        },
      },
      // {
      //   accessorKey: "amount",
      //   header: "Amount",
      //   accessorFn: (row) => {
      //     try {
      //       const stripeResponseObj = JSON.parse(row.stripeResponse);
      //       return stripeResponseObj.amount;
      //     } catch (error) {
      //       console.error("Error parsing stripeResponse:", error);
      //       return "";
      //     }
      //   },
      // },
      {
        accessorKey: "paymentType",
        header: "Payment Type",
        accessorFn: (row) => convertToPascalCase(row.mode),
      },
      {
        accessorKey: "description",
        header: "Description",
        Cell: ({ cell }) => <CustomCell cellData={cell.getValue()} />,
        // accessorFn: (row) => {
        //   const description = row.description?row.description:"";
        //   if (description && description.length > 28) {
        //     return description.slice(0, 28) + "...";
        //   }
        //   return description;
        // }
      },

      {
        accessorKey: "invoicenumber",
        header: "Invoice Number",
        accessorFn: (row) => (row.invoicenumber),
      },
      {
        accessorKey: "review",
        header: "Review",
        Cell: ({ cell }) => <CustomCell cellData={cell.getValue()} />,
        accessorFn: (row) => {
          try {
            const stripeResponseObj = JSON.parse(row.stripeResponse);
            return stripeResponseObj.review || "";
          } catch (error) {
            console.error("Error parsing stripeResponse:", error);
            return "";
          }
        },
      },

    ],
    []
  );
  const rowActions = [
    {
      label: "Download Invoice",
      icon: <FileDownloadOutlined color="primary" />,
      click: (row) => handleViewClick(row.original.invoice),
    },
  ];

  return (
    <div>
      {isLoading && <CustomLoading />}
      <CustomHeading title="Payment History" />
      <CustomMaterialTableActions
        columns={paymentHistoryTable}
        data={paymentHistory}
        isLoading={isLoading}
        rowActions={rowActions}
      />
    </div>
  );
}

export default PaymentHistory;
