import React from 'react'
import ForgotPasswordForm from '../../components/forgotpassword/ForgotPasswordForm'



function Forgot() {
  return (
    <div className="bg-gray-50 pr-2 pl-2">
    <div className="container mx-auto flex flex-col lg:flex-row">

     <ForgotPasswordForm/>
      
    </div>
  </div>
  )
}

export default Forgot