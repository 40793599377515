import React from 'react'
import CustomHeading from '../../components/CustomHeading'
import CustomToastContainer from '../../components/CustomToastContainer'
import CustomButton from '../../components/CustomButton'
import CustomInputField from '../../components/CustomInputField'
import { CATEGORY_REQUIRED_MESSAGE, DESCRIPTION_REQUIRED_MESSAGE, MAX_LENGTH_FIVE_THOUSAND, MIN_LENGTH_VALIDATE_MESSAGE } from '../../utills/ApplicationConstants'
import CustomFile from '../../components/CustomFile'
import CustomLoading from '../../components/CustomLoading'
import { useState } from 'react'
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";



import {
  Autocomplete,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,

  FormLabel,
  TextField,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";
import { request } from '../../services/AxiosConfig'
import { INVOICE } from '../../utills/ApplicationRouting'
import { useEffect } from 'react'
import CustomViewWithTooltip from '../../components/CustomViewWithTooltip'
import CustomTooltipField from '../../components/CustomTooltipField'
import { InfoOutlined } from '@mui/icons-material'
import CustomFileInvoiceAdd from '../../components/CustomFileInvoiceAdd'


function InvoiceUpload() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [fileError, setFileError] = useState("");
  const { token, homeId,roles } = useSelector(state => state.hybridhoa);
  const [selectedCategory, setSelectedCategory] = useState("");

  const [file, setFile] = useState([]);
  const fileTypes = [
    "JPG",
    "PNG",
    "JPEG",
    "PDF",
    "DOC",
    "XLS",
    "XLSX",
    "TIF",
    "TIFF",
    "DOCX",
  ];

  const handleViewClick = () => {
   
    navigate(-1);
  };

  const UploadViewClick = () => {
   
    navigate(-1);
  };
    const {
      handleSubmit,
      control,
      reset,
      setError,
      clearErrors,
      formState: { errors },
    } = useForm();
  
    


  const handleFileChange = (newFiles) => {
    setFile(newFiles);
    };

   



    const handleCategoryChange = (e) => {
      setSelectedCategory(e.target.value);
      clearErrors("category");
    };


    useEffect(() => {
      // Reset fileError when a valid file is selected
      if(file.length !== 0){
      setFileError("")
      }
      }, [file]);
    const handleClick = () => {
      navigate(-1);
    };
  
  const onSubmit = (data) => {
    if(file.length===0){
      setFileError("Attachment is Required")
      return;
      };
   
    const totalFileSize = file.reduce((acc, file) => acc + file.size, 0);
    const maxSizeInMB = 10;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    
    if (totalFileSize > maxSizeInBytes) {
    // setFileError(`Total file size should not exceed ${maxSizeInMB}MB.`);
    return;
    }
    
    // Check file types
    // const allowedFileTypes = ["image/jpg", "image/png", "image/jpeg", "application/pdf"];
   
    const allowedFileTypes = [ "image/jpg", "image/jpeg", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "image/tiff" ];
    const invalidFiles = file.filter(file => !allowedFileTypes.includes(file.type));
    if (invalidFiles.length > 0) {
    // setFileError("Some files have invalid file types. Allowed types are JPG, PNG, JPEG, PDF.");
    return;
    }
const formData = new FormData();
    formData.append("description", data.description);
    file.forEach((file) => {
      formData.append('files', file);
      });
    formData.append("category", selectedCategory);
    formData.append("role",roles);

    console.log("description", data.description);
    console.log("category", selectedCategory);
    console.log("file:", file);
    if (!selectedCategory) {
      setError("category", {
        type: "manual",
        message: CATEGORY_REQUIRED_MESSAGE,
      });
      return;
    }
    setIsLoading(true)
    // return;
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    request({ url: INVOICE, method: "post", data: formData, headers })
    // setIsLoading(false)
      .then((res) => {
        
        setIsLoading(false)

        console.log(res);
        if (res.status == 200) {
          setIsLoading(false)
          console.log("Success");
           setTimeout(() => {
      navigate(-1);
    }, 4000);
        
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const renderTooltip = (message) => (
    <Tooltip title={<Typography sx={{ fontSize: "14px" }}>{message}</Typography>} placement="right">
      <IconButton>
        <InfoOutlined />
      </IconButton>
    </Tooltip>
  );



  return (
    <div> 
    <div class="flex flex-col md:flex-row">
      <CustomHeading title="Upload" />
      <CustomToastContainer />
      <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
        <CustomButton
          fieldType="button"
          buttonName="Back"
          click={handleViewClick}
        />
      </div>
    </div>
    <Card>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-4 md:space-y-6 "
      >
        <CardContent>
          <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
          <CustomTooltipField
              fieldName="description"
              requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
              fieldId="description"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={5000}
              maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
              fieldLabel="Description"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
              tooltipMessage={"Please enter a detailed description"}
            />


            

            <div className="flex items-center md:w-1/2 ">
<div className="w-full ">

            <Controller
              name="category"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl variant="standard" fullWidth error={fieldState.invalid}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Please Select Category <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    label="Please Select Category"
                  >
                    <MenuItem value="Purchases">Purchases</MenuItem>
                    <MenuItem value="Sales">Sales</MenuItem>
                    <MenuItem value="Suppliers">Suppliers</MenuItem>
                    <MenuItem value="Expenses">Expenses</MenuItem>
                  </Select>

                  <FormHelperText style={{ color: "#D32F2F" }}>
                    {fieldState.invalid ? fieldState.error?.message : ""}
                  </FormHelperText>
                </FormControl>
              )}
            />
             </div>
  <div>
    {renderTooltip("Please select a category from the list")}
  </div>
</div>
            

            {/* <FormLabel>
              Upload <span style={{ color: "red" }}>*</span>
              <FileUploader
                handleChange={handleFileChange}
                name="file"
                types={fileTypes}
                required
              />
              {file ? (
                <p>Selected File: {file.name}</p>
              ) : (
                <p>No file selected</p>
              )}
            </FormLabel> */}
            <CustomFileInvoiceAdd file={file} handleFileChange={handleFileChange} token={token} fileError={fileError} required isRequired={true}/>
          </section>
            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Create" />
              {isLoading && <CustomLoading />}
            

            </div>
          </CardContent>
        </form>
      </Card>
      </div>
  )
}

export default InvoiceUpload