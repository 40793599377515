import {
  Box,
  MenuItem,
  Typography,
  Checkbox,
  DialogContent,
  DialogActions,
  Tooltip,
} from "@mui/material";
import { useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import CustomInputField from "./CustomInputField";
import { useForm } from "react-hook-form";
import {
  BASE_URL,
  CARD_DELETE,
  CARD_DETAILS,
  GET_CARD_DETAILS,
  GROUP_CONFIG,
  PAYMENT, USER_DUE
} from "../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import CustomButton from "./CustomButton";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import CustomModel from "./CustomModel";
import { Elements } from "@stripe/react-stripe-js";
import CustomCardElement from "./CustomCardElement";
import { request } from "../services/AxiosConfig";
import CustomCardEditElement from "./CustomCardEditElement";
import { loadStripe } from "@stripe/stripe-js";
import CustomToastContainer from "../components/CustomToastContainer";
import CustomCheckBox from "./CustomCheckBox";
import { TWO_DECIMAL, TWO_DIGITS_TWO_DECIMAL } from "../utills/ApplicationRegex";
import CustomLoading from './CustomLoading';
import CustomButtonForPayment from "./CustomButtonForPayment";
import { useDispatch } from "react-redux";
import { setSelectedTotalAmount } from "../redux/Hybridhoa";

var stripePromise = null;
var options = null;

const CustomMaterialTables = (props) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const [totalWaiveAmount, setTotalWaiveAmount] = useState(0);
  const [groupConfigurationDetails, setGroupConfigurationDetails] = useState(
    []
  );
  const [showEditCard, setShowEditCard] = useState(false);
  const [minimumAmount, setMinimumAmount] = useState(0);
  const [calAmount, setCalAmount] = useState(0);
  const [amounts, setAmounts] = useState(totalWaiveAmount);
  const { token, homeId, selectedTotalAmount, remainingDueAmount } = useSelector((state) => state.hybridhoa);
  const [showCard, setShowCard] = useState(false);
  const [publishKey, setPublishKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [cardBrand, setCardBrand] = useState("");
  const [lastDigits, setLastDigits] = useState("");
  const [expMonth, setExpMonth] = useState("");
  const [expYr, setExpYr] = useState("");
  const [pmId, setPmId] = useState("");
  const [checkDues, setCheckDues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [addCardOpen, setAddCardOpen] = useState(false);
  const [taxCalculation, setTaxCalculation] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [active, setIsActive] = useState(false);
  const [processAmount, setProcessAmount] = useState("");
  const [totalDueAmount, setTotalDueAmount] = useState(0);
  const [dueDetails, setDueDetails] = useState(0);
  const [tooltipMessage, setTooltipMessage] = useState("");
  // const [buttonShow,setButtonShow] = useState(true);
  const actions = props.rowActions;
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [enabledCheckboxes, setEnabledCheckboxes] = useState([]);
  const dispatch = useDispatch();


  const handleCloseCardEdit = () => {
    setShowEditCard(false);
  };
  const handleCloseCardAdd = () => {
    setShowCard(false);
  };

  const handleAddClickOpen = () => {
    request({
      url: `${CARD_DETAILS}`,
      method: "get",
    })
      .then((res) => {
        if (res.status == 200) {
          const data = res.data;
          console.log(data);
          setSecretKey(data.clientSecret);
          setPublishKey(data.publishKey);

          stripePromise = loadStripe(data.publishKey);

          options = {
            // passing the client secret obtained from the server
            clientSecret: data.clientSecret,
          };

          setShowCard(true);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.res) {
        }
      });
  };

  const getPaymentDetails = async () => {
    try {
      const storedToken = token;

      // console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GET_CARD_DETAILS}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;

        const cardDetails = data.card;
        const cardBrandDetails = cardDetails.brand;
        const cardFourDigit = cardDetails.last4;
        const cardMonth = cardDetails.exp_month;
        const cardYear = cardDetails.exp_year;
        const cardPmId = data.id;
        console.log("card details data", data.id);
        setCardBrand(cardBrandDetails);
        setLastDigits(cardFourDigit);
        setExpMonth(cardMonth);
        setExpYr(cardYear);
        setPmId(cardPmId);
        setIsLoading(false)
      } else {
        setIsLoading(false)
        throw new Error("Failed to fetch data");

      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
      setTimeout(() => {
        setIsLoading(false)
      }, 3000);

    }
  };
  useEffect(() => {
    getPaymentDetails();
  }, []);

  const handleUpdateClickOpen = () => {
    setOpenEdit(true);
    request({
      url: `${CARD_DETAILS}`,
      method: "get",
    })
      .then((res) => {
        if (res.status == 200) {
          const data = res.data;
          console.log(data);
          setSecretKey(data.clientSecret);

          stripePromise = loadStripe(data.publishKey);

          options = {
            // passing the client secret obtained from the server
            clientSecret: data.clientSecret,
          };

          setShowEditCard(true);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.res) {
        }
      });
  };
  useEffect(
    (row) => {
      // Initialize enabledCheckboxes array with initial state
      const initialEnabledCheckboxes = props.data.map(
        (_, row, index) => row.index === 0
      );
      setEnabledCheckboxes(initialEnabledCheckboxes);

      // Ensure the first row is selected initially
      if (props.data.length > 0) {
        handleRowSelection(props.data[0]);
      }
    },
    [props.data]
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    reset,
    clearErrors
  } = useForm();

  const getGroupConfigurationDetails = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GROUP_CONFIG}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        setGroupConfigurationDetails(data);
        const item = data.find(
          (item) => item.topic === "Minimum Amount In Percentage"
        );

        if (item) {
          setMinimumAmount(item.content);

          console.log("min percentage", item.content);
          console.log("TestCase", (totalDueAmount / 100) * item.content);
        }
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getGroupConfigurationDetails();
  }, [minimumAmount]);

  const handleAmountChange = (event) => {
    const newValue = event.target.value;
    setAmounts(newValue);

    const calculatedAmount = (selectedTotalAmount / 100) * minimumAmount;
    setCalAmount(calculatedAmount);

    console.log("calculated value:", calculatedAmount);
  };

  const handleRowSelection = (row) => {

    clearErrors("amount")
    const isFirstRow = row.index === 0;
    const selectedIndex = selectedRows.findIndex(
      (selectedRow) => selectedRow.id === row.id
    );
    console.log("selected index", selectedIndex);

    let newSelected = [...selectedRows];
    let newEnabledCheckboxes = [...enabledCheckboxes];
    console.log("selected new index", newEnabledCheckboxes.length);
    // console.log("checking for amount",newSelected);

    if (selectedIndex === -1) {
      newSelected.push(row);
      console.log("Selected row amount:", row);


    } else {
      newSelected.splice(selectedIndex, 1);
      console.log("Deselected row amount:", row);

    }

    if (isFirstRow && selectedIndex !== -1) {
      newEnabledCheckboxes = props.data.map(() => false);
      newSelected = [];
      setAmounts(0);
      console.log("Deselected first row, resetting all selections.");
    } else {
      // New functionality to deselect subsequent rows
      if (selectedIndex !== -1) {
        // If a row other than the first row is deselected
        newSelected = newSelected.filter((selectedRow) => selectedRow.index <= row.index);
        newEnabledCheckboxes = newEnabledCheckboxes.map((enabled, index) => index <= row.index);

      }

      let updatedDues = newSelected.map((selectedRow) => selectedRow.original);
      // const amountCheck = newSelected.map((selectedRow) => selectedRow.totalAmount);

      // console.log("checking amount code by aishu",amountCheck);
      // dispatch(setSelectedTotalAmount(amountCheck));
      updatedDues = updatedDues.filter(value => value !== undefined);
      console.log("updated dues", updatedDues);
      console.log("lenght of update dues", updatedDues.lenght);
      console.log(selectedIndex);
      setCheckDues(updatedDues);

      const totalAmount = newSelected.reduce((total, selectedRow) => {
        if (selectedRow.original && selectedRow.original.dueAmount) {
          return total + parseFloat(selectedRow.original.dueAmount);
        }
        return total;
      }, 0);
      // const calculateTotalDueAmount = selectedRow.original.totalAmount
      // let calculateTotalDueAmount = newSelected.map((selectedRow) => selectedRow.original.totalAmount);
      // console.log("selectedRow,",calculateTotalDueAmount);

      // setTotalDueAmount(calculateTotalDueAmount);

      setAmounts(totalAmount);
      setTotalWaiveAmount(totalAmount);
      console.log("Total selected amount:", totalAmount);

    }

    newEnabledCheckboxes = props.data.map((_, index) => {
      if (index === 0) {
        return true;
      }
      return newSelected.some((selectedRow) => selectedRow.index === index - 1);
    });

    setEnabledCheckboxes(newEnabledCheckboxes);
    setSelectedRows(newSelected);

  };


  const handleAddCardClose = () => {
    setAddCardOpen(false);
  };

  const handleAddCardIconClose = () => {
    setAddCardOpen(false);
  };

  const addCardModelAction = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Close"
        click={handleAddCardIconClose}
      />
    </>
  );

  const addCardContent = (
    <>
      <div className="flex item-center justify-center mb-5 mt-5">
        <DialogContent dividers>
          <Typography gutterBottom>Card not added. Please add your card information in the Payment Method section under Payments.</Typography>
        </DialogContent>
      </div>
      <DialogActions>{addCardModelAction}</DialogActions>
    </>
  );



  const onSubmit = () => {
    console.log(enabledCheckboxes);

    const doubleValue = parseFloat(amounts);

    const multipleProcessFee = (doubleValue + 0.3) / 0.971;
    const wholeNumber = multipleProcessFee.toFixed(2);
    setProcessAmount(wholeNumber);
    const reduceRemainingAmount = totalWaiveAmount - amounts

    const roundedCalAmount = parseFloat(calAmount.toFixed(2));
    const roundedAmounts = parseFloat(doubleValue.toFixed(2));

    // Only validate if the checkbox is not active
    if (amounts === 0 || amounts === "") {
      setError("amount", {
        type: "manual",
        message: "Please Provide amount",
      });
      return;
    }


    if (amounts < roundedCalAmount) {
      setError("amount", {
        type: "manual",
        message: `Please Pay Minimum Amount ${roundedCalAmount}`,
      });
      console.log("Validation failed:", errors);
      setTaxCalculation(false);
      return;
    }
    if (amounts > totalWaiveAmount) {
      setError("amount", {
        type: "manual",
        message: `please provide maximum amount of ${totalWaiveAmount}`,
      });
      console.log("Validation failed:", errors);
      setTaxCalculation(false);
      return;
    }

    console.log("checking calAmount value from paymentTable.js", calAmount);
    console.log("checking reduceRemainingAmount value from paymentTable.js", reduceRemainingAmount);
    if (checkDues.length <= 1) {
      console.log("inside lenght");

      // console.log(calAmount +">="+ reduceRemainingAmount);

      if (reduceRemainingAmount != "0") {
        if (calAmount >= reduceRemainingAmount) {

          setError("amount", {
            type: "manual",
            message: `Your remaining balance is lower than minimum value please try with full payment`,
          });
          console.log("Validation failed:", errors);
          setTaxCalculation(false);
          return;
        }
      }

    }




    // console.log("checking totalWaivedAMount value from paymentTable.js",totalWaiveAmount);
    // console.log("checking totalAmount value from paymentTable.js",selectedTotalAmount);
    // console.log("checking amount value from paymentTable.js",amounts);
    // console.log("checking calAmount value from paymentTable.js",calAmount);
    // console.log("checking reduceRemainingAmount value from paymentTable.js",reduceRemainingAmount);
    setTaxCalculation(true);
  };



  const onSubmitDelete = () => {

    // return;
    let isMinimum = false;
    let slicedDues = checkDues;

    // if (checkDues[0] === undefined) {
    //   console.log("checkDues[0] is undefined");
    //   slicedDues = checkDues.slice(1);
    // }

    console.log(checkDues[0]);
    console.log(checkDues[0]);
    // const slicedDues = checkDues.slice(1);
    const doubleValue = parseFloat(amounts);
    const multipleProcessFee = (doubleValue + 0.3) / 0.971
    const wholeNumber = multipleProcessFee.toFixed(2);
    setProcessAmount(wholeNumber);
    console.log("multipleProcessFee", wholeNumber);
    console.log("multipleprocess amount", typeof (doubleValue));
    console.log("slicedDues", slicedDues.length);
    let splitSelectRow = selectedRows;
    // let selectedRowValidate = splitSelectRow;
    if (slicedDues.length == 1) {
      console.log("selected rows", selectedRows.length);
      if (selectedRows.length == 2) {
        splitSelectRow = selectedRows.slice(1);
        // selectedRowValidate = splitSelectRow[0].original.dueAmount;
        console.log("inside if splitselectRow", splitSelectRow);
        // console.log("inside if selectedRowValidate",selectedRowValidate);
      } else {
        splitSelectRow = selectedRows;
        // selectedRowValidate = splitSelectRow[0].original.dueAmount;
        console.log("inside else splitselectRow", splitSelectRow);
        // console.log("inside else selectedRowValidate",selectedRowValidate);
      }
      // const splitSelectRow = selectedRows.slice(1);
      // const splitSelectRow = selectedRows;
      console.log(splitSelectRow);

      console.log(slicedDues);
      const selectedRowValidate = splitSelectRow[0].original.dueAmount;
      console.log("selectedRowValidate", selectedRowValidate);
      console.log("due amount", splitSelectRow[0]);
      isMinimum = selectedRowValidate == amounts ? false : true;
      console.log("selectedRowValidate", selectedRowValidate);
      console.log("isMinimum", isMinimum);
    } else {
      isMinimum = false;
      console.log("isMinimum else", isMinimum);
    }
    // return;
    const postData = {
      amount: parseFloat(amounts).toFixed(2),
      amountWithFees: wholeNumber,
      isMinimum: isMinimum,
      homeOwnerDuesList: slicedDues,
      homeId: homeId,

    };

    console.log("postData", postData);
    // return;

    console.log("pmId", pmId);
    setIsLoading(true);

    request({ url: PAYMENT, method: "post", data: postData })
      .then((res) => {

        console.log(res);
        if (res.status == 200) {


          setIsLoading(false);
          window.location.reload();


          //  setTimeout(()=>{
          //   navigate(-1)
          //  },3000)
        }
        else {
          setIsActive(false);
          setTimeout(() => {

            setIsLoading(false);
          }, 3000);


        }
      })
      .catch((error) => {

        setIsLoading(false)


      });

    // return;
  };


  const handleAddCardOpen = () => {
    handleDeleteClose();
    setAddCardOpen(true)


  }
  const handleDeleteClose = () => {
    setTaxCalculation(false);
    setIsActive(false);

  };
  const handleDeleteOkClose = () => {
    setTaxCalculation(false);


  };
  const handleCheckBoxChange = (e) => {
    setIsActive(e.target.checked);

  };
  const getDueDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${USER_DUE}?homeId=${homeId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;

        setDueDetails(data);
        if (data == '') {
          // console.log("inside useeffect null due details");
          
                setTooltipMessage("There are no outstanding dues")
              }
              else{
                setTooltipMessage("No dues have been selected for processing...")

              }
        console.log("data", data);
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDueDetails();
  }, [homeId]);


//   useEffect(() => {

//     if (dueDetails!="" && amounts=== 0  ) {
// console.log("inside useeffect null amount");

//       setTooltipMessage("There are no dues have been selected for processing...")

//     } else if (dueDetails === "") {
// console.log("inside useeffect null due details");

//       setTooltipMessage("There are no current dues")
//     }
//   }, []);
  
  const okCancelButtonActions = (
    <>
      {/* {active ? (
    <CustomButton
    fieldType="submit"
    buttonName="Ok"
    click={handleDeleteClose}
    />
    ) :*/}

      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleDeleteClose}
      />
      {
        active ? (
          <CustomButton
            fieldType="submit"
            buttonName="Ok"
            click={handleDeleteOkClose}
          />
        )
          :
          (
            <>
              <Tooltip arrow title="Agree the term to enable the ok button">
                <span>
                  <CustomButtonForPayment
                    fieldType="submit"
                    buttonName="Ok"
                    disabled={true}
                    click={handleDeleteClose}
                  />
                </span>
              </Tooltip>
            </>
          )}
      {/* {!pmId && active ? (
        <CustomButton
          fieldType="submit"
          buttonName="Ok"
          click={handleAddCardOpen}
        />
      ) :
        pmId && active ? (
          <CustomButton
            fieldType="submit"
            buttonName="Ok"
            click={handleDeleteOkClose}
          />
        )
          :
          (
            <>
              <Tooltip arrow title="Agree the term to enable the ok button">
                <span>
                  <CustomButtonForPayment
                    fieldType="submit"
                    buttonName="Ok"
                    disabled={true}
                    click={handleDeleteClose}
                  />
                </span>
              </Tooltip>
            </>
          )} */}


    </>
  );

  const deleteModel = (
    <>

      <form
        onSubmit={handleSubmit(onSubmitDelete)}
        className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography>
            {" "}
            you will be charged {processAmount}  from your account, for payment processing fees {"(2.9%+0.30 cents)"}

            <CustomCheckBox
              fieldName="isactive"
              control={control}
              defaultValue={false}
              labelName="I Agree"
              onChange={handleCheckBoxChange}
            />
          </Typography>
        </DialogContent>
        <DialogActions>{okCancelButtonActions}</DialogActions>
      </form>
    </>

  );

  const addCardModel = (
    <>
      <DialogContent dividers>
        <Elements stripe={stripePromise} options={options}>
          <CustomCardElement />
        </Elements>
      </DialogContent>
    </>
  );

  const editCardModel = (
    <>
      <DialogContent dividers>
        <Elements stripe={stripePromise} options={options}>
          <CustomCardEditElement />
        </Elements>
      </DialogContent>
    </>
  );
  return (
    <div class="grid md:grid-cols-1 xl:grid-cols-1">
      <CustomModel
        title="Warning"
        content={addCardContent}
        action={addCardModelAction}
        openStatus={addCardOpen}
        closeStatus={handleAddCardClose}
        iconCloseStatus={handleAddCardIconClose}
        reset={reset}
      />
      <section class="grid md:grid-cols-3 xl:grid-cols-3 ml-5 mt-4">
        <div>

          <br />
          <br />
          <form onSubmit={handleSubmit(onSubmit)}>
            <CustomInputField
              fieldName="amount"
              fieldPattern={TWO_DECIMAL}
              patternErrorMessage={"Please provide vaild amount. i.e ##.##"}
              // requiredErrorMessage={"required field"}
              // minLength={2}
              // minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              // maxLength={5000}
              // maxLengthErrorMessage={"amount is lesser than waived amount"}
              fieldId="amount"
              fieldType="text"
              fieldLabel="Amount"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"

              value={typeof amounts === 'number' ? amounts.toFixed(2) : amounts}
              onInput={handleAmountChange}
              disabled={checkDues.length >= 2 || amounts === 0}
            />

            {amounts === 0 ?
              <Tooltip arrow title={tooltipMessage}>
                <span>
                  <CustomButtonForPayment
                    fieldType="submit"
                    buttonName="Pay"
                    disabled={amounts === 0}
                  />
                </span>

              </Tooltip>
              :

              <span>
                <CustomButton
                  fieldType="submit"
                  buttonName="Pay"

                />
              </span>


            }



            {/* {!checkDues.length >= 1 && amounts === 0  ?
              <Tooltip arrow title="Gopal">
                <span>
                  <CustomButtonForPayment
                    fieldType="submit"
                    buttonName="Pay"
                    disabled={amounts === 0}
                  />
                </span>

              </Tooltip>
              
              :
               amounts !== 0 ? 
               <span>
               <CustomButton
                 fieldType="submit"
                 buttonName="Pay"

               />
             </span>
            
            :
             
            <Tooltip arrow title="Aishu">
            <span>
              <CustomButtonForPayment
                fieldType="submit"
                buttonName="Pay"
                disabled={amounts === 0}
              />
            </span>

          </Tooltip>

            } */}

          </form>
          {isLoading && (
            <CustomLoading />
          )}
        </div>



        <CustomModel
          title="Add Card"
          content={addCardModel}
          openStatus={showCard}
          closeStatus={handleCloseCardAdd}
          iconCloseStatus={handleCloseCardAdd}
          reset={reset}
        />
        <CustomModel
          title="Update Card"
          content={editCardModel}
          openStatus={showEditCard}
          closeStatus={handleCloseCardEdit}
          iconCloseStatus={handleCloseCardEdit}
          reset={reset}
        />
        <CustomModel
          title="Payment"
          submit={onSubmitDelete}
          content={deleteModel}
          action={okCancelButtonActions}
          openStatus={taxCalculation}
          closeStatus={handleDeleteClose}
          iconCloseStatus={handleDeleteClose}
          reset={reset}
          modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
        />
      </section >
      <br />
      <MaterialReactTable
        columns={[
          // Add a checkbox column for row selection
          {
            Header: "",
            id: "selection",
            Cell: ({ row }) => (
              <Checkbox
                checked={
                  selectedRows.findIndex(
                    (selectedRow) => selectedRow.id === row.id
                  ) !== -1
                }
                onChange={() => handleRowSelection(row)}
                // Disable checkbox based on the enabled/disabled state
                disabled={!enabledCheckboxes[row.index]}
              />
            ),
            disableResizing: true,
            minWidth: 40,
          },
          ...props.columns,
        ]}
        data={props.data}
        enableColumnOrdering
        enableRowActions={actions ? true : false}
        positionActionsColumn="last"
        enableSorting={false}
        onPaginationChange={setPagination}
        state={{
          // isLoading: props.isLoading,
          pagination,
        }}
        muiTableHeadCellProps={{
          sx: {
            fontWeight: "semi-bold",
            fontSize: 14,
            fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            fontSize: 14,
            fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,
          },
        }}
        enableColumnPinning
        renderRowActionMenuItems={({ row }) => {
          const renderActions = Array.isArray(actions)
            ? actions.map((i) => (
              <MenuItem key={i.label} onClick={() => i.click(row)}>
                {i.icon}
                <Typography sx={{ pl: 1 }}> {i.label}</Typography>
              </MenuItem>
            ))
            : actions(row).map((i) => (
              <MenuItem key={i.label} onClick={i.click}>
                {i.icon}
                <Typography sx={{ pl: 1 }}>{i.label}</Typography>
              </MenuItem>
            ));

          return renderActions;
        }}
        renderTopToolbarCustomActions={() => (
          <>
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              {props.actionButtons}
            </Box>
          </>
        )}
      />

      <br />


      <b>Note:-</b> <p>To see all your paid dues, please refer to the Payment History page. It provides an overview of all your transactions.</p>




      <CustomToastContainer />
    </div >
  );
};

export default CustomMaterialTables;
