import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BASE_URL, CONTACT_VERIFY } from '../../utills/ApplicationRouting';
import { useParams } from 'react-router-dom';
import ColoredCircle from '../../components/coloredCircle'; // Make sure the import path is correct
import { useNavigate } from 'react-router-dom';
function ContactVerified() {
  const { tokenId } = useParams();
  const [status, setStatus] = useState('');
  let navigate = useNavigate();
  const getPhoneVerification = async () => {
    try {
      const response = await axios.get(`${BASE_URL}${CONTACT_VERIFY}${tokenId}`, {});
      console.log(response.data);

      if (response.status === 200) {
        const statuss = response.status;
        setStatus(statuss);
        setTimeout(()=>{
          navigate('/')
},5000)
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getPhoneVerification();
  }, [tokenId]);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div>
        <ColoredCircle isSuccess={status === 200} />
      </div>
      <div className="mt-4">
        {status === 200 ? (
          <p className='text-xl font-bold'>Your phone number is verified successfully</p>
        ) : (
          <p className='text-xl font-bold'>Your phone number verification failed. Please try again.</p>
        )}
      </div>
    </div>
  );
}

export default ContactVerified;
