import React, { useEffect, useMemo, useState } from "react";
import CustomHeading from "../../components/CustomHeading";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import { useNavigate } from "react-router-dom";
import { DeleteOutline, EditOutlined, ListAlt } from "@mui/icons-material";
import CustomButton from "../../components/CustomButton";
import CustomModel from "../../components/CustomModel";
import { DialogActions, DialogContent, Typography } from "@mui/material";
import CustomInputField from "../../components/CustomInputField";
import {
  FOOTER_INVALID_MESSAGE,
  FOOTER_REQUIRED_MESSAGE,
  HEADER_INVALID_MESAGE,
  HEADER_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_THOUSAND,
  MAX_LENGTH_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
} from "../../utills/ApplicationConstants";
import { Controller, useForm } from "react-hook-form";
import { ALPHANUMBERIC_ALL_SPECIAL_CHARACTERS } from "../../utills/ApplicationRegex";
import CustomCheckBox from "../../components/CustomCheckBox";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL, TEMPLATE } from "../../utills/ApplicationRouting";
import { request } from "../../services/AxiosConfig";
import CustomLoading from "../../components/CustomLoading";
import CustomToastContainer from "../../components/CustomToastContainer";

function TemplateList() {
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { token } = useSelector((state) => state.hybridhoa);
  const [templateDetails, setTemplateDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [active, setIsActive] = useState(false);
  const [templateId, setTemplateId] = useState("");
  const [headerData, setHeaderData] = useState("");
  const [footerData, setFooterData] = useState("");
  const [editActive, setEditActive] = useState([]);
  const [editActiveChk, setEditActiveChk] = useState(false);

  const handleAddClickOpen = () => {
    // setOpen(true);
    navigate("/templateadd");
  };
  const handleAddClose = (status) => {
    if (status === 200) {
      setOpen(false);
    }
  };

  const handleIconClose = () => {
    setOpen(false);
  };

  const handleEditClickOpen = (selectedRow) => {
    // setEditOpen(true);
    // console.log(selectedRow);
    navigate(`/templateedit/${selectedRow}`) 
    console.log(selectedRow); 
        setTemplateId(selectedRow);
  // getRenterDetailsById();
  };
  const handleEditClose = (status) => {
    if (status === 200) {
      setEditOpen(false);
    }
  };
  const handleEditIconClose = () => {
    setEditOpen(false);
  };

  const navigate = useNavigate();

  const handleViewClick = () => {
    navigate("/raisedbyarcview");
  };

  const handleDeleteClickOpen = (selectedRow) => {
    setDeleteOpen(true);
    console.log(selectedRow);
    setTemplateId(selectedRow);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    shouldUnregister: true, // Add this option
  });


  const handleCheckBoxChange = (e) => {
    setIsActive(e.target.checked);
  };
  const handleEditCheckBoxChange = (e) => {
    console.log(e.target.checked);
    setEditActive(e.target.checked);
  };
  const headerChange = (e) => {
    setHeaderData(e.target.value);
  };
  const footerChange = (e) => {
    setFooterData(e.target.value);
  };

  const getTemplateDetails = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${TEMPLATE}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setTemplateDetails(data);
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getTemplateDetails();
  }, []);

  const onSubmit = (data) => {
    setIsLoading(true);
    const postData = {
      header: data.header,
      footer: data.footer,
      isActive: active,
    };
    request({ url: TEMPLATE, method: "post", data: postData })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");
          getTemplateDetails();
          getRenterDetailsById();
          setIsLoading(false);
          handleAddClose(res.status);
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const getRenterDetailsById = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${TEMPLATE}/${templateId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        console.log(data);
        setHeaderData(data.header);
        setFooterData(data.footer);
        setEditActive(data.isActive);
        console.log(data.isActive);
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getRenterDetailsById();
  }, [templateId]);

  const submitEdit = () => {
    setIsLoading(true);
    const putData = {
      header: headerData.trim(),
      footer: footerData.trim(),
      isActive: editActive,
    };

    if (headerData.trim() == "" || headerData.trim() == null) {
      setError("editheader", {
        type: "manual",
        message: HEADER_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (footerData.trim() == "" || footerData.trim() == null) {
      setError("editFooter", {
        type: "manual",
        message: FOOTER_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    request({ url: `${TEMPLATE}/${templateId}`, method: "put", data: putData })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          setIsLoading(false);
          getTemplateDetails();
          handleEditClose(res.status);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const onSubmitDelete = () => {
    // return;
    request({ url: `${TEMPLATE}/${templateId}`, method: "delete" })
      .then((res) => {
        console.log(res);
        getTemplateDetails();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const raisedByArcTable = useMemo((rowData) => [
    {
      accessorKey: "serialNo",
      header: "#",
      size: 20,
      Cell: ({ row }) => row.index + 1,
    },
    {
      accessorKey: "header",
      header: "Header",
    },
    {
      accessorKey: "footer",
      header: "Footer",
    },
    {
      accessorKey: "isActive",
      accessorFn: (row) => (row && row.isActive ? "Yes" : "No"),
      header: "Is Active",
    },
  ]);

  const rowActions = [
    //  {
    //    label: "View",
    //    icon: <ListAlt color="primary" />,
    //    click: handleViewClick,
    //  },
    {
      label: "Edit",
      icon: <EditOutlined color="primary" />,
      click: (row) => handleEditClickOpen(row.original.reportTemplateId),
    },
    {
      label: "Delete",
      icon: <DeleteOutline color="error" />,
      click: (row) => handleDeleteClickOpen(row.original.reportTemplateId),
    },
  ];

  const tableActionButtons = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Add"
        click={handleAddClickOpen}
      />
    </>
  );

  const addActionButton = (
    <CustomButton fieldType="submit" buttonName="Add" click={handleAddClose}>
      {isLoading && <CustomLoading />}
    </CustomButton>
  );
  const addModel = (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography gutterBottom>
            <CustomInputField
              fieldName="header"
              requiredErrorMessage={HEADER_REQUIRED_MESSAGE}
              fieldId="headr"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldLabel="Header"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
            />

            <CustomInputField
              fieldName="footer"
              requiredErrorMessage={FOOTER_REQUIRED_MESSAGE}
              fieldId="footer"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldLabel="Footer"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
            />

            {/* <CustomCheckBox
              fieldName="isactive"
              control={control}
              defaultValue={false}
              labelName="Is Active"
              onChange={handleCheckBoxChange}
            /> */}
          </Typography>
        </DialogContent>
        <DialogActions>{addActionButton}</DialogActions>
      </form>
    </>
  );

  const editActionButton = (
    <CustomButton
      fieldType="submit"
      buttonName="Update"
      click={handleEditClose}
    />
  );
  const editModel = (
    <>
      <form
        onSubmit={handleSubmit(submitEdit)}
        className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography gutterBottom>
            <CustomInputField
              fieldName="editheader"
              fieldId="editheader"          
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldLabel="Header"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
              value={headerData}
              onInput={headerChange}
            />

            <CustomInputField
              fieldName="editFooter"
              fieldId="editFooter"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldLabel="Footer"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
              value={footerData}
              onInput={footerChange}
            />

            <CustomCheckBox
              fieldName="editActive"
              control={control}
              checked={editActive}
              labelName="Is Active"
              onChange={handleEditCheckBoxChange}
            />
          </Typography>
        </DialogContent>
        <DialogActions>{editActionButton}</DialogActions>
      </form>
    </>
  );

  const okCancelButtonActions = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleDeleteClose}
      />
      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleDeleteClose}
      />
    </>
  );

  const deleteModel = (
    <form
      onSubmit={handleSubmit(onSubmitDelete)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography>
          {" "}
          Are you sure you want to delete this template ?
        </Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );

  return (
    <div>
      <CustomHeading title="Template" />
      <CustomToastContainer />

      <CustomMaterialTable
        columns={raisedByArcTable}
        data={templateDetails}
        rowActions={rowActions}
        actionButtons={tableActionButtons}
        isLoading={isLoading}
      />

      <CustomModel
        title="Add Template"
        submit={onSubmit}
        content={addModel}
        action={addActionButton}
        openStatus={open}
        closeStatus={handleAddClose}
        iconCloseStatus={handleIconClose}
        reset={reset}
      />

      <CustomModel
        title="Edit Template"
        submit={submitEdit}
        content={editModel}
        action={editActionButton}
        openStatus={editOpen}
        closeStatus={handleEditClose}
        iconCloseStatus={handleEditIconClose}
        reset={reset}
      />

      <CustomModel
        title=" Delete Template"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      />
    </div>
  );
}
export default TemplateList;
