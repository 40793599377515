import React, { useEffect, useState } from "react";
import "chart.js/auto";
import "./../../../index.css";
import CardLayout from "./../../../components/dashboard/CardLayout";
import CustomHeading from "./../../../components/CustomHeading";
import logo from "./../../../assets/img/logo.png";
import { BASE_URL, CHART, DASHBOARD, DASHBOARD_GU } from "../../../utills/ApplicationRouting";
import { useSelector } from "react-redux";
import ChartLayout from "../../../components/dashboard/ChartLayout";
import CustomLoading from "../../../components/CustomLoading";
import axios from "axios"
import { HandymanOutlined, NotificationsActiveOutlined } from "@mui/icons-material";
import CardLayoutContact from "../../../components/dashboard/CardLayoutContact";

function GuDashboard() {
  const { token } = useSelector((state) => state.hybridhoa);
  const [isLoading, setIsLoading] = useState(true);
  const [datas, setDatas] = useState({});
  const [chartData, setChartData] = useState({});



    const getGuDashboardData = async () => {
      try {
        setIsLoading(true)
        const storedToken = token;

        // Fetch dashboard data
        const response1 = await axios.get(`${BASE_URL}${DASHBOARD}${DASHBOARD_GU}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        if (response1.status === 200) {
          setDatas(response1.data);
        } else {
          throw new Error("Failed to fetch dashboard data");
        }

        // Fetch chart data
        const response2 = await axios.get(`${BASE_URL}${DASHBOARD}${CHART}${DASHBOARD_GU}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        if (response2.status === 200) {
          setChartData(response2.data);
        } else {
          throw new Error("Failed to fetch chart data");
          setIsLoading(false);
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };


    useEffect(() => {
      getGuDashboardData();
  }, [token]);

  const cardDatas= ([
    { count: datas.maintenanceCount, badgeContent: datas.maintenanceUnread, name: "Maintenance Requests", icon: <HandymanOutlined />, color: ["#fee2e2"], linkTo: "/gumaintenance",  tooltip: "Click to view the list of Maintenance requests", },
    { count: datas.notificationCount,badgeContent: datas.notificationUnread, name: "Notifications", icon: <NotificationsActiveOutlined />, color: "#ffd8eb", linkTo: "/notification",  tooltip: "Click to view the list of Notifications", },
  ]);

  if (isLoading) {
    return <CustomLoading />;
  }

const chartDatas= [
  {
    title: "Maintenance",
    tooltip: "Maintenance Request Status",
    card: [
      {
        count: chartData.maintenanceInProgressCount,
        title: "In progress",
      },
      {
        count: chartData.maintenancePendingCount,
        title: "Pending",
      },
      {
        count: chartData.maintenanceCompletedCount,
        title: "Completed",
      },
      {
        count: chartData.maintenanceClosedCount,
        title: "Closed",
      },
     
    ],
    data: {
      labels: [ "Pending", "In progress", "Completed", "Closed"],
      datasets: [
        {
          data: [ chartData.maintenancePendingCount,chartData.maintenanceInProgressCount,chartData.maintenanceCompletedCount,chartData.maintenanceClosedCount,],
          backgroundColor: ["#9cd96c", "#1e90ff", "#6cd9a9", "#c970db", "#ff4da6","#00bfff"],
                          hoverBackgroundColor: ["#74c533", "#006ad1", "#33c585", "#b233cb", "#ff0080","#87cefa"],
        },
      ],
    },
  },
];

  const chartOptions = { responsive: true };

  return (
    <div>
      <CustomHeading title="Dashboard" />
      <br />
      <CardLayoutContact />
      <br />
      <CardLayout cardData={cardDatas} type="dashboardGU" />
      <br />
      <br />
      <ChartLayout chartData={chartDatas} chartOptions={chartOptions} />
      <div className="fixed bottom-0 right-0 m-4">
        <a href="https://hybridhoa.com" target="blank">
          <img src={logo} width={100} height={100} alt="logo" />
        </a>
      </div>
    </div>
  );
}

export default GuDashboard;
