import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import '../index.css';
import { useDispatch, useSelector } from "react-redux";
import { setRoles } from "../redux/Hybridhoa";

function CustomCard(props) {
  const data = props.cardData;
  const cardHeight = props.cardHeight || '100px';
  const cardWidth = props.cardWidth || '250px';
  const dispatch = useDispatch();

  const userRoles = useSelector((state) => state.hybridhoa.roles);
  console.log('User Roles:', userRoles);

  const navigate = useNavigate();

  const handleCardClick = (role) => {
    // Perform role-based navigation logic
    switch (role) {
      case 'ROLE_HO':
        navigate('/dashboard-ho');
        break;
      case 'ROLE_ARC':
        navigate('/dashboard-arc');
        break;
      case 'ROLE_BM':
        navigate('/dashboard-bm');
        break;
      case 'HOMES':
        navigate('/homes');
        break;
      // Add more cases for other roles as needed
      default:
        // Handle default case or show an error message
        break;
    }
    dispatch(setRoles([role]));
  };

  useEffect(() => {
    if (userRoles.length === 1) {
      switch (userRoles[0]) {
        case 'ROLE_HO':
          navigate('/dashboard-ho');
          break;
        case 'ROLE_BM':
          navigate('/dashboard-bm');
          break;
        case 'ROLE_ARC':
          navigate('/dashboard-arc');
          break;
        // Add more cases as needed
        default:
          break;
      }
    }
  }, [userRoles, navigate]);

  const renderMultipleCards = () => {
    const filteredData = data.filter(item => userRoles.includes(item.role));

    return (
      <section className={`grid gap-6 items-center justify-center ${filteredData.length === 2 ? 'grid-cols-1 sm:grid-cols-2' : 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3'}`}>
        {filteredData.map(i => (
          <div className="mt-5" key={i.name}>
            <div className="m-4 non-draggable-container custom-card shadow-lg h-full" style={{ height: cardHeight, width: cardWidth }} onClick={() => handleCardClick(i.role)}>
              <div className="flex flex-col items-center p-8 bg-white rounded-lg shadow-lg">
                <div className={`h-16 w-16 text-${i.color}-600 bg-${i.color}-100 rounded-full mb-2`}>
                  {i.icon}
                </div>
                <div className="text-center">
                  <span className="block text-9xl font-bold mt-1 text-center" style={{ fontSize: '14px' }}>
                    {i.name}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>
    );
  };

  return renderMultipleCards();
}

export default CustomCard;
