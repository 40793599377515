import React, { useEffect, useState } from "react";
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL, GROUP_CONFIG } from "../../utills/ApplicationRouting";

const CardLayoutContact = () => {
  const [footer, setFooter] = useState('');
  const { token } = useSelector((state) => state.hybridhoa);

  const getGroupConfigurationDetails = async () => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL}${GROUP_CONFIG}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (response.status === 200) {
        const data = response.data;
        const nameGroup = data.find(item => item.topic.trim() === 'Contact Details');
        const groupContent = nameGroup.content;
        setFooter(groupContent);
      } else {
        console.error("Failed to fetch data. Status:", response.status);
        console.error("Response:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Status code:", error.response.status);
      }
    }
  };

  useEffect(() => {
    getGroupConfigurationDetails();
  }, []);

  return (
    <div>
      <div className="w-full max-w-sm sm:max-w-md lg:max-w-lg">
        <div className="bg-white rounded-lg shadow-lg">
          <div className="flex items-center p-4 sm:p-6">
            <div className="flex items-center justify-center h-12 w-12 sm:h-16 sm:w-16 rounded-full mr-4 sm:mr-6" style={{ backgroundColor: '#bbddff' }}>
              <PhoneEnabledIcon />
            </div>
            <div className="flex-1">
              <div className="max-w-xs">
                <span
                  className="block text-sm sm:text-base lg:text-xl font-bold custom-text"
                >
                  {footer}
                </span>
              </div>
              <span className="block text-gray-500 text-xs sm:text-sm mt-2">Contact Details</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardLayoutContact;
