import {
  Box,
  MenuItem,
  Typography,
  Checkbox,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import { useForm } from "react-hook-form";
import {
  BASE_URL,
  CARD_DELETE,
  CARD_DETAILS,
  CARD_DETAILS_REDIRECT,
  GET_CARD_DETAILS,
  GROUP_CONFIG,
  PAYMENT,
} from "../../utills/ApplicationRouting";
import axios from "axios";
import CustomModel from "../../components/CustomModel";
import { useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import CustomCardElement from "../../components/CustomCardElement";
import CustomCardEditElement from "../../components/CustomCardEditElement";
import { loadStripe } from "@stripe/stripe-js";
import { request } from "../../services/AxiosConfig";
import CustomButton from "../../components/CustomButton";
import CustomToastContainer from "../../components/CustomToastContainer";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";


var stripePromise = null;
var options = null;

function PaymentMethod() {
  const [totalWaiveAmount, setTotalWaiveAmount] = useState(0);

  const [showEditCard, setShowEditCard] = useState(false);
  const navigate = useNavigate();
  const { addcard } = useParams();
  const { token } = useSelector((state) => state.hybridhoa);
  const [showCard, setShowCard] = useState(false);
  const [publishKey, setPublishKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [cardBrand, setCardBrand] = useState("");
  const [lastDigits, setLastDigits] = useState("");
  const [expMonth, setExpMonth] = useState("");
  const [expYr, setExpYr] = useState("");
  const [pmId, setPmId] = useState("");
  const [checkDues, setCheckDues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [addCardOpen, setAddCardOpen] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    reset,
  } = useForm();
  const handleCloseCardEdit = () => {
    setShowEditCard(false);
  };
  const handleCloseCardAdd = () => {
    setShowCard(false);
  };

  useEffect(() => {
    if (addcard === "success") {
      

      navigate.replace("/#/paymentmethod");
    }
  }, [addcard, navigate]);
  const handleAddClickOpen = () => {
    request({
      url: `${CARD_DETAILS}`,
      method: "get",
    })
      .then((res) => {
        if (res.status == 200) {
          const data = res.data;
          console.log(data);
          setSecretKey(data.clientSecret);
          setPublishKey(data.publishKey);

          stripePromise = loadStripe(data.publishKey);

          options = {
            clientSecret: data.clientSecret,
          };

          setShowCard(true);
        }
      })
      .catch((error) => {
        console.log("error",error.response.status);
      });
  };
  const handleCardDelete = () => {
    request({
      url: `${CARD_DELETE}/${pmId}`,
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          getPaymentDetails();
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
        }
      });
  };
  const getPaymentDetails = async () => {
    try {
      const storedToken = token;

      // console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GET_CARD_DETAILS}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;

        const cardDetails = data.card;
        const cardBrandDetails = cardDetails.brand;
        const cardFourDigit = cardDetails.last4;
        const cardMonth = cardDetails.exp_month;
        const cardYear = cardDetails.exp_year;
        const cardPmId = data.id;
        console.log("card details data", data.id);
        setCardBrand(cardBrandDetails);
        setLastDigits(cardFourDigit);
        setExpMonth(cardMonth);
        setExpYr(cardYear);
        setPmId(cardPmId);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error.response);
    }
  };
  useEffect(() => {
    getPaymentDetails();
  }, []);
  const handleUpdateClickOpen = () => {
    setOpenEdit(true);
    request({
      url: `${CARD_DETAILS}`,
      method: "get",
    })
      .then((res) => {
        if (res.status == 200) {
          const data = res.data;
          console.log(data);
          setSecretKey(data.clientSecret);

          stripePromise = loadStripe(data.publishKey);

          options = {
            // passing the client secret obtained from the server
            clientSecret: data.clientSecret,
          };

          setShowEditCard(true);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.res) {
        }
      });
  };
  // useEffect(() => {
  //   console.log("lastDigits", lastDigits);

  //   if (window.location.search.includes("setup_intent")) {
  //     toast.success("Payment card added successfully!");
  //     // Redirect to the new URL
  //     setTimeout(() => {
  //       window.location.href = "/#/paymentmethod";
  //     }, 4000);
  //   }
  // }, []);

  const CardRedirect = async () => {
    if (window.location.search.includes("setup_intent")) {
      try {

        const storedToken = token;
        const response = await axios.get(
          `${BASE_URL}${CARD_DETAILS_REDIRECT}`,
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
        console.log(response.data);
        setIsLoading(true)
        console.log("check stauts", response.status);
        if (response.status === 202) {
          

          toast.success("Payment card added successfully!");
          setTimeout(() => {
            window.location.href = "/#/paymentmethod";
          }, 4000);

        } else {
          setIsLoading(false);
          throw new Error("Failed to fetch data");
        }

      } catch (error) {
        setIsLoading(false);

        console.error("Error fetching data:", error);
      }


    }

  }

  useEffect(() => {
    CardRedirect();
  }, []);
  const addCardModel = (
    <>
      <DialogContent dividers>
        <Elements stripe={stripePromise} options={options}>
          <CustomCardElement />
        </Elements>
      </DialogContent>
    </>
  );

  const editCardModel = (
    <>
      <DialogContent dividers>
        <Elements stripe={stripePromise} options={options}>
          <CustomCardEditElement />
        </Elements>
      </DialogContent>
    </>
  );
  return (
    <>
      <CustomToastContainer />
      <section class="grid md:grid-cols-1 xl:grid-cols-2 gap-1">
        <div class="p-8 bg-white  rounded-lg  shadow-lg ">
          <span class="block text-black text-base font-semibold">
            Payment Information
          </span>
          <br />

          <div class="grid md:grid-cols-2 xl:grid-cols-2">
            <span class="block text-black text-sm">
              <b>{cardBrand.toUpperCase()} Ending in :</b> XXXX XXXX XXXX{" "}
              {lastDigits ? lastDigits : "XXXX"}
            </span>

            <span class="block text-black text-sm">
              <b>Exp Date : </b> {expMonth ? expMonth : "MM"}/{" "}
              {expYr ? expYr : "YYYY"}
            </span>
       
          
          </div>
          <div class="mt-4 flex space-x-2">
      {pmId ? (
        <>
          <CustomButton
            fieldType="submit"
            buttonName="Update"
            click={handleUpdateClickOpen}
          />
          <CustomButton
            fieldType="submit"
            buttonName="Delete"
            click={handleCardDelete}
          />
        </>
      ) : (
        <CustomButton
          fieldType="submit"
          buttonName="Add"
          click={handleAddClickOpen}
        />
      )}
    </div>
        
            
            

          <CustomModel
            title="Add Card"
            content={addCardModel}
            openStatus={showCard}
            closeStatus={handleCloseCardAdd}
            iconCloseStatus={handleCloseCardAdd}
            reset={reset}
          />
          <CustomModel
            title="Update Card"
            content={editCardModel}
            openStatus={showEditCard}
            closeStatus={handleCloseCardEdit}
            iconCloseStatus={handleCloseCardEdit}
            reset={reset}
          />
        </div>
      </section>
    </>
  );
}

export default PaymentMethod;
