import { Button, Card, CardContent, FormHelperText, FormLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DateCalendar,
  DateField,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MuiTelInput } from "mui-tel-input";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import { CustomStyle } from "../../../components/CustomStyle";
import CustomHeading from "../../../components/CustomHeading";
import CustomButton from "../../../components/CustomButton";
import CustomDatePicker from "../../../components/CustomDatePicker";
import {
  CONTACT_REQUIRED_MESSAGE,
  EMAIL_INVALID_MESSAGE,
  EMAIL_REQUIRED_MESSAGE,
  FIRST_NAME_INVALID_MESSAGE,
  FIRST_NAME_REQUIRED_MESSAGE,
  FROM_DATE_REQUIRED_MESSAGE,
  LAST_NAME_INVALID_MESSAGE,
  LAST_NAME_REQUIRED_MESSAGE,
  LEASE_END_DATE_REQUIRED_GREATER_MESSAGE,
  LEASE_END_DATE_REQUIRED_MESSAGE,
  LEASE_START_DATE_REQUIRED_MESSAGE,
  MAX_LENGTH_VALIDATE_MESSAGE,
  MIN_LENGTH_VALIDATE_MESSAGE,
  PHONE_REQUIRED_MESSAGE,
  TO_DATE_REQUIRED_MESSAGE,
  MIN_LENGTH_VALIDATE_ONE, HOME_ADDRESS_REQUIRED_MESSAGE, HOME_ADDRESS_INVALID_MESSAGE,ADDRESS_REQUIRED_MESSAGE,MAX_LENGTH_TWO_FIFTY,
} from "../../../utills/ApplicationConstants";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../../../components/CustomInputField";
import {
  EMAIL_REGEX_PATTERN,
  ONLY_ALPHABETIC,
  EMAIL_REGEX_PATTERNS,ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN 
} from "../../../utills/ApplicationRegex";
import PhoneInput from "react-phone-input-2";
import axios from "axios";

import "react-phone-input-2/lib/material.css";
import { request } from "../../../services/AxiosConfig";
import { RENTER, HOMES_DETAILS,BASE_URL } from "../../../utills/ApplicationRouting";

import CustomLoading from "../../../components/CustomLoading";
import CustomPhoneInput from "../../../components/CustomPhoneInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import CustomToastContainer from "../../../components/CustomToastContainer";
import CustomFile from "../../../components/CustomFile";
import { useSelector } from "react-redux";
import CustomAutoComplete from "../../../components/CustomAutoComplete";
import CustomTooltipField from "../../../components/CustomTooltipField";

function RenterAddMc() {
  const [phone, setPhone] = useState("");
  const [file, setFile] = useState([]);
  const fileTypes = ["JPG", "PNG", "JPEG", "PDF"];
  const [isLoading, setIsLoading] = useState(false);
  const [leaseStartDate, setLeaseStartDate] = useState('');
  const [leaseEndDate, setLeaseEndDate] = useState('');
  const [dob, setDob] = useState('');
  const [fileError, setFileError] = useState("");
  const { token ,homeId} = useSelector(state => state.hybridhoa);
  const navigate = useNavigate();
  const [homeDetails, setHomeDetails] = useState("");
  const [homeAddresses, setHomeAddresses] = useState([]);
  const [homeOwnerDetails, setHomeOwnerDetails] = useState("");
  const [filteredOption, setFilteredHomeAddresses] = useState("");
  const [selectHomeId, setSelectHomeId] = useState(""); 
  const [selectedHomeOwnerLoginId, setSelectedHomeOwnerLoginId] = useState("");
  const [address,setAddress] = useState('')
  const handleChange = (newPhone) => {
    setPhone(newPhone);
  };

  const handleFileChange = (file) => {
    setFile(file);
  };

  const handleClick = () => {
    navigate(-1);
  };
 
  // console.log(errors);
    const {
      handleSubmit,
      control,
      setValue,
      watch,
      formState: { errors },
      setError, clearErrors,
    } = useForm(); 

  useEffect(() => {
    if (file && file.size <= 10 * 1024 * 1024) {
      const fileType = file.type && file.type.split("/")[1];
      if (fileType) {
        const fileExtension = fileType.toUpperCase().replace(/[^\w]/g, "");
        if (fileTypes.includes(fileExtension)) {
          setFileError("");
        }
      }
    }
  }, [file, fileTypes]);

  const minDate = dayjs().subtract(2, "year").startOf("day");
  const maxDate = dayjs().add(1, "year").endOf("day");
  const dobDate = dayjs().subtract(18, "year").endOf("day");
  const [toDateDisabled, setToDateDisabled] = useState(true);
  const [toDateMinDate, setToDateMinDate] = useState(minDate);
  const [toDateMaxDate, setToDateMaxDate] = useState(maxDate);

  const handleFromDateChange = (selectedFromDate) => {
    const newToDateMaxDate = dayjs(selectedFromDate).add(100, "year").endOf("day");
    setToDateMaxDate(newToDateMaxDate);
    setToDateMinDate(selectedFromDate);
    setToDateDisabled(false);
    setLeaseStartDate(selectedFromDate);
  };

  const handleLeaseEndDateChange = (selectedEndDate) => {
    setLeaseEndDate(selectedEndDate);
  };

  const handleDobChange = (selectedDob) => {
    setDob(selectedDob);
  };

  const getHomes = async () => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${HOMES_DETAILS}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);
      console.log(homeId);
      const data = response.data;
      if (response.status === 200) {
        const filteredHomeAddresses = data.filter(home => {
          console.log("Checking home:", home);
          return homeId?.includes(home?.homeId);
        });


        setHomeAddresses(data);
        setHomeOwnerDetails(data.homeOwnerDetails);
        setFilteredHomeAddresses(filteredHomeAddresses);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      // Handle error
      console.error("Error fetching data:", error);
    }
  };
  const renderedAddresses = homeAddresses
  .filter((home) => !filteredOption.includes(home)) // Exclude filteredHomeAddresses
  .map((home, index) => ({
    address: home.address,
    value: home.homeId,
    key: index,
  }));
  useEffect(() => {
    getHomes();
  }, []);


  const handleHomeChange = (event, newValue) => {
    if (newValue && newValue.value !== undefined && newValue.value !== null) {
      const selectedHomeId = newValue.value;
      const selectedHome = homeAddresses.find(
        (home) => home.homeId === selectedHomeId
      );
      const homeOwner = selectedHome;

      if (homeOwner) {
        const { name } = homeOwner;
        const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";
        console.log("Selected Home Description:", selectedHomeDescription);

        // Set the selected home details to the state if selectedHomeDescription is not null
// console.log(selectedHomeDescription);
        setHomeDetails(selectedHomeDescription);
        setSelectHomeId(selectedHome.homeId);
        setSelectedHomeOwnerLoginId(selectedHome.homeOwnerLoginId)

        // console.log(selectedHome.homeOwnerLoginId);
        // Use setValue to set the selected homeId in the react-hook-form
        setValue("selectedOption", selectedHomeId);

        // You can also do other operations if needed
        console.log("Selected Home:", selectedHome);
        console.log("Home Details:", homeAddresses);
      } else {
        setHomeDetails("");
        // setValue("selectedOption", null);
        setValue("selectedOption", selectedHomeId);
      }
    } else {
      // Handle the case when the selected option is removed (e.g., by pressing backspace)
      setHomeDetails("");

      // Use setValue to clear the selected homeId in the react-hook-form
      setValue("selectedOption", null);
    }
  };
  useEffect(() => {
    console.log("homeAddresses", homeAddresses);
   
  }, [selectHomeId,selectedHomeOwnerLoginId]);
  const onSubmit = (data) => {
    if (!data.leaseStartDate) {
      setError("leaseStartDate", {
        type: "manual",
        message: LEASE_START_DATE_REQUIRED_MESSAGE,
      });
      return;
    }
    if (!data.leaseEndDate) {
      setError("leaseEndDate", {
        type: "manual",
        message: LEASE_END_DATE_REQUIRED_MESSAGE,
      });
      return;
    }
    if (data.leaseEndDate < data.leaseStartDate) {
      setError("leaseEndDate", {
        type: "manual",
        message: LEASE_END_DATE_REQUIRED_GREATER_MESSAGE,
      });
      return;
    }
    const totalFileSize = file.reduce((acc, file) => acc + file.size, 0);
    const maxSizeInMB = 10;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
  
    if (totalFileSize > maxSizeInBytes) {
      // setFileError(`Total file size should not exceed ${maxSizeInMB}MB.`);
      return;
    }
  
    // Check file types
    const allowedFileTypes = ["image/jpg", "image/png", "image/jpeg", "application/pdf"];
    const invalidFiles = file.filter(file => !allowedFileTypes.includes(file.type));
  
    if (invalidFiles.length > 0) {
      // setFileError("Some files have invalid file types. Allowed types are JPG, PNG, JPEG, PDF.");
      return;
    }
    setIsLoading(true);

    const formData = new FormData();
    formData.append('leaseStartDate', dayjs(leaseStartDate).format("YYYY-MM-DD"));
    formData.append('leaseEndDate', dayjs(leaseEndDate).format("YYYY-MM-DD"));
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('dateOfBirth', dob ? dayjs(dob).format("YYYY-MM-DD") : '');
    file.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('email', data.email);
    formData.append('contact', data.phoneNumber|| "");
    formData.append('role', "ROLE_MC")
    formData.append('homeId', selectHomeId)
     formData.append('homeOwnerLoginId', selectedHomeOwnerLoginId)
    formData.append('address',data.address)

    const headers = {
      'Content-Type': 'multipart/form-data'
    };

    request({  url: `${BASE_URL}${RENTER}/${selectHomeId}`, method: "post", data: formData, headers }).then(res => {
      setIsLoading(false);
      if (res.status == 200) {
        setIsLoading(false);
        setTimeout(() => {
          navigate('/renterlistmc');
        }, 1000)
        setFile('')
      }
    }).catch(error => {
      setIsLoading(false);
      if (error.response) {
        setIsLoading(false);
        console.log("error.response.status" + error.response.status);
      }
    });
  };

  return (
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomHeading title="Add Renter" />
        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full text-lg font-sans mb-2">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleClick}
          />
        </div>
      </div>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 md:space-y-6 ">
          <CardContent>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomAutoComplete
                fieldName="selectedOption"
                requiredErrorMessage={HOME_ADDRESS_REQUIRED_MESSAGE}
                id="selectId"
                options={renderedAddresses}
                fieldLabel="Select Home Address"
                fieldControl={control}
                error={errors}
                setValue={setValue}
                fieldRequiredIcon="*"
                onChange={handleHomeChange}
                invalidOption={HOME_ADDRESS_INVALID_MESSAGE}
                tooltipMessage={"Please select the home address of the homeowner you are creating the Renter for."}
              />
              <CustomTooltipField
                fieldName="homeOwnerDetails"
                fieldId="homeOwnerDetails"
                fieldType="text"
                fieldLabel="HomeOwner Name"
                fieldControl={control}
                fieldError={errors}
                disabled={true}
                value={homeDetails}
                tooltipMessage={"This field displays the name of the homeowner based on the selected home address."}
              />
              <CustomInputField
                requiredErrorMessage={FIRST_NAME_REQUIRED_MESSAGE}
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={FIRST_NAME_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldName="firstName"
                fieldId="firstName"
                fieldType="text"
                fieldLabel="First Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                tooltipMessage={"Enter the first name of the renter."}
              />
              <CustomInputField
                fieldName="lastName"
                requiredErrorMessage={LAST_NAME_REQUIRED_MESSAGE}
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={LAST_NAME_INVALID_MESSAGE}
                minLength={1}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_ONE}
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldId="lastName"
                fieldType="text"
                fieldLabel="Last Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                tooltipMessage={"Enter the last name of the renter."}
              />
              <CustomInputField
                fieldName="email"
                requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
                fieldPattern={EMAIL_REGEX_PATTERNS}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                validator={false}
                fieldId="email"
                fieldType="text"
                fieldLabel="Email"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                tooltipMessage={"Enter a valid email address for the renter."}
              />
               <CustomInputField
                fieldName="address"
                requiredErrorMessage={ADDRESS_REQUIRED_MESSAGE}
                // fieldPattern={ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN}
                // patternErrorMessage={ADDRESS_FOR_COMMUNICATION_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={250}
                // maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
                fieldId="address"
                fieldType="text"
                fieldLabel="Address"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                tooltipMessage={"Enter the Address of the renter."}
              />
              <CustomDatePicker
                fieldName="dob"
                label="Date of Birth"
                control={control}
                maxDate={dobDate}
                onChange={handleDobChange}
                tooltipMessage={"Select the date of birth of the renter."}
              />
              <div>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    validate: {
                      isValidPhoneNumber: value => !value || isValidPhoneNumber(value) || "Please provide a valid phone number"
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <CustomPhoneInput
                        isRequiredIcon={false}
                        isRequired={false}
                        fieldControl={control}
                        label="Phone Number"
                        clearErrors={clearErrors}
                        country="US"
                        fieldError={errors}
                        value={field.value}
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        tooltipMessage={"Enter a valid phone number for the renter."}
                      />
                    </>
                  )}
                />
                {errors.phoneNumber && (
                  <FormHelperText style={{ color: "#D32F2F" }}>
                    {errors.phoneNumber.message}
                  </FormHelperText>
                )}
              </div>
              <CustomDatePicker
                fieldName="leaseStartDate"
                label="Lease Start Date"
                control={control}
                minDate={minDate}
                maxDate={maxDate}
                onChange={(date) => handleFromDateChange(date)}
                fieldRequiredIcon="*"
                tooltipMessage={"Select the start date of the lease."}
              />
              <CustomDatePicker
                fieldName="leaseEndDate"
                label="Lease End Date"
                fieldRequiredIcon="*"
                control={control}
                minDate={toDateMinDate}
                maxDate={toDateMaxDate}
                disabled={toDateDisabled}
                onChange={handleLeaseEndDateChange}
                tooltipMessage={"Select the end date of the lease."}
              />
              <CustomFile file={file} handleFileChange={handleFileChange} token={token} fileError={fileError} tooltipMessage={"Upload supporting documents such as lease agreement, identity proof, etc."} />
            </section>
            <br />
            <br />
            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Submit" />
              {isLoading && <CustomLoading />}
            </div>
          </CardContent>
        </form>
      </Card>
      <CustomToastContainer />
    </div>
  );
}

export default RenterAddMc;
