import React from "react";
import { CustomStyle } from "./CustomStyle";
import { Button, Tooltip } from "@mui/material";

function CustomButton(props) {
  const tooltipTitle = props.disabled
  ? props.tooltipText || "Button is disabled; you cannot perform any action"
  : "";
  return (
    <Tooltip title={tooltipTitle} placement={props.tooltipPlacement || "top"}>
    <div>
     <Button
     className="export-button"
    variant="contained"
      type={props.fieldType}
      onClick={props.click}
      disabled={props.disabled}
      autoFocus
       color={props.color}
          style={{ pointerEvents: props.disabled ? 'none' : 'auto' }}
        >
          <span className={CustomStyle.containedButtonStyle}>{props.buttonName}</span>
        </Button>
      </div>
    </Tooltip>
  );
}

export default CustomButton;
