import React from 'react';
import { Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import moment from 'moment';

const styles = StyleSheet.create({
  page: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 35,
    backgroundColor: '#F6F8FA',
 },
  section: {
    margin: 8,
    padding: 5,
    flexGrow: 1,
    color: 'white'
  },
  logo: {
    width: 70,
    height: 60,
    marginTop:"300px",
    // backgroundColor:"red",
    alignItems:"center",
    justifyContent:"center",
    // paddingLeft:"150px",
    flexDirection:"column"
    // color:'blue',  
  },
  header: {
    marginBottom: 0,
    alignItems: 'center',
    justifyContent:'center',
    color: 'grey',
    width:"60%",
    height:"20%",
    marginLeft:"21%"
  },
  title:{
    textAlign:"center",
    fontSize:"12px",
    color: 'black',
    marginTop:"8%",
    fontWeight
    :"bold"
},headerImage:{
    marginTop:"15%",
    paddingLeft:"70px",
    paddingRight:"50px",

    alignContent:"center",
    justifyContent:"center",
    width:"470px",
    height:"200px",
    // backgroundColor:"yellow"
  }
});

const PDFDocument = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {/* Assuming data.logo is a base64 string or a URL to the image */}
      <Text  style={styles.title}>{data.hoaName}</Text>
      {/* {data.logo ? ( <Image src={data.logo} 
      style={styles.headerImage} />):(
        <Text style={styles.headerImage}/>
      )} */}
       {data.logo ? (
        <Image src={data.logo} style={styles.headerImage} />
      ) : (
        <Text style={styles.headerImage}> </Text> // Render an empty string
      )}
      {data.letterContent.map((item, index) => {
        switch (item.type) {
          case 'text':
            return <Text key={index} style={{ ...styles.text, ...item.style }}>{item.text}</Text>;
          case 'image':
            // Ensure your image source (item.src) is a valid base64 string or a URL
            return <Image key={index} src={item.src} style={{ ...styles.image, ...item.style }}/> ;
          default:
            return null;
        }
      })}
    </Page>
  </Document>
);

export default PDFDocument;