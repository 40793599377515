import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CustomViewPage from '../../components/CustomViewPage'
import { BASE_URL, DOCUMENT } from '../../utills/ApplicationRouting';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { FileDownloadOutlined } from '@mui/icons-material';
import CustomLoading from '../../components/CustomLoading';

function ArchivedDocumentView() {
  const navigate = useNavigate();
  const {documentId} = useParams();
  const [documentDetailsData,setDocumentDetailsData] = useState('');
  const { token } = useSelector(state => state.hybridhoa);
  const [isLoading, setIsLoading] = useState(true);
  const [firstName,setFirstName] = useState('');
  const [lastName,setLastName] = useState('');
  const [fileUrl,setFileUrl]=useState("");
  const [createdDateAndTime,setCreatedDateAndTime]=useState("")
  const handleClick =() =>{
      navigate(-1)
  }

  const getDocumentDetailsById = async () => {
    try {
      setIsLoading(true);
          const storedToken = token

      console.log(storedToken);
      
        const response= await axios.get(`${BASE_URL}${DOCUMENT}/${documentId}`,{
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
              console.log(response.data); 

      if (response.status === 200) {
        const data = response.data;
        setDocumentDetailsData(data);
        const createdByDetails = data.createdByDetails;
        setFirstName(createdByDetails.firstName);
        setLastName(createdByDetails.lastName);
        setIsLoading(false);
        setFileUrl(data.fileUrl);

        const formattedDate = moment(data.createdDateAndTime).format("MM/DD/YYYY hh:mm A");
        setCreatedDateAndTime(formattedDate)
      } else {
        setIsLoading(false);
        throw new Error('Failed to fetch data');
      }
     
    } catch (error) {
      setIsLoading(false);
      // Handle error
      console.error("Error fetching data:", error);
    }
  };



useEffect(()=>{
  getDocumentDetailsById();
},[])

const fullName = firstName + " " + lastName;

const convertToPascalCase = (str) => {
  // Check if the input string is undefined or empty
  if (str === undefined || str === '' || str === null) return '';

  // Remove underscores and convert string to Pascal case
  return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};

const data = {
  pageTitle:"Archived Document View",
  buttons :[
    {
      buttonTitle:"Back",
      onClick : handleClick
    },  
   
  ],
  content: [
    {
      topic: "Created By",
      description:fullName,
    },
    {
      topic: "Category",
      // description: documentDetailsData.category,
      description :convertToPascalCase(documentDetailsData.category)
    },

    {
      topic: "Created Date Time",
      description: moment(documentDetailsData.createdDateAndTime).format(
        "MM/DD/YYYY hh:mm:ss a"
      ),
    },
    {
      topic: "Audit Date Time",
      description: moment(documentDetailsData.auditDateAndTime).format(
        "MM/DD/YYYY hh:mm:ss a"
      ),
    },
    {
      topic: "Publish Date",
      description: documentDetailsData.publishDate,
    },
    {
      topic: "Status",
      // description: documentDetailsData.status,
      description :convertToPascalCase(documentDetailsData.status)
    },
    {
      topic: "Is Hidden",
      description: documentDetailsData.isHidden ? "Yes" : "No",
    },
    {
      topic :'Attachments',
      component: "AttachmentList", 
      props: {
        fileUrl: fileUrl,
        documentName: fileUrl,
        docname: fileUrl,
        uploadedTimes: createdDateAndTime,
      },
    },
    {
      topic: "Description",
      description: documentDetailsData.description,
    },
  ],
}
  return (
    <div>
        {isLoading && <CustomLoading />}
      <CustomViewPage data = {data} />
         </div>
  )
}
export default ArchivedDocumentView