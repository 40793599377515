import React, { useState } from 'react'
import logo from "../../assets/img/logo.png";
import "../../App.css";

import LoginRight from './../../components/login/LoginRight';
import LoginForm from './../../components/login/LoginForm';
import CustomToastContainer from '../../components/CustomToastContainer';
import CustomInputField from '../../components/CustomInputField';
import CustomModelView from '../../components/CustomModelView';
import { EMAIL_INVALID_MESSAGE, EMAIL_REQUIRED_MESSAGE, PASSWORD_REQUIRED_MESSAGE } from '../../utills/ApplicationConstants';
import { EMAIL_REGEX_PATTERN, EMAIL_REGEX_PATTERNS } from '../../utills/ApplicationRegex';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Link, useNavigate } from 'react-router-dom';
import CustomButton from '../../components/CustomButton';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import axios from 'axios'; // Import axios for making HTTP requests
// import ReCAPTCHA from 'react-google-recaptcha'; // Import ReCAPTCHA if you're using it
import { BASE_URL, LINK_EMAIL, LOGIN } from '../../utills/ApplicationRouting';
import { setHomeDetails, setHomeId, setRoles, setToken, setUserDetails, setUserLoginDetails, setUserRoles } from '../../redux/Hybridhoa';
import { toast } from 'react-toastify';
import CustomModel from '../../components/CustomModel';
import {
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Typography,
} from "@mui/material";
import { request } from '../../services/AxiosConfig';
import { Password } from '@mui/icons-material';
import ReCAPTCHA from "react-google-recaptcha"
// Import constants and utilities if needed


function Login() {

 
    
  const [captchaToken, setCaptchaToken] = useState(null);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);

  const { token } = useSelector(state => state.hybridhoa);
  const dispatch = useDispatch();
  const storedToken = localStorage.getItem("token");
  let navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const[emailOpen,setEmailOpen]=useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const[email,setEmail]=useState("");

  const handleVerify = (token) => {
    if (token) {
      setIsCaptchaValid(true);
    } else {
      setIsCaptchaValid(false);
    }
    console.log(token);
  };

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors},  
    reset,
    setError,
    setValue,
  } = useForm();

  const {
    handleSubmit : handleSubmitForm,
    watch :watchForm,
    control :controlForm,
    formState: { errors:errorsForm}, 
    reset :resetForm,
    setError :setErrorForm,
    setValue :setValueForm,
    clearErrors :clearErrorsForm,

  } = useForm();
  
    
  const onSubmit = (data) => {
    if (loginAttempts >= 3 && !isCaptchaValid) {
      toast.error('You must complete the CAPTCHA to proceed');
      return;
    }
      
    // if (typeof data.password !== 'string' || data.password.trim() === '') {
    //   setError("password", {
    //     type: "manual",
    //     message: PASSWORD_REQUIRED_MESSAGE,
    //   });
    //   setIsLoading(false);
    //   return;
    // }
    if (typeof data.email !== 'string' || data.email.trim() === '') {
      setErrorForm("email", {
        type: "manual",
        message: EMAIL_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    const postData = {
      email: data.email,
      password: data.password,
      captchaToken: captchaToken,
    };
    console.log(data);
  
    axios.post(`${BASE_URL}${LOGIN}`, postData)
      .then(response => {
        console.log(response.data);

        if (response.status === 200) {

          // console.log(response);
          // console.log(response.data.user.login);
          // console.log(response.data.user.details);
          // console.log(response.data.user.login.roles);
          // console.log(response.data.token + "Response Token")
          // console.log(response.message + "Response Message");
          localStorage.setItem("token", response.data.token)
          dispatch(setUserLoginDetails(response.data.user.login));
          dispatch(setUserDetails(response.data.user.details));  
          dispatch(setHomeDetails(response.data.homeDetails));
          dispatch(setRoles(response.data.user.login.roles));
          dispatch(setUserRoles(response.data.user.login.roles));
          // const homeIds = response.data.homeDetails.map(home => home.homeId);
          const homeIds = response.data?.homeDetails[0]?.homeId

          // console.log("homeIds",homeIds);
          dispatch(setHomeId(homeIds));
          const userRoles = response.data.user.login.roles;       
          dispatch(setToken(storedToken));
          const phoneVerified = response.data.user.login.phoneVerified;
          const emailVerified = response.data.user.login.emailVerified;
          
          // console.log("first",phoneVerified)
          if(phoneVerified === false) {
            navigate("/contactverification", { replace: true })
        } else if(emailVerified === false) {
          navigate("/linkemail", { replace: true });
      }

        else if (userRoles.includes("ROLE_GU")) {
            navigate("/dashboard-gu", { replace: true });
        } else if (userRoles.includes("ROLE_MC")) {
            navigate("/dashboard-mc", { replace: true });
        } else {
            navigate("/userrole", { replace: true });
        }
        }
      })
      .catch(error => {
        console.log(error.response); 
    
        if (error.response) {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_CENTER,
            });
            console.log("error.response.status" + error.response.status);           
        // Increment login attempts
        setLoginAttempts(prevAttempts => prevAttempts + 1);

        // If login attempts exceed 3, reset attempts and show CAPTCHA
        if (loginAttempts >= 3) {
          setCaptchaToken(null); // Reset CAPTCHA token
          // setLoginAttempts(0); // Reset login attempts
        }
      }
    });
  };
  const handleEmailClickOpen = () => {
    setEmailOpen(true);
    
  };
  
  const handleEmailClose = () => {
    setEmailOpen(false);
    setEmail("");
    clearErrorsForm();
    resetForm();
  };
  const okCancelAction = (
    <>
      {/* <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleEmailClose}
      /> */}
    <CustomButton
        fieldType="submit"
        buttonName="Send"
        className="bg-blue-500 mr-32"
      />
      
    </>
  )



  const onSubmitEmail = () => {
    setIsLoading(true);
    if (typeof email !== 'string' || email.trim() === '') {
      setErrorForm("email", {
        type: "manual",
        message: EMAIL_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

  
    axios.get(`${BASE_URL}${LINK_EMAIL}${email}`).then(res=>{
      console.log(res);
      setIsLoading(false);
      if(res.status===200){
        toast.success(res.data, { position: "top-center" }); 
        setEmailOpen(false);
        setEmail("");
      }
    }).catch(error=>{

      if (error.response) {
        toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
        });}
      setIsLoading(false);
console.log(error);
    });
  };


  const emailModel = (
    <>
      <form
        onSubmit={handleSubmitForm(onSubmitEmail)}
        className="space-y-2 md:space-y-2"
      >
        <DialogContent dividers>
          <div className='flex items-center w-full'> {/* Removed width for dynamic sizing */}
            <Typography  className="flex-grow">
              {/* <InputLabel htmlFor="email" className="text-sm">Email</InputLabel>  
              <FormControl fullWidth>
                <div className="flex"> 
                  <TextField
                    id="email"
                    type="email"
                    variant="outlined"
                    fullWidth
                    onInput={(event) => setEmail(event.target.value)}
                  />
                </div>
              </FormControl> */}

<CustomInputField
                fieldName="email"
                // requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
                fieldPattern={EMAIL_REGEX_PATTERN}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                validator={false}
                fieldId="email"
                fieldType="text"
                fieldLabel="Email"
                fieldControl={controlForm}
                fieldError={errorsForm}
                fieldRequiredIcon="*"
                value={email}
                onInput={(e)=> setEmail(e.target.value)}
                className="w-full"
            
              />
              <Link
                onClick={handleEmailClose}
                className="text-sm font-medium text-primary-600 hover:underline text-blue-400 mt-2 md:mt-0"
              >
                Click here to sign in
              </Link>
            </Typography>
            <DialogActions > {/* Align actions to the right */}
              {okCancelAction}
            </DialogActions>
          </div>
        </DialogContent>
      </form>
    </>
  );
  
  

  return (
    <div  >

<CustomModelView

title = "Email Verification " 
submit = {onSubmit}
content = {emailModel}
action ={okCancelAction}
openStatus = {emailOpen}
                   
closeStatus={handleEmailClose}
iconCloseStatus={handleEmailClose}
modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
reset={reset}
isLoading={isLoading}

/>
     <div class="grid  grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 md:grid-cols-1  gap-4">
 
   
  
    {/* <div className="hidden lg:block lg:w-1/3 text-black mb-10 mr-8 mt-80 md:mb-30 " style={{ marginLeft: "100px", marginTop: "-500px"  }}> */}
{/* 
    <div class="flex justify-center hidden sm:block lg:w-1/3  md:justify-start md:ml-20 md:-mt-96  md:-mb-32 mt-auto  "> */}
    {/* <div class="hidden lg:block lg:w-1/3 text-black mb-10 mr-8 md:mb-30 lg:ml-100 lg:mt-0 mt-80"> */}
    {/* <div className="md:-mt-96 md:-mb-12"> */}

    {/* <div class=" hidden sm:block flex justify-center overflow-auto md:justify-start md:ml-20  md:-mb-32 mt-32  mt-[-450px] mr-[1109px]"> */}
<div className=' hidden lg:block md:block'>
    <div class=" lg:w-1/2  md:ml-16  absolute bottom-10 left-0 ">



      <div className="md:mt-30 md:mt-0 md:mt-0 lg:mt-60 mr-20  ">

        
        <p className="text-md font-bold">Communication</p>
        <p className="text-sm mr-20">
          Send and receive maintenance and compliance requests
        </p>
        <br />
        <p className="text-md font-bold">Payments</p>
        <p className="text-sm mr-20">
          Payonline, check payment status and payment history
        </p>
        <br />
 
        {/* <div className="flex justify-between">
          <a href='https://apps.apple.com/us/app/hybridhoa/id6444210368'>
            <img src="appstore.png" alt="App Store" style={{ width: '100px', height: '35px' }} />
          </a>
          <a href='https://play.google.com/store/apps/details?id=com.hybridhoa&pli=1'>
            <img src="googleplay.png" alt="Google Play" style={{ width: '100px', height: '35px', marginRight: '400px' }} />
          </a>
        </div>  */}


        {/* <div class="flex justify-between  ">
  <a href="https://apps.apple.com/us/app/hybridhoa/id6444210368">
    <img src="appstore.png" alt="App Store" class="w-24 md:w-32 lg:w-40" />
  </a>
  <a href="https://play.google.com/store/apps/details?id=com.hybridhoa&pli=1">
    <img src="googleplay.png" alt="Google Play" class="w-24 md:w-32 lg:w-40 mr-20 " />
  </a>
</div> */}

<div class="flex space-x-5">
  <a href="https://apps.apple.com/us/app/hybridhoa/id6444210368">
    <img src="appstore.png" alt="App Store" class="w-20 md:w-24 lg:w-32" />
  </a>
  <a href="https://play.google.com/store/apps/details?id=com.hybridhoa&pli=1">
    <img src="googleplay.png" alt="Google Play" class="w-20 md:w-24 lg:w-32 " />
  </a>
</div>

      </div>
    </div>
    </div>

    <div >
      <CustomToastContainer/>
      {/* <div style={{ marginRight: "330px" }}></div> */}
      <div className="flex justify-center items-center h-screen overflow-hidden  " >
     {/* <div class="flex justify-end items-center h-screen sm:mr-0 md:mr-32"> */}
     {/* <div class="flex justify-center md:justify-center items-center h-screen sm:mr-0 md:mr-0 mr-32"> */}
     {/* <div class="flex justify-end items-center h-screen sm:mr-0 md:mr-0 md:justify-center "> */}


    <div className="w-full max-w-md p-6 md:p-8 ">
      <div className="bg-white rounded-lg shadow-md">
        <div className="p-6 space-y-4 md:space-y-6 ">
          {/* <div className="mb-6" style={{ position: "absolute", top: 0, left: 75 }}> */}
          <div className="mb-6 absolute top-0 left-1/2 transform -translate-x-1/2 md:left-36">
  <img className="w-44 h-14 mx-auto md:mx-0" src={logo} alt="logo" />
</div>



          <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 text-center text-size-20px">
            Owner Portal
          </h1>
          <form className="space-y-2 md:space-y-4"  onSubmit={handleSubmit(onSubmit)} >
                <div>
                  <CustomInputField
                    fieldName="email"
                    requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
                    fieldPattern={EMAIL_REGEX_PATTERN}
                    patternErrorMessage={EMAIL_INVALID_MESSAGE}
                    fieldId="login-email"
                    fieldType="text"
                    fieldLabel="Email"
                    fieldControl={control}
                    fieldError={errors}
                  />
                </div>
                <div className="relative z-0 mb-6">
                   {/* <CustomInputField
                     fieldName="password"
                     requiredErrorMessage={PASSWORD_REQUIRED_MESSAGE}
                     fieldId="login-password"
                     fieldType="password"
                     fieldLabel="Password"
                     fieldControl={control}
                     fieldError={errors}
                     
                     /> */}
                  <Controller
                    name="password"
                    control={control}
                    rules={{ required: PASSWORD_REQUIRED_MESSAGE }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type={showPassword ? "text" : "password"}
                        variant="standard"
                        label="Password"
                        fullWidth
                        error={!!fieldState?.error}
                        helperText={fieldState?.error?.message}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                 <VisibilityOffIcon />
                                  ) : (
                                    <VisibilityIcon />
                                  )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  
                
                </div>
                {loginAttempts >= 3 && (
                      <div className="flex justify-center">
                        <ReCAPTCHA
                          sitekey="6Lc6O5AqAAAAAAAiMlNjiSQNK1K-wsispctL-Ebh"
                          
                          
                          
                          // Replace with your reCAPTCHA site key
                          onChange={handleVerify}
                        />
                      </div>
                    )}
                {/* <br/> */}
                <div className="flex justify-center  pt-3">
                  <CustomButton fieldType="submit" buttonName="Login" />
                </div>
                <br/>
                <div className="text-center">
  <Link onClick={handleEmailClickOpen} className="text-sm font-medium text-primary-600 hover:underline mb-2" style={{ display: 'block' }}>Email Verification</Link>
  <Link to="/requestforaccess" className="text-sm font-medium text-primary-600 hover:underline mb-2" style={{ display: 'block' }}>Request for Access</Link>
  <Link to="/forgot" className="text-sm font-medium text-primary-600 hover:underline mb-2" style={{ display: 'block' }}>Forgot password?</Link>
</div>


             
                <hr />
                <div className="text-gray-600 text-center" style={{ fontSize: '10px' }}>
                  By logging into our products, you are automatically agreeing to our  Terms of Service and Privacy Policy

                  {/* <a href="https://hybridhoa.com/termsofservice" target="_blank" rel="noopener noreferrer">Terms of Service</a> */}

                  {/* <Link to="https://hybridhoa.com/termsofservice" target="_blank" className="text-sm text-gray-600 text-center ml-1 hover:underline">
                 Terms of Service </Link> & */}
                 {/* <Link to="https://hybridhoa.com/privacypolicy"target="_blank" className="text-sm text-gray-600 text-center ml-1 hover:underline">
                 Privacy Policy</Link> */}

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
  )
}

export default Login;