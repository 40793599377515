import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../../components/CustomInputField";
import {
  BYLAW_REQUIRED_MESSAGE,
  CATEGORY_INVALID_MESSAGE,
  CATEGORY_REQUIRED_MESSAGE,
  DESCRIPTION_REQUIRED_MESSAGE,
  HOME_ADDRESS_INVALID_MESSAGE,
  HOME_ADDRESS_REQUIRED_MESSAGE,  
  MAX_LENGTH_FIVE_THOUSAND,
  MAX_LENGTH_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
} from "../../utills/ApplicationConstants";
import {
  Autocomplete,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  TextField,Box,Typography,
} from "@mui/material";
import CustomHeading from "../../components/CustomHeading";

import CustomInputFieldExpand  from"../../components/CustomInputFieldExpand";
import { FileUploader } from "react-drag-drop-files";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  BASE_URL,
  BASE_URL_IMAGE,
  BYLAW,
  CATEGORY,
  COMPLIANCE_REQUEST,
  COMPLIANCE_REQUEST_BY_ID,
  COMPLIANCE_REQUEST_EDIT_BY_ID,
  HOMES,
  HOMES_DETAILS,
} from "../../utills/ApplicationRouting";
import CustomAutoComplete from "../../components/CustomAutoComplete";
import { request } from "../../services/AxiosConfig";
import CustomLoading from "../../components/CustomLoading";
import CustomToastContainer from "../../components/CustomToastContainer";
import CustomAutoCompleteEdit from "../../components/CustomAutoCompleteEdit";
import { InfoOutlined } from "@mui/icons-material";
import { Tooltip, IconButton } from "@mui/material";
import FileSaver from "file-saver";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CustomFile from "../../components/CustomFile";
import CustomTooltipField from "../../components/CustomTooltipField";
import CustomFileEdit from "../../components/CustomFileEdit";


function ComplianceRequestEdit() {
  const [file, setFile] = useState([]);
  const fileTypes = ["JPG", "PNG", "JPEG", "PDF"];
  const navigate = useNavigate();
  const [homeAddresses, setHomeAddresses] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [byLawDetails, setByLawDetails] = useState([]);
  const [byLawDescription, setByLawDescription] = useState("");
  const [description, setDescription] = useState("");
  const [byLawId, setByLawId] = useState("");
  const [fileData, setFileData] = useState([]);
  const { token, homeId,userRoles } = useSelector((state) => state.hybridhoa);
  const [complianceRequestData, setComplianceRequestData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { complainceId } = useParams();
  const [crId, setCrId] = useState("");
  const [selectedHomeAddress, setSelectedHomeAddress] = useState("");
  const [bylawTopic, setBylawTopic] = useState("");
  const [category, setCategory] = useState("");
  const [homeDetails, setHomeDetails] = useState("");
  const [filteredOption, setFilteredHomeAddresses] = useState("");
  const [homeOwnerDetails, setHomeOwnerDetails] = useState("");
  const [selectedBylawValue, setSelectedBylawValue] = useState(null);
  const [fileError, setFileError] = useState("");
  const [bylawsUpdated, setBylawsUpdated] = useState(true);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
    watch,
  } = useForm();

  

  useEffect(() => {
    const selectedBylaw = watch("selectedBylaw");

    if (!selectedBylaw || selectedBylaw === "") {
      setByLawDescription("");
      // setIsBylawFieldEmpty(true);
    } else {
      // Find the matching byLaw from byLawDetails
      const matchingByLaw = byLawDetails.find(
        (byLaw) => byLaw.byLawId === selectedBylaw
      );

      if (matchingByLaw) {
        setByLawDescription(matchingByLaw.description);
        // setIsBylawFieldEmpty(false);
      } else {
        // Handle the case where no matching byLaw is found
        setByLawDescription("");
        // setIsBylawFieldEmpty(true);
      }
    }
  }, [watch("selectedBylaw"), byLawDetails]);

  useEffect(() => {
    const selectedHome = watch("selectedOption");

    if (!selectedHome || selectedHome === "") {
      setByLawDescription("");
      // setIsBylawFieldEmpty(true);
    } else {
      // Find the matching byLaw from byLawDetails
      const matchingHome = homeAddresses.find(
        (home) => home.homeId === selectedHome
      );

      if (matchingHome) {
        if (matchingHome) {
          const {name} = matchingHome;
          const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";
          setHomeDetails(selectedHomeDescription);
        }
        // setIsBylawFieldEmpty(false);
      } else {
        // Handle the case where no matching byLaw is found
        setHomeDetails("");
        // setIsBylawFieldEmpty(true);
      }
    }
  }, [watch("selectedOption"), homeDetails]);

  const handleViewClick = () => {
    navigate(-1);
  };

  const handleFileChange = (file) => {
    setFile(file);
  };

  const getHomes = async () => {
    try {
      const storedToken = token;

      

      const response = await axios.get(`${BASE_URL}${HOMES_DETAILS}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setHomeAddresses(data);
        const filteredHomeAddresses = data.filter(home => {
          console.log("Checking home:", home);
          return homeId.includes(home.homeId);
        });
        setFilteredHomeAddresses(filteredHomeAddresses);
        setHomeOwnerDetails(data.homeOwnerDetails);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getHomes();
  }, []);

  const handleHomeChange = (event, newValue) => {
    if (newValue && newValue.value !== undefined && newValue.value !== null) {
      const selectedHomeId = newValue.value;
      const selectedHome = homeAddresses.find(
        (home) => home.homeId === selectedHomeId
      );
      const homeOwner = selectedHome.homeOwnerDetails;

      if (homeOwner) {
        const { name } = homeOwner;
        const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";
        console.log("Selected Home Description:", selectedHomeDescription);

        // Set the selected home details to the state
        setHomeDetails(selectedHomeDescription);

        // Use setValue to set the selected homeId in the react-hook-form
        setValue("selectedOption", selectedHomeId);

        // You can also do other operations if needed
        console.log("Selected Home:", selectedHome);
        console.log("Home Details:", homeAddresses);
      } else {
        setHomeDetails("");
        // setValue("selectedOption", null);
        setValue("selectedOption", selectedHomeId);
      }
    } else {
      // Handle the case when the selected option is removed (e.g., by pressing backspace)
      setHomeDetails("");

      // Use setValue to clear the selected homeId in the react-hook-form
      setValue("selectedOption", null);
    }
  };

  useEffect(() => {
    console.log("homeAddresses", homeAddresses);
    console.log("bylawTopic", bylawTopic);
    console.log("category", category);
  }, [homeAddresses, bylawTopic, category]);

  const getCategory = async () => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setCategoryDetails(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const handleCategoryChange = (event, newValue) => {
    if (newValue) {
      const selectedCategoryId = newValue.value;

      // Set the selected homeId to the state
      setValue("category", selectedCategoryId);

      // You can also do other operations if needed

      console.log("selectedCategoryId:", selectedCategoryId);
      console.log("Home Details:", homeAddresses);
    } else {
      setValue("category", null); // Reset to null if no category is selected
    }
  };

  // const handleByLawChange = (event, newValue) => {
  //   console.log("working");

  //   if (newValue) {
  //     const selectedByLawId = newValue.value;
  //     setValue("selectedBylaw",selectedByLawId)
  //     const selectedByLawDescription = byLawDetails.find(
  //       (byLaw) => byLaw.byLawId === selectedByLawId
  //     )
  //   const byLawDescription=selectedByLawDescription.description

  //     console.log("Selected ByLaw:", newValue);
  //     console.log("ByLaw Details:", byLawDetails);
  //     console.log("Selected ByLaw Description:", selectedByLawDescription);

  //     setByLawDescription(byLawDescription || "");
  //   } else {
  //     setByLawDescription("");
  //   }
  // };

  const handleByLawChange = (event, newValue) => {
    if (newValue && newValue.value !== undefined && newValue.value !== null) {
      const selectedByLawId = newValue.value;
      setValue("selectedBylaw", selectedByLawId);
      const selectedByLawDescription = byLawDetails.find(
        (byLaw) => byLaw.byLawId === selectedByLawId
      )?.description;

      setByLawDescription(selectedByLawDescription);

      // Update the state here
      setSelectedBylawValue(newValue);
    } else {
      // Clear the state values
      setByLawDescription("");
      setValue("selectedBylaw", null);

      // Update the state here
      setSelectedBylawValue(null);
    }
  };
  const renderedAddresses = homeAddresses
    .filter((home) => !filteredOption.includes(home)) // Exclude filteredHomeAddresses
    .map((home, index) => ({
      address: home.address,
      value: home.homeId,
      key: index,
    }));

  const renderedCategory = categoryDetails
    .filter((category) => category.type === "CR") // Filter categories with type 'CR'
    .map((category, index) => ({
      category: category.category,
      value: category.categoryId,
      key: index,
    }));

  const renderedBylaw = byLawDetails.map((ByLaw, index) => ({
    topic: ByLaw.topic,
    value: ByLaw.byLawId,
    key: index,
  }));

  const getBylaw = async () => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${BYLAW}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        console.log("byLaw data", data);
        setByLawDetails(data);
        setBylawsUpdated(data.length > 0);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getBylaw();
  }, []);

  const getComplianceRequestDetailsById = async () => {
    try {
      const storedToken = token;
      setIsLoading(true);

      console.log(storedToken);
      console.log("complainceId", complainceId);
      const response = await axios.get(
        `${BASE_URL}${COMPLIANCE_REQUEST_EDIT_BY_ID}/${complainceId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);
      console.log(response.data.bylawId);

      if (response.status === 200) {
        const data = response.data.compliance;
        const home = response.data.compliance.address.address;
        setSelectedHomeAddress(home);
        console.log("Home", home);
        setComplianceRequestData(data);
        setDescription(data.description);
        setFileData(data.attachments);
        setIsLoading(false);
        setCrId(response.data.compliance.crId);

        const matchingHome = homeAddresses.find(
          (home) => home.homeId === data.homeId
        );
        console.log("matchingHome", matchingHome);

        if (matchingHome) {
          setSelectedHomeAddress(matchingHome.address);
          setValue("selectedOption", matchingHome.homeId);
          const { name } = matchingHome;
          const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";
          setHomeDetails(selectedHomeDescription);
        } else {
          setSelectedHomeAddress(""); // Handle when matching home is not found
          setValue("selectedOption", ""); // Reset to an empty string if no matching home is found
          setHomeDetails("");
        }

        const matchingByLaw = byLawDetails.find(
          (byLaw) => byLaw.byLawId === data.bylawId
        );
        console.log("matchingByLaw", matchingByLaw);
        console.log("byLawDetails", byLawDetails);

        if (matchingByLaw) {
          setBylawTopic(matchingByLaw.topic);
          setValue("selectedBylaw", matchingByLaw.byLawId);
          setByLawDescription(matchingByLaw.description);
          console.log("Bylaw: " + matchingByLaw.byLawId);
        } else {
          setValue("selectedBylaw", ""); // Reset to an empty string if no matching bylaw is found
          setByLawDescription("");
        }

        const matchingCategory = categoryDetails.find(
          (category) => category.categoryId === data.category
        );
        console.log("matchingCategory", matchingCategory);
        console.log("categoryDetails", categoryDetails);
        if (matchingCategory) {
          setCategory(matchingCategory.category);
          console.log("category", matchingCategory.categoryId);
          setValue("category", matchingCategory.categoryId);
        } else {
          setCategory("");
          setValue("category", "");
        }
      } else {
        setIsLoading(false);

        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);

      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getComplianceRequestDetailsById();
  }, [homeAddresses, byLawDetails, categoryDetails]);



 
  const onSubmit = async (data) => {
    if (!description || description.trim() === "") {
      setError("violationDescription", {
        type: "manual",
        message: "Description is required",
      });
      return;
    }
    
    const totalFileSize = file.reduce((acc, file) => acc + file.size, 0);
    const maxSizeInMB = 10;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
  
    if (totalFileSize > maxSizeInBytes) {
      // setFileError(`Total file size should not exceed ${maxSizeInMB}MB.`);
      return;
    }
  
    // Check file types
    const allowedFileTypes = ["image/jpg", "image/png", "image/jpeg", "application/pdf"];
    const invalidFiles = file.filter(file => !allowedFileTypes.includes(file.type));
  
    if (invalidFiles.length > 0) {
      // setFileError("Some files have invalid file types. Allowed types are JPG, PNG, JPEG, PDF.");
      return;
    }

  
  setIsLoading(true);

    const formData = new FormData();
    formData.append("homeId", data.selectedOption);

    formData.append("category", data.category);
    formData.append("description", description);
    file.forEach((file) => {
      formData.append('files', file);
      });
    formData.append("bylawId", data.selectedBylaw);
    formData.append("role", userRoles[0]);
    console.log("homeId:", data.selectedOption);
    console.log("category:", data.category);
    console.log("description:", description);
    console.log("file:", file);
    console.log("bylawId:", data.selectedBylaw);
    console.log("role:",  userRoles[0]);

    // console.log('formData:', formData);

    const storedToken = token;

    console.log(storedToken);
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    // return;
    // data:formData,

    request({
      url: `${BASE_URL}${COMPLIANCE_REQUEST_BY_ID}/${complainceId}`,
      data: formData,
      method: "put",
      headers,
    })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          setIsLoading(false);
          setTimeout(() => {
            navigate(-1);
          }, 1000); // Adjust the delay time as needed (in milliseconds)
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        if (error.response) {
        setIsLoading(false);

          console.log("error.response.status" + error.response.status);
        }
      });
  };

  return (
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomHeading title="Edit Compliance" />

        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleViewClick}
          />
        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomTooltipField
                fieldName="complianceRequestId"
                fieldId="complianceRequestId"
                fieldType="text"
                fieldLabel="Compliance Request ID"
                fieldControl={control}
                fieldError={errors}
                disabled={true}
                value={crId}
                tooltipMessage={"This field displays the unique identifier for the compliance request."}
              />
              {/* {homeOwnerDetails !== "" && homeOwnerDetails !== null && ( */}
              <CustomAutoCompleteEdit
                  fieldName="selectedOption"
                  requiredErrorMessage={HOME_ADDRESS_REQUIRED_MESSAGE}
                  id="selectId"
                  options={renderedAddresses}
                  fieldLabel="Select Home Address"
                  fieldControl={control}
                  error={errors}
                  setValue={setValue}
                  fieldRequiredIcon="*"
                  onChange={handleHomeChange}
                  value={selectedHomeAddress}
                  invalidOption={HOME_ADDRESS_INVALID_MESSAGE}
                  tooltipMessage={"Please select the home address of the homeowner you are creating the compliance for."}
                />
              {/* )} */}
                {/* {homeOwnerDetails !== "" && homeOwnerDetails !== null && ( */}
                <CustomTooltipField
                  fieldName="homeOwnerDetails"
                  fieldId="homeOwnerDetails"
                  fieldType="text"
                  fieldLabel="HomeOwner Name"
                  fieldControl={control}
                  fieldError={errors}
                  disabled={true}
                  value={homeDetails}
                  multiline={true}
                  numberOfRows={2}
                  tooltipMessage={"This field displays the name of the homeowner based on the selected home address."}
                />
              {/* )} */}

              <CustomAutoCompleteEdit
                fieldName="category"
                requiredErrorMessage={CATEGORY_REQUIRED_MESSAGE}
                id="selectId"
                options={renderedCategory}
                fieldLabel="Select Category/Subject"
                fieldControl={control}
                error={errors}
                setValue={setValue}
                fieldRequiredIcon="*"
                onChange={handleCategoryChange}
                value={category}
                invalidOption={CATEGORY_INVALID_MESSAGE}
                tooltipMessage={"Please select the category or subject relevant to the compliance issue."}
              />

              <CustomTooltipField
                fieldName="violationDescription"
                // requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={5000}
                maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
                fieldId="violationDescription"
                fieldType="text"
                fieldLabel="Violation Description"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                multiline={true}
                numberOfRows={2}
                value={description}
                onInput={(event) => {
                  setDescription(event.target.value);
                }}
                tooltipMessage={"Provide a detailed description of the violation or compliance issue."}
              />

              

             
            
              {/* <CustomAutoCompleteEdit
                fieldName="selectedBylaw"
                // requiredErrorMessage={BYLAW_REQUIRED_MESSAGE}
                id="selectId"
                options={renderedBylaw}
                fieldLabel="Select Bylaw"
                fieldControl={control}
                // error={errors}
                setValue={setValue}
                onChange={handleByLawChange}
                value={selectedBylawValue}
                isRequired={false}
                invalidOption={BYLAW_REQUIRED_MESSAGE}
              /> */}
              <div className="h-1/2">
{!bylawsUpdated ? (<Tooltip title="The bylaw in your association is not updated, so please contact the group admin." placement="bottom">
      <span>
        <CustomAutoComplete
          fieldName="selectedBylaw"
          id="selectId"
          options={renderedBylaw}
          fieldLabel="Select Bylaw"
          fieldControl={control}
          setValue={setValue}
          onChange={handleByLawChange}
          value={selectedBylawValue}
          isRequired={false}
          invalidOption={BYLAW_REQUIRED_MESSAGE}
          bylawNull="no bylaw"
          disabled={!bylawsUpdated}
          tooltipMessage={"Select the bylaw that pertains to the compliance issue, or contact the admin if bylaws are not updated."}
        />
      </span>
    </Tooltip>) : <CustomAutoComplete
          fieldName="selectedBylaw"
          id="selectId"
          options={renderedBylaw}
          fieldLabel="Select Bylaw"
          fieldControl={control}
          setValue={setValue}
          onChange={handleByLawChange}
          value={selectedBylawValue}
          isRequired={false}
          invalidOption={BYLAW_REQUIRED_MESSAGE}
          bylawNull="no bylaw"
          tooltipMessage={"Please select the relevant bylaw if applicable"}
        />
      }
    </div>
              {/* <FormLabel>
                Attach Documents
                <Tooltip
                  title={`Please note that uploading a new document will override the existing one`}
                >
                  <IconButton>
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
                <FileUploader
                  handleChange={handleFileChange}
                  name="file"
                  // types={fileTypes}
                  // required
                />
              {fileData ? (
        <p style={{ cursor: "pointer" }} onClick={handleFileDataClick}>
          <FileDownloadIcon /> {fileData}
        </p>
      ) : (
        <p>No file selected</p>
      )}
                {fileError && <p className="text-red-600 text-sm">{fileError}</p>}
              </FormLabel> */}
{byLawDescription ? (
                <Box>
                  <Typography style={{ fontSize: "16px", color: "grey" }}>Bylaw Description</Typography>
                  <CustomInputFieldExpand
                    fieldName="bylawDescription"
                    fieldId="bylawDescription"
                    fieldType="text"
                    fieldControl={control}
                    fieldError={errors}
                    disabled={true}
                    multiline={true}
                    value={byLawDescription}
                    // tooltipMessage={"text"}
                  />
                </Box>
              ):<p/>}
<CustomFileEdit downloadFiles={true} fileData={fileData} handleFileChange={handleFileChange} token={token} file={file}/>

              {/* <CustomInputField
                fieldName="bylawDescription"
                fieldId="bylawDescription"
                fieldType="text"
                fieldLabel="Bylaw Description"
                fieldControl={control}
                fieldError={errors}
                disabled={true}
                value={byLawDescription}
              /> */}
                
            </section>
            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Update" />
              {isLoading && <CustomLoading />}
            </div>
          </CardContent>
        </form>
      </Card>
      <CustomToastContainer />
    </div>
  );
}

export default ComplianceRequestEdit;
