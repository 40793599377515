import React, { useEffect, useMemo, useState } from "react";
import CustomHeading from "../../components/CustomHeading";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import { useNavigate } from "react-router-dom";
import { ListAlt } from "@mui/icons-material";
import { BASE_URL, GET_ALL_ARCHIVED_HO_DOCUMENT } from "../../utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import CustomMaterialWithoutActons from "../../components/CustomMaterialWithoutActons";

function ArchivedDocumentList() {
  const navigate = useNavigate();
  const { token } = useSelector(state => state.hybridhoa);
  const [documentDetails,setDocumentDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const handleViewClick = (selectedRow)=>{
      navigate (`/archiveddocument/${selectedRow.row.original.documentId}`);
  }

  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '' || str === null) return '';
  
    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };
  

  const getDocumentDetails = async () => {
    try {
      setIsLoading(true);
          const storedToken = token

      console.log(storedToken);
      
        const response= await axios.get(`${BASE_URL}${GET_ALL_ARCHIVED_HO_DOCUMENT}`,{
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
              console.log(response.data); 

      if (response.status === 200) {
        const data = response.data;
        setDocumentDetails(data);
        setIsLoading(false);

      } else {
        setIsLoading(false);
        throw new Error('Failed to fetch data');
      }
     
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };



useEffect(()=>{
  getDocumentDetails();
},[])
      
      const archivedDocumentTable = useMemo((rowData) => [
        {
          accessorKey: "serialNo",
          header: "#",
          size: 20,
          Cell: ({row}) => row.index+1
        },
        {
            accessorKey: "category",
            header: "Category",
            size: 20,
            accessorFn: (row) => convertToPascalCase(row.category),
          },
        {
          accessorKey: "createdDateAndTime",
          header: "Created Date",
          accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),
        },
        {
          accessorKey: "uploadedBy",
          header: "Uploaded By",
          accessorFn: (row) => `${row.createdByDetails? row.createdByDetails.firstName : ""} ${row.createdByDetails? row.createdByDetails.lastName : ""} `,
       
        },
        {
            accessorKey: "publishDate",
            header: "Publish Date",
          },
          {
            accessorKey: "status",
            header: "Status",
            size: 20,
            accessorFn: (row) => convertToPascalCase(row.status),
          },
       
          
      ]);

      // const rowActions = [
      //   {
      //     label: "View",
      //     icon: <ListAlt color="primary" />,
      //     click: handleViewClick,
      //   },
       
      // ]
   
     
     
      return (
        <div>
          <CustomHeading title="Archived Documents" />
    
         
          <CustomMaterialWithoutActons
            columns={archivedDocumentTable}
            data={documentDetails}
            // rowActions = {rowActions}
            isLoading ={isLoading}
            onRowClick = {handleViewClick}
         />
        </div>
      );
    }

export default ArchivedDocumentList