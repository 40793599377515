import CustomViewPage from "../../components/CustomViewPage";
import CustomButton from "../../components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import {
  Controller,
  FormProvider,
  useForm,
  useFormState,
} from "react-hook-form";
import CustomInputField from "../../components/CustomInputField";
// import from "../../app.css";
import {
  BYLAW_REQUIRED_MESSAGE,
  CATEGORY_REQUIRED_MESSAGE,
  COMMENTS_REQUIRED_MESSAGE,
  DESCRIPTION_REQUIRED_MESSAGE,
  HOME_ADDRESS_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_HUNDRED,
  MAX_LENGTH_FIVE_THOUSAND,
  MAX_LENGTH_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
  REVIEW_COMMENT_REQUIRED_MESSAGE,
  STATUS_REQUIRED_MESSAGE,
} from "../../utills/ApplicationConstants";
import {
  Autocomplete,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField, Typography, Box, Tooltip, Button ,
} from "@mui/material";
import CustomHeading from "../../components/CustomHeading";
import { FileUploader } from "react-drag-drop-files";
import { useEffect, useMemo, useState } from "react";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import { useSelector } from "react-redux";
import {
  BASE_URL,
  BYLAW,
  COMPLIANCE_LINK,
  COMPLIANCE_REQUEST,
  COMPLIANCE_REQUEST_BY_ID,
  COMPLIANCE_REVIEW,
  GENERAL_COMMENTS,
  GET_ALL_GENERAL_COMMENTS,
} from "../../utills/ApplicationRouting";
import { DeleteOutline, EditNoteOutlined } from '@mui/icons-material';

import axios from "axios";
import { FileDownloadOutlined, Watch } from "@mui/icons-material";
import moment from "moment";
import CustomModel from "../../components/CustomModel";
import { request } from "../../services/AxiosConfig";
import CustomLoading from "../../components/CustomLoading";
import CustomToastContainer from "../../components/CustomToastContainer";
import CustomAutoCompleteEdit from "../../components/CustomAutoCompleteEdit";
import CustomOptions from "../../components/CustomOptions";
import CustomInputFieldExpand from "../../components/CustomInputFieldExpand";
import CustomAutoComplete from "../../components/CustomAutoComplete";
import CustomMaterialWithoutRoutes from "../../components/CustomMaterialWithoutRoutes";
import CustomReviewTable from "../../components/CustomReviewTableWithoutActions";
import CustomViewHeading from "../../components/CustomViewHeading";
import CustomViewWithTooltip from "../../components/CustomViewWithTooltip";
import AttachmentList from "../../components/AttachmentList";
import AttachmentListsView from "../../components/AttachmentListsView";
import CustomLateFees from "../../components/CustomLateFees";
import CustomFile from "../../components/CustomFile";
import CustomViewWithMore from "../../components/CustomViewWithMore";
import CustomViewWithTooltipexpand from "../../components/CustomViewWithTooltipexpand";


function RaisedByHoView() {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },

    setValue,
    watch
  } = useForm();
  const {
    handleSubmit: handleSubmitLinkComments,
    control: controlLinkComments,
    formState: { errors: errorsGeneralComments },
    setValue: setValueLinkComments,
    setError: setErrorLinkComments,
    reset: resetLinkComments,
    watch: watchLinkComments,
    
  } = useForm();
  const {
    handleSubmit: handleSubmitLinkedComments,
    control: controlLinkedComments,
    formState: { errors: errorsGeneraledComments },
    setValue: setValueLinkedComments,
    setError: setErrorLinkedComments,
    reset: resetLinkedComments,
    watch: watchLinkedComments,
  } = useForm();
  // generalComments
  const {
    handleSubmit: handleSubmitGeneral,
    control: controlGeneral,
    formState: { errors: errorsGeneral },
    setValue: setValueGeneral,
    setError: setErrorGeneral,
    reset: resetGeneral,
    watch: watchGeneral,
    clearErrors: clearErrorsGeneral,
  } = useForm();
  const [generalCommentsDataAdd, setGeneralCommentsDataAdd] = useState("");
  const [generalCommentDetails, setGeneralCommentDetails] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [generalCommentsData,setGeneralCommentsData] = useState('');
  const [commentsId,setCommentsId] = useState('');
  const [homeDues,setHomeDues]=useState(0);
  const [editModes, setEditModes] = useState(
    Array.isArray(generalCommentDetails)
      ? Array(generalCommentDetails.length).fill(false)
      : []
  );
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };
  const { token, userLoginDetails, roles ,userDetails,homeId} = useSelector(
    (state) => state.hybridhoa
  );
  console.log("userLoginDetail",userLoginDetails);
  console.log("detailsss",userDetails);
  const HomeLoginId= userLoginDetails?.homeOwnerLoginId;

  //    

  const disabledButtonStyle = {
    opacity: 0.5, // Reduce opacity to make it appear disabled
    cursor: "not-allowed",
    // cursor: 'not-allowed',
    color: "red", // Change cursor to not-allowed
    /* Add any other styles to visually indicate disabled state */
  };
  const { isValid } = useFormState({ control });
  const navigate = useNavigate();
  const [file, setFile] = useState([]);
  const [linkedFile,setLinkedFile]=useState([])
  const [homeOwnerDetailsId, setHomeOwnerDetailsId] = useState("");
  const fileTypes = ["JPG", "PNG", "JPEG", "PDF"];
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedLinkStatus,setSelectedLinkStatus]=useState("")
  const [fileError, setFileError] = useState("");

  
  const [status, setStatus] = useState("");
  const [complianceRequestData, setComplianceRequestData] = useState([]);
  const [byLawIds, setByLawIds] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [fileUrl, setFileUrl] = useState([]);
  const [reviewComments, setReviewComments] = useState([]);
  const [createdDateAndTimes, setCreatedDateAndTimes] = useState("");
  const [fileUrls, setFileUrls] = useState([]);
  const [linkedcreatedDateAndTimes, setLinkedCreatedDateAndTimes] = useState("");

  const [createdDate, setCreatedDate] = useState("");

  const [homeOwnerLoginId, setHomeOwnerLoginId] = useState("");
  const [linkedlistData, setlinkedlistData] = useState({});
  const [linkedStatus,setLinkedStatus]=useState("")
  const [linkedReview,setLinkedReview]=useState([])
  const [fineDetails,setFineDetails]=useState([])
  const [bylawTopic, setBylawTopic] = useState("");
  const [byLawDetails, setByLawDetails] = useState([]);
  const [byLawDescription, setByLawDescription] = useState("");
  const [categoryDetailsId, setCategoryDetailsId] = useState("");
  const [linkCr, setLinkCr] = useState(false);
  const [linkComplainceId,setLinkComplainceId]=useState("")
  const [role, setRole] = useState("");
  const [open, setOpen] = useState(false);
  const [filePdf,setFilePdf]=useState("");
  const [auditBy,setAuditBy]=useState("");
  const [reviewLink, setReviewLink] = useState("");
  const [linkFirstName, setLinkFirstName] = useState("");
  const [byLawLink, setBylawLink] = useState("");
  const [viewByLaw, setViewByLaw] = useState("");
  const [showFullDescription, setShowFullDescription] = useState(false); 
  const [fineApply,setFineApply] = useState("");

  const [selectedBylawValue, setSelectedBylawValue] = useState(null);
  const [isBylawFieldEmpty, setIsBylawFieldEmpty] = useState(false);
  const handleLinkOpen = () => {
    console.log("working ");
    setOpen(true);
  };
  const { complainceId } = useParams();
  const [review, setReview] = useState("");
  const [homeid, setHomeid] = useState("");
  const [linkedReviewChange,setLinkedReviewChange]=useState("");
  const [expandedRows, setExpandedRows] = useState({});

  const toggleDescription = (rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  console.log("error", errors);

 
  const handleClick = () => {
    navigate(-1);
  };

  const handleFileChange = (newFiles) => {
    setFile(newFiles);
  };
  const handleLinkedFileChange = (newFiles) => {
    setLinkedFile(newFiles);
  };
  const handleSelectChange= event =>{
    
    console.log("Selected value:", event.target.value);
    setStatus(event.target.value)
    console.log(event.target.Value);
    console.log("statusa",status);
  }
  const handleLinkedSelectChange=(event)=>{
    const selectedValue = event.target.value;
    setLinkedStatus(selectedValue); // Updates local component state
    // Now, you also need to inform React Hook Form of this change:
    // Assume `field.onChange` is passed to this handler from the render prop of Controller
    field.onChange(selectedValue);
  }

useEffect(()=>{
  console.log("status",status);
},[status])
//**Get linking  complaice */
const getComplianceRequestDetailsLink = async () => {
  try {
    const storedToken = token;
    const details = userLoginDetails;
    const detailsId = details.homeOwnerLoginId;
    console.log(detailsId);
    setHomeOwnerLoginId(detailsId);
    console.log(storedToken);

    const response = await axios.get(
      `${BASE_URL}${COMPLIANCE_LINK}/${complainceId}`,
      {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      }
    );
    console.log(response);

    if (response.status === 200) {
      const data = response.data;
      setlinkedlistData(data);
      setLinkedStatus(data.status)
      setLinkedReview(data.reviewComments)
      if (data.attachments && data.attachments.length > 0) {
        setFileUrls(data.attachments)
      }else{
        setFileUrls([])
      }
      setLinkComplainceId(data.complainceId);
      setSelectedLinkStatus(data.status)
      const formattedDate = moment(data.createdDateAndTime).format(
        "MM/DD/YYYY hh:mm A"
      );
      setCreatedDate(formattedDate);
      console.log(data);
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    // Handle error
    console.error("Error fetching data:", error);
  }
};
useEffect(() => {
  getComplianceRequestDetailsLink();
}, [complainceId]);

const getComplianceFineById = async () => {
  try {
    setIsLoading(true);
    const storedToken = token;
    

    const response = await axios.get(
      `${BASE_URL}${COMPLIANCE_REQUEST_BY_ID}/${linkComplainceId}`,
      {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      }
    );
    console.log(response.data);

    if (response.status === 200) {
      setIsLoading(false)
      const lateFees = response.data.crfinehistory;
      const totalDueAmount = lateFees
      // .filter(item => item.status === 'PENDING')
      .reduce((accumulator, current) => accumulator + (+current['dueAmount']), 0);
console.log(totalDueAmount);
setHomeDues(totalDueAmount)
const pendingLateFees = lateFees.filter(fee => fee.status === 'PENDING');
  setFineDetails(pendingLateFees);
    }
    
  } catch (error) {
    // Handle error
    console.error("Error fetching data:", error);
  }
};
useEffect(() => {
  getComplianceFineById();
}, [linkComplainceId]);

//**to link complaice */

//**Getting view  complaice */
  const getComplianceRequestDetailsById = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;
      const details = userLoginDetails;
      const detailsId = details.homeOwnerLoginId;
      console.log(detailsId);
      setHomeOwnerLoginId(detailsId);
      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${COMPLIANCE_REQUEST_BY_ID}/${complainceId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data.compliance;
        
        setIsLoading(false);

        setHomeOwnerDetailsId(data.homeId);
        setCategoryDetailsId(data.categoryDetails.categoryId);
       
        const reviewtable = data.reviewComments;
        setReviewComments(reviewtable);
        setStatus(data.status);
        setSelectedStatus(data.status);
        setValue("status", data.status);
        setRole(data.role);
        setHomeid(data.homeId);
        setReviewLink(data.description);
        setComplianceRequestData(data);
        setFileUrl(data.attachments);
        setFineApply(data.isFineApplicable)
         setAuditBy(data.auditBy)
        setFilePdf(data.fileUrl)

        setByLawIds(data.bylawDetails?.byLawId);
    
        console.log(data.description,"testCaseDescription");
        // setViewByLaw(data.bylawDetails);
        setViewByLaw(data.bylawDetails?.topic);
        // setCreatedDateAndTime(data.createdDateAndTime)
        const formattedDate = moment(data.createdDateAndTime).format(
          "MM/DD/YYYY hh:mm A"
        );
        setCreatedDateAndTimes(formattedDate);
       
        const matchingByLaw = byLawDetails.find(
          (byLaw) => byLaw.byLawId === data?.bylawId
        );
        console.log("matchingByLaw", matchingByLaw);
        console.log("byLawDetails", byLawDetails);

        if (matchingByLaw) {
          setBylawTopic(matchingByLaw.topic);
          setValue("selectedBylaw", matchingByLaw?.byLawId);
          setByLawDescription(matchingByLaw.description);
          setSelectedBylawValue({
            label: matchingByLaw.topic,
            value: matchingByLaw.byLawId,
          });
        } else {
          setValue("selectedBylaw", "");
          setByLawDescription("");
          setSelectedBylawValue(null);

        }
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getComplianceRequestDetailsById();
  }, [byLawDetails, homeOwnerDetailsId,complainceId]);

  
  const handleLinkReviewChange = (event) => {
    setReviewLink(event.target.value);
  };

  useEffect(()=>{
    console.log("reviewwww",reviewLink);
  },[reviewLink])
  const handleAddClose = (status) => {
    if (status == 200) {
      setOpen(false);
      setFile([])
      setFileError("")
      resetLinkComments()
    }
  };
  const handleIconClose = () => {
    setOpen(false);
    setFile([])
    setFileError("")
    resetLinkComments()
  };
   
  //**Get Bylaw   */
  const getBylaw = async () => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${BYLAW}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        console.log("byLaw data", data);
        setByLawDetails(data);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getBylaw();
  }, []);
 
  const handleByLawChange = (event, newValue) => {
    if (newValue && newValue.value !== undefined && newValue.value !== null) {
      const selectedByLawId = newValue.value;
      setValue("selectedBylaw", selectedByLawId);
      const selectedByLawDescription = byLawDetails.find(
        (byLaw) => byLaw.byLawId === selectedByLawId
      )?.description;

      // setSelectedByLaw(selectedByLawId);
      setByLawDescription(selectedByLawDescription);
      setIsBylawFieldEmpty(false);

      // Update the state consistently using setSelectedBylawValue
      setSelectedBylawValue(newValue);
    } else {
      // Clear the state values when no bylaw is selected
      setValue("selectedBylaw", null);
      setByLawDescription("");
      // setSelectedByLaw(null);
      setIsBylawFieldEmpty(true);

      // Update the state consistently using setSelectedBylawValue
      setSelectedBylawValue(null);
    }
  };
  const renderedBylaw = byLawDetails.map((ByLaw, index) => ({
    topic: ByLaw.topic,
    value: ByLaw.byLawId,
    key: index,
  }));

  useEffect(() => {
    const selectedBylaw = watch("selectedBylaw");

    
      if (!selectedBylaw || selectedBylaw === "") {
        setByLawDescription("");
        setIsBylawFieldEmpty(true);
      } else {
        // Find the matching byLaw from byLawDetails
        const matchingByLaw = byLawDetails.find(
          (byLaw) => byLaw.byLawId === selectedBylaw
        );

        if (matchingByLaw) {
          setByLawDescription(matchingByLaw.description);
          setIsBylawFieldEmpty(false);
        } else {
          // Handle the case where no matching byLaw is found
          setByLawDescription("");
          setIsBylawFieldEmpty(true);
        }
      }
  }, [watch("selectedBylaw"), byLawDetails]);
 
  const onSubmitLink = (data) => {
    console.log("test modal");
    if (Array.isArray(file)) {
      const totalFileSize = file.reduce((acc, file) => acc + file.size, 0);
      const maxSizeInMB = 10;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    
      if (totalFileSize > maxSizeInBytes) {
        // setFileError(`Total file size should not exceed ${maxSizeInMB}MB.`);
        return;
      }
    
      // Check file types
      const allowedFileTypes = ["image/jpg", "image/png", "image/jpeg", "application/pdf"];
      const invalidFiles = file.filter(file => !allowedFileTypes.includes(file.type));
    
      if (invalidFiles.length > 0) {
        // setFileError("Some files have invalid file types. Allowed types are JPG, PNG, JPEG, PDF.");
        return;
      }
  }

    

   
    if (typeof reviewLink !== 'string' || reviewLink.trim() === '') {
      setErrorLinkComments("reviewComment", {
        type: "manual",
        message: DESCRIPTION_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    } 
    setIsLoading(true);
      const formData = new FormData();
    formData.append("homeId", homeOwnerDetailsId);
    formData.append("description", reviewLink);
    // formData.append("file", file);
    linkedFile.forEach((file) => {
      formData.append('files', file);
      })
    formData.append("role", roles);
    formData.append("comments",review)
    formData.append("bylawId", byLawIds);
    formData.append("parentId", complainceId);
    formData.append("category", categoryDetailsId);
    formData.append("createdHomeId",homeId)
   
    const storedToken = token;
    const config = {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    };
    if (typeof reviewLink !== 'string' || reviewLink.trim() === '') {
      setErrorLinkComments("reviewComment", {
        type: "manual",
        message: DESCRIPTION_REQUIRED_MESSAGE,
      });
      return;
    }
    request({
      url: COMPLIANCE_LINK,
      method: "post",
      data: formData,
      headers: config.headers,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          setIsLoading(false)
          handleAddClose(res.status);
          getComplianceRequestDetailsById();
         
          // getJobCategoryDetails();
          // reset();
          resetLinkComments();
          setTimeout(()=>{
            navigate('/raisedbyho')

          },2000)
        }else{
          setIsLoading(false)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error.response);
        if (error.response) {
          setIsLoading(false)
          console.log("error.response.status" + error.response.status);
        }
      });
    console.log(data.editjobCategory);
  };
  //**before linking complaice */
  const onSubmit = (data) => {
    console.log(data.selectedBylaw);
    const totalFileSize = file.reduce((acc, file) => acc + file.size, 0);
    const maxSizeInMB = 10;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
  
    if (totalFileSize > maxSizeInBytes) {
      // setFileError(`Total file size should not exceed ${maxSizeInMB}MB.`);
      return;
    }
  
    // Check file types
    const allowedFileTypes = ["image/jpg", "image/png", "image/jpeg", "application/pdf"];
    const invalidFiles = file.filter(file => !allowedFileTypes.includes(file.type));
  
    if (invalidFiles.length > 0) {
      // setFileError("Some files have invalid file types. Allowed types are JPG, PNG, JPEG, PDF.");
      return;
    }
    const formData = new FormData();
formData.append('status', status);
formData.append('comments', review);
formData.append('bylawId', data.selectedBylaw);
// formData.append('byLawDescription', byLawDescription);
// formData.append('homeid', homeid);
file.forEach((file) => {
  formData.append('files', file);
  });
formData.append('role', roles[0]);
setIsLoading(true);

    const storedToken = token;
    const config = {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    };

    request({
      url: `${COMPLIANCE_REVIEW}/${complainceId}`,
      method: "put",
      data: formData,
      headers: config.headers,
    })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          setIsLoading(false);
          getComplianceRequestDetailsById()
          setReview('');

          // navigate('/raisedbyho');
          setTimeout(()=>{
            setIsLoading(false);

               navigate('/raisedbyho')
          },3000)
        } else {
          throw new Error("Failed to fetch data");

        }
      })
      .catch((error) => {
        setIsLoading(false);

        console.log(error);
      });
  };
  //**after linking complaice */
  const onSubmitLinked = (data) => {
    console.log(data.selectedBylaw);
    const totalFileSize = file.reduce((acc, file) => acc + file.size, 0);
    const maxSizeInMB = 10;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
  
    if (totalFileSize > maxSizeInBytes) {
      // setFileError(`Total file size should not exceed ${maxSizeInMB}MB.`);
      return;
    }
  
    // Check file types
    const allowedFileTypes = ["image/jpg", "image/png", "image/jpeg", "application/pdf"];
    const invalidFiles = file.filter(file => !allowedFileTypes.includes(file.type));
  
    if (invalidFiles.length > 0) {
      // setFileError("Some files have invalid file types. Allowed types are JPG, PNG, JPEG, PDF.");
      return;
    }
    if (!linkedStatus) {
      setError("linkedStatus", {
        type: "manual",
        message: STATUS_REQUIRED_MESSAGE ,
      });
      return;
    }
    setIsLoading(true)
    const formData = new FormData();
formData.append('status', linkedStatus);
formData.append('comments', linkedReviewChange);
formData.append('bylawId', byLawIds);
// formData.append('byLawDescription', byLawDescription);
// formData.append('homeid', homeid);
file.forEach((file) => {
  formData.append('files', file);
  });
formData.append('role', roles[0]);

    const storedToken = token;
    const config = {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    };

    request({
      url: `${COMPLIANCE_REVIEW}/${linkComplainceId}`,
      method: "put",
      data: formData,
      headers: config.headers,
    })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          setIsLoading(false);
          getComplianceRequestDetailsById()
          getComplianceRequestDetailsLink()
          setReview('');
          // navigate('/raisedbyho');
          setTimeout(()=>{
               navigate('/raisedbyho')
          },3000)
        } else {
          throw new Error("Failed to fetch data");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const addAction = (
    <CustomButton
      fieldType="submits"
      buttonName="Update"
      click={handleAddClose}
    />
  );
  const addModel = (
    <>
      <form
        onSubmit={handleSubmitLinkComments(onSubmitLink)}
        className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <div style={{ padding: "13px" }}>
            <b class="text-sm font-sans break-words">Created To :</b>
            <span class="p-2">
              {complianceRequestData.createdToDetails?.firstName || ""}{" "}
              {complianceRequestData.createdToDetails?.lastName || ""}
            </span>
          </div>
          <Divider class="auto"></Divider>
          <div style={{ padding: "13px" }}>
            <b class="text-sm font-sans break-words">Address :</b>
            <span class="p-2">
    {complianceRequestData.address && complianceRequestData.address.address ?
        (complianceRequestData.address.address.length > 20 ?
            `${complianceRequestData.address.address.substring(0, 30)}...` :
            complianceRequestData.address.address
        ) : ""
    }
</span>
          </div>
          <Divider></Divider>
          <div style={{ padding: "13px" }}>
            <b class="text-sm font-sans break-words">
              ByLaws Topic :</b>
              <span class="p-2 overflow-hidden overflow-wrap">
                {/* {complianceRequestData.bylawDetails?.topic.length > 20 ?
        `${complianceRequestData.bylawDetails.topic.substring(0, 30)}...` :
        complianceRequestData.bylawDetails.topic} */}
         {complianceRequestData.bylawDetails && complianceRequestData.bylawDetails.topic || ""
        // (complianceRequestData.bylawDetails.topic.length > 20 ?
        //     `${complianceRequestData.bylawDetails.topic.substring(0, 30)}...` :
        //     complianceRequestData.bylawDetails.topic
        // ) : ""
    }
</span>
            
          </div>
          <Divider></Divider>
          <CustomFile file={linkedFile} handleFileChange={handleLinkedFileChange}
           token={token} tooltipMessage={"Upload supporting documents such as lease agreement, identity proof, etc."} />
          {/* <FormLabel>
            Attach Documents
            <FileUploader
              handleChange={handleFileChange}
              name="file"
              types={fileTypes}
             
            />
            {file ? <p>Selected File: {file.name}</p> : <p>No file selected</p>}
            {fileError && <p className="text-red-600 text-sm">{fileError}</p>}

          </FormLabel> */}
          <CustomInputField
            fieldName="reviewComment"
            // requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
            minLength={2}
            minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
            maxLength={5000}
            maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
            fieldId="reviewComment"
            fieldType="text"
            fieldLabel="Description"
            fieldControl={controlLinkComments}
            fieldError={errorsGeneralComments}
            fieldRequiredIcon="*"
            multiline={true}
            numberOfRows={4}
            value={reviewLink}
            onInput={handleLinkReviewChange}
          />
        </DialogContent>
        <div class="flex justify-center items-center p-3 space-x-5">
          <CustomButton fieldType="submit" buttonName="Update" />
        </div>
      </form>
    </>
  );
  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '') return '';

    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};
  const handleToggleDescriptionLinked = () => {
    setShowFullDescription(!showFullDescription);
  };
  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  // console.log(complianceRequestData);
  const renderDescription = () => {
    console.log("inside render ");
    if (complianceRequestData.description && !showFullDescription) {
    console.log("inside render ",complianceRequestData.description);
  
      return (
        <>
        
          {complianceRequestData.description.slice(0, 250)}
          {complianceRequestData.description.length > 250 && (
            <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Read More</Button>
          )}
        </>
      );
    } else {
    console.log("inside render else ",complianceRequestData.description);
  
      return (
        <>
          {complianceRequestData.description}
          <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Less</Button>
        </>
      );
    }
  };
  const linkedrenderDescription = () => {
    console.log("inside render ");
    if (linkedlistData.description && !showFullDescription) {
    console.log("inside render ",linkedlistData.description);
  
      return (
        <>
        
          {linkedlistData.description.slice(0, 250)}
          {linkedlistData.description.length > 250 && (
            <Button onClick={handleToggleDescriptionLinked} style={{fontSize:"12px"}}>Read More</Button>
          )}
        </>
      );
    } else {
    console.log("inside render else ",linkedlistData.description);
  
      return (
        <>
          {linkedlistData.description}
          <Button onClick={handleToggleDescriptionLinked} style={{fontSize:"12px"}}>Less</Button>
        </>
      );
    }
  };

useEffect(()=>{
  console.log("dateandtime",moment().diff(moment(complianceRequestData.createdDateAndTime), 'days') >= 15);
},[complianceRequestData])

  const fileList = fileUrl.map(file => ({
    docname: file.file.substring(12), // Assuming file name is the document name
    fileUrl: file.file, // Assuming file location is the file URL
    documentnamed: file.file, // Assuming file name is the document name
    uploadedTimes:  moment(file.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"), // Assuming uploadedTimes is available
  }));
  const data = [
    {
      groupName: 'Compliance Request Details',
      items: [
        { label: 'Compliance Request Id', data: `${ complianceRequestData?.crId || ""}`, text: 'Unique identifier for the compliance request' },
        { label: 'Created Date Time', data: `${moment(complianceRequestData?.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss A") || ""}` ,text: 'The date and time when the compliance request was created'},
               { label: 'Category', data: `${complianceRequestData.categoryDetails?.category || ""}` ,text: 'The category or subject of the compliance request'},
               
                 
                   
   { label: 'Description', data: renderDescription() ,text: 'Detailed description of the compliance issue'},
   { label: 'ByLaws Topic', data: `${complianceRequestData.bylawDetails?.topic || ""}` ,text: 'The topic of the bylaw related to the compliance issue'},
   { label: 'ByLaws Description', data: `${complianceRequestData.bylawDetails?.description || ""}`, text: 'The description of the bylaw related to the compliance issue' },

                     

      ],
    },
    {
      groupName: 'Audit and status Details ',
      items: [
       
                  { label: 'Audit Date Time', data: `${moment(complianceRequestData?.auditDateAndTime).format(
                     "MM/DD/YYYY hh:mm:ss A") || ""}` ,text: 'The date and time of the last audit.'},
                     { label: 'Status', data: `${convertToPascalCase(status)}` ,text: 'Current status of the compliance request (e.g., Open, Closed)'},
                     
                    //  ...(complianceRequestData?.fineApply && moment().diff(moment(complianceRequestData.createdDateAndTime), 'days') >= 15 && HomeLoginId !== complianceRequestData.createdToDetails?.homeOwnerLoginId ? [
                    //      {label: 'Fine Details', data: <CustomLateFees groupId={complianceRequestData.createdToDetails?.groupId} homeOwnerDetailsId={complianceRequestData.createdToDetails?.homeOwnerDetailsId} homeOwnerLoginId={complianceRequestData.createdToDetails?.homeOwnerLoginId}  homeId={complianceRequestData.address?.homeId}/>}
                    //    ] : []),
                    // ...(moment().diff(moment(complianceRequestData.createdDateAndTime), 'days') >= 15 && HomeLoginId !== complianceRequestData.createdToDetails?.homeOwnerLoginId   ? [
                    //   {label: 'Fine Details', data: <CustomLateFees groupId={complianceRequestData.createdToDetails?.groupId} homeOwnerDetailsId={complianceRequestData.createdToDetails?.homeOwnerDetailsId} homeOwnerLoginId={complianceRequestData.createdToDetails?.homeOwnerLoginId}  homeId={complianceRequestData.address?.homeId} complainceId={complainceId}/>}
                    // ] : []),

      ],
    },
    {
      groupName: 'Attachment Details',
      items: [
       
                  
                     { label:'Attachment', data: (
                      <AttachmentListsView fileList={fileList} />

                    ), text: 'List of Document attached to compliance request'},

      ],
    },
    {
      groupName: 'Created By Details',
      items: [
        { label: 'Firstname', data:`${complianceRequestData.createdByDetails?.firstName || ""}`, text: 'First name of the person  whom created compliance request '},
        { label: 'Lastname', data:`${complianceRequestData.createdByDetails?.lastName || ""}`, text: 'Last name of the person who created compliance request' },
    
        { label: 'Email', data:`${complianceRequestData.createdByDetails?.email || ""}`, text: 'Email address of the person who created compliance request ' },
        { label: 'Address', data:`${complianceRequestData?.createdByHomeAddress || ""}`, text: 'Home address of the person who created compliance request ' },
        // { label: 'Address for communication', data: `${complianceRequestData.createdByDetails?.addressForCommunication || ""}`, text: 'Address for communication of the person who created compliance request' },

        { label: 'Contact', data:`${complianceRequestData.createdByDetails?.contact || ""}`, text: 'Contact number of the person Who created compliance request ' },

      ],
    },
    {
      groupName: 'Created To Details',
      items: [
        { label: 'Firstname', data:`${complianceRequestData.createdToDetails?.firstName || ""}`, text: 'First name of the person or entity to whom the compliance request was created'},
        { label: 'Lastname', data:`${complianceRequestData.createdToDetails?.lastName || ""}`, text: 'Last name of the person or entity to whom the compliance request was created' },
    
        { label: 'Email', data:`${complianceRequestData.createdToDetails?.email || ""}`, text: 'Email address of the person or entity to whom the compliance request was created' },
        { label: 'Address', data:`${complianceRequestData.address?.address || ""}`, text: 'Home address of the person or entity to whom the compliance request was created' },
        // { label: 'Address for communication', data: `${complianceRequestData.createdToDetails?.addressForCommunication || ""}`, 
        // text: 'Address ForCommunication to whom the compliance request was created' },

        { label: 'Contact', data:`${complianceRequestData.createdToDetails?.contact || ""}`, text: 'Contact number of the person or entity to whom the compliance request was created.' },

      ],
    },
  ];

  
  // const linkedData = {
  //   pageTitle: "Linked Compliance request",
  //   buttons: [],
  //   content: [
  //     {
  //       topic: "Created By ",
  //       description: `${linkedlistData?.createdBy || ""}` 
        
  //     },
  //     {
  //       topic :'Created Date Time',
  //       description : moment(linkedlistData?.createdDateAndTime || '').format("MM/DD/YYYY hh:mm:ss a")
  //     },
  //     {
  //       topic: "Category",
  //       description: `${linkedlistData.categoryDetails?.category}`
  //     },
  //     {
  //       topic: "ByLaws Topic",
  //       description: `${linkedlistData.bylawDetails?.topic || ""}`,
  //     },
  //     {
  //       topic :'Audit Date Time ',
  //       description : moment(linkedlistData?.auditDateAndTime).format("MM/DD/YYYY hh:mm:ss a")
  //     },
  //     {
  //       topic: "Status",
  //       description: `${linkedlistData?.status || ""}`,
  //     },
  //     {
  //       topic: "Created To",
  //       description: `${linkedlistData.createdToDetails?.firstName || ""} ${
  //         linkedlistData.createdToDetails?.lastName || ""
  //       }`,
  //     },
  //     {
  //       topic: "Address",
  //       description: `${linkedlistData.address?.address || ""}`,
  //     },
  //     {
  //       topic: "Description",
  //       description: `${linkedlistData?.description || ""}`,
  //     },
  //     {
  //       topic: "Attachments",
  //       component: "AttachmentList",
  //       props: {
  //         fileUrl: fileUrls,
  //         documentName: fileUrls,
  //         docname: fileUrls,
  //         uploadedTimes: createdDate,
  //       },
  //     },
  //     {
  //       topic: "Is Fine Applicable",
  //       description: linkedlistData.isFineApplicable? 'true' : 'false',
  //     },
  //   ],
  // };

  const viewCrFineTable = useMemo((rowData) => [
    {
      accessorKey: "dueDate",
      header: "Fine Added Date",
      accessorFn: (row) => moment(row.dueDate).format("MM/DD/YYYY"),
    },
    {
      accessorKey: "dueAmount",
      header: "Amount",
      size: 20,
    },
    // {
    //   accessorKey: "status",
    //   header: "Status",
    //   size: 20,
    //   Cell: ({ row }) => {
    //     const status = row.original.status;
    //     return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    //   },
    // },
    {
    accessorFn: row => row.description,
    id: 'description',
    header: 'Description',
    Cell: ({ row }) => {
      const comments = row.original.description;
      const isExpanded = expandedRows[row.id];
      const shouldShowMoreButton = comments && comments.length > 28;

      return (
        <div>
          {isExpanded ? comments : comments.slice(0, 28) + (shouldShowMoreButton ? '...' : '')}
          {shouldShowMoreButton && (
            <Button
              size="small"
              onClick={() => toggleDescription(row.id)}
              style={{ marginLeft: '10px' }}
            >
              {isExpanded ? 'Show Less' : 'Show More'}
            </Button>
          )}
        </div>
      );
    },
  }
  ]);

  const renderRole = (role) => {
    switch (role) {
      case 'ROLE_ARC':
        return 'ARC';
      case 'ROLE_BM':
        return 'Board Member';
        case 'ROLE_MC':
          return 'Management Company';
      default:
        return role; // or return null if you don't want to display anything for other roles
    }
  };

  const fileListLink = fileUrls.map(file => ({
    docname: file.file.substring(12), // Assuming file name is the document name
    fileUrl: file.file, // Assuming file location is the file URL
    documentnamed: file.file, // Assuming file name is the document name
    uploadedTimes:  moment(file.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"), // Assuming uploadedTimes is available
  }));
  const linkedData = [
    {
      groupName: ' Compliance Request Details',
      items: [
        { label: 'Compliance Request Id', data: `${ linkedlistData?.crId || ""}`, text: 'Unique identifier for the compliance request' },
        // { label: 'Created Date Time', data: `${moment(complianceRequestData?.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss A") || ""}` ,text: 'Tooltip text 1'},
               { label: 'Category', data: `${linkedlistData.categoryDetails?.category || ""}` ,text: 'The category or subject of the compliance request'},
               { label: 'Description', data: linkedrenderDescription() ,text: 'Detailed description of the compliance issue'},
                  
               { label: 'ByLaws Topic', data: `${linkedlistData.bylawDetails?.topic || ""}` ,text: 'The topic of the bylaw related to the compliance issue'},
      ],
    },
   
    {
      groupName: ' Audit and Status Details',
      items: [
               { label: 'AuditDateAndTime', data:`${moment(linkedlistData?.auditDateAndTime).format("MM/DD/YYYY hh:mm:ss A") || ""}`, text: 'The date and time of the last audit.'},

                { label: 'Status', data: `${convertToPascalCase(linkedStatus)}` ,text: 'Current status of the compliance request (e.g., Open, Closed)'},
               
                 

      ],
    },
    // {
    //   groupName: 'Compliance Request Fine Details',
    //   items: [
    //     ...(moment().diff(moment(complianceRequestData?.createdDateAndTime), 'days') >= 30 &&
    //       HomeLoginId !== complianceRequestData.createdToDetails?.homeOwnerLoginId &&
    //       (complianceRequestData.status === "PENDING" || complianceRequestData.status === "ACKNOWLEDGED")
    //       ? [
    //         {
    //           label: 'Fine Details',
    //           data: <CustomLateFees
    //             groupId={complianceRequestData.createdToDetails?.groupId}
    //             homeOwnerDetailsId={complianceRequestData.createdToDetails?.homeOwnerDetailsId}
    //             homeOwnerLoginId={complianceRequestData.createdToDetails?.homeOwnerLoginId}
    //             homeId={complianceRequestData.address?.homeId}
    //             id={complainceId}
    //           />
    //         }
    //       ]
    //       : []
    //     ),
    //     ...(homeDues !== 0
    //       ? [
    //         {
    //           label: "Late Fees",
    //           data: `${homeDues}`,
    //           text: "The late fees are for raised compliance that has not been resolved or responded to by a particular homeowner."
    //         }
    //       ]
    //       : []
    //     ),
    //   ],
    // },
    
    {
      groupName: 'Linked Details',
      items: [
        { label: 'CreatedBy', data:`${renderRole(linkedlistData?.role)}`, text: ' The role of the person linked to the compliance '},
        { label: 'CreatedDateAndTime', data:`${moment(linkedlistData?.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss A") || ""}`, text: 'The date and time when the compliance request was created'},
],
    },
    {
      groupName: 'Created To Details',
      items: [
        { label: 'Firstname', data:`${linkedlistData.createdToDetails?.firstName || ""}`, text: 'First name of the person or entity to whom the compliance request was created'},
        { label: 'Lastname', data:`${linkedlistData.createdToDetails?.lastName || ""}`, text: 'Last name of the person or entity to whom the compliance request was created' },
    
        { label: 'Email', data:`${linkedlistData.createdToDetails?.email || ""}`, text: 'Email address of the person or entity to whom the compliance request was created' },
        { label: 'Address', data:`${linkedlistData.address?.address || ""}`, text: 'Home address of the person or entity to whom the compliance request was created' },
        { label: 'Address for communication', data: `${complianceRequestData.createdToDetails?.addressForCommunication || ""}`, 
        text: 'Address ForCommunication to whom the compliance request was created' },
        { label: 'Contact', data:`${linkedlistData.createdToDetails?.contact || ""}`, text: 'Contact number of the person or entity to whom the compliance request was created.' },
        
      ],
    },
    {groupName:"Attachment Details",
      items:[
        { label:'Attachment', data: (
          <AttachmentListsView fileList={fileListLink} />

        ), text: 'List of Document attached to compliance request'},
      ]
    }
  ];
  const crFineTable=()=>{
    return <CustomMaterialWithoutRoutes columns={viewCrFineTable} data={fineDetails} />
  }
  if (
    moment().diff(moment(linkedlistData?.createdDateAndTime), 'days') >= 30 &&
    HomeLoginId !== linkedlistData.createdToDetails?.homeOwnerLoginId &&
    (( linkedlistData.status === "ACKNOWLEDGED" && linkedlistData.isLinked) || (linkedlistData.status === "NOT RESOLVED" && linkedlistData.isLinked) ||(linkedlistData.isLinked && linkedlistData.status === "PENDING")) ||
    homeDues !== 0
  ) {
    linkedData.splice(5, 0, {
        groupName: 'Compliance Request Fine Details',
        items: [
            ...(moment().diff(moment(linkedlistData?.createdDateAndTime), 'days') >= 30 &&
                HomeLoginId !== linkedlistData.createdToDetails?.homeOwnerLoginId && ((linkedlistData.status === "ACKNOWLEDGED" && linkedlistData.isLinked) || (linkedlistData.status === "NOT RESOLVED" && linkedlistData.isLinked) || (linkedlistData.isLinked && linkedlistData.status === "PENDING"))
                ? [
                  { label: 'Compliance Fine Applied', data: `${linkedlistData.isFineApplicable? 'Yes' : 'No'}` ,text: 'Indicates if a fine is applicable for this compliance request.'}, 
                    {
                        label: 'Apply Fine',
                        data: <CustomLateFees
                            groupId={linkedlistData.createdToDetails?.groupId}
                            homeOwnerDetailsId={linkedlistData.createdToDetails?.homeOwnerDetailsId}
                            homeOwnerLoginId={linkedlistData.createdToDetails?.homeOwnerLoginId}
                            homeId={linkedlistData.address?.homeId}
                            complainceId={linkedlistData?.complainceId}
                            getData={getComplianceFineById}
                        />
                    }
                ]
                : []
            ),
            ...(homeDues !== 0
                ? [
                    {
                        label: "Compliance Request Fine",
                        data: `${parseFloat(homeDues).toFixed(2)}`,
                        text: "The Compliance Request Fine is for raised compliance that has not been resolved or responded to by a particular homeowner."
                    },{
                      label:"Compliance Request Fine History",
                      data:crFineTable()
                    }
                ]
                : []
            ),
           
        ],
    });
  }

  
  const handleReviewChange = (event) => {
    setReview(event.target.value);
  };
  const handleLinkedReviewChange=(event)=>{
    setLinkedReviewChange(event.target.value)
  }

  

  const viewDataTable = useMemo((rowData) => [
    {
      accessorKey: "serial No",
      header: "Serial No",
      size: 20,
      Cell: ({ row }) => row.index + 1,
    },
    // {
    //   accessorKey: "assignedDateTime",
    //   header: "Assigned DateTime",
    //   accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
    // },
    {
      accessorKey: "assignedToName",
      header: "Name",
      size: 20,
    },
    {
      accessorKey: "status",
      header: "Review Status",
      size: 20,
    },
    {
      accessorKey: "reviewDateTime",
      header: "Review DateTime",

      accessorFn: (row) => moment(row.auditDateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
    },
    // {
    //   accessorKey: "comments",
    //   header: "Review Comments",
    //   size: 20,
    //   accessorFn: (row) => {
    //     const comments = row && row.comments;
    //     if (comments && comments.length > 28) {
    //       return comments.slice(0, 28) + "...";
    //     }
    //     return comments;
    //   }
    // },
  ]);

  const viewDataLinkedComplaince = useMemo((rowData) => [
    {
      accessorKey: "serialNo",
      header: "Serial No.",
      size: 20,
      Cell: ({ row }) => row.index + 1,
    },
    // {
    //   accessorKey: "assignedDateTime",
    //   header: "Assigned DateTime",
    //   accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
    // },
    {
      accessorKey: "assignedToName",
      header: "name",
      size: 20,
    },
    {
      accessorKey: "status",
      header: "Review OutCome",
      size: 20,
    },
    {
      accessorKey: "reviewDateTime",
      header: "Review DateTime",

      accessorFn: (row) => moment(row.auditDateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
    },
    // {
    //   accessorKey: "comments",
    //   header: "Review Comments",
    //   size: 20,
    //   accessorFn: (row) => {
    //     const comments = row && row.comments;
    //     if (comments && comments.length > 28) {
    //       return comments.slice(0, 28) + "...";
    //     }
    //     return comments;
    //   }
    // },
  ]);
  // generalComments
  const handleEditClick = (index, generalCommentsId) => {
    console.log("Edit button clicked", index, generalCommentsId);
    setEditModes((prevModes) =>
      prevModes.map((mode, i) => (i === index ? true : mode))
    );
    setCommentsId(generalCommentsId);
    console.log("CommentsId set:", generalCommentsId);
    getGeneralCommentsById();
  };

  const handleInputChange = (e) => {
    setGeneralCommentsData(e.target.value);
  };
  const handleInputChangeAdd = (e) => {
    setGeneralCommentsDataAdd(e.target.value);
  };
  const handleCancelClick = () => {
    clearErrorsGeneral("editComments");
    getGeneralComments();
    setEditModes(false);
    setValue('editComments',"")
  };

  const getGeneralComments = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${GET_ALL_GENERAL_COMMENTS}/${complainceId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setGeneralCommentDetails(data);
        setEditModes(Array(data.length).fill(false));
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getGeneralComments();
  }, []);
  const onSubmitGeneral = (data,e) => {
  
    setIsLoading(true);

    // if (generalCommentsData == "" || generalCommentsData == null) {
    //   setError("comments", {
    //     type: "manual",
    //     message: COMMENTS_REQUIRED_MESSAGE,
    //   });
    //   setIsLoading(false);
    //   return;
    // }
    if (typeof generalCommentsDataAdd !== 'string' || generalCommentsDataAdd.trim() === '') {
      setErrorGeneral("comments", {
        type: "manual",
        message: COMMENTS_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    const postData = {
      comments: generalCommentsDataAdd,
      referenceId: complainceId,
    };
    request({ url: GENERAL_COMMENTS, method: "post", data: postData })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          getGeneralComments();
          // postData = {};
          console.log("Success");
          setIsLoading(false);
          setGeneralCommentsDataAdd("")  
          // e.target.reset();
          // formRef.current.reset(); 
          setTimeout(() => {
            //  navigate(-1);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const getGeneralCommentsById = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${GENERAL_COMMENTS}/${commentsId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        console.log(response.data);
        setGeneralCommentsData(data.comments);

        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getGeneralCommentsById();
  }, [commentsId]);

  useEffect(()=>{
    if (generalCommentsData.trim().length > 1000) {
      setErrorGeneral("editComments", {
          type: "manual",
          message: MAX_LENGTH_THOUSAND,
      });
      setIsLoading(false);
      return;
  }
  },[generalCommentsData])

  const submitUpdate = (data) => {
    console.log("Test");
    setIsLoading(true);
  

    if (generalCommentsData.trim() == "" || generalCommentsData.trim() == null) {
      setErrorGeneral("editComments", {
        type: "manual",
        message: COMMENTS_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (generalCommentsData.trim().length < 2) {
      setErrorGeneral("editComments", {
        type: "manual",
        message: MIN_LENGTH_VALIDATE_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (generalCommentsData.trim().length > 1000) {
      setErrorGeneral("editComments", {
        type: "manual",
        message: MAX_LENGTH_THOUSAND,
      });
      setIsLoading(false);
      return;
    }
    const putData = {
      comments: generalCommentsData.trim(),
      referenceId: commentsId,
    };
    request({
      url: `${GENERAL_COMMENTS}/${commentsId}`,
      method: "put",
      data: putData,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");
          setIsLoading(false);
          getGeneralComments();
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };
  const handleDeleteClickOpen = (generalCommentsId) => {
    setDeleteOpen(true);

    setCommentsId(generalCommentsId);
    getGeneralCommentsById();
  };
  const onSubmitDelete = () => {
    request({ url: `${GENERAL_COMMENTS}/${commentsId}`, method: "delete" })
      .then((res) => {
        console.log(res);
        getGeneralComments();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const okCancelButtonActions = (
    <>
      
      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleDeleteClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleDeleteClose}
      />
    </>
  );

  const deleteModel = (
    <form
      onSubmit={handleSubmitGeneral(onSubmitDelete)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography> Are you sure you want to delete comment?</Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );
  return (
    <div>
      {isLoading && <CustomLoading />}
      <CustomToastContainer />
      {/* <CustomViewPage data={data} /> */}
      <CustomViewHeading onClick={handleClick}  title={"View Compliance Request"} data={data} fileList={fileList} description={(complianceRequestData?.description || "")}>
      {/* <CustomViewWithTooltip data={data}  /> */}
      <CustomViewWithMore data={data}/>
     </ CustomViewHeading>
      <Divider />
<br/>  
<br />
  
      {selectedStatus !== "CLOSED" && (
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
          {selectedStatus !== "ACKNOWLEDGED" || auditBy === HomeLoginId ? (
            <section class="grid md:grid-cols-2 xl:grid-cols-3 gap-5">
             <Controller
                name="status"
                control={control}
                rules={{ required: STATUS_REQUIRED_MESSAGE }}
                render={({ field, fieldState }) => (
                  <FormControl
                    variant="standard"
                    fullWidth
                    error={fieldState.invalid}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Please Select Status<span style={{ color: "red" }}> *</span>
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={status}
                      // onChange={(e) => {
                      //   // setStatus(e.target.value);
                      //   field.onChange(e.target.value);
                      // }}
                      onChange={handleSelectChange}
                      label="Please  Select Status"
                    >
                      <MenuItem
                        value="PENDING"
                        disabled={
                          status === "PENDING" ||
                          status === "RESOLVED" ||
                          status === "ACKNOWLEDGED" ||
                          status === "NOT RESOLVED" ||
                          status === "CLOSED"
                        }
                      >
                        Pending
                      </MenuItem>
                      <MenuItem value="ACKNOWLEDGED">Acknowledged</MenuItem>
                      <MenuItem value="CLOSED" >Closed</MenuItem>
                      {/* <MenuItem value="NOT RESOLVED">Not Resolved</MenuItem> */}
                      {/* {status === 'RESOLVED' && (
    <MenuItem value="RESOLVED" disabled>
      Resolved
    </MenuItem>
  )} */}
                      {status === "RESOLVED" ? (
          <MenuItem value="RESOLVED" disabled>
            Resolved
          </MenuItem>
        ) : null} 
                      <MenuItem
                        value="NOT RESOLVED"
                        disabled={status === "Not Resolved" ||
                           status ==="PENDING" || 
                           status ==="ACKNOWLEDGED" 
                           || status === "CLOSED"
                          }
                      >
                        Not Resolved
                      </MenuItem>
                    </Select>
                    <FormHelperText style={{ color: "#D32F2F" }}>
                      {fieldState.invalid ? fieldState.error?.message : ""}
                    </FormHelperText>
                  </FormControl>
                 )}
              />

 {/* <CustomOptions
          label="Status"
          options={options}
          value={status}
          onChange={handleSelectChange}
          // disabled={true}
        /> */}
              <CustomAutoComplete
                fieldName="selectedBylaw"
                // requiredErrorMessage={BYLAW_REQUIRED_MESSAGE}
                id="selectId"
                options={renderedBylaw}
                fieldLabel="Select Bylaw"
                fieldControl={control}
                error={errors}
                setValue={setValue}
                onChange={handleByLawChange}
                value={selectedBylawValue}
                // fieldRequiredIcon="*"
                invalidOption={BYLAW_REQUIRED_MESSAGE}

                isRequired={false}
               
              />

              {/* <CustomInputFieldExpand
                fieldName="bylawDescription"
                fieldId="bylawDescription"
                fieldType="text"
                fieldLabel="Bylaw Description"
                fieldControl={control}
                fieldError={errors}
                disabled={true}
                value={byLawDescription}
              /> */}
                {byLawDescription ? (
                <Box>
                  <Typography style={{ fontSize: "16px", color: "grey" }}>Bylaw Description</Typography>
                  <CustomInputFieldExpand
                    fieldName="bylawDescription"
                    fieldId="bylawDescription"
                    fieldType="text"
                    fieldControl={control}
                    fieldError={errors}
                    // disabled={true}
                    multiline={true}
                    value={byLawDescription}
                  />
                </Box>
              ):<p/>}

              {(selectedStatus !== "Acknowledged" ||
                selectedStatus !== "Resolved") && (
                <CustomInputField
                  fieldName="reviewComment"
                  requiredErrorMessage={REVIEW_COMMENT_REQUIRED_MESSAGE}
                  minLength={2}
                  minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                  maxLength={500}
                  maxLengthErrorMessage={MAX_LENGTH_FIVE_HUNDRED}
                  fieldId="reviewComment"
                  fieldType="text"
                  fieldLabel="Review Comment"
                  fieldControl={control}
                  fieldError={errors}
                  fieldRequiredIcon="*"
                  multiline={true}
                  numberOfRows={4}
                  value={review}
                  onInput={handleReviewChange}
                />
              )}

<CustomFile 
        handleFileChange={handleFileChange}
        token={token}
        fileError={fileError}
        file={file}
      />
            </section>
          ):" "}
            <div class="flex justify-center items-center mt-10 space-x-5">
              <>
                {/* {isValid && */}
                <>
                {selectedStatus !== "ACKNOWLEDGED" || auditBy === HomeLoginId ? (

                  <CustomButton
                    fieldType="submit"
                    buttonName="Update"
                    // className={!isValid ? 'disabled-button' : ''}
                    style={!isValid ? disabledButtonStyle : {}}
                  />
                ):" "}
                {selectedStatus === "ACKNOWLEDGED" &&
  (!linkedlistData || Object.keys(linkedlistData).length === 0) &&  (
                      <CustomButton
                        buttonName="Link"
                        // fieldType="submit"
                        click={!isValid ? () => {} : handleLinkOpen}
                        disabled={!isValid}
                      />
                    )}
                </>
                {/* // } */}
              </>
            </div>
          </CardContent>
        </form>
      </Card>
       )}  
      <Divider />
      <CustomModel
        title="Link CR to Particular Homeowner"
        submit={onSubmitLink}
        content={addModel}
        action={addAction}
        openStatus={open}
        closeStatus={handleAddClose}
        iconCloseStatus={handleIconClose}
        reset={resetLinkComments}
      />
      <CustomReviewTable columns={viewDataTable} data={reviewComments} />
            {/* GeneralComments */}
            <br />

        
{/*upto here general comments  */}
      {linkedlistData && Object.keys(linkedlistData).length > 0  && (
        // <CustomViewPage data={linkedData} />
        <div class="border-sky-500">
<CustomHeading title={`Compliance Request  ${complianceRequestData?.crId || ""} linked to ${linkedlistData?.crId || ""}`} />
{/* // <CustomViewHeading onClick={handleClick}  title={"Linked Compliance Request"}> */}
      <CustomViewWithTooltipexpand data={linkedData}  />
    {/* //  </ CustomViewHeading>  */}
    </div>
        )}
              {linkedlistData && Object.keys(linkedlistData).length > 0  && (

        <div>
      {(selectedLinkStatus === "RESOLVED" || selectedLinkStatus === "NOT RESOLVED"  )&&(
      <Card>
        <form
          onSubmit={handleSubmitLinkedComments(onSubmitLinked)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
            <section class="grid md:grid-cols-2 xl:grid-cols-2 gap-5">
             <Controller
                name="status"
                control={controlLinkedComments}
                // rules={{ required: STATUS_REQUIRED_MESSAGE }}
                render={({ field, fieldState }) => (
                  <FormControl
                    variant="standard"
                    fullWidth
                    error={fieldState.invalid}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Please Select Status<span style={{ color: "red" }}> *</span>
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={linkedStatus}
                      onChange={(e) => {
                        setLinkedStatus(e.target.value); // Updates local state
                        field.onChange(e.target.value); // Ensures React Hook Form is updated
                      }}
                      // onChange={handleLinkedSelectChange}
                      label="Please  Select Status"
                    >
                      <MenuItem
                        value="PENDING"
                        disabled={
                          linkedStatus === "PENDING" ||
                          // linkedStatus === "RESOLVED" ||
                          linkedStatus === "ACKNOWLEDGED" 
                          
                          // linkedStatus === "Not Resolved"
                        }
                      >
                        Pending
                      </MenuItem>
                      {/* <MenuItem value="ACKNOWLEDGED">Acknowledged</MenuItem> */}
                      <MenuItem value="CLOSED">Closed</MenuItem>
          
                      
                    <MenuItem value="RESOLVED" disabled>
                 Resolved
                  </MenuItem>
                  <MenuItem value="NOT RESOLVED">Not Resolved</MenuItem>
                    </Select>
                    <FormHelperText style={{ color: "#D32F2F" }}>
                      {fieldState.invalid ? fieldState.error?.message : ""}
                    </FormHelperText>
                  </FormControl>
                 )}
              />
 
             <CustomInputField
                  fieldName="reviewComment"
                  requiredErrorMessage={REVIEW_COMMENT_REQUIRED_MESSAGE}
                  minLength={2}
                  minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                  maxLength={500}
                  maxLengthErrorMessage={MAX_LENGTH_FIVE_HUNDRED}
                  fieldId="reviewComment"
                  fieldType="text"
                  fieldLabel="Review Comment"
                  fieldControl={controlLinkedComments}
                  fieldError={errorsGeneraledComments}
                  fieldRequiredIcon="*"
                  multiline={true}
                  numberOfRows={1}
                  value={linkedReviewChange}
                  onInput={handleLinkedReviewChange}
                />
             <CustomFile 
        handleFileChange={handleFileChange}
        token={token}
        fileError={fileError}
        file={file}
      />
            </section>
            <div class="flex justify-center items-center mt-10 space-x-5">
              <>
                
                <>
                  <CustomButton
                    fieldType="submit"
                    buttonName="Update"
                  
                  />
              
                </>
                {/* // } */}
              </>
            </div>
          </CardContent>
        </form>
      </Card>
       )} 
   </div>
           )}
{linkedlistData && Object.keys(linkedlistData).length > 0  && (
      // <CustomViewPage data={linkedData}/>
            <CustomReviewTable 
            columns={viewDataLinkedComplaince}
            data={linkedReview}
            />
)}
<CustomHeading title="General Comments" />
        <CustomModel
          title=" Delete Comments"
          submit={onSubmitDelete}
          content={deleteModel}
          action={okCancelButtonActions}
          openStatus={deleteOpen}
          closeStatus={handleDeleteClose}
          iconCloseStatus={handleDeleteClose}
          reset={resetGeneral}
          modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }} />
          <Card>
          <form
            onSubmit={handleSubmitGeneral(onSubmitGeneral)}
            className="space-y-4 md:space-y-6 "
          >
            <CardContent>
            <div className="flex items-center space-x-4">
              <CustomInputField
                fieldName="comments"
                // requiredErrorMessage={COMMENTS_REQUIRED_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={1000}
                maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
                fieldId="comments"
                fieldType="text"
                fieldLabel="Write a Comments"
                fieldControl={controlGeneral}
                fieldError={errorsGeneral}
                fieldRequiredIcon="*"
                multiline={true}
                numberOfRows={2}
                value={generalCommentsDataAdd}
                onInput={handleInputChangeAdd} />

              <div class="text-center mt-10">
                <CustomButton fieldType="submit" buttonName="Post" />
                {isLoading && <CustomLoading />}
              </div>
              </div>
            </CardContent>
          </form>
          <CardContent>
            {generalCommentDetails &&
              generalCommentDetails.map((comment, index) => (
                <div key={comment.generalCommentsId}>
                  <section className="grid md:grid-cols-1 xl:grid-cols-1 gap-3 mt-6 ml-16">
                    <p>
                      <span className="text-primary" style={{ float: "left" }}>
                        @ {comment.createdByName}{" "}
                      </span>
                      <span style={{ float: "right" }}>


                        {!editModes[index] && comment.createdById === HomeLoginId && (
                          <>
                            {index === 0 && (
                              <>
                                <Tooltip title="Edit Comment">
                                  <EditNoteOutlined
                                    color="primary"
                                    onClick={() => {
                                      handleEditClick(index, comment.generalCommentsId);
                                      console.log(comment.generalCommentsId);
                                    } } />
                                </Tooltip>
                                &nbsp; {/* Adding space between icons */}
                                <Tooltip title="Delete Comment">
                                  <DeleteOutline
                                    color="error"
                                    onClick={() => {
                                      handleDeleteClickOpen(comment.generalCommentsId);
                                      console.log(comment.generalCommentsId);
                                    } } />
                                </Tooltip>
                              </>
                            )}
                          </>
                        )}
                        {moment(comment.createdDateAndTime).format(
                          "MM/DD/YYYY hh:mm:ss a"
                        )}
                      </span>
                      {editModes[index] ? (
                        <form
                          onSubmit={handleSubmitGeneral(submitUpdate)}
                          className="space-y-4 md:space-y-6 "
                        >
                          <CardContent>
                            <CustomInputField
                              fieldName="editComments"


                              fieldId="editComments"
                              fieldType="text"
                              // fieldLabel="Write a Comments"
                              fieldControl={controlGeneral}
                              fieldError={errorsGeneral}
                              // fieldRequiredIcon="*"
                              multiline={true}
                              numberOfRows={2}
                              value={generalCommentsData}
                              onInput={handleInputChange} />

                            <div class="flex justify-center mt-8 space-x-1">
                              <CustomButton
                                fieldType="submit"
                                buttonName="Save" />

                              <CustomButton
                                fieldType="button"
                                buttonName="Cancel"
                                click={handleCancelClick} />
                              {/* {isLoading && <CustomLoading />} */}
                            </div>
                          </CardContent>
                        </form>
                      ) : (
                        <p className="mt-8 mb-8">{comment.comments}</p>
                      )}
                    </p>
                  </section>

                  <Divider />
                </div>
              ))}
          </CardContent>
        </Card>
    </div>
  );
}

export default RaisedByHoView;
