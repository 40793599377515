import React, { useEffect, useState } from "react";
import "chart.js/auto";
import "./../../../index.css";
import CardLayout from "./../../../components/dashboard/CardLayout";
import ChartLayout from "./../../../components/dashboard/ChartLayout";
import CustomHeading from "./../../../components/CustomHeading";
import logo from "./../../../assets/img/logo.png";
import { HandymanOutlined, InsertDriveFileOutlined, NotificationsActiveOutlined } from "@mui/icons-material";
import { BASE_URL, CHART, DASHBOARD, DASHBOARD_MC } from "../../../utills/ApplicationRouting";
import { useSelector } from "react-redux";
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import CustomLoading from "../../../components/CustomLoading";
import DvrIcon from '@mui/icons-material/Dvr';
import CardLayoutContact from "../../../components/dashboard/CardLayoutContact";

function McDashboard() {
  const { token } = useSelector((state) => state.hybridhoa);
  const [isLoading, setIsLoading] = useState(true);
  const [datas, setDatas] = useState({});
  const [chartData, setChartData] = useState({});

  const getMcDashboardData = async () => {
    try {
      setIsLoading(true)
      const storedToken = token;

      // Fetch dashboard data
      const response1 = await axios.get(`${BASE_URL}${DASHBOARD}${DASHBOARD_MC}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (response1.status === 200) {
        setDatas(response1.data);
      } else {
        throw new Error("Failed to fetch dashboard data");
      }

      // Fetch chart data
      const response2 = await axios.get(`${BASE_URL}${DASHBOARD}${CHART}${DASHBOARD_MC}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (response2.status === 200) {
        setChartData(response2.data);
      } else {
        throw new Error("Failed to fetch chart data");
        setIsLoading(false);
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };


  useEffect(() => {
    getMcDashboardData();
}, [token]);

const cardDatas=([
  { count: datas.documentCount, badgeContent: datas.documentUnread, name: "Documents", icon: <InsertDriveFileOutlined />, color: ["#fee2e2"], linkTo: "/mcbmarcdocument",  tooltip: "Click to view the list of Documents", },
  { count: datas.maintenanceCount, badgeContent: datas.maintenanceUnread, name: "Maintenance Requests by Homeowner", icon: <HandymanOutlined />, color: ["#bbddff"], linkTo: "/homemaintenance" ,  tooltip: "Click to view the list of Maintenance requests Homeowner",},
  { count: datas.complainceCountByHO, badgeContent: datas.complainceUnread, name: "Compliance Requests By Homeowner", icon: <DvrIcon />, color: ["#c2f6c2"], linkTo: "/raisedbyho",  tooltip: "Click to view the list of Compliance requests Homeowner", },
  { count: datas.notificationCount, badgeContent: datas.notificationUnread, name: "Notifications", icon: <NotificationsActiveOutlined />, color: "#ffd8eb", linkTo: "/mcbmarcnotification",  tooltip: "Click to view the list of Notifications", },
])

const chartDatas = [
  {
    title: "Maintenance",
    tooltip: "Maintenance Request Status",
    card: [
      {
        count: chartData.maintenancePendingCount,
        title: "Pending",
      },
      // {
      //   count: chartData.maintenanceInProgressCount,
      //   title: "In Progress",
      //   color: "orange",
      // },
      {
        count: chartData.maintenanceAckCount,
        title: "Acknowledged",
      },
      {
        count: chartData.maintenanceApprovedCount,
        title: "Approved",
      },
      {
        count:chartData.maintenancePendingApprovedCount,
        title:"Pending Approval",
      },
      {
        count:chartData.maintenanceRejectedCount,
        title:"Rejeced",
      },
      
      
    ],
    data: {
      labels: ["Approved", "Pending", "Acknowledged", "Pending Approval", "Rejected"],
      datasets: [
        {
          data: [chartData.maintenanceApprovedCount, chartData.maintenancePendingCount, chartData.maintenanceAckCount,chartData.maintenancePendingApprovedCount,chartData.maintenanceRejectedCount],
          backgroundColor: ["#9cd96c", "#1e90ff", "#6cd9a9", "#c970db", "#ff4da6"],
                          hoverBackgroundColor: ["#74c533", "#006ad1", "#33c585", "#b233cb", "#ff0080"],
        },
      ],
    },
  },
  {
    title: "Compliance",
    tooltip: "HO Compliance Request Status",
    card: [
      { count: chartData.complaincePendingCount, title: "Pending" },
      { count: chartData.complainceAckCount, title: "Acknowledged"},
      { count: chartData.complainceResolvedCount, title: "Resolved"},
      { count: chartData.complainceClosedCount, title: "Closed"},
      { count: chartData.complainceNotResolvedCount, title: "Not Resolved" }
    ],
    data: {
      labels: ["Pending", "Acknowledged", "Resolved", "Closed","Not resolved"],
      datasets: [{ data: [chartData.complaincePendingCount, chartData.complainceAckCount, chartData.complainceResolvedCount,chartData.complainceClosedCount,chartData.complainceNotResolvedCount], 
        backgroundColor: ["#ff794d", "#9cd96c", "#6cd9a9", "#00a5b3","#87cefa"],
        hoverBackgroundColor: ["#ff531a", "#74c533", "#33c585", "#007680","#00bfff"],}],
    },
  },
];

  

  if (isLoading) {
    return <CustomLoading />;
  }

  const chartOptions = { responsive: true };

  return (
    <div>
      <CustomHeading title="Dashboard" />
      <br />
      <CardLayoutContact />
      <br />
      <CardLayout cardData={cardDatas} type="dashboardMC"/>
      <br />
      <br />
      <ChartLayout chartData={chartDatas} chartOptions={chartOptions} />
      <div className="fixed bottom-0 right-0 m-4">
        <a href="https://hybridhoa.com" target="blank">
          <img src={logo} width={100} height={100} />
        </a>
      </div>
    </div>
  );
};

export default McDashboard;