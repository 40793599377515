import {EditNoteOutlined, ListAlt } from "@mui/icons-material";
import React, { useEffect, useMemo, useState } from "react";
import {  useNavigate } from "react-router-dom";
import CustomHeading from "../../../components/CustomHeading";
import CustomMaterialTable from "../../../components/CustomMaterialTable";
import CustomButton from "../../../components/CustomButton";
import { useSelector } from "react-redux";
import axios from "axios";
import { ARC_COMPLIANCE_REQUEST, BASE_URL, CATEGORY, COMPLIANCE_REQUEST, MC_COMPLIANCE_REQUEST } from "../../../utills/ApplicationRouting";
import moment from 'moment'
import CustomLoading from "../../../components/CustomLoading";

function McComplianceList() {
  const [open, setOpen] = useState(false);
  const { token, userLoginDetails,roles } = useSelector(state => state.hybridhoa);
  const [complianceRequestDetails,setComplianceRequestDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [categoryDetails, setCategoryDetails] = useState([]);
     
     const navigate = useNavigate();
  
    const handleViewClick = (selectedRow) => {
      navigate(`/arccompliancerequest/${selectedRow.original.complainceId}`);
    };
    const handleViewClicks = (selectedRow) => {
    if(roles.includes("ROLE_ARC") ){
      navigate(`/arccompliancerequest/${selectedRow.row.original.complainceId}`)
    }else if (roles.includes("ROLE_MC") ){
      navigate(`/mccomplianceview/${selectedRow.row.original.complainceId}`)

    };
    };
    const handleAddClick = () => {
      navigate("/arccompliancerequestadd");
    };
    const handleEditClick = (selectedRow) =>{
        navigate(`/arccompliancerequestedit/${selectedRow.original.complainceId}`)
    }

    const getCategory = async () => {
      try {
        const storedToken = token;
    
        const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
    
        if (response.status === 200) {
          const allCategories = response.data;
    
          // Filter categories based on conditions
          // const filteredCategories = allCategories.filter(category => {
          //   return (
          //     (category.isEventForArc && roles.includes('ROLE_ARC')) ||
          //     (!category.isEventForArc && roles.includes('ROLE_BM'))
          //   );
          // });
    
          setCategoryDetails(allCategories); // Set the filtered data to the state
          setIsLoading(false);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        // Handle error
        console.error("Error fetching data:", error);
      }
    };
    
    useEffect(() => {
      getCategory();
    }, []);

    const getComplianceRequestDetails = async () => {
      try {
            const storedToken = token
            setIsLoading(true);
        console.log(storedToken);
        
          const response= await axios.get(`${BASE_URL}${MC_COMPLIANCE_REQUEST}`,{
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
                console.log(response.data); 
  
        if (response.status === 200) {
          const data = response.data;
          setComplianceRequestDetails(data);
          setIsLoading(false);
  
        } else {
          setIsLoading(false);
          throw new Error('Failed to fetch data');
        }
       
      } catch (error) {
        // Handle error
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    };
  
  
  
  useEffect(()=>{
    getComplianceRequestDetails();
  },[])

     
    const complianceRequestTable = useMemo((rowData) => [
      {
        accessorKey: "crId",
        header: "Compliance RequestId",
        size: 20,
      },
      {
        accessorKey: "description",
        header: "Description",
        size: 20,
        accessorFn: (row) => {
          const description = row && row.description;
          if (description && description.length > 28) {
            return description.slice(0, 28) + "...";
          }
          return description;
        }
      },
          {
        accessorKey: "categoryDetails.category",
        header: "Category",
        Cell: ({ row }) => {
          if (row.original.categoryDetails === null || row.original.categoryDetails === undefined) {
            return "";
          } else {
            return row.original.categoryDetails.category || " ";
          }
        },
        size: 20,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 20,
      },
      {
        accessorKey: "createdDateAndTime",
        header: "Created Date",
       
        accessorFn: (row) => moment(row.crCreatedDate || row.createdDateAndTime).format("MM/DD/YYYY"),
        size: 20,
      },
      {
        accessorKey: "isFineApplicable",
        accessorFn: (row) => (row && row.isFineApplicable ? "Yes" : "No"),
        header: "Is Fine        Applicable",
       
      },
    ]);
 
//  const rowActions = [
//   {
//     label: "View",
//     icon: <ListAlt color="primary" />,
//     click: handleViewClick,
//   },
//   ...(data.status === 'Pending' ? 
//     [{
//       label: "Edit",
//       icon: <EditNoteOutlined color="primary" />,
//       click: handleEditClick,
//     }] : []
//   )
// ];

// const rowActions = [
//   {
//     label: "View",
//     icon: <ListAlt color="primary" />,
//     click:(row)=> handleViewClick(row),
//   },
//   {
//       label: "Edit",
//       icon: <EditNoteOutlined color="primary" />,
//       click: handleEditClick,
//     }
  
// ];

const rowActions = (rowData) => {
  console.log("rowData:", rowData);
  console.log("userLoginDetails:", userLoginDetails);

  // Check if the user is the creator of the row and the status is "PENDING"
  const isCreator = userLoginDetails.homeOwnerLoginId === rowData.original.createdBy;
  const isPending = rowData.original.status === "PENDING";
  const isLinked=rowData.original.isLinked===false;

  // if (isCreator && isPending && !paidDues && isLinked) {
    if (isCreator && isPending && isLinked) {
    return [
      {
        label: "Edit",
        icon: <EditNoteOutlined color="primary" />,
        click: () => handleEditClick(rowData),
      }
    ];
  }  else {
    return [
      {
        label: "N/A",
      }
    ];
  }
};


 const tableActionButtons = (
      <>
        <CustomButton
          fieldType="button"
          buttonName="Create"
          click={handleAddClick}
        />
      </>
    );
  
  
   
    return (
      <div>
        <CustomHeading title="Compliance Requests " />
        {isLoading && <CustomLoading />}

       
        <CustomMaterialTable
          columns={complianceRequestTable}
          data={complianceRequestDetails}
          rowActions={rowActions}
          actionButtons={tableActionButtons}
          // isLoading={isLoading}
          onRowClick = {handleViewClicks}
        />
      </div>
    );
  }

export default McComplianceList