import { Card, CardContent, FormHelperText, FormLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate, useParams } from "react-router-dom";
import CustomHeading from "../../../components/CustomHeading";
import CustomButton from "../../../components/CustomButton";
import CustomDatePicker from "../../../components/CustomDatePicker";
import {
  CONTACT_REQUIRED_MESSAGE,
  EMAIL_INVALID_MESSAGE,
  EMAIL_REQUIRED_MESSAGE,
  FIRST_NAME_INVALID_MESSAGE,
  FIRST_NAME_REQUIRED_MESSAGE,
  FROM_DATE_REQUIRED_MESSAGE,
  LAST_NAME_INVALID_MESSAGE,
  LAST_NAME_REQUIRED_MESSAGE,
  LEASE_END_DATE_REQUIRED_GREATER_MESSAGE,
  LEASE_END_DATE_REQUIRED_MESSAGE,
  LEASE_START_DATE_REQUIRED_MESSAGE,
  MAX_LENGTH_VALIDATE_MESSAGE,
  MIN_LENGTH_VALIDATE_MESSAGE,
  PHONE_REQUIRED_MESSAGE,
  TO_DATE_REQUIRED_MESSAGE, HOME_ADDRESS_INVALID_MESSAGE, HOME_ADDRESS_REQUIRED_MESSAGE, ADDRESS_FOR_COMMUNICATION_INVALID_MESSAGE, MAX_LENGTH_TWO_FIFTY,
  ADDRESS_REQUIRED_MESSAGE,
} from "../../../utills/ApplicationConstants";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../../../components/CustomInputField";
import {
  EMAIL_REGEX_PATTERN, EMAIL_REGEX_PATTERNS,
  ONLY_ALPHABETIC, ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN
} from "../../../utills/ApplicationRegex";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL, RENTER, HOMES_DETAILS } from "../../../utills/ApplicationRouting";
import { request } from "../../../services/AxiosConfig";
import CustomToastContainer from '../../../components/CustomToastContainer';
import CustomLoading from '../../../components/CustomLoading';
import CustomPhoneInput from "../../../components/CustomPhoneInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import { InfoOutlined } from "@mui/icons-material";
import { Tooltip, IconButton, } from "@mui/material";
import CustomFile from "../../../components/CustomFile";
import CustomAutoCompleteEdit from "../../../components/CustomAutoCompleteEdit";
import CustomTooltipField from "../../../components/CustomTooltipField";
import CustomFileEdit from "../../../components/CustomFileEdit";
function RenterEditMc() {
  const [phone, setPhone] = useState("");
  const [file, setFile] = useState([]);
  const fileTypes = ["JPG", "PNG", "JPEG", "PDF"];
  const { renterId } = useParams();
  const { token ,homeId} = useSelector(state => state.hybridhoa);
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [leaseStartDate, setLeaseStartDate] = useState('');
  const [leaseEndDate, setLeaseEndDate] = useState('');
  const [dob, setDob] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fileData, setFileData] = useState('');
  const [email, setEmail] = useState('');
  const [fileError, setFileError] = useState("");
  const [homeAddresses, setHomeAddresses] = useState([]);
  const [selectedHomeAddress, setSelectedHomeAddress] = useState("");
  const [homeDetails, setHomeDetails] = useState("");
  // const [filteredOption, setFilteredHomeAddresses] = useState("");
  const [homeOwnerDetails, setHomeOwnerDetails] = useState("");
  const [selectedHomeId, setSelectedHomeId] = useState("");
  const [selectedHomeOwnerLoginId, setSelectedHomeOwnerLoginId] = useState("");
  const [address, setAddress] = useState('')
  const handleChange = (newPhone) => {
    setPhone(newPhone);
  };
  const handleFileChange = (file) => {
    console.log("first",file)
    setFile(file);
  };
  const handleClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    // Reset fileError when a valid file is selected
    if (file && file.size <= 10 * 1024 * 1024) {
      console.log("File:", file);
      const fileType = file.type && file.type.split("/")[1]; // Check if file.type exists
      console.log("File type:", fileType);
      if (fileType) {
        const fileExtension = fileType.toUpperCase().replace(/[^\w]/g, ""); // Remove special characters
        console.log("File extension:", fileExtension);
        if (fileTypes.includes(fileExtension)) {
          setFileError("");
        }
      }
    }
  }, [file, fileTypes]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError, clearErrors, setValue,
  } = useForm();

  console.log(errors);
  const getHomes = async () => {
    try {
      const storedToken = token;



      const response = await axios.get(`${BASE_URL}${HOMES_DETAILS}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setHomeAddresses(data);
        setHomeOwnerDetails(data.homeOwnerDetails);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      // Handle error
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    getHomes();
  }, []);


  const getRenterDetailsById = async () => {
    try {
      setIsLoading(true);
      const storedToken = token

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${RENTER}/${renterId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setFileData(data.attachments);
        setEmail(data.email);
        setLeaseStartDate(dayjs(data.leaseStartDate));
        setLeaseEndDate(dayjs(data.leaseEndDate));
        setAddress(data.address)

        // setDob(dayjs(data.dob));
        const contacts = data.contact ===
          "undefined" ? '' : data.contact;
        setValue("phoneNumber", contacts.trim())
        if (data && data.dateOfBirth) {
          setDob(dayjs(data.dateOfBirth));
          console.log((data.dateOfBirth));
        } else {
          setDob(null);
        }
        const matchingHome = homeAddresses.find(
          (home) => home.homeId === data.homeId
        );

        console.log("matchingHome", matchingHome);

        if (matchingHome) {
          setSelectedHomeAddress(matchingHome.address);
          setValue("selectedOption", matchingHome.homeId);
          const { name } = matchingHome;
          const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";
          console.log(selectedHomeDescription);
          setHomeDetails(selectedHomeDescription);
          setSelectedHomeId(matchingHome.homeId)
        } else {
          setSelectedHomeAddress(""); // Handle when matching home is not found
          setValue("selectedOption", null); // Reset to an empty string if no matching home is found
          setHomeDetails("");
        }
        setIsLoading(false);

      } else {
        setIsLoading(false);
        throw new Error('Failed to fetch data');
      }

    } catch (error) {
      setIsLoading(false);
      // Handle error
      console.error("Error fetching data:", error);
    }
  };



  useEffect(() => {
    getRenterDetailsById();
  }, [homeAddresses])


  const handleHomeChange = (event, newValue) => {
    if (newValue && newValue.value !== undefined && newValue.value !== null) {
      const selectedHomeId = newValue.value;
      console.log(selectedHomeId, "lkasodjlahfhasfsoihoiashfoiahsfohasoifhoiashfoi");

      const selectedHome = homeAddresses.find(
        (home) => home.homeId === selectedHomeId
      );
      console.log(selectedHome,"SELECTEDHOME...................");
      const homeOwner = selectedHome;
      console.log(homeOwner,"HOMEOWNER...........");
      if (homeOwner) {
        console.log("inside if");
        const { name } = homeOwner;
        const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";
        console.log("Selected Home Description:", selectedHomeDescription);
        console.log(selectedHome);
        
        // Set the selected home details to the state
        setHomeDetails(selectedHomeDescription);
        setSelectedHomeId(selectedHome.homeId)
        setSelectedHomeOwnerLoginId(selectedHome.homeOwnerLoginId)
        // Use setValue to set the selected homeId in the react-hook-form
        setValue("selectedOption", selectedHomeId);

        // You can also do other operations if needed
        console.log("Selected Home:", selectedHome);
        console.log("Home Details:", homeAddresses);
      } else {
        setHomeDetails("");
        // setValue("selectedOption", null);
        setValue("selectedOption", "");
      }
    } else {
      // Handle the case when the selected option is removed (e.g., by pressing backspace)
      setHomeDetails("");

      // Use setValue to clear the selected homeId in the react-hook-form
      setValue("selectedOption","");
    }
  };

  useEffect(() => {
    console.log("homeAddresses", homeAddresses);

  }, [homeAddresses]);
  const renderedAddresses = homeAddresses
    .map((home, index) => ({
      address: home.address,
      value: home.homeId,
      key: index,
    }));

  const onSubmit = (data) => {
console.log("inside submit");
const totalFileSize = file.reduce((acc, file) => acc + file.size, 0);
const maxSizeInMB = 10;
const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

if (totalFileSize > maxSizeInBytes) {
  // setFileError(`Total file size should not exceed ${maxSizeInMB}MB.`);
  return;
}

// Check file types
const allowedFileTypes = ["image/jpg", "image/png", "image/jpeg", "application/pdf"];
const invalidFiles = file.filter(file => !allowedFileTypes.includes(file.type));

if (invalidFiles.length > 0) {
  // setFileError("Some files have invalid file types. Allowed types are JPG, PNG, JPEG, PDF.");
  return;
}


    if (!leaseStartDate) {
      setError("leaseStartDate", {
        type: "manual",
        message: LEASE_START_DATE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (!leaseEndDate) {
      setError("leaseEndDate", {
        type: "manual",
        message: LEASE_END_DATE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (leaseEndDate < leaseStartDate) {
      setError("leaseEndDate", {
        type: "manual",
        message: LEASE_END_DATE_REQUIRED_GREATER_MESSAGE,
      });
      return;
    }
    // if (!firstName) {
    //   setError("firstName", {
    //     type: "manual",
    //     message: FIRST_NAME_REQUIRED_MESSAGE,
    //   });
    //   setIsLoading(false);
    //   return;
    // } 

    if (typeof firstName !== 'string' || firstName.trim() === '') {
      setError("firstName", {
        type: "manual",
        message: FIRST_NAME_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (typeof lastName !== 'string' || lastName.trim() === '') {
      setError("lastName", {
        type: "manual",
        message: LAST_NAME_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (typeof email !== 'string' || email.trim() === '') {
      setError("email", {
        type: "manual",
        message: EMAIL_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    // if (typeof address !== 'string' || address.trim() === '') {
    //   setError("address", {
    //     type: "manual",
    //     message: ADDRESS_REQUIRED_MESSAGE,
    //   });
    //   setIsLoading(false);
    //   return;
    // }
    // if (!lastName) {
    //   setError("lastName", {
    //     type: "manual",
    //     message: LAST_NAME_REQUIRED_MESSAGE,
    //   });
    //   setIsLoading(false);
    //   return;
    // }

    setIsLoading(true);
    console.log('Form data:', data);
    const formData = new FormData();
    formData.append('leaseStartDate', dayjs(leaseStartDate).format("YYYY-MM-DD"));
    formData.append('leaseEndDate', dayjs(leaseEndDate).format("YYYY-MM-DD"));
    formData.append('firstName', firstName.trim());
    formData.append('lastName', lastName.trim());
    formData.append('dateOfBirth', dob ? dayjs(dob).format("YYYY-MM-DD") : '');
    file.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('email', email.trim());
    formData.append('contact', data.phoneNumber|| "");
    formData.append('role', "ROLE_MC")
    formData.append('homeId', selectedHomeId)
    formData.append('homeOwnerLoginId', selectedHomeOwnerLoginId)
    console.log('leaseStartDate:', dayjs(leaseStartDate).format("YYYY-MM-DD"));
    console.log('leaseEndDate', dayjs(leaseEndDate).format("YYYY-MM-DD"));
    console.log('firstName', firstName);
    console.log('lastName', lastName);
    console.log('file:', file);
    console.log('dateOfBirth', dayjs(dob).format("YYYY-MM-DD"));
    console.log('email', email);

    console.log('formData:', formData);


    const headers = {
      'Content-Type': 'multipart/form-data'
    };

    request({ url: `${RENTER}/${renterId}`, method: "put", data: formData, headers }).then(res => {
      setIsLoading(false);
      console.log(res);
      if (res.status == 200) {
        setTimeout(() => {
          navigate(`/renterlistmc`);
          setSelectedHomeAddress("")
          setHomeDetails("")
          setHomeOwnerDetails("")
        }, 1000)
        setIsLoading(false);

      }
    }).catch(error => {
      console.log(error.response);
      setIsLoading(false);
      if (error.response) {

        console.log("error.response.status" + error.response.status);


      }
    });
  };
  const handleInputChange = (e) => {
    setFirstName(e.target.value);
  };
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleLeaseEndDateChange = (selectedEndDate) => {
    setLeaseEndDate(selectedEndDate);
  };

  const handleDobChange = (selectedDob) => {
    setDob(selectedDob);

  };
  const minDate = dayjs().subtract(2, "year").startOf("day");
  const maxDate = dayjs().add(1, "year").endOf("day");
  const dobDate = dayjs().subtract(18, "year").endOf("day");
  const [toDateDisabled, setToDateDisabled] = useState(true);
  const [toDateMinDate, setToDateMinDate] = useState(minDate);
  const [toDateMaxDate, setToDateMaxDate] = useState(maxDate);
  const handleFromDateChange = (selectedFromDate) => {
    const newToDateMaxDate = dayjs(selectedFromDate).add(100, "year").endOf("day");
    setToDateMaxDate(newToDateMaxDate);
    setToDateMinDate(selectedFromDate);
    setToDateDisabled(false);
    setLeaseStartDate(selectedFromDate)
  };


  return (
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomToastContainer />
        <CustomHeading title="Edit Renter" />

        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full text-lg font-sans mb-2">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleClick}
          />
        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">

              <CustomAutoCompleteEdit
                fieldName="selectedOption"
                requiredErrorMessage={HOME_ADDRESS_REQUIRED_MESSAGE}
                id="selectId"
                options={renderedAddresses}
                fieldLabel="Select Home Address"
                fieldControl={control}
                error={errors}
                setValue={setValue}
                fieldRequiredIcon="*"
                onChange={handleHomeChange}
                value={selectedHomeAddress}
                invalidOption={HOME_ADDRESS_INVALID_MESSAGE}
                tooltipMessage={"Please select the home address of the homeowner you are creating the compliance for."}

              />

              <CustomTooltipField
                fieldName="homeOwnerDetails"
                fieldId="homeOwnerDetails"
                fieldType="text"
                fieldLabel="HomeOwner Name"
                fieldControl={control}
                fieldError={errors}
                disabled={true}
                value={homeDetails}
                tooltipMessage={"This field displays the name of the homeowner based on the selected home address."}
              />

              <CustomInputField
                // requiredErrorMessage={FIRST_NAME_REQUIRED_MESSAGE}
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={FIRST_NAME_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldName="firstName"
                fieldId="firstName"
                fieldType="text"
                fieldLabel="First Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={firstName}
                onInput={handleInputChange}
                tooltipMessage={"Enter the first name of the renter."}
              />

              <CustomInputField
                fieldName="lastName"
                // requiredErrorMessage={LAST_NAME_REQUIRED_MESSAGE}
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={LAST_NAME_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                fieldId="lastName"
                fieldType="text"
                fieldLabel="Last Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={lastName}
                onInput={handleLastNameChange}
                tooltipMessage={"Enter the last name of the renter."}
              />


              <CustomInputField
                fieldName="email"
                // requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
                fieldPattern={EMAIL_REGEX_PATTERNS}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                validator={false}
                fieldId="email"
                fieldType="text"
                fieldLabel="Email"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={email}
                onInput={handleEmailChange}
                tooltipMessage={"Enter a valid email address for the renter."}
              />


              <CustomInputField
                fieldName="address"
                // requiredErrorMessage={LAST_NAME_REQUIRED_MESSAGE}
                // fieldPattern={ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN}
                // patternErrorMessage={ADDRESS_FOR_COMMUNICATION_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={250}
                maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
                fieldId="address"
                fieldType="text"
                fieldLabel="Address"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                value={address}
                onInput={handleAddressChange}
                tooltipMessage={"Enter the Address of the renter."}
              />

              <CustomDatePicker
                fieldName="dob"
                label="Date of Birth"
                control={control}
                maxDate={dobDate}
                value={dob}
                onChange={handleDobChange}
                tooltipMessage={"Select the date of birth of the renter."}
              />
              {/* <FormLabel>
                Attach Documents <Tooltip
        title={`Please note that uploading a new document will override the existing one`}
      >
        <IconButton>
          <InfoOutlined />
        </IconButton>
      </Tooltip> 
                <FileUploader
                  handleChange={handleFileChange}
                  name="file"
                  types={fileTypes}
                  // required
                />
                {fileData ? <p>{fileData}</p> : <p>No file selected</p>}
              </FormLabel> */}



              <div>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    validate: {
                      isValidPhoneNumber: value => !value || isValidPhoneNumber(value) || "Please provide a valid phone number"
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <CustomPhoneInput
                        isRequiredIcon={false}
                        isRequired={false}
                        fieldControl={control}
                        label="Phone Number"
                        clearErrors={clearErrors}
                        country="US"
                        fieldError={errors}
                        value={field.value}
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        tooltipMessage={"Enter a valid phone number for the renter."}
                      />
                    </>
                  )}
                  tooltipMessage={"test"}
                />
                {errors.phoneNumber && (
                  <FormHelperText style={{ color: "#D32F2F" }}>
                    {errors.phoneNumber.message}
                  </FormHelperText>
                )}
              </div>
              <CustomDatePicker
                fieldName="leaseStartDate"
                label="Lease Start Date"
                control={control}
                minDate={minDate}
                maxDate={maxDate}
                onChange={(date) => handleFromDateChange(date)}
                fieldRequiredIcon="*"
                value={leaseStartDate}
                tooltipMessage={"Select the start date of the lease."}
              />

              <CustomDatePicker
                fieldName="leaseEndDate"
                label="Lease End Date"
                fieldRequiredIcon="*"
                control={control}
                // requiredErrorMessage={LEASE_END_DATE_REQUIRED_MESSAGE}
                minDate={toDateMinDate}
                maxDate={toDateMaxDate}
                disabled={toDateDisabled}
                value={leaseEndDate}
                onChange={handleLeaseEndDateChange}
                tooltipMessage={"Select the end date of the lease."}
              />
               <CustomFileEdit downloadFiles={true} fileData={fileData} handleFileChange={handleFileChange} token={token} file={file}/>
              {/* <Controller
  name="phoneNumber"
  control={control}
  rules={{
    validate: {
      isValidPhoneNumber: value => !value || isValidPhoneNumber(value) || "Please provide a valid phone number"
    }
  }}
  render={({ field, fieldState }) => (
    <>
      <CustomPhoneInput
        isRequiredIcon={false}
        isRequired={false}
        fieldControl={control}
        label="Phone Number"
        clearErrors={clearErrors}
        country="US"
        value={field.value}
        onChange={(value) => {
          field.onChange(value);
        }}
      />
      {fieldState.error && (
        <FormHelperText style={{ color: "#D32F2F" }}>
          {fieldState.error.message}
        </FormHelperText>
      )}
    </>
  )}
/> */}



            </section>

            <br />
            <br />

            {/* <Controller
                name="phoneNumber"
                control={control}
                // rules={{ required: CONTACT_REQUIRED_MESSAGE }}
                render={({ field, fieldState }) => (
                  <>
                    <PhoneInput
                      error={fieldState.invalid}
                      country={"us"}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                    />
                    <FormHelperText style={{ color: "#D32F2F" }}>
                      {fieldState.invalid ? fieldState.error?.message : ""}
                    </FormHelperText>
                  </>
                )}
              /> */}
            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Update" />
              {isLoading && <CustomLoading />}
            </div>
          </CardContent>
        </form>

      </Card>
    </div>
  );
}

export default RenterEditMc