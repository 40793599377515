import React, { useMemo, useState } from "react";
import CustomHeading from "../../components/CustomHeading";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BASE_URL, REIMBURSE_DUE } from "../../utills/ApplicationRouting";
import axios from "axios";
import { useEffect } from "react";
import CustomLoading from "../../components/CustomLoading";
import CustomMaterialWithoutRoutes from "../../components/CustomMaterialWithoutRoutes";
import ReadMore from "../../components/ReadMoreTable";
function ReimburseDue() {
  const navigate = useNavigate();
  const [dueDetails, setDueDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { token,homeId } = useSelector((state) => state.hybridhoa);

  const getDueDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${REIMBURSE_DUE}?homeId=${homeId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setDueDetails(data);
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDueDetails();
  }, [homeId]);

  const reimbureseDueTable = useMemo((rowData) => [
    {
      accessorKey: "serialNo",
      header: "#",
      size: 20,
      Cell: ({ row }) => row.index + 1,
    },
    {
      accessorKey: "title",
      header: "Title",
      Cell: ({ row }) => (
        <ReadMore text={row.original.title || ""} maxLength={75} />
    ),
    },
    {
      accessorKey: "dueType",
      header: "Due Type",
    },
    {
      accessorKey: "waivedAmount",
      header: "Waived Amount",
    },
    {
      accessorKey: "appliedTo",
      header: "Applied To",
   
      accessorFn: (row) => `${row.appliedToDetails? row.appliedToDetails.firstName : ""} ${row.appliedToDetails? row.appliedToDetails.lastName : ""} `,
    },
    {
      accessorKey: "totalAmount",
      header: "Total Amount",
    },
  ]);

  return (
    <div>
      {isLoading && <CustomLoading />}
      <CustomHeading title="Waived Off Dues" />

      <CustomMaterialWithoutRoutes columns={reimbureseDueTable} data={dueDetails} />
    </div>
  );
}

export default ReimburseDue;
