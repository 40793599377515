export const EMAIL_REQUIRED_MESSAGE = "Email address is required";

export const EMAIL_INVALID_MESSAGE = "Please enter a valid email address";

export const NAME_INVALID_MESSAGE = "Please Enter a valid Name";

export const PASSWORD_REQUIRED_MESSAGE = "Password is required";

export const JOB_CATEGORY_REQUIRED_MESSAGE = "Job Category is required";

export const EVENT_ADDRESS_REQUIRED_MESSAGE = "Event Address is required";

export const JOB_CATEGORY_INVAILD_MESSAGE = "Only accept alphabetic characters";

export const HOME_ADDRESS_REQUIRED_MESSAGE = "Home address is required";

export const HOME_ADDRESS_INVALID_MESSAGE = "Please provide a valid address";

export const FIRST_NAME_REQUIRED_MESSAGE = "First name is required";

export const NAME_REQUIRED_MESSAGE = "Name is required";

export const MIN_LENGTH_VALIDATE_MESSAGE =
  "Please provide a minimum of 2 characters";

export const MIN_EIGHT_LENGTH_VALIDATE_MESSAGE =
  "Please provide a minimum of 8 characters";

export const MAX_LENGTH_VALIDATE_MESSAGE =
  "Please provide a maximum of 60 characters only";
export const MAX_LENGTH_VALIDATE_MESSAGE_TEN =
  "Please provide a maximum of 10 characters only";
export const MAX_LENGTH_TWO_FIFTY =
  "Please provide a maximum of 250 characters only";

export const MAX_LENGTH_FIVE_THOUSAND =
  "Please provide a maximum of 5000 characters only";

export const MAX_LENGTH_THOUSAND =
  "Please provide a maximum of 1000 characters only";

export const MAX_LENGTH_HUNDRED =
  "Please provide a maximum of 100 characters only";

export const MAX_LENGTH_FIVE_HUNDRED =
  "Please provide a maximum of 500 characters only";

export const LAST_NAME_REQUIRED_MESSAGE = "Last name is required";

export const FIRST_NAME_INVALID_MESSAGE = "Please provide valid FirstName";

export const LAST_NAME_INVALID_MESSAGE = "Please provide valid LastName";

export const EXTERNAL_ROLE_REQUIRED_MESSAGE = "Please select a role";

export const ROLES_REQUIRED_MESSAGE = "Please select at least one role";

export const TOPIC_REQUIRED_MESSAGE = "Topic is required";

export const TOPIC_INVALID_MESSAGE = "Please provide valid topic";

export const DESCRIPTION_REQUIRED_MESSAGE = "Description is required";

export const ATTACHMENT_REQUIRED_MESSAGE = "Attachment is required";

export const VIOLATION_DESCRIPTION_REQUIRED_MESSAGE =
  "Violation Description is required";

export const DESCRIPTION_INVALID_MESSAGE = "Please provide valid Description";

export const TITLE_REQUIRED_MESSAGE = "Title is required";

export const TITLE_INVALID_MESSAGE = "Please provide valid Title";

export const AMOUNT_REQUIRED_MESSAGE = "Amount is required";

export const AMOUNT_INVALID_MESSAGE = "Please provide valid Amount";

export const DUE_TYPE_REQUIRED_MESSAGE = " Due Type is required";

export const DUE_DATE_REQUIRED_MESSAGE = "Due Date is required";

export const DUE_FREQUENCY_REQUIRED_MESSAGE = "Due Frequency is required";

export const SPECIAL_ASSESSMENT_DUE_TYPE =
  "Special Assessment Due Type is required";

export const REIMBURSE_COMMENT = "Reimburse Comment is required";

export const TYPE_REQUIRED_MESSAGE = " Type is required";

export const FROM_DATE_REQUIRED_MESSAGE = "From date is required";

export const TO_DATE_REQUIRED_MESSAGE = "To date is required";

export const CITY_REQUIRED_MESSAGE = "City is required";

export const CITY_INVALID_MESSAGE = "Please provide valid city name";

export const MAX_LENGTH_TWENTY_MESSAGE = "Please provide 20 characters only";

export const STATE_REQUIRED_MESSAGE = "State is required";

export const STATE_INVALID_MESSAGE = "Please provide valid state";

export const ZIPCODE_REQUIRED_MESSAGE = "Zip code is required";

export const FAX_INVALID_MESSAGE = "Please enter a valid fax number";

export const ZIPCODE_INVALID_MESSAGE = "Please provide valid zipcode";

export const ZIPCODE_MIN_LENGTH_MESSAGE = "Please provide minimum 5 digits";

export const ZIPCODE_MAX_LENGTH_MESSAGE =
  "Please provide a maximum of 5 digits";

export const CONTACT_REQUIRED_MESSAGE = "Contact is required";

export const LEASE_START_DATE_REQUIRED_MESSAGE = "Lease start date is required";

export const LEASE_END_DATE_REQUIRED_MESSAGE = "Lease end date is required";

export const LEASE_END_DATE_REQUIRED_GREATER_MESSAGE =
  "Lease End Date must be greater than Lease Start Date";

export const BYLAW_REQUIRED_MESSAGE = "Please provide a valid bylaw.";

export const TO_DATE_ERROR_MESSAGE =
  "Please provide To date greater than from date";

export const ADDRESS_FOR_COMMUNICATION_REQUIRED_MESSAGE =
  "Address for communication is required";

export const ADDRESS_FOR_COMMUNICATION_INVALID_MESSAGE =
  "Please provide valid address for communication";

export const GENDER_REQUIRED_MESSAGE = "Gender is required";

export const CATEGORY_REQUIRED_MESSAGE = "Category is required";

export const CATEGORY_SUBJECT_REQUIRED_MESSAGE = "Category/Subject is required";

export const CATEGORY_INVALID_MESSAGE = "Please provide valid Category";

export const REQUEST_TYPE_REQUIRED_MESSAGE = "Please select a request type";

export const REVIEW_COMMENT_REQUIRED_MESSAGE = "Review Comment is required";

export const STATUS_REQUIRED_MESSAGE = "Please select status";

export const COMMENTS_REQUIRED_MESSAGE = "Comments is required";

export const HEADER_REQUIRED_MESSAGE = "Compliance request header is required";

export const HEADER_INVALID_MESAGE = "Please provide valid Header";

export const FOOTER_REQUIRED_MESSAGE = "Compliance request footer is required";

export const FOOTER_INVALID_MESSAGE = "Please provide valid footer";

export const PUBLISH_DATE_REQUIRED_MESSAGE = "Please select publish date";

export const SUBJECT_REQUIRED_MESSAGE = "Subject is required";

export const NOTIFICATION_REQUIRED_MESSAGE = "Notification type is required";

export const REVIEW_COMMENTS_REQUIRED_MESSAGE = "Review comments is required";

export const CURRENT_AND_NEW_PASSWORD_CHECKING =
  "Current password and new password should not be same";

export const NEW_PASSWORD_CURRENT_PASSWORD_CHECKING =
  "Confirm password and new password must be the same.";

export const CURRENT_PASSWORD_REQUIRED_MESSAGE = "Current Password is required";

export const NEW_PASSWORD_REQUIRED_MESSAGE = "New Password is required";

export const CONFIRM_PASSWORD_REQUIRED_MESSAGE = "Confirm Password is required";

export const DATE_TIME_REQUIRED_MESSAGE = "Date & time is required ";

export const WHAT_NEED_ATTENTION_REQUIRED_MESSAGE =
  "What needs attention is required";

export const MORE_INFORMATION_REQUIRED_MESSAGE = "More information is required";

export const EXTERNAL_OCCUPATION_REQUIRED_MESSAGE = "Job-category is required";

export const EXTERNAL_USER_REQUIRED_MESSAGE = "External user is required";

export const ADDRESS_REQUIRED_MESSAGE = "Address is required";

export const PHONE_REQUIRED_MESSAGE = "Contact is required";

export const PHONE_INVALID_MESSAGE = "Please provide valid contact";

export const ALPHABETS_ARE_ALLOWED = "Only alpabets are allowed";
export const DOCUMENT_TYPE_INVALID_MESSAGE = "Please select a Document type";

export const MIN_LENGTH_VALIDATE_ONE =
  "Please provide a minimum of 1 character";

export const NOTIFICATION_TYPE_MESSAGE = "Notification type is required";
export const SUBJECT_MIN_LENGTH_VALIDATE_MESSAGE="Please provide a minimum of 8 characters";
export const SUBJECT_MAX_LENGTH_VALIDATE_MESSAGE="Please provide a maximum of 250 characters";
export const DESCRIPTION_MIN_LENGTH_VALIDATE_MESSAGE="Please provide a minimum of 2 characters.";

export const DESCRIPTION_MAX_LENGTH_VALIDATE_MESSAGE="Please provide a maximum of 1000 characters";

export const AMOUNT_INVALID_MESSAGES="Please provide valid amount";

export const CREATED_DATE_REQUIRED="Created date is required"
