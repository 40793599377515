import React from 'react'

function CustomPascalChange(str) {
                  if (str.includes('_')) {
                      // Split the string by underscores
                      const parts = str.split('_');
              
                      // Capitalize each part and join them with spaces
                      const transformedString = parts.map(part => {
                          // Special case for "ARC"
                          if (part.toUpperCase() === 'ARC') {
                              return part.toUpperCase();
                          } else {
                              return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
                          }
                      }).join(' ');
              
                      return transformedString;
                  } else {
                      // If no underscores, capitalize the string
                      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
                  }
              }
              

export default CustomPascalChange