import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomViewPage from "../../components/CustomViewPage";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL, CATEGORY, EVENT } from "../../utills/ApplicationRouting";
import moment from "moment";
import CustomLoading from "../../components/CustomLoading";
import CustomViewWithTooltip from "../../components/CustomViewWithTooltip";
import CustomViewHeading from "../../components/CustomViewHeading";
import { Button } from "@mui/material";
import CustomViewWithMore from "../../components/CustomViewWithMore";
function EventView() {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.hybridhoa);
  const [eventData, setEventData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { eventId } = useParams();
  // const [firstNameData, setFirstNameData] = useState("");
  // const [lastNameData, setLastNameData] = useState("");
  const [createdrole, setcreatedrole] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [crId, setCrId] = useState("");
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [showFullDescription, setShowFullDescription] = useState(false); 
  const [showFulltopic, setShowFulltopic] = useState(false);
  const [showFullAddress, setShowFullAddress] = useState(false);

  const handleClick = () => {
    navigate(-1);
  };

  const getCategory = async () => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setCategoryDetails(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const getEventDetailsById = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${EVENT}/${eventId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        const createdDetails = data.createdByDetails;

        // setFirstNameData(createdDetails.firstName);
        // setLastNameData(createdDetails.lastName);
        setcreatedrole(response.data.role);
        const matchingCategory = categoryDetails.find(
          (category) => category.categoryId === data.category
        );
        console.log("matchingCategory", matchingCategory);
        console.log("categoryDetails", categoryDetails);
        setCrId(response.data.crId);
        setSelectedCategory(matchingCategory.category);
        setEventData(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const handleToggletopic = () => {
    setShowFulltopic(!showFulltopic);
  };
  const handleToggleAddress = () => {
    setShowFullAddress(!showFullAddress);
  };
  const renderDescription = () => {
    console.log("inside render ");
    if (eventData.description && !showFullDescription) {
    console.log("inside render ",eventData.description);
  
      return (
        <>
        
          {eventData.description.slice(0, 250)}
          {eventData.description.length > 250 && (
            <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Read More</Button>
          )}
        </>
      );
    } else {
    console.log("inside render else ",eventData.description);
  
      return (
        <>
          {eventData.description}
          <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Less</Button>
        </>
      );
    }
  };

  const renderTopic = () => {
    console.log("inside render ");
    if (eventData.topic && !showFulltopic) {
    console.log("inside render ",eventData.topic);
  
      return (
        <>
        
          {eventData.topic.slice(0, 250)}
          {eventData.topic.length > 250 && (
            <Button onClick={handleToggletopic} style={{fontSize:"12px"}}>Read More</Button>
          )}
        </>
      );
    } else {
    console.log("inside render else ",eventData.topic);
  
      return (
        <>
          {eventData.topic}
          <Button onClick={handleToggletopic} style={{fontSize:"12px"}}>Less</Button>
        </>
      );
    }
  };
  const renderAddress = () => {
    console.log("inside render ");
    if (eventData.eventAddress && !showFullAddress) {
    console.log("inside render ",eventData.eventAddress);
  
      return (
        <>
        
          {eventData.eventAddress.slice(0, 250)}
          {eventData.eventAddress.length > 250 && (
            <Button onClick={handleToggleAddress} style={{fontSize:"12px"}}>Read More</Button>
          )}
        </>
      );
    } else {
    console.log("inside render else ",eventData.eventAddress);
  
      return (
        <>
          {eventData.eventAddress}
          <Button onClick={handleToggleAddress} style={{fontSize:"12px"}}>Less</Button>
        </>
      );
    }
  };


  useEffect(() => {
    getEventDetailsById();
  }, [categoryDetails]);
  const roleWithoutPrefix = createdrole.substring("ROLE_".length);
  console.log("role"+roleWithoutPrefix);
  let role;
if (roleWithoutPrefix === "BM") {
  role = "Board Member";
} else if (roleWithoutPrefix === "MC") {
  role = "Management Company";
} else {
  role = roleWithoutPrefix;
}
  const data = [
    {
      groupName:'Event Details',
      items: [
        {
          label: "Topic of Event",
          data: renderTopic(),
          text: 'The Topic of the event.',
        },
        {
          label: "Description",
          data: renderDescription(),
          text: 'A brief description of the event.',
        },
      {
        label: "Category",
        data: selectedCategory,
        text: 'A Category of the event.',
      },
      {
        label: "Created By",
        data: role,
        text: 'The role of the person who created the event.' ,
      },
      
      {
        label: "Event Date & Time",
        data: moment(eventData.dateAndTime).format(
          "MM/DD/YYYY hh:mm:ss a"
        ),
        text: 'Date and Time of the event.',
      },
      // {
      //   label: "Publish Date & Time",
      //   data: moment(eventData.publishDate).format("MM/DD/YYYY hh:mm:ss a"),
      // },
     
      
    
      // {
      //   label: "Created Date",
      //   data: moment(eventData.createdDateAndTime).format("MM/DD/YYYY"),
      // },
      ...(eventData.status === "CANCELED" ? [{
        label: "Status",
        data: eventData.status,
        text: 'Current status of the event.',
      }] : []),
    ],
  },
  {
    groupName:'Event Location',
      items: [
        {
          label: "Event Address",
        data: renderAddress(),
        text:'The Address of the event',
        }
  ],},
  {
    groupName:'Contact Details',
      items: [
        {
          label: "Name",
        data: eventData.name,
        text:'The name of the person who created the event',
        },
        {
          label: "Email",
        data: eventData.email,
        text:'The email of the person who created the event',
        },{
          label: "Contact",
        data: eventData.contact,
        text:'The contact of the person who created the event',
        }
  ],}
  ];
  const data2 = [
    {
      groupName:'Event Details',
      items: [
        {
          label: "Topic of Event",
          data: eventData.topic,
        },
        {
          label: "Description",
          data: eventData.description,
        },
      {
        label: "Category",
        data: selectedCategory,
      },
      {
        label: "Created By",
        data: role,
      },
      
      {
        label: "Event Date & Time",
        data: moment(eventData.dateAndTime).format(
          "MM/DD/YYYY hh:mm:ss a"
        ),
      },
      // {
      //   label: "Publish Date & Time",
      //   data: moment(eventData.publishDate).format("MM/DD/YYYY hh:mm:ss a"),
      // },
     
      
    
      // {
      //   label: "Created Date",
      //   data: moment(eventData.createdDateAndTime).format("MM/DD/YYYY"),
      // },
      ...(eventData.status === "CANCELED" ? [{
        label: "Status",
        data: eventData.status,
      }] : []),
    ],
  },
  {
    groupName:'Event Location',
      items: [
        {
          label: "Event Address",
        data: eventData.eventAddress,
        }
  ],},
  {
    groupName:'Contact Details',
      items: [
        {
          label: "Name",
        data: eventData.name,
        },
        {
          label: "Email",
        data: eventData.email,
        },{
          label: "Contact",
        data: eventData.contact,
        }
  ],}
  ];
  return (
    <div>
      {isLoading && <CustomLoading />}
      <CustomViewHeading onClick={handleClick} title={"Event"} data={data2} fileName={`Event_${eventData.eventId}.pdf`} description={(eventData?.description || "")}>
      {/* <CustomViewHeading onClick={handleClick} > */}
      <CustomViewWithMore data={data}  />
      </CustomViewHeading>
    </div>
  );
}

export default EventView;
