import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const CustomReviewTableWithoutActions = (props) => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  const renderReviewComments = (value) => {
    if (!value) {
      return null; // Handle case where value (comments) is undefined or null
    }

    const maxCharactersPerLine = 20;
    const numLinesToShowInitially = 3;

    const lines = [];
    let currentLine = '';

    // Build lines and check if there are spaces inside
    let hasSpaces = false;
    for (let i = 0; i < value.length; i++) {
      if (value[i] === ' ') {
        hasSpaces = true;
        break;
      }
    }

    // Build lines with or without spaces
    if (hasSpaces) {
      const words = value.split(' ');
      for (const word of words) {
        if ((currentLine + word).length > maxCharactersPerLine) {
          lines.push(currentLine.trim());
          currentLine = '';
        }
        currentLine += (currentLine ? ' ' : '') + word;
      }
    } else {
      for (let i = 0; i < value.length; i++) {
        if ((currentLine + value[i]).length > maxCharactersPerLine) {
          lines.push(currentLine.trim());
          currentLine = '';
        }
        currentLine += value[i];
      }
    }

    // Push the last line if it's not empty
    if (currentLine.trim()) {
      lines.push(currentLine.trim());
    }

    const [showAllLines, setShowAllLines] = useState(false);

    const handleToggleLines = () => {
      setShowAllLines(!showAllLines);
    };

    return (
      <>
        {showAllLines
          ? lines.map((line, index) => (
              <Typography key={index} component="div" variant="body2" sx={{ textAlign: 'justify' }}>
                {line}
              </Typography>
            ))
          : lines.slice(0, numLinesToShowInitially).map((line, index) => (
              <Typography key={index} component="div" variant="body2" sx={{ textAlign: 'justify' }}>
                {line}
              </Typography>
            ))}
        {lines.length > numLinesToShowInitially && (
          <IconButton size="small" onClick={handleToggleLines} sx={{ fontSize: 12 }}>
            {showAllLines ? "Show less" : "Show more"} {showAllLines ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        )}
      </>
    );
  };

  const columns = [
    ...props.columns,
    {
      id: "reviewcomments",
      accessorKey: "comments",
      header: "Review Comments",
      size: 60,
      Cell: ({ row }) => {
        return renderReviewComments(row.original.comments);
      },
    },
    // {
    //   id: "actions",
    //   Header: "Actions",
    //   Cell: ({ row }) => (
    //     <Box sx={{ display: "flex", gap: "0.5rem" }}>
    //       {props.rowActions && <props.rowActions rowData={row.original} />}
    //     </Box>
    //   ),
    // },
  ];

  return (
    <MaterialReactTable
      columns={columns}
      data={props.data}
      enableColumnOrdering
      positionActionsColumn="last"
      enableSorting={false}
      onPaginationChange={setPagination}
      state={{
        isLoading: props.isLoading,
        pagination,
      }}
      muiTableHeadCellProps={{
        sx: {
          fontWeight: "semi-bold",
          fontSize: 14,
          fontFamily:
            "'Inter', 'Open Sans', 'Proxima Nova Regular', 'Helvetica', 'Arial', 'sans-serif'",
        },
      }}
      muiTableBodyCellProps={{
        sx: {
          fontSize: 14,
          fontFamily:
            "'Inter', 'Open Sans', 'Proxima Nova Regular', 'Helvetica', 'Arial', 'sans-serif'",
        },
      }}
      enableColumnPinning
    />
  );
};

export default CustomReviewTableWithoutActions;
