import React, { useEffect, useState } from "react";
import CustomButton from "../../../components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../../../components/CustomInputField";
import { FileUploader } from "react-drag-drop-files";
import CustomPdfFileEdit from "../../../components/CustomePdfFileEdit";

import {
  BYLAW_REQUIRED_MESSAGE,
  CATEGORY_INVALID_MESSAGE,
  CATEGORY_REQUIRED_MESSAGE,
  CATEGORY_SUBJECT_REQUIRED_MESSAGE,
  CREATED_DATE_REQUIRED,
  DESCRIPTION_REQUIRED_MESSAGE,
  HOME_ADDRESS_INVALID_MESSAGE,  
  HOME_ADDRESS_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_THOUSAND,
  MAX_LENGTH_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
  VIOLATION_DESCRIPTION_REQUIRED_MESSAGE,
} from "../../../utills/ApplicationConstants";

import {
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import CustomHeading from "../../../components/CustomHeading";
import axios from "axios";
import { BASE_URL, BYLAW, CATEGORY, COMPLIANCE_REQUEST_BY_ID, COMPLIANCE_REQUEST_EDIT_BY_ID, HOMES, HOMES_DETAILS } from "../../../utills/ApplicationRouting";
import { useSelector } from "react-redux";
import { request } from "../../../services/AxiosConfig";
import CustomAutoComplete from "../../../components/CustomAutoComplete";
import CustomToastContainer from "../../../components/CustomToastContainer";
import CustomAutoCompleteEdit from "../../../components/CustomAutoCompleteEdit";
import {Tooltip, FormLabel,IconButton} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import CustomInputFieldExpand  from"../../../components/CustomInputFieldExpand";

import CustomFile from "../../../components/CustomFile";
import CustomLoading from "../../../components/CustomLoading";
import CustomTooltipField from "../../../components/CustomTooltipField";
import CustomFileEditArcComplain from "../../../components/CustomFileEditArcComplain";

import CustomFileEdit from "../../../components/CustomFileEdit";
import CustomDatePicker from "../../../components/CustomDatePicker";
import dayjs from "dayjs";


import { Box } from "@mui/system";


function ArcComplianceEdit() {
    // const [file, setFile] = useState(null);
    const fileTypes  = ["image/jpg", "image/png", "image/jpeg", "application/pdf"];
    // const navigate = useNavigate();
  
    // const handleViewClick = () => {
    //   navigate(-1);
    // };
  
    // const handleFileChange = (file) => {
    //   setFile(file);
    // };
  
    // const homeAddess = [
    //   { label: "HomeReq3" },
    //   { label: "PrimaryGroup5" },
    //   { label: "demoaddress" },
    //   { label: "cutomeraddress" },
    //   { label: "resend1" },
    //   { label: "resend2" },
    //   { label: "HomeEmailAddress1" },
    //   { label: "HomeEmailAddress" },
    //   { label: "assign email" },
    //   { label: "adresstharun" },
    // ];
  
    // const category = [
    //   { label: "Parking" },
    //   { label: "Electricity" },
    //   { label: "Grass" },
    //   { label: "Buliding" },
    //   { label: "Others" },
    // ];
  
    // const bylaw = [{ label: "testing bylaw" }];
  
    
  
    // const {
    //   handleSubmit,
    //   control,
    //   formState: { errors },
    //   setValue,
    // } = useForm();
    // const onSubmit = (data) => {};

    const [file, setFile] = useState([]);
    const [fileData,setFileData] = useState([])
  // const fileTypes = ["JPG", "PNG", "JPEG", "PDF"];
  const navigate = useNavigate();
  const [homeAddresses, setHomeAddresses] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [byLawDetails, setByLawDetails] = useState([]);
  const [byLawDescription, setByLawDescription] = useState("");
  const [description, setDescription] = useState('');
  const [byLawId,setByLawId] = useState('');
  //  const [fileData, setFileData] = useState("");
  const { token, homeId,roles } = useSelector((state) => state.hybridhoa);
  const [complianceRequestData, setComplianceRequestData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { complainceId } = useParams();
  const [selectedByLawTopic, setSelectedByLawTopic] = useState('');
  const [selectedHome, setSelectedHome] = useState('');
  const [selectedCategory,setSelectedCategory]=useState('')
  const [crId,setCrId]=useState('')
  const [bylawTopic,setBylawTopic]=useState('')
  const [category,setCategory]=useState('')
  const [homeDetails,setHomeDetails]=useState('');
  const [selectedHomeAddress,setSelectedHomeAddress]=useState('');
  const [filteredOption,setFilteredHomeAddresses]=useState('')
  const [homeOwnerDetails,setHomeOwnerDetails]= useState('');
  const [selectedBylawValue, setSelectedBylawValue] = useState(null);
  const [fileError, setFileError] = useState("");
  const [bylawsUpdated, setBylawsUpdated] = useState(true);
  const [fileOld,setFileOld] = useState([]);
  const [createdDate,setCreatedDate]=useState("");
  const [paidDues,setPaidDues]=useState(false);
  const [homeIds,setHomeIds]=useState("");

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,setError,watch
  } = useForm();

  useEffect(() => {
    const selectedBylaw = watch('selectedBylaw');
  
  
      if (!selectedBylaw || selectedBylaw === "") {
        setByLawDescription('');
        // setIsBylawFieldEmpty(true);
      } else {
        // Find the matching byLaw from byLawDetails
        const matchingByLaw = byLawDetails.find((byLaw) => byLaw.byLawId === selectedBylaw);
  
        if (matchingByLaw) {
          setByLawDescription(matchingByLaw.description);
          // setIsBylawFieldEmpty(false);
        } else {
          // Handle the case where no matching byLaw is found
          setByLawDescription('');
          // setIsBylawFieldEmpty(true);
        }
      }
    
  }, [watch('selectedBylaw'), byLawDetails]);

  useEffect(() => {
    const selectedHome = watch('selectedOption');
  
  
      if (!selectedHome || selectedHome === "") {
        setByLawDescription('');
        // setIsBylawFieldEmpty(true);
      } else {
        // Find the matching byLaw from byLawDetails
        const matchingHome = homeAddresses.find((home) => home.homeId === selectedHome);
  
        if (matchingHome) {
          const { name } = matchingHome;
        const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";

  setHomeDetails(selectedHomeDescription);
          // setIsBylawFieldEmpty(false);
        } else {
          // Handle the case where no matching byLaw is found
          setHomeDetails('');
          // setIsBylawFieldEmpty(true);
        }
      }
    
  }, [watch('selectedOption'), homeDetails]);
  
  const handleViewClick = () => {
    navigate(-1);
  };

  const handleEditFileChange = (file) => {
    console.log("on file change ");
    setFile(file);
    setFileError("");
    //   setFileData(`Selected File: ${file.name}`);
  };
  const handleFileOldChange = (fileOld) => {
    console.log("fileold"+fileOld);
    setFileOld(fileOld);
    }
 

  const getHomes = async () => {
    try {
      const storedToken = token;
    
      
      const response = await axios.get(`${BASE_URL}${HOMES_DETAILS}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);
  
      if (response.status === 200) {
        const data = response.data;
        setHomeOwnerDetails(data.homeOwnerDetails)
        setHomeAddresses(data); 
        const filteredHomeAddresses = data.filter(home => {
          console.log("Checking home:", home);
          return homeId.includes(home.homeId);
        });
        setFilteredHomeAddresses(filteredHomeAddresses);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error('Failed to fetch data');
      }
  
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getHomes();
  }, []);

 

  const handleHomeChange = (event, newValue) => {
    if (newValue && newValue.value !== undefined && newValue.value !== null) {
      const selectedHomeId = newValue.value;
      const selectedHome = homeAddresses.find((home) => home.homeId === selectedHomeId);
      // const homeOwner = selectedHome.homeOwnerDetails;
  
      if (selectedHome) {
        const { name } = selectedHome;
        const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";
        console.log("Selected Home Description:", selectedHomeDescription);
  
        // Set the selected home details to the state
        setHomeDetails(selectedHomeDescription);
  
        // Use setValue to set the selected homeId in the react-hook-form
        setValue("selectedOption", selectedHomeId);
  
        // You can also do other operations if needed
        console.log("Selected Home:", selectedHome);
        console.log("Home Details:", homeAddresses);
        
      } else {
        setHomeDetails('');
        setValue("selectedOption", null);
      }
    } else {
      // Handle the case when the selected option is removed (e.g., by pressing backspace)
      setHomeDetails('');
  
      // Use setValue to clear the selected homeId in the react-hook-form
      setValue("selectedOption", null);
    }
  };

  const today = dayjs(); // Current date as Day.js object
const maxDate = today; // Max date is today
const minDate = today.subtract(3, 'year'); 

  
  useEffect(() => {
 console.log('homeAddresses',homeAddresses)
 console.log('bylawTopic',bylawTopic)
 console.log('category',category)
  }, [homeAddresses,bylawTopic,category]);

  const getCategory = async () => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setCategoryDetails(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const handleCategoryChange = (event, newValue) => {
    if (newValue) {
      const selectedCategoryId = newValue.value;
    
      // Set the selected homeId to the state
      setValue("category", selectedCategoryId);
    
      // You can also do other operations if needed
    
      console.log("selectedCategoryId:", selectedCategoryId);
      console.log("Home Details:", homeAddresses);
    } else {
      setValue("category", null); // Reset to null if no category is selected
    }
  };

  const handleByLawChange = (event, newValue) => {
    if (newValue && newValue.value !== undefined && newValue.value !== null) {
      const selectedByLawId = newValue.value;
      setValue("selectedBylaw", selectedByLawId);
      const selectedByLawDescription = byLawDetails.find(
        (byLaw) => byLaw.byLawId === selectedByLawId
      )?.description;
  
      setByLawDescription(selectedByLawDescription);

      // Update the state here
      setSelectedBylawValue(newValue);
    } else {
      // Clear the state values
      setByLawDescription('');
      setValue("selectedBylaw", null);
  
      // Update the state here
      setSelectedBylawValue(null);
    }
  };

  const handleCreatedDateChange = (selectedDob) => {
    const date = new Date(selectedDob); // Convert to Date object
    let year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).slice(-2); // Add leading zero
    let day = ('0' + date.getDate()).slice(-2);          // Add leading zero
    let hours = ('0' + date.getHours()).slice(-2);       // Add leading zero
    let minutes = ('0' + date.getMinutes()).slice(-2);   // Add leading zero
    let seconds = ('0' + date.getSeconds()).slice(-2);   // Add leading zero
  
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  
    setCreatedDate(formattedDate); // Update the state with formatted date
  };
  
  const renderedAddresses = homeAddresses
  .filter(home => !filteredOption.includes(home)) // Exclude filteredHomeAddresses
  .map((home, index) => ({
    address: home.address, 
    value: home.homeId,   
    key: index,
  }));

  const renderedCategory = categoryDetails
    .filter(category => category.type === 'CR') // Filter categories with type 'CR'
    .map((category, index) => ({
      category: category.category,
      value: category.categoryId,
      key: index,
    }));

    const renderedBylaw = byLawDetails.map((ByLaw, index) => ({
      topic: ByLaw.topic,
      value: ByLaw.byLawId,
      key: index,
    }));

    const getBylaw = async () => {
      try {
        const storedToken = token;
  
        const response = await axios.get(`${BASE_URL}${BYLAW}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        console.log(response.data);
  
        if (response.status === 200) {
          const data = response.data;
      console.log("byLaw data",data);
          setByLawDetails(data);
          setBylawsUpdated(data.length > 0);
        } else {
          setIsLoading(false);
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
   
        console.error("Error fetching data:", error);
      }
    };
  
    useEffect(() => {
      getBylaw();
    }, []); 
  
    // useEffect(() => {
    //   // Reset fileError when a valid file is selected
    //   if (file && file.size <= 10 * 1024 * 1024) {
    //     console.log("File:", file);
    //     const fileType = file.type && file.type.split("/")[1]; // Check if file.type exists
    //     console.log("File type:", fileType);
    //     if (fileType) {
    //       const fileExtension = fileType.toUpperCase().replace(/[^\w]/g, ""); // Remove special characters
    //       console.log("File extension:", fileExtension);
    //       if (fileTypes.includes(fileExtension)) {
    //         setFileError("");
    //       }
    //     }
    //   }
    // }, [file, fileTypes]);


    const getComplianceRequestDetailsById = async () => {
      try {
        const storedToken = token;
        setIsLoading(true);
        console.log(storedToken);
        const response = await axios.get(
          `${BASE_URL}${COMPLIANCE_REQUEST_EDIT_BY_ID}/${complainceId}`,
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
        console.log(response.data);
        console.log(response.data.bylawId);
  
        if (response.status === 200) {
          const data = response.data.compliance;
          const home = response.data.compliance.address.address;
         setSelectedHomeAddress(home)
          console.log("Home",home)
          setComplianceRequestData(data);
          setDescription(data.description);
          setFileData(data.attachments);
          setIsLoading(false);
          setCrId(data.crId);

          const paidDues = response.data.homeOwnerDues;

          // Log the value to ensure we are working with the correct data
          console.log("homeOwnerDues:", paidDues);
          
          if (Array.isArray(paidDues) && paidDues.length > 0) {
            // If the array is not empty, set paidDues to true
            setPaidDues(true);
            // Log to confirm the result
            console.log("Paid dues found:", paidDues);
        } else {
            console.log("paidDues is either empty or not an array");
            setPaidDues(false); // If the array is empty, set to false
        }
        
        console.log("complainceId",data.crCreatedDate);
          setCreatedDate(data.crCreatedDate)
          const matchingHome = homeAddresses.find((home) => home.homeId === data.homeId);
console.log("matchingHome", matchingHome);

if (matchingHome) {
  setSelectedHomeAddress(matchingHome.address);
  setValue('selectedOption', matchingHome.homeId);
  setHomeIds(matchingHome.homeId)
  const { name } = matchingHome;
  const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";
  setHomeDetails(selectedHomeDescription);
} else {
  setSelectedHomeAddress(''); // Handle when matching home is not found
  setValue("selectedOption", ''); // Reset to an empty string if no matching home is found
  setHomeDetails('');
}
          
const matchingByLaw = byLawDetails.find((byLaw) => byLaw.byLawId === data.bylawId);
console.log("matchingByLaw", matchingByLaw);
console.log("byLawDetails", byLawDetails);

if (matchingByLaw) {
  setBylawTopic(matchingByLaw.topic)
  setValue('selectedBylaw', matchingByLaw.byLawId);
  setByLawDescription(matchingByLaw.description)
  console.log("Bylaw: " + matchingByLaw.byLawId);
} else {
  setValue("selectedBylaw", ''); // Reset to an empty string if no matching bylaw is found
  setByLawDescription('');
}

const matchingCategory = categoryDetails.find((category) => category.categoryId === data.category);
console.log("matchingCategory", matchingCategory);
console.log("categoryDetails", categoryDetails);
if(matchingCategory){
  setCategory(matchingCategory.category)
console.log('category', matchingCategory.categoryId)
  setValue('category', matchingCategory.categoryId)
}else{
  setCategory('')
  setValue('category', '')
}   
        } else {
          setIsLoading(false);
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        // Handle error
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    useEffect(() => {
      getComplianceRequestDetailsById();
    }, [homeAddresses,byLawDetails,categoryDetails]);



const onSubmit = async (data) => {
  // if(file.length===0){
  //   setFileError("Attachment is Required")
  //   return;
  //   }
  // const totalFileSize = file.reduce((acc, file) => acc + file.size, 0);
  // const maxSizeInMB = 10;
  // const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

  // if (totalFileSize > maxSizeInBytes) {
  //   return;
  // }

  // // Check file types
  // const allowedFileTypes = ["image/jpg", "image/png", "image/jpeg", "application/pdf"];
  // const invalidFiles = file.filter(file => !allowedFileTypes.includes(file.type));

  // if (invalidFiles.length > 0) {
  //   return;
  // }
  if (!description || description.trim() === "") {
    setError("violationDescription", {
      type: "manual",
      message: "Description is required ",
    });
    return;
  }

  if (!createdDate || createdDate.trim() === "") {
    setError("createdDate", {
      type: "manual",
      message: {CREATED_DATE_REQUIRED},
    });
    return;
  }
 
  if(file.length ===0 && fileOld.length ===0){

    setFileError("Attachment is required");
    return;
    }
// const totalFileSize = file.reduce((acc, file) => acc + file.size, 0);
// const maxSizeInMB = 10;
// const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
  
 

//   const allowedFileTypes = ["image/jpg", "image/png", "image/jpeg", "application/pdf"];
//   const invalidFiles = file.filter(file => !allowedFileTypes.includes(file.type));

//   if (invalidFiles.length > 0) {
//     console.log("max size ");
//     setFileError("Some files have invalid file types. Allowed only PDF file type.");
//     return;
//   }
//   const oversizedFiles = file.filter(file => file.size > maxSizeInBytes);
//   if (oversizedFiles.length > 0) {
//     console.log("file sixe is morrrr");
//     // setFileError("Some files exceed the maximum allowed size of 10 MB.");
//     return;
//   }
const totalFileSize = file.reduce((acc, file) => acc + file.size, 0);
const maxSizeInMB = 10;
const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

if (totalFileSize > maxSizeInBytes) {
  return;
}

// Check file types
const allowedFileTypes = ["image/jpg", "image/png", "image/jpeg", "application/pdf"];
const invalidFiles = file.filter(file => !allowedFileTypes.includes(file.type));

if (invalidFiles.length > 0) {
  return;
}

  


setIsLoading(true);
  const formData = new FormData();
  if (paidDues) {
    if (homeIds) {
        formData.append('homeId', homeIds);
    } else {
        console.error('homeIds is undefined or null.');
    }
} else {
    if (data.selectedOption) {
        formData.append('homeId', data.selectedOption);
    } else {
        console.error('data.selectedOption is undefined or null.');
    }
}
 
    formData.append('category',data.category);
    formData.append('description',description);
    // formData.append('file',file);
    file.forEach((file) => {
      formData.append('files', file);
      });
    formData.append('bylawId',data.selectedBylaw);
  formData.append('role',roles[0]);
  formData.append("crCreatedDate",createdDate);

  // console.log('homeId:', data.selectedOption.homeId);
  // console.log('category:', data.category.categoryId);
  // console.log('description:', data.violationDescription);
  // console.log('file:', file); 
  // // console.log('bylawId:', data.selectedBylaw.byLawId);
  // console.log('role:', 'ROLE_HO');

  // console.log('formData:', formData);

  const storedToken = token

  console.log(storedToken);
  const headers = {
    'Content-Type': 'multipart/form-data'
  };
  // return;
  
  
  request({url:`${BASE_URL}${COMPLIANCE_REQUEST_BY_ID}/${complainceId}`, method:"put", data:formData, headers}).then(res=>{
setIsLoading(false); 
console.log(res);
if(res.status==200){
  
  setIsLoading(false); 
  setTimeout(() => {
    navigate(-1);
  }, 1000); // Adjust the delay time as needed (in milliseconds)
  
}
}).catch(error=>{
console.log(error.response);
setIsLoading(false); 
if (error.response) {
  setIsLoading(false);

  console.log("error.response.status" + error.response.status);
 

}  
});
};

  
    return (
      <div>
        <div class="flex flex-col md:flex-row">
          <CustomHeading title="Edit Compliance" />
  
          <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
            <CustomButton
              fieldType="button"
              buttonName="Back"
              click={handleViewClick}
            />
          </div>
        </div>
        <Card>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-4 md:space-y-6 "
          >
            <CardContent>
              <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              {/* <CustomInputField
                  fieldName="complianceRequestId"
                  fieldId="complianceRequestId"
                  fieldType="text"
                  fieldLabel="Compliance Request ID"
                  fieldControl={control}
                  fieldError={errors}
                  disabled={true}
                />

<CustomInputField
                  fieldName="violationDescription"
                  requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
                  minLength={2}
                  minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                  maxLength={5000}
                  maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
                  fieldId="violationDescription"
                  fieldType="text"
                  fieldLabel="Violation Description"
                  fieldControl={control}
                  fieldError={errors}
                  fieldRequiredIcon="*"
                  multiline={true}
                  numberOfRows={1}
                />
                <Controller
                  name="selectedOption"
                  control={control}
                  rules={{ required: HOME_ADDRESS_REQUIRED_MESSAGE }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth error={Boolean(error)}>
                      <Autocomplete
                        fullWidth
                        {...field}
                        id="combo-box-demo"
                        options={homeAddess}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) => {
                          setValue("selectedOption", newValue, {
                            shouldDirty: true,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <span>
                                Select Home Address <span style={{ color: 'red' }}>*</span>
                              </span>
                            }
                            variant="standard"
                            error={Boolean(error) && !field.value}
                            helperText={
                              Boolean(error) && !field.value ? error.message : ""
                            }
                          />
                        )}
                      />
                    </FormControl>
                  )}
                />

<Controller
                  name="category"
                  control={control}
                  rules={{ required: CATEGORY_REQUIRED_MESSAGE }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth error={Boolean(error)}>
                      <Autocomplete
                        fullWidth
                        {...field}
                        id="combo-box-demo"
                        options={category}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) => {
                          setValue("category", newValue, {
                            shouldDirty: true,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <span>
                                Select Category/Subject <span style={{ color: 'red' }}>*</span>
                              </span>
                            }
                            variant="standard"
                            error={Boolean(error) && !field.value}
                            helperText={
                              Boolean(error) && !field.value ? error.message : ""
                            }
                          />
                        )}
                      />
                    </FormControl>
                  )}
                />
  
                <CustomInputField
                  fieldName="homeOwnerDetails"
                  fieldId="homeOwnerDetails"
                  fieldType="text"
                  fieldLabel="HomeOwner Details"
                  fieldControl={control}
                  fieldError={errors}
                  disabled={true}
                />
  
  
                <Controller
                  name="selectedBylaw"
                  control={control}
                  rules={{ required: BYLAW_REQUIRED_MESSAGE }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth error={Boolean(error)}>
                      <Autocomplete
                        fullWidth
                        {...field}
                        id="combo-box-demo"
                        options={bylaw}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) => {
                          setValue("selectedOption", newValue, {
                            shouldDirty: true,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <span>
                                Select Bylaw <span style={{ color: 'red' }}>*</span>
                              </span>
                            }
                            variant="standard"
                            error={Boolean(error) && !field.value}
                            helperText={
                              Boolean(error) && !field.value ? error.message : ""
                            }
                          />
                        )}
                      />
                    </FormControl>
                  )}
                />
  
                <CustomInputField
                  fieldName="bylawDescription"
                  fieldId="bylawDescription"
                  fieldType="text"
                  fieldLabel="Bylaw Description"
                  fieldControl={control}
                  fieldError={errors}
                  disabled={true}
                />
  
  <FormLabel>
                Attach Documents <span style={{color:'red'}}>*</span>
                <FileUploader 
                handleChange={handleFileChange}
                name="file"
                types={fileTypes}
                required
              />
              {file ? <p>Selected File: {file.name}</p> : <p>No file selected</p>}
              </FormLabel> */}
               <CustomTooltipField
                fieldName="complianceRequestId"
                fieldId="complianceRequestId"
                fieldType="text"
                fieldLabel="Compliance Request ID"
                fieldControl={control}
                fieldError={errors}
                disabled={true}
                value={crId}
                tooltipMessage={"This field displays the unique identifier for the compliance request."}

              />
<CustomAutoCompleteEdit
              fieldName="selectedOption"
              requiredErrorMessage = {HOME_ADDRESS_REQUIRED_MESSAGE}
              id="selectId"
              options={renderedAddresses}
              fieldLabel="Select Home Address"
              fieldControl={control}
              error={errors}
              setValue={setValue}
              fieldRequiredIcon="*" 
              onChange={handleHomeChange}
              value={selectedHomeAddress}
              invalidOption={HOME_ADDRESS_INVALID_MESSAGE}
              tooltipMessage={paidDues ? "Already fine added for this compliance, so you can't edit this field." : "Please select the home address of the homeowner you are creating the compliance for."}
              disabled={paidDues}
               />
               <CustomTooltipField
                fieldName="homeOwnerDetails"
                fieldId="homeOwnerDetails"
                fieldType="text"
                fieldLabel="HomeOwner Name"
                fieldControl={control}
                fieldError={errors}
                disabled={true}
                value={homeDetails}
                multiline={true}
                numberOfRows={2}
                tooltipMessage={"This field displays the name of the homeowner based on the selected home address."}
              />
                 <CustomAutoCompleteEdit
                fieldName="category"
                requiredErrorMessage = {CATEGORY_SUBJECT_REQUIRED_MESSAGE}
                id="selectId"
                options={renderedCategory}
                fieldLabel="Select Category/Subject"
                fieldControl={control}
                error={errors}
                setValue={setValue}
                fieldRequiredIcon="*" 
                onChange={handleCategoryChange}
                value={category}
                invalidOption={CATEGORY_INVALID_MESSAGE}
                tooltipMessage={"Please select the category or subject relevant to the compliance issue."}
              />
<CustomTooltipField
                fieldName="violationDescription"
                // requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={5000}
                maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
                fieldId="violationDescription"
                fieldType="text"
                fieldLabel="Violation Description"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                multiline={true}
                numberOfRows={2}
                value={description}
                onInput={(event)=>{setDescription(event.target.value)}}
                tooltipMessage={"Provide a detailed description of the violation or compliance issue."}

              />
           {/* {homeOwnerDetails !=='' && homeOwnerDetails !== null && (  */}
             
               {/* )} */}

            

{/* {homeOwnerDetails !=='' && homeOwnerDetails !== null && (    */}

              {/* )} */}

{/* <CustomAutoCompleteEdit
        fieldName="selectedBylaw"
        // requiredErrorMessage={BYLAW_REQUIRED_MESSAGE}
        isRequired={false}
        id="selectId"
        options={renderedBylaw}
        fieldLabel="Select Bylaw"
        fieldControl={control}
        error={errors}
        setValue={setValue}
        onChange={handleByLawChange}
        value={selectedBylawValue}
        invalidOption={BYLAW_REQUIRED_MESSAGE}
        // fieldRequiredIcon="*"
      /> */}
      <div className="h-1/2">
{!bylawsUpdated ? (<Tooltip title="The bylaw in your association is not updated, so please contact the group admin." placement="bottom">
      <span>
        <CustomAutoComplete
          fieldName="selectedBylaw"
          id="selectId"
          options={renderedBylaw}
          fieldLabel="Select Bylaw"
          fieldControl={control}
          setValue={setValue}
          onChange={handleByLawChange}
          value={selectedBylawValue}
          isRequired={false}
          invalidOption={BYLAW_REQUIRED_MESSAGE}
          bylawNull="no bylaw"
          disabled={!bylawsUpdated}
          tooltipMessage={"Please select the relevant bylaw if applicable"}

        />
      </span>
    </Tooltip>) : <CustomAutoComplete
          fieldName="selectedBylaw"
          id="selectId"
          options={renderedBylaw}
          fieldLabel="Select Bylaw"
          fieldControl={control}
          setValue={setValue}
          onChange={handleByLawChange}
          value={selectedBylawValue}
          isRequired={false}
          invalidOption={BYLAW_REQUIRED_MESSAGE}
          bylawNull="no bylaw"
          tooltipMessage={"Please select the relevant bylaw if applicable"}

        />
      }
    </div>
    {byLawDescription ? (
                <Box>
                  <Typography style={{ fontSize: "16px", color: "grey" }}>Bylaw Description</Typography>
                  <CustomInputFieldExpand
                    fieldName="bylawDescription"
                    fieldId="bylawDescription"
                    fieldType="text"
                    fieldControl={control}
                    fieldError={errors}
                    disabled={true}
                    multiline={true}
                    value={byLawDescription}
                    // tooltipMessage={"text"}
                  />
                </Box>
              ):<p/>}
    
    {/* <FormLabel>
            Attach Documents{" "}
            <Tooltip
                title={`Please note that uploading a new document will override the existing one`}
            >
                <IconButton>
                <InfoOutlined />
                </IconButton>
            </Tooltip>
            <FileUploader
                handleChange={handleFileChange}
                name="file"
                // types={fileTypes}
            />
            {fileData ? (
                <p
                    style={{ cursor: "pointer" }}
                    onClick={() => handleFileDataClick(fileData, token)}
                >
                    <FileDownloadIcon />
                    {fileData}
                </p>
            ) : (
                <p>No file selected</p>
            )}
       {fileError && <p className="text-red-600 text-sm">{fileError}</p>}
              </FormLabel> */}
               {/* <CustomTooltipField
                fieldName="bylawDescription"
                fieldId="bylawDescription"
                fieldType="text"
                fieldLabel="Bylaw Description"
                fieldControl={control}
                fieldError={errors}
                 disabled={true}
                value={byLawDescription}
                tooltipMessage={""}

              />
             

{byLawDescription ? (
                <Box>
                  <Typography style={{ fontSize: "16px", color: "grey" }}>Bylaw Description</Typography>
                  <CustomInputFieldExpand
                    fieldName="bylawDescription"
                    fieldId="bylawDescription"
                    fieldType="text"
                    fieldControl={control}
                    fieldError={errors}
                    disabled={true}
                    multiline={true}
                    value={byLawDescription}
                    // tooltipMessage={"text"}
                  />
                </Box>
              ):<p/>}

            {/* <CustomFileEditArcComplain downloadFiles={true} fileData={fileData} handleFileChange={handleFileChange} token={token} file={file} isRequired={true} required={true} fileError={fileError} onFileOldChange={handleFileOldChange}/> */}

            {/* <FormLabel>
              Upload Document (PDF Format only){" "}<Tooltip
        title={`Please note that uploading a new document will override the existing one`}
      >
        <IconButton>
          <InfoOutlined />
        </IconButton>
      </Tooltip>
              {/* <span style={{ color: "red" }}>*</span> */}
              {/* <FileUploader
                handleChange={handleEditFileChange}
                name="file"
                types={fileTypes}
                // required
              />
              {fileData ? <p>{fileData}</p> : <p>No file selected</p>}
            </FormLabel> */} 

<CustomDatePicker
                fieldName="createdDate"
                label="Created Date"
                maxDate={maxDate}  
                minDate={minDate}
                // requiredErrorMessage={CREATED_DATE_REQUIRED}
                control={control}
                value={createdDate}
                onChange={handleCreatedDateChange}
                fieldRequiredIcon="*"
                tooltipMessage={paidDues ? "Already fine added for this compliance, so you can't edit this field." : "Select the created date for compliance."}
              disabled={paidDues}
              />
             <CustomFileEditArcComplain 
            downloadFiles={true} 
            fileError={fileError}
            fileData={fileData} 
            handleFileChange={handleEditFileChange} 
            token={token} 
            file={file}
            isRequired={true}
            onFileOldChange={handleFileOldChange}
            
            />
                      </section>
              <div class="text-center mt-10">
                <CustomButton fieldType="submit" buttonName="Update" />
              {isLoading && <CustomLoading />}

              </div>
            </CardContent>
          </form>
        </Card>
        <CustomToastContainer />
      </div>
    );
  }

export default ArcComplianceEdit