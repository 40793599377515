import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearAuthToken, setToken } from "./redux/Hybridhoa";
import CustomLayout from "./components/CustomLayout";
import Dashboard from "./screens/dashboard/Dashboard";
import Profile from "./screens/profile/Profile";
import ChangePassword from "./screens/changepassword/ChangePassword";
import ComplianceRequestList from "./screens/compliancerequest/ComplianceRequestList";
import RaisedByArcList from "./screens/compliancerequest/RaisedByArcList";
import MaintenanceRequest from "./screens/maintenancerequest/MaintenanceRequest";
import PaymentHistory from "./screens/payment/PaymentHistory";
import ReimburseDue from "./screens/payment/ReimburseDue";
import ArchivedNotification from "./screens/notification/ArchivedNotification";
import Notification from "./screens/notification/Notification";
import EventList from "./screens/event/EventList";
import DocumentList from "./screens/document/DocumentList";
import ArchivedDocumentList from "./screens/document/ArchivedDocumentList";
import NotificationView from "./screens/notification/NotificationView";
import ArchivedNotificationView from "./screens/notification/ArchivedNotificationView";
import EventView from "./screens/event/EventView";
import DocumentView from "./screens/document/DocumentView";
import ArchivedDocumentView from "./screens/document/ArchivedDocumentView";
import AddComplianceRequest from "./screens/compliancerequest/AddComplianceRequest";
import ComplianceRequestEdit from "./screens/compliancerequest/ComplianceRequestEdit";
import MaintenanceRequestAdd from "./screens/maintenancerequest/MaintenanceRequestAdd";
import MaintenanceRequestEdit from "./screens/maintenancerequest/MaintenanceRequestEdit";
import RenterList from "./screens/renter/RenterList";
import RenterAdd from "./screens/renter/RenterAdd";
import RenterEdit from "./screens/renter/RenterEdit";
import ComplianceRequestView from "./screens/compliancerequest/ComplianceRequestView";
import RaisedByArcView from "./screens/compliancerequest/RaisedByArcView";
import MaintenanceRequestView from "./screens/maintenancerequest/MaintenanceRequestView";
import Faq from "./screens/faq/Faq";
import GuProfile from "./screens/profile/guprofile/GuProfile";
import GuDashboard from "./screens/dashboard/gudashboard/GuDashboard";
import GuMaintenanceList from "./screens/maintenancerequest/gumaintenance/GuMaintenanceList";
import GuMaintenanceView from "./screens/maintenancerequest/gumaintenance/GuMaintenanceView";
import InvoiceList from "./screens/invoice/InvoiceList";
import InvoiceView from "./screens/invoice/InvoiceView";
import ArcDashboard from "./screens/dashboard/arcdashboard/ArcDashboard";
import McDashboard from "./screens/dashboard/mcdashboard/McDashboard";
import BmDashboard from "./screens/dashboard/bmdashboard/BmDashboard";
import ArcComplianceList from "./screens/compliancerequest/arccompliancerequest/ArcComplianceList";
import BmRaisedByArcList from "./screens/compliancerequest/bmcompliancerequest/BmRaisedByArcList";
import McRaisedByArcList from "./screens/compliancerequest/mccompliancerequest/McRaisedByArcList";
import ArcComplianceAdd from "./screens/compliancerequest/arccompliancerequest/ArcComplianceAdd";
import ArcComplianceEdit from "./screens/compliancerequest/arccompliancerequest/ArcComplianceEdit";
import ArcComplianceView from "./screens/compliancerequest/arccompliancerequest/ArcComplianceView";
import RaisedByHoList from "./screens/compliancerequest/RaisedByHoList";
import TemplateList from "./screens/compliancerequest/TemplateList";
import MaintenanceRequestList from "./screens/maintenancerequest/mcbmarcmaintenance/MaintenanceRequestList";
import McBmArcDocumentList from "./screens/document/mcbmarcdocument/McBmArcDocumentList";
import McBmArcArchivedDocumentList from "./screens/document/mcbmarcdocument/McBmArcArchivedDocumentList";
import McBmArcArchivedDocumentView from "./screens/document/mcbmarcdocument/McBmArcArchivedDocumentView";
import McBmArcDocumentView from "./screens/document/mcbmarcdocument/McBmArcDocumentView";
import McBmArcNotificationList from "./screens/notification/mcbmarcnotification/McBmArcNotificationList";
import McBmArcArchivedNotificationList from "./screens/notification/mcbmarcnotification/McBmArcArchivedNotificationList";
import McBmArcArchivedNotificationView from "./screens/notification/mcbmarcnotification/McBmArcArchivedNotificationView";
import McBmArcEventList from "./screens/event/mcbmarcevent/McBmArcEventList";
import McBmArcNotificationView from "./screens/notification/mcbmarcnotification/McBmArcNotificationView";
import ReviewInvoiceList from "./screens/invoice/ReviewInvoiceList";
import ReviewInvoiceView from "./screens/invoice/ReviewInvoiceView";
import McBmArcEventView from "./screens/event/mcbmarcevent/McBmArcEventView";
import ArcMcBmRequestReport from "./screens/requestreport/ArcMcBmRequestReport";
import ArcMcBmHomeActivityReport from "./screens/requestreport/ArcMcBmHomeActivityReport";
import BmHomeActivityReport from "./screens/requestreport/BmHomeActivityReport";
import McBmArReport from "./screens/requestreport/McBmArReport";
import ArcMcBmRequestReportCrView from "./screens/requestreport/ArcMcBmRequestReportCrView";
import ArcMcBmRequestReportMrView from "./screens/requestreport/ArcMcBmRequestReportMrView";

import ArcMcBmHomeActivityMrView from "./screens/requestreport/ArcMcBmHomeActivityMrView";
import ArcMcBmHomeActivityCrView from "./screens/requestreport/ArcMcBmHomeActivityCrView";
import McBmArReportView from "./screens/requestreport/McBmArReportView";
import RenterView from "./screens/renter/RenterView";
import UserPage from "./screens/userPage/userPage";
import RequestForAccess from "./screens/requestforaccess/RequestForAccess";

import MaintenanceRequestListGeneralUser from "./screens/maintenancerequest/mcbmarcmaintenance/MaintenanceRequestListGeneralUser";
import MaintenanceRequestViewGeneralUser from "./screens/maintenancerequest/mcbmarcmaintenance/MaintenanceRequestViewGeneralUser";
import RaisedByHoView from "./screens/compliancerequest/RaisedByHoView";

import IdleTimeout from "./screens/idletimeout/IdleTimeout";

// import Forgot from "./screens/forgotpage/Forgot";
import PhoneOtpVerification from "./screens/contactverification/PhoneOtpVerfication";
import ArcMaintenanceView from "./screens/maintenancerequest/mcbmarcmaintenance/ArcMaintenanceView";
import BmMaintenanceView from "./screens/maintenancerequest/mcbmarcmaintenance/BmMaintenanceView";
import Discussionboard from "./screens/DiscussionBoard/Discussionboard"
import ForgotChange from "./screens/forgotpage/ForgotChange";
import Forgot from "./screens/forgotpage/Forgot";


import MaintenanceRequestAddGeneralUser from "./screens/maintenancerequest/mcbmarcmaintenance/MaintenanceRequestAddGeneralUser"
import MaintenanceRequestEditGeneralUser from "./screens/maintenancerequest/mcbmarcmaintenance/MaintenanceRequestEditGeneralUser";
import ContactVerified from "./screens/contactverification/contactVerified";
import Dues from "./screens/dues/dues";
import EmailVerified from "./screens/emailVerify/emailverify";
import BmMaintenanceList from "./screens/maintenancerequest/mcbmarcmaintenance/BmMaintenanceList";
import ArcEventList from "./screens/event/mcbmarcevent/ArcEventList";
import McEventList from "./screens/event/mcbmarcevent/McEventList";
import ArcArchievedDocument from "./screens/document/mcbmarcdocument/ArcArchivedDocumentList"
// import McAddMaintenance from "./screens/maintenancerequest/mcmaintenance/McAddMaintenance";
import McBmArReportPaidView from "./screens/requestreport/McBmArReportPaidView";
import ArcMcBmHomeActivitynew from "./screens/requestreport/ArcMcBmHomeActivitynew";
import LinkEmail from "./screens/LinkEmail";
import TestLogin from "./screens/loginpage/TestLogin";
import McMaintenanceRequestAdd from "./screens/maintenancerequest/mcbmarcmaintenance/McMaintenanceAdd";

import InvoiceEdit from "./screens/invoice/InvoiceEdit";
import InvoiceUpload from "./screens/invoice/Invoice Upload";
import McBmArcNotificationAdd from "./screens/notification/mcbmarcnotification/McBmArcNotificationAdd";
import McBmArcNotificationEdit from "./screens/notification/mcbmarcnotification/McBmArcNotificationEdit";

import McMaintenanceEdit from "./screens/maintenancerequest/mcbmarcmaintenance/McMaintenanceEdit";

import McBmArcSendNotification from "./screens/notification/mcbmarcnotification/McBmArcSendNotification";

import ArcRequestReport from "./screens/requestreport/ArcRequestReport";
import TemplateAdd from "./screens/Template/templateadd";
import Templateedit from "./screens/Template/templateedit";


import RenterAddMC from "./screens/renter/RenterMc/RenterAddMc"
import RenterEditMc from "./screens/renter/RenterMc/RenterEditMc";
import RenterViewMc from "./screens/renter/RenterMc/RenterViewMc";
import RenterListMc from "./screens/renter/RenterMc/RenterListMc";


import McMaintenanceList from "./screens/maintenancerequest/mcbmarcmaintenance/McMaintenanceList";

import PaymentMethod from "./screens/payment/PaymentMethod";


import McBmArcAddEvent from "./screens/event/mcbmarcevent/McBmArcAddEvent";
import McBmArcEditEvent from "./screens/event/mcbmarcevent/McBmArcEditEvent";
import McBmArcUnHideDocument from "./screens/document/mcbmarcdocument/McBmArcUnHideDocument";
import McBmArcEditDocument from "./screens/document/mcbmarcdocument/McBmArcEditDocument";
import McBmArcAddDocument from "./screens/document/mcbmarcdocument/McBmArcAddDocument";
import Login from "./screens/loginpage/Login"
import MaintenanceRequestListHoInMc from "./screens/maintenancerequest/mcbmarcmaintenance/MaintenanceRequestHoInMc";
import Viewraisedbyarc from "./screens/compliancerequest/viewraisedbyarcbm";
import McComplianceView from "./screens/compliancerequest/arccompliancerequest/McComplianceView";
import McComplianceList from "./screens/compliancerequest/arccompliancerequest/McComplainList";
import McArcNotificationView from "./screens/notification/mcbmarcnotification/McArcNotificationView";
import McMaintenanceView from "./screens/maintenancerequest/mcbmarcmaintenance/McMaintenanceView";
import MaintenanceHoMcView from "./screens/maintenancerequest/mcbmarcmaintenance/MaintenanceHoMcView";
import McRaisedByArcView from "./screens/compliancerequest/McRaisedByArcView";
import DiscussionBoardAdd from "./screens/DiscussionBoard/DiscussionBoardAdd";
import DiscussionBoardEdit from "./screens/DiscussionBoard/Discussionboardedit";
// import McBmArcEditDocument from "./screens/document/mcbmarcdocument/McBmArcEditDocument";
// import McBmArcAddDocument from "./screens/document/mcbmarcdocument/McBmArcAddDocument";


function App() {
  const { token, roles ,userLoginDetails} = useSelector((state) => state.hybridhoa);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location= useLocation();

  // useEffect(() => {
  //   const storedToken = localStorage.getItem("token");
  //   const storedRoles = localStorage.getItem("roles");

  //   if (token === null && storedToken) {
  //     // Set the token from local storage to Redux state
  //     dispatch(setToken(storedToken));
  //   }

  // //   if (!token) {
  // //     // If no token exists, redirect to login
  // //     navigate("/");
  // //   }
  // //   // else{
  // //   //   navigate('/dashboard')
  // //   // }
  // }, [token, dispatch, navigate]);
  useEffect(() => {
    console.log("localtion" , window.location.href)
    console.log("localtion" , location.pathname)
    console.log("localtion" , location.pathname.startsWith('/verifyEmail'))
    if(location.pathname.startsWith('/verifyEmail')|| location.pathname.startsWith('/verifyContact')){
      // if (typeof Crisp !== 'undefined' && Crisp.websiteId) {
      //   Crisp.chat.hide();
      // } else {
      //   console.warn('Crisp is not initialized properly.'); // Log a warning
      // }
      // navigate(location.pathname)
      // Crisp.chat.hide()
      localStorage.clear(); 
  
      dispatch(clearAuthToken());
      ;
      navigate(location.pathname)

    }else{
      const storedToken = localStorage.getItem("token");
      const storedRoles = localStorage.getItem("roles");

      if (token === null && storedToken) {
        // Set the token from local storage to Redux state
        dispatch(setToken(storedToken));
      }
    }
 

    // if (!token) {
    //   // If no token exists, redirect to login
    //   navigate("/");
    // }
    // else{
    //   navigate('/dashboard')
    // }
  }, [token, dispatch, navigate]);

  useEffect(() => {
    if (token !== null) {
      if (location.pathname.startsWith('/')) {
        if (userLoginDetails.phoneVerified === false) {
          navigate("/contactverification");
        }
      }
    }
  }, [token, userLoginDetails, navigate]);
  return (
    <>
      {token == null && (<>
        <Routes>
          {/* <Route path="/" element={<Login />} /> */}
          <Route path="/" element={<Login />} />
          <Route path="/requestforaccess" element={<RequestForAccess />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/forgetpassword/:tokenId" element={<ForgotChange />} />
          <Route path="/verifyContact/:tokenId" element={<ContactVerified />} />
          <Route path="/verifyEmail/:tokenId" element={<EmailVerified />} />
          <Route path="/linkemail" element={<LinkEmail />} />

        </Routes>
      </>
      )}

{token !== null && userLoginDetails.phoneVerified === false && ( 
  <Routes>
       <Route path="/contactverification" element={<PhoneOtpVerification/>}/>
  </Routes>
)}
 
      {
        token != null  ?
        <Routes>
        {roles != "ROLE_GU" && roles != "ROLE_MC" && (
          <Route path="/userrole" element={<UserPage />} />
        )}
      </Routes>
      :null
      }

      {token != null && (
        <>
            <IdleTimeout />
          <CustomLayout>
            <Routes>
              {/* HomeOwner Routing Start */}
              {roles == "ROLE_ARC" ? (
                <Route path="/dashboard-arc" element={<ArcDashboard />} />
              ) : roles == "ROLE_GU" ? (
                <Route path="/dashboard-gu" element={<GuDashboard />} />
              ) : roles == "ROLE_MC" ? (
                <Route path="/dashboard-mc" element={<McDashboard/>} />
              ) : roles == "ROLE_BM" ? (
                <Route path="/dashboard-bm" element={<BmDashboard />} />
              ) : (
                <Route path="/dashboard-ho" element={<Dashboard />} />
              )}
              {roles == "ROLE_GU" || roles == "ROLE_MC" ? (
                <Route path="/gumcprofile" element={<GuProfile />} />
              ) : (
                <Route path="/profile" element={<Profile />} />
              )}
              <Route path="/changepassword" element={<ChangePassword />} />
              <Route
                path="/compliancerequest"
                element={<ComplianceRequestList />}
              />
               <Route 
                path="/discussionboard" 
              element={<Discussionboard/>}/>
              <Route
                path="/compliancerequestadd"
                element={<AddComplianceRequest />}
              />
              <Route
                path="/compliancerequestedit/:complainceId"
                element={<ComplianceRequestEdit />}
              />
              <Route
                path="/compliancerequest/:complainceId"
                element={<ComplianceRequestView />}
              />
              <Route
                path="/mcraisedbyarc/:complainceId"
                element={<McRaisedByArcView />}
              />
              <Route path="/raisedbyarc" element={<RaisedByArcList />} />
              <Route
                path="/raisedbyarc/:complainceId"
                element={<RaisedByArcView />}
              />
              {/* Fixed by ranjith */}
              <Route
                path="/raisedbyarcs/:complainceId"
                element={<Viewraisedbyarc />}
              />
                            {/* Fixed by ranjith */}

               <Route
                path="/raisedbyho/:complainceId"
                element={<RaisedByHoView />}
              />
              {roles == "ROLE_HO" ? (
                <Route
                  path="/maintenancerequest"
                  element={<MaintenanceRequest />}
                />
              ) : (
                ""
              )}
              {roles == "ROLE_HO" ? (
                <Route
                  path="/due"
                  element={<Dues />}
                />
              ) : (
                ""
              )}
              <Route
                path="/maintenancerequestadd"
                element={<MaintenanceRequestAdd />}
              />
              <Route
                path="/maintenancerequestedit/:maintenanceId"
                element={<MaintenanceRequestEdit />}
              />
              <Route
                path="/mcmaintenancerequestedit/:maintenanceId"
                element={<McMaintenanceEdit />}
              />
              <Route
                path="/maintenancerequestmcadd"
                element={<McMaintenanceRequestAdd />}
              />
              <Route
                path="/maintenancerequest/:maintenanceId"
                element={<MaintenanceRequestView />}
              />
              {/* {roles == "ROLE_ARC" || roles == "ROLE_BM" ? (
                <Route
                  path="/bmarcmaintenancerequestview/:maintenanceId"
                  element={<BmArcMaintenanceView />}
                />
              ) : (
                ""
              )} */}
              {roles == "ROLE_MC" ? (
                <Route
                  path="/mcmaintenance"
                  element={<McMaintenanceList />}
                />
              ) : (
                ""
              )}
              {roles == "ROLE_ARC" ? (
                <Route
                  path="/arcmaintenance/:maintenanceId"
                  element={<ArcMaintenanceView />}
                />
              ) : (
                ""
              )}
               {roles == "ROLE_MC" ? (
                <Route
                  path="/mcmaintenance/:maintenanceId"
                  element={<McMaintenanceView />}
                />
              ) : (
                ""
              )}
               {roles == "ROLE_BM" ? (
                <Route
                  path="/bmmaintenance/:maintenanceId"
                  element={<BmMaintenanceView />}
                />
              ) : (
                ""
              )}
              <Route path="/renter" element={<RenterList />} />
              <Route path="/renteradd" element={<RenterAdd />} />
              <Route path="/renteredit/:renterId" element={<RenterEdit />} />
              <Route path="/renter/:renterId" element={<RenterView />} />
              <Route path="/event" element={<EventList />} />
              <Route path="/event/:eventId" element={<EventView />} />
              <Route path="/document" element={<DocumentList />} />
              <Route path="/discussionboardadd" element={<DiscussionBoardAdd />} />
              <Route path="/discussionboardedit/:discussionId" element={<DiscussionBoardEdit />} />

              {/* <Route path="/mcbmarcdocumentadd" element={<McBmArcDocumentAdd/>}/> */}
              {/* <Route path="/mcbmarcdocumentedit/:documentId" element={<McBmArcDocumentEdit/>}/> */}
              <Route
                path="/document/:documentId"
                element={<DocumentView />}
              />
              <Route
                path="/archiveddocument"
                element={<ArchivedDocumentList />}
              />
              <Route
                path="/archiveddocument/:documentId"
                element={<ArchivedDocumentView />}
              />
              <Route path="/notification" element={<Notification />} />
              <Route path="/notification/:notificationId" element={<NotificationView />} />
              <Route path="/notificationmcarc/:notificationId" element={<McArcNotificationView />} />

              <Route path="/mcbmarcnotificationadd" element={<McBmArcNotificationAdd />} />
              <Route path="/mcbmarcnotificationedit/:notificationId" element={<McBmArcNotificationEdit />} />
              <Route path="/mcbmarcsendnotification/:notificationId" element={<McBmArcSendNotification />} />

              
              <Route
                path="/archivednotification"
                element={<ArchivedNotification />}
              />
              <Route
                path="/archivednotification/:notificationId"
                element={<ArchivedNotificationView />}
              />
              <Route path="/paymenthistory" element={<PaymentHistory />} />
              <Route path="/reimbursedue" element={<ReimburseDue />} />
              <Route path="/faq" element={<Faq />} />

              {/* HomeOwner Routing End */}

              {/* ARC Routing Start */}
              <Route
                path="/arccompliancerequest"
                element={<ArcComplianceList />}
              />
              <Route
                path="/mccompliancerequest"
                element={<McComplianceList />}
              />
              <Route
                path="/arccompliancerequestadd"
                element={<ArcComplianceAdd />}
              />
              <Route
                path="/arccompliancerequestedit/:complainceId"
                element={<ArcComplianceEdit />}
              />
              <Route
                path="/arccompliancerequest/:complainceId"
                element={<ArcComplianceView />}
              />

<Route
                path="/mccomplianceview/:complainceId"
                element={<McComplianceView />}
              />
              {/* ARC Routing End */}

              {/* BM Routing Start */}
              <Route path="/raisedbyarc" element={<BmRaisedByArcList />} />
              {/* BM Routing End */}

              {/* General User(GU) Routing start */}

              <Route
                path="/gumaintenance"
                element={<GuMaintenanceList />}
              />
              <Route
                path="/gumaintenance/:maintenanceId"
                element={<GuMaintenanceView />}
              />

              {/* General User(GU) Routing End */}

              {/* MC Routing Start */}
              <Route path="/mcraisedbyarc" element={<McRaisedByArcList />} />
              {/* MC Routing End */}

              {/* Arc,GU,MC,BM Routing Start */}

              <Route path="/invoice" element={<InvoiceList />} />
              <Route path="/invoice/:invoiceId" element={<InvoiceView />} />
              <Route path="/invoiceEdit/:invoiceId" element={<InvoiceEdit />} />
              <Route path="/invoiceUpload" element={<InvoiceUpload />} />

              <Route
                path="/reviewinvoicelist"
                element={<ReviewInvoiceList />}
              />
              <Route
                path="/reviewinvoiceview/:invoiceId"
                element={<ReviewInvoiceView />}
              />
              <Route path="/raisedbyho" element={<RaisedByHoList />} />
              <Route path="/template" element={<TemplateList />} />
              <Route path="/templateadd" element={<TemplateAdd />} />
              <Route path="/templateedit/:reportTemplateId" element={<Templateedit />} />

              <Route
                path="/homemaintenance"
                element={<MaintenanceRequestList />}
              />

<Route
                path="/homcmaintenance"
                element={<MaintenanceRequestListHoInMc />}
              />
              <Route
                path="/homeownermrview/:maintenanceId"
                element={<ArcMaintenanceView />}
              />
              <Route
                path="/mcbmarcdocument"
                element={<McBmArcDocumentList />}
              />
              <Route
                path="/mcbmarcdocument/:documentId"
                element={<McBmArcDocumentView />}
              />
              <Route
                path="/mcbmarcarchiveddocument"
                element={<McBmArcArchivedDocumentList />}
              />
              <Route
                path="/mcbmarcarchiveddocumentview/:documentId"
                element={<McBmArcArchivedDocumentView />}
              />
              <Route
                path="/mcbmarcnotification"
                element={<McBmArcNotificationList />}
              />
              <Route
                path="/mcbmarcnotification/:notificationId"
                element={<McBmArcNotificationView />}
              />
              <Route
                path="/mcbmarcarchivednotification"
                element={<McBmArcArchivedNotificationList />}
              />
              <Route
                path="/mcbmarcarchivednotification/:notificationId"
                element={<McBmArcArchivedNotificationView />}
              />
              <Route path="/bmarcevent" element={<McBmArcEventList />} />
              <Route
                path="/bmarcevent/:eventId"
                element={<McBmArcEventView />}
              />
              <Route path="/requestreport" element={<ArcMcBmRequestReport />} />
              <Route path="/arcrequestreport" element={<ArcRequestReport />} />
                
              <Route
                path="/homeactivityreport" 
                element={<BmHomeActivityReport />}
              />
              <Route
                path="/homeactivityreports"
                element={<ArcMcBmHomeActivitynew />}
              />
              <Route path="/mcbmarreport" element={<McBmArReport />} />
              <Route
                path="/mcbmarcreportcr/:complainceId"
                element={<ArcMcBmRequestReportCrView />}
              />
              <Route
                path="/mcbmarcreportmr/:maintenanceId"
                element={<ArcMcBmRequestReportMrView />}
              />
            
              <Route
                path="/mcbmarchomeactivitycr/:complainceId"
                element={<ArcMcBmHomeActivityCrView />}
              />
              <Route
                path="/mcbmarchomeactivitymr/:maintenanceId"
                element={<ArcMcBmHomeActivityMrView />}
              />
              <Route path="/mcbmarreport/:homeOwnerDuesId" element={<McBmArReportView />} />
              {/* GU,MC,BM Routing End */}
          
            <Route path="/maintenancerequestlistgeneraluser" element={< MaintenanceRequestListGeneralUser/>} />
            
              <Route path="/maintenancerequestViewgeneraluser/:maintenanceId" element={< MaintenanceRequestViewGeneralUser/>}/>

              <Route path="/maintenancerequestAddgeneraluser" element={< MaintenanceRequestAddGeneralUser/>}/>

                <Route path="/maintenancerequestEditgeneraluser/:maintenanceId" element={<MaintenanceRequestEditGeneralUser/>}/>

                <Route path="/homcmaintenanceview/:maintenanceId" element={<MaintenanceHoMcView/>}/>

                <Route path="/Bmhomemaintenance" element={< BmMaintenanceList/>} />
                <Route path="/arcevent" element={< ArcEventList/>} /> 
                <Route path="/mcevent" element={< McEventList/>} /> 
                <Route path="/arcarchieveddocument" element={< ArcArchievedDocument/>} />

                {/* <Route path="/mcmaintenance" element={<McAddMaintenance/>} /> */}
                <Route path="/arreportpaid/:homeOwnerPaymentId" element={<McBmArReportPaidView/>}/>


                <Route path="/renteraddmc" element={<RenterAddMC/>}/>
                <Route path="/rentereditmc/:renterId" element={<RenterEditMc/>}/>
                <Route path="/renterviewmc/:renterId" element={<RenterViewMc/>}/>
                <Route path="/renterlistmc" element={<RenterListMc/>}/>

                <Route path="/paymentmethod" element={<PaymentMethod />} />

                <Route path="/bmarcmcaddevent" element={<McBmArcAddEvent />} />
                <Route path="/bmarcmceditevent/:eventId" element={<McBmArcEditEvent />} />
                <Route path="/mcbmarcunhidedocument/:documentId" element={<McBmArcUnHideDocument />} />
                <Route path="/mcbmarcadddocument" element={<McBmArcAddDocument />} />
                <Route path="/mcbmarceditdocument/:documentId" element={<McBmArcEditDocument />} />


              </Routes>
          </CustomLayout>
        </>
      )}
    </>
  );
}

export default App;
