import React, { useEffect, useMemo, useState } from "react";
import CustomHeading from "../../../components/CustomHeading";
import CustomMaterialTable from "../../../components/CustomMaterialTable";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import CustomButton from "../../../components/CustomButton";
import CustomDatePicker from "../../../components/CustomDatePicker";
import { ONLY_ALPHABETIC,EMAIL_REGEX_PATTERN} from "../../../utills/ApplicationRegex";
import CustomPhoneInput from "../../../components/CustomPhoneInput";
import { InfoOutlined } from "@mui/icons-material";

import {
  Autocomplete,
  DialogActions,
  DialogContent,
  FormControl,
  TextField,
  Card,
  CardContent,
  Tooltip ,
  Typography,FormControlLabel,Checkbox,
  Grid,
  IconButton,
} from "@mui/material";
import Box from '@mui/material/Box';
import {
  CATEGORY_INVALID_MESSAGE,
  CATEGORY_REQUIRED_MESSAGE,
  DESCRIPTION_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_HUNDRED,
  MAX_LENGTH_FIVE_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
  MAX_LENGTH_VALIDATE_MESSAGE,
  EMAIL_INVALID_MESSAGE,
  PHONE_REQUIRED_MESSAGE,
  PUBLISH_DATE_REQUIRED_MESSAGE,
  TOPIC_REQUIRED_MESSAGE,
  NAME_REQUIRED_MESSAGE,
  EMAIL_REQUIRED_MESSAGE,
  NAME_INVALID_MESSAGE,
  EVENT_ADDRESS_REQUIRED_MESSAGE,
} from "../../../utills/ApplicationConstants";
import CustomInputField from "../../../components/CustomInputField";
import {
  LocalizationProvider,
  MobileDateTimePicker,
  MobileDatePicker,
  StaticDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CustomCheckBox from "../../../components/CustomCheckBox";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  BASE_URL,
  CATEGORY,
  EVENT,
  EVENT_CANCEL,ARC_EVENT,
} from "../../../utills/ApplicationRouting";
import { request } from "../../../services/AxiosConfig";
import CustomToastContainer from "../../../components/CustomToastContainer";
import CustomAutoComplete from "../../../components/CustomAutoComplete";
import CustomLoading from "../../../components/CustomLoading";
import CustomAutoCompleteEdit from "../../../components/CustomAutoCompleteEdit";
import moment from "moment";
import CustomModelView from '../../../components/CustomModelView';
import { isValidPhoneNumber } from 'react-phone-number-input';
import CustomTooltipField from "../../../components/CustomTooltipField";



function McBmArcAddEvent() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const minDate = dayjs().startOf("day");
  const maxDate = dayjs().add(1, "year").endOf("day");
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [customDate, setCustomDate] = useState(null); // State for custom date
  const [isCustomDateOpen, setIsCustomDateOpen] = useState(false); 
  const currentDateTime = new Date();
  const { token, roles, userLoginDetails } = useSelector(
    (state) => state.hybridhoa
  );
  const [eventDetails, setEventDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [DatePickerOpen, setDatePickerOpen] = useState(true);
  const [selectedRows, setSelectedRow] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [eventTime, setEventTime] = useState("");
  const [isCommunity, setIsCommunity] = useState(false);
  const [disabled, setdisabled] = useState(true);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isImmediateChecked, setIsImmediateChecked] = useState(false);
  const [isCustomeChecked, setIsCustomeChecked] = useState(false);
  const [Customedate, setCustomedate] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const handleEventChange = (selectedFromDate) => {
    console.log(selectedFromDate);
    const now = dayjs();
    if(selectedFromDate!=null){
    if (selectedFromDate.isSame(now, 'day')) {
      // Set the current time if the selected date is today
      const updatedDate = selectedFromDate.set('hour', now.hour()).set('minute', now.minute());
      setSelectedDate(updatedDate);
      console.log("date is today ");
      setCustomDate(null);
    setIsCustomeChecked(false);
    setIsImmediateChecked(false);
    setdisabled(false);
    }}
    console.log("date is nott today ");
    // setEventTime(selectedFromDate);
    // setdisabled(false);
    // setCustomDate(null);
    // setIsCustomeChecked(false);
    // setIsImmediateChecked(false);
  };

  const [categoryData, setCategoryData] = useState([]);

  const [categoryId, setCategoryId] = useState("");
  const [checked, setChecked] = useState(false);
  const [notforcommunitychecked, setNotforcommunitychecked] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationOpentwo, setConfirmationOpentwo] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ImmediateModalOpen, setImmediateModalOpen] = useState(false);


  const {
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const {
    handleSubmit:handleSubmitCommunityEvent,
    
  } = useForm();

  const handleViewClick = (selectedRow) => {
    navigate(-1);
  };
  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
    setIsModalOpen(false); 
    setIsCommunity(false)
  };
  const handleConfirmationCloseTwo = () => {
    setConfirmationOpentwo(false);
  };
  const handleConfirmationConfirm = () => {
    setShowError(false);
    setChecked(true);
    setConfirmationOpen(false);
    setIsModalOpen(false);
    setIsCommunity(true)
    setNotforcommunitychecked(false);
  };

  const handleConfirmationConfirmtwo = () => {
    setShowError(false);
    setNotforcommunitychecked(true);
    setConfirmationOpentwo(false);
    setIsCommunity(false);
    setChecked(false);
  };

    



const eventCardModelAction = (
  <>
    <CustomButton
      buttonName="Cancel"
      click={handleConfirmationClose}

    />
    <CustomButton
      fieldType="submit"
      buttonName="OK"
      click={handleConfirmationConfirm}
    />

  </>
);
const eventCardModelActionTwo = (
  <>
    <CustomButton
      buttonName="Cancel"
      click={handleConfirmationCloseTwo}

    />
    <CustomButton
      fieldType="submit"
      buttonName="OK"
      click={handleConfirmationConfirmtwo}
    />

  </>
);
const EventCardContent = (
  <>
    <form onSubmit={handleSubmitCommunityEvent(handleConfirmationConfirm)}>
      <DialogContent dividers>
        <div className="flex mb-5 mt-5">Are you sure you want to send this event to all homeowners?</div>
      </DialogContent>


      <DialogActions>{eventCardModelAction}</DialogActions>
    </form>
  </>)
  const EventCardContenttwo = (
  <>
    <form onSubmit={handleSubmitCommunityEvent(handleConfirmationConfirmtwo)}>
      <DialogContent dividers>
        <div className="flex mb-5 mt-5">Are you sure you want to send this event to the board?</div>
      </DialogContent>


      <DialogActions>{eventCardModelActionTwo}</DialogActions>
    </form>
  </>)
  const renderedCategory = useMemo(() => {
    console.log("Category Details:", categoryDetails);
    const options = categoryDetails.map((category, index) => ({
      category: category.category,
      value: category.categoryId,
      key: index,
    }));
    console.log("Rendered Category:", options);
    return options;
  }, [categoryDetails]);
 
  const handleImmediateCheckbox = (e) => {
    setIsImmediateChecked(e.target.checked);
    if (e.target.checked) {
      setCustomDate(dayjs());
      setIsCustomeChecked(false);
      setIsDatePickerOpen(false);
      setImmediateModalOpen(false);
    }else{
      setImmediateModalOpen(true);
      setCustomDate(null);
    }
  };
  const handleCustomeCheckbox = (e) => {
    
    if (e.target.checked) {
      
      setIsDatePickerOpen(true);
    } else {
      // isCustomeChecked
      setIsCustomeChecked(false);
      setIsDatePickerOpen(false);
      setCustomDate(null);

    }
  };


  const handleCancel = (date) => {
    setIsDatePickerOpen(false);

  };
  const handleAccept = (date) => {
    setIsImmediateChecked(false);
    setCustomDate(date);
    setIsDatePickerOpen(false);
    setIsCustomeChecked(true);
  };


  const handleAddClose = (status) => {
    if (status == 200) {
      setOpen(false);
      setChecked(false);
      setIsCommunity(false)
    }
  };

  const handleCustomeDateChange = (selectedPublishDate) => {
    setCustomedate(selectedPublishDate);
    console.log("hi");
  };
  const handleCategoryChange = (event, newValue) => {
    if (newValue) {
      const selectedCategoryId = newValue.value;
    
      // Set the selected homeId to the state
      setValue("category", selectedCategoryId);
    
      // You can also do other operations if needed
    
      console.log("selectedCategoryId:", selectedCategoryId);
    } else {
      setValue("category", null); // Reset to null if no category is selected
    }
  };

  const handleCheckboxChangeForEvent = () => {
    console.log("hiiiii"+selectedOption);
    if (!checked) {
      console.log("hiiiii 2");
      setConfirmationOpen(true);

      setIsModalOpen(true); // Set isModalOpen to true when opening the modal
    } else {
      console.log("hiiiii 3");
      setIsCommunity(false);
      setChecked(false);
    }
  };
  const handleCheckboxChange = () => {
    console.log("hiiiii"+selectedOption);
    if (!notforcommunitychecked) {
      console.log("hiiiii 2");
      setConfirmationOpentwo(true);

    } else {
      console.log("hiiiii 3");
      setNotforcommunitychecked(false);
    }
  };

  const handleEventDateAccept = (date) => {
      const now = dayjs();
    //   if (date.isBefore(now)) {
    //     setError("selectedDate", {
    //       type: "manual",
    //       message: "Please select a date and time that is not in the past",
    //     });
    //   setEventTime("");
    // } else {
      setEventTime(date);
      setdisabled(false);
      setCustomDate(null);
      setIsCustomeChecked(false);
      setIsImmediateChecked(false);
      clearErrors("selectedDate");
    // }
  };


  const getCategory = async () => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      console.log(response.data)

      if (response.status === 200) {
        const allCategories = response.data;

       if (roles=="ROLE_BM"){
        const filteredCategories = allCategories.filter((category) => {
          return (
            (category.type === "EV" && category.isEventForArc === false )
          );
        });
        console.log("filteredCategories",filteredCategories);
        setCategoryDetails(filteredCategories);
      
        
        setIsLoading(false);
        const categoryIds = filteredCategories.map(
          (category) => category.categoryId
        );

        categoryIds.forEach((singleCategoryId) => {
          setCategoryId(singleCategoryId);
        });
        setCategoryData(filteredCategories);
      }else if (roles=="ROLE_ARC"){
        
        const filteredCategories = allCategories.filter((category) => {
          return (
            (category.type === "EV" && category.isEventForArc === true )
          );
        });
        console.log("filteredCategories",filteredCategories);
        setCategoryDetails(filteredCategories);
      
        
        setIsLoading(false);
        const categoryIds = filteredCategories.map(
          (category) => category.categoryId
        );

        categoryIds.forEach((singleCategoryId) => {
          setCategoryId(singleCategoryId);
        });
        setCategoryData(filteredCategories);
      }
      else if (roles=="ROLE_MC"){
        const filteredCategories = allCategories.filter((category) => {
          return (
            (category.type === "EV" && category.isEventForArc === true )
          );
        });
        console.log("filteredCategories",filteredCategories);
        setCategoryDetails(filteredCategories);
      
        
        setIsLoading(false);
        const categoryIds = filteredCategories.map(
          (category) => category.categoryId
        );

        categoryIds.forEach((singleCategoryId) => {
          setCategoryId(singleCategoryId);
        });
        setCategoryData(filteredCategories);
      }
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  // const getCategory = async () => {
  //   try {
  //     const storedToken = token;

  //     const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
  //       headers: {
  //         Authorization: `Bearer ${storedToken}`,
  //       },
  //     });

  //     if (response.status === 200) {
  //       const allCategories = response.data;

  //       // Filter categories based on type (e.g., 'EV')
  //       const filteredCategories = allCategories.filter(category => category.type === 'EV');

  //       setCategoryDetails(filteredCategories); // Set the filtered data to the state
  //       setIsLoading(false);
  //       setCategoryData(filteredCategories);
  //     } else {
  //       throw new Error("Failed to fetch data");
  //     }
  //   } catch (error) {
  //     // Handle error
  //     console.error("Error fetching data:", error);
  //   }
  // };


  // useEffect(() => {
  //   getCategory();
  // }, []);


  // const getCategory = async () => {
  //   try {
  //     const storedToken = token;

  //     const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
  //       headers: {
  //         Authorization: `Bearer ${storedToken}`,
  //       },
  //     });
  //     console.log(response.data);

  //     if (response.status === 200) {
  //       const data = response.data;
  //       setCategoryDetails(data); // Set the fetched data to the state
  //       setIsLoading(false);
  //       setCategoryData(data);
  //     } else {
  //       throw new Error("Failed to fetch data");
  //     }
  //   } catch (error) {
  //     // Handle error
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // useEffect(() => {
  //   getCategory();
  // }, []);



  // const getEventDetails = async () => {
  //   try {
  //     setIsLoading(true);

  //     const storedToken = token;

  //     console.log(storedToken);

  //     const response = await axios.get(`${BASE_URL}${ARC_EVENT}`, {
  //       headers: {
  //         Authorization: `Bearer ${storedToken}`,
  //       },
  //     });
  //     console.log(response.data);

  //     if (response.status === 200) {
  //       const data = response.data;
  //       setEventDetails(data);
  //       setIsLoading(false);
  //     } else {
  //       setIsLoading(false);

  //       throw new Error("Failed to fetch data");
  //     }
  //   } catch (error) {
  //     // Handle error
  //     setIsLoading(false);

  //     console.error("Error fetching data:", error);
  //   }
  // };

  useEffect(() => {
    // getEventDetails();
  }, []);



  const onSubmit = (data) => {
    
    // const isValidPhoneNumberForCountry = isValidPhoneNumber(data.phoneNumber);
    // console.log("phone number "+isValidPhoneNumberForCountry);
    console.log("data"+JSON.stringify(data));
    // if (dayjs(publishDate).isBefore(dayjs(eventTime))) {
    //   // Publish date is earlier than event date
    //   // alert("Publish Date cannot be earlier than Date & time of event.");
    //   setIsLoading(false);
    //   setError("publishdate", {
    //     type: "manual",
    //     message: "Publish Date cannot be earlier than Date & time of event.",
    //   });
    //   return;
    // }

    // if (dayjs(publishDate).isAfter(dayjs(eventTime))) {
    //   // Publish date is after the event date
    //   setIsLoading(false);
    //   setError("publishdate", {
    //     type: "manual",
    //     message: "Publish Date cannot be later than the Date & time of event.",
    //   });
    //   return;
    // }
    console.log("checkend "+checked);
    console.log("checkend 2 "+notforcommunitychecked);
    
    if(!checked && !notforcommunitychecked){
      console.log("in the conditoin");
      setShowError(true);
      setErrorMessage("Event is Required");
      setIsLoading(false);
      return;
    }
    console.log(isCommunity);
    if(isCommunity){
      if (!data.Name.trim()) {     
        setError("Name", {
          type: "manual",
          message: NAME_REQUIRED_MESSAGE,
        });
        setIsLoading(false);
        return;
      }
      if (!data.email) {
        setError("email", {
          type: "manual",
          message: EMAIL_REQUIRED_MESSAGE,
        });
        setIsLoading(false);
        return;
      }
      if (!data.phoneNumber) {
        setError("phoneNumber", {
          type: "manual",
          message: PHONE_REQUIRED_MESSAGE
        });
        setIsLoading(false);
        return;
      }
      console.log("hello");
  
      if (!isValidPhoneNumber(data.phoneNumber)) {
        setError("phoneNumber", {
          type: "manual",
          message: "Invalid phone number"
        });
        setIsLoading(false);
        return;
      }
      console.log("hello");

    }
    const now = dayjs();
    console.log("logg now: " + now.format("YYYY-MM-DD HH:mm:ss"));
    console.log("logg eventTime: " + dayjs(eventTime).format("YYYY-MM-DD HH:mm:ss"));
    
    if (dayjs(eventTime).isBefore(now)||!dayjs(eventTime).isValid() ) {
      console.log("my congition");
      // setError("selectedDate", {
      //   type: "manual",
      //   message: "Please select a date and time that is not in the past",
      // });
      const updatedDate = dayjs().add(2, 'minute');
      setEventTime(updatedDate);

      return;
    // setEventTime("");
  } 
  setIsLoading(true);
    console.log("hello");
    const postData = {
      topic: data.topic,
      description: data.description,
      category: data.category,
      dateAndTime: dayjs(eventTime).format("YYYY-MM-DD HH:mm:ss"),
      isCommunityEvent: isCommunity,
      role: roles[0],
      isNotifyNow :isImmediateChecked,
      // publishDate: dayjs(publishDate).format("YYYY-MM-DD"),
      notificationDate: customDate ? customDate.format("YYYY-MM-DD HH:mm:ss") : null,
      eventAddress:data.eventAddress,
      email: isCommunity ? data.email : null,
      name: isCommunity ? data.Name : null,
      contact: isCommunity ? data.phoneNumber : null,
      
    };
    console.log("name"+data.Name);
    console.log(data.topic);
    console.log(data.description);
    console.log(categoryId);
    console.log(dayjs(eventTime).format("YYYY-MM-DD HH:mm:ss"));
    console.log(isCommunity);

    // return;
    request({ url: EVENT, method: "post", data: postData })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");

          setIsLoading(false);
          handleAddClose(res.status);
          // getEventDetails();
          setChecked(false);
        reset()
        setTimeout(() => {
          navigate(-1);
        }, 3000); 
        }else{
          setIsLoading(false);

        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };


  return (
    <div>
            <div class="flex flex-col md:flex-row">
        <CustomHeading title="Add Event" />
        <CustomToastContainer />

        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleViewClick}
          />
        </div>
      </div>
      <Card>
        <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-4 md:space-y-6 "
      >
        <CardContent>
        <CustomHeading title="Event Details" />
        <text>Choose Your Event: <span className='text-red-500'>*</span></text>
        <div onClick={(e) => e.stopPropagation()}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleCheckboxChangeForEvent}
                        color="primary"
                        size="small"
                        title="Check to share event with everyone. Boost community spirit by making it visible to all"
                        
                      />
                    }
                    label={<span style={{ fontSize: '14px' }}>Community Event</span>}
                  />
                  <FormControlLabel
        control={
          <Checkbox
            checked={notforcommunitychecked}
            onChange={handleCheckboxChange}
            name="notCommunityEvent"
            color="primary"
            size="small"
            title="Check this if the event is not for the community"
          />
        }
        label={<span style={{ fontSize: '14px' }}>Board Event</span>}
      />
      </div>
      {/* {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>} */}

        {showError && (
       <label>
       {<span style={{ color: "#d32f2f", fontSize: '12px' }}> Please choose a Event </span>}
     </label> )}
                
         {/* <div onClick={(e) => e.stopPropagation()}> */}

      
    {/* </div> */}
        <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
          <Typography gutterBottom>
          
            <CustomInputField
              fieldName="topic"
              requiredErrorMessage={TOPIC_REQUIRED_MESSAGE}
              fieldId="topic"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={500}
              maxLengthErrorMessage={MAX_LENGTH_FIVE_HUNDRED}
              fieldLabel="Topic of Event"
              fieldControl={control}
              fieldError={errors}
              multiline={true}
              fieldRequiredIcon="*"
              tooltipMessage=" Provide a Topic of the Event"
              
            />

            <CustomInputField
              fieldName="description"
              requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
              fieldId="description"
              minLength={2} 
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={5000}
              maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
              fieldLabel="Description"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
              tooltipMessage={"Provide a detailed description of the Event"}
            />



<CustomAutoComplete
              fieldName="category"
              requiredErrorMessage = {CATEGORY_REQUIRED_MESSAGE}
              id="selectId"
              options={renderedCategory}
              fieldLabel="Select Category/Subject"
              fieldControl={control}
              error={errors}
              setValue={setValue}
              tooltipMessage={"Please select the category relevant to the Event."}
              fieldRequiredIcon="*"
                            onChange={handleCategoryChange}
                            invalidOption={CATEGORY_INVALID_MESSAGE}
            />

      {/* <FormControl fullWidth> */}
     
       
      <div className="flex items-center w-full md:w-1/2">
        <>
    <div className="flex  w-full ">
      <>
      <Controller
        control={control}
        name="selectedDate"
        render={({ field }) => (
          <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDateTimePicker
            {...field}
            minDate={minDate}
            maxDate={maxDate}
            disablePast={true}
            value={selectedDate}

            onChange={(date) => {
              handleEventChange(date);
              field.onChange(date); // Manually trigger onChange for React Hook Form
            }}
            onAccept={(date) => {
              handleEventDateAccept(date);
              field.onChange(date); // Manually trigger onChange for React Hook Form
            }}
            slotProps={{
              textField: {
                variant: "standard",
                label: (
                  <span>
                    Date & time of event
                    <span style={{ color: "red" }}> *</span>
                  </span>
                ),
                error: !!errors.selectedDate,
                helperText: errors.selectedDate ? errors.selectedDate.message : "",
              },
            }}
          />
          </LocalizationProvider>
          </FormControl>
        )}
        rules={{ required: 'Date & time is required' }} // Add validation rule
      />
      </>
    </div>
    </>
    <Tooltip
      title={
        <Typography sx={{ fontSize: "14px" }}>
          {"Select Date and Time of the Event"}
        </Typography>
      }
      placement="right"
      arrow
    >
      <IconButton size="small">
        <InfoOutlined />
      </IconButton>
    </Tooltip>
  </div>



      {/* </FormControl> */}
   
    
<CustomInputField
              fieldName="eventAddress"
              fieldId="eventAddress"
              requiredErrorMessage={EVENT_ADDRESS_REQUIRED_MESSAGE}
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={500}
              maxLengthErrorMessage={MAX_LENGTH_FIVE_HUNDRED}
              fieldLabel="Event Address"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              tooltipMessage={"Please provide the Address of the event."}
              numberOfRows={2}
            />
{/* <br/>
<text>Email Notification (Optional)</text>
<br/>
<FormControlLabel
        control={
          <Checkbox
            checked={isImmediateChecked}
            onChange={handleImmediateCheckbox}
            color="primary"
            size="small"
          />
        }
        label={<span style={{ fontSize: '14px' }}>Immediate</span>}
      />
      
      <Tooltip title={disabled ? "Please choose the date and time of the event before selecting a custom date" : ""} disableHoverListener={!disabled}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={isCustomeChecked}
              onChange={handleCustomeCheckbox}
              color="primary"
              size="small"
              inputProps={{
                'aria-label': 'Checkbox A',
              }}
            />
          }
          label={<span style={{ fontSize: '14px' }}>Custom Date</span>}
        />
      </Tooltip>
      <div className="flex items-center w-full md:w-1/2">
    <div className="flex  w-full ">
      <FormControl fullWidth>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
            minDate={minDate}
            maxDate={eventTime}
          disablePast
          open={isDatePickerOpen}
          onAccept={handleAccept}
          onClose={handleCancel}
          value={customDate}
          slotProps={{
            textField: {
              variant: "standard",
              disabled: true,
              label: (
                <span>
                  Custom Date 
                </span>
              ),
              // error: !!errors.selectedDate,
              // helperText: errors.selectedDate ? errors.selectedDate.message : "",
            },
          }}
        />
      </LocalizationProvider>
    </FormControl>
    </div>
    </div> */}

    {checked &&
            <div>
          <CustomHeading title="Contact Details" />
          <CustomTooltipField
                fieldPattern={ONLY_ALPHABETIC}
                patternErrorMessage={NAME_INVALID_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={60}
                maxLengthErrorMessage={MAX_LENGTH_VALIDATE_MESSAGE}
                // requiredErrorMessage={NAME_REQUIRED_MESSAGE}
                fieldName="Name"
                fieldId="Name"
                fieldType="text"
                fieldLabel="Name"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                // value={name}
                // onInput={handleInputChange}
                tooltipMessage=" Update your name here. This is how we'll address you in communications and personalize your experience on our platform"
              />
              
              <CustomInputField
                fieldName="email"
                fieldPattern={EMAIL_REGEX_PATTERN}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                // requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
                fieldId="email"
                fieldType="text"
                fieldLabel="Email"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                // value={email}
                tooltipMessage=" your current email address. This is how we'll communicate with you and keep you informed about important updates and notifications"
              />


              <Controller
                name="phoneNumber"
                control={control}
                
                render={({ field, fieldState }) => (
                  <>
                    <CustomPhoneInput
                      fieldControl={control}
                      label="Phone Number"
                      clearErrors={clearErrors}
                      country="US"
                      value={field.value}
                      tooltipMessage=" Enter your current Phone number. This is how we'll communicate with you and keep you informed about important updates and notifications"
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      error={fieldState.invalid ? fieldState.error : null}
                      helperText={fieldState.invalid ? fieldState.error?.message : ''}
                      fieldError={errors}
                    />                


                  </>
                )}
              />
              </div>}


          </Typography>
        </section>
        <div class="text-center mt-10">
        <CustomButton
        fieldType="submit"
        buttonName="Add"
        click={handleAddClose}
      ></CustomButton>
      {isLoading && <CustomLoading />}
      </div>
      </CardContent>
      </form>
      </Card>

      <CustomModelView
        title="Community Event"
        content={EventCardContent}
        action={eventCardModelAction}
        openStatus={confirmationOpen}
        closeStatus={handleConfirmationClose}
        iconCloseStatus={handleConfirmationClose}/>
         <CustomModelView
        title="Board Event"
        content={EventCardContenttwo}
        action={eventCardModelActionTwo}
        openStatus={confirmationOpentwo}
        closeStatus={handleConfirmationCloseTwo}
        iconCloseStatus={handleConfirmationCloseTwo}/>
        </div>


  );
}
export default McBmArcAddEvent;
