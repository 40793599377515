import { FileDownload, FileUploadOutlined, InfoOutlined, ListAlt } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { useNavigate } from "react-router-dom";
import { CustomStyle } from "../../components/CustomStyle";
import CustomHeading from "../../components/CustomHeading";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import CustomDatePicker from "../../components/CustomDatePicker";
import CustomButton from "../../components/CustomButton";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import {
  FROM_DATE_REQUIRED_MESSAGE,
  STATUS_REQUIRED_MESSAGE,
  TO_DATE_ERROR_MESSAGE,
  TO_DATE_REQUIRED_MESSAGE,
  TYPE_REQUIRED_MESSAGE,
} from "../../utills/ApplicationConstants";
import { AR_REPORT, BASE_URL, BASE_URL_IMAGE, GROUP_CONFIG } from "../../utills/ApplicationRouting";
import { request } from "../../services/AxiosConfig";
import moment from "moment";
import CustomLoading from "../../components/CustomLoading";
import CustomToastContainer from "../../components/CustomToastContainer";
import { pdf } from "@react-pdf/renderer";
import PDFDocumentList from "../../components/TemplateListpdf";
import { useSelector } from "react-redux";
import axios from "axios";
import { toDate } from "date-fns";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Tooltip from "@mui/material/Tooltip";
import CustomMaterialWithoutActons from "../../components/CustomMaterialWithoutActons";
import LZString from 'lz-string';

function McBmArReport() {
  const [type, setType] = useState("");

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
  } = useForm();

  
  const [datas, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [homeAddress,setHomeAddress] = useState('');
  const [listData, setListData] = useState([]);
  const [fileImage,setFileImage]=useState("")
  const [groupConfigurationDetails,setGroupConfigurationDetails]=useState([])
  const [logoUrl, setLogoUrl] = useState('');
  const [imageBlob,setImageBlob]=useState('');
  const { token } = useSelector((state) => state.hybridhoa);
  const minDate = dayjs().subtract(3, "year").startOf("day");
  const maxDate = dayjs().endOf("day");
  const [toDateDisabled, setToDateDisabled] = useState(true);
  const [toDateMinDate, setToDateMinDate] = useState(minDate);
  const [toDateMaxDate, setToDateMaxDate] = useState(maxDate);
  const [toDate, setToDate] = useState("")
  const [fromDate, setFromDate] = useState("")
  const[footerAdress,setFooterAdress]=useState('')
  const[groupAdress,setGroupAdress]=useState('')
  const handleFromDateChange = (selectedFromDate) => {
    // setListData([])
    setToDate("")
    const newToDateMaxDate = dayjs(selectedFromDate)
      .add(3, "month")
      .endOf("day");
    setToDateMaxDate(newToDateMaxDate);

    setToDateMinDate(selectedFromDate);
    setToDateDisabled(false);
    setFromDate(selectedFromDate);
    
  };

  const handleToDateChange = (selectedEndDate) => {
    setToDate(selectedEndDate);
    // setListData([])

  };
  
  const getGroupConfigurationDetails = async () => {
    // setIsLoading(true);
    try {
      
      const storedToken = token;
  
      console.log(storedToken);
  
      const response = await axios.get(`${BASE_URL}${GROUP_CONFIG}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response);
  
      if (response.status === 200) {
        const data = response.data;
        // setIsLoading(false);

        setGroupConfigurationDetails(data);
        const logoConfig = data.find(config => config.topic === 'Logo');
        console.log(logoConfig);
        if (logoConfig) {
          setLogoUrl(logoConfig.content);
          console.log(logoConfig.content);
        }
        const footer = data.find(config => config.topic === 'Contact Details');
        console.log(logoConfig);
        if (footer) {
          setFooterAdress(footer.content);
          console.log(footer.content);
        }
        const groupField = data.find(config => config.topic ==='Group Name');
        console.log(logoConfig);
        if (groupField) {
          setGroupAdress(groupField.content);
          console.log(groupField.content);
        }
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(()=>{
     getGroupConfigurationDetails()
  },[])
  const selectedStatus = [
   
    { label: "Pending", value: "PENDING" },
    { label: "Paid", value: "PAID" },
 
  ];
  const [selectedStatusOption,setSelectedStatusOption]=useState(null)
  useEffect(() => {
    const savedFormData = JSON.parse(localStorage.getItem('ArReportFormData'));
    if (savedFormData) {
      setValue('fromDate', savedFormData.fromDate);
      setValue('toDate', savedFormData.toDate);
      setValue('selectedStatus', savedFormData.selectedStatus);
      console.log('selectedStatusss', savedFormData.selectedStatus);
      // setValue('selectedType', savedFormData.selectedType); // Uncomment if you have a selectedType field
      setFromDate(dayjs(savedFormData.fromDate));
      setToDate(dayjs(savedFormData.toDate));
      setSelectedStatusOption(savedFormData.selectedStatus)
    }
  }, [setValue]);
  const onSubmit = (data) => {
    setIsLoading(true);
    // const fromDate = dayjs(data.fromDate);
    // const toDate = dayjs(data.toDate);

    if (!fromDate) {
      setError("fromDate", {
        type: "manual",
        message: FROM_DATE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (!toDate) {
      setError("toDate", {
        type: "manual",
        message: TO_DATE_ERROR_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (toDate.isBefore(fromDate)) {
      setIsLoading(false)
      alert(TO_DATE_ERROR_MESSAGE);
      return;
    }
    localStorage.setItem('ArReportFormData', JSON.stringify(data));

    console.log(data);
    const getTypeValue = (label) => {
      const selectedTypeObj = selectedStatus.find(
        (type) => type.label === label
      );
      return selectedTypeObj ? selectedTypeObj.value : "";
    };
    const postData = {
      from: dayjs(fromDate).format("YYYY-MM-DD"),
      to: dayjs(toDate).format("YYYY-MM-DD"),
      type: getTypeValue(data.selectedStatus.label),
    };
    console.log(dayjs(fromDate).format("YYYY-MM-DD"));
    console.log(dayjs(toDate).format("YYYY-MM-DD"));

    // console.log(getTypeValue(data.selectedType.label));
    // return;

    request({ url: AR_REPORT, method: "post", data: postData })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status === 200) {
          console.log(res);
          console.log(res.data.homeOwnerDues);
          // console.log(res.data.homeOwnerDues[0].homeDetails.address);
          let filteredData = [];
          if (data.selectedStatus.label === "All") {
            filteredData = res.data.homeOwnerDues.concat(res.data.homeOwnerPayments);
          } else if (data.selectedStatus.label === "Pending") {
            filteredData = res.data.homeOwnerDues;
          } else if (data.selectedStatus.label === "Paid") {
            filteredData = res.data.homeOwnerPayments;
            console.log("payyyyyyy",filteredData);
            
          }
          setListData(filteredData);
          setData(filteredData);
          // localStorage.setItem('arData', JSON.stringify(filteredData));
          const compressedData = LZString.compress(JSON.stringify(filteredData));
          console.log("compress",compressedData)
          localStorage.setItem('arReport', compressedData)
          // localStorage.setItem('arReport', JSON.stringify(filteredData));
          

        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  // useEffect(() => {
  //   // Retrieve data from local storage
  //   const storageData = localStorage.getItem('arReport');
  //   if (storageData) {
  //     // Parse the JSON data
  //     const parsedData = JSON.parse(storageData);
  //     // Set the parsed data in the state
  //     setListData(parsedData);
  //     setData(parsedData);
  //     console.log("parsed",parsedData);
  //   }
  // }, []);
  useEffect(() => {
    // Retrieve data from local storage
    const compressedDatass = localStorage.getItem('arReport');
    console.log("startget",compressedDatass);
    
    
    if (compressedDatass) {
      console.log("startgetgfhdfghs",compressedDatass);
      try {
        console.log("Attempting to decompress data...");
        const decompressedData = LZString.decompress(compressedDatass);
        console.log("Decompressed data:", decompressedData);
        
        if (decompressedData) {
          // Parse the JSON data
          const parsedData = JSON.parse(decompressedData);
          console.log("Parsed data:", parsedData);
          setListData(parsedData);
          setData(parsedData);
        } else {
          console.error('Decompressed data is empty');
        }
      } catch (error) {
        console.error('Error during decompression or parsing:', error);
      }
    // try {
    // // Decompress the data
    // const decompressedData = LZString.decompress(compressedDatass);
    // console.log("before if",decompressedData);

    // if (decompressedData) {
    // // Parse the JSON data
    // const parsedData = JSON.parse(decompressedData);
    // // Set the parsed data in the state
    // console.log("after if",parsedData);
    
    // setListData(parsedData);
    // setData(parsedData);
    // console.log("parsed", parsedData);
    // } else {
    // console.error('Decompressed data is empty');
    // }
    // } catch (error) {
    // console.error('Error during decompression or parsing:', error);
    // }
    } else {
    console.error('No data found in localStorage for arReport');
    }
    }, []);
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };
  const navigate = useNavigate();
  const handleViewClick = (selectedRow) => {
    // const { status, homeOwnerDuesId, homeOwnerPaymentId } = selectedRow.row.original;
  const homeOwnerPaymentId=selectedRow.row.original.homeOwnerPaymentId
  const homeOwnerDuesId=selectedRow.row.original.homeOwnerDuesId
  const status =selectedRow.row.original.status
    if (status === 'PENDING') {
      // Navigate to the URL for pending status
      navigate(`/mcbmarreport/${homeOwnerDuesId}`);
    } else if (status === 'PAID') {
      // Navigate to the URL for paid status
      navigate(`/arreportpaid/${homeOwnerPaymentId}`);
      console.log("hgfdjhsdgf",homeOwnerPaymentId);
    } else {
      console.log(`Unhandled status: ${status}`);
    }
  };
  
  // const handleViewClick = (selectedRow) => {
  //   const status = selectedRow.row.original.status;
  //   // navigate(`/mcbmarreport/${selectedRow.row.original.homeOwnerDuesId}`);
  //   if (status === 'PENDING') {
  //     navigate(`/mcbmarreport/${selectedRow.row.original.homeOwnerDuesId}`);
  //   } else if (status === 'PAID') {
  //     navigate(`/arreportpaid/${selectedRow.row.original.homeOwnerPaymentId}`);
  //     console.log(selectedRow.row.original.homeOwnerPaymentId);
  //   } else {
  //     console.log(`Unhandled status: ${status}`);
  //   }
  // };
  const handlePaidViewClick =(selectedRow) =>{
    navigate(`/arreportpaid/${selectedRow.original.homeOwnerPaymentId}`);
    console.log(selectedRow.original.homeOwnerPaymentId);
  }
  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '' || str === null) return '';
  
    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const requestReportTable = useMemo((rowData) => [
    {
      accessorKey: 'homeOwnerFullName',
      header: 'Home Owner Name',
      accessorFn: (row) => {
        const firstName = row.homeOwnerDetails && row.homeOwnerDetails.firstName;
        const lastName = row.homeOwnerDetails && row.homeOwnerDetails.lastName;
        return `${firstName || ''} ${lastName || ''}`.trim();
      },
      sortDescFirst: true,
    },

    {
      accessorKey: 'homeAddress',
      header: 'Home Address',
      size: 20,

      // accessorFn: (row) => {
      //   const adress = row.homeOwnerDetails && row.homeOwnerDetails.addressForCommunication;
      //   // const lastName = row.createdByDetails && row.createdByDetails.lastName;
      //   return `${adress || ''}`.trim();
      // },
      accessorFn: (row) => row.homeDetails?row.homeDetails.address: "",
      sortDescFirst: true,
    },
    {
      accessorKey: 'createdBy',
      header: 'Created By',
      size: 20,
      accessorFn: (row) => {
        const firstName = row.createdByDetails && row.createdByDetails.firstName;
        const lastName = row.createdByDetails && row.createdByDetails.lastName;
        return `${firstName || ''} ${lastName || ''}`.trim();
      },
      sortDescFirst: true,


    },
    {
      accessorKey: "dueType",
      header: "Due Type",
      size: 20,
      accessorFn: (row) => convertToPascalCase(row.dueType),
    },

    {
      accessorKey: "status",
      header: "Status",
      size: 20,
      accessorFn: (row) => convertToPascalCase(row.status),
    },
    {
      accessorKey: "createdDateAndTime",
      header: "Created Date ",
      size: 20,
      accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),
    },
    {
      accessorKey: "dueDate",
      header: "Due Date",
      size: 20,
      accessorFn: (row) => moment(row.dueDate).format("MM/DD/YYYY "),
    },
    {
      accessorKey: 'lastDate',
      header: 'Last Date',
      size: 20,
      accessorFn: (row) => {
        if (!row.endDate) {
          return ''; // Return empty string if lastDate is undefined or null
        }
        return moment(row.endDate).format('MM/DD/YYYY ');
      },
    },
    {
      accessorKey: "dueAmount",
      header: "Amount",
      size: 20,
    },
  ]);
  const rowActions = (row) => {
    const actions =[];
    
    if (row.original.homeOwnerDuesId && row.original.status == "PENDING") {
      actions.push({
        label: "View",
        icon: <ListAlt color="primary" />,
        click: () => handleViewClick(row),
      });
    }
    if (row.original.homeOwnerPaymentId && row.original.status == "PAID") {
      actions.push({
        label: "View",
        icon: <ListAlt color="primary" />,
        click: () => handlePaidViewClick(row),
      });
    }
    return actions;
  };

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  const exportCSV = (data, fields, csvConfig) => {
    // Generate CSV headers from the fields array
    const headers = fields.map(field => csvConfig.useKeysAsHeaders ? field.header : field.accessor).join(csvConfig.fieldSeparator);
    // Generate CSV rows from the data
    const rows = data.map(row => {
      return fields.map(field => {
        const value = row[field.accessor];
        // Check if value is defined before accessing its properties
        if (value && value.includes && value.includes(csvConfig.fieldSeparator)) {
          return `"${value}"`;
        }
        return value;
      }).join(csvConfig.fieldSeparator);
    });
  
    const csvContent = [headers, ...rows].join('\n');
    return csvContent;
};

const csvConfigWithFilename = {
  ...csvConfig,
  filename: 'Dues Report' 
};
const handleExportData = () => {
  const excludedProperties = [
    "homeId",
    "stripeResponse","totalDueAmount",
    "groupId",
    "invoice",
    "isAgree","homeOwnerPaymentId",
    "fineAmount",
    "homeOwnerDuesId",
    "auditBy",
    "title",
    "homeOwnerDetails",
    "requestForAccess",
    "id",
    "waivedOffAmount",
    // "createdBy",
    "isReimbursedApplied",
    "isPaymentStarted",
    "isOnRent",
    "homeOwnerLoginId",
    "homeOwnerDetailsId",
    "createdDateAndTime",
    "auditDateAndTime",
    "complianceId",
    "isAssigned", "totalAmount", "homeDetails", "createdByDetails",
    "groupDuesId", "specialAssType", "dueFrequency", "description", "tite"
  ];

  const propertyOrder = [
    "homeOwner", "homeAddress", "createdBy", "createdDateTime", "dueDate", "status", "dueAmount"
    // Add more properties here in the desired order
  ];

  const formattedData = Object.values(listData).map((item) => {
    const formattedDatetime = item.createdDateAndTime
      ? moment(item.createdDateAndTime).format("MM/DD/YYYY ")
      : '';
    const formattedDueDatetime = item.dueDate
      ? moment(item.dueDate).format("MM/DD/YYYY ")
      : '';
    const lastDates = item.lastDate
      ? moment(item.lastDate).format("MM/DD/YYYY ")
      : '';
      const endDates = item.endDate
      ? moment(item.endDate).format("MM/DD/YYYY ")
      : '';
    const homeOwnerName = item.homeOwnerDetails
      ? `${item.homeOwnerDetails.firstName} ${item.homeOwnerDetails.lastName}`
      : '';
    // const homeAddress = item.homeOwnerDetails
    //   ? `${item.homeOwnerDetails.addressForCommunication}`
    //   : '';
    // const homeAddress = item.homeOwnerDetails?.addressForCommunication ?? '';
    const homeAddress=item.homeDetails?.address??""

    const createdBy = item.createdByDetails
      ? `${item.createdByDetails.firstName} ${item.createdByDetails.lastName}`
      : '';
    const filteredItem = {
      ...item,
      homeOwner: homeOwnerName,
      homeAddress: homeAddress,
      createdBy: createdBy,
      createdDate: formattedDatetime,
      dueDate: formattedDueDatetime,
      lastDate:lastDates,
      endDate:endDates
    };

    excludedProperties.forEach((property) => {
      delete filteredItem[property];
    });

    // Create a new object with properties in the desired order
    const orderedItem = {};
    propertyOrder.forEach((property) => {
      if (filteredItem[property] !== undefined) {
        orderedItem[property] = filteredItem[property];
      }
    });

    // Add any remaining properties that are not in the propertyOrder array
    Object.keys(filteredItem).forEach((key) => {
      if (!orderedItem.hasOwnProperty(key)) {
        orderedItem[key] = filteredItem[key];
      }
    });

    return orderedItem;
  });

  const csvConfigWithFilename = {
    ...csvConfig,
    filename: 'Ar Report Details'
  };

  const csv = generateCsv(csvConfigWithFilename)(formattedData);
  download(csvConfigWithFilename)(csv);
};
const getHoaAttachments=async () => {
  try {
    const storedToken = token;
    const response = await axios.get(`${BASE_URL_IMAGE}/${logoUrl}`, {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
      responseType: "blob",
    });
    const blob = response.data; // Accessing the blob data from the response
    const fileURL = URL.createObjectURL(blob);
    setImageBlob(fileURL);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
useEffect(()=>{
  getHoaAttachments()
},[logoUrl])
const currentDate = moment(); // Get the current date
let dateTimeText = currentDate.format("DD MMMM YYYY"); 
const handleRenderPDF = () => {
  if (!listData || listData.length === 0) {
    console.error('No data available for PDF rendering.');
    return;
  }

  const styles = {
    title: {
      fontSize: 18,
      color: "black",
      textAlign: "center",
      padding: "5px",
    },
    images:{
      width:"200px",
      height:"100px"
      
          },
    emptys: {
      marginTop: "3%",
    },emptyModule:{
      marginTop:"7px"
     },
    content: {
      fontSize: 12,
    color: "black",
    textAlign: "left",
    // paddingLeft:"60px",
    padding:"5px"
    },
    re:{
      fontSize:12,
      color:"black",
      textAlign:"left",
      padding:"5px"
         },
         adress:{
          fontSize: 12,
          color: "black",
          textAlign: "left",
          
          // paddingLeft:"60px",
          padding:"5px",
          // fontWeight:"bold"
        },
    description: {
      fontSize: 15,
      color: "black",
      textAlign: "left",
      paddingLeft: "60px",
      padding: "5px",
      fontWeight: "bold",
    },
    signature: {
      fontSize: 14,
      color: "black",
      textAlign: "left",
      fontStyle: "italic",
    },
    re:{
      fontSize:12,
      color:"black",
      textAlign:"left",
      padding:"5px"
         },
    headers: {
      fontSize: 12,
      color: "black",
      textAlign: "left",
      alignItems: "center",
      padding: "4px",
    },
    heading: {
      fontSize: 20,
      textAlign: "center",
      fontFamily: "Oswald",
    },
    afterfrom: {
      marginTop: "8%",
    },
    from: {
      fontSize: 11,
      color: "black",
      textAlign: "left",
      alignItems: "center",
      padding: "2px",
    },
    empty: {
      marginTop: "128%",
    },
    secondEmpty: {
      marginTop: "30%",
    }, headerImage:{
      marginTop:"1%",
 paddingLeft:"70px",
    paddingRight:"50px",
    alignItems:"center",

      width:"470px",
      height:"160px",
      // backgroundColor:"yellow"
    },
    formempty: {
      marginTop: "49%",
    },
    fromBorder: {
      border: "1px solid black",
    },
    description:{
      fontSize: 13,
      color: "black",
      textAlign: "left",
      // paddingLeft:"60px",
      padding:"5px",
    fontWeight:"bold"
    },
    toBorder: {
      border: "1px solid black",
    },letterPad:{
      marginTop:"30%"
    }
  };
  const renderPageContent = (details) => {
    const letterContent = [];
  
    if (details && details.homeOwnerDuesId ) {
      // logo:imageBlob
     
      letterContent.push(
        // Section for Compliance Request Notice
        {
          type: "text",
          text: groupAdress,
          style: styles.title,
        },

        {
          type: "text",
          text: "Due Details",
          style: styles.title,
        },
        { type: "text", text: " " },
        // { type: 'text', text: dateTimeText, style:styles.from },
        { style: styles.fromBorder },
        { type: "text", text: "", style: styles.formempty },
        { type: "text", text: "From", style: styles.from },
        { type: 'text', text: groupAdress, style: styles.from },

        { type: "text", text: footerAdress, style: styles.from },
        // {type:'text',text:dateTimeText,style: styles.content},
        { type: 'text',text: "",  style:styles.emptyModule},

       
        { type: "text", text: "", style: styles.afterfrom },

        // { text: " "  },
        // { text: `${(details && details.createdByDetails && details.createdByDetails.firstName) || '' }`, style: styles.adress, fontSize: 15 },
        { type: "text", text: "To :", style: styles.headers },
        // { type: 'text', text: "From", style: styles.headers  },
        // { type: 'text', text: "From", style: styles.headers  },
        {
          type: "text",
          text: `${
            (details &&
             details.homeOwnerDetails &&
             (details.homeOwnerDetails.firstName || '') + 
             (details.homeOwnerDetails.firstName && details.homeOwnerDetails.lastName ? ' ' : '') +
             (details.homeOwnerDetails.lastName || ''))
            || ""}`,
          style: styles.headers,
        },
        {
          type: "text",
          text: `${
            (details.homeOwnerDetails?.addressForCommunication?? "") ||
            (details.homeDetails?.address?? "") ||
            " "
          }`,
          style: styles.headers,
        },
        {
          type: "text",
          text: `${
            (details &&
              details.homeOwnerDetails &&
              details.homeOwnerDetails.city) ||
            ""
          }${details && details.homeOwnerDetails && 
            details.homeOwnerDetails.city && details.
            homeOwnerDetails.zipcode ? ", " : ""} ${
            (details &&
              details.homeOwnerDetails &&
              details.homeOwnerDetails.zipcode) ||
            " "
          }`,
          style: styles.headers,
        },

        { type: "text", text: "", style: styles.empty },
        { type: "text", text: "", style: styles.secondEmpty },

        {type: 'image',src:imageBlob, style:styles.headerImage  },
        { type: 'text', text: groupAdress, style: styles.title },
        { type: 'text', text: "Due Details", style: styles.title },
        {type:'text',text:dateTimeText,style: styles.content},

        { type: 'text',text: "",  style:styles.emptyModule},
        {
          type: "text",
          text: `${
            (details &&
             details.homeOwnerDetails &&
             (details.homeOwnerDetails.firstName || '') + 
             (details.homeOwnerDetails.firstName && details.homeOwnerDetails.lastName ? ' ' : '') +
             (details.homeOwnerDetails.lastName || ''))
            || ""}`,
          style: styles.headers,
        },

        {
          type: "text",
          text: `${
            (details.homeOwnerDetails?.addressForCommunication?? "") ||
            (details.homeDetails?.address?? "") ||
            " "
          }`,
          style: styles.headers,
        },
        {
          type: "text",
          text: `${
            (details &&
              details.homeOwnerDetails &&
              details.homeOwnerDetails.city) ||
            ""
          }${details && details.homeOwnerDetails && 
            details.homeOwnerDetails.city && details.
            homeOwnerDetails.zipcode ? ", " : ""} ${
            (details &&
              details.homeOwnerDetails &&
              details.homeOwnerDetails.zipcode) ||
            " "
          }`,
          style: styles.headers,
        },
        { type: 'text',text: "",  style:styles.emptyModule},
        {
          type: 'text',
          text: `RE: ${
            (details.homeOwnerDetails?.addressForCommunication?? "") ||
            (details.homeDetails?.address?? "") ||
            " "
          }`,
          style: styles.re
        },
        { type: 'text',text: "",  style:styles.emptyModule},
        {
          type: "text",
          text: "Dear Resident,",
          //  ${
          //   (details &&
          //     details.createdByDetails &&
          //     details.createdByDetails.firstName) ||
          //   " "
          // }`   
          
          style: styles.content,
          fontSize: 15,
        },
        // {
        //   type: "text",
        //   text: "You have previously asked to address the following matter with your property.",
        //   style: styles.content,
        // },
        {
          type: "text",
          text: `This is to inform you about the Due  status: ${convertToPascalCase(details?.status ?? "")}`,
          style: styles.content,
        },
        // {
        //   type: "text",
        //   text: `${details && details.description}`,
        //   style: styles.description,
          
        // },
        {
          type: "text",
          text: details?.status === "PENDING"
            ? `This is to inform you about your ${convertToPascalCase(details?.dueType ?? "")} with a due amount of ${details?.dueAmount ?? ""} $. The last date for payment is ${moment(details?.endDate).format('MM/DD/YYYY')}. Please make the necessary arrangements.`
            : `Your ${convertToPascalCase(details?.dueType ?? "")} due of ${details?.dueAmount ?? ""}$ has been paid within the due date.`,
          style: styles.description,
        },
        
        {
          type: "text",
          text: "If you have any questions, feel that you have received this letter in error, or would like to request a variance, please feel free to contact our office.",
          style: styles.content,
        },
        {
          type: "text",
          text: "Thank you in advance for your attention to this matter.",
          style: styles.content,
        },
        { type: "text", text: "" },
        { type: "text", text: "", style: styles.content },
        { type: "text", text: "", style: styles.content },
        // {  type: 'text',text: "Sincerely,", style: styles.signature },
        // { type: 'text',text: footerContent , style: styles.adress}
        {type: 'text', text: "Sincerely,", style: styles.adress },
        { 
          type: "text", 
          text: `${footerAdress} (computer generated letter)`, 
          style: styles.adress 
        },
        // { 
        //   type: "text", 
        //   text: "Attachments:", 
        //   style: styles.content 
        // },
        // {type: 'text',  text: `${details?.fileurl ?? ''}`, style: styles.content},

        // {
        //   type: 'image',
        //   src: `${BASE_URL_IMAGE}/${details.fileUrl?? ""}`,
        //   style: styles.images 
        // }
        // { type: "text", text: "", style: styles.empty },
        // { type: "text", text: "", style: styles.secondEmpty },
        // { type: "image", src: testImages }
      );
//         if (testImages.length > 0) {

//           testImages.forEach(image => {
//           letterContent.push({
//               type: "image",
//               src: image.src
//           });
//       });
//   }
      

    }
    if (details.attachments && Array.isArray(details.attachments) && details.attachments.length > 0) {
      letterContent.push({ type: "text", text: "Attachments:", style: styles.content });
    
      details.attachments.forEach((attachment) => {
        const fileRemoval = attachment.file.substring(12);
    
        letterContent.push(
          { type: 'text', text: fileRemoval, style: styles.content },
          {
            type: 'image',
            src: `${BASE_URL_IMAGE}/${attachment.file}`,
            style: styles.images
          }
        );
      });
    } else {
      letterContent.push({ type: "text", text: " ", style: styles.content });
    }
   
    return letterContent;
  };
  // const headingTitle=groupAdress;
   const logo = imageBlob;
  const data = listData.map(details => ({
    letterContent: renderPageContent(details)
  }));

  return (
    <PDFDocumentList data={data} styles={styles} logo={logo} />
  );
};
const handleExportDataReport = async () => {
  try {
    const pdfData = handleRenderPDF();
    const blob = await pdf(pdfData).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};
  // const handleExportData = (data) => {
  //   const fieldsToExport = [
  //     {
  //       header: 'Home Address',
  //       accessorFn: (row) => {
  //         const firstName = row.homeOwnerDetails && row.homeOwnerDetails.firstName;
  //         const lastName = row.homeOwnerDetails && row.homeOwnerDetails.lastName;
  //         return `${firstName || ''} ${lastName || ''}`.trim();
  //       },
  //     },
  //     { header: 'DueType',
  //      accessor: 'dueType' 
  //     },
  //     { header: 'Status',
  //      accessor: 'status' ,

  //     },
  //     { 
  //       header: 'Created Date Time ',
  //       accessor: 'createdDateAndTime', 
  //       accessorFn: (row) => row.createdDateAndTime ? moment(row.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a") : ''
  //     },
      
  //     { header: 'Amount', 
  //     accessor: 'dueAmount'
  //    },

  //     // Add more fields as needed
  //   ];
  //   const csv = exportCSV(data, fieldsToExport, csvConfigWithFilename);
  // download(csvConfigWithFilename)(csv);
  //   // const csv = generateCsv(csvConfig)(auditListTable);
  //   // download(csvConfig)(csv);
  // };
  // const handleExportData = () => {
  //   const csv = generateCsv(csvConfig)(datas);
  //   download(csvConfig)(csv);
  // };
  const getComplainceAttachments=async () => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL_IMAGE}/${logoUrl}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
        responseType: "blob",
      });
      const blob = response.data; // Accessing the blob data from the response
      const fileURL = URL.createObjectURL(blob);
      setImageBlob(fileURL);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(()=>{
    getComplainceAttachments()
  },[fileImage])

 
  // const handleExportData = async () => {
  //   // console.log("test",logoUrl);
  //   try {
  //     const pdfData = handleRenderPDF();
  //     const blob = await pdf(pdfData).toBlob();
  //     const url = URL.createObjectURL(blob);
  //     window.open(url, '_blank');
  //   } catch (error) {
  //     console.error('Error generating PDF:', error);
  //   }
  // };
  const tableActionButtons = (
    <>
    {listData && listData.length>0 &&(
      <>
      <Button
        color="primary"
        variant="contained"
        onClick={() => handleExportData(listData)}
        startIcon={<FileUploadIcon />}
      >
        <span className={CustomStyle.containedButtonStyle}>
          Export All Data
        </span>
        </Button>
        <Button
            color="primary"
            variant="contained"
            onClick={handleExportDataReport}
            startIcon={<FileUploadIcon/>}
          >
            <span className={CustomStyle.containedButtonStyle}>
              Export As pdf
            </span>
          </Button>
     </>

)}
    </>
  );
  // const tableActionButtons = (
  //   <>
  //   {
  //     listData.length > 0 ?(
  //       <Button
  //       color="primary"
  //       variant="contained"
  //       onClick={handleExportData}
  //       startIcon={<FileDownload />}
  //     >
  //       <span className={CustomStyle.containedButtonStyle}>
  //         Export All Data
  //       </span>
  //     </Button>

  //     )
  //     :null
  //   }
    
  //   </>
  // );

  return (
    <div>
      <CustomToastContainer/>
      <CustomHeading title="AR Report" />
      <Card>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
              <CustomDatePicker
                fieldName="fromDate"
                label="From Date"
                control={control}
                requiredErrorMessage={FROM_DATE_REQUIRED_MESSAGE}
                minDate={minDate}
                maxDate={maxDate}
                value={fromDate}
                onChange={(date) => handleFromDateChange(date)}
                fieldRequiredIcon="*"
                tooltipMessage="Select from date"
              />

              <CustomDatePicker
                fieldName="toDate"
                label="To Date"
                control={control}
                requiredErrorMessage={TO_DATE_REQUIRED_MESSAGE}
                minDate={toDateMinDate}
                maxDate={toDateMaxDate}
                disabled={toDateDisabled}
                fieldRequiredIcon="*"
                value={toDate}
                tooltipMessage="Select to date"
               onChange={handleToDateChange}
              />
<div className="flex items-center md:w-1/2 w-full">
                  <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
<Controller
                name="selectedStatus"
                control={control}
                rules={{ required: STATUS_REQUIRED_MESSAGE }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={Boolean(error)}>
                    <Autocomplete
                      fullWidth
                      {...field}
                      id="combo-box-demo"
                      options={selectedStatus}
                      getOptionLabel={(option) => option?.label || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      value={selectedStatusOption}

                      onChange={(event, newValue) => {
                        setValue("selectedStatus", newValue, {
                          shouldDirty: true,
                        });
                        setSelectedStatusOption(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span>
                              Status <span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          variant="standard"
                          error={Boolean(error) && !field.value}
                          helperText={
                            Boolean(error) && !field.value ? error.message : ""
                          }
                        />
                      )}
                    />
                  </FormControl>
                )}
              />
                <Tooltip
                      title={
                        <Typography sx={{ fontSize: "14px" }}>please select the status</Typography>
                      }
                      placement="right"
                      arrow
                    >
                      <IconButton size="small">
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
              </div>
              </div>
            </section>
            <div class="text-left mt-10">
              <CustomButton fieldType="submit" buttonName="Search" />
              {isLoading && <CustomLoading/>}
            </div>
          </form>
        </CardContent>
      </Card>
      <br />
      <br />

      <CustomMaterialWithoutActons
        columns={requestReportTable}
        data={datas}
        onRowClick={handleViewClick}
        actionButtons={tableActionButtons}
      />
    </div>
  );
}

export default McBmArReport;
