import React, { useState, useEffect } from 'react';
import {
  Tooltip,
  IconButton,
  Typography,
  List,
  ListItem,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ClearIcon from '@mui/icons-material/Clear';
import axios from "axios";
import { BASE_URL, BASE_URL_IMAGE, DELETE_FILE } from '../utills/ApplicationRouting';
import CustomButton from './CustomButton';

function CustomFileEdit({
  handleFileChange,
  token,
  fileError,
  fileData = [],
  file = [],
  downloadFiles = false,
  required,
  isRequired = false,
}) {
  const fileTypes = ["jpg", "png", "jpeg", "pdf"];
  const [files, setFiles] = useState([]);
  const [fileOld, setFileOld] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [isOldFile, setIsOldFile] = useState(false);

  useEffect(() => {
    setFiles(file);
  }, [file]);

  useEffect(() => {
    setFileOld(fileData);
  }, [fileData]);

  const removeFile = (fileToRemove) => {
    const updatedFiles = files.filter(f => f !== fileToRemove);
    setFiles(updatedFiles);
    handleFileChange(updatedFiles);
  }
  const handleFileChangeInternal = (newFiles) => {
    const updatedFiles = [...files, ...newFiles];
    setFiles(updatedFiles);
    handleFileChange(updatedFiles);
  };

  const openConfirmationDialog = (file, oldFile) => {
    setFileToDelete(file); 
    setIsOldFile(oldFile);
    setOpenDialog(true);
  };

  const confirmDelete = () => {
    if (isOldFile) {
      // Remove old file
      const updatedFileOld = fileOld.filter(
        (f) => f.attachmentId !== fileToDelete.attachmentId
      );
      setFileOld(updatedFileOld);
      handleDeleteFile(fileToDelete.file);
    } else {
      // Remove new file
      const updatedFiles = files.filter((f) => f !== fileToDelete);
      setFiles(updatedFiles);
      handleFileChange(updatedFiles);
    }
    setOpenDialog(false);
  };

  const handleDeleteFile = (fileName) => {
    axios
      .delete(`${BASE_URL}${DELETE_FILE}${fileName}`)
      .then((res) => {
        if (res.status === 200) {
          console.log("File deleted successfully.");
        }
      })
      .catch((error) => {
        console.error("Error deleting file:", error);
      });
  };

  const handleFileDataClick = async (fileData, token) => {
    if (!fileData) return;

    const getContentType = (fileName) => {
      const extension = fileName.split(".").pop().toLowerCase();
      switch (extension) {
        case "pdf":
          return "application/pdf";
        case "jpg":
        case "jpeg":
          return "image/jpeg";
        case "png":
          return "image/png";
        case "csv":
          return "text/csv";
        case "doc":
          return "application/msword";
        case "xls":
          return "application/vnd.ms-excel";
        default:
          return "application/octet-stream";
      }
    };

    try {
      const response = await axios.get(`${BASE_URL_IMAGE}/${fileData}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      });

      const contentType = getContentType(fileData);
      const fileBlob = new Blob([response.data], { type: contentType });

      if (contentType === "image/jpeg" || contentType === "image/png") {
        displayPreview(fileBlob, fileData);
      } else if (contentType === "application/pdf") {
        displayPDFPreview(fileBlob);
      } else {
        downloadFile(fileBlob, fileData);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const displayPreview = (fileBlob, fileName) => {
    const imagePreview = URL.createObjectURL(fileBlob);
    const newTab = window.open();

    const imgTag = document.createElement("img");
    imgTag.src = imagePreview;
    imgTag.style.display = "block";
    imgTag.style.margin = "auto";
    imgTag.style.width = "600px";
    imgTag.style.height = "600px";
    imgTag.style.objectFit = "contain";

    newTab.document.body.style.textAlign = "center";
    newTab.document.body.appendChild(imgTag);

    const downloadLink = document.createElement("a");
    downloadLink.href = imagePreview;
    downloadLink.download = fileName;

    const downloadIcon = document.createElement("img");
    downloadIcon.src = "download.png";
    downloadIcon.style.position = "absolute";
    downloadIcon.style.top = "10px";
    downloadIcon.style.right = "40px";
    downloadIcon.style.width = "40px";
    downloadIcon.style.height = "40px";

    downloadLink.appendChild(downloadIcon);
    newTab.document.body.appendChild(downloadLink);
  };

  const displayPDFPreview = (fileBlob) => {
    const pdfPreview = URL.createObjectURL(fileBlob);
    window.open(pdfPreview);
  };

  const downloadFile = (fileBlob, fileName) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(fileBlob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <FormLabel>
        Attach Documents{" "}
        {isRequired && <span className='text-red-500'>*</span>}
        {files?.length > 0 && (
  <div className='w-1/2'>
        <List>
          {files.map((file, index) => (
            <ListItem key={index}>
              <Typography>{file.name}</Typography>
          {!fileTypes.includes(file.name.split('.').pop().toLowerCase()) ? (
         <span className="text-red-600 text-sm ml-5">Please provide a valid document</span>
       ) : (
         file.size > 10 * 1024 * 1024 && (
           <span className="text-red-600 text-sm ml-5">Attachment size cannot exceed 10 MB</span>
         )
       )}    
          {file && (
            <div>
              <IconButton>
                <ClearIcon sx={{ color: "red" }}
                 onClick={(event) => { event.preventDefault(); removeFile(file); }}/>
              </IconButton>
              {file.fileError && (
                <p className="text-red-600 text-sm">{file.fileError}</p>
              )}
            </div>
          )}
            </ListItem>
          ))}
        </List>
  </div>
)}
        {downloadFiles && Array.isArray(fileOld) && fileOld.length > 0 && (
          <div>
          <List>
              {fileOld.map((file, index) => {
                const modifiedFile = { ...file, file: file.file.substring(12) };
                return(
              <ListItem key={index}>
                  <Typography>{modifiedFile.file}</Typography>
                  <div>
                    <IconButton >
                      <FileDownloadIcon onClick={(event) =>{ event.preventDefault(); handleFileDataClick(file.file, token);}}/>
                    </IconButton>
                    <IconButton onClick={() => openConfirmationDialog(file, true)}>
                  <ClearIcon sx={{ color: "red" }} />
                </IconButton>
                  </div>
              </ListItem>
                )}
              )}
          </List>
          </div>
        )}
        <FileUploader
          handleChange={handleFileChangeInternal}
          name="file"
          // types={fileTypes}
          multiple
        />
        <p className='text-sm'>Allowed file types: {fileTypes.join(", ")}</p>
        {fileError && <p className="text-red-600 text-sm">{fileError}</p>}
      </FormLabel>

      <Dialog
  open={openDialog}
  onClose={() => setOpenDialog(false)} 
>
  <DialogTitle>Delete File</DialogTitle>
  <DialogContent dividers>
    <DialogContentText>
      Are you sure you want to delete this file permanently? 
      <br />
      <p className='font-bold'>File name:</p>
      <strong>
  {((fileToDelete?.name || fileToDelete?.file)?.slice(12))}
</strong>
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <CustomButton click={() => setOpenDialog(false)} fieldType="submit" buttonName="Cancel" />
    <CustomButton click={confirmDelete} color="error" buttonName="Delete" />
  </DialogActions>
</Dialog>

    </div>
  );
}

export default CustomFileEdit;
