import React, { useEffect, useMemo, useState } from "react";
import CustomHeading from "../../../components/CustomHeading";
import CustomMaterialTable from "../../../components/CustomMaterialTable";
import { useNavigate } from "react-router-dom";
import {  ListAlt } from "@mui/icons-material";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL, GET_ALL_ARCHIVED_ARC_NOTIFICATION, GET_ALL_ARCHIVED_BM_NOTIFICATION, GET_ALL_ARCHIVED_MC_NOTIFICATION } from "../../../utills/ApplicationRouting";
import moment from "moment";
import CustomLoading from "../../../components/CustomLoading";


function McBmArcArchivedNotificationList() {
    const navigate = useNavigate();
    const { token, userLoginDetails,roles } = useSelector((state) => state.hybridhoa);
    const [notificationDetails, setNotificationDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
  
    const handleViewClick = (selectedRow)=>{
        navigate (`/mcbmarcarchivednotification/${selectedRow.original.notificationId}`);
    }
  
    const getBmNotificationDetails = async () => {
      try {
        const storedToken = token;
  
        console.log(storedToken);
  
        const response = await axios.get(`${BASE_URL}${GET_ALL_ARCHIVED_BM_NOTIFICATION}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        console.log(response.data);
  
        if (response.status === 200) {
          const data = response.data;
          setNotificationDetails(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        // Handle error
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    };
  
  
  
    const getARCNotificationDetails = async () => {
      try {
        const storedToken = token;
  
        console.log(storedToken);
  
        const response = await axios.get(`${BASE_URL}${GET_ALL_ARCHIVED_ARC_NOTIFICATION}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        console.log(response.data);
  
        if (response.status === 200) {
          const data = response.data;
          setNotificationDetails(data);
          setIsLoading(false);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        // Handle error
        console.error("Error fetching data:", error);
      }
    };
  
  
  
    const getMcNotificationDetails = async () => {
      try {
        const storedToken = token;
  
        console.log(storedToken);
  
        const response = await axios.get(`${BASE_URL}${GET_ALL_ARCHIVED_MC_NOTIFICATION}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        console.log(response.data);
  
        if (response.status === 200) {
          const data = response.data;
          setNotificationDetails(data);
          setIsLoading(false);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        // Handle error
        console.error("Error fetching data:", error);
      }
    };
  
  
  
    useEffect(() => {
      const fetchData = async () => {
  
        {roles.includes('ROLE_BM')   ? ( 
          await getBmNotificationDetails()) 
          :
          roles.includes('ROLE_ARC') ? (
          await getARCNotificationDetails()) 
          :
          await getMcNotificationDetails()}
        };
  
      fetchData();
    }, [roles]);
   

    
    const notificationTable = useMemo((rowData) => [
      {
        accessorKey: "serialNo",
        header: "#",
        size: 20,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "subject",
        header: "Subject",
      },
      {
        accessorKey: "createdDateAndTime",
        header: "Created Date",
        accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),

      },
      {
        accessorKey: "createdBy",
        header: "Created By",
        Cell: ({ row }) =>
          `${row.original.createdByDetails?.firstName} ${row.original.createdByDetails?.lastName}`,
      },
  
      {
        accessorKey: "status",
        header: "Status",
      },
    ]);
  
       const rowActions = [
     {
       label: "View",
       icon: <ListAlt color="primary" />,
       click: (row)=>handleViewClick(row),
     },
    
    
   ]

      return (
        <div>
           {isLoading && <CustomLoading/>}
          <CustomHeading title="Archived Notifications" />
    
         
          <CustomMaterialTable
            columns={notificationTable}
            data={notificationDetails}
            rowActions={rowActions}
            // isLoading={isLoading}
            onRowClick = {handleViewClick}

           
         />
 
            </div>
          );
        }
export default McBmArcArchivedNotificationList