import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CustomViewPage from '../../../components/CustomViewPage'
import { useSelector } from 'react-redux';
import { BASE_URL, NOTIFICATION } from '../../../utills/ApplicationRouting';
import axios from 'axios';
import moment from 'moment';
import CustomLoading from '../../../components/CustomLoading';
import CustomMaterialTable from '../../../components/CustomMaterialTable';

function McBmArcArchivedNotificationView() {
    const navigate = useNavigate()
    const { token, roles,userLoginDetails } = useSelector((state) => state.hybridhoa);
    const [notificationData, setNotificationData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [viewData, setViewData] = useState([]);
    const [firstNameData, setFirstNameData] = useState("");
    const [lastNameData, setLastNameData] = useState("");
    const { notificationId } = useParams();
    const [createdFor,setCreatedFor] = useState("");
    const [status,setStatus] = useState("");

    const handleClick =() =>{
        navigate(-1)
    }
  
    const getNotificationDetailsById = async () => {
      try {
        setIsLoading(true);
        const storedToken = token;
  
        console.log(storedToken);
  
        const response = await axios.get(
          `${BASE_URL}${NOTIFICATION}/${notificationId}`,
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
        console.log(response.data);
  
        if (response.status === 200) {
          const data = response.data;
          const createdDetails = data.createdByDetails;
  
          setFirstNameData(createdDetails.firstName);
          setLastNameData(createdDetails.lastName);
          setNotificationData(data);
          setViewData(data.reviewsComments);
       console.log(data.reviewsComments.map(comment => comment.createdFor));
       setCreatedFor(data.reviewsComments.map(comment => comment.createdFor));
       setStatus(data.reviewsComments.map(comment => comment.status));
          setIsLoading(false);
        } else {
          setIsLoading(false);
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        // Handle error
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    };
  
    useEffect(() => {
      getNotificationDetailsById();
    }, [notificationId]);
  
    const fullName = firstNameData + " " + lastNameData;
  const data = {
    pageTitle:"Archived Notification View",
    buttons :[
      {
        buttonTitle:"Back",
        onClick : handleClick
      },  
     
    ],
    content: [
      {
        topic: "Subject",
        description: notificationData.subject,
      },
      {
        topic: "Created By",
        description: fullName,
      },
      {
        topic: "Created Date",
        description: moment(notificationData.createdDateAndTime).format(
          "MM/DD/YYYY"
        ),
      },
      {
        topic: "Description",
        description: notificationData.description,
      },
      {
        topic: "Status",
        description: notificationData.status,
      },
    ],

    
  }

  const viewDataTable = useMemo((rowData) => [
    {
      accessorKey: "name",
      header: "Commented By",
      size: 20,
    },
    {
      accessorKey: "role",
      header: "Role",
      size: 20,
    },
    {
      accessorKey: "status",
      header: "Review Status",
      size: 20,
    },
    {
      accessorKey: "reviewDateTime",
      header: "Review DateTime",
      size: 20,
      Cell: ({ value }) => {
        const formattedDate = moment(value).format("MM/DD/YYYY");
        return formattedDate;
      },
    },
    {
      accessorKey: "comments",
      header: "Review Comments",
      size: 20,
    },
  ]);
    return (
      <div>
        {isLoading && <CustomLoading/>}
        <CustomViewPage data = {data} />
        <br/>
        <br/>

        <CustomMaterialTable columns={viewDataTable} data={viewData} />
           </div>
    )
  }

export default McBmArcArchivedNotificationView