import CustomViewPage from "../../components/CustomViewPage";
import CustomButton from "../../components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import CustomInputField from "../../components/CustomInputField";
import {
  COMMENTS_REQUIRED_MESSAGE,
  MAX_LENGTH_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
} from "../../utills/ApplicationConstants";
import {
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";
import CustomHeading from "../../components/CustomHeading";
import { useSelector } from "react-redux";
import {
  ADD_MAINTENANCE,
  BASE_URL,
  GENERAL_COMMENTS,
  GET_ALL_GENERAL_COMMENTS,
  MAINTENANCE_REQUEST,
  MAINTENANCE_REQUEST_BY_ID,
} from "../../utills/ApplicationRouting";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  DeleteOutline,
  EditNoteOutlined,
  FileDownloadOutlined,
} from "@mui/icons-material";
import moment from "moment/moment";
import CustomLoading from "../../components/CustomLoading";
import CustomToastContainer from "../../components/CustomToastContainer";
import { request } from "../../services/AxiosConfig";
import CustomModel from "../../components/CustomModel";
import Tooltip from "@mui/material/Tooltip";
import CustomPascalChange from "../../components/CustomPascalChange";
import AttachmentList from "../../components/AttachmentList";
import CustomViewHeading from "../../components/CustomViewHeading";
import CustomViewWithTooltip from "../../components/CustomViewWithTooltip";
import AttachmentListsView from "../../components/AttachmentListsView";
import CustomViewWithMore from "../../components/CustomViewWithMore";
import CustomGeneralComments from "../../components/CustomGeneralComments";

function MaintenanceRequestView() {
  const navigate = useNavigate();
  const { maintenanceId } = useParams();
  const [maintenanceRequestData, setMaitenanceRequestData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { token,userDetails, groupNames, roles } = useSelector((state) => state.hybridhoa);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
    reset,clearErrors
  } = useForm();
  const [generalCommentDetails, setGeneralCommentDetails] = useState("");
  const [editModes, setEditModes] = useState(
    Array.isArray(generalCommentDetails)
      ? Array(generalCommentDetails.length).fill(false)
      : []
  );
  const [deleteOpen, setDeleteOpen] = useState(false);
  
  const [generalCommentsData, setGeneralCommentsData] = useState(""); 
  const [generalCommentsDataAdd, setGeneralCommentsDataAdd] = useState("");
  const [commentsId, setCommentsId] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const[fileImage,setFileImage]=useState([])
  const [createdDateAndTimes, setCreatedDateAndTimes] = useState("");
  const HomeLoginId= userDetails.homeOwnerLoginId;
  const [showMoreInformation, setShowMoreInformation] = useState(false);
  const [showTopic, setShowTopic] = useState(false);
  const [createdByDetails,setCreatedByDetails]=useState("")

  const onSubmits = (data) => {};
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleClick = () => {
    navigate(-1);
  };

  const getMaintenanceRequestDetailsById = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${ADD_MAINTENANCE}/${maintenanceId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setMaitenanceRequestData(data);
        setIsLoading(false);
        setFileImage(data.attachments)
        setCreatedByDetails(data.createdByDetails)
        setFileUrl(data.fileUrl);
        const formattedDate = moment(data.createdDateAndTime).format(
          "MM/DD/YYYY hh:mm A"
        );
        setCreatedDateAndTimes(formattedDate);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getMaintenanceRequestDetailsById();
  }, []);

  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '' || str === null) return '';
  
    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };
  // const data = {
  //   pageTitle: "View Maintenance",
  //   buttons: [
  //     {
  //       buttonTitle: "Back",
  //       onClick: handleClick,
  //     },
  //   ],
  //   content: [
  //     {
  //       topic: "MaintenanceRequest Id",
  //       description: maintenanceRequestData.mrId,
  //     },
  //     {
  //       topic: "What needs attention?",
  //       description: maintenanceRequestData.topic,
  //     },
  //     {
  //       topic: "More Information ",
  //       description: maintenanceRequestData.description,
  //     },
  //     {
  //       topic: "Requested Date Time",
  //       description: moment(maintenanceRequestData.createdDateAndTime).format(
  //         "MM/DD/YYYY hh:mm:ss A"
  //       ),
  //     },
  //     {
  //       topic: "Updated Date Time",
  //       description: moment(maintenanceRequestData.auditDateAndTime).format(
  //         "MM/DD/YYYY hh:mm:ss A"
  //       ),
  //     },
  //     {
  //       topic: "Is this issue urgent? ",
  //       description: maintenanceRequestData.isUrgent ? "Yes" : "No",
  //     },
  //     {
  //       topic: "Is this issue causing a threat to personal safety?",
  //       description: "Yes",
  //     },
  //     {
  //       topic: "Created By",
  //       description: `${
  //         maintenanceRequestData.createdByDetails?.firstName || "Unknown"
  //       } ${maintenanceRequestData.createdByDetails?.lastName || "Unknown"}`,
  //     },
  //     {
  //       topic: "Status",
  //       description :convertToPascalCase(maintenanceRequestData.status)
  //     },
  //     {
  //       topic: "Request Type",
  //       description: maintenanceRequestData.type,
  //     },
  //     {
  //       topic: "Home Address",
  //       description: maintenanceRequestData.homeDetails?.address,
  //     },
  //     {
  //       topic: "Attachments",
  //       component: "AttachmentList",
  //       props: {
  //         fileUrl: fileUrl,
  //         documentName: fileUrl,
  //         docname: fileUrl,
  //         uploadedTimes: createdDateAndTimes,
  //       },
  //     },
  //   ],
  // };


  let formattedContact = "";
if (createdByDetails?.contact) {
  formattedContact = createdByDetails.contact.replace(/^\+\d+\s?(\d{3})(\d{4})(\d{3})$/, "$1-$2-$3");
}

  const fileList = fileImage.map(file => ({
    docname: file.file.substring(12), // Assuming file name is the document name
    fileUrl: file.file, // Assuming file location is the file URL
    documentnamed: file.file, // Assuming file name is the document name
    uploadedTimes: moment(file.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"), // Assuming uploadedTimes is available
    }));

    useEffect(()=>{
      console.log("roles",roles);
    },[roles])

  const data = [
    {
      groupName: 'Maintenance Details',
      items: [
        { label: 'MaintenanceRequest Id', data: `${maintenanceRequestData?.mrId || ""}`, text: 'Unique identifier for the maintenance request.' },
        // { label: 'What needs attention?', data: `${maintenanceRequestData?.topic || ""}`, text: 'Description of the issue or topic that needs attention.' },
        {
          label: "What needs attention?",
          data:`${maintenanceRequestData?.topic || ""}`,
          text: "Description of the issue or topic that needs attention.",
        },
        { label: 'More Information', data: `${maintenanceRequestData?.description || ""}`, text: 'Additional information or details about the maintenance request.' },
        { label: 'Is this issue urgent?', data: `${maintenanceRequestData.isUrgent ? "Yes" : "No" || ""}`, text: 'Indicates whether the issue requires immediate attention (Yes/No).' },
        { label: 'Is this issue causing a threat to personal safety?', data: `${maintenanceRequestData.isSafty ? "Yes" : "No" || ""}`, text: 'Indicates whether the issue poses a threat to personal safety (Yes/No).' },
        { label: 'Status', data: `${convertToPascalCase(maintenanceRequestData?.status) || ""}`, text: 'Current status of the maintenance request.' },
        { label: 'Request Type', data: `${maintenanceRequestData?.type || ""}`, text: 'Type or category of the maintenance request.' },
      ],
    },
    {
      groupName: 'Date Details',
      items: [
        {
          label: 'Start Date',
          data: maintenanceRequestData?.startDate && moment(maintenanceRequestData.startDate).isValid() ? moment(maintenanceRequestData.startDate).format("MM/DD/YYYY") : "",
          text: 'Date and time when the maintenance request was initiated.'
        },
        {
          label: 'End Date',
          data: maintenanceRequestData?.endDate && moment(maintenanceRequestData.endDate).isValid() ? moment(maintenanceRequestData.endDate).format("MM/DD/YYYY") : "",
          text: 'Date and time when the maintenance request is expected to be completed.'
        },       
        // { label: 'Requested Date Time', data: `${moment(maintenanceRequestData?.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss A") || "" }`, text: 'Date and time when the maintenance request was submitted.' },
        // { label: 'Updated Date Time', data: `${moment(maintenanceRequestData?.auditDateAndTime).format("MM/DD/YYYY hh:mm:ss A") || ""}`, text: 'Date and time when the maintenance request was last updated.' },
      ],
    },
    {
      groupName: 'Attachment Details',
      items: [
        { label: 'Attachment', data: (
          <AttachmentListsView fileList={fileList} />
          ), text: 'List of attached files/documents related to the maintenance request.' },
      ],
    },
  ];
  

  if (roles === 'ROLE_MC' && createdByDetails) {
    data.push({
      groupName: 'Created By Details',
      items: [
        {
          label: 'Firstname',
          label2: 'Lastname',
          data: `${createdByDetails?.firstName || ""}`,
          data2: `${createdByDetails?.lastName || ""}`,
          text: 'First and Last name of the person or entity who created the compliance request.',
        },
        { 
          label: 'Email', 
          data: `${createdByDetails?.email || ""}`, 
          text: 'Email address of the person or entity who created the compliance request.' 
        },
        { 
          label: 'Address', 
          data: `${groupNames || ""}`, 
          text: 'Home address of the person or entity who created the compliance request.' 
        },
        { 
          label: 'Contact', 
          data: `${formattedContact || ""}`, 
          text: 'Contact number of the person or entity who created the compliance request.' 
        },
      ],
    });
  }
  

  const handleEditClick = (index, generalCommentsId) => {
    console.log("Edit button clicked", index, generalCommentsId);
    setEditModes((prevModes) =>
      prevModes.map((mode, i) => (i === index ? true : mode))
    );
    setCommentsId(generalCommentsId);
    console.log("CommentsId set:", generalCommentsId);
    getGeneralCommentsById();
  };

  const handleInputChange = (e) => {
    setGeneralCommentsData(e.target.value);
  };
  const handleInputChangeAdd = (e) => {
    setGeneralCommentsDataAdd(e.target.value);
  };
  const handleCancelClick = () => {
    clearErrors("editComments");
    getGeneralComments();
    setEditModes(false);
    setValue('editComments',"")
  };
  

  const getGeneralComments = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${GET_ALL_GENERAL_COMMENTS}/${maintenanceId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setGeneralCommentDetails(data);
        setEditModes(Array(data.length).fill(false));
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getGeneralComments();
  }, []);
  const onSubmit = (data,e) => {
  
    setIsLoading(true);

    // if (generalCommentsData == "" || generalCommentsData == null) {
    //   setError("comments", {
    //     type: "manual",
    //     message: COMMENTS_REQUIRED_MESSAGE,
    //   });
    //   setIsLoading(false);
    //   return;
    // }
    if (typeof generalCommentsDataAdd !== 'string' || generalCommentsDataAdd.trim() === '') {
      setError("comments", {
        type: "manual",
        message: COMMENTS_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    const postData = {
      comments: generalCommentsDataAdd,
      referenceId: maintenanceId,
    };
    request({ url: GENERAL_COMMENTS, method: "post", data: postData })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          getGeneralComments();
          // postData = {};
          console.log("Success");
          setIsLoading(false);
          setGeneralCommentsDataAdd("")  
          // e.target.reset();
          // formRef.current.reset(); 
          setTimeout(() => {
            //  navigate(-1);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const getGeneralCommentsById = async () => {
    try {
      setIsLoading(true)
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${GENERAL_COMMENTS}/${commentsId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        console.log(response.data);
        setGeneralCommentsData(data.comments);

        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getGeneralCommentsById();
  }, [commentsId]);

  useEffect(()=>{
    if (generalCommentsData.trim().length > 1000) {
      setError("editComments", {
          type: "manual",
          message: MAX_LENGTH_THOUSAND,
      });
      setIsLoading(false);
      return;
  }
  },[generalCommentsData])

  const submitUpdate = (data) => {
    console.log("Test");
    setIsLoading(true);
  

    if (generalCommentsData.trim() == "" || generalCommentsData.trim() == null) {
      setError("editComments", {
        type: "manual",
        message: COMMENTS_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    // if (generalCommentsData.trim().length < 2) {
    //   setError("editComments", {
    //     type: "manual",
    //     message: MIN_LENGTH_VALIDATE_MESSAGE,
    //   });
    //   setIsLoading(false);
    //   return;
    // }

    // if (generalCommentsData.trim().length > 1000) {
    //   setError("editComments", {
    //     type: "manual",
    //     message: MAX_LENGTH_THOUSAND,
    //   });
    //   setIsLoading(false);
    //   return;
    // }
    const putData = {
      comments: generalCommentsData.trim(),
      referenceId: commentsId,
    };
    request({
      url: `${GENERAL_COMMENTS}/${commentsId}`,
      method: "put",
      data: putData,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");
          setIsLoading(false);
          getGeneralComments();
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const handleDeleteClickOpen = (generalCommentsId) => {
    setDeleteOpen(true);

    setCommentsId(generalCommentsId);
    getGeneralCommentsById();
  };
  const onSubmitDelete = () => {
    request({ url: `${GENERAL_COMMENTS}/${commentsId}`, method: "delete" })
      .then((res) => {
        console.log(res);
        getGeneralComments();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const okCancelButtonActions = (
    <>
      
      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleDeleteClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleDeleteClose}
      />
    </>
  );

  const deleteModel = (
    <form
      onSubmit={handleSubmit(onSubmitDelete)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography> Are you sure you want to delete comment?</Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );

  return (
    <div>
      {isLoading && <CustomLoading/>}
      {/* <CustomViewPage data={data} /> */}
      <CustomViewHeading onClick={handleClick} fileList={fileList} information={(maintenanceRequestData.description)} attention={maintenanceRequestData.topic} title={"Maintenance Request"} data={data} >
      <CustomViewWithMore data={data}  />
      <CustomToastContainer />
      <br />

      {/* <CustomHeading title="General Comments" />
      <CustomModel
        title=" Delete Comments"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      />
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
          <div className="flex items-center space-x-4">
      <CustomInputField
        fieldName="comments"
        // requiredErrorMessage={COMMENTS_REQUIRED_MESSAGE}
        minLength={2}
        minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
        maxLength={1000}
        maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
        fieldId="comments"
        fieldType="text"
        fieldLabel="Write a Comment"
        fieldControl={control}
        fieldError={errors}
        fieldRequiredIcon="*"
        multiline={true}
        numberOfRows={2}
        value={generalCommentsDataAdd}
        onInput={handleInputChangeAdd}
        className="flex-grow"
      />
      <CustomButton fieldType="submit" buttonName="Post" />
      {isLoading && <CustomLoading />}
    </div>
          </CardContent>
        </form>
        <CardContent>
          {generalCommentDetails &&
            generalCommentDetails.map((comment, index) => (
              <div key={comment.generalCommentsId}>
                <section className="grid md:grid-cols-1 xl:grid-cols-1 gap-3 mt-3 ml-16">
                  <p>
                    <span className="text-primary" style={{ float: "left" }}>
                      @ {comment.createdByName}{" "}
                    </span>
                    <span style={{ float: "right" }}>
                     

                      {!editModes[index] && comment.createdById === HomeLoginId &&  (
                        <>
                          {index === 0 && (
                            <>
                               <Tooltip title="Edit Comment">
  <EditNoteOutlined
    color="primary"
    onClick={() => {
      handleEditClick(index, comment.generalCommentsId);
      console.log(comment.generalCommentsId);
    }}
  />
</Tooltip>
                              &nbsp; 
                              <Tooltip title="Delete Comment">
  <DeleteOutline
    color="error"
    onClick={() => {
      handleDeleteClickOpen(comment.generalCommentsId);
      console.log(comment.generalCommentsId);
    }}
  />
</Tooltip>
                            </>
                          )}
                        </>
                      )}
                       {moment(comment.auditDateAndTime).format(
                        "MM/DD/YYYY hh:mm:ss a"
                      )}
                    </span>
                    {editModes[index] ? (
                      <form
                        onSubmit={handleSubmit(submitUpdate)}
                        className="space-y-4 md:space-y-6 "
                      >
                        <CardContent>
                          <CustomInputField
                            fieldName="editComments"
                            
                            minLength={2}
                            minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                            maxLength={1000}
                            maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
                            fieldId="editComments"
                            fieldType="text"
                            // fieldLabel="Write a Comments"
                            fieldControl={control}
                            errors={errors}
                            // fieldRequiredIcon="*"
                            multiline={true}
                            numberOfRows={2}
                            value={generalCommentsData}
                            onInput={handleInputChange}
                          />

<div className="flex justify-center mt-8 space-x-1">
  <CustomButton
    fieldType="submit"
    buttonName="Save"
  />
  <CustomButton
    fieldType="button"
    buttonName="Cancel"
    click={handleCancelClick}
  />
  {isLoading && <CustomLoading />}
</div>
                        </CardContent>
                      </form>
                    ) : (
                      <p className="mt-8 mb-3">{comment.comments}</p>
                    )}
                  </p>
                </section>

                <Divider />
              </div>
            ))}
        </CardContent>
      </Card> */}

      <CustomGeneralComments />
      </CustomViewHeading>
    </div>
  );
}

export default MaintenanceRequestView;
