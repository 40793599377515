import { Delete, EditNoteOutlined, ListAlt } from "@mui/icons-material";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {  useNavigate } from "react-router-dom";
import CustomHeading from "../../../components/CustomHeading";
import CustomMaterialTable from "../../../components/CustomMaterialTable";
import CustomButton from "../../../components/CustomButton";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL, CATEGORY, GROUP_CONFIG, MAINTENANCE_REQUEST, MAINTENANCE_REQUEST_BY_ID, MAINTENANCE_REQUEST_MC, MAINTENANCE_REQUEST_MC_HO, MAINTENANCE_REQUEST_SINGLE } from "../../../utills/ApplicationRouting";
import moment from "moment";
import CustomMaterialWithoutActons from "../../../components/CustomMaterialWithoutActons";
import CustomLoading from "../../../components/CustomLoading";

function MaintenanceRequestListHoInMc() {
  const { token,roles } = useSelector(state => state.hybridhoa);
  const [maintenanceRequestDetails,setMaintenanceRequestDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [groupName,setGroupName]=useState("");
      
    const navigate = useNavigate();
 
    const handleViewClick = (selectedRow) => {
        navigate(`/homcmaintenanceview/${selectedRow.row.original.maintenanceId}`);
    };

    const getCategory = async () => {
      try {
        const storedToken = token;
    
        const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
    
        if (response.status === 200) {
          const allCategories = response.data;
    
          // Filter categories based on conditions
          // const filteredCategories = allCategories.filter(category => {
          //   return (
          //     (category.isEventForArc && roles.includes('ROLE_ARC')) ||
          //     (!category.isEventForArc && roles.includes('ROLE_BM'))
          //   );
          // });
    
          setCategoryDetails(allCategories); // Set the filtered data to the state
          setIsLoading(false);
        } else {
          setIsLoading(false);
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        // Handle error
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    };
    
    useEffect(() => {
      getCategory();
    }, []);
   
    const getGroupConfigurationDetails = async () => {
      try {
        const storedToken = token;
        const response = await axios.get(`${BASE_URL}${GROUP_CONFIG}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
    
        if (response.status === 200) {
          const data = response.data;
          const nameGroup = data.find((item) => item.topic === 'Contact Details')
          console.log("data",nameGroup.content);
          const groupContent = nameGroup.content
          setGroupName(groupContent);
          
        } else {
          console.error("Failed to fetch data. Status:", response.status);
          console.error("Response:", response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Status code:", error.response.status);
        }
      }
    };
    
  
    useEffect(()=>{
      getGroupConfigurationDetails()
      console.log("working");
    },[])

   const getMaintananceRequestDetails = async () => {
    try {
          const storedToken = token

      console.log(storedToken);
   
        const response= await axios.get(`${BASE_URL}${MAINTENANCE_REQUEST_MC_HO}`,{
          headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
              console.log(response.data); 

      if (response.status === 200) {
        const data = response.data;
        setMaintenanceRequestDetails(data);
        setIsLoading(false);

      } else {
        setIsLoading(false);
        throw new Error('Failed to fetch data');
      }
     
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };



useEffect(()=>{
  getMaintananceRequestDetails();
},[])
 

const maintenanceRequestTable = useMemo((rowData) => [
  {
    accessorKey: "mrId",
    header: "Id",
    size: 20,
  },
  {
    accessorKey: "categoryDetails.category",
    header: "Category",
    Cell: ({ row }) => {
      if (row.original.categoryDetails === null || row.original.categoryDetails === undefined) {
        return "";
      } else {
        return row.original.categoryDetails.category || "";
      }
    },
  },
  {
    accessorKey: "description",
    header: "Description",
    accessorFn: ( row ) => row.topic?row.topic:"",
  },
  {
    accessorKey: "createdByDetails.firstName",
    header: "Firstname",
    accessorFn: (row) => {
      console.log("row",row)
      return row.createdByDetails?.firstName;
    }
  },
  {
    accessorKey: "createdByDetails.lastName",
    header: "Lastname",
    accessorFn: (row) => {
      console.log("row",row)
      return row.createdByDetails?.lastName;
    }
  },
  {
    accessorKey: "homeDetails.address",
    header: "Address",
    accessorFn: (row) => {
      console.log("row",row)
      if(row.role==="ROLE_MC"){
        return groupName
      }else{
      return row.homeDetails?.address;
      }
    }
  },
  //  {
  //    accessorKey: "isUrgent",

  //    accessorFn: (row) => (row && row.isUrgent ? "Yes" : "No"),
  //    header: "Urgent?",
  //  },
  // {
  //   accessorKey: "category",
  //   header: "Category",
  //   Cell: ({ row }) =>
  //     row.original.categoryDetails
  //       ? row.original.categoryDetails.category
  //       : "N/A",
  // },
 
  {
    accessorKey: "status",
    header: "Status",
    Cell: ({ row }) => {
      const status = row.original.status;
      if(status==="PENDING_IN_ARC"){
        return "Pending In ARC"
      }else{
      return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
      }
    },
  },
  
  // {
  //   accessorKey: "homeDetails.address",
  //   header: "Created By",
  //   cell: ({ row }) => {
  //     const address = row.original.createdByDetails?.homeDetails?.address;
  //     return address || "N/A";
  //   },},
  {
    accessorKey: "startDate",
    header: "Start Date",
    accessorFn: (row) => {
      const startDate = moment(row?.startDate);
      return startDate.isValid() ? startDate.format("MM/DD/YYYY") : "N/A";
    }
  },
  {
    accessorKey: "endDate",
    header: "End Date",
    accessorFn: (row) => {
      const endDate = moment(row?.endDate);
      return endDate.isValid() ? endDate.format("MM/DD/YYYY") : "N/A";
    }
  },
  {
    accessorKey: "isUrgent",
    
    accessorFn: (row) => (row && row.isUrgent ? "Yes" : "No"),
    header: "Is Urgent?",
    size: 20,
  },
    ]);

  const rowActions = [
    //  {
    //    label: "View",
    //    icon: <ListAlt color="primary" />,
    //    click: handleViewClick,
    //  },
    
   
   ]
  const handleAddClick = () => {
    navigate("/maintenancerequestmcadd");
  };

  // const tableActionButtons = roles == "ROLE_MC" ? (
  //   <CustomButton
  //     fieldType="button"
  //     buttonName="Create"
  //     click={handleAddClick}
  //   />
  // ) : null;
 
 
  
   return (
     <div>
       {isLoading && <CustomLoading />}
       <CustomHeading title="Maintenance Request" />
 
      
       <CustomMaterialTable
         columns={maintenanceRequestTable}
         data={maintenanceRequestDetails}
        //  isLoading={isLoading}
         onRowClick = {handleViewClick}
       />
     </div>
   );
 }

export default MaintenanceRequestListHoInMc