import { Button, Box } from "@mui/material";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { Tooltip, IconButton, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

const CustomInputField = ({
  fieldName,
  requiredErrorMessage,
  fieldPattern,
  patternErrorMessage,
  fieldId,
  fieldType,
  fieldLabel,
  fieldControl,
  fieldError,
  fieldTrigger,
  value,
  onChange,
  defaultValue,
  fieldRequiredIcon,
  disabled,
  multiline,
  numberOfRows,
  minLength,
  minLengthErrorMessage,
  maxLength,
  maxLengthErrorMessage,
  onInput,
  tooltipMessage
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="flex items-center md:w-1/2 w-full">
    <Controller
      name={fieldName}
      control={fieldControl}
      defaultValue={defaultValue}
      rules={{
        required: requiredErrorMessage,
        pattern: {
          value: fieldPattern,
          message: patternErrorMessage,
        },
        minLength: {
          value: minLength,
          message: minLengthErrorMessage,
        },
        maxLength: {
          value: maxLength,
          message: maxLengthErrorMessage,
        },
      }}
      render={({ field, fieldState }) => (
        <Box>
          <div
            id={fieldId}
            style={{
              fontSize: 16,
              fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,
              overflow: "hidden",
              wordWrap: "break-word",
              wordBreak: "break-all",
              whiteSpace: "pre-wrap",
              maxHeight: expanded ? "none" : "3em", // Show 3 lines by default
            }}
          >
            {value}
          </div>
          {(value.length > 50 || expanded) && (
          <Button
          size="small"
          onClick={toggleExpand}
          sx={{
            padding: "4px 8px",
            fontSize: 10,
            lineHeight: 1,
            borderRadius: 4,
          }}
        >
          {expanded ? "less" : "Read more"}
        </Button>
          )}
        </Box>
      )}
    />
     {tooltipMessage && (
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: "14px" }}>{tooltipMessage}</Typography>
                      }
                      placement="right"
                      arrow
                    >
                      <IconButton size="small">
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
  );
};

export default CustomInputField;
