import React, { useEffect, useMemo, useState } from "react";
import CustomViewPage from "../../components/CustomViewPage";
import { useSelector } from "react-redux";
import {
  BASE_URL,
  COMPLIANCE_LINK,
  COMPLIANCE_REQUEST,
  COMPLIANCE_REQUEST_BY_ID,
  COMPLIANCE_REVIEW,
  GENERAL_COMMENTS,
  GET_ALL_GENERAL_COMMENTS,
} from "../../utills/ApplicationRouting";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import CustomButton from "../../components/CustomButton";

import {
  Autocomplete,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,Button,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  MAX_LENGTH_FIVE_HUNDRED,
  MIN_LENGTH_VALIDATE_MESSAGE,
  REVIEW_COMMENTS_REQUIRED_MESSAGE,
  COMMENTS_REQUIRED_MESSAGE,
  MAX_LENGTH_THOUSAND,
  STATUS_REQUIRED_MESSAGE,
} from "../../utills/ApplicationConstants";
import CustomInputField from "../../components/CustomInputField";
import { request } from "../../services/AxiosConfig";
import CustomLoading from "../../components/CustomLoading";
import CustomToastContainer from "../../components/CustomToastContainer";
import CustomPascalChange from "../../components/CustomPascalChange";
import CustomMaterialWithoutRoutes from "../../components/CustomMaterialWithoutRoutes";
import AttachmentList from "../../components/AttachmentList";
import CustomViewHeading from "../../components/CustomViewHeading";
import CustomViewWithTooltip from "../../components/CustomViewWithTooltip";
import CustomHeading from "../../components/CustomHeading";
import CustomModel from "../../components/CustomModel";
import {
  DeleteOutline,
  EditNoteOutlined,
  FileDownloadOutlined,
} from "@mui/icons-material";
import CustomReviewTable from "../../components/CustomReviewTable";
import AttachmentListsView from "../../components/AttachmentListsView";
import CustomLateFees from "../../components/CustomLateFees";
import CustomFile from "../../components/CustomFile";
import CustomViewWithMore from "../../components/CustomViewWithMore";

function getRoleAbbreviation(role) {
  switch (role) {
    case "ROLE_ARC":
      return "ARC";
    case "ROLE_MC":
      return "Management Company";
    case "ROLE_HO":
      return "HO";
      case "ROLE_BM":
      return "Board Member";
      case "ROLE_GU":
      return "GU";
    default:
      return role; // Return original role if not one of the specified abbreviations
  }
}
function RaisedByArcView() {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },

    setValue,
    setError
  } = useForm();
  const { token, userDetails, roles } = useSelector(
    (state) => state.hybridhoa
  );
  const HomeLoginId= userDetails.homeOwnerLoginId;
  const { complainceId } = useParams();
  const fileTypes = ["JPG", "PNG", "JPEG", "PDF"];
  const [file, setFile] = useState([]);

  console.log("HomeLoginId",HomeLoginId);

  const handleFileChange = (newFiles) => {
    setFile(newFiles);
  };

  const [fileUrl, setFileUrl] = useState([]);
  const [createdDate, setCreatedDate] = useState("");
  const [complianceRequestData, setComplianceRequestData] = useState("");
  const [reviewComments, setReviewComments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [review, setReview] = useState("");
  const [formByLaw, setFormByLaw] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [fileError, setFileError] = useState("");
  const [createdByDetails,setCreatedByDetails]=useState("");
  const [fineDetails,setFineDetails]=useState([]);
  const [showFullDescription, setShowFullDescription] = useState(false); 
  const [statuss,setStatuss]=useState("");
  const [generalCommentsDataAdd, setGeneralCommentsDataAdd] = useState("");
  const {
    handleSubmit: handleSubmitGeneral,
    control: controlGeneral,
    formState: { errors: errorsGeneral },
    setValue: setValueGeneral,
    setError: setErrorGeneral,
    watch: watchGeneral,
    clearErrors: clearErrorsGeneral,
  } = useForm();

  const [expandedRows, setExpandedRows] = useState({});

  const toggleDescription = (rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };


  const [generalCommentDetails, setGeneralCommentDetails] = useState("");
  const [homeDues,setHomeDues]=useState(0);

  const [editModes, setEditModes] = useState(
    Array.isArray(generalCommentDetails)
      ? Array(generalCommentDetails.length).fill(false)
      : []
  );
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [generalCommentsData, setGeneralCommentsData] = useState("");
  const [commentsId, setCommentsId] = useState("");
  const [createdDateAndTimes, setCreatedDateAndTimes] = useState("");
  const [createdToDetails,setCreatedToDetails]=useState("");
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };
  const handleReviewChange = (event) => {
    setReview(event.target.value);
  };
  const getComplianceRequestDetailsById = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      const response = await axios.get(
        `${BASE_URL}${COMPLIANCE_REQUEST_BY_ID}/${complainceId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },  
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        setIsLoading(false);

        const data = response.data.compliance;
        const lateFees = response.data?.crfinehistory;
        const totalDueAmount = lateFees
        // .filter(item => item.status === 'PENDING')
        .reduce((accumulator, current) => accumulator + (+current['dueAmount']), 0);
      
  
  console.log(totalDueAmount);
  setHomeDues(totalDueAmount)
  const pendingLateFees = lateFees.filter(fee => fee.status === 'PENDING');
  setFineDetails(pendingLateFees);
        console.log(data);
        setComplianceRequestData(data);

        setStatus(data.status);
        setValue("status", data.status);
        setCreatedByDetails(data.createdByDetails)
        setFormByLaw(data.bylawId);
        const reviewtable = data.reviewComments;
        console.log(data.reviewComments);
        setReviewComments(reviewtable);
        setSelectedStatus(data.status);
        setFileUrl(data.attachments);
 setStatuss(data.status)
        const formattedDate = moment(data.createdDateAndTime).format(
          "MM/DD/YYYY hh:mm A"
        );
        setCreatedDate(formattedDate);
        console.log("datee",formattedDate);
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getComplianceRequestDetailsById();
  }, [complainceId]);

  const handleClick = () => {
    navigate(-1);
  };
  

  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const fileList = fileUrl.map(file => ({
    docname: file.file.substring(12), // Assuming file name is the document name
    fileUrl: file.file, // Assuming file location is the file URL
    documentnamed: file.file, // Assuming file name is the document name
    uploadedTimes:  moment(file.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"), // Assuming uploadedTimes is available
  }));
  const renderDescription = () => {
    console.log("inside render ");
    if (complianceRequestData.description && !showFullDescription) {
    console.log("inside render ",complianceRequestData.description);
  
      return (
        <>
        
          {complianceRequestData.description.slice(0, 250)}
          {complianceRequestData.description.length > 250 && (
            <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Read More</Button>
          )}
        </>
      );
    } else {
    console.log("inside render else ",complianceRequestData.description);
  
      return (
        <>
          {complianceRequestData.description}
          <Button onClick={handleToggleDescription} style={{fontSize:"12px"}}>Less</Button>
        </>
      );
    }
  };

  // const data = {
  //   pageTitle: "View Compliance",
  //   buttons: [
  //     {
  //       buttonTitle: "Back",
  //       onClick: handleClick,
  //     },
  //   ],
  //   content: [
  //     {
  //       topic: "Created By",
  //       description: getRoleAbbreviation(complianceRequestData.role)
  //     },
  //     {
  //       topic: "Created Date Time",
  //       description: moment(complianceRequestData.createdDateAndTime).format(
  //         "MM/DD/YYYY hh:mm:ss a"
  //       ),
  //     },
  //     {
  //       topic: "Category",
  //       description: `${complianceRequestData.categoryDetails?.category || ""}`,
  //     },
  //     {
  //       topic: "ByLaws Topic",
  //       description: `${complianceRequestData.bylawDetails?.topic || ""}`,
  //     },
  //     {
  //       topic: "Audit Date Time ",
  //       description: moment(complianceRequestData.auditDateAndTime).format(
  //         "MM/DD/YYYY hh:mm:ss a"
  //       ),
  //     },
  //     {
  //       topic: "Status",
  //       description: CustomPascalChange(status),
  //     },
  //     {
  //       topic: "Created To",
  //       description: `${
  //         complianceRequestData.createdToDetails?.firstName || ""
  //       } ${complianceRequestData.createdToDetails?.lastName || ""}`,
  //     },
  //     {
  //       topic: "Address",
  //       description: `${complianceRequestData.address?.address}`,
  //     },
  //     {
  //       topic: "Description",
  //       description: complianceRequestData.description,
  //     },
  //     {
  //       topic: "Attachments",
  //       component: "AttachmentList",
  //       props: {
  //         fileUrl: fileUrl,
  //         documentName: fileUrl,
  //         docname: fileUrl,
  //         uploadedTimes:createdDate,
  //       },
  //     },
  //   ],
  // };

  useEffect(()=>{
    console.log("fileurl",fileUrl);
  },[fileUrl])
  

 

//   let formattedContact = "";
// if (createdByDetails?.contact) {
//   formattedContact = createdByDetails.contact.replace(/^\+\d+\s?(\d{3})(\d{4})(\d{3})$/, "$1-$2-$3");
// }

const roleMap = {
  ROLE_ARC: "ARC",
  ROLE_BM: "Board Member",
  ROLE_MC: "Management Company"
};

const getMappedRole = (role) => {
  return roleMap[role] || role;
};

const viewCrFineTable = useMemo((rowData) => [
  {
    accessorKey: "dueDate",
    header: "Fine Added Date",
    accessorFn: (row) => moment(row.dueDate).format("MM/DD/YYYY"),
  },
  {
    accessorKey: "dueAmount",
    header: "Amount",
    size: 20,
  },
  // {
  //   accessorKey: "status",
  //   header: "Status",
  //   size: 20,
  //   Cell: ({ row }) => {
  //     const status = row.original.status;
  //     return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  //   },
  // },
  {
  accessorFn: row => row.description,
  id: 'description',
  header: 'Description',
  Cell: ({ row }) => {
    const comments = row.original.description;
    const isExpanded = expandedRows[row.id];
    const shouldShowMoreButton = comments && comments.length > 28;

    return (
      <div>
        {isExpanded ? comments : comments.slice(0, 28) + (shouldShowMoreButton ? '...' : '')}
        {shouldShowMoreButton && (
          <Button
            size="small"
            onClick={() => toggleDescription(row.id)}
            style={{ marginLeft: '10px' }}
          >
            {isExpanded ? 'Show Less' : 'Show More'}
          </Button>
        )}
      </div>
    );
  },
}
]);

const data = [
  {
    groupName: 'Compliance Request Details',
    items: [
      { label: 'Compliance Request Id', data: `${complianceRequestData?.crId || ""}`, text: 'Unique identifier for the compliance request' },
      { label: 'Created Date Time', data: complianceRequestData?.crCreatedDate 
        ? moment(complianceRequestData?.crCreatedDate).format("MM/DD/YYYY hh:mm:ss A")
        : complianceRequestData?.createdDateAndTime 
        ? moment(complianceRequestData.createdDateAndTime, moment.ISO_8601).format("MM/DD/YYYY hh:mm:ss A")
        : ""
, text: 'The date and time when the compliance request was created' },
      { label: 'Category', data: `${complianceRequestData.categoryDetails?.category || ""}`, text: 'The category or subject of the compliance request' },
      { label: 'Description', data: renderDescription(), text: 'Detailed description of the compliance issue' },
      { label: 'ByLaws Topic', data: `${complianceRequestData.bylawDetails?.topic || ""}`, text: 'The topic of the bylaw related to the compliance issue' },
      { label: 'ByLaws Description', data: `${complianceRequestData.bylawDetails?.description || ""}`, text: 'The description of the bylaw related to the compliance issue' },
    ],
  },
  {
    groupName: 'Audit and Status Details',
    items: [
      { label: 'Audit Date Time', data: `${moment(complianceRequestData?.auditDateAndTime).format("MM/DD/YYYY hh:mm:ss A") || ""}`, text: 'The date and time of the last audit' },
      { label: 'Status', data: `${CustomPascalChange(statuss)}`, text: 'Current status of the compliance request (e.g., Open, Closed)' },
    ]
  },
  {
    groupName: 'Attachment Details',
    items: [
      { label: 'Attachment', data: (<AttachmentListsView fileList={fileList} />), text: 'List of documents attached to the compliance request' },
    ],
  },
  {
    groupName: 'Created By Details',
    items: [
      { label: 'Created By', data: `${getMappedRole(complianceRequestData?.role)}`, text: 'Role of the person who created the compliance request (e.g., ARC, BM)' },
    ],
  },
  {
    groupName: 'Created To Details',
    items: [
      { label: 'Firstname', data: `${complianceRequestData?.createdToDetails?.firstName || ""}`, text: 'First name of the person or entity to whom the compliance request was created' },
      { label: 'Lastname', data: `${complianceRequestData?.createdToDetails?.lastName || ""}`, text: 'Last name of the person or entity to whom the compliance request was created' },
      { label: 'Email', data: `${complianceRequestData?.createdToDetails?.email || ""}`, text: 'Email address of the person or entity to whom the compliance request was created' },
      { label: 'Address', data: `${complianceRequestData?.address?.address || " "}`, text: 'Home address of the person or entity to whom the compliance request was created' },
      { label: 'Contact', data: `${complianceRequestData?.createdToDetails?.contact || ""}`, text: 'Contact number of the person or entity to whom the compliance request was created.' },
    ],
  },
];

const crFineTable=()=>{
  return <CustomMaterialWithoutRoutes columns={viewCrFineTable} data={fineDetails} />
}
if (
  moment().diff(moment(complianceRequestData?.crCreatedDate || complianceRequestData?.createdDateAndTime), 'days') >= 30 &&
  HomeLoginId !== complianceRequestData.createdToDetails?.homeOwnerLoginId &&
  (( complianceRequestData.status === "ACKNOWLEDGED" && complianceRequestData.isLinked) || (complianceRequestData.status === "NOT RESOLVED" && complianceRequestData.isLinked) || (complianceRequestData.isLinked && complianceRequestData.status === "PENDING")) &&
  homeDues !== 0
) {
  data.splice(5, 0, {
      groupName: 'Compliance Request Fine Details',
      items: [
          ...(moment().diff(moment(complianceRequestData?.crCreatedDate || complianceRequestData?.createdDateAndTime), 'days') >= 30 &&
              HomeLoginId !== complianceRequestData.createdToDetails?.homeOwnerLoginId && ((complianceRequestData.status === "ACKNOWLEDGED" && complianceRequestData.isLinked) || (complianceRequestData.isLinked && complianceRequestData.status === "PENDING"))
              ? [
                { label: 'Compliance Fine Applied', data: `${complianceRequestData.isFineApplicable? 'Yes' : 'No'}` ,text: 'Indicates if a fine is applicable for this compliance request.'}, 
                  // {
                  //     label: 'Apply Fine',
                  //     data: <CustomLateFees
                         
                  //         complainceId={complianceRequestData?.complainceId}
                  //         getData={getComplianceFineById}
                  //     />
                  // }
              ]
              : []
          ),
          ...(homeDues !== 0
              ? [
                  {
                      label: "Compliance Request Fine",
                      data: `${parseFloat(homeDues).toFixed(2)}`,
                      text: "The Compliance Request Fine is for raised compliance that has not been resolved or responded to by a particular homeowner."
                  },{
                    label:"Compliance Request Fine History",
                    data:crFineTable()
                  }
              ]
              : []
          ),
         
      ],
  });
}


  const viewDataTable = useMemo((rowData) => [
    {
      accessorKey: "serialNo",
      header: "#",
      size: 20,
      Cell: ({ row }) => row.index + 1,
    },
    // {
    //   accessorKey: "assignedDateTime",
    //   header: "Assigned DateTime",
    //   accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
    // },
    {
      accessorKey: "assignedToName",
      header: "Assigned To",
      size: 20,
    },
    {
      accessorKey: "status",
      header: "Review OutCome",
      size: 20,
      Cell: ({ row }) => {
        const status = row.original.status;
        return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
      },
    },
    {
      accessorKey: "reviewDateTime",
      header: "Review DateTime",

      accessorFn: (row) => moment(row.auditDateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
    },
    // {
    //   accessorKey: "comments",
    //   header: "Review Comments",
    //   size: 20,
    //   accessorFn: (row) => {
    //     const comments = row && row.comments;
    //     if (comments && comments.length > 28) {
    //       return comments.slice(0, 28) + "...";
    //     }
    //     return comments;
    //   }
    // },
  ]);
  const [isSelectChanged, setIsSelectChanged] = useState(true);

  const handleSelectChange = (event) => {
    setIsSelectChanged(false);
    console.log("Selected value:", event.target.value);
    setStatus(event.target.value);

    console.log(event.target.Value);
    console.log("statusa", status);
  };
 
  const onSubmit = (data) => {

      const totalFileSize = file.reduce((acc, file) => acc + file.size, 0);
      const maxSizeInMB = 10;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
      if (totalFileSize > maxSizeInBytes) {
        // setFileError(`Total file size should not exceed ${maxSizeInMB}MB.`);
        return;
      }
      // Check file types
      const allowedFileTypes = ["image/jpg", "image/png", "image/jpeg", "application/pdf"];
      const invalidFiles = file.filter(file => !allowedFileTypes.includes(file.type));
    
      if (invalidFiles.length > 0) {
        // setFileError("Some files have invalid file types. Allowed types are JPG, PNG, JPEG, PDF.");
        return;
    }

    if (typeof review !== 'string' || review.trim() === '') {
      setError("reviewComment", {
        type: "manual",
        message: REVIEW_COMMENTS_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    } 
    

    setIsLoading(true)
    console.log(data.selectedBylaw);
    const formData = new FormData();
    formData.append("status", status);
    formData.append("comments", review);
    formData.append("role", roles[0]);
    file.forEach((file) => {
      formData.append('files', file);
      });
    formData.append("bylawId", formByLaw);

    const storedToken = token;
    const config = {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      },
    };

    request({
      url: `${COMPLIANCE_REVIEW}/${complainceId}`,
      method: "put",
      data: formData,
      headers: config.headers,
    })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          getComplianceRequestDetailsById();

          setIsLoading(false);
          setReview("");
          setFile([]);
          setTimeout(() => {}, 1000);
          // navigate('/raisedbyarc');
        } else {
          throw new Error("Failed to fetch data");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    if(commentsId){
    getGeneralCommentsById();}
  }, [commentsId]);

  useEffect(()=>{
    if (generalCommentsData.trim().length > 1000) {
      setErrorGeneral("editComments", {
          type: "manual",
          message: MAX_LENGTH_THOUSAND,
      });
      setIsLoading(false);
      return;
  }
  },[generalCommentsData])

  const submitUpdate = (data) => {
    setIsLoading(true);
    
    const putData = {
      comments: generalCommentsData.trim(),
      referenceId: commentsId,
    };

    if (generalCommentsData == "" || generalCommentsData == null) {
      setErrorGeneral("editComments", {
        type: "manual",
        message: COMMENTS_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (generalCommentsData.trim().length < 2) {
      setErrorGeneral("editComments", {
        type: "manual",
        message: MIN_LENGTH_VALIDATE_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (generalCommentsData.trim().length > 1000) {
      setErrorGeneral("editComments", {
        type: "manual",
        message: MAX_LENGTH_THOUSAND,
      });
      setIsLoading(false);
      return;
    }
    request({
      url: `${GENERAL_COMMENTS}/${commentsId}`,
      method: "put",
      data: putData,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");
          setIsLoading(false);
          getGeneralComments();
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const handleEditClick = (index, generalCommentsId) => {
    console.log("Edit button clicked", index, generalCommentsId);
    setEditModes((prevModes) =>
      prevModes.map((mode, i) => (i === index ? true : mode))
    );
    setCommentsId(generalCommentsId);
    console.log("CommentsId set:", generalCommentsId);
    getGeneralCommentsById();
  };

  const handleInputChange = (e) => {
    setGeneralCommentsData(e.target.value);
  };

  const handleCancelClick = () => {
    clearErrorsGeneral("editComments");
    getGeneralComments();
    setEditModes(false);
    setValue('editComments',"")
  };
  const handleInputChangeAdd = (e) => {
    setGeneralCommentsDataAdd(e.target.value);
  };
  const getGeneralComments = async () => {
    try {
     
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${GET_ALL_GENERAL_COMMENTS}/${complainceId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setGeneralCommentDetails(data);
        setEditModes(Array(data.length).fill(false));
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getGeneralComments();
  }, []);
  const onSubmitComments = (data,e) => {
   


    if (typeof generalCommentsDataAdd !== 'string' || generalCommentsDataAdd.trim() === '') {
      setErrorGeneral("comments", {
        type: "manual",
        message: COMMENTS_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const postData = {
      comments: generalCommentsDataAdd.trim(),
      referenceId: complainceId,
    };
    request({ url: GENERAL_COMMENTS, method: "post", data: postData })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          getGeneralComments();
          console.log("Success");
          setIsLoading(false);
          setGeneralCommentsDataAdd("")
          // e.target.reset();
          // setTimeout(() => {
          //   navigate(-1);
          // }, 1000);
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const getGeneralCommentsById = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${GENERAL_COMMENTS}/${commentsId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        console.log(response.data);
        setGeneralCommentsData(data.comments);
        setStatus(data.status);

        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleDeleteClickOpen = (generalCommentsId) => {
    setDeleteOpen(true);

    setCommentsId(generalCommentsId);
    getGeneralCommentsById();
  };
  const onSubmitDelete = () => {
    request({ url: `${GENERAL_COMMENTS}/${commentsId}`, method: "delete" })
      .then((res) => {
        console.log(res);
        getGeneralComments();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const okCancelButtonActions = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleDeleteClose}
      />
      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleDeleteClose}
      />
    </>
  );

  const deleteModel = (
    <form
      onSubmit={handleSubmitGeneral(onSubmitDelete)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography> Are you sure you want to delete comment?</Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );

  return (
    <div>
      <CustomToastContainer />
      {isLoading && <CustomLoading />}
      {/* <CustomViewPage data={data} /> */}
      <CustomViewHeading onClick={handleClick}  title={"Compliance Request"} data={data} fileList={fileList}
       description={(complianceRequestData?.description || "")}>
      <CustomViewWithMore data={data}  />
      <br/>
     
      <CustomReviewTable columns={viewDataTable} data={reviewComments} actions={false}/>
      
      {selectedStatus !== "CLOSED" && (
        <Card>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="space-y-4 md:space-y-6 "
          >
            <CardContent>
              <section class="grid md:grid-cols-2 xl:grid-cols-2 gap-5">
                <Controller
                  name="status"
                  control={control}
                  rules={{ required: STATUS_REQUIRED_MESSAGE }}
                  render={({ field, fieldState }) => (
                    <FormControl
                      variant="standard"
                      fullWidth
                      error={fieldState.invalid}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Please Select Status
                        <span style={{ color: "red" }}> *</span>
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={status}
                        // onChange={(e) => {
                        // // setStatus(e.target.value);
                        //   field.onChange(e.target.value);
                        //                         }}
                        onChange={handleSelectChange}
                        label="Please  Select Status"
                      >
                        <MenuItem
                          value="PENDING"
                          disabled={
                            status === "PENDING" ||
                            status === "RESOLVED" ||
                            status === "ACKNOWLEDGED" ||
                            status === "NOT RESOLVED"
                          }
                        >
                          Pending
                        </MenuItem>
                        <MenuItem value="ACKNOWLEDGED">Acknowledged</MenuItem>
                        {/* <MenuItem value="CLOSED" >Closed</MenuItem> */}

                        <MenuItem value="RESOLVED">Resolved</MenuItem>

                        <MenuItem
                          value="NOT RESOLVED"
                          disabled={
                            status === "PENDING" ||
                            status === "NOT RESOLVED" ||
                            status === "ACKNOWLEDGED"
                          }
                        >
                          Not Resolved
                        </MenuItem>
                      </Select>
                      <FormHelperText style={{ color: "#D32F2F" }}>
                        {fieldState.invalid ? fieldState.error?.message : ""}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
                {/* {status !== "PENDING" && ( */}
                  <CustomInputField
                    fieldName="reviewComment"
                    // requiredErrorMessage={REVIEW_COMMENTS_REQUIRED_MESSAGE}
                    minLength={2}
                    minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                    maxLength={500}
                    maxLengthErrorMessage={MAX_LENGTH_FIVE_HUNDRED}
                    fieldId="reviewComment"
                    fieldType="text"
                    fieldLabel="Review Comment"
                    fieldControl={control}
                    fieldError={errors}
                    fieldRequiredIcon="*"
                    multiline={true}
                    numberOfRows={1}
                    value={review}
                    onInput={handleReviewChange}
                  />
                {/* // )} */}
                {/*<FormLabel>
                  Attach Documents
                   <FileUploader
                    handleChange={handleFileChange}
                    name="file"
                    types={fileTypes}
                  />
                  {file ? (
                    <p>Selected File: {file.name}</p>
                  ) : (
                    <p>No file selected</p>
                  )}
          {fileError && <p className="text-red-600 text-sm">{fileError}</p>}

                </FormLabel> */}
                {/* <CustomFile file={file} handleFileChange={handleFileChange} token={token} tooltipMessage={"Upload supporting documents such as lease agreement, identity proof, etc."} /> */}
                <CustomFile 
        handleFileChange={handleFileChange}
        token={token}
        fileError={fileError}
        file={file}
      />
              </section>
              <div class="flex justify-center items-center mt-10 space-x-5">
                <CustomButton
                  fieldType="submit"
                  buttonName="Update"
                  // disabled={status === "PENDING"}
                />
              </div>
            </CardContent>
          </form>
        </Card>
      )}
      <br />
      <CustomHeading title="General Comments" />
      <CustomModel
        title=" Delete Comments"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      />
      <Card>
        <form
          onSubmit={handleSubmitGeneral(onSubmitComments)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
          <div className="flex items-center space-x-4">
            <CustomInputField
              fieldName="comments"
              // requiredErrorMessage={COMMENTS_REQUIRED_MESSAGE}
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldId="comments"
              fieldType="text"
              fieldLabel="Write a Comments"
              fieldControl={controlGeneral}
              fieldError={errorsGeneral}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
              value={generalCommentsDataAdd}
              onInput={handleInputChangeAdd}
            />

            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Post" />
              {/* {isLoading && <CustomLoading />} */}
            </div>
            </div>
          </CardContent>
        </form>
        <CardContent>
          {generalCommentDetails &&
            generalCommentDetails.map((comment, index) => (
              <div key={comment.generalCommentsId}>
                <section className="grid md:grid-cols-1 xl:grid-cols-1 gap-3 ml-16">
                  <p>
                    <span className="text-primary " style={{ float: "left" }}>
                      @{comment.createdByName}{" "}
                    </span>
                    <span style={{ float: "right" }}>
                     
                      {!editModes[index] && comment.createdById === HomeLoginId && (
                        <>
                          {index === 0 && (
                            <>
                              {" "}
                              {/* Add a space here */}
                              <EditNoteOutlined
                                color="primary"
                                onClick={() => {
                                  handleEditClick(
                                    index,
                                    comment.generalCommentsId
                                  );
                                  console.log(comment.generalCommentsId);
                                }}
                              />{" "}
                              {/* Add a space here */}
                              <DeleteOutline
                                color="error"
                                onClick={() => {
                                  handleDeleteClickOpen(
                                    comment.generalCommentsId
                                  );
                                  console.log(comment.generalCommentsId);
                                }}
                              />
                            </>
                          )}
                        </>
                      )}
                       {moment(comment.createdDateAndTime).format(
                        "MM/DD/YYYY HH:mm:ss"
                      )}
                    </span>

                    {editModes[index] ? (
                      <form
                        onSubmit={handleSubmitGeneral(submitUpdate)}
                        className="space-y-4 md:space-y-6 "
                      >
                        <CardContent>
                          <CustomInputField
                            fieldName="editComments"
                          
                            fieldId="editComments"
                            fieldType="text"
                            // fieldLabel="Write a Comments"
                            fieldControl={controlGeneral}
                            fieldError={errorsGeneral}
                            // fieldRequiredIcon="*"
                            multiline={true}
                            numberOfRows={2}
                            value={generalCommentsData}
                            onInput={handleInputChange}
                          />

<div className="flex justify-center mt-8 space-x-1">
  <CustomButton
    fieldType="submit"
    buttonName="Save"
  />
  <CustomButton
    fieldType="button"
    buttonName="Cancel"
    click={handleCancelClick}
  />
  {isLoading && <CustomLoading />}
</div>
                        </CardContent>
                      </form>
                    ) : (
                      <p className="mt-8 mb-3 break-words">
                        {comment.comments}
                      </p>
                    )}
                  </p>
                </section>

                <Divider />
              </div>
            ))}
        </CardContent>
      </Card>
      </CustomViewHeading>
    </div>
  );
}

export default RaisedByArcView;

