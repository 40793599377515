import React from "react";
import { Doughnut } from "react-chartjs-2";
import { IconButton, Tooltip } from "@mui/material";
import { HelpOutline } from "@mui/icons-material";

const ChartLayout = (props) => {
  const chartData = props.chartData;
  const chartOptions = props.chartOptions;

  const getLegendPosition = () => {
    // Determine screen size and set legend position accordingly
    const screenWidth = window.innerWidth;
    if (screenWidth < 768) {
      return "bottom";
    } else {
      return "left"; // Or any other default position for larger screens
    }
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-3">
      {chartData.map((item) => (
        <div key={item.title} className="w-full bg-white rounded-lg shadow-lg p-4 md:p-6">
          <div className="flex justify-between items-center mb-3">
            <div className="flex items-center">
              <h5 className="text-sm font-regular leading-none text-gray-900 dark:text-white pe-1">
                {item.title}
              </h5>
              <Tooltip title={item.tooltip} placement="right">
                <IconButton>
                  <HelpOutline />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <div className="bg-gray-50 p-3 rounded-lg">
            <div
              className="grid gap-1"
              style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))' }}
            >
              {item.card.map((i, index) => (
                <dl
                  key={index}
                  className="rounded-lg flex flex-col items-center justify-center h-[78px] mb-1"
                >
                  <dt
                    className={`w-8 h-8 rounded-full bg-${i.color}-100 text-${i.color}-600 dark:text-${i.color}-300 text-sm font-medium flex items-center justify-center mb-1`}
                  >
                    {i.count}
                  </dt>
                  <dd
                    className={`text-${i.color}-600 dark:text-${i.color}-300 text-xs font-medium text-center mt-0`}
                  >
                    <span className="inline-block overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[100%]">
                      {i.title}
                    </span>
                  </dd>
                </dl>
              ))}
            </div>
          </div>

          <div className="flex justify-center mt-4">
            <div style={{ position: 'relative', width: '100%', height: '300px' }}>
              <Doughnut
                data={item.data}
                options={{
                  ...chartOptions,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: getLegendPosition(), // Dynamically determine legend position
                      align: 'center',
                      labels: {
                        usePointStyle: false,
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChartLayout;
