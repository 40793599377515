import React, { useState } from 'react'
import CustomHeading from '../../components/CustomHeading'
import CustomToastContainer from '../../components/CustomToastContainer'
import CustomButton from '../../components/CustomButton'
import { useNavigate } from 'react-router-dom';
import {
    Autocomplete,
    Card,
    CardContent,
    FormControl,
    FormLabel,
    TextField,
    Typography,
  } from "@mui/material";
import { request } from '../../services/AxiosConfig';
import { DISCUSSION } from '../../utills/ApplicationRouting';
import CustomTooltipField from '../../components/CustomTooltipField';
import { DESCRIPTION_REQUIRED_MESSAGE, MAX_LENGTH_THOUSAND, MAX_LENGTH_TWO_FIFTY, MIN_LENGTH_VALIDATE_MESSAGE, TOPIC_REQUIRED_MESSAGE } from '../../utills/ApplicationConstants';
import { useForm } from 'react-hook-form';
import CustomLoading from '../../components/CustomLoading';
import { useSelector } from 'react-redux';
function DiscussionBoardAdd() {
    const navigate = useNavigate();
    const { token, homeId,roles } = useSelector((state) => state.hybridhoa);

    const {
        handleSubmit,
        control,
        reset,
        setError,
        formState: { errors },
      } = useForm();
      const[isLoading,setIsLoading]=useState(false)

  const handleViewClick = () => {
    navigate(-1);
  };
  const onSubmit = (data) => {
    setIsLoading(true);
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const topic = data.topic ? data.topic.trim() : '';
    const description = data.description ? data.description.trim() : '';
    const postData = {
      topic: topic,
      description: description,
      role: roles[0],
    };

    if (!postData.topic) {
      setError("topic", {
        type: "manual",
        message: TOPIC_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (!postData.description) {
      setError("description", {
        type: "manual",
        message: DESCRIPTION_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    request({ url: DISCUSSION, method: "post", data: postData, headers })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");
        //   getDisussionDetails();
        setIsLoading(false);
        setTimeout(()=>{
            navigate('/discussionboard')
         },[3000])
      
       
        }
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  }
  return (
    <div>
      <div class="flex flex-col md:flex-row">
      {isLoading && <CustomLoading />}
                 
        <CustomHeading title="Discussion Board" />
        <CustomToastContainer />
        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleViewClick}
          />
        </div>
        </div>
        <Card>
        <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-4 md:space-y-6 "
      >
        <CardContent>
        <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">

            <CustomTooltipField
              fieldName="topic"
              // requiredErrorMessage={TOPIC_REQUIRED_MESSAGE}
              // fieldPattern={ONLY_ALPHABETIC_WITH_WHITE_SPACE}
              // patternErrorMessage={ALPHABETS_ARE_ALLOWED}
              fieldId="topic"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={250}
              maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
              fieldLabel="Topic"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
              tooltipMessage={"Topic for the discussionBoard"}

            />

            <CustomTooltipField
              fieldName="description"
              // requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
              fieldId="description"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldLabel="Description"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
              tooltipMessage={"Description for the discussionBoard"}

            />
</section>
<div class="text-center mt-10">
<CustomButton fieldType="submit" buttonName="Update" />
</div>
</CardContent>
          
      </form>
        </Card>
      
    </div>
  )
}

export default DiscussionBoardAdd
