import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomViewPage from "../../../components/CustomViewPage";
import CustomHeading from "../../../components/CustomHeading";
import {
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";
import CustomInputField from "../../../components/CustomInputField";
import {
  COMMENTS_REQUIRED_MESSAGE,
  REVIEW_COMMENTS_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_THOUSAND,
  MAX_LENGTH_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
} from "../../../utills/ApplicationConstants";
import CustomButton from "../../../components/CustomButton";
import { useForm } from "react-hook-form";
import CustomMaterialTable from "../../../components/CustomMaterialTable";
import CustomModel from "../../../components/CustomModel";
import { useSelector } from "react-redux";
import {
  BASE_URL,
  NOTIFICATION,
  NOTIFICATION_REVIEW_UPDATE,
} from "../../../utills/ApplicationRouting";
import axios from "axios";
import moment from "moment";
import { request } from "../../../services/AxiosConfig";
import CustomToastContainer from "../../../components/CustomToastContainer";
import CustomLoading from "../../../components/CustomLoading";
import CustomPascalChange from "../../../components/CustomPascalChange";
import CustomReviewTable from "../../../components/CustomReviewTable";
import CustomViewWithTooltip from "../../../components/CustomViewWithTooltip";
import CustomViewWithMore from "../../../components/CustomViewWithMore";
import CustomReviewTableWithoutActions from "../../../components/CustomReviewTableWithoutActions";
import CustomViewHeading from "../../../components/CustomViewHeading";

function McArcNotificationView() {
  const { token, roles, userLoginDetails,rowData,userRoles } = useSelector(
    (state) => state.hybridhoa
  );
  const [approveOpen, setApproveOpen] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);
  const [abstainOpen, setAbstainOpen] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { notificationId } = useParams();
  const [viewData, setViewData] = useState([]);
  const [firstNameData, setFirstNameData] = useState("");
  const [lastNameData, setLastNameData] = useState("");
  const [createdFor, setCreatedFor] = useState("");
  const [hide, setHide] = useState(false);
  const [status, setStatus] = useState("");
  const [general,setGeneral] = useState("");
  const [statuss,setStatuss]=useState("")
  
  const [createdByDetails,setCreatedByDetails]=useState("");
  const[homeId,setHomeId]=useState("")
  const[createdBy,setCretaedBy]=useState("")
  const [showFullDescription, setShowFullDescription] = useState(false); 
  const [showFullSubject, setShowFullSubject] = useState(false); 
  const [viewGeneral,setViewGeneral]=useState(false )
  const [hideTable,setHideTable]=useState(false)
  const isAnotherUserLoggedIn = userLoginDetails && userLoginDetails !== "";
  console.log(roles);
  const descriptionPattern = /^[a-zA-Z0-9\s.,!?]*$/;


  useEffect(() => {
   console.log("roles",roles);
   console.log("status",notificationData.status);
   console.log("user",userLoginDetails.homeOwnerLoginId);
   setHomeId(userLoginDetails.homeOwnerLoginId);
  }, [roles, notificationData, rowData, userLoginDetails]);
  const handleClick = () => {
    navigate(-1);
  };

  const handleAbstainClickOpen = () => {
    setAbstainOpen(true);
};
const handleAbstainClose = (status) => {
  if (status === 200) {
      setAbstainOpen(false);
  }
};
const handleAbstainIconClose = () => {
  setAbstainOpen(false);
};
const onSubmitAbstain = (data) => {
  setIsLoading(true);
  const abstainData = {
      status: "Abstained",
      comments: data.abstainReviewComments || "I am okay if everyone is okay",
  };
  request({
      url: `${NOTIFICATION_REVIEW_UPDATE}/${notificationId}`,
      method: "put",
      data: abstainData,
  })
  .then((res) => {
      console.log(res);
      if (res.status === 200) {
          handleAbstainClose(res.status);
          getNotificationDetailsById();
          console.log("Success");
          setIsLoading(false);
      }
  })
  .catch((error) => {
      console.log(error.response);
      if (error.response) {
          console.log("error.response.status" + error.response.status);
      }
  });
};

  const handleApproveClickOpen = () => {
    setApproveOpen(true);
  };
  
  const handleApproveClose = (status) => {
    if (status == 200) {
      setApproveOpen(false);
    }
  };
  const handleApproveIconClose = () => {
    setApproveOpen(false);
  };

  const handleRejectClickOpen = () => {
    setRejectOpen(true);
  };

  const handleRejectClose = (status) => {
    if (status == 200) {
      setRejectOpen(false);
    }
  };

  const handleRejectIconClose = () => {
    setRejectOpen(false);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);
    const postData = {
      status: "Approved",
      comments: data.approveReviewComments,
    };
    request({
      url: `${NOTIFICATION_REVIEW_UPDATE}/${notificationId}`,
      method: "put",
      data: postData,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          handleApproveClose(res.status);
          getNotificationDetailsById();
          console.log("Success");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const onSubmitReject = (data) => {
    setIsLoading(true);
    const rejectData = {
      status: "Rejected",
      comments: data.rejectReviewComments,
    };
    request({
      url: `${NOTIFICATION_REVIEW_UPDATE}/${notificationId}`,
      method: "put",
      data: rejectData,
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          handleRejectClose(res.status);
          getNotificationDetailsById();
          console.log("Success");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const getNotificationDetailsById = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${NOTIFICATION}/${notificationId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
      
        const data = response.data;
        console.log("general",data.isGeneral);
        const generaldata = data.isGeneral
        setGeneral(generaldata);
        setViewGeneral(data.isGeneral)
        setCretaedBy(data.createdBy)
        const createdDetails = data.createdByDetails;

        const createdForValues = data.reviewsComments.map(
          (comment) =>
            comment.createdFor === userLoginDetails.homeOwnerLoginId &&
            comment.status === "Pending"
        );
        const anyTrue = createdForValues.includes(true);
        console.log("anyTrue", anyTrue);
        setHide(anyTrue); // Set hide based on the condition
        setStatuss(data.status)
        console.log(data.status);
        setFirstNameData(createdDetails.firstName);
        setLastNameData(createdDetails.lastName);
        setNotificationData(data);
        setCreatedByDetails(data.createdByDetails);
        setViewData(data.reviewsComments);
        console.log(data.reviewsComments.map((comment) => comment.createdFor));
        console.log(data.reviewsComments.map((comment) => comment.status));
        //  setCreatedFor(data.reviewsComments.map(comment => comment.createdFor));
        const reviewsComments = data.reviewsComments;

        // Iterate over each review/comment in the array
        reviewsComments.forEach((comment) => {
          const createdFor = comment.createdFor;
          // Do something with the createdFor value, for example, log it
          console.log("Created For:", createdFor);

          // If you want to update the state with the createdFor value, you can do something like:
          setCreatedFor(createdFor);
        });
        //  setStatus(data.reviewsComments.map(comment => comment.status));
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getNotificationDetailsById();
  }, [notificationId]);

  useEffect(() => {
    console.log("hide", hide);
    console.log("status", status);
  }, [hide, status]);

  // const fullName = firstNameData + " " + lastNameData;

  // const data = {
  //   pageTitle: "Notification",
  //   buttons: [
  //     {
  //       buttonTitle: "Back",
  //       onClick: handleClick,
  //     },
  //   ],
  //   content: [
  //     {
  //       topic: "Subject",
  //       description: notificationData.subject,
  //     },
  //     {
  //       topic: "Created By",
  //       description: fullName,
  //     },
  //     {
  //       topic: "Created Date",
  //       description: moment(notificationData.createdDateAndTime).format(
  //         "MM/DD/YYYY"
  //       ),
  //     },
  //     {
  //       topic: "Description",
  //       description: notificationData.description,
  //     },
  //     {
  //       topic: "Status",
  //       description: CustomPascalChange(statuss),
  //     },
  //     {
  //       topic: "Publishdate",
  //       description: notificationData.publishDate
  //     },
  //     {
  //       topic: "Notification Type",
  //       description: notificationData.isGeneral ?  "General Notification - This will be Publish General Notification to Homeowners" : "Need 51% Approval from Board Member",
  //     },
  //   ],
  // };
 
  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const handleToggleSubject = () => {
    setShowFullSubject(!showFullSubject);
  };
  const renderDescription = () => {
    console.log("inside render ");
    // Remove HTML tags and replace &nbsp; with spaces
    const cleanDescription = notificationData.description
      ? notificationData.description.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ')
      : '';
  
    if (cleanDescription && !showFullDescription) {
      console.log("inside render ", cleanDescription);
  
      return (
        <>
          {cleanDescription.slice(0, 250)}
          {cleanDescription.length > 250 && (
            <Button onClick={handleToggleDescription} style={{ fontSize: "12px" }}>Read More</Button>
          )}
        </>
      );
    } else {
      console.log("inside render else ", cleanDescription);
  
      return (
        <>
          {cleanDescription}
          <Button onClick={handleToggleDescription} style={{ fontSize: "12px" }}>Less</Button>
        </>
      );
    }
  };
  const renderSubject = () => {
    console.log("inside render ");
    if (notificationData.subject && !showFullSubject) {
    console.log("inside render ",notificationData.subject);
  
      return (
        <>
        
          {notificationData.subject.slice(0, 100)}
          {notificationData.subject.length > 100 && (
            <Button onClick={handleToggleSubject} style={{fontSize:"12px"}}>Read More</Button>
          )}
        </>
      );
    } else {
    console.log("inside render else ",notificationData.subject);
  
      return (
        <>
          {notificationData.subject}
          <Button onClick={handleToggleSubject} style={{fontSize:"12px"}}>Less</Button>
        </>
      );
    }
  };
  
  const data = [
    {
      groupName: 'Notification Details',
      items: [

        { label: 'Subject', data: `${notificationData?.subject || ""}`, text: 'The subject of the notification.' },
        { label: 'Description', data: renderDescription(), text: 'A brief description of the notification.' },
        { label: 'Notification Type', data: notificationData.isGeneral ? "General Notification - This will be published to all homeowners." : "Need 51% approval from Board Members.", text: 'Type of notification and its requirement.' },
        { label: 'Created Date', data: `${moment(notificationData.createdDateAndTime).format("MM/DD/YYYY")}`, text: 'The date when the notification was created.' },
        { label: 'Status', data: `${CustomPascalChange(statuss)}`, text: 'Current status of the notification.' },
        { label: 'Publish Date', data: `${moment(notificationData.publishDate).format("MM/DD/YYYY")}`, text: 'The date when the notification will be published.' },
    ],
    },
    {
      groupName: ' By Details',
      items: [
        { label: 'Firstname', data: `${createdByDetails?.firstName ?? ""}`, text: 'The first name of the person who created the notification.' },
        { label: 'Lastname', data: `${createdByDetails?.lastName ?? ""}`, text: 'The last name of the person who created the notification.' },
        { label: 'Email', data: `${createdByDetails?.email ?? ""}`, text: 'The email address of the person who created the notification.' },
        { label: 'Address', data: `${notificationData?.homesDetails?.address || ""}`, text: 'The address associated with the notification.' },
        { label: 'Contact', data: `${createdByDetails?.contact ?? ""}`, text: 'The contact number of the person who created the notification.' },
        { label: 'Role', data: `${notificationData.role === 'ROLE_BM' ? "Board Member" : notificationData.role === 'ROLE_ARC' ? "ARC" : "Management Company"}`, text: 'The role of the person who created the notification.' },
      
          // { 
          //     label: 'Subject', 
          //     data: renderSubject(),
          //     text: 'The subject of the notification.'
          // },
          // { 
          //     label: 'Description', 
          //     data: renderDescription(),
          //     text: 'A brief description of the notification.'
          // },
          // { 
          //     label: 'Notification Type', 
          //     data: notificationData.isGeneral 
          //         ? "General Notification - This will be Publish General Notification to Homeowners" 
          //         : "Need 51% Approval from Board Member",
          //     text: 'Indicates whether the notification is general or requires board approval.'
          // },
          // { 
          //     label: 'Create Date', 
          //     data: `${moment(notificationData.createdDateAndTime).format("MM/DD/YYYY")}`,
          //     text: 'The date when the notification was created.'
          // },
          // { 
          //     label: 'Status', 
          //     data: `${CustomPascalChange(statuss)}`,
          //     text: 'The current status of the notification.'
          // },
          // { 
          //     label: 'Publish Date', 
          //     data: `${moment(notificationData.publishDate).format("MM/DD/YYYY")}`,
          //     text: 'The date when the notification is published.'
          // },
      ],
  },
  // {
  //     groupName: 'Created By Details',
  //     items: [
  //         { 
  //             label: 'Firstname', 
  //             data: `${createdByDetails.firstName}`,
  //             text: 'The first name of the person who created the notification.'
  //         },
  //         { 
  //             label: 'Lastname', 
  //             data: `${createdByDetails.lastName}`,
  //             text: 'The last name of the person who created the notification.'
  //         },
  //         { 
  //             label: 'Email', 
  //             data: `${createdByDetails.email}`,
  //             text: 'The email address of the person who created the notification.'
  //         },
  //         { 
  //             label: 'Address', 
  //             data:`${
  //             (notificationData.createdByDetails?.addressForCommunication?? "") ||
  //             (notificationData.homesDetails?.address?? "") ||
  //             " "
  //           }`,
  //             //  `${notificationData?.homesDetails?.address || ""}`,
  //             text: 'The address associated with the notification.'
  //         },
  //         { 
  //             label: 'Contact', 
  //             data: `${createdByDetails.contact}`,
  //             text: 'The contact number of the person who created the notification.'
  //         },

  //     ],
  // },
  ];

  const viewDataTable = useMemo((rowData) => [
    {
      accessorKey: "name",
      header: "Reviewed By",
      size: 20,
    },
    {
      accessorKey: "role",
      header: "Role",
      size: 20,
    },
    {
      accessorKey: "status",

      header: "Review Status",
      size: 20,
      Cell: ({ row }) => {
        const status = row.original.status;
        return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
      },
    },
    {
      accessorKey: "reviewDateTime",
      header: "Review DateTime",
      size: 20,
      accessorFn: (row) =>
        moment(row.auditDateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
      // Cell: ({ value }) => {
      //   const formattedDate = moment(value).format("MM/DD/YYYY");
      //   return formattedDate;
      // },
    },
    // {
    //   accessorKey: "comments",
    //   header: "Review Comments",
    //   size: 20,
    // },
  ]);

  const approveActionButton = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Approve"
        click={handleApproveClose}
        
      ></CustomButton>
      {isLoading && <CustomLoading />}
    </>
  );
  const abstainActionButton = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Abstain"
        click={handleAbstainClose}
      ></CustomButton>
      {isLoading && <CustomLoading />}
    </>
  );
  const rowActions = ({ rowData })=>{
    const isPending = rowData.status === "PENDING";
    return (
    <div className="text-center flex space-x-3">
      {/* ' && notificationData.status == "PENDING"  */}
      {roles =='ROLE_BM'   && rowData.createdFor ==userLoginDetails.homeOwnerLoginId ? (
        <>
          <CustomButton
            fieldType="submit"
            buttonName="Approve"
            click={handleApproveClickOpen}
            disabled={(!isPending && notificationData.status === "PENDING PUBLISH") || notificationData.status === "REJECTED" || notificationData.status === "PUBLISHED" }
                    // disabled={notificationData.status === "PUBLISHED" || notificationData.status === "REJECTED" || notificationData.status === "PENDING PUBLISH"}
          tooltipText="Action prohibited: 51% criteria threshold met"
                        tooltipPlacement="top"
          />
          <CustomButton
            fieldType="submit"
            buttonName="Reject"
            click={handleRejectClickOpen}
            tooltipText="Action prohibited: 51% criteria threshold met"

            tooltipPlacement="right"
            // disabled={(!isPending && notificationData.status === "PUBLISHED") || notificationData.status === "REJECTED" || notificationData.status === "PENDING PUBLISH" }
            disabled={(!isPending && notificationData.status === "PENDING PUBLISH") || notificationData.status === "REJECTED" || notificationData.status === "PUBLISHED" }

            // disabled={notificationData.status === "PUBLISHED" || notificationData.status === "REJECTED" || notificationData.status === "PENDING PUBLISH"}
          
/>
          <CustomButton
    fieldType="submit"
    buttonName="Abstain"
    click={handleAbstainClickOpen}
    tooltipPlacement="right"
    tooltipText="Action prohibited: 51% criteria threshold met"
    
    // disabled={(!isPending && notificationData.status === "PUBLISHED") || notificationData.status === "REJECTED" || notificationData.status === "PENDING PUBLISH" }
    disabled={(!isPending && notificationData.status === "PENDING PUBLISH") || notificationData.status === "REJECTED" || notificationData.status === "PUBLISHED" }

    // disabled={notificationData.status === "PUBLISHED" || notificationData.status === "REJECTED" || notificationData.status === "PENDING PUBLISH"}
/>
        </>
       ): null} 
    </div>
    )
  };

  const approveModel = (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography gutterBottom>
            <CustomInputField
              fieldName="approveReviewComments"
              requiredErrorMessage={REVIEW_COMMENTS_REQUIRED_MESSAGE}
              fieldId="approveReviewComments"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldLabel="Review Comments"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
            />
          </Typography>
        </DialogContent>
        <DialogActions>{approveActionButton}</DialogActions>
      </form>
    </>
  );

  const rejectActionButton = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Reject"
        click={handleApproveClose}
      ></CustomButton>
      {isLoading && <CustomLoading />}
    </>
  );
  const rejectModel = (
    <>
      <form
        onSubmit={handleSubmit(onSubmitReject)}
        className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography gutterBottom>
            <CustomInputField
              fieldName="rejectReviewComments"
              requiredErrorMessage={REVIEW_COMMENTS_REQUIRED_MESSAGE}
              fieldId="reviewComments"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldLabel="Review Comments"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
            />
          </Typography>
        </DialogContent>
        <DialogActions>{rejectActionButton}</DialogActions>
      </form>
    </>
  );
  const abstainModel = (
    <>
      <form
        onSubmit={handleSubmit(onSubmitAbstain)}
        className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography gutterBottom>
            <CustomInputField
              fieldName="abstainReviewComments"
              requiredErrorMessage={REVIEW_COMMENTS_REQUIRED_MESSAGE}
              fieldId="abstainReviewComments"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldLabel="Review Comments"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
            />
          </Typography>
        </DialogContent>
        <DialogActions>{abstainActionButton}</DialogActions>
      </form>
    </>
);
// const publishedDate =moment(notificationData.publishDate);
// const currentDate= new Date()
const publishedDate = moment(notificationData.publishDate);

// Current date using Moment.js
const currentDate = moment();

// Format dates as dd/mm/yyyy
const formattedPublishedDate = publishedDate.format('DD/MM/YYYY');
const formattedCurrentDate = currentDate.format('DD/MM/YYYY');
console.log(`Published Date: ${formattedPublishedDate}`);
console.log(`Current Date: ${formattedCurrentDate}`);
const tableStatus =notificationData.status
useEffect(() => {

if (
  formattedPublishedDate < formattedCurrentDate && // Check if publishDate has passed
  tableStatus === 'PENDING'
) {
  setHideTable(true); // Hide the <h1> tag
} else {
  setHideTable(false); // Show the <h1> tag
}
}, [publishedDate, tableStatus]);
 // Example date, replace with actual published date
   // Current date
  // const status = 'PENDING'; // Example status, replace with actual status

 

  return (
    <div>
      {isLoading && <CustomLoading />}
      {/* <CustomViewPage data={data} /> */}
      <CustomViewHeading data={data} onClick={handleClick} 
       title={"Notification"} description={(notificationData?.description || "")}/>
      <CustomViewWithMore data={data} />

      {/* <CustomViewWithTooltip data={data} onClick={handleClick}  title={"Notification"} /> */}

      <CustomToastContainer />
      <br />
      <br />
      {/* {general ? " " :
      <CustomMaterialTable columns={viewDataTable} data={viewData} />
      } */}
      {/* {general ? " " : */}
      {/* {(!viewGeneral)}
      {( createdBy === homeId || ( roles.includes('ROLE_BM'))) && (
      <CustomReviewTable columns={viewDataTable} data={viewData}  rowActions={rowActions} />
      )} */}
       {viewGeneral || hideTable? (
        // Render a component or message when viewGeneral is true
        <div></div>
      ) :(
        (createdBy === homeId || (roles.includes('ROLE_BM') ) ) && (
          <CustomReviewTableWithoutActions columns={viewDataTable} data={viewData} rowActions={rowActions} />
        )
      )
      }
      {/* } */}

      <br />
      {/* {roles.includes('ROLE_BM') && notificationData.status != "PUBLISHED"  && !(status.includes("APPROVED"))  && notificationData.createdBy == userLoginDetails.homeOwnerLoginId? (
      
        <>
         { console.log(createdFor)}
         { console.log(notificationData.createdBy)}
         { console.log(userLoginDetails.homeOwnerLoginId)}
          <CustomButton
            fieldType="submit"
            buttonName="Approve"
            click={handleApproveClickOpen}
          />
          &nbsp;
          <CustomButton
            fieldType="submit"
            buttonName="Reject"
            click={handleRejectClickOpen}
          />
          <br />
          <br />
        </>
      ) : null} */}


{/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
  {hide && roles !== "ROLE_ARC" && status === "PENDING" && (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Approve"
        click={handleApproveClickOpen}
      /> &nbsp;
      <CustomButton
        fieldType="submit"
        buttonName="Reject"
        click={handleRejectClickOpen}
      />
      <br />
      <br />
    </>
  )}
</div> */}



      <CustomModel
        title="Approve Notification"
        submit={onSubmit}
        content={approveModel}
        action={approveActionButton}
        openStatus={approveOpen}
        closeStatus={handleApproveClose}
        iconCloseStatus={handleApproveIconClose}
        reset={reset}
      />

      <CustomModel
        title="Reject Notification"
        submit={onSubmitReject}
        content={rejectModel}
        action={rejectActionButton}
        openStatus={rejectOpen}
        closeStatus={handleRejectClose}
        iconCloseStatus={handleRejectIconClose}
        reset={reset}
      />
     <CustomModel
    title="Abstain Notification"
    submit={onSubmitAbstain}
    content={abstainModel}
    action={abstainActionButton}
    openStatus={abstainOpen}
    closeStatus={handleAbstainClose}
    iconCloseStatus={handleAbstainIconClose}
    reset={reset}
/>
    </div>
  );
}

export default McArcNotificationView;
