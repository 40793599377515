import React from 'react';
import { Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import moment from 'moment';

const styles = StyleSheet.create({
  page: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 35,
    backgroundColor: '#F6F8FA',
 },
  section: {
    margin: 8,
    padding: 5,
    flexGrow: 1,
    color: 'white'
  },
   header: {
    // fontSize: 12,
    marginBottom: 0,
    alignItems: 'center',
    justifyContent:'center',
    color: 'grey',
    width:"60%",
    height:"20%",
    marginLeft:"21%"
  },
  headerImage:{
    marginTop:"15%",
    paddingLeft:"70px",
    paddingRight:"50px",

    alignContent:"center",
    justifyContent:"center",
    width:"470px",
    height:"200px",
 
    },
    title:{
      textAlign:"center",
      fontSize:"18px",
      color: 'black',
      marginTop:"8%"
  }
//   header: {
//     color: 'blue',
//     fontSize: 32
//   },
});

const PDFDocumentList = ({ data,logo }) => (
  <Document>
    {data.map((details, index) => (
      <Page key={index} size="A4" style={styles.page}>
    {/* { data.logo ? ( */}
    {/* <Image src={logo} style={styles.headerImage} /> */}
    {/* {title ? (
  <Text style={styles.title}>{title}</Text>
) :  (
  <View style={styles.title} />
)} */}

 {logo ? (
          <Image src={logo} style={styles.headerImage} />
        ) : (
          <View style={styles.headerImage} />
        )}
    {/* ):(<Text style={styles.headerImage}/>)} */}
       
        <View style={styles.section}>
        {details.letterContent.map((item, index) => {
        switch (item.type) {
          case 'text':
            return <Text key={index} style={{ ...styles.text, ...item.style }}>{item.text}</Text>;
          case 'image':
            console.log("Image item:", item);
            // Ensure your image source (item.src) is a valid base64 string or a URL
            // return  <Image key={index} src={item.src} style={{ ...styles.image, ...item.style }} /> />; 
            return item.src && item.src !== '' ? (
              <Image key={index} src={item.src} style={{ ...styles.image, ...item.style }} />
            ) : null;
            default:
            return null;
        }
      })}
          {/* <Text>From, Group Admin</Text> */}
        </View>
      </Page>
    ))}
  </Document>
);

export default PDFDocumentList;
