import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useSelector } from "react-redux";
import CustomLoading from "../../components/CustomLoading";
import CardLayout from "./../../components/dashboard/CardLayout";
import logo from "./../../assets/img/logo.png";
import { HandymanOutlined, InsertDriveFileOutlined, NotificationsActiveOutlined } from "@mui/icons-material";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { BASE_URL, DASHBOARD, DASHBOARD_HO, CHART } from "../../utills/ApplicationRouting";
import CustomHeading from "./../../components/CustomHeading";
import DvrIcon from '@mui/icons-material/Dvr';
import ChartLayout from "./../../components/dashboard/ChartLayout";
import CardLayoutContact from "../../components/dashboard/CardLayoutContact";

function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [datas, setDatas] = useState({});
  const [chartData, setChartData] = useState({});
  const { token, roles, homeId } = useSelector((state) => state.hybridhoa);

 
    const getHoDasboardData = async () => {
      try {
        setIsLoading(true)
        const storedToken = token;

        // Fetch dashboard data
        const response1 = await axios.get(`${BASE_URL}${DASHBOARD}${DASHBOARD_HO}/${homeId}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        if (response1.status === 200) {
          setDatas(response1.data);
        } else {
          throw new Error("Failed to fetch dashboard data");
        }

        // Fetch chart data
        const response2 = await axios.get(`${BASE_URL}${DASHBOARD}${CHART}${DASHBOARD_HO}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        if (response2.status === 200) {
          setChartData(response2.data);
        } else {
          throw new Error("Failed to fetch chart data");
          setIsLoading(false);
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };


    useEffect(() => {
      getHoDasboardData();
  }, [token,homeId]);

  if (isLoading) {
    return <CustomLoading />;
  }

  const cardDatas = [
    {
      count: datas.documentCount,
      badgeContent: datas.documentUnread,
      name: "Documents",
      icon: <InsertDriveFileOutlined />,
      color: ["#fee2e2"],
      tooltip: "Click to view the list of Documents",
      linkTo:'/document',
    },
    {
      count: datas.maintenanceCount,
      badgeContent: datas.maintenanceUnread,
      name: "Maintenance Requests",
      icon: <HandymanOutlined />,
      color: ["#bbddff"],
      linkTo: "/maintenancerequest",
      tooltip: "Click to view the list of Maintenance Requests",
    },
    {
      count: datas.complainceCountByARC,
      badgeContent: datas.complainceUnread,
      name: "Compliance Requests By ARC",
      icon: <DvrIcon />,
      color: ["#c2f6c2"],
      linkTo: "/raisedbyarc",
      tooltip: "Click to view the list of Compliance Requests by ARC",
    },
    {
      count: datas.totalSummary,
      name: "Card Balance Summary",
      icon: <MonetizationOnIcon />,
      color: "#ffffcc",
    },
    {
      count: datas.notificationCount,
      badgeContent: datas.notificationUnread,
      name: "Notifications",
      icon: <NotificationsActiveOutlined />,
      color: "#ffd8eb",
      linkTo: "/notification",
      tooltip: "Click to view the list of Notifications",
    },
  ];

 

  return (
    <div>
      <CustomHeading title="Dashboard" />
      <br />
      <CardLayoutContact />
      <br />
      <CardLayout cardData={cardDatas} type="dashboardHO" />
      <br />
      <br />
      <div className="fixed bottom-0 right-0 m-4">
        <a href="https://hybridhoa.com" target="_blank" rel="noopener noreferrer">
          <img src={logo} width={100} height={100} alt="logo" />
        </a>
      </div>
    </div>
  );
}

export default Dashboard;