import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import { useState } from "react";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

function CustomDatePicker(props) {
  const [open, setOpen] = useState(false);
  const CustomInputLabel = ({ label, requiredIcon }) => (
    <label>
      {label}
      {requiredIcon && <span style={{ color: "red" }}> {requiredIcon} </span>}
    </label>
  );

  const formattedValue = props.value ? dayjs(props.value) : null;

  return (
    <div className="flex items-center md:w-1/2 w-full">
    <Controller
      control={props.control}
      name={props.fieldName}
      rules={{
        required: props.requiredErrorMessage, // Define validation rule (required)
        // Add more rules as needed
      }}
      render={({ field, fieldState }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            {...field}
            views={["year", "month", "day"]}
            minDate={props.minDate}
            maxDate={props.maxDate}
            sx={{width:"100%"}}
            disabled={props.disabled}
            value={formattedValue}
            onChange={(date) => {
              field.onChange(date);
              console.log("Selected Date:", date);
              props.onChange && props.onChange(date);
            }}
            slotProps={{
              textField: {
                variant: "standard",
                label: (
                  <CustomInputLabel
                    label={props.label}
                    requiredIcon={props.fieldRequiredIcon}
                  />
                ),
                error: fieldState.invalid, // Apply error state based on validation
                helperText: fieldState.invalid ? fieldState.error?.message : "",
                onClick: () => setOpen(true),
                readOnly: true,
              },
            }}
            open={open}
            onClose={() => setOpen(false)}
          />
          {props.tooltipMessage && (
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: "14px" }}>{props.tooltipMessage}</Typography>
                      }
                      placement="right"
                      arrow
                    >
                      <IconButton size="small">
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  )}
        </LocalizationProvider>
      )}
    />
    </div>
  );
}

export default CustomDatePicker;
