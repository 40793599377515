import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomViewPage from "../../components/CustomViewPage";
import {
  BASE_URL,
  DOCUMENT,
  HO_DOCUMENT,
} from "../../utills/ApplicationRouting";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import { FileDownloadOutlined } from "@mui/icons-material";
import CustomLoading from "../../components/CustomLoading";
import CustomViewHeading from "../../components/CustomViewHeading";
import CustomViewWithTooltip from "../../components/CustomViewWithTooltip";
import {Divider}from"@mui/material";
import CustomToastContainer from "../../components/CustomToastContainer";
import AttachmentListsView from "../../components/AttachmentListsView";
function DocumentView() {
  const navigate = useNavigate();
  const { documentId } = useParams();
  const [documentDetailsData, setDocumentDetailsData] = useState("");
  const { token, homeId } = useSelector((state) => state.hybridhoa);
  const [isLoading, setIsLoading] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fileUrl, setFileUrl] = useState([]);
  const [createdDateAndTime, setCreatedDateAndTime] = useState("");
  const handleClick = () => {
    navigate(-1);
  };

  const getDocumentDetailsById = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${DOCUMENT}/${documentId}?homeId=${homeId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        const createdByDetails = data.createdByDetails;
        setFirstName(createdByDetails.firstName);
        setLastName(createdByDetails.lastName);
        setDocumentDetailsData(data);
        setIsLoading(false);
        setFileUrl(data.attachments);      

        const formattedDate = moment(data.createdDateAndTime).format(
          "MM/DD/YYYY hh:mm A"
        );
        setCreatedDateAndTime(formattedDate);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDocumentDetailsById();
  }, []);

  const fullName = firstName + " " + lastName;
  const fileList = fileUrl.map(file => ({
    docname: file.file.substring(12), // Assuming file name is the document name
    fileUrl: file.file, // Assuming file location is the file URL
    documentnamed: file.file, // Assuming file name is the document name
    uploadedTimes: moment(file.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"), // Assuming uploadedTimes is available
    }));
  
  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '' || str === null) return '';
  
    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };
  
  const data = [
    {
      groupName:'Review Document',
      items : [
        {
          label: "Category",
          data: convertToPascalCase(documentDetailsData.category),
          // description :convertToPascalCase(documentDetailsData.category)
        },
        {
          label: "description",
          data: documentDetailsData.description,
        },
      {
        label: "Created By",
        data: fullName,
      },
      

      {
        label: "Created Date Time",
        data: moment(documentDetailsData.createdDateAndTime).format(
          "MM/DD/YYYY hh:mm:ss a"
        ),
      },
      // {
      //   label: "Audit Date Time",
      //   data: moment(documentDetailsData.auditDateAndTime).format(
      //     "MM/DD/YYYY hh:mm:ss a"
      //   ),
      // },
      {
        label: "Publish Date",
        data: documentDetailsData.publishDate,
      },
      // {
      //   label: "Status",
      //   // data: documentDetailsData.status,
      //   data :convertToPascalCase(documentDetailsData.status)
      // },
      // code commented by Hema shanker on 13/05/24
      // {
      //   label: "Is Hidden",
      //   data: documentDetailsData.isHidden ? "Yes" : "No",
      // },
      {
        label: "Attachments",
        data:(
          <AttachmentListsView fileList={fileList} />
        )},
      
    ]
  }
];
  return (
    <div>
      {isLoading && <CustomLoading />}
      <CustomViewHeading onClick={handleClick} title={"Document"} data={data} fileName={`Document_${documentDetailsData.documentId}.pdf`}  fileList={fileList} >
      {/* <CustomViewHeading onClick={handleClick} > */}
      <CustomViewWithTooltip data={data}  />
      <Divider />
      <CustomToastContainer />
      </CustomViewHeading>
    </div>
  );
}

export default DocumentView;
