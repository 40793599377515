import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {  FileUploadOutlined, ListAlt } from "@mui/icons-material";
import html2canvas from 'html2canvas';
import {
    Autocomplete,
    Button,
    Card,
    CardContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormHelperText,
    TextField,
    Typography,
  } from "@mui/material";
import CustomButton from '../../components/CustomButton';
import CustomInputField from '../../components/CustomInputField';
import CustomAutoComplete from '../../components/CustomAutoComplete';
import { HOME_ADDRESS_REQUIRED_MESSAGE, STATUS_REQUIRED_MESSAGE } from '../../utills/ApplicationConstants';
import CustomHeading from '../../components/CustomHeading';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Controller, useForm } from 'react-hook-form';
import { CustomStyle } from '../../components/CustomStyle';
import { BASE_URL, BASE_URL_IMAGE, GROUP_CONFIG, HOMES_DETAILS, HOME_REPORT } from '../../utills/ApplicationRouting';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import CustomMaterialTable from '../../components/CustomMaterialTable';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useNavigate } from 'react-router-dom';
import PDFDocumentList from '../../components/TemplateListpdf';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import CustomPhoneInput from '../../components/CustomPhoneInput';
import { pdf } from '@react-pdf/renderer';
import CustomLoading from '../../components/CustomLoading';
import jsPDF from 'jspdf';
// import CustomHomeActivityTable from '../../components/CustomHomeActivityTable';
import CustomMaterialWithoutActons from '../../components/CustomMaterialWithoutActons';

function ArcMcBmHomeActivitynew() {

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue, watch,
      } = useForm();
      const navigate = useNavigate();
  const { token } = useSelector((state) => state.hybridhoa);
  const [homeDetails,setHomeDetails]=useState('');
  const [homeEmail,setHomeEmail]=useState("");
  const [homeAdressDetail,setHomeAdressDetail]=useState("");
  const [homeAddresses, setHomeAddresses] = useState([]);
  const [homeOwnerDetailsId,setHomeOwnerDetailsId]= useState('');
  const [isLoading,setIsLoading]=useState(false)
  const [homeComplaince,setHomeComplaince]=useState([])
  const [homeMaintenance,setHomeMaintenence]=useState([])
  const [groupConfigurationDetails,setGroupConfigurationDetails]=useState([])
  const [logoUrl, setLogoUrl] = useState('');
  const [imageBlob,setImageBlob]=useState('');
  const[footerAdress,setFooterAdress]=useState('')
  const[groupAdress,setGroupAdress]=useState('')
  const [dueData,setDDueData]=useState([])
  const [duePaidData,setDuePaidData]=useState([])
  const [contact,setContact]=useState('')
const [firstName,setFirstName]=useState("");
const [lastName,setLastName]=useState("")
const [email,setEmail]=useState("")
const [hadress,sethadress]=useState("")
const [renterFirstName,setRenterFirstName]=useState("")
const [reneterLastName,setRenterLastName]=useState("")
const [renterContact,setRenterContact]=useState("")
const [renteremail,setRenterEmail]=useState("")
const [testImages,setTestImages]=useState('')
const [complainceImage,setComplainceImage]=useState("");
const [exportHoDetails,setExportHoDetails]=useState([])
  const handleCrViewClick=(selectedRow)=>{
    navigate(`/mcbmarchomeactivitycr/${selectedRow.row.original.complainceId}`)
  }
  const handleMrViewClick = (selectedRow) => {
    navigate(`/mcbmarchomeactivitymr/${selectedRow.row.original.maintenanceId}`);
  };
      const selectedStatus = [
   
        { label: "Pending", value: "PENDING" },
        { label: "Paid", value: "PAID" },
     
      ];
      const getHomes = async () => {
        try {
          const storedToken = token;
        
          const persistRoot = JSON.parse(localStorage.getItem("persist:root"));
    
    // Parse the Redux state to get the user details
    const userDetails = JSON.parse(persistRoot?.userDetails);
    
    // Extract the homeOwnerLoginId
    const storedHomeOwnerLoginId = userDetails?.homeOwnerLoginId;
    
    console.log("storedHomeOwnerLoginId", storedHomeOwnerLoginId);
          const response = await axios.get(`${BASE_URL}${HOMES_DETAILS}`, {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });
          console.log(response.data);
          
          const data = response.data;
          if (response.status === 200) {
              // Filter out entries with null values for specific fields
              const filteredData = data.map(home => {
                // Create a new object without null fields
                const filteredHome = {};
                for (const key in home) {
                    if (home.hasOwnProperty(key)) {
                        // Replace null values with empty strings
                        filteredHome[key] = home[key] !== null ? home[key] : "";
                    }
                }
                return filteredHome;
            });
            
            setHomeAddresses(filteredData);
          } else {
            setIsLoading(false);
            throw new Error('Failed to fetch data');
          }
      
        } catch (error) {
          // Handle error
          setIsLoading(false);
          console.error("Error fetching data:", error);
        }
      };
    
      useEffect(() => {
        getHomes();
      }, []);
      const handleHomeChange = (event, newValue) => {
        if (newValue && newValue.value !== undefined && newValue.value !== null) {
          const selectedHomeId = newValue.value;
          const selectedHome = homeAddresses.find((home) => home.homeId === selectedHomeId);
          // const homeOwner = selectedHome.homeOwnerDetails;
      console.log("beforeSelect",selectedHomeId);
          if (selectedHome) {
            // const {name } = selectedHome;
            // const {email } = selectedHome;
            console.log("Select",selectedHomeId);

            const {address,name,email } = selectedHome;
           
            const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";
            console.log("Selected Home Description:", selectedHomeDescription);
            const selectedHomeEmail = email !==null  && email !== undefined ? `${email}` : "";
            const selectedHomeAdress = address !== null && address !== undefined  ? `${address}` :""
            // Set the selected home details to the state

            setHomeDetails(selectedHomeDescription);
            setHomeEmail(selectedHomeEmail);
            // Use setValue to set the selected homeId in the react-hook-form
            setValue("selectedOption", selectedHomeId);
            setHomeOwnerDetailsId(selectedHomeId)
            
            // You can also do other operations if needed
            console.log("Selected Home:", selectedHome);
            console.log("Home Details:", homeAddresses);
            
          } else {
            setHomeDetails('');
            setValue("selectedOption", null);
          }
        } else {
          // Handle the case when the selected option is removed (e.g., by pressing backspace)
          setHomeDetails('');
      
          // Use setValue to clear the selected homeId in the react-hook-form
          setValue("selectedOption", null);
        }
      };
      
      useEffect(() => {
        const selectedHome = watch('selectedOption');
      
      
          if (!selectedHome || selectedHome === "") {
            setHomeDetails('');
            // setIsBylawFieldEmpty(true);
          } else {
            // Find the matching byLaw from byLawDetails
            const matchingHome = homeAddresses.find((home) => home.homeId === selectedHome);
      
            if (matchingHome) {
              const { name } = matchingHome;
              const selectedHomeDescription = name !== null && name !== undefined ? `${name}` : "";
    
      setHomeDetails(selectedHomeDescription);
              // setIsBylawFieldEmpty(false);
            } else {
              // Handle the case where no matching byLaw is found
              setHomeDetails('');
              // setIsBylawFieldEmpty(true);
            }
          }
        
      }, [watch('selectedOption'), homeDetails]);
      // const renderedAddresses = homeAddresses
      // .map((home, index) => ({
      //   address: home.address, 
      //   value: home.homeId,   
      //   key: index,
      // }));
      const renderedAddresses = homeAddresses.map((home, index) => ({
        address: `${home.address}, ${home.email}, ${home.name}`,
        value: home.homeId,
        key: index,
      }));
      // const renderedAddresses = homeAddresses.map((home, index) => {
      //   const parts = [
      //     home.name,
      //     home.email,
      //     home.address,

      //     // home.contact,
      //   ].filter(part => part); // Filter out any null or undefined parts
      
      //   const address = parts.join(', '); // Join the parts with a comma and a space
      
      //   return {
      //     address: address,
      //     value: home.homeOwnerDetailsId,
      //     key: index,
      //   };
      // });

      useEffect(()=>{
        console.log("homeownerid",homeOwnerDetailsId);
      },[homeOwnerDetailsId])

      const onSubmit = async(data) => {
   
        setIsLoading(true)
        const payload = {
          type:"ALL"
        };
        try {
          
          // setIsLoading(true)
          const response = await axios.post(`${BASE_URL}${HOME_REPORT}/${homeOwnerDetailsId.trim()}`,payload , {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          console.log(response);
          if (response.status == 200) {
            setIsLoading(false);
            
            let filteredData = [];
            // if (type === "ALL") {
              filteredData = response.data
            // } 
            // console.log("images",filteredData.data.complaince[0].fileUrl);

            console.log(filteredData);
            localStorage.setItem('homeActivity', JSON.stringify(filteredData));
            setHomeMaintenence(filteredData.maintenance ?? [])
            setExportHoDetails(filteredData.homeOwnerDetails )
            console.log("testeeeeeeeee");
            console.log(filteredData.complaince);
            setHomeComplaince(filteredData.complaince ?? [])
            setDDueData(filteredData.homeOwnerDues ?? [] )
            
            setDuePaidData(filteredData.homeOwnerPayments ?? [])
            console.log("testeeeeeeeee",filteredData.homeOwnerDues ?? []);
 
            setContact(filteredData.homeOwnerDetails?.contact??"")
            setFirstName(filteredData.homeOwnerDetails?.firstName??"")
            setLastName(filteredData.homeOwnerDetails?.lastName??"")
            setEmail(filteredData.homeOwnerDetails?.email??"")
            console.log("identity",data.renterList?.[0]?.firstName?? "");

            setRenterFirstName(filteredData.renterList?.[0]?.firstName?? "")
            setRenterLastName(filteredData.renterList?.[0]?.lastName?? "")
            setRenterContact(filteredData.renterList?.[0]?.contact?? "")
            setRenterEmail(filteredData.renterList?.[0]?.email?? "")
            // sethadress(filteredData.homeOwnerDetails?.addressForCommunication??"");
            sethadress(
              filteredData.homeOwnerDetails?.addressForCommunication ||
              filteredData.homeOwnerDetails?.address ||
              ""
            );
            setComplainceImage(filteredData.complaince[0].fileUrl)

            // setListData(filteredData);
            // setComplainceImage(response.data.complaince[2].fileUrl)
            //  console.log(filteredData.fileUrl,"img position");
            // console.log(filteredData[2].map(item => item.fileUrl),"testeeeeeee")
           
          } else {
            setIsLoading(false);
            throw new Error("Failed to fetch data");
          }
        } catch (error) {
          setIsLoading(false);
          console.log(error);
        }
      };
      // imagePosition
      // const getComplianceImages = async () => {
      //   try {
      //     const imageFetchPromises = homeComplaince.map(complianceImages =>
      //       axios.get(`${BASE_URL_IMAGE}/${complianceImages.fileUrl}`, {
      //         headers: {
      //           Authorization: `Bearer ${token}`,
      //         },
      //         responseType: "blob",
      //       }).then(response => URL.createObjectURL(response.data))
      //       .catch(error => {
      //         console.error("Error fetching image:", complianceImage, error);
      //         return ''; // Return an empty string or a default image URL as fallback
      //       })
      //     );
      //     const images = await Promise.all(imageFetchPromises);
      //     const filteredImages = images.filter(image => image !== '');
      //           setTestImages(filteredImages);
      //     console.log("testeeeeeee",filteredImages);
      //   } catch (error) {
      //     console.error("Error fetching images:", error);
      //   }
      // };
      const getGroupAttachments=async () => {
        try {
          const storedToken = token;
          const response = await axios.get(`${BASE_URL_IMAGE}/${complainceImage}`, {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
            responseType: "blob",
          });
          const blob = response.data; // Accessing the blob data from the response
          const fileURL = URL.createObjectURL(blob);
          setTestImages(fileURL);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      useEffect(()=>{
        getGroupAttachments();
      },[complainceImage])
      // 
      // useEffect(()=>{
      //   if (homeComplaince.length > 0) {
      //     getComplianceImages();
      //   }    },[homeComplaince])
      useEffect(() => {
        const storedData = localStorage.getItem('homeActivity');
        if (storedData) {
            const data = JSON.parse(storedData);
            setHomeMaintenence(data.maintenance ?? []);
            setHomeComplaince(data.complaince ?? []);
            setDDueData(data.homeOwnerDues ?? [])
            setDuePaidData(data.homeOwnerPayments ?? [])
            setFirstName(data.homeOwnerDetails?.firstName??"");
            setLastName(data.homeOwnerDetails?.lastName??"")
            setEmail(data.homeOwnerDetails?.email??"")
            setContact(data.homeOwnerDetails?.contact??"")
            sethadress(
              data.homeOwnerDetails?.addressForCommunication ||
              data.homeOwnerDetails?.address ||
              ""
            );   
console.log("identity",data.renterList?.[0]?.firstName?? "");
            setRenterFirstName(data.renterList?.[0]?.firstName?? "")
            setRenterLastName(data.renterList?.[0]?.lastName?? "")
            setRenterContact(data.renterList?.[0]?.contact?? "")
            setRenterEmail(data.renterList?.[0]?.email?? "")
            // if (data.renterList && data.renterList.length > 0) {
            //   setRenterFirstName(data.renterList[0]?.firstName ?? "");
            //   setRenterLastName(data.renterList[0]?.lastName ?? "");
            //   setRenterContact(data.renterList[0]?.contact ?? "");
            //   setRenterEmail(data.renterList[0]?.email ?? "");
            // } else {
            //   setRenterFirstName("");
            //   setRenterLastName("");
            //   setRenterContact("");
            //   setRenterEmail("");
            // }
                  }
    }, []);
    const getGroupConfigurationDetails = async () => {
        // setIsLoading(true);
        try {
          
          const storedToken = token;
      
          console.log(storedToken);
      
          const response = await axios.get(`${BASE_URL}${GROUP_CONFIG}`, {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });
          console.log(response);
      
          if (response.status === 200) {
            const data = response.data;
            // setIsLoading(false);
    
            setGroupConfigurationDetails(data);
            const logoConfig = data.find(config => config.topic === 'Logo');
            console.log(logoConfig);
            if (logoConfig) {
              setLogoUrl(logoConfig.content);
              console.log(logoConfig.content);
            }
            const footer = data.find(config => config.topic === 'Contact Details');
            console.log(logoConfig);
            if (footer) {
              setFooterAdress(footer.content);
              console.log(footer.content);
            }
            const groupField = data.find(config => config.topic ==='Group Name');
            console.log(logoConfig);
            if (groupField) {
              setGroupAdress(groupField.content);
              console.log(groupField.content);
            }
          } else {
            throw new Error("Failed to fetch data");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      useEffect(()=>{
         getGroupConfigurationDetails()
      },[])
      const getComplainceAttachments=async () => {
        try {
          const storedToken = token;
          const response = await axios.get(`${BASE_URL_IMAGE}/${logoUrl}`, {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
            responseType: "blob",
          });
          const blob = response.data; // Accessing the blob data from the response
          const fileURL = URL.createObjectURL(blob);
          setImageBlob(fileURL);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      useEffect(()=>{
        getComplainceAttachments()
      },[logoUrl])
      const convertToPascalCase = (str) => {
        // Check if the input is null, undefined, or not a string
        if (str === null || str === undefined || typeof str !== 'string' || str.trim() === '') {
          return '';
        }
      
        // Remove underscores and convert string to Pascal case
        return str
          .replace(/_/g, ' ')
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
          .replace(/\s+/g, ''); // Remove spaces to form PascalCase
      };
      const homeActivityReportComplainceTable = useMemo((rowData) => [
        {
          accessorKey: "crId",
          header: "#",
          size: 25,
          // Cell: ({ row }) => row.index + 1,
    
        },
        // {
        //   accessorKey: "createdByHomeAddress",
        //   header: "Address",
        //   accessorFn: (row) => `${row.createdByAddress? row.createdByAddress.address : ""} `,
        //   sortDescFirst: true,
        // },
        {
          accessorKey: "assignedToDetails",
          header: "Created To",
          accessorFn: (row) => `${row.createdToDetails? row.createdToDetails.firstName : ""} ${row.createdToDetails? row.createdToDetails.lastName : ""} `,
          sortDescFirst: true,
        },
        {
          accessorKey: "assignedToAddress",
          header: "Created To Address",
          accessorFn: (row) => row.address ? row.address.address || "" : "",          sortDescFirst: true,
        },
        {
            accessorKey:"description",
            header:"Description",
            accessorFn: (row) => {
              const description = row && row.description;
              if (description && description.length > 28) {
                return description.slice(0, 28) + "...";
              }
              return description;
            }
        },
        // {
        //   accessorKey: "type",
        //   header: "Type",
        //   Cell: ({ row }) => {
        //     const selectedTypeLabel = homeAddresses?.label || "All";
        //     const crId = row.original.crId || "";
        //     const mrId = row.original.mrId || "";
        
        //     return (
        //       <span>
        //         {selectedTypeLabel === "All"
        //           ? `${crId ? "Compliance Request" : ""} ${mrId ? "Maintenance Request" : ""}`.trim()
        //           : selectedTypeLabel === "Compliance Request"
        //           ? "Compliance Request"
        //           : selectedTypeLabel === "Maintenance Request"
        //           ? "Maintenance Request"
        //           : ""}
        //       </span>
        //     );
        //   },
        // },
        {
          accessorKey: "status",
          header: "Status",
          accessorFn: (row) => convertToPascalCase(row.status),
        },
    
        {
          accessorKey: "createdDateAndTime",
          header: "Created Date",
          size: 25,
       accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),
    },
       
      ]);
      const homeActivityReportMaintenanceTable = useMemo((rowData) => [
        {
          accessorKey: "mrId",
          header: "#",
          size: 25,
          // Cell: ({ row }) => row.index + 1,
    
        },
        {
          accessorKey: "createdByNa",
          header: "FirstName",
          accessorFn: (row) => `${row.createdByDetails? row.createdByDetails.firstName : ""} `,
          sortDescFirst: true,
        },
        {
          accessorKey: "createdByName",
          header: "LastName",
          accessorFn: (row) => ` ${row.createdByDetails? row.createdByDetails.lastName : ""} `,
          seortDescFirst: true,
        },
        {
          accessorKey: "creatdedByAddress",
          header: "Address",
          accessorFn: (row) => ` ${row.homeDetails? row.homeDetails.address: ""} `,
          sortDescFirst: true,
        },
        {
            accessorKey:"topic",
            header:"Description",
            accessorFn: (row) => {
              const description = row && row.topic;
              if (description && description.length > 28) {
                return description.slice(0, 28) + "...";
              }
              return description;
            }
        },
        // {
        //   accessorKey: "type",
        //   header: "Type",
        //   Cell: ({ row }) => {
        //     const selectedTypeLabel = homeAddresses?.label || "All";
        //     const crId = row.original.crId || "";
        //     const mrId = row.original.mrId || "";
        
        //     return (
        //       <span>
        //         {selectedTypeLabel === "All"
        //           ? `${crId ? "Compliance Request" : ""} ${mrId ? "Maintenance Request" : ""}`.trim()
        //           : selectedTypeLabel === "Compliance Request"
        //           ? "Compliance Request"
        //           : selectedTypeLabel === "Maintenance Request"
        //           ? "Maintenance Request"
        //           : ""}
        //       </span>
        //     );
        //   },
        // },
        {
          accessorKey: "status",
          header: "Status",
          accessorFn: (row) => convertToPascalCase(row.status),
        },
    
        {
          accessorKey: "createdDateAndTime",
          header: "Created Date",
          size: 25,
       accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),
    },
       
      ]);
      const handleViewClick = (selectedRow) => {
        // const status = selectedRow.row.original.status;
        // console.log("hhoa",status);
        // console.log(selectedRow.row.original.homeOwnerDuesId,"iodddddddddddd");

        // // navigate(`/mcbmarreport/${selectedRow.row.original.homeOwnerDuesId}`);
        // if (status == 'PENDING') {
        //   console.log(selectedRow.row.original.homeOwnerDuesId,"iodddddddddddd");
          
          navigate(`/mcbmarreport/${selectedRow.row.original.homeOwnerDuesId}`);
        // } else if (status == 'PAID') {
        //   console.log(selectedRow.row.original.homeOwnerPaymentId,"iodddddddddddd");

        //   navigate(`/arreportpaid/${selectedRow.row.original.homeOwnerPaymentId}`);
        //   console.log(selectedRow.row.original.homeOwnerPaymentId);
        // } else {
        //   console.log(`Unhandled status: ${status}`);
        // }
      };
      const handleViewPaidClick = (selectedRow) => {
        // const status = selectedRow.row.original.status;
        // console.log("hhoa",status);
        // console.log(selectedRow.row.original.homeOwnerDuesId,"iodddddddddddd");

        // // navigate(`/mcbmarreport/${selectedRow.row.original.homeOwnerDuesId}`);
        // if (status == 'PENDING') {
        //   console.log(selectedRow.row.original.homeOwnerDuesId,"iodddddddddddd");
          
        //   navigate(`/mcbmarreport/${selectedRow.row.original.homeOwnerDuesId}`);
        // } else if (status == 'PAID') {
        //   console.log(selectedRow.row.original.homeOwnerPaymentId,"iodddddddddddd");

          navigate(`/arreportpaid/${selectedRow.row.original.homeOwnerPaymentId}`);
        //   console.log(selectedRow.row.original.homeOwnerPaymentId);
        // } else {
        //   console.log(`Unhandled status: ${status}`);
        // }
      };
      // const handlePaidViewClick =(selectedRow) =>{
      //   navigate(`/arreportpaid/${selectedRow.original.homeOwnerPaymentId}`);
      //   console.log(selectedRow.original.homeOwnerPaymentId);
      // }
      
      const requestReportDueTable = useMemo((rowData) => [
        {
          accessorKey: 'homeOwnerFullName',
          header: 'Home Owner Name',
          accessorFn: (row) => {
            const firstName = row.homeOwnerDetails && row.homeOwnerDetails.firstName;
            const lastName = row.homeOwnerDetails && row.homeOwnerDetails.lastName;
            return `${firstName || ''} ${lastName || ''}`.trim();
          },
          sortDescFirst: true,
        },
        {
          accessorKey: "homeAddress",
          header: "Home Address",
          size: 20,
          accessorFn: (row) => {
            const firstName = row.homeDetails && row.homeDetails.address;
            // const lastName = row.homeOwnerDetails && row.homeOwnerDetails.lastName;
            return `${firstName || ''} `.trim();
          },
          sortDescFirst: true,
        },
        {
          accessorKey: 'createdBy',
          header: 'Created By',
          size: 20,
          accessorFn: (row) => {
            const firstName = row.createdByDetails && row.createdByDetails.firstName;
            const lastName = row.createdByDetails && row.createdByDetails.lastName;
            return `${firstName || ''} ${lastName || ''}`.trim();
          },
          sortDescFirst: true,
    
        },
        {
          accessorKey: "description",
          header: "Description",
          accessorFn: (row) => {
            const description = row && row.description;
            if (description && description.length > 28) {
              return description.slice(0, 28) + "...";
            }
            return description;
          }
        },
        
        {
          accessorKey: "dueType",
          header: "Due Type",
          size: 20,
          accessorFn: (row) => convertToPascalCase(row.dueType),
        },
    
        {
          accessorKey: "status",
          header: "Status",
          size: 20,
          accessorFn: (row) => convertToPascalCase(row.status),
        },

        {
          accessorKey: "createdDateAndTime",
          header: "Created Date Time",
          size: 20,
          accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
        },
        {
          accessorKey: "dueDate",
          header: "Due Date ",
          size: 20,
          accessorFn: (row) => moment(row.dueDate).format("MM/DD/YYYY "),
        },
        {
          accessorKey: "endDate",
          header: "Last Date",
          size: 20,
          accessorFn: (row) => row.endDate ? moment(row.endDate).format("MM/DD/YYYY") : ""
       },
        {
          accessorKey: "dueAmount",
          header: "Amount",
          size: 20,
        },
      ]);
      const requestReportDuePaidTable = useMemo((rowData) => [
        {
          accessorKey: 'homeOwnerFullName',
          header: 'Home Owner Name',
          accessorFn: (row) => {
            const firstName = row.homeOwnerDetails && row.homeOwnerDetails.firstName;
            const lastName = row.homeOwnerDetails && row.homeOwnerDetails.lastName;
            return `${firstName || ''} ${lastName || ''}`.trim();
          },
          sortDescFirst: true,
        },
        {
          accessorKey: "homeAddress",
          header: "Home Address",
          size: 20,
          accessorFn: (row) => {
            const firstName = row.homeDetails && row.homeDetails.address;
            // const lastName = row.homeOwnerDetails && row.homeOwnerDetails.lastName;
            return `${firstName || ''} `.trim();
          },
          sortDescFirst: true,
        },
        {
          accessorKey: 'createdBy',
          header: 'Created By',
          size: 20,
          accessorFn: (row) => {
            const firstName = row.createdByDetails && row.createdByDetails.firstName;
            const lastName = row.createdByDetails && row.createdByDetails.lastName;
            return `${firstName || ''} ${lastName || ''}`.trim();
          },
          sortDescFirst: true,
    
        },
        {
          accessorKey: "description",
          header: "Description",
          accessorFn: (row) => {
            const description = row && row.description;
            if (description && description.length > 28) {
              return description.slice(0, 28) + "...";
            }
            return description;
          }
        },
        
        {
          accessorKey: "dueType",
          header: "Due Type",
          size: 20,
          accessorFn: (row) => convertToPascalCase(row.dueType),
        },
    
        {
          accessorKey: "status",
          header: "Status",
          size: 20,
          accessorFn: (row) => convertToPascalCase(row.status),
        },

        {
          accessorKey: "createdDateAndTime",
          header: "Created Date Time",
          size: 20,
          accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
        },
        {
          accessorKey: "dueDate",
          header: "Due Date ",
          size: 20,
          accessorFn: (row) => moment(row.dueDate).format("MM/DD/YYYY "),
        },
        {
          accessorKey: "endDate",
          header: "Last Date",
          size: 20,
          accessorFn: (row) => row.endDate ? moment(row.endDate).format("MM/DD/YYYY") : ""
       },
        {
          accessorKey: "dueAmount",
          header: "Amount",
          size: 20,
        },
      ]);
      // const rowActionsDue = (row) => {
      //   const actions =[];
        
      //   if (row.original.homeOwnerDuesId && row.original.status == "PENDING") {
      //     actions.push({
      //       label: "View",
      //       icon: <ListAlt color="primary" />,
      //       click: () => handleViewClick(row),
      //     });
      //   }
      //   if (row.original.homeOwnerPaymentId && row.original.status == "PAID") {
      //     actions.push({
      //       label: "View",
      //       icon: <ListAlt color="primary" />,
      //       click: () => handlePaidViewClick(row),
      //     });
      //   }
      //   return actions;
      // };
          
      const csvConfig = mkConfig({
        fieldSeparator: ",",
        decimalSeparator: ".",
        useKeysAsHeaders: true,
      });
      const exportCSV = (data, fields, csvConfig) => {
        // Generate CSV headers from the fields array
        const headers = fields.map(field => csvConfig.useKeysAsHeaders ? field.header : field.accessor).join(csvConfig.fieldSeparator);
        // Generate CSV rows from the data
        const rows = data.map(row => {
          return fields.map(field => {
            const value = row[field.accessor];
            // Check if value is defined before accessing its properties
            if (value && value.includes && value.includes(csvConfig.fieldSeparator)) {
              return `"${value}"`;
            }
            return value;
          }).join(csvConfig.fieldSeparator);
        });
      
        const csvContent = [headers, ...rows].join('\n');
        return csvContent;
    };
    
    const csvConfigWithFilename = {
      ...csvConfig,
      filename: 'Dues Report' 
    };
    const csvConfigWithComplainceFilename = {
        ...csvConfig,
        filename: 'Complaince Report' 
      };
      const csvConfigWithMaintenanceFilename = {
        ...csvConfig,
        filename: 'Maintenance Report' 
      };
  
      // const handleExportComplianceCsv = (data) => {
      //   const fieldsToExport = [
      //       {
      //           accessor: "status",
      //           header: "Status",
      //           accessorFn: (row) => convertToPascalCase(row.status),
      //         },
          
      //         {
      //           accessor: "createdDateAndTime",
      //           header: "Created Date",
      //           size: 25,
      //        accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),
      //     },
      //     {
      //       accessor:"description",
      //       header:"Description"
      //     },
      //     {
      //       accessor:"crId",
      //       header:"crId"
      //     }
    
      //     // Add more fields as needed
      //   ];
      //   const csv = exportCSV(data, fieldsToExport, csvConfigWithComplainceFilename);
      // download(csvConfigWithComplainceFilename)(csv);
      //   // const csv = generateCsv(csvConfig)(auditListTable);
      //   // download(csvConfig)(csv);
      // };
      const handleExportComplianceCsv = () => {
        const excludedProperties = [
          "homeId",
          "groupId",
          "homeOwnerDuesId",
          "homeOwnerDues",
      "attachmentsCount",
      "crCreatedDate",
          "createdHomeId",
          "auditBy",
          "title",
          "homeOwnerDetails",
          "requestForAccess",
          "id",
          "attachments",
          "dueAmount",
          "dueDate",
          "isLinked","isFineApplicable","isManualFine",

          // "createdBy",
          "isOnRent",
          "fileUrl",
          "homeOwnerLoginId",
          "homeOwnerDetailsId",
         "createdHomeId",
      "dueAmount",
      "dueDate",
      "attachments",
          "complainceId",
         "category",
         "address",
         "createdToName",
         "createdByHomeAddress",
          "reviewComments",
          "categoryDetails",
          "createdToDetails",
          "byLawDetails",
          "createdToDetails",
          "createdByName",
          "homeOwner",
          "bylawId",
          "parentId",
          "comments",
          "bylawDetails",
          "auditDateAndTime",
          "isAssigned","totalAmount","homeDetails","createdByDetails",
          "groupDuesId","specialAssType","dueFrequency","tite","role","categoryId",
         "maintenanceId", "externalUserId","jobId","isAssignedToBm","jobDetails","auditList",
        ];
      
       
      
        const formattedData = Object.values(homeComplaince).map((item) => {
          const formattedDatetime = moment(item.createdDateAndTime).format(
            "MM/DD/YYYY HH:mm:ss"
          );
          const formattedDateAudit = moment(item.auditDateAndTime).format(
            "MM/DD/YYYY HH:mm:ss"
          );
          const createdBy = item.createdByDetails
            ? `${item.createdByDetails.firstName} ${item.createdByDetails.lastName}`
            : '';
            const createdByAddress = item.address
            ? `${item.address.address}`
            :  `${item.createdByName}`;
            const createdTo = item.createdToDetails
            ? `${item.createdToDetails.firstName} ${item.createdToDetails.lastName}`
            : '';
            const createdToAddress = item.address ? item.address.address : "";

            const type = item.complainceId ? "Compliance Request" : "Maintenance Request";
          const filteredItem = {
            ...item,
            createdBy:createdBy,
            createdByAddress:createdByAddress,
            createdDateAndTime: formattedDatetime,
            AuditDateAndTime:formattedDateAudit,
            ...(createdTo && { createdTo }),
            createdToAddress:createdToAddress,

            type: type,
                  };
      
          excludedProperties.forEach((property) => {
            delete filteredItem[property];
          });
      
          return filteredItem;
        });
      
        const csvConfigWithFilename = {
          ...csvConfig,
          filename: 'HomeActivity compliance Report' 
        };
      
        const csv = generateCsv(csvConfigWithFilename)(formattedData);
      download(csvConfigWithFilename)(csv);
      };
      const handleExportMaintenanceCsv = () => {
        const excludedProperties = [
          "homeId",
          "groupId",
          "attachmentsCount",
          "homeOwnerDuesId",
          "auditBy",
          "title",
          "homeOwnerDetails",
          "requestForAccess",
          "id",
          "attachments",
          "isSafty","topic","isUrgent",
      "auditDateAndTime",
          // "createdBy",
          "isOnRent",
          "fileUrl",
          "homeOwnerLoginId",
          "homeOwnerDetailsId",
         
          "complainceId",
         "category",
         "address",
         "createdToName",
         "createdByHomeAddress",
          "reviewComments",
          "categoryDetails",
          "createdToDetails",
          "byLawDetails",
          "createdToDetails",
          "createdByName",
          "homeOwner",
          "bylawId",
          "parentId",
          "comments",
          "bylawDetails",
          "crId",
          "assignedTo",
          "createdToAddress",
          // "startDate","endDate",
          "isAssigned","totalAmount","homeDetails","createdByDetails",
          "groupDuesId","specialAssType","dueFrequency","tite","role","categoryId",
         "maintenanceId", "externalUserId","jobId","isAssignedToBm","jobDetails","auditList",
        ];
      
       
      
        const formattedData = Object.values(homeMaintenance).map((item) => {
          const formattedDatetime = moment(item.createdDateAndTime).format(
            "MM/DD/YYYY HH:mm:ss"
          );
          const startDates = 
          item.startDate ? moment(item.startDate).format("MM/DD/YYYY") : "";;
          const endDates=item.endDate ? moment(item.endDate).format("MM/DD/YYYY") : "";
          console.log("Start Date After Formatting:", startDates);
    console.log("End Date After Formatting:", endDates);
          const createdBy = item.createdByDetails
            ? `${item.createdByDetails.firstName} ${item.createdByDetails.lastName}`
            : '';
            const createdByAddress = item.homeDetails
            ? `${item.homeDetails.address}`
            : "" ;
            
            const type = item.complainceId ? "Compliance Request" : "Maintenance Request";
          const filteredItem = {
            ...item,
            createdBy:createdBy,
            createdByAddress:createdByAddress,
            createdDateAndTime: formattedDatetime,
            startDate:startDates,
            endDate:endDates,
            // ...(createdTo && { createdTo }),
            type: type,
                  };
      
          excludedProperties.forEach((property) => {
            delete filteredItem[property];
          });
      
          return filteredItem;
        });
      
        const csvConfigWithFilename = {
          ...csvConfig,
          filename: 'Maintenance Report' 
        };
      
        const csv = generateCsv(csvConfigWithFilename)(formattedData);
      download(csvConfigWithFilename)(csv);
      };
      // const handleExportDueData = (data) => {
      //   const fieldsToExport = [
      //     { header: 'Home Address',
      //      accessor: 'homeAddress',
      //      accessorFn: (row) => {
      //       // Check if homeDetails exists
      //       if (row.homeDetails) {
      //           const address = row.homeDetails.address || '';
      //           return address.trim(); // Return the address or empty string
      //       }
      //       return 'hhoa'; // Return empty if homeDetails is undefined
      //   }
      //          },
      //     { header: 'DueType',
      //      accessor: 'dueType' 
      //     },
      //     { header: 'Status',
      //      accessor: 'status' ,
    
      //     },
      //     { header: 'Created Date Time ',
      //      accessor: 'createdDateAndTime' , 
      //      accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a")
      //     },
      //     { header: 'Amount', 
      //     accessor: 'dueAmount'
      //    },
    
      //     // Add more fields as needed
      //   ];
      //   const csv = exportCSV(data, fieldsToExport, csvConfigWithFilename);
      // download(csvConfigWithFilename)(csv);
      //   // const csv = generateCsv(csvConfig)(auditListTable);
      //   // download(csvConfig)(csv);
      // };
     
      const handleExportDueData = () => {
        const excludedProperties = [
          "createdByDetails",
          "createdBy",
          "complianceId",
          "auditDateAndTime",
          "auditBy",
          "dueDate",
          "description",
          
          "dueFrequency",
          "endDate","fineAmount",
          // "createdBy",
          "groupDuesId",
          "groupId",
          "homeDetails",
          "homeOwnerDetails",
         "homeId",
          "homeOwnerLoginId",
         "homeOwnerDuesId",
         "homeOwnerDetailsId",
         "lastDate",
         "isReimbursedApplied",
          "isPaymentStarted",
          
          "specialAssType",
          "lastDate",
          "title",
          "createdByName",
          "totalAmount",
          "bylawId",
          "parentId",
          "waivedOffAmount",
          "bylawDetails",
          "crId",
          "assignedTo",
          "isAssigned","totalAmount","homeDetails","createdByDetails",
          "groupDuesId","specialAssType","dueFrequency","tite","role","categoryId",
         "maintenanceId", "externalUserId","jobId","isAssignedToBm","jobDetails","auditList",
        ];
      
       
      
        const formattedData = Object.values(dueData).map((item) => {
          const formattedDatetime = moment(item.createdDateAndTime).format(
            "MM/DD/YYYY HH:mm:ss"
          );
         
          const createdBy = item.dueType
            const createdByAddress = item.homeDetails
            ? `${item.homeDetails.address}`
            :  " ";
            const createdTo = item.homeOwnerDetails
            ? `${item.homeOwnerDetails.address}
            `
            : '';
            const startDates=item.dueAmount || "";
            const endDates=item.endDate || "";
            // const type = item.complainceId ? "Compliance Request" : "Maintenance Request";
          const filteredItem = {
            ...item,
            homeAddress:createdTo,
            dueType:createdBy,
            // createdByAddress:createdByAddress,
            createdDateAndTime: formattedDatetime,
            dueAmount:startDates,
            // endDate:endDates,
            // ...(createdTo && { createdTo }),
            // type: type,
                  };
      
          excludedProperties.forEach((property) => {
            delete filteredItem[property];
          });
      
          return filteredItem;
        });
      
        const csvConfigWithFilename = {
          ...csvConfig,
          filename: 'Dues report' 
        };
      
        const csv = generateCsv(csvConfigWithFilename)(formattedData);
      download(csvConfigWithFilename)(csv);
      };
      const handleExportPaidData = () => {
        const excludedProperties = [
          "createdByDetails",
          "createdBy",
          "isAgree",
          "totalDueAmount",
          "homeOwnerPaymentId",
          "stripeResponse",
          "invoice",
          "complianceId",
          "auditDateAndTime",
          "auditBy",
          "dueDate",
          "description",
          
          "dueFrequency",
          "endDate","fineAmount",
          // "createdBy",
          "groupDuesId",
          "groupId",
          "homeDetails",
          "homeOwnerDetails",
         "homeId",
          "homeOwnerLoginId",
         "homeOwnerDuesId",
         "homeOwnerDetailsId",
         "lastDate",
         "isReimbursedApplied",
          "isPaymentStarted",
          
          "specialAssType",
          "lastDate",
          "title",
          "createdByName",
          "totalAmount",
          "bylawId",
          "parentId",
          "waivedOffAmount",
          "bylawDetails",
          "crId",
          "assignedTo",
          "isAssigned","totalAmount","homeDetails","createdByDetails",
          "groupDuesId","specialAssType","dueFrequency","tite","role","categoryId",
         "maintenanceId", "externalUserId","jobId","isAssignedToBm","jobDetails","auditList",
        ];
      
       
      
        const formattedData = Object.values(duePaidData).map((item) => {
          const formattedDatetime = moment(item.createdDateAndTime).format(
            "MM/DD/YYYY HH:mm:ss"
          );
         
          const createdBy = item.dueType
            const createdByAddress = item.homeDetails
            ? `${item.homeDetails.address}`
            :  " ";
            const createdTo = item.homeOwnerDetails
            ? `${item.homeOwnerDetails.address}
            `
            : '';
            const startDates=item.dueAmount || "";
            const endDates=item.endDate || "";
            // const type = item.complainceId ? "Compliance Request" : "Maintenance Request";
          const filteredItem = {
            ...item,
            homeAddress:createdTo,
            dueType:createdBy,
            // createdByAddress:createdByAddress,
            createdDateAndTime: formattedDatetime,
            dueAmount:startDates,
            // endDate:endDates,
            // ...(createdTo && { createdTo }),
            // type: type,
                  };
      
          excludedProperties.forEach((property) => {
            delete filteredItem[property];
          });
      
          return filteredItem;
        });
      
        const csvConfigWithFilename = {
          ...csvConfig,
          filename: 'Dues report' 
        };
      
        const csv = generateCsv(csvConfigWithFilename)(formattedData);
      download(csvConfigWithFilename)(csv);
      };
      const tableDueActionButtons = (
        <div>
        {(dueData ?? []).length>0 &&(
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleExportDueData(dueData)}
            startIcon={<FileUploadIcon />}
          >
            <span className={CustomStyle.containedButtonStyle}>
              Export All Data
            </span>
          </Button>
    
    )}
        </div>
      );
      const tableDuePaidActionButtons = (
        <div>
        {(duePaidData ?? []).length>0 &&(
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleExportPaidData(duePaidData)}
            startIcon={<FileUploadIcon />}
          >
            <span className={CustomStyle.containedButtonStyle}>
              Export All Data
            </span>
          </Button>
    
    )}
        </div>
      );
      const currentDate = moment(); // Get the current date
  let dateTimeText = currentDate.format("DD MMMM YYYY"); 
  const handleRenderPDF = () => {
    if (!homeComplaince || homeComplaince.length === 0) {
      console.error('No data available for PDF rendering.');
      return;
    }

    const styles = {
      title: {
        fontSize: 18,
        color: "black",
        textAlign: "center",
        padding: "5px",
      },
      images:{
        width:"200px",
        height:"100px"
        
            },
      emptys: {
        marginTop: "3%",
      },emptyModule:{
        marginTop:"7px"
       },
      content: {
        fontSize: 12,
      color: "black",
      textAlign: "left",
      // paddingLeft:"60px",
      padding:"5px"
      },
      re:{
        fontSize:12,
        color:"black",
        textAlign:"left",
        padding:"5px"
           },
           adress:{
            fontSize: 12,
            color: "black",
            textAlign: "left",
            
            // paddingLeft:"60px",
            padding:"5px",
            // fontWeight:"bold"
          },
      description: {
        fontSize: 15,
        color: "black",
        textAlign: "left",
        paddingLeft: "60px",
        padding: "5px",
        fontWeight: "bold",
      },
      signature: {
        fontSize: 14,
        color: "black",
        textAlign: "left",
        fontStyle: "italic",
      },
      re:{
        fontSize:12,
        color:"black",
        textAlign:"left",
        padding:"5px"
           },
      headers: {
        fontSize: 12,
        color: "black",
        textAlign: "left",
        alignItems: "center",
        padding: "4px",
      },
      heading: {
        fontSize: 20,
        textAlign: "center",
        fontFamily: "Oswald",
      },
      afterfrom: {
        marginTop: "8%",
      },
      from: {
        fontSize: 11,
        color: "black",
        textAlign: "left",
        alignItems: "center",
        padding: "2px",
      },
      empty: {
        marginTop: "128%",
      },
      secondEmpty: {
        marginTop: "30%",
      }, headerImage:{
        marginTop:"1%",
   paddingLeft:"70px",
      paddingRight:"50px",
      alignItems:"center",
  
        width:"470px",
        height:"160px",
        // backgroundColor:"yellow"
      },
      formempty: {
        marginTop: "49%",
      },
      fromBorder: {
        border: "1px solid black",
      },
      description:{
        fontSize: 13,
        color: "black",
        textAlign: "left",
        // paddingLeft:"60px",
        padding:"5px",
      fontWeight:"bold"
      },
      toBorder: {
        border: "1px solid black",
      },letterPad:{
        marginTop:"30%"
      }
    };
    const renderPageContent = (details) => {
      const letterContent = [];
    
      if (details && details.complainceId ) {
        // logo:imageBlob
       
        letterContent.push(
          // Section for Compliance Request Notice
          {
            type: "text",
            text: groupAdress,
            style: styles.title,
          },

          {
            type: "text",
            text: "Compliance Request",
            style: styles.title,
          },
          { type: "text", text: " " },
          // { type: 'text', text: dateTimeText, style:styles.from },
          { style: styles.fromBorder },
          { type: "text", text: "", style: styles.formempty },
          { type: "text", text: "From", style: styles.from },
          { type: 'text', text: groupAdress, style: styles.from },

          { type: "text", text: footerAdress, style: styles.from },
          // {type:'text',text:dateTimeText,style: styles.content},
          { type: 'text',text: "",  style:styles.emptyModule},

         
          { type: "text", text: "", style: styles.afterfrom },

          // { text: " "  },
          // { text: `${(details && details.createdByDetails && details.createdByDetails.firstName) || '' }`, style: styles.adress, fontSize: 15 },
          { type: "text", text: "To :", style: styles.headers },
          // { type: 'text', text: "From", style: styles.headers  },
          // { type: 'text', text: "From", style: styles.headers  },
          {
            type: "text",
            text: `${
              (details &&
               details.createdToDetails &&
               (details.createdToDetails.firstName || '') + 
               (details.createdToDetails.firstName && details.createdToDetails.lastName ? ' ' : '') +
               (details.createdToDetails.lastName || ''))
              || ""}`,
            style: styles.headers,
          },
          {
            type: "text",
            text: `${
              (details.createdToDetails?.addressForCommunication?? "") ||
              (details.address?.address?? "") ||
              " "
            }`,
            style: styles.headers,
          },
          {
            type: "text",
            text: `${
              (details &&
                details.createdToDetails &&
                details.createdToDetails.city) ||
              ""
            }${
              (details &&
                details.createdToDetails &&
                details.createdToDetails.city &&
                details.createdToDetails.zipcode) ? ", " : ""
            }${
              (details &&
                details.createdToDetails &&
                details.createdToDetails.zipcode) ||
              " "
            }`,
            style: styles.headers,
          },

          { type: "text", text: "", style: styles.empty },
          { type: "text", text: "", style: styles.secondEmpty },

          {type: 'image',src:imageBlob, style:styles.headerImage  },
          { type: 'text', text: groupAdress, style: styles.title },
          { type: 'text', text: "Compliance Request", style: styles.title },
          {type:'text',text:dateTimeText,style: styles.content},

          { type: 'text',text: "",  style:styles.emptyModule},
          {
            type: "text",
            text: `${
              (details &&
               details.createdToDetails &&
               (details.createdToDetails.firstName || '') + 
               (details.createdToDetails.firstName && details.createdToDetails.lastName ? ' ' : '') +
               (details.createdToDetails.lastName || ''))
              || ""}`,
            style: styles.headers,
          },

          {
            type: "text",
            text: `${
              (details.createdToDetails?.addressForCommunication?? "") ||
              (details.address?.address?? "") ||
              " "
            }`,
            style: styles.headers,
          },
          {
            type: "text",
            text: `${
              (details &&
                details.createdToDetails &&
                details.createdToDetails.city) ||
              ""
            }${
              (details &&
                details.createdToDetails &&
                details.createdToDetails.city &&
                details.createdToDetails.zipcode) ? ", " : ""
            }${
              (details &&
                details.createdToDetails &&
                details.createdToDetails.zipcode) ||
              " "
            }`,
            style: styles.headers,
          },
          { type: 'text',text: "",  style:styles.emptyModule},
          {
            type: 'text',
            text: `RE: ${
              (details.createdToDetails?.addressForCommunication?? "") ||
              (details.address?.address?? "") ||
              " "
            }`,
            style: styles.re
          },
          { type: 'text',text: "",  style:styles.emptyModule},
          {
            type: "text",
            text: "Dear Resident,",
            //  ${
            //   (details &&
            //     details.createdByDetails &&
            //     details.createdByDetails.firstName) ||
            //   " "
            // }`   
            
            style: styles.content,
            fontSize: 15,
          },
          // {
          //   type: "text",
          //   text: "You have previously asked to address the following matter with your property.",
          //   style: styles.content,
          // },
          {
            type: "text",
            text: `This is to inform you about the compliance request: ${
              details?.crId ?? ""
            }_${moment(details?.createdDateAndTime).format('MM/DD/YYYY hh:mm A')}`,
            style: styles.content,
          },
          {
            type: "text",
            text: `${details && details.description}`,
            style: styles.description,
            
          },
          {
            type: "text",
            text: "If you have any questions, feel that you have received this letter in error, or would like to request a variance, please feel free to contact our office.",
            style: styles.content,
          },
          {
            type: "text",
            text: "Thank you in advance for your attention to this matter.",
            style: styles.content,
          },
          { type: "text", text: "" },
          { type: "text", text: "", style: styles.content },
          { type: "text", text: "", style: styles.content },
          // {  type: 'text',text: "Sincerely,", style: styles.signature },
          // { type: 'text',text: footerContent , style: styles.adress}
          {type: 'text', text: "Sincerely,", style: styles.adress },
          { 
            type: "text", 
            text: `${footerAdress} (computer generated letter)`, 
            style: styles.adress 
          },
          // { 
          //   type: "text", 
          //   text: "Attachments:", 
          //   style: styles.content 
          // },
          // {type: 'text',  text: `${details?.fileurl ?? ''}`, style: styles.content},

          // {
          //   type: 'image',
          //   src: `${BASE_URL_IMAGE}/${details.fileUrl?? ""}`,
          //   style: styles.images 
          // }
          // { type: "text", text: "", style: styles.empty },
          // { type: "text", text: "", style: styles.secondEmpty },
          // { type: "image", src: testImages }
        );
//         if (testImages.length > 0) {

//           testImages.forEach(image => {
//           letterContent.push({
//               type: "image",
//               src: image.src
//           });
//       });
//   }
        

      }
      if (details.attachments && Array.isArray(details.attachments) && details.attachments.length > 0) {
        letterContent.push({ type: "text", text: "Attachments:", style: styles.content });
      
        details.attachments.forEach((attachment) => {
          const fileRemoval = attachment.file.substring(12);
      
          letterContent.push(
            { type: 'text', text: fileRemoval, style: styles.content },
            {
              type: 'image',
              src: `${BASE_URL_IMAGE}/${attachment.file}`,
              style: styles.images
            }
          );
        });
      } else {
        letterContent.push({ type: "text", text: " ", style: styles.content });
      }
     
      return letterContent;
    };
    // const headingTitle=groupAdress;
     const logo = imageBlob;
    const data = homeComplaince.map(details => ({
      letterContent: renderPageContent(details)
    }));

    return (
      <PDFDocumentList data={data} styles={styles} logo={logo} />
    );
  };
  const handleRenderPdfMaintainence = () => {
    if (!homeMaintenance || homeMaintenance.length === 0) {
      console.error('No data available for PDF rendering.');
      return;
    }

    const styles = {
      title: {
        fontSize: 18,
        color: "black",
        textAlign: "center",
        padding: "5px",
      },images:{
        width:"200px",
        height:"100px"
        
            },
      emptys: {
        marginTop: "3%",
      },emptyModule:{
        marginTop:"7px"
       },
      content: {
        fontSize: 12,
      color: "black",
      textAlign: "left",
      // paddingLeft:"60px",
      padding:"5px"
      },
      re:{
        fontSize:12,
        color:"black",
        textAlign:"left",
        padding:"5px"
           },
           adress:{
            fontSize: 12,
            color: "black",
            textAlign: "left",
            
            // paddingLeft:"60px",
            padding:"5px",
            // fontWeight:"bold"
          },
      description: {
        fontSize: 15,
        color: "black",
        textAlign: "left",
        paddingLeft: "60px",
        padding: "5px",
        fontWeight: "bold",
      },
      signature: {
        fontSize: 14,
        color: "black",
        textAlign: "left",
        fontStyle: "italic",
      },
      re:{
        fontSize:12,
        color:"black",
        textAlign:"left",
        padding:"5px"
           },
      headers: {
        fontSize: 12,
        color: "black",
        textAlign: "left",
        alignItems: "center",
        padding: "4px",
      },
      heading: {
        fontSize: 20,
        textAlign: "center",
        fontFamily: "Oswald",
      },
      afterfrom: {
        marginTop: "8%",
      },
      from: {
        fontSize: 11,
        color: "black",
        textAlign: "left",
        alignItems: "center",
        padding: "2px",
      },
      empty: {
        marginTop: "128%",
      },
      secondEmpty: {
        marginTop: "30%",
      }, headerImage:{
        marginTop:"1%",
   paddingLeft:"70px",
      paddingRight:"50px",
      alignItems:"center",
  
        width:"470px",
        height:"160px",
        // backgroundColor:"yellow"
      },
      formempty: {
        marginTop: "49%",
      },
      fromBorder: {
        border: "1px solid black",
      },
      description:{
        fontSize: 13,
        color: "black",
        textAlign: "left",
        // paddingLeft:"60px",
        padding:"5px",
      fontWeight:"bold"
      },
      toBorder: {
        border: "1px solid black",
      },letterPad:{
        marginTop:"30%"
      }
    };
    const renderPageContent = (details) => {
      const letterContent = [];
    
      if (details && details.maintenanceId) {
        // logo:imageBlob
        letterContent.push(
          // Section for Compliance Request Notice
          {
            type: "text",
            text: groupAdress,
            style: styles.title,
          },
          {type: 'text', text: "Maintenance Request", style: styles.title },
          { text: " "  }, 
          // { text: dateTimeText, style:styles.adress },
          {style:styles.fromBorder},
          {type: 'text', text: "",  style:styles.formempty},
          { type: 'text',text: "From", style: styles.from },
          { type: 'text', text: groupAdress, style: styles.from },

          { type: "text", text: footerAdress, style: styles.from },

        //   { type: 'text',text: `${(details && details.createdByDetails && details.createdByDetails.firstName) || '' }`, style: styles.from },
        //  {  type: 'text',text: `${(details && details.address && details.address.address) || " " }`, style: styles.from },
        //   {  type: 'text',text: `${(details && details.createdByDetails && details.createdByDetails.city) || ""}, ${(details && details.createdByDetails && details.createdByDetails.zipcode) || " "}`, style: styles.from},
          { type: 'text',text: "",  style:styles.afterfrom},

          // { text: " "  },
          // { text: `${(details && details.createdByDetails && details.createdByDetails.firstName) || '' }`, style: styles.adress, fontSize: 15 },
          // { type: 'text',text: "To :", style: styles.headers  },
          {
            type: "text",
            text: `${
              (details &&
               details.createdByDetails &&
               (details.createdByDetails.firstName || '') + 
               (details.createdByDetails.firstName && details.createdByDetails.lastName ? ' ' : '') +
               (details.createdByDetails.lastName || ''))
              || ""}`,
            style: styles.headers,
          },
          {
            type: "text",
            text:`${
              (details.createdByDetails?.addressForCommunication?? "") ||
              (details.homeDetails?.address?? "") ||
              " "
            }`,
            style: styles.headers,
          },
          {
            type: "text",
            text: `${
              (details &&
                details.createdByDetails &&
                details.createdByDetails.city) ||
              ""
            }${
              details && details.createdByDetails && details.createdByDetails.city && details.createdByDetails.zipcode
                ? ", "
                : ""
            } ${
              (details &&
                details.createdByDetails &&
                details.createdByDetails.zipcode) ||
              " "
            }`,
            style: styles.headers,
          },
          {type: 'text', text: "",  style:styles.empty},
          { type: 'text',text: "",  style:styles.secondEmpty},
          // { type: 'text',text: "",  style:styles.letterPad},
          {type: 'image',src:imageBlob, style:styles.headerImage  },
          { type: 'text', text: groupAdress, style: styles.title },
          { type: 'text', text: "Maintenance Request", style: styles.title },
          {type:'text',text:dateTimeText,style: styles.content},

          { type: 'text',text: "",  style:styles.emptyModule},
          {
            type: "text",
            text: `${
              (details &&
               details.createdByDetails &&
               (details.createdByDetails.firstName || '') + 
               (details.createdByDetails.firstName && details.createdByDetails.lastName ? ' ' : '') +
               (details.createdByDetails.lastName || ''))
              || ""}`,
            style: styles.headers,
          },
          {
            type: "text",
            text: `${
              (details.createdByDetails?.addressForCommunication?? "") ||
              (details.homeDetails?.address?? "") ||
              " "
            }`,
            style: styles.headers,
          },
          {
            type: "text",
            text: `${
              (details &&
                details.createdByDetails &&
                details.createdByDetails.city) ||
              ""
            }${
              details && details.createdByDetails && details.createdByDetails.city && details.createdByDetails.zipcode
                ? ", "
                : ""
            } ${
              (details &&
                details.createdByDetails &&
                details.createdByDetails.zipcode) ||
              " "
            }`,
            style: styles.headers,
          },
          {
            type: 'text',
            text: `RE:  ${
              (details.createdByDetails?.addressForCommunication?? "") ||
              (details.homeDetails?.address?? "") ||
              " "
            }`,
            style: styles.re
          },
          { type: 'text',text: "",  style:styles.emptyModule},

          { type: 'text',text:"DearResident", style: styles.content },
          { type: 'text',text: "You have previously asked to address the following matter with your property.", style: styles.content },
          { type: 'text',text: `This is to inform you about the maintenance request: ${
            details?.mrId ?? ""
          }_${moment(details?.createdDateAndTime).format('MM/DD/YYYY hh:mm A')}`+",which has been "+convertToPascalCase(details.status), style: styles.content },
          {type: 'text',  text: `${details?.description ?? ''}`, style: styles.content, fontSize: 15 },
          {
            type: 'text',
            text: `${
              details && details.startDate && details.endDate
                ? "We have scheduled the desired start of the maintenance " + 
                  moment(details.startDate).format("MM/DD/YYYY") + 
                  " and desired end of maintenance on " + 
                  moment(details.endDate).format("MM/DD/YYYY") + 
                  ". Please ensure these dates are noted. Should any adjustments be needed, contact me at your earliest convenience."
                : ""
            }`,
            style: styles.content
          },
          { type: 'text',text: "If you have any questions, feel that you have received this letter in error, or would like to request a variance, please feel free to contact our office.", style: styles.content },
          { type: 'text',text: "Thank you in advance for your attention to this matter.", style: styles.content },
          {type: 'text', text: "",  }, 
          { type: 'text',text: "", style: styles.content },
          { type: 'text',text: "", style: styles.content },
          {type: 'text', text: "Sincerely,", style: styles.adress },
          { 
            type: "text", 
            text: `${footerAdress} (computer generated letter)`, 
            style: styles.adress 
          },
         

        );
      }
      if (details.attachments && Array.isArray(details.attachments) && details.attachments.length > 0) {
        letterContent.push({ type: "text", text: "Attachments:", style: styles.content });
      
        details.attachments.forEach((attachment) => {
          const fileRemoval = attachment.file.substring(12);
      
          letterContent.push(
            { type: 'text', text: fileRemoval, style: styles.content },
            {
              type: 'image',
              src: `${BASE_URL_IMAGE}/${attachment.file}`,
              style: styles.images
            }
          );
        });
      } else {
        letterContent.push({ type: "text", text: " ", style: styles.content });
      }
     
      return letterContent;
    };
    // const headingTitle=groupAdress;
     const logo = imageBlob;
    const data = homeMaintenance.map(details => ({
      letterContent: renderPageContent(details)
    }));

    return (
      <PDFDocumentList data={data} styles={styles} logo={logo} />
    );
  };
      const handleExportDataComplaince = async () => {
        try {
          const pdfData = handleRenderPDF();
          const blob = await pdf(pdfData).toBlob();
          const url = URL.createObjectURL(blob);
          window.open(url, '_blank');
        } catch (error) {
          console.error('Error generating PDF:', error);
        }
      };
      const handleExportDataMaintenance = async () => {
        try {
          const pdfData = handleRenderPdfMaintainence();
          const blob = await pdf(pdfData).toBlob();
          const url = URL.createObjectURL(blob);
          window.open(url, '_blank');
        } catch (error) {
          console.error('Error generating PDF:', error);
        }
      };
      const rowActions =(row) =>{ 
        const actions =[];
        
        // if (row.original && row.original.maintenanceId) {
        //   actions.push({
        //     label: "View",
        //     icon: <ListAlt color="primary" />,
        //     click: () => handleViewClick(row),
        //   });
        // }
        if (row.original && row.original.complainceId) {
          actions.push({
            label: "View",
            icon: <ListAlt color="primary" />,
            click: () => handleCrViewClick(row),
          });
        }
        return actions;
       
      };
      const rowActionsMaintenance =(row) =>{ 
        const actions =[];
        
        // if (row.original && row.original.maintenanceId) {
        //   actions.push({
        //     label: "View",
        //     icon: <ListAlt color="primary" />,
        //     click: () => handleViewClick(row),
        //   });
        // }
        if (row.original && row.original.maintenanceId) {
          actions.push({
            label: "View",
            icon: <ListAlt color="primary" />,
            click: () => handleMrViewClick(row),
          });
        }
        return actions;
       
      };
      const tableActionButtonsComplaince = (
        <>
        {homeComplaince && homeComplaince.length > 0 && (
            <>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleExportComplianceCsv(homeComplaince)}
            startIcon={<FileUploadIcon/>}
          >
            <span className={CustomStyle.containedButtonStyle}>
              Export All Data
            </span>
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleExportDataComplaince}
            startIcon={<FileUploadIcon/>}
          >
            <span className={CustomStyle.containedButtonStyle}>
              Export As pdf
            </span>
          </Button>
          </>
        )}
        </>
      );
      const tableActionButtonsMaintenance = (
        <>
        {homeMaintenance && homeMaintenance.length > 0 && (
            <>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleExportMaintenanceCsv(homeMaintenance)}
            startIcon={<FileUploadIcon/>}
          >
            <span className={CustomStyle.containedButtonStyle}>
              Export All Data
            </span>
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleExportDataMaintenance}
            startIcon={<FileUploadIcon/>}
          >
            <span className={CustomStyle.containedButtonStyle}>
              Export As pdf
            </span>
          </Button>
          </>
        )}
        </>
      );
      const handleExportScreen = () => {
        console.log("Export button clicked");
        
        // Apply CSS transformation to skip 25% from the left side when printing
        const originalStyle = document.body.style.cssText;
        document.body.style.cssText += "transform: translateY(-10%) translateX(-32%);";
        
        // Trigger the print functionality
        window.print();
        
        // Restore the original body style after printing
        document.body.style.cssText = originalStyle;
      }

      const hiddenContentRef  = useRef(null);

      // const handleCapture = () => {
      //   const contentToExport = contentRef.current;
      //   if (!contentToExport) return;
    
      //   const contentHTML = contentToExport.innerHTML;
    
      //   // Open a new window
      //   const exportWindow = window.open('', '_blank');
    
      //   // Write the content to the new window
      //   exportWindow.document.write(`
      //     <html>
      //     <head>
      //       <title>Export</title>
      //     </head>
      //     <body>
      //       <div>
      //         ${contentHTML}
      //       </div>
      //     </body>
      //     </html>
      //   `);
    
      //   // Prevent the new window from navigating away when closed
      //   exportWindow.document.close();
      // };
  //*changes
      // const handleCapture = () => {
      // const content = hiddenContentRef.current.innerHTML;
      // const newWindow = window.open('', '_blank');
      // newWindow.document.write('<html><head><title>Home Activity Report</title></head><body>');
      // newWindow.document.write(content);
      // newWindow.document.write('</body></html>');
      // newWindow.document.close();
      // newWindow.document.querySelector('button.download-btn').addEventListener('click', () => {
      //   newWindow.print();
      // });
      // }
      // 
      const handleCapture = () => {
        // Get the HTML content from the hidden content
        const content = hiddenContentRef.current.innerHTML;
         
        // Open a new tab
        const newWindow = window.open("", "_blank");
      
        // Write the content and style to the new tab
        newWindow.document.write(`
          <html>
            <head>
              <title>Home acivity details</title>
              <style>
                @media print {
                  .download-btn {
                    display: none;
                  }
                  @page {
                    size: auto;
                    margin: 0mm;
                    padding:10mm
                  }
                  body {
                    margin: 10mm;
                  }
                }
              </style>
            </head>
            <body>
              ${content}
              <script>
                document.addEventListener('DOMContentLoaded', () => {
                  const downloadButton = document.querySelector('.download-btn');
                  if (downloadButton) {
                    downloadButton.addEventListener('click', () => {
                      window.print();
                    });
                  }
                });
              </script>
            </body>
          </html>
        `);
      
        // Close the document to ensure it's fully written
        newWindow.document.close();
      };
      const handleExportScreenss = () => {
        
    
        // Trigger print dialog
        newWindow.print();
      };
      const hasData = homeComplaince.length > 0 || homeMaintenance.length > 0 || dueData.length > 0;

  return (
    <div>
            <CustomHeading title="Home Activity Report" />
            {isLoading && <CustomLoading />}
            <div>
            <form onSubmit={handleSubmit(onSubmit)}>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
            <div class=" flex text-left mt-0 p-3 ">

           <CustomAutoComplete
              fieldName="selectedOption"
              requiredErrorMessage = {HOME_ADDRESS_REQUIRED_MESSAGE}
              id="selectId"
              options={renderedAddresses}
              fieldLabel="Search By HomeDetails
              "
              fieldControl={control}
              error={errors}
              setValue={setValue}
              fieldRequiredIcon="*" 
              onChange={handleHomeChange}
              invalidOption="Invalid home address"
              tooltipMessage="You can search by (address, firstname, lastname, email, contact) it shows description below"
               />

              <div class="pl-2 mt-5">
              <CustomButton fieldType="submit" buttonName="Search" />
              </div>
              <div class="pl-2 mt-5" style={{marginLeft:"10px"}}>
              <Button
            color="primary"
            variant="contained"
            onClick={handleCapture}
            disabled={!hasData}
            ><span className={CustomStyle.containedButtonStyle}>
            Export
          </span></Button> 
          </div>
            </div>
            <div class=" mt-5">
                      </div>
            </section>
          </form>
            </div>
            <div class="mt-2">
            <Accordion defaultExpanded>
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="font-medium">
         Home Owner Information
        </AccordionSummary>
        <AccordionDetails>
        <CardContent>
            <section class="grid md:grid-cols-2 xl:grid-cols-2 gap-3">

          
<CustomInputField
                fieldName="homeOwnerDetails"
                fieldId="homeOwnerDetails"
                fieldType="text"
                fieldLabel="First Name"
                fieldControl={control}
                fieldError={errors}
                disabled={true}
                value={firstName}
              />
              <CustomInputField
                fieldName="homeOwnerDetails"
                fieldId="homeOwnerDetails"
                fieldType="text"
                fieldLabel="Last Name"
                fieldControl={control}
                fieldError={errors}
                disabled={true}
                value={lastName}
              />
<CustomInputField
                fieldName="homeOwnerDetails"
                fieldId="homeOwnerDetails"
                fieldType="text"
                fieldLabel="Email"
                fieldControl={control}
                fieldError={errors}
                disabled={true}
                value={email}
              />
              <CustomInputField
                fieldName="homeOwnerDetails"
                fieldId="homeOwnerDetails"
                fieldType="text"
                fieldLabel="Address"
                fieldControl={control}
                fieldError={errors}
                disabled={true}
                value={hadress}
              />
               <CustomPhoneInput
                      fieldControl={control}
                      label="Phone Number"
                    //   clearErrors={clearErrors}
                    isRequiredIcon= {false}

                      country="US"
                      value={contact}
                      disabled={true}
                    />
                   
      {/* <div class="mt-2">    
              <Controller
                name="dueFrequency"
                control={control}
                rules={{ required: TYPE_REQUIRED_MESSAGE }}
                render={({ field, fieldState }) => (
        <FormControl
                    variant="standard"
                    fullWidth
                    error={fieldState.invalid}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Please select type <span style={{ color: "red" }}>*</span>
                    </InputLabel> 
                    
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={type}
                      onChange={(e) => {
                        setType(e.target.value);
                        field.onChange(e.target.value);
                      }}
                      label="Select type"
                    >
                      <MenuItem value="ALL">All</MenuItem>
                      <MenuItem value="CR">Compliance Request</MenuItem>
                      <MenuItem value="MR">Maintenance Request</MenuItem>
                    </Select>
                    <FormHelperText style={{ color: "#D32F2F" }}>
                      {fieldState.invalid ? fieldState.error?.message : ""}
                    </FormHelperText>
                  </FormControl>
                )}
              />
              </div> */}
            </section>
            <CustomHeading title="Renter Information"/>
            <section class="grid md:grid-cols-2 xl:grid-cols-2 gap-3">
        
<CustomInputField
                fieldName="homeOwnerDetails"
                fieldId="homeOwnerDetails"
                fieldType="text"
                fieldLabel="First Name"
                fieldControl={control}
                fieldError={errors}
                disabled={true}
                value={renterFirstName}
              />
              <CustomInputField
                fieldName="homeOwnerDetails"
                fieldId="homeOwnerDetails"
                fieldType="text"
                fieldLabel="Last Name"
                fieldControl={control}
                fieldError={errors}
                disabled={true}
                value={reneterLastName}
              />
<CustomInputField
                fieldName="homeOwnerDetails"
                fieldId="homeOwnerDetails"
                fieldType="text"
                fieldLabel="Email"
                fieldControl={control}
                fieldError={errors}
                disabled={true}
                value={renteremail}
              />
              {/* <CustomInputField
                fieldName="homeOwnerDetails"
                fieldId="homeOwnerDetails"
                fieldType="text"
                fieldLabel="Address"
                fieldControl={control}
                fieldError={errors}
                disabled={true}
                value={homeAdressDetail}
              /> */}
               <CustomPhoneInput
                      fieldControl={control}
                      label="Phone Number"
                    //   clearErrors={clearErrors}
                    isRequiredIcon= {false}
                      country="US"
                      value={renterContact}
                      disabled={true}
                    />
                    </section>
        </CardContent>
        </AccordionDetails>
      </Accordion>
      </div>
      <div class="mt-2">
      <Accordion>
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="font-medium">
         Dues
        </AccordionSummary>
        <AccordionDetails>
        <CardContent>
        <h1 className="p-3">Dues Pending Details</h1>
        <CustomMaterialWithoutActons
        columns={requestReportDuePaidTable}
        data={dueData}
        // rowActions={rowActionsDue}
        onRowClick={handleViewClick}
        actionButtons={tableDueActionButtons}
      />
          <h1 className="p-3">Dues Paid Details</h1>
          <CustomMaterialWithoutActons
        columns={requestReportDueTable}
        data={duePaidData}
        // rowActions={rowActionsDue}
        onRowClick={handleViewPaidClick}
        actionButtons={tableDuePaidActionButtons}
      />
        </CardContent>
        </AccordionDetails>
       </Accordion>
       </div>
       <div class="mt-2">
       <Accordion>
       <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="font-medium">
         Compliance Request
        </AccordionSummary>
       <AccordionDetails>
        <CardContent>
            <section class="grid md:grid-cols-2 xl:grid-cols-2 gap-3">

           {/* <CustomAutoComplete
              fieldName="selectedOption"
              requiredErrorMessage = {HOME_ADDRESS_REQUIRED_MESSAGE}
              id="selectId"
              options={["jhgjhgjg","hfghgjhg"]}
              fieldLabel="Select Home Address"
              fieldControl={control}
              error={errors}
              setValue={setValue}
              fieldRequiredIcon="*" 
            //   onChange={handleHomeChange}
              invalidOption="Invalid home address"
               /> */}
{/* <CustomInputField
                fieldName="homeOwnerDetails"
                fieldId="homeOwnerDetails"
                fieldType="text"
                fieldLabel="HomeOwner Name"
                fieldControl={control}
                fieldError={errors}
                disabled={true}
                // value={}
              /> */}

      
            </section>
            <div class="text-left">
              {/* <CustomButton fieldType="submit" buttonName="Search" /> */}
            </div>
          <CustomMaterialWithoutActons
        columns={homeActivityReportComplainceTable}
        data={homeComplaince}
        // rowActions={rowActions}
        onRowClick={handleCrViewClick}
        actionButtons={tableActionButtonsComplaince}
      />
        </CardContent>
        </AccordionDetails>
        </Accordion>
        </div>
        <div class="mt-2">
        <Accordion>
       <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="font-medium">
         Maintenance Request
        </AccordionSummary>
       <AccordionDetails>
        <CardContent>
        <CustomMaterialWithoutActons
        columns={homeActivityReportMaintenanceTable}
        data={homeMaintenance}
        // rowActions={rowActionsMaintenance}
        actionButtons={tableActionButtonsMaintenance}
                  onRowClick={handleMrViewClick}

      />
        </CardContent>
        </AccordionDetails>
        </Accordion>
        </div>

        <div>
        <div id="pdf-content" ref={hiddenContentRef} style={{ display: 'none'}}>

<CardContent style={{alignItems:"center"}}>

  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
<h3>HomeOwner Information</h3>
{/* <Button className="download-btn" >Download</Button> */}
<Button
            color="primary"
            variant="contained"
            className='download-btn' ><span className={CustomStyle.containedButtonStyle}>
            Download
          </span></Button> 
</div>
<CardContent class="justify-items-center" >
        <div class="flex">
        <section class="grid md:grid-cols-2 xl:grid-cols-2 gap-3">

        <div style={{ padding: "13px" }}>
            <b class="text-sm font-sans break-words">First Name:</b>
            <span class="p-2">
              {firstName}
            {/* {exportHoDetails && exportHoDetails.firstName !== undefined && exportHoDetails.firstName !== null ? exportHoDetails.firstName : ""} */}
            </span>
          </div>
          <div style={{ padding: "13px" }}>
            <b class="text-sm font-sans break-words">Last Name:</b>
            <span class="p-2">
              {lastName}
            {/* {exportHoDetails && exportHoDetails.lastName !== undefined && exportHoDetails.lastName !== null ? exportHoDetails.lastName : ""} */}
              </span>
          </div>
          <div style={{ padding: "13px" }}>
            <b class="text-sm font-sans break-words">Email:</b>
            <span class="p-2">
              {email}
            {/* {exportHoDetails && exportHoDetails.email !== undefined && exportHoDetails.email !== null ? exportHoDetails.email : ""} */}
            </span>
          </div>
          <div style={{ padding: "13px" }}>
            <b class="text-sm font-sans break-words">Address:</b>
            <span class="p-2">
              {hadress}
            {/* {exportHoDetails && exportHoDetails.addressForCommunication !== undefined && exportHoDetails.addressForCommunication !== null ? exportHoDetails.addressForCommunication : ""} */}
              </span>
          </div>
          <div style={{ padding: "13px" }}>
            <b class="text-sm font-sans break-words">Contact:</b>
            <span class="p-2">
              {contact}
            {/* {exportHoDetails && exportHoDetails.contact !== undefined && exportHoDetails.contact !== null ? exportHoDetails.contact : ""} */}
              </span>
          </div>
          </section>
          </div>  
          <h3>Renter Information</h3>
        <div class="flex">
        <section class="grid md:grid-cols-2 xl:grid-cols-2 gap-3">

        <div style={{ padding: "13px" }}>
            <b class="text-sm font-sans break-words">First Name:</b>
            <span class="p-2">
{renterFirstName|| ""} 
           </span>
          </div>
          <div style={{ padding: "13px" }}>
            <b class="text-sm font-sans break-words">Last Name:</b>
            <span class="p-2">
            {reneterLastName|| ""} 
              </span>
          </div>
          <div style={{ padding: "13px" }}>
            <b class="text-sm font-sans break-words">Email:</b>
            <span class="p-2">
            {renteremail|| ""} 

            </span>
          </div>
          {/* <div style={{ padding: "13px" }}>
            <b class="text-sm font-sans break-words">Address:</b>
            <span class="p-2">
              {hadress || ""}
              </span>
          </div> */}
          <div style={{ padding: "13px" }}>
            <b class="text-sm font-sans break-words">Contact:</b>
            <span class="p-2">
            {renterContact|| ""} 

            </span>
                     </div>
          </section>
          </div>
                   
         
        <h3>Dues Pending Report</h3>
       
        <table style={{ borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    {/* <th style={{ border: "1px solid black" }}>Serial No</th> */}
                    <th style={{ border: "1px solid black" }}>
                      HomeOwner Name
                    </th>
                    <th style={{ border: "1px solid black" }}>Home Address</th>
                    <th style={{ border: "1px solid black" }}>CreatedBy</th>
                    <th style={{ border: "1px solid black" }}>Description</th>

                    <th style={{ border: "1px solid black" }}>Due Type</th>
                    <th style={{ border: "1px solid black" }}>Status</th>
                    {/* <th style={{ border: '1px solid black' }}>CreatedToAddress</th> */}
                    {/* <th style={{ border: '1px solid black' }}>Address</th> */}

                    {/* <th style={{ border: '1px solid black' }}>Status</th> */}
                    <th style={{ border: "1px solid black" }}>Cretaed Date</th>
                    <th style={{ border: "1px solid black" }}>Due Date</th>
                    <th style={{ border: "1px solid black" }}>Last Date</th>

                    <th style={{ border: "1px solid black" }}>Due Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {(dueData ?? [] ?? []).map((item, index) => (
                    <tr key={index}>
                      {/* <td style={{ border: "1px solid black" }}>{index + 1}</td> */}
                      <td style={{ border: "1px solid black" }}>
                        {((item.homeOwnerDetails &&
                          item.homeOwnerDetails.firstName) ||
                          "") +
                          (item.homeOwnerDetails &&
                          item.homeOwnerDetails.firstName &&
                          item.homeOwnerDetails.lastName
                            ? " "
                            : "") +
                          ((item.homeOwnerDetails &&
                            item.homeOwnerDetails.lastName) ||
                            "")}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {(item.homeDetails && item.homeDetails.address) || ""}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {((item.createdByDetails &&
                          item.createdByDetails.firstName) ||
                          "") +
                          (item.createdByDetails &&
                          item.createdByDetails.firstName &&
                          item.createdByDetails.lastName
                            ? " "
                            : "") +
                          ((item.createdByDetails &&
                            item.createdByDetails.lastName) ||
                            "")}
                      </td>
                      <td
                        style={{
                          maxWidth: "200px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          border: "1px solid black",
                        }}
                      >
                        {item.description && item.description.length > 20
                          ? `${item.description.substring(0, 20)}...`
                          : item.description}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {convertToPascalCase(item.dueType)}
                      </td>

                      <td style={{ border: "1px solid black" }}>
                        {convertToPascalCase(item.status)}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {" "}
                        {item.createdDateAndTime && moment(item.createdDateAndTime).isValid() ? moment(item.createdDateAndTime).format("MM/DD/YYYY") : ""}

                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {" "}
                        {item.dueDate && moment(item.dueDate).isValid() ? moment(item.dueDate).format("MM/DD/YYYY") : ""}

                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {" "}
                        {item.endDate && moment(item.endDate).isValid() ? moment(item.endDate).format("MM/DD/YYYY") : ""}

                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {item.dueAmount}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <h3>Dues paid Report</h3>
       
        <table style={{ borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    {/* <th style={{ border: "1px solid black" }}>Serial No</th> */}
                    <th style={{ border: "1px solid black" }}>
                      HomeOwner Name
                    </th>
                    <th style={{ border: "1px solid black" }}>Home Address</th>
                    <th style={{ border: "1px solid black" }}>CreatedBy</th>
                    <th style={{ border: "1px solid black" }}>Description</th>

                    <th style={{ border: "1px solid black" }}>Due Type</th>
                    <th style={{ border: "1px solid black" }}>Status</th>
                    {/* <th style={{ border: '1px solid black' }}>CreatedToAddress</th> */}
                    {/* <th style={{ border: '1px solid black' }}>Address</th> */}

                    {/* <th style={{ border: '1px solid black' }}>Status</th> */}
                    <th style={{ border: "1px solid black" }}>Cretaed Date</th>
                    <th style={{ border: "1px solid black" }}>Due Date</th>
                    <th style={{ border: "1px solid black" }}>Last Date</th>

                    <th style={{ border: "1px solid black" }}>Due Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {(duePaidData ?? [] ?? []).map((item, index) => (
                    <tr key={index}>
                      {/* <td style={{ border: "1px solid black" }}>{index + 1}</td> */}
                      <td style={{ border: "1px solid black" }}>
                        {((item.homeOwnerDetails &&
                          item.homeOwnerDetails.firstName) ||
                          "") +
                          (item.homeOwnerDetails &&
                          item.homeOwnerDetails.firstName &&
                          item.homeOwnerDetails.lastName
                            ? " "
                            : "") +
                          ((item.homeOwnerDetails &&
                            item.homeOwnerDetails.lastName) ||
                            "")}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {(item.homeDetails && item.homeDetails.address) || ""}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {((item.createdByDetails &&
                          item.createdByDetails.firstName) ||
                          "") +
                          (item.createdByDetails &&
                          item.createdByDetails.firstName &&
                          item.createdByDetails.lastName
                            ? " "
                            : "") +
                          ((item.createdByDetails &&
                            item.createdByDetails.lastName) ||
                            "")}
                      </td>
                      <td
                        style={{
                          maxWidth: "200px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          border: "1px solid black",
                        }}
                      >
                        {item.description && item.description.length > 20
                          ? `${item.description.substring(0, 20)}...`
                          : item.description}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {convertToPascalCase(item.dueType)}
                      </td>

                      <td style={{ border: "1px solid black" }}>
                        {convertToPascalCase(item.status)}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {" "}
                        {item.createdDateAndTime && moment(item.createdDateAndTime).isValid() ? moment(item.createdDateAndTime).format("MM/DD/YYYY") : ""}

                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {" "}
                        {item.dueDate && moment(item.dueDate).isValid() ? moment(item.dueDate).format("MM/DD/YYYY") : ""}

                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {" "}
                        {item.endDate && moment(item.endDate).isValid() ? moment(item.endDate).format("MM/DD/YYYY") : ""}

                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {item.dueAmount}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
        <h3>Compliance Request</h3>
       
        <table style={{ borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    {/* <th style={{ border: "1px solid black" }}>Serial No</th> */}
                    <th style={{ border: "1px solid black" }}>Id</th>
                    <th style={{ border: "1px solid black" }}>Description</th>
                    {/* <th style={{ border: '1px solid black' }}>CreatedBy</th> */}
                    {/* <th style={{ border: '1px solid black' }}>CreatedByAddress</th> */}
                    <th style={{ border: "1px solid black" }}>FirstName</th>
                    <th style={{ border: "1px solid black" }}>LastName</th>

                    <th style={{ border: "1px solid black" }}>
                      CreatedToAddress
                    </th>
                    {/* <th style={{ border: '1px solid black' }}>Address</th> */}

                    <th style={{ border: "1px solid black" }}>Status</th>
                    <th style={{ border: "1px solid black" }}>Cretaed Date</th>
                  </tr>
                </thead>
                <tbody>
                  {(homeComplaince ?? []).map((item, index) => (
                    <tr key={index}>
                      {/* <td style={{ border: "1px solid black" }}>{index + 1}</td> */}
                      <td style={{ border: "1px solid black" }}>{item.crId}</td>
                      <td
                        style={{
                          maxWidth: "200px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          border: "1px solid black",
                        }}
                      >
                        {item.description && item.description.length > 20
                          ? `${item.description.substring(0, 20)}...`
                          : item.description??""}
                      </td>
                      {/* <td style={{ border: '1px solid black' }}> {((item.createdByDetails && item.createdByDetails.firstName) || '') +
    ((item.createdByDetails && item.createdByDetails.firstName && item.createdByDetails.lastName) ? ' ' : '') +
    ((item.createdByDetails && item.createdByDetails.lastName) || '')
  }</td> */}
                      <td style={{ border: "1px solid black" }}>
                        {item.createdToDetails?.firstName??""}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                      { item.address?.address ?? ""}

                        {/* {item.createdToDetails?.addressForCommunication ?? item.address?.address ?? ""} */}
                      </td>
                      {/* <td style={{ border: "1px solid black" }}>
                        {(item.homeDetails && item.homeDetails.address) || ""}
                      </td> */}

                      <td style={{ border: "1px solid black" }}>
                        {item.address?.address ?? ""}
                      </td>

                      <td style={{ border: "1px solid black" }}>
                        {convertToPascalCase(item.status)}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {" "}
                        {item.createdDateAndTime && moment(item.createdDateAndTime).isValid() ? moment(item.createdDateAndTime).format("MM/DD/YYYY") : ""}

                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
        <h3>Maintenance Request</h3>
       
        <table style={{ borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    {/* <th style={{ border: "1px solid black" }}>Serial No</th> */}
                    <th style={{ border: "1px solid black" }}>Id</th>
                    <th style={{ border: "1px solid black" }}>Description</th>
                    <th style={{ border: "1px solid black" }}>firstName</th>
                    <th style={{ border: "1px solid black" }}>lastName</th>

                    <th style={{ border: "1px solid black" }}>Address</th>
                    <th style={{ border: "1px solid black" }}>Start Date</th>
                    <th style={{ border: "1px solid black" }}>End Date</th>
                    <th style={{ border: "1px solid black" }}>Status</th>
                    <th style={{ border: "1px solid black" }}>CretaedDate</th>
                  </tr>
                </thead>
                <tbody>
                  {(homeMaintenance ?? []).map((item, index) => (
                    <tr key={index}>
                      {/* <td style={{ border: "1px solid black" }}>{index + 1}</td> */}
                      <td style={{ border: "1px solid black" }}>{item.mrId}</td>
                      <td
                        style={{
                          maxWidth: "200px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          border: "1px solid black",
                        }}
                      >
                        {item.topic && item.topic.length > 20
                          ? `${item.topic.substring(0, 20)}...`
                          : item.topic??""}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {item.createdByDetails?.firstName??""}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {item.createdByDetails?.lastName??""}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {item.homeDetails?.address ?? ""}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {" "}
                       {item.startDate && moment(item.startDate).isValid() ? moment(item.startDate).format("MM/DD/YYYY") : ""}                       </td>
                      <td style={{ border: "1px solid black" }}>
                        {" "}
                        {item.endDate && moment(item.endDate).isValid() ? moment(item.endDate).format("MM/DD/YYYY") : ""}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {convertToPascalCase(item.status)}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {" "}
                        {item.createdDateAndTime && moment(item.createdDateAndTime).isValid() ? moment(item.createdDateAndTime).format("MM/DD/YYYY") : ""}

                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
        </CardContent>
        </CardContent>
      </div>
    </div>
    </div>
   
    
  )
}

export default ArcMcBmHomeActivitynew

