import { Delete, EditNoteOutlined, ListAlt } from "@mui/icons-material";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CustomHeading from "../../components/CustomHeading";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import CustomButton from "../../components/CustomButton";
import { useSelector } from "react-redux";
import {
  BASE_URL,
  CATEGORY,
  COMPLIANCE_REQUEST,
} from "../../utills/ApplicationRouting";
import axios from "axios";
import moment from "moment";
import CustomPascalChange from "../../components/CustomPascalChange";
import CustomLoading from "../../components/CustomLoading";

function ComplianceRequestList() {
  const [open, setOpen] = useState(false);
  const { token, homeId } = useSelector((state) => state.hybridhoa);
  const [complianceRequestDetails, setComplianceRequestDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [status, setStatus] = useState('')

  const handleViewClick = (selectedRow) => {
    navigate(`/compliancerequest/${selectedRow.row.original.complainceId}`);
  };
  const handleAddClick = () => {
    navigate("/compliancerequestadd");
  };
  const handleEditClick = (selectedRow) => {
    navigate(`/compliancerequestedit/${selectedRow.original.complainceId}`);
  };

  const getCategory = async () => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (response.status === 200) {
        const allCategories = response.data;

        // Filter categories based on conditions
        // const filteredCategories = allCategories.filter(category => {
        //   return (
        //     (category.isEventForArc && roles.includes('ROLE_ARC')) ||
        //     (!category.isEventForArc && roles.includes('ROLE_BM'))
        //   );
        // });

        setCategoryDetails(allCategories); // Set the filtered data to the state
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);
  //   const  homeData=()
  // const homeMaps=homeId.map((item,index)=>{
  //      const homeId=item.homeId
  //      console.log("testeyymaop",homeId);
  // })
  const getComplianceRequestDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);


      const response = await axios.get(`${BASE_URL}${COMPLIANCE_REQUEST}?homeId=${homeId}`, {

        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setComplianceRequestDetails(data);
        setIsLoading(false);
      } else {
        setIsLoading(false)
        // throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false)
    }
  };

  useEffect(() => {
    getComplianceRequestDetails();
  }, [homeId]);

  const complianceRequestTable = useMemo((rowData) => [
    {
      accessorKey: "crId",
      header: "Compliance RequestId",
      size: 20,
    },
    {
      accessorKey: "categoryDetails.category",
      header: "Category",
      Cell: ({ row }) => {
        if (row.original.categoryDetails === null || row.original.categoryDetails === undefined) {
          return "N/A";
        } else {
          return row.original.categoryDetails.category || "N/A";
        }
      },
    },
    // {
    //   accessorKey: "description",
    //   header: "Description",
    // },
    {
      accessorKey: "description",
      header: "Description",
      accessorFn: (row) => {
        const description = row && row.description;
        if (description && description.length > 28) {
          return description.slice(0, 28) + "...";
        }
        return description;
      }
    },
    {
      accessorKey: "createdTo.firstName",
      header: "Firstname",
      Cell: ({ row }) =>
        `${row.original.createdToDetails?.firstName || ""}`,
    },
    {
      accessorKey: "createdTo.lastName",
      header: "Lastname",
      Cell: ({ row }) =>
        ` ${row.original.createdToDetails?.lastName || ""}`,
    },
    {
      accessorKey: "address.address",
      header: "Address",
      Cell: ({ row }) =>
        `${row.original.address?.address || ""}`,
    }
    ,

    {
      accessorKey: "status",
      header: "Status",
      Cell: ({ row }) => {
        const status = row.original.status;
        return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
      },
    },
    {
      accessorKey: "createdDateAndTime",
      header: "Created Date",
      accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY"),
    },
  ]);

  const rowActions = (row) => {
    const actions = [
      // {
      //   label: "View",
      //   icon: <ListAlt color="primary" />,
      //   click: () => handleViewClick(row),
      // },
    ];

    if (row.original.status === "PENDING") {
      actions.push({
        label: "Edit",
        icon: <EditNoteOutlined color="primary" />,
        click: () => handleEditClick(row),
      });
    }

    // Check if there are no actions and add "N/A" if true
    // if (actions.length === 0) {
    //   actions.push({
    //     label: "N/A",
    //     icon: null, // You can add an icon if you want, or keep it null
    //     click: () => {}, // No action on click
    //   });
    // }

    return actions;
  };


  const tableActionButtons = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Create"
        click={handleAddClick}
      />
    </>
  );

  return (
    <div>
      {isLoading && <CustomLoading />}
      <CustomHeading title="Compliance Request" />

      <CustomMaterialTable
        columns={complianceRequestTable}
        data={complianceRequestDetails}
        rowActions={rowActions}
        actionButtons={tableActionButtons}
        isLoading={isLoading}
        onRowClick={handleViewClick}
      />
    </div>
  );
}

export default ComplianceRequestList;
