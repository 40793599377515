// export const EMAIL_REGEX_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const EMAIL_REGEX_PATTERN = /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}\s*$/i;
export const FAX_REGEX_PATTERN = /^\+?[0-9]+$/;
export const ONLY_ALPHABETIC = /^[a-zA-Z ]*$/;
export const ONLY_ALPHABETIC_REGEX_PATTERN =/^[a-zA-Z](?:[-_ ]?[a-zA-Z])*$/ ;
export const ALPHABETIC_WITH_SPECIAL_CHARACTERS = /^[a-zA-Z][a-zA-Z0-9#\-_ ]*[a-zA-Z]$/;
export  const ALPHANUMBERIC_ALL_SPECIAL_CHARACTERS = /^[a-zA-Z][a-zA-Z0-9!@#$%^&*()_+={}[\]:;<>,.?\/\\~-]*[a-zA-Z]$/;

export const ONLY_ALPHABETIC_WITH_WHITE_SPACE = /^\s*[a-zA-Z][a-zA-Z ]*[a-zA-Z]\s*$/
export const ALPHANUMERIC_COMMA_PERIOD_PUNCHUCATION_HYPEN = /^[a-zA-Z0-9,.\-\p{P} ]*$/
// export const ALPHABETIC_WITH_UNDERSCORE = /^\s*[a-zA-Z][a-zA-Z_ ]*[a-zA-Z]\s*$/ ;
export const ALPHABETIC_WITH_UNDERSCORE = /^[a-zA-Z](?:[-_ ]?[a-zA-Z])*$/
export const ONLY_DIGITS = /^\d+$/;
export const FIVE_DIGITS_TWO_DECIMAL = /^\d{1,5}(\.\d{0,2})?$/
export const TWO_DIGITS_TWO_DECIMAL = /^\d{1,2}(\.\d{0,2})?$/
export const TWO_DECIMAL = /^\d+(\.\d{1,2})?$/
export const ALL_SENARIO_EMAIL_REGEX = /^(?!\s*$)(?:(?!.*\.{2,})(?!.*@.*@.*)(?!.*\.$)(?!.*\.\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\s*,\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*)$/


export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+:";'.,/?><|`~=])[A-Za-z\d!@#$%^&*()_+:;'.,/?><|"`~=]{8,128}$/;
// ^[a-zA-Z][a-zA-Z-_]*[a-zA-Z]$

export const EMAIL_REGEX_PATTERNS = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i; // Updated pattern

export const AMOUNT_REGEX=/^[1-9][0-9]*$/