import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CustomViewPage from '../../components/CustomViewPage'
import { useSelector } from 'react-redux';
import moment from 'moment';
import CustomLoading from '../../components/CustomLoading';
import { BASE_URL, NOTIFICATION } from '../../utills/ApplicationRouting';
import axios from 'axios';
import CustomViewWithTooltip from '../../components/CustomViewWithTooltip';
import { Button } from '@mui/material';
import CustomViewWithMore from '../../components/CustomViewWithMore';
import CustomViewHeading from '../../components/CustomViewHeading';

function NotificationView() {
  const { token, roles,userLoginDetails, homeId } = useSelector((state) => state.hybridhoa);
  const [notificationData, setNotificationData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { notificationId } = useParams();
    const [firstNameData, setFirstNameData] = useState("");
    const [lastNameData, setLastNameData] = useState("");
    const [createdByDetails,setCreatedByDetails]=useState("");
    const [showFullDescription, setShowFullDescription] = useState(false); 
    const [showFullSubject, setShowFullSubject] = useState(false); 


    const handleClick =() =>{
        navigate(-1)
    }
    const convertToPascalCase = (str) => {
      // Check if the input string is undefined or empty
      if (str === undefined || str === '' || str === null) return '';
    
      // Remove underscores and convert string to Pascal case
      return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
    };
    

    const getNotificationDetailsById = async () => {
      try {
        setIsLoading(true);
        const storedToken = token;
  
        console.log(storedToken);
  
        const response = await axios.get(
          `${BASE_URL}${NOTIFICATION}/${notificationId}?homeId=${homeId}`,
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
        console.log(response.data);
  
        if (response.status === 200) {
          const data = response.data;
          const createdDetails = data.createdByDetails;
  
          setFirstNameData(createdDetails.firstName);
          setLastNameData(createdDetails.lastName);
          setNotificationData(data);
          setCreatedByDetails(data.createdByDetails)
          setIsLoading(false);
        } else {
          setIsLoading(false);
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        // Handle error
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    };
  
    useEffect(() => {
      getNotificationDetailsById();
    }, [notificationId]);
  //   const fullName = firstNameData + " " + lastNameData;
  
  // const data = {
  //   pageTitle:"Notification",
  //   buttons :[
  //     {
  //       buttonTitle:"Back",
  //       onClick : handleClick
  //     },  
     
  //   ],
  //   content: [
  //     {
  //       topic: "Subject",
  //       description: notificationData.subject,
  //     },
  //     {
  //       topic: "Role",
  //       description: notificationData.role == 'ROLE_BM' ? "Board Member" : notificationData.role == 'ROLE_ARC' ? "ARC" :  "Management Company",
  //     },
  //     {
  //       topic: "Created Date",
  //       description: moment(notificationData.createdDateAndTime).format(
  //         "MM/DD/YYYY"
  //       ),
  //     },
  //     {
  //       topic: "Description",
  //       description: notificationData.description,
  //     },
  
    
  //     // ...(roles[0] == "ROLE_HO" ? [{
  //     //   topic: "Status",
  //     //   description: convertToPascalCase(notificationData.status) ,
  //     // }] : []),
  //   ],
  // }
  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const handleToggleSubject = () => {
    setShowFullSubject(!showFullSubject);
  };
  
  const renderDescription = () => {
    console.log("inside render ");
  // Remove HTML tags and replace &nbsp; with spaces
  const cleanDescription = notificationData.description
  ? notificationData.description.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ')
  : '';

if (cleanDescription && !showFullDescription) {
  console.log("inside render ", cleanDescription);

  return (
    <>
      {cleanDescription.slice(0, 250)}
      {cleanDescription.length > 250 && (
        <Button onClick={handleToggleDescription} style={{ fontSize: "12px" }}>Read More</Button>
      )}
    </>
  );
} else {
  console.log("inside render else ", cleanDescription);

  return (
    <>
      {cleanDescription}
      <Button onClick={handleToggleDescription} style={{ fontSize: "12px" }}>Less</Button>
    </>
  );
}
};
  const renderSubject = () => {
    console.log("inside render ");
    if (notificationData.subject && !showFullSubject) {
    console.log("inside render ",notificationData.subject);
  
      return (
        <>
        
          {notificationData.subject.slice(0, 100)}
          {notificationData.subject.length > 100 && (
            <Button onClick={handleToggleSubject} style={{fontSize:"12px"}}>Read More</Button>
          )}
        </>
      );
    } else {
    console.log("inside render else ",notificationData.subject);
  
      return (
        <>
          {notificationData.subject}
          <Button onClick={handleToggleSubject} style={{fontSize:"12px"}}>Less</Button>
        </>
      );
    }
  };
  const data = [
    {
      groupName: 'Notification Details',
      items: [
       
               { label: 'Subject', data: `${notificationData?.subject || ""}` ,text: 'The subject of the notification.'},
               { label: 'Description', data: renderDescription() ,text: 'A detailed description of the notification'},
             
                    

                    { label: 'Created Date', data: `${moment(notificationData.createdDateAndTime).format(
                             "MM/DD/YYYY" )}` ,text: 'The date when the notification was created'},
                            //  { label: 'Status', data: notificationData.status ,text: 'Tooltip text 1'},

                  
      ],
    },
    {
      groupName: ' Created By Details',
      items: [

        { label: 'Role ', data:`${ notificationData.role == 'ROLE_BM' ? "Board Member" : notificationData.role == 'ROLE_ARC' ? "ARC" :  "Management Company"}`,text: 'Role of the  Notification'},

        
      ],
    },
  ];

    return (
      <div>
        {isLoading && <CustomLoading/>}
      {/* <CustomViewWithTooltip data={data} onClick={handleClick}  title={"Notification"} /> */}
      <CustomViewHeading data={data} onClick={handleClick} 
       title={"Notification"} description={(notificationData?.description || "")}/>
      <CustomViewWithMore data={data} />

        {/* <CustomViewPage data = {data} /> */}
           </div>
    )
  }

export default NotificationView