import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CustomViewPage from '../../../components/CustomViewPage'
import CustomHeading from '../../../components/CustomHeading'
import { Card, CardContent, DialogActions, DialogContent, Divider, Typography } from '@mui/material'
import CustomInputField from '../../../components/CustomInputField'
import { COMMENTS_REQUIRED_MESSAGE, MAX_LENGTH_THOUSAND, MIN_LENGTH_VALIDATE_MESSAGE } from '../../../utills/ApplicationConstants'
import CustomButton from '../../../components/CustomButton'
import { useForm } from 'react-hook-form'
import CustomMaterialTable from '../../../components/CustomMaterialTable'
import { useSelector } from 'react-redux'
import { BASE_URL, DOCUMENT, GENERAL_COMMENTS, GET_ALL_GENERAL_COMMENTS } from '../../../utills/ApplicationRouting'
import axios from 'axios'
import moment from 'moment'
import { DeleteOutline, EditNoteOutlined, FileDownloadOutlined } from '@mui/icons-material'
import CustomLoading from '../../../components/CustomLoading'
import { request } from '../../../services/AxiosConfig'
import CustomModel from '../../../components/CustomModel'

function McBmArcArchivedDocumentView() {
    const navigate = useNavigate();
    const { token, roles } = useSelector((state) => state.hybridhoa);
    const {documentId} = useParams();
    const [documentData,setDocumentData] = useState([]);
    const [firstNameData,setFirstNameData] =useState('');
    const [lastNameData,setLastNameData] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [generalCommentDetails, setGeneralCommentDetails] = useState("");
    const [editModes, setEditModes] = useState(Array.isArray(generalCommentDetails) ? Array(generalCommentDetails.length).fill(false) : []);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [generalCommentsData,setGeneralCommentsData] = useState('');
    const [commentsId,setCommentsId] = useState('');
    const [viewData,setViewData] = useState([]);
    const [fileUrl, setFileUrl] = useState("");
    const [createdDateAndTime, setCreatedDateAndTime] = useState("");
    const [generalCommentsDataAdd, setGeneralCommentsDataAdd] = useState("");
    const handleClick =() =>{
        navigate(-1)
    }
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        setError,reset
      } = useForm();
      const handleEditClick = (index, generalCommentsId) => {
        console.log("Edit button clicked", index, generalCommentsId);
        setEditModes((prevModes) =>
          prevModes.map((mode, i) => (i === index ? true : mode))
        );
        setCommentsId(generalCommentsId);
        console.log("CommentsId set:", generalCommentsId);
        getGeneralCommentsById(); 
      };
      
      const handleInputChange = (e) => {
        setGeneralCommentsData(e.target.value);
      };
      
      const handleCancelClick = () => {
        console.log("Cancel");
        setEditModes(false);
      };
      
      const getGeneralComments = async () => {
        try {
          setIsLoading(true);
          const storedToken = token;
      
          console.log(storedToken);
      
          const response = await axios.get(
            `${BASE_URL}${GET_ALL_GENERAL_COMMENTS}/${documentId}`,
            {
              headers: {
                Authorization: `Bearer ${storedToken}`,
              },
            }
          );
          console.log(response.data);
      
          if (response.status === 200) {
            const data = response.data;
            setGeneralCommentDetails(data);
            setEditModes(Array(data.length).fill(false));
            setIsLoading(false);
          } else {
            setIsLoading(false);
            throw new Error("Failed to fetch data");
          }
        } catch (error) {
          // Handle error
          setIsLoading(false);
          console.error("Error fetching data:", error);
        }
      };
      
      useEffect(() => {
        getGeneralComments();
      }, []);
      const onSubmit = (data) => {
       
        if (typeof generalCommentsDataAdd !== 'string' || generalCommentsDataAdd.trim() === '') {
          setError("comments", {
            type: "manual",
            message: COMMENTS_REQUIRED_MESSAGE,
          });
          setIsLoading(false);
          return;
        }
        setIsLoading(true);
        const postData = {
          comments: generalCommentsDataAdd,
          referenceId: documentId,
        };
        request({ url: GENERAL_COMMENTS, method: "post", data: postData })
          .then((res) => {
            console.log(res);
            if (res.status == 200) {
            
              getGeneralComments();
              console.log("Success");
              setIsLoading(false);
              setGeneralCommentsDataAdd("")  

              setTimeout(() => {
              //  navigate("/mcbmarcdocument");
              }, 1000);
              
            }
          })
          .catch((error) => {
            console.log(error.response);
            if (error.response) {
              console.log("error.response.status" + error.response.status);
            }
          });
      };
      
      
      
      const getGeneralCommentsById = async () => {
        try {
          const storedToken = token;
      
          console.log(storedToken);
      
          const response = await axios.get(
            `${BASE_URL}${GENERAL_COMMENTS}/${commentsId}`,
            {
              headers: {
                Authorization: `Bearer ${storedToken}`,
              },
            }
          );
          console.log(response);
      
          if (response.status === 200) {
            const data = response.data;
            console.log(response.data);
           setGeneralCommentsData(data.comments);
          
            setIsLoading(false);
          } else {
            setIsLoading(false);
            throw new Error("Failed to fetch data");
          }
        } catch (error) {
          // Handle error
          setIsLoading(false);
          console.error("Error fetching data:", error);
        }
      };
      
      useEffect(() => {
        getGeneralCommentsById();
      }, [commentsId]);
      
      const submitUpdate = (data) => {
        console.log("Test");
        setIsLoading(true);
        const putData = {
          comments:generalCommentsData,
          referenceId: commentsId,
        };
      
        if (generalCommentsData == '' || generalCommentsData == null) {
          setError("editComments", {
            type: "manual",
            message: COMMENTS_REQUIRED_MESSAGE,
          });
          setIsLoading(false);
          return;
        }
        request({ url:`${GENERAL_COMMENTS}/${commentsId}`, method: "put", data: putData })
          .then((res) => {
            console.log(res);
            if (res.status == 200) {
              console.log("Success");
              setIsLoading(false);
              getGeneralComments();
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error.response);
            if (error.response) {
              console.log("error.response.status" + error.response.status);
            }
          });
      };
      const handleInputChangeAdd = (e) => {
        setGeneralCommentsDataAdd(e.target.value);
      };
      const handleDeleteClickOpen = (generalCommentsId) => {
        setDeleteOpen(true);
      
        setCommentsId(generalCommentsId);
        getGeneralCommentsById(); 
       
      };
      const handleDeleteClose = () => {
        setDeleteOpen(false);
      };
      
      const onSubmitDelete = () => {
        request({url:`${GENERAL_COMMENTS}/${commentsId}`,method:"delete"}).then(res=>{
          console.log(res);
      getGeneralComments();
      
        }).catch(error=>{
      console.log(error);
        });
      };
      
      const getDocumentDetailsById = async () => {
        try {
          setIsLoading(true);
              const storedToken = token
    
          console.log(storedToken);
          
            const response= await axios.get(`${BASE_URL}${DOCUMENT}/${documentId}`,{
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });
                  console.log(response.data); 
    
          if (response.status === 200) {
            const data = response.data;
            const createdDetails = data.createdByDetails
  
            setFirstNameData(createdDetails.firstName);
            setLastNameData(createdDetails.lastName);
            setViewData(data.reviewComments);
            setFileUrl(data.fileUrl);
            const formattedDate = moment(data.createdDateAndTime).format("MM/DD/YYYY hh:mm A");
            setCreatedDateAndTime(formattedDate)
            setDocumentData(data);
            setIsLoading(false);
    
          } else {
            setIsLoading(false);
            throw new Error('Failed to fetch data');
          }
         
        } catch (error) {
          // Handle error
          setIsLoading(false);
          console.error("Error fetching data:", error);
        }
      };
    
    
    
    useEffect(()=>{
      getDocumentDetailsById();
    },[])

    const okCancelButtonActions = (
      <>
        <CustomButton
          fieldType="submit"
          buttonName="Ok"
          click={handleDeleteClose}
        />
        <CustomButton
          fieldType="button"
          buttonName="Cancel"
          click={handleDeleteClose}
        />
      </>
    );
    
    const deleteModel = (
      <form
        onSubmit={handleSubmit(onSubmitDelete)}
        className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography> Are you sure you want to delete comment?</Typography>
        </DialogContent>
        <DialogActions>{okCancelButtonActions}</DialogActions>
      </form>
    );

    const fullName = firstNameData + " " + lastNameData;

    const convertToPascalCase = (str) => {
      // Check if the input string is undefined or empty
      if (str === undefined || str === '' || str === null) return '';
    
      // Remove underscores and convert string to Pascal case
      return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
    };
    
    const data = {
      pageTitle: "Review Document",
      buttons: [
        {
          buttonTitle: "Back",
          onClick: handleClick,
        },
      ],
      content: [
        {
          topic: "Created By",
          description: fullName,
        },
        {
          topic: "Category",
          // description: documentData.category,
          description :convertToPascalCase(documentData.category)
        },
  
        {
          topic: "Created Date Time",
          description: moment(documentData.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
        },
        {
          topic: "Audit Date Time",
          description:  moment(documentData.auditDateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
        },
        {
          topic: "Publish Date",
          description: documentData.publishDate,
        },
        {
          topic: "Status",
          // description: documentData.status,
          description :convertToPascalCase(documentData.status)
        },
        {
          topic: "Is Hidden",
          description: documentData.isHidden ? 'Yes':'No',
        },
        {
          topic: "Description",
          description: documentData.description,
        },
        {
          topic: "Attachments",
          component: "AttachmentList", 
          props: {
            fileUrl: fileUrl,
            documentName: fileUrl,
            docname: fileUrl,
            uploadedTimes: createdDateAndTime,
          },
        },
       
      ],
    };


    const viewDataTable = useMemo((rowData) => [

      {
        accessorKey: "createdDateAndTime",
        header: "Assigned DateTime",
        size: 20,
        Cell: ({ value }) => {
          const formattedDate = moment(value).format("MM/DD/YYYY");
          return formattedDate;
        },
      },
      {
        accessorKey: "name",
        header: "Assigned To",
        size: 20,
      },
      {
        accessorKey: "status",
        header: "Review OutCome",
        size: 20,
      },
      {
        accessorKey: "auditDateAndTime",
        header: "Review DateTime",
        size: 20,
        Cell: ({ value }) => {
          const formattedDate = moment(value).format("MM/DD/YYYY");
          return formattedDate;
        },
      },
      {
        accessorKey: "comments",
        header: "Review Comments",
        size: 20,
      },
    ]);
    return (
      <div>
        {isLoading && <CustomLoading/>}
        <CustomViewPage data = {data} />
<br/>
<br/>
       <CustomMaterialTable
          columns={viewDataTable}
          data={viewData}
          />
          <br/>
          <br/>

        <CustomHeading title="General Comments"/>
        <CustomModel
        title=" Delete Comments"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        reset ={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      />
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
            <CustomInputField
              fieldName="comments"
              // requiredErrorMessage={COMMENTS_REQUIRED_MESSAGE}
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldId="comments"
              fieldType="text"
              fieldLabel="Write a Comments"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={3}
              value={generalCommentsDataAdd}
              onInput={handleInputChangeAdd}
            />

            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Post" />
              {isLoading && <CustomLoading />}
            </div>
          </CardContent>
        </form>
        <CardContent>
          {generalCommentDetails &&
            generalCommentDetails.map((comment, index) => (
              <div key={comment.generalCommentsId}>
                <section className="grid md:grid-cols-1 xl:grid-cols-1 gap-3 mt-6 ml-16">
                  <p>
                    <span className="text-primary" style={{ float: "left" }}>
                      @ HomeRequest Four{" "}
                    </span>
                    <span style={{ float: "right" }}>
                      {moment(comment.createdDateAndTime).format(
                        "MM/DD/YYYY HH:mm:ss"
                      )}

                      {!editModes[index] && (
                        <>
                          {index === 0 && (
                            <>
                            
                                <EditNoteOutlined
                                color="primary"  onClick={() => {
                                    handleEditClick(index, comment.generalCommentsId)
                                    console.log(comment.generalCommentsId)
                                }
                                  }/>
                                <DeleteOutline color="primary" onClick={() => {
                                    handleDeleteClickOpen( comment.generalCommentsId)
                                    console.log(comment.generalCommentsId)
                                }
                                  }/>
                          
                            </>
                          )}
                        </>
                      )}
                    </span>
                    {editModes[index]? (
                    <form
                    onSubmit={handleSubmit(submitUpdate)}
                    className="space-y-4 md:space-y-6 "
                  >
                    <CardContent>
                      <CustomInputField
                        fieldName="editComments"
                        // requiredErrorMessage={COMMENTS_REQUIRED_MESSAGE}
                        minLength={2}
                        minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                        maxLength={1000}
                        maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
                        fieldId="editComments"
                        fieldType="text"
                        // fieldLabel="Write a Comments"
                        fieldControl={control}
                        fieldError={errors}
                        // fieldRequiredIcon="*"
                        multiline={true}
                        numberOfRows={6}
                        value={generalCommentsData}
                        onInput={handleInputChange}
                      />
    
                      <div class="text-center mt-8 space-x-1">
                        
                        <CustomButton fieldType="submit" buttonName="Save" />

                        <CustomButton fieldType="button" buttonName="Cancel"  click={handleCancelClick}/>
                        {isLoading && <CustomLoading />}
                      </div>
                    </CardContent>
                  </form>
                  ) : (
                    <p className="mt-8 mb-8">{comment.comments}</p>
                  )}
                  </p>

                 
                </section>
        
                <Divider />
              </div>
            ))}
        </CardContent>
      </Card>
    </div>

    )
  }

export default McBmArcArchivedDocumentView