import {ListAlt } from "@mui/icons-material";
import React, { useMemo, useState } from "react";
import {  useNavigate } from "react-router-dom";
import CustomHeading from "../../../components/CustomHeading";
import CustomMaterialTable from "../../../components/CustomMaterialTable";


function BmRaisedByArcList() {
    const [open, setOpen] = useState(false);
     
    const navigate = useNavigate();
 
   const handleViewClick = () => {
     navigate("/compliancerequestview");
   };
   
   const data = [
     {
       complianceRequestId: 'CR_202311219485',
       description: "testing one",
       category: 'parking',
       status: "Pending",
       createdDate: "10/06/2023",
       isFineApplicable:true,
     },
     {
       complianceRequestId: 'CR_202311219485',
       description: "testing one",
       category: 'parking',
       status: "Pending",
       createdDate: "10/06/2023",
       isFineApplicable:false,
     },
     {
       complianceRequestId: 'CR_202311219485',
       description: "testing one",
       category: 'parking',
       status: "Pending",
       createdDate: "10/06/2023",
       isFineApplicable:false,
     },
     {
       complianceRequestId: 'CR_202311219485',
       description: "testing one",
       category: 'parking',
       status: "Pending",
       createdDate: "10/06/2023",
       isFineApplicable:false,
     },
     {
       complianceRequestId: 'CR_202311219485',
       description: "testing one",
       category: 'parking',
       status: "Closed",
       createdDate: "10/06/2023",
       isFineApplicable:false,
     },
   ];
 
   const complianceRequestTable = useMemo((rowData) => [
     {
       accessorKey: "complianceRequestId",
       header: "Compliance RequestId",
       size: 20,
     },
     {
       accessorKey: "description",
       header: "Description",
       size: 20,
     },
     {
       accessorKey: "category",
       header: "Category",
       size: 20,
    
     },
     {
       accessorKey: "status",
       header: "Status",
       size: 20,
     },
     {
       accessorKey: "createdDate",
       header: "Created Date",
       accessorFn: (row) => moment(row.crCreatedDate || row.createdDateAndTime).format("MM/DD/YYYY"),
       size: 20,
     },
     {
       accessorKey: "isFineApplicable",
       accessorFn: (row) => (row && row.isFineApplicable ? "Yes" : "No"),
       header: "Is Fine Applicabable",
       size: 20,
     },
   ]);

//  const rowActions = [
//   {
//     label: "View",
//     icon: <ListAlt color="primary" />,
//     click: handleViewClick,
//   },
//   ...(data.status === 'Pending' ? 
//     [{
//       label: "Edit",
//       icon: <EditNoteOutlined color="primary" />,
//       click: handleEditClick,
//     }] : []
//   )
// ];

const rowActions = [
 {
   label: "View",
   icon: <ListAlt color="primary" />,
   click: handleViewClick,
 },
 
];

  
 
  
   return (
     <div>
       <CustomHeading title="Compliance Request By ARC" />
 
      
       <CustomMaterialTable
         columns={complianceRequestTable}
         data={data}
         rowActions={rowActions}
       />
     </div>
   );
 }

export default BmRaisedByArcList