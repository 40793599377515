import React, { useEffect, useMemo, useState } from "react";
import CustomHeading from "../../components/CustomHeading";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import CustomModel from "../../components/CustomModel";
import {
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import CustomInputField from "../../components/CustomInputField";
import {
  CATEGORY_REQUIRED_MESSAGE,
  DESCRIPTION_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
} from "../../utills/ApplicationConstants";
import { Controller, useForm } from "react-hook-form";
import { FileUploader } from "react-drag-drop-files";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL, INVOICE } from "../../utills/ApplicationRouting";
import { request } from "../../services/AxiosConfig";
import CustomToastContainer from '../../components/CustomToastContainer';
import CustomFile from "../../components/CustomFile";
import {
  CancelOutlined,
  DeleteOutline,
  EditNoteOutlined,
  ListAlt,
} from "@mui/icons-material";
import CustomLoading from "../../components/CustomLoading";

function InvoiceList() {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const fileTypes = [
    "JPG",
    "PNG",
    "JPEG",
    "PDF",
    "DOC",
    "XLS",
    "XLSX",
    "TIF",
    "TIFF",
    "DOCX",
  ];
  const navigate = useNavigate();
  const { token, roles, userLoginDetails } = useSelector((state) => state.hybridhoa);
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [firstNameData, setFirstNameData] = useState("");
  const [lastNameData, setLastNameData] = useState("");
  const [categoryChange, setCategoryChange] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchCreatedBy, setSearchCreatedBy] = useState(""); // State to store search input for Created By field
  const [fileError, setFileError] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [createdBy,setCreatedBy] = useState("");
 

  // console.log("roles",roles);

  const handleViewClick = (selectedRow) => {
    console.log("selectedRow",selectedRow);
    navigate(`/invoice/${selectedRow.original.invoiceId}`);
  };
  const handleEditClick = (selectedRow) => {
    navigate(`/invoiceEdit/${selectedRow.original.invoiceId}`);
  };

  const handleFileChange = (file) => {
    console.log(file);
    setFile(file);
  };
  
  const handleAddClickOpen = () => {
    navigate('/invoiceUpload');
  };
  const handleAddClose = (status) => {
    if (status === 200) {
      setOpen(false);
      setSelectedCategory('');
      setFile('');
    }
  };
  const handleIconClose = () => {

    setOpen(false);
    setSelectedCategory('');
    setFile('');
  };

 useEffect(() =>{
  console.log("userLoginDetails",userLoginDetails.homeOwnerLoginId);
 })

  const {
    handleSubmit,
    control,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  

  const handleDeleteClickOpen = (selectedRow) => {
    console.log(selectedRow);
    setDeleteOpen(true);
    console.log(selectedRow.eventId);
    setSelectedRow(selectedRow);
  };
  
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  

 

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    clearErrors("category");
  };

  const onSubmit = (data) => {
    const formData = new FormData();

    formData.append("description", data.description);
    formData.append("file", file);
    formData.append("category", selectedCategory);
    formData.append("role",roles);

    console.log("description", data.description);
    console.log("category", selectedCategory);
    console.log("file:", file);
    if (!selectedCategory) {
      setError("category", {
        type: "manual",
        message: CATEGORY_REQUIRED_MESSAGE,
      });
      return;
    }
    // return;
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    request({ url: INVOICE, method: "post", data: formData, headers })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");
          getInvoiceDetails();
          setIsLoading(false);
          handleAddClose(res.status);
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };
 
  const getInvoiceDetails = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${INVOICE}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        console.log("data",data);
// console.log(data[0].createdBy,"createdby details");
        setInvoiceDetails(data);
        setIsLoading(false);
        const homeownerLoginIds = data.map(invoice => invoice.createdBy);
        console.log(homeownerLoginIds, "homeowner login IDs");
        setCreatedBy(homeownerLoginIds)
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
    
  };

  useEffect(() => {
    getInvoiceDetails();
  }, []);
 
  const onSubmitDelete = () => {
    setIsLoading(true);
    const invoiceId = selectedRow.invoiceId; 
    console.log(selectedRow.invoiceId);
  
    request({url:`${INVOICE}/${invoiceId}`,method:"delete"}).then(res=>{
      console.log(res);
      getInvoiceDetails();
      setIsLoading(false);
    }).catch(error=>{
      setIsLoading(false);
console.log(error);
    });
  };
  const eventTable = useMemo((rowData) => [
    {
      accessorKey: "invId",
      header: "Invoice Id",
      size: 20,
    },
    // {
    //   accessorKey: "createdBy",
    //   header: "Created By",
    //   Cell: ({ row }) =>
    //     `${row.original.createdByDetails?.firstName} ${row.original.createdByDetails?.lastName}`,
    // },
    {
      accessorKey: "category",
      header: "Invoice Type",
    },
    // {
    //   accessorKey: "description",
    //   header: "Description",
    // },
    {
      accessorKey:"description",
      header:"Description",
      accessorFn: (row) => {
        const description = row && row.description;
        if (description && description.length > 28) {
          return description.slice(0, 28) + "...";
        }
        return description;
      }
    },
    {
      accessorKey: "createdBy",
      // header: "Created By",
      // header: "Uploaded By",
      header: "Name ",


      accessorFn: (row ) =>
      `${row.createdByDetails?.firstName??""} ${row.createdByDetails?.lastName??""}`,
      
    },
    
   
    {
      accessorKey: "status",
      header: "Status",
      Cell: ({ row }) => {
        const status = row.original.status;
        return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
      },
    },
  ]);

  // const rowActions = (row) => {
  //   const actions = [
  //   // {
  //   //   label: "View",
  //   //   icon: <ListAlt color="primary" />,
  //   //   click: () => handleViewClick(row),
  //   // },
  //   // {
  //   //   label: "Edit",
  //   //   icon: <ListAlt color="primary" />,
  //   //   click: (row) => handleEditClick(row),
  //   // },
  //   ];
  //   if (row.original.createdBy == userLoginDetails.homeOwnerLoginId  && row.original.role === roles.join(', ')) {
  //     actions.push({
  //       label: "Edit",
  //       icon: <EditNoteOutlined color="primary" />,

  //     click: () => handleEditClick(row),


  //     });
  //   }
  //   if (row.original.createdBy == userLoginDetails.homeOwnerLoginId && row.original.role === roles.join(', ')) {
  //       actions.push({
  //         label: "Delete",
  //         icon: <DeleteOutline color="error" />,
  //         click: () => handleDeleteClickOpen(row.original),
  //       });
  //     }
  //   return actions;

  //   }
  const rowActions = (row) => {
    const actions = [];

    // Check if the row is not approved or rejected
    
        // Add Edit action if the createdBy and role conditions are met
        if (row.original.createdBy == userLoginDetails.homeOwnerLoginId && row.original.role === roles.join(', ') && row.original.status !== "APPROVED" && row.original.status !== "REJECTED") {
            actions.push({
                label: "Edit",
                icon: <EditNoteOutlined color="primary" />,
                click: () => handleEditClick(row),
            });
        

        // Add Delete action if the createdBy and role conditions are met
        if (row.original.createdBy == userLoginDetails.homeOwnerLoginId && row.original.role === roles.join(', ')) {
            actions.push({
                label: "Delete",
                icon: <DeleteOutline color="error" />,
                click: () => handleDeleteClickOpen(row.original),
            });
        }
    }

    return actions;
}


    const okCancelAction = (
      <>
        <CustomButton
          fieldType="button"
          buttonName="Cancel"
          click={handleDeleteClose}
        />
      <CustomButton
          fieldType="submit"
          buttonName="Ok"
          click={handleDeleteClose}
        />
        
      </>
    )
  

    const deleteModel = (
      <>
         <form
              onSubmit={handleSubmit(onSubmitDelete)}
              className="space-y-4 md:space-y-6 "
            >
              <DialogContent dividers>
                <Typography>
                  Are you sure you want to delete this invoice ?
                </Typography>
              </DialogContent>
              <DialogActions>
              {okCancelAction}
              </DialogActions>
            </form>
      </>
    )
  
  
  const tableActionButtons = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Upload"
        click={handleAddClickOpen}
      />
    </>
  );
  const handleViewClicks = (selectedRow)=>{
    navigate (`/invoice/${selectedRow.row.original.invoiceId}`);
}


  const addActionButton = (
    <CustomButton fieldType="submit" buttonName="Upload" click={handleAddClose} />
  );
  // const addModel = (
  //   <>
  //     <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 md:space-y-6 ">
  //       <DialogContent dividers>
          
  //         <Typography gutterBottom>
  //           <CustomInputField
  //             fieldName="description"
  //             requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
  //             fieldId="description"
  //             minLength={2}
  //             minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
  //             maxLength={5000}
  //             maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
  //             fieldLabel="Description"
  //             fieldControl={control}
  //             fieldError={errors}
  //             fieldRequiredIcon="*"
  //             multiline={true}
  //             numberOfRows={2}
  //           />


  //           <br />
  //           <br />

  //           <Controller
  //             name="category"
  //             control={control}
  //             render={({ field, fieldState }) => (
  //               <FormControl variant="standard" fullWidth error={fieldState.invalid}>
  //                 <InputLabel id="demo-simple-select-standard-label">
  //                   Please Select Category <span style={{ color: "red" }}>*</span>
  //                 </InputLabel>
  //                 <Select
  //                   labelId="demo-simple-select-standard-label"
  //                   id="demo-simple-select-standard"
  //                   value={selectedCategory}
  //                   onChange={handleCategoryChange}
  //                   label="Please Select Category"
  //                 >
  //                   <MenuItem value="Purchases">Purchases</MenuItem>
  //                   <MenuItem value="Sales">Sales</MenuItem>
  //                   <MenuItem value="Suppliers">Suppliers</MenuItem>
  //                   <MenuItem value="Expenses">Expenses</MenuItem>
  //                 </Select>

  //                 <FormHelperText style={{ color: "#D32F2F" }}>
  //                   {fieldState.invalid ? fieldState.error?.message : ""}
  //                 </FormHelperText>
  //               </FormControl>
  //             )}
  //           />
  //           <br />
  //           <br />

  //           {/* <FormLabel>
  //             Upload <span style={{ color: "red" }}>*</span>
  //             <FileUploader
  //               handleChange={handleFileChange}
  //               name="file"
  //               types={fileTypes}
  //               required
  //             />
  //             {file ? (
  //               <p>Selected File: {file.name}</p>
  //             ) : (
  //               <p>No file selected</p>
  //             )}
  //           </FormLabel> */}
  //           <CustomFile file={file} handleFileChange={handleFileChange} token={token} fileError={fileError} required isRequired={true}/>
  //         </Typography>
  //       </DialogContent>
  //       <DialogActions>{addActionButton}</DialogActions>
  //     </form>
  //   </>
  // );



  return (
    <div>
       {isLoading && <CustomLoading />}
      <CustomHeading title="Invoice" />
      <CustomToastContainer/>
  
      <CustomMaterialTable
        columns={eventTable}
        data={invoiceDetails}
        rowActions={rowActions}
        actionButtons={tableActionButtons}
        // enableRowActions={createdBy === userLoginDetails.homeOwnerLoginId ? true : false}
        enableRowActions = {true}


        // isLoading={isLoading}
        onRowClick = {handleViewClicks}
        // globalSearch={true} // Enable global search
        // searchOptions={[ // Add custom search options for specific fields
        //   { accessorKey: "createdBy", label: "Created By" },
        //   { accessorKey: "category", label: "Invoice Type" },
        //   { accessorKey: "description", label: "Description" },
        //   { accessorKey: "status", label: "Status" },
        // ]}
        // searchText={searchCreatedBy} // Pass search input for Created By field
        // onSearchTextChange={(searchText) => setSearchCreatedBy(searchText)} // Update search input for Created By field
      />
      {/* <CustomModel
        title="Upload Invoice"
        submit={onSubmit}
        content={addModel}
        action={addActionButton}
        openStatus={open}
        closeStatus={handleAddClose}
        iconCloseStatus={handleIconClose}
        reset={reset}
      /> */}

<CustomModel 

title = "Delete " 
submit = {onSubmit}
content = {deleteModel}
action ={okCancelAction}
openStatus = {deleteOpen}
closeStatus={handleDeleteClose}
iconCloseStatus={handleDeleteClose}
modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
reset={reset}
isLoading={isLoading}

/>
    </div>
  );
}

export default InvoiceList;
