import { DatePicker, LocalizationProvider ,MobileDatePicker} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import { InfoOutlined } from "@mui/icons-material";
import { TextField, Tooltip, IconButton, Typography } from "@mui/material";


function CustomPublishDatePicker(props) {
  const CustomInputLabel = ({ label, requiredIcon }) => (
    <label>
      {label}
      {requiredIcon && <span style={{ color: "red" }}> {requiredIcon} </span>}
    </label>
  );

  const formattedValue = props.value ? dayjs(props.value) : null;

  // Validation function to check if the selected date is in the past
  const validateDate = (selectedDate) => {
    if (!selectedDate) return props.pastDateErrorMessage; // Handle case where selectedDate is undefined
    const today = dayjs().startOf('day'); // Start of today
    const selectedDay = selectedDate.startOf('day'); // Start of selected date
    return selectedDay.isAfter(today) || selectedDay.isSame(today)
      ? undefined // Return undefined if date is today or in the future
      : props.pastDateErrorMessage; // Return error message if date is in the past
  };

  return (
    <div className="flex items-center md:w-1/2 w-full">
    <Controller
      control={props.control}
      name={props.fieldName}
      rules={{
        required: props.requiredErrorMessage,
        validate: {
          pastDate: validateDate // Use the validate prop here
        }
      }}
      render={({ field, fieldState }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            {...field}
            views={["year", "month", "day"]}
            minDate={props.minDate}
            maxDate={props.maxDate}
            disabled={props.disabled}
            value={props.value}
            onChange={(date) => {
              field.onChange(date);
              console.log("Selected Date:", date);
              props.onChange && props.onChange(date);
            }}
            slotProps={{
              textField: {
                variant: "standard",
                label: (
                  <CustomInputLabel
                    label={props.label}
                    requiredIcon={props.fieldRequiredIcon}
                  />
                ),

                error: fieldState.invalid,
                helperText: fieldState.invalid ? props.errorMessage || fieldState.error?.message : "",
              },
              input: {
                readOnly: true, // Prevent typing into the input field
              },
            }}
          />
        </LocalizationProvider>
      )}
    />
     {props.tooltipMessage && (
                    <Tooltip
                      title={
                        <Typography sx={{ fontSize: "14px" }}>{props.tooltipMessage}</Typography>
                      }
                      placement="right"
                      arrow
                    >
                      <IconButton size="small">
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
  );
}

export default CustomPublishDatePicker;
