import React, { useEffect, useState } from "react";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../../../components/CustomInputField";

import {  MAX_LENGTH_FIVE_THOUSAND, MAX_LENGTH_THOUSAND, MIN_LENGTH_VALIDATE_MESSAGE, WHAT_NEED_ATTENTION_REQUIRED_MESSAGE,  EXTERNAL_OCCUPATION_REQUIRED_MESSAGE, EXTERNAL_USER_REQUIRED_MESSAGE } from "../../../utills/ApplicationConstants";
import {
  Autocomplete,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,

} from "@mui/material";
// ADD_MAINTENANCE, BASE_URL, BYLAW, CATEGORY, HOMES
import CustomHeading from "../../../components/CustomHeading";
import { FileUploader } from "react-drag-drop-files";
// import CustomRadioButton from "../../../components/CustomRadioButton";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL, ADD_MAINTENANCE, EXTERNAL_OCCUPATION, EXTERNAL_USER } from "../../../utills/ApplicationRouting";
import { request } from "../../../services/AxiosConfig";
// import CustomAutoComplete from "../../../components/CustomAutoComplete";
import CustomToastContainer from "../../../components/CustomToastContainer";
import CustomLoading from "../../../components/CustomLoading";
import CustomTooltipField from "../../../components/CustomTooltipField";
import { InfoOutlined } from "@mui/icons-material";
import CustomFile from "../../../components/CustomFile";

function MaintenanceRequestAdd() {
  const [file, setFile] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const { token } = useSelector(state => state.hybridhoa);
  const [isUrgent, setIsUrgent] = useState(false)
  const [isSafety, setIsSafety] = useState(false)
  const [externalUser, setExternalUser] = useState([]);
  const [externalUserName, setExternalUserName] = useState([]);
  const [isJobCategorySelected, setIsJobCategorySelected] = useState(false);
  const [selectedExternalUser, setSelectedExternalUser] = useState(null);

  const fileTypes = ["JPG", "PNG", "JPEG", "PDF"];
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue, setError, clearErrors, watch,
  } = useForm();
  const externalOccupation = watch("externalOccupation");
  const handleViewClick = () => {
    navigate(-1);
  };

  const handleFileChange = (file) => {
    setFile(file);
  };

  const getCategory = async () => {
    try {
      const storedToken = token;


      const response = await axios.get(`${BASE_URL}${EXTERNAL_OCCUPATION}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setCategoryDetails(data); // Set the fetched data to the state
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error('Failed to fetch data');
      }

    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);


  const getExternalUser = async () => {
    try {
      const storedToken = token;


      const response = await axios.get(`${BASE_URL}${EXTERNAL_USER}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setExternalUser(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error('Failed to fetch data');
      }

    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getExternalUser();
  }, []);



  const handleCategoryChange = (event, newValue) => {
    if (newValue) {
      const selectedCategoryId = newValue.value;
      setValue("externalOccupation", selectedCategoryId);
      // console.log(newValue, "newvalue");
      const matchingUsers = externalUser.filter(user => user.loginData.externalOccupationId === selectedCategoryId);
      const exterUserName = matchingUsers.map((user, index) => ({
        category: `${user.userDetails.firstName} ${user.userDetails.lastName}`,
        value: user.loginData.homeOwnerLoginId,
        key: index

      }));
     
      setExternalUserName(exterUserName)

      setIsJobCategorySelected(true);


    } else {
      // console.log("false");
      // console.log(externalUserName);
      setExternalUserName([]);
      // setExternalUserName([]); 
      setValue("externalOccupation", null);
      setValue("externalUser", null); 
      setSelectedExternalUser(null); 
      setIsJobCategorySelected(false);
    }
  };
  // Use useEffect to clear externalUser when externalOccupation changes
  useEffect(() => {
    setValue("externalUser", null);
    setSelectedExternalUser(null);
  }, [externalOccupation, setValue]);



  const renderedCategory = categoryDetails.map((category, index) => ({
    category: category.occupation,
    value: category.externalOccupationId,
    key: index,
  }));




  const onSubmit = (data) => {

    const totalFileSize = file.reduce((acc, file) => acc + file.size, 0);
    const maxSizeInMB = 10;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
  
    if (totalFileSize > maxSizeInBytes) {
      // setFileError(`Total file size should not exceed ${maxSizeInMB}MB.`);
      return;
    }
  
    // Check file types
    const allowedFileTypes = ["image/jpg", "image/png", "image/jpeg", "application/pdf"];
    const invalidFiles = file.filter(file => !allowedFileTypes.includes(file.type));
  
    if (invalidFiles.length > 0) {
      // setFileError("Some files have invalid file types. Allowed types are JPG, PNG, JPEG, PDF.");
      return;
    }



    const formData = new FormData();
    formData.append('topic', data.whatneedsattention);
              // ## code by Ranjith by the suggestion of gopal ## /

    formData.append('description',  data.moreInformation ? data.moreInformation : " ");
              // ## code by Ranjith by the suggestion of gopal ## /

    file.forEach((file) => {
      formData.append('files', file);
      });
    formData.append('isUrgent', isUrgent ? 'true' : 'false');
    formData.append('isSafty', isSafety ? 'true' : 'false');
    formData.append('role', 'ROLE_GU');
    formData.append('jobId', data.externalOccupation);
    formData.append('externalUserId', data.externalUser.value);



    // console.log('topic', data.whatneedsattention);
    // console.log('description', data.moreInformation);
    // console.log('file', file);
    // console.log('role', 'ROLE_BM');
    // console.log('isUrgent', isUrgent ? 'true' : 'false');
    // console.log('isSafty', isSafety ? 'true' : 'false');
    // console.log('jobId', data.externalOccupation);
    // console.log('externalUserId', data.externalUser.value);


    // console.log('formData:', formData);


    const headers = {
      'Content-Type': 'multipart/form-data'
    };
    // return;

    request({ url: ADD_MAINTENANCE, method: "post", data: formData, headers }).then(res => {
      setIsLoading(false);
      console.log(res);
      if (res.status == 200) {

        setIsLoading(false);
        setTimeout(()=>{
          navigate(-1)
        },2000)

      }
    }).catch(error => {
      console.log(error.response);
      setIsLoading(false);
      if (error.response) {

        console.log("error.response.status" + error.response.status);


      }
    });
  };

  const renderTooltip = (message) => (
    <Tooltip title={<Typography sx={{ fontSize: "14px" }}>{message}</Typography>} placement="right">
    <IconButton>
    <InfoOutlined />
    </IconButton>
    </Tooltip>
    );

  return (
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomHeading title="Add Maintenance" />

        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleViewClick}
          />
        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">

              <CustomTooltipField
                fieldName="whatneedsattention"
                requiredErrorMessage={WHAT_NEED_ATTENTION_REQUIRED_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={1000}
                maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
                fieldId="whatneedsattention"
                fieldType="text"
                fieldLabel="What needs attention?"
                fieldControl={control}
                fieldError={errors}
                fieldRequiredIcon="*"
                multiline={true}
                numberOfRows={1}
                tooltipMessage={"Describe the issue or area that requires attention."}
              />
              <CustomTooltipField
                fieldName="moreInformation"
                // requiredErrorMessage={MORE_INFORMATION_REQUIRED_MESSAGE}
                minLength={2}
                minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                maxLength={5000}
                maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
                fieldId="moreInformation"
                fieldType="text"
                fieldLabel="More Information"
                fieldControl={control}
                fieldError={errors}
                multiline={true}
                numberOfRows={1}
                tooltipMessage={"Provide additional details or information about the issue."}
              />
              <div className="flex items-center md:w-1/2 ">
  <div className="w-full ">
              <FormControlLabel control={<Switch checked={isUrgent} onChange={(event) => setIsUrgent(event.target.checked)} />} label="Is this issue urgent? " >
 </FormControlLabel>     </div>         <div>{renderTooltip("Check this if the issue requires immediate attention.")}</div></div>
 <div className="flex items-center md:w-1/2 ">
  <div className="w-full ">
              <FormControlLabel control={<Switch checked={isSafety} onChange={(event) => setIsSafety(event.target.checked)} />} label="Is this issue actively causing property damage or a threat to personal safety? " > </FormControlLabel>
              </div>         <div>{renderTooltip("Is this issue actively causing property damage or a threat to personal safety?")}</div></div>
              <div className="flex items-center md:w-1/2 ">
  <div className="w-full ">
              <Controller
                name="externalOccupation"
                control={control}
                rules={{ required: EXTERNAL_OCCUPATION_REQUIRED_MESSAGE }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={Boolean(error)}>
                    <Autocomplete
                      fullWidth
                      {...field}
                      id="combo-box-demo"
                      options={renderedCategory || []}
                      getOptionLabel={(option) => option.category}
                      onChange={handleCategoryChange}

                      // Enable freeSolo to allow manual input

                      // Validate manually entered value
                      onInputChange={(event, newInputValue) => {
                        if (!newInputValue.trim()) {
                          setError("externalOccupation", {
                            type: "required",
                            message: "Job-Category is required",
                          });
                        } else if (!renderedCategory.some((option) => option.category === newInputValue)) {
                          setError("externalOccupation", {
                            type: "manualInputError",
                            message: "Invalid Job-Category. Please select from the provided options.",

                          });
                        } else {
                          clearErrors("externalOccupation");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span>
                              Select Job-Category<span style={{ color: "red" }}> * </span>
                            </span>
                          }
                          variant="standard"
                          error={Boolean(error) && !field.value}
                          helperText={
                            Boolean(error) ? error.message : ""
                          }
                        />
                      )}
                    />
                  </FormControl>
                )}
              />
              </div>
    <div>{renderTooltip("Select the job category from the dropdown or type to search.")}</div>
  </div>

<div className="flex items-center md:w-1/2 ">
  <div className="w-full ">
              <Controller
                name="externalUser"
                control={control}
                rules={{ required: EXTERNAL_USER_REQUIRED_MESSAGE }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={Boolean(error)}>
                    <Autocomplete
                      fullWidth
                      {...field}
                      id="combo-box-demo"
                      options={externalUserName || ''}
                      getOptionLabel={(option) => option.category}
                      value={selectedExternalUser}
                      disabled={!isJobCategorySelected}
                      defaultValue={null}
                      onChange={(event, newValue) => {
                        setValue("externalUser", newValue, { shouldDirty: true });
                        setSelectedExternalUser(newValue);
                      }}
                      // Enable freeSolo to allow manual input
                      // freeSolo
                      // Validate manually entered value
                      onInputChange={(event, newInputValue) => {
                        if (!newInputValue.trim()) {
                          setError("externalUser", {
                            type: "required",
                            message: "External user is required",
                          });
                        } else if (!externalUserName.some((option) => option.category === newInputValue)) {
                          setError("externalUser", {
                            type: "manualInputError",
                            message: "Invalid External user. Please select from the provided options.",
                          });
                        } else {
                          clearErrors("externalUser");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span>
                              Select External User Name<span style={{ color: "red" }}> * </span>
                            </span>
                          }
                          variant="standard"
                          error={Boolean(error) && !field.value}
                          helperText={
                            Boolean(error) ? error.message : ""
                          }
                        />
                      )}
                    />
                  </FormControl>
                )}
              />
               </div>
    <div>{renderTooltip("Select the external user name from the dropdown or type to search.")}</div>
  </div>

              {/* <FormLabel>
                Attach Documents <span style={{ color: 'red' }}>*</span>
                <FileUploader
                  handleChange={handleFileChange}
                  name="file"
                  types={fileTypes}
                  required
                />
                {file ? <p>Selected File: {file.name}</p> : <p>No file selected</p>}
              </FormLabel> */}
              <CustomFile file={file} handleFileChange={handleFileChange} token={token} tooltipMessage={"Upload supporting documents such as lease agreement, identity proof, etc."} />
              {/* <CustomToastContainer /> */}

            </section>

            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Submit" />
              {isLoading && <CustomLoading />}
              <CustomToastContainer />

            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}


export default MaintenanceRequestAdd