// import React from 'react'
// import Loading from 'react-loading'

// function CustomLoading() {
//   return (
//     <div className="loading-overlay">
//     <Loading type="bars" color="#045F9F" />
//  </div>
//   )
// }

// export default CustomLoading
import {React,useState }from 'react';
import Loading from 'react-loading';

import { HashLoader } from 'react-spinners';
function CustomLoading() {
  const [loading, setLoading] = useState(true);

  // Common styles for all screen sizes
  const overlayStyle = {
    position: 'fixed',
    top: '50%',
    right: '0',
    transform: 'translateY(-50%)',
    width: '100%', // Default width for large screens
    height: '100%', // Default height for large screens
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(255, 255, 255, 0.8)',
    zIndex: 999,
  };
  

  // Media queries for different screen sizes
  const smallScreen = '@media (max-width: 576px)';
  const mediumScreen = '@media (min-width: 577px) and (max-width: 992px)';
  const largeScreen = '@media (min-width: 993px)';

  // Responsive styles for different screen sizes
  const responsiveStyles = {
    [smallScreen]: {
      width: '90%',
      height: '90%',
    },
    [mediumScreen]: {
      width: '100%',
      height: '100%',
    },
    // You can add more styles for larger screens if needed
  };

  // Combine common styles with responsive styles using spread syntax
  const combinedStyles = { ...overlayStyle, ...responsiveStyles };


  return loading  ? (
    <div style={combinedStyles}>
      <HashLoader type="bars" color="#045F9F" speedMultiplier="1" />
    </div>
  ) : null;
}

export default CustomLoading;
