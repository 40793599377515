import React from "react";
import SideMenuItem from "./SideMenuItem";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SideMenuDropdown from "./SideMenuDropdown";
import Groups2Icon from '@mui/icons-material/Groups2';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import {  AccountBalanceWalletOutlined, AddHomeWorkOutlined,  AlarmAddOutlined,  AttachMoneyOutlined,    Description,    GroupsOutlined,  HandymanOutlined, CreditCardOutlined, HelpOutlineOutlined,  HomeOutlined,  HomeWorkOutlined,  InsertDriveFileOutlined,  LocalAtmOutlined,  NotificationsActiveOutlined} from "@mui/icons-material";
import './SideNavStyle.css';
import { useSelector } from "react-redux";

const SideMenu = ({ onMenuItemClick }) => {   // Small screen condition fixed by mohan
  const { token,roles } = useSelector((state) => state.hybridhoa);
  console.log('User Roles:', roles);
  const dashboardLinks = {
    ROLE_HO: "/dashboard-ho",
    ROLE_ARC: "/dashboard-arc",
    ROLE_BM: "/dashboard-bm",
    ROLE_GU: "/dashboard-gu",
    ROLE_MC: "/dashboard-mc",
  };

  const getDashboardLink = (role) => dashboardLinks[role] || "/";
  const menuItemdata = [
    
    {
      navLink: getDashboardLink(roles),
      name: "Dashboard",
      icon: <DashboardOutlinedIcon />,
      id: "collapse-dashboard",
      childItem: [
        {
          navLink: getDashboardLink(roles),
          name: "Dashboard",
          icon: <DashboardIcon />
        }
      ],
      isNested: false
    },

    ...(roles  == 'ROLE_HO'
    ? [
      {
        navLink: "#",
        name: "Compliance Request",
        icon: <HomeOutlined />,
        id: "collapse-homes",
        childItem: [
          {
            navLink: "/compliancerequest",
            name: "My Compliance Requests",
            icon: <AddHomeWorkOutlined />,
          },
          {
            navLink: "/raisedbyarc",
            name: "Raised By ARC",
            icon: <GroupsOutlined />,
          },
        ],
        isNested: true,
      }
    ]
    : roles == 'ROLE_ARC'
      ? [
        {
          navLink: "#",
          name: "Compliance Request",
          icon: <HomeOutlined />,
          id: "collapse-homes",
          childItem: [
            {
              navLink: "/arccompliancerequest",
              name: "Compliance Request By ARC",
              icon: <AddHomeWorkOutlined />,
            },
            {
              navLink: "/raisedbyho",
              name: "Raised By HomeOwner",
              icon: <GroupsOutlined />,
            },
            // {
            //   navLink: "/template",
            //   name: "Template",
            //   icon: <GroupsOutlined />,
            // },
          ],
          isNested: true,
        }
      ]
      : roles == 'ROLE_BM' ? 
      [
        {
          navLink: "#",
          name: "Compliance Request",
          icon: <HomeOutlined />,
          id: "collapse-homes",
          childItem: [
            {
              navLink: "/raisedbyarc",
              name: "Raised By ARC",
              icon: <AddHomeWorkOutlined />,
            },
            {
              navLink: "/raisedbyho",
              name: "Raised By HomeOwner",
              icon: <GroupsOutlined />,
            },
            // {
            //   navLink: "/template",
            //   name: "Template",
            //   icon: <GroupsOutlined />,
            // },
          ],
          isNested: true,
        }
      ] 
      : roles == 'ROLE_MC' ?
       [
        {
          navLink: "#",
          name: "Compliance Request",
          icon: <HomeOutlined />,
          id: "collapse-homes",
          childItem: [
            {
              navLink: "/mccompliancerequest",
              name: "Compliance Requests By Management Company",
              icon: <AddHomeWorkOutlined />,
            },
            {
              navLink: "/mcraisedbyarc",
              name: "Raised By ARC",
              icon: <AddHomeWorkOutlined />,
            },
            {
              navLink: "/raisedbyho",
              name: "Raised By HomeOwner",
              icon: <GroupsOutlined />,
            },
            // {
            //   navLink: "/template",
            //   name: "Template",
            //   icon: <GroupsOutlined />,
            // },
          ],
          isNested: true,
        }
       ]
       :[]),
    

       ...(roles == 'ROLE_GU' 
       ?
       [
        {
            navLink: "/gumaintenance",
            name: "Maintenance Requests",
            icon: <HandymanOutlined />,
          } 
       ]
       : roles == 'ROLE_MC'
       ?
       [
        {
          navLink: "#",
          name: "Maintenance Request",
          icon: <HomeOutlined />,
          id: "collapse-homes",
          childItem: [
            {
              navLink: "/homcmaintenance",
              name: "MR - List(Homeowners)",
              icon: <HandymanOutlined />,
            },
            {
              navLink: "/mcmaintenance",
              name: "Maintenance Request By MC",
              icon: <HandymanOutlined />,
            },
          ],
          isNested: true,
        }
      ]
      //  : roles == 'ROLE_MC'
      //  ?
      //  [
      //   {
      //       navLink: "/mcmaintenance",
      //       name: "Maintenance Request",
      //       icon: <HandymanOutlined />,
      //     } 
      //  ]
       :roles == 'ROLE_ARC'
       ?
       [
        {
            navLink: "/homemaintenance",
            name: "MR - List(Homeowners)",
            icon: <HandymanOutlined />,
          } 
       ]
       : roles == 'ROLE_BM'
       ?
       [
        {
          navLink: "#",
          name: "Maintenance Request",
          icon: <HomeOutlined />,
          id: "collapse-homes",
          childItem: [
            {
              navLink: "/Bmhomemaintenance",
              name: "MR - List(Homeowners)",
              icon: <HandymanOutlined />,
            },
            {
              navLink: "/maintenancerequestlistgeneraluser",
              name: "MR - List(General User)",
              icon: <HandymanOutlined />,
            },
          ],
          isNested: true,
        }
      ]
      :
    [
      {
        navLink: "/maintenancerequest",
        name: "Maintenance Request",
        icon: <HandymanOutlined />,
      }
    ]
        ),



    //     ...(roles == 'ROLE_GU' 
    //     ?
    //     [
    //      {
    //          navLink: "/gumaintenance",
    //          name: "Maintenance Request",
    //          icon: <HandymanOutlined />,
    //        } 
    //     ]
    //     :roles == 'ROLE_ARC' 
    //     ?
    //     [
    //      {
    //          navLink: "/homemaintenance",
    //          name: "MR-List(HomeOwners)",
    //          icon: <HandymanOutlined />,
    //        } 
    //     ]
    //     : roles == 'ROLE_MC'
    //     ?
    //     [
    //      {
    //        navLink: "#",
    //        name: "Maintenance Request",
    //        icon: <HandymanOutlined />,
    //        id: "collapse-homes",
    //        childItem: [
    //          {
    //            navLink: "/mcmaintenance",
    //            name: "Maintenance Request",
    //            icon: <HandymanOutlined />,
    //          },
    //          {
    //            navLink: "/homemaintenance",
    //            name: "MR-List(HomeOwners)",
    //            icon: <HandymanOutlined />,
    //          } ,
    //        ],
    //        isNested: true,
    //      }
    //    ]
    //     : roles == 'ROLE_BM'
    //     ?
    //     [
    //      {
    //        navLink: "#",
    //        name: "Maintenance Request",
    //        icon: <HomeOutlined />,
    //        id: "collapse-homes",
    //        childItem: [
    //          {
    //            navLink: "/Bmhomemaintenance",
    //            name: "MR-List(HomeOwners)",
    //            icon: <HandymanOutlined />,
    //          },
    //          {
    //            navLink: "/maintenancerequestlistgeneraluser",
    //            name: "MR-List(General User)",
    //            icon: <HandymanOutlined />,
    //          },
    //        ],
    //        isNested: true,
    //      }
    //    ]
    //    :
    //  [
    //    {
    //      navLink: "/maintenancerequest",
    //      name: "Maintenance Request",
    //      icon: <HandymanOutlined />,
    //    }
    //  ]
    //      ),
















        ...(roles  == 'ROLE_HO' 
        ?[
          {
            navLink: "/renter",
            name: "Renter Information",
            icon: <HomeWorkOutlined />,
          }
        ] :[]
      
          ),  
          ...(roles  == 'ROLE_MC' 
          ?[
            {
              navLink: "/renterlistmc",
              name: "Renter Information",
              icon: <HomeWorkOutlined />,
            }
          ] :[]
        
            ),  

      ...(roles == 'ROLE_HO' 
      ?
      [
        // {
        //   navLink: "#",
        //   name: "Documents",
        //   icon: <InsertDriveFileOutlined />,
        //   id:"collapse-homes",
        //   childItem:[
            {
              navLink: "/document",
              name: "Documents",
              icon: <InsertDriveFileOutlined />
            },
            // {
            //   navLink: "/archiveddocument",
            //   name: "Documents(Archived)",
            //   icon: <InsertDriveFileOutlined/>
            // },
          
        //   ],
        //   isNested:true
        // }
      ]
      : roles =='ROLE_BM' || roles == 'ROLE_MC' 
      ?
      [
        // {
        //   navLink: "#",
        //   name: "Documents",
        //   icon: <InsertDriveFileOutlined />,
        //   id:"collapse-homes",
        //   childItem:[
            {
              navLink: "/mcbmarcdocument",
              name: "Documents",
              icon: <InsertDriveFileOutlined />
            },
            // {
            //   navLink: "/mcbmarcarchiveddocument",
            //   name: "Documents(Archived)",
            //   icon: <InsertDriveFileOutlined/>
            // },
          
        //   ],
        //   isNested:true
        // }
      ]
      : roles == 'ROLE_ARC' 
      ?
      [
        // {
        //   navLink: "#",
        //   name: "Documents",
        //   icon: <InsertDriveFileOutlined />,
        //   id:"collapse-homes",
        //   childItem:[
            {
              navLink: "/mcbmarcdocument",
              name: "Documents",
              icon: <InsertDriveFileOutlined />
            },
            // {
            //   navLink: "/arcarchieveddocument",
            //   name: "Documents(Archived)",
            //   icon: <InsertDriveFileOutlined/>
            // },
          
          // ],
        //   isNested:true
        // }
      ]:
      []
      ), 

     

      ...( roles  == 'ROLE_BM' || roles  == 'ROLE_MC'
      ?
       [
          {
            navLink: "#",
            name: "Invoice",
            icon: <Description />,
            id:"collapse-homes",
            childItem:[
              {
                navLink: "/invoice",
                name: "Invoice",
                icon: <Description />
              },
              {
                navLink: "/reviewinvoicelist",
                name: "Review Invoice",
                icon: <Description/>
              },
            
            ],
            isNested:true
          }
        ]:''
       
       ),

       ...(roles  == 'ROLE_ARC' || roles  == 'ROLE_GU' 
      ?
        [
          {
              navLink: "/invoice",
              name: "Invoice",
              icon: <Description />,
            },
        ]: ''
       
       ),

       ...( roles  =='ROLE_BM'  
       ?[
         {
        navLink: "/bmarcevent",
        name: "Events",
        icon: <AlarmAddOutlined />,
      },
       ]
       :
       roles  == 'ROLE_HO' ?
       [
        {
          navLink: "/event",
          name: "Events",
          icon: <AlarmAddOutlined />,
        },
       ]
       :
       roles  == 'ROLE_ARC' ?
       [
        {
          navLink: "/arcevent",
          name: "Events",
          icon: <AlarmAddOutlined />,
        },
       ]
       :roles  == 'ROLE_MC' ?
       [
        {
          navLink: "/mcevent",
          name: "Events",
          icon: <AlarmAddOutlined />,
        },
       ]
       :
       []
        ),
        ...(roles  == 'ROLE_HO' 
        ?
          [
            {
              navLink: "/notification",
              name: "Notifications",
              icon: <NotificationsActiveOutlined />
            },
          ]: 
          roles  == 'ROLE_GU' 
          ?
            [
              {
                    navLink: "/notification",
                    name: "Notifications",
                    icon: <AlarmAddOutlined />,
                  },
            ]: 
            roles  == 'ROLE_ARC' || roles  == 'ROLE_BM' || roles  == 'ROLE_MC'
            ?
              [
                {
                          navLink: "/mcbmarcnotification",
                          name: "Notifications",
                          icon: <NotificationsActiveOutlined />
                        },
              ]:[]
         ),
      // ...(roles  == 'ROLE_HO' 
      // ?
      // [
      //   {
      //     navLink: "#",
      //     name: "Notifications",
      //     icon: <NotificationsActiveOutlined />,
      //     id:"collapse-homes",
      //     childItem:[
      //       // {
      //       //   navLink: "/notification",
      //       //   name: "Notifications",
      //       //   icon: <NotificationsActiveOutlined />
      //       // },
      //       // {
      //       //   navLink: "/archivednotification",
      //       //   name: "Notifications(Archived)",
      //       //   icon: <NotificationsActiveOutlined/>
      //       // },
          
      //     ],
      //     isNested:true
      //   }
      // ]
      // ...( roles  == 'ROLE_ARC' || roles  == 'ROLE_BM' || roles  == 'ROLE_MC'
      // ?[
      //   {
      //     navLink: "#",
      //     name: "Notifications",
      //     icon: <NotificationsActiveOutlined />,
      //     id:"collapse-homes",
      //     childItem:[
      //       {
      //         navLink: "/mcbmarcnotification",
      //         name: "Notifications",
      //         icon: <NotificationsActiveOutlined />
      //       },
      //       // {
      //       //   navLink: "/mcbmarcarchivednotification",
      //       //   name: "Notifications(Archived)",
      //       //   icon: <NotificationsActiveOutlined/>
      //       // },
          
      //     ],
      //     isNested:true
      //   }
      // ]
      // : roles == 'ROLE_GU'
      // ?[
      //   {
      //     navLink: "/notification",
      //     name: "Notifications",
      //     icon: <AlarmAddOutlined />,
      //   },
      // ]
      // :[]
      // ),

      ...(roles  == 'ROLE_HO'
      ?[
        {
          navLink: "#",
          name: "Payment",
          icon: <AttachMoneyOutlined />,
          id:"collapse-homes",
          childItem:[
            {
              navLink: "/reimbursedue",
              name: "Waived Off Dues",
              icon: <AccountBalanceWalletOutlined />
            },
            {
              navLink: "/due",
              name: "Dues",
              icon: <AttachMoneyOutlined/>  
            },
            {
              navLink: "/paymenthistory",
              name: "Payment History",
              icon: <LocalAtmOutlined/>
            },
            {
              navLink: "/paymentmethod",
              name: "Payment Method",
              icon: <CreditCardOutlined/>
            },
          ],
          isNested:true
        }
      ]:[]
      ),

     
      ...(roles == 'ROLE_ARC' 
      ?
      [
        {
          navLink: "#",
          name: "Reports",
          icon: <InsertDriveFileOutlined />,
          id:"collapse-homes",
          childItem:[
            {
              navLink: "/arcrequestreport",
              name: "Request Report",
              icon: <InsertDriveFileOutlined />
            },
            {
              navLink: "/homeactivityreport",
              name: "Home Activity Report",
              icon: <InsertDriveFileOutlined/>
            },
          
          ],
          isNested:true
        }
      ]
      : roles == 'ROLE_BM' || roles == 'ROLE_MC'
      ?
      [
        {
          navLink: "#",
          name: "Reports",
          icon: <InsertDriveFileOutlined />,
          id:"collapse-homes",
          childItem:[
            {
              navLink: "/requestreport",
              name: "Request Report",
              icon: <InsertDriveFileOutlined />
            },
            {
              navLink: "/homeactivityreports",
              name: "Home Activity Report",
              icon: <InsertDriveFileOutlined/>
            },
            {
              navLink: "/mcbmarreport",
              name: "AR Report",
              icon: <InsertDriveFileOutlined/>
            },
          
          ],
          isNested:true
        }
      ]
      :[]
      ), 



      ...(roles == 'ROLE_ARC' || roles =='ROLE_BM' || roles  == 'ROLE_HO'
      ?[
        {
          navLink: "/faq",
          name: "Frequently Asked Questions (FAQ)",
          icon: <HelpOutlineOutlined />,
        },
      ]
      
      
      :[]
       ),
     
       ...(roles =='ROLE_ARC' || roles == 'ROLE_MC'|| roles == 'ROLE_BM' ?[
        {
          navLink: "/discussionboard",
          name: "Discussion Board",
          icon: <Groups2Icon />,
        }
       ]:''),
     

  ];

  return (
    <ul class="space-y-1 font-medium ">
      {menuItemdata.map((i) => {
        console.log(i.childItem);
        return (
          !i.isNested ?(
          <SideMenuItem
            navigationLink={i.navLink}
            itemName={i.name}
            icon={i.icon}
            onClick={onMenuItemClick} //// Small screen condition fixed by mohan

          />
          
          ):
          <SideMenuDropdown
          childItem={i.childItem}
          id={i.id}
          parrentName={i.name}
          icon={i.icon}
          onClick={onMenuItemClick} //// Small screen condition fixed by mohan
          />
          
         
        );
      })}
      
    </ul>
  );
};

export default SideMenu;
