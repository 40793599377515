
import React from 'react';

const ColoredCircle = ({ isSuccess }) => {
  const circleStyle = {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: '24px',
  };

  if (isSuccess) {
    circleStyle.backgroundColor = 'green';
    return (
      <div style={circleStyle}>
        <span>&#10003;</span>
      </div>
    );
  } else {
    circleStyle.backgroundColor = 'red';
    return (
      <div style={circleStyle}>
        <span>&#10005;</span>
      </div>
    );
  }
};

export default ColoredCircle;
