import React, { useEffect, useState } from "react";
import "chart.js/auto";
import "./../../../index.css";
import CardLayout from "./../../../components/dashboard/CardLayout";
import ChartLayout from "./../../../components/dashboard/ChartLayout";
import CustomHeading from "./../../../components/CustomHeading";
import logo from "./../../../assets/img/logo.png";
import { HandymanOutlined, InsertDriveFileOutlined, NotificationsActiveOutlined } from "@mui/icons-material";
import { BASE_URL, CHART, DASHBOARD, DASHBOARD_BM } from "../../../utills/ApplicationRouting";
import { useSelector } from 'react-redux';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import axios from "axios";
import CustomLoading from "../../../components/CustomLoading";
import DvrIcon from '@mui/icons-material/Dvr';
import CardLayoutContact from "../../../components/dashboard/CardLayoutContact";

function BmDashboard() {
  
  const [isLoading, setIsLoading] = useState(true);
  const [datas, setDatas] = useState({});
  const [chartData, setChartData] = useState({});
  const { token, homeId } = useSelector((state) => state.hybridhoa);


    const getBmDashboardData = async () => {
      try {
        setIsLoading(true)
        const storedToken = token;

        // Fetch dashboard data
        const response1 = await axios.get(`${BASE_URL}${DASHBOARD}${DASHBOARD_BM}/${homeId}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        if (response1.status === 200) {
          setDatas(response1.data);
        } else {
          throw new Error("Failed to fetch dashboard data");
        }

        // Fetch chart data
        const response2 = await axios.get(`${BASE_URL}${DASHBOARD}${CHART}${DASHBOARD_BM}/${homeId}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });

        if (response2.status === 200) {
          setChartData(response2.data);
        } else {
          throw new Error("Failed to fetch chart data");
          setIsLoading(false);
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    
    useEffect(() => {
      getBmDashboardData();
  }, [token]);




  if (isLoading) {
    return <CustomLoading />;
  }

  const cardDatas= ([
    {
      count: datas.documentCount,
      badgeContent: datas.documentUnread,
      name: "Documents",
      icon: <InsertDriveFileOutlined />,
      color: ["#fee2e2"],
      linkTo: "/mcbmarcdocument",
      tooltip: "Click to view the list of Documents",
    },
    {
      count: datas.maintenanceCountByHO,
      badgeContent: datas.maintenanceUnread,
      name: "Maintenance Requests by Homeowner",
      icon: <HandymanOutlined />,
      color: ["#ffc1ac"],
      linkTo: "/Bmhomemaintenance",
   
      tooltip: "Click to view the list of Maintenance requests by Homeowner",
    },
    {
      count: datas.maintenanceCountByGU,
      badgeContent: datas.maintenanceUnread,
      name: "Maintenance Requests by General User",
      icon: <HandymanOutlined />,
      color: ["#bbddff"],
      linkTo: "/maintenancerequestlistgeneraluser",
      tooltip: "Click to view the list of Maintenance requests by General User",
    },
    {
      count: datas.complainceCountByHO,
      badgeContent: datas.complainceUnread,
      name: "Compliance Requests By Homeowner",
      icon: <DvrIcon />,
      color: ["#c2f6c2"],
      linkTo: "/raisedbyho",
      tooltip: "Click to view the list of Compliance Requests by Homeowner",
    },
    {
      count: datas.eventCount,
      badgeContent: datas.eventUnread,
      name: "List of calendar events",
      icon: <CalendarMonthIcon />,
      color: "#ffffcc",
      linkTo: "/bmarcevent",
      tooltip: "Click to view the list of Events",
    },
    {
      count: datas.notificationCount,
      badgeContent: datas.notificationUnread,
      name: "Notifications",
      icon: <NotificationsActiveOutlined />,
      color: "#ffd8eb",
      linkTo: "/mcbmarcnotification",
      tooltip: "Click to view the list of Notifications",
    },
  ]);

  const chartDatas = [
    {
      title: "Maintenance",
      tooltip: "Maintenance Request Status",
      card: [

        {
          count: chartData.maintenancePendingCount,
          title: "Pending",
        },
        {count:chartData.maintenanceInProgressCount,
          title: "In Progress",
        },
        {
          count: chartData.maintenanceApprovedCount,
          title: "Approved",
        },
        {
          count: chartData.maintenanceCompletedCount,
          title: "Completed",
        },
        {
          count: chartData.maintenancePendingApprovedCount,
          title: "PendingApproval",
        },
        {
          count: chartData.maintenanceRejectedCount,
          title: "Rejected",
        },
        {
          count: chartData.maintenanceAckCount,
          title: "Acknowledged",
        },
        {
          count: chartData.maintenanceClosedCount,
          title: "Closed",
        },
      ],
      data: {
        labels: ["Approved", "Pending", "Acknowledged", "Pending Approval", "Rejected","In progress","Completed","Closed"],
        datasets: [
          {
            data: [chartData.maintenanceApprovedCount, chartData.maintenancePendingCount, chartData.maintenanceAckCount,chartData.maintenancePendingApprovedCount,chartData.maintenanceRejectedCount,chartData.maintenanceInProgressCount,chartData.maintenanceCompletedCount,chartData.maintenanceClosedCount],
            backgroundColor:      ["#ff581a","#9cd96c", "#1e90ff", "#6cd9a9", "#c970db", "#ff4da6","#ffff64","#8be2fd"],
            hoverBackgroundColor: ["#ff9067","#74c533", "#006ad1", "#33c585", "#b233cb", "#ff0080","#ffff00","#11c2fa"],
          },
        ],
      },
    },
    {
      title: "Compliance",
      tooltip: "HO Compliance Request Status",
      card: [
        {
          count: chartData.complaincePendingCount,
          title: "Pending",
        },
        {
          count: chartData.complainceAckCount,
          title: "Acknowledged",
        },
        {
          count: chartData.complainceResolvedCount,
          title: "Resolved",
        },
        {
          count: chartData.complainceClosedCount,
          title: "Closed",
        },
        {
          count: chartData.complainceNotResolvedCount,
          title: "Not resolved",
        },
      ],
      data: {
        labels: ["Pending", "Acknowledged", "Resolved", "Closed", "Not resolved"],
        datasets: [
          {
            data: [chartData.complaincePendingCount, chartData.complainceAckCount, chartData.complainceResolvedCount,chartData.complainceClosedCount,chartData.complainceNotResolvedCount],
            backgroundColor: ["#ff794d", "#9cd96c", "#6cd9a9", "#00a5b3","#00bfff"],
            hoverBackgroundColor: ["#ff531a", "#74c533", "#33c585", "#007680","#87cefa"],
          },
        ],
      },
    },
  ];

  const chartOptions = {
    responsive: true,
  };

  return (
    <div>
      <CustomHeading title="Dashboard" />
      <br />
      <CardLayoutContact />
      <br />
      <CardLayout cardData={cardDatas} type="dashboardBM" />
      <br />
      <br />
      <ChartLayout chartData={chartDatas} chartOptions={chartOptions} />
      <div className="fixed bottom-0 right-0 m-4">
        <a href="https://hybridhoa.com" target="blank">
          <img src={logo} width={100} height={100} alt="logo" />
        </a>
      </div>
    </div>
  );
}

export default BmDashboard;