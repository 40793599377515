import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL, BASE_URL_IMAGE, BYLAW, DECISION_MAINTENANCE, EDIT_MAINTENANCE, GENERAL_COMMENTS, GET_ALL_GENERAL_COMMENTS, GROUP_CONFIG, MAINTENANCE_REQUEST, REVIEW_MAINTENANCE } from '../../../utills/ApplicationRouting';
import { DeleteOutline, EditNoteOutlined, FileDownloadOutlined } from '@mui/icons-material';
import CustomViewPage from '../../../components/CustomViewPage';
import CustomHeading from '../../../components/CustomHeading';
import { Card, CardContent, Checkbox, DialogActions, DialogContent, Divider, FormControlLabel, Typography, Box } from '@mui/material';
import CustomInputField from '../../../components/CustomInputField';
import CustomInputFieldExpand from '../../../components/CustomInputFieldExpand';
import { BYLAW_REQUIRED_MESSAGE, COMMENTS_REQUIRED_MESSAGE, MAX_LENGTH_THOUSAND, MIN_LENGTH_VALIDATE_MESSAGE, REVIEW_COMMENT_REQUIRED_MESSAGE } from '../../../utills/ApplicationConstants';
import CustomButton from '../../../components/CustomButton';
import moment from 'moment';
import CustomLoading from '../../../components/CustomLoading';
import CustomToastContainer from '../../../components/CustomToastContainer';
import { request } from '../../../services/AxiosConfig';
import CustomModel from '../../../components/CustomModel';
import CustomOptions from '../../../components/CustomOptions';
import CustomAutoComplete from '../../../components/CustomAutoComplete';
import CustomModelView from '../../../components/CustomModelView';
import Tooltip from "@mui/material/Tooltip";
import CustomPascalChange from '../../../components/CustomPascalChange';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PDFDocument from '../../../components/Templatepdf';
import { pdf } from '@react-pdf/renderer';
import CustomViewPages from '../../../components/CustomViewPages';
import AttachmentList from '../../../components/AttachmentList';
import CustomViewHeading from '../../../components/CustomViewHeading';
import CustomViewWithTooltip from '../../../components/CustomViewWithTooltip';
import AttachmentListsView from '../../../components/AttachmentListsView';
import CustomViewWithMore from '../../../components/CustomViewWithMore';
import CustomFile from '../../../components/CustomFile';
import CustomReviewTable from '../../../components/CustomReviewTable';
import CustomGeneralComments from '../../../components/CustomGeneralComments';
import CustomMainButton from '../../../components/CustomMainButton';
import CustomReviewTableWithoutActions from '../../../components/CustomReviewTableWithoutActions';

function ArcMaintenanceView() {
  const navigate = useNavigate();
  const { maintenanceId } = useParams();
  const [maintenanceRequestData, setMaitenanceRequestData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [byLawDetails, setByLawDetails] = useState([]);
  const [byLawDescription, setByLawDescription] = useState('');
  const [status, setStatus] = useState('');
  const [address, setAddress] = useState('');

  const [file, setFile] = useState([]);

 

  const { token, roles, userDetails } = useSelector(state => state.hybridhoa);
  const {
    handleSubmit: handleSubmitGeneralComments,
    control: controlGeneralComments,
    formState: { errors: errorsGeneralComments },
    setValue: setValueGeneralComments,
    setError: setErrorGeneralComments,
    reset: resetGeneralComments,
    watch: watchGeneralComments,
    clearErrors:clearErrorsGeneralComments,
  } = useForm();

  // useForm for review comments
  const {
    handleSubmit: handleSubmitReviewComments,
    control: controlReviewComments,
    formState: { errors: errorsReviewComments },
    setValue: setValueReviewComments,
    setError: setErrorReviewComments,
    reset: resetReviewComments,
    watch: watchReviewComments,
  } = useForm();

  console.log("errorsReviewComments", errorsReviewComments)
  const { userLoginDetails, groupNames } = useSelector(state => state.hybridhoa);
  const [generalCommentDetails, setGeneralCommentDetails] = useState("");
  const [editModes, setEditModes] = useState(Array.isArray(generalCommentDetails) ? Array(generalCommentDetails.length).fill(false) : []);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [generalCommentsData, setGeneralCommentsData] = useState('');
  const [commentsId, setCommentsId] = useState('');
  const [bylawTopic, setBylawTopic] = useState('')
  const [isBylawFieldEmpty, setIsBylawFieldEmpty] = useState(false);
  const [approvalOpen, setApprovalOpen] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);
  const [selectedBylawValue, setSelectedBylawValue] = useState(null);
  const [bylawsUpdated, setBylawsUpdated] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [fileUrl, setFileUrl] = useState("");
  const [createdDateAndTimes, setCreatedDateAndTimes] = useState("")
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isAssignedToBm, setIsAssignedToBm] = useState(false);
  const [generalCommentsDataAdd, setGeneralCommentsDataAdd] = useState("");
  const [groupConfigurationDetails,setGroupConfigurationDetails]=useState([])
  const [logoUrl, setLogoUrl] = useState('');
  const [imageBlob,setImageBlob]=useState('');
  const [fileImage,setFileImage]=useState([])
  const[footerAdress,setFooterAdress]=useState('')
  const[groupAdress,setGroupAdress]=useState('')
  const [filePdfs,setFilePdfs]=useState("");
  const [includeComments, setIncludeComments] = useState(false);
const [createdToDetails,setCreatedToDetails]=useState("")
const [fileError, setFileError] = useState("");
const [reviewComments, setReviewComments] = useState([]);
const [createdByDetails,setCreatedByDetails]=useState("")

  const handleCheckboxChanges = (event) => {
    setIncludeComments(event.target.checked);
  };

  const handleFileChange = (newFiles) => {
    setFile(newFiles);
  };
  const getGroupConfigurationDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${GROUP_CONFIG}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response);

      if (response.status === 200) {
        setIsLoading(false);
        const data = response.data;
        setGroupConfigurationDetails(data);
        const logoConfig = data.find(config => config.topic === 'Logo');
        console.log(logoConfig);
        if (logoConfig) {
          setLogoUrl(logoConfig.content);
          console.log(logoConfig.content);
        }
        const footer = data.find(config => config.topic === 'Footer');
        console.log(logoConfig);
        if (footer) {
          setFooterAdress(footer.content);
          console.log(footer.content);
        }
        const groupField = data.find(config => config.topic ==='Group Name');
        console.log(logoConfig);
        if (groupField) {
          setGroupAdress(groupField.content);
          console.log(groupField.content);
        }

      }
        
    } catch (error) {
      setIsLoading(false)
      console.error("Error fetching data:", error);
    }
  };
  useEffect(()=>{
     getGroupConfigurationDetails()
  },[])
  const getGroupAttachments=async () => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL_IMAGE}/${logoUrl}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
        responseType: "blob",
      });
      const blob = response.data; // Accessing the blob data from the response
      const fileURL = URL.createObjectURL(blob);
      setImageBlob(fileURL);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(()=>{
    getGroupAttachments()
  },[logoUrl])
  const onSubmits = (data) => { };

  const handleClick = () => {
    navigate(-1)
  }
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleInputChangeAdd = (e) => {
    setGeneralCommentsDataAdd(e.target.value);
  };

  const getMaintenanceRequestDetailsById = async () => {
    try {
      setIsLoading(true);
      const storedToken = token

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${EDIT_MAINTENANCE}${maintenanceId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        setIsLoading(false)
        const data = response.data;
        // console.log("homeDetails", data.homeDetails.address);
        // const homeAddress = data.homeDetails.address;
        // setAddress(homeAddress)
        setMaitenanceRequestData(data);
        setSelectedOption(data.status)
        setStatus(data.status)
        setFileUrl(data.fileUrl);
        setFilePdfs(data.fileUrl)
        setIsAssignedToBm(data.isAssignedToBm)
        setFileImage(data.attachments)
        setCreatedByDetails(data.createdByDetails)
        const reviewtable = data.auditList;
        setReviewComments(reviewtable);
        const formattedDate = moment(data.createdDateAndTime).format(
          "MM/DD/YYYY hh:mm A"
        );
        setCreatedDateAndTimes(formattedDate)
        const matchingByLaw = byLawDetails.find((byLaw) => byLaw.byLawId === data.bylawId);
        if (matchingByLaw) {
          setBylawTopic(matchingByLaw.topic);
          setValueReviewComments("selectedBylaw", matchingByLaw.byLawId);
          setByLawDescription(matchingByLaw.description);
          setSelectedBylawValue({ label: matchingByLaw.topic, value: matchingByLaw.byLawId });
        } else {
          setValueReviewComments("selectedBylaw", '');
          setByLawDescription('');
          setSelectedBylawValue(null);
        }

       

      } 

    } catch (error) {
      // Handle error
      setIsLoading(false)
      console.error("Error fetching data:", error);
    }
  };

 useEffect(() => {
    getMaintenanceRequestDetailsById();
  }, [maintenanceId, byLawDetails])

  const getComplainceAttachments=async () => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL_IMAGE}/${filePdfs}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
        responseType: "blob",
      });
      const blob = response.data; // Accessing the blob data from the response
      const fileURL = URL.createObjectURL(blob);
      setFilePdfs(fileURL);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(()=>{
    getComplainceAttachments()
  },[filePdfs])
  const currentDate = moment(); // Get the current date
  let dateTimeText = currentDate.format("DD MMMM YYYY"); // Format the current date
  const handleRenderPDF = () => {
    
    const styles = {
      title: {
        fontSize: 18,
        color: "black",
        textAlign: "center",
        padding:"5px"
      },
    empty:{
      color:"red",
    padding:"500px"
    },
    content: {
      fontSize: 12,
      color: "black",
      textAlign: "left",
      // paddingLeft:"60px",
      padding:"5px"
    },
    emptys:{
        marginTop:"3%"
    },
    empty:{
    marginTop:"120%"
    },
    secondEmpty:{
     marginTop:"40%"
    },
    from:{
      fontSize: 12,
      color: "black",
      textAlign: "left",
      alignItems:"center",
      padding:"2px",
     },
   headers:{
    fontSize: 12,
    color: "black",
    textAlign: "left",
    alignItems:"center",
    padding:"2px",
   },
    adress:{
      fontSize: 14,
      color: "black",
      textAlign: "left",
      
      // paddingLeft:"60px",
      padding:"5px",
      // fontWeight:"bold"
    },
    description:{
      fontSize: 15,
      color: "black",
      textAlign: "left",
      // paddingLeft:"60px",
      padding:"5px",
    fontWeight:"bold"
    },
    signature: {
      fontSize: 16,
      color: "black",
      textAlign: "left",
      fontStyle: "italic",
    },
    heading: {
      fontSize: 24,
      textAlign: 'center',
      fontFamily: 'Oswald'
    },
    pageInitial:{
      marginTop:"100px"
    },
    afterfrom:{
      marginTop:"7%"
    },
    formempty:{
      marginTop:"47%"
    },anotherempty:{
      marginTop:"8%"
    },images:{
width:"200px",
height:"100px"

    },
    fromBorder:{
       border:"1px solid black"
    },toBorder:{
      border:"1px solid black"

    }
  };
  const datas = {
    // title:"Complaince Request Notice",
    // hoaName:groupAdress,

    logo: imageBlob,
          letterContent: [
      // { type: 'text',text: "",style:styles.emptys }, // Empty line for spacing
      { type: 'text', text: groupAdress, style: styles.title },

      { type: 'text', text: "Maintenance Request", style: styles.title },
      { type: 'text', text: " "  }, 
      // { type: 'text',text: dateTimeText, style:styles.from },
      // { type: 'text',text: "",  }, // Empty line for spacing
      // { type: 'text',text: "",  },
      
      {style:styles.fromBorder},
      
      {type: 'text', text: "",  style:styles.formempty},
      // {type: 'text', text: "",  style:styles.anotherempty},
      // { type: 'text',text: "",  style:styles.secondEmpty},
          
      { type: 'text',text: "From :", style: styles.from  } ,
      { type: "text", text: footerAdress, style: styles.from },

      // {type: 'text',text: ""+ `${maintenanceRequestData.createdByDetails?.firstName || ""}`,style: styles.from, },
      // {type: 'text',text: ""+ `${maintenanceRequestData.address?.address || ""}`,style: styles.from,},
      // { type: 'text',text: `${maintenanceRequestData.createdByDetails?.city || ""}, ${maintenanceRequestData.createdByDetails?.zipcode || ""}`, style: styles.from },
     
             // {style:styles.toBorder},

      { type: 'text',text: "",  style:styles.afterfrom},
      { type: 'text',text: "To :", style: styles.headers  },
      {
        type: 'text',
        text: `${maintenanceRequestData?.createdToDetails?.firstName || ""}${maintenanceRequestData?.createdToDetails?.lastName || ""}`,
        style: styles.headers
      },
      { 
        type: 'text',
        text:`${maintenanceRequestData?.homeDetails?.address || ""}`,
        style: styles.headers
      },
      {
        type: 'text',
        text: `${maintenanceRequestData?.homeDetails?.city || ""}${maintenanceRequestData?.homeDetails?.zipcode ? `, ${complainceDetails?.homeDetails?.zipcode}` : ""}`,
        style: styles.headers
      },
      
       {type: 'text', text: "",  style:styles.empty},
      { type: 'text',text: "",  style:styles.secondEmpty},
      {type: 'text', text: "Dear "  + `${(maintenanceRequestData.createdToDetails?.firstName || "")}`, style: styles.content },
      {type: 'text',text:"You have previously asked to adress the following matter with your property.",style: styles.content},
    {type: 'text', text: "This is to inform you about the complaince request:"+ maintenanceRequestData.mrId+"_"+dateTimeText+",", style: styles.content},
    { type: 'text',text: "" + `${maintenanceRequestData.description ||""}`, style: styles.description,},
    {
      type: 'text',
      text: "We have scheduled the desired start of the maintenance " + (maintenanceRequestData.startDate ? moment(maintenanceRequestData.startDate).format("MM/DD/YYYY") : "") + " and desired end of maintenance on " + (maintenanceRequestData.endDate ? moment(maintenanceRequestData.endDate).format("MM/DD/YYYY") : "") + ". Please ensure these dates are noted. Should any adjustments be needed, contact me at your earliest convenience.",style: styles.description
    },
    
{ type: 'text',text: "If u have any questions,feel that you have recieved this letter in error,or would  like to request a variance,please feel free to contact our office.", style: styles.content},

   {type: 'text',text:"Thank you in advance for your attention to this matter.", style: styles.content},
    {type: 'text', text: "",  }, 
    {type: 'text', text: "", style: styles.content },
    {type: 'text', text: "", style: styles.content },
      
      // {type: 'text', text: "Sincerely", style: styles.adress },
      // {text: ""+ complainceDetails.createdByDetails?.firstName + "",style: styles.adress},
      // {text: ""+ complainceDetails.address?.address + "",style: styles.adress,fontSize: 15 ,},
      // { text: `${complainceDetails.createdByDetails?.city || ""}, ${complainceDetails.createdByDetails?.zipcode || ""}`, style: styles.adress, fontSize: 15 },    
      //  {type: 'text',text: footerContent , style: styles.adress}
        
      {type: 'text', text: "", },
      // {type: 'text', text: "",  style:styles.empty},
      // { type: 'text',text: "",  style:styles.secondEmpty},
      // {type: 'image',src:fileImage,style:styles.images },

    ]
  };
  if (filePdfs) {
    datas.letterContent.push({ type: 'text', text:"Attachments :", style: styles.content });

    datas.letterContent.push({ type: 'text', text:filePdfs, style: styles.content });
  }
  if (filePdfs) {
    datas.letterContent.push({ type: 'image', src: filePdfs, style: styles.images });
  }
  if (includeComments && generalCommentDetails.length > 0) {
    // Add the "Comments" heading before looping through comments
    datas.letterContent.push({ type: 'text', text: "Comments :", style: styles.content });

    generalCommentDetails.forEach((commentDetail) => {
        // Add each comment
        datas.letterContent.push({ type: 'text', text: commentDetail.comments, style: styles.content });
    });
}

  return (
    <PDFDocument data={datas} />
  );
};
const handleClickPdf = async () => {
  console.log("test",logoUrl);
  try {
    const pdfData = handleRenderPDF();
    const blob = await pdf(pdfData).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};
  const handleEditClick = (index, generalCommentsId) => {
    console.log("Edit button clicked", index, generalCommentsId);
    setEditModes((prevModes) =>
      prevModes.map((mode, i) => (i === index ? true : mode))
    );
    setCommentsId(generalCommentsId);
    console.log("CommentsId set:", generalCommentsId);
    getGeneralCommentsById();
  };

  const handleInputChange = (e) => {
    setGeneralCommentsData(e.target.value);
  };

  const handleCancelClick = () => {
    clearErrorsGeneralComments('')
    getGeneralComments();
    setEditModes(false);
    setValueGeneralComments('editComments',"")
  };

  const getGeneralComments = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${GET_ALL_GENERAL_COMMENTS}/${maintenanceId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        console.log(response);
        setGeneralCommentDetails(data);
        setEditModes(Array(data.length).fill(false));
        setIsLoading(false);
      } 
    } catch (error) {
      // Handle error
      setIsLoading(false)
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getGeneralComments();
  }, []);
  const onSubmit = (data, e) => {
    console.log("hi");

    if (typeof generalCommentsDataAdd !== 'string' || generalCommentsDataAdd.trim() === '') {
      setErrorGeneralComments("comments", {
        type: "manual",
        message: COMMENTS_REQUIRED_MESSAGE,
      });
      //setIsLoading(false);
      return;
    }
    //setIsLoading(true);
    const postData = {
      comments: generalCommentsDataAdd,
      referenceId: maintenanceId,

    };
    // if (data.comments == '' || data.comments == null) {
    //   setErrorGeneralComments("comments", {
    //     type: "manual",
    //     message: COMMENTS_REQUIRED_MESSAGE,
    //   });
    //   //setIsLoading(false);
    //   return;
    // }
    request({ url: GENERAL_COMMENTS, method: "post", data: postData })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {

          getGeneralComments();
          console.log("Success");
          setIsLoading(false);
          // e.target.reset();
          setGeneralCommentsDataAdd("")

          setTimeout(() => {
            //  navigate(-1);
          }, 1000);

        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };



  const getGeneralCommentsById = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${GENERAL_COMMENTS}/${commentsId}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response);

      if (response.status === 200) {
        const data = response.data;
        console.log(response.data);
        setGeneralCommentsData(data.comments);

        setIsLoading(false);
      } 
    } catch (error) {
      // Handle error
      setIsLoading(false)
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getGeneralCommentsById();
  }, [commentsId]);

  useEffect(()=>{
    if (generalCommentsData.trim().length > 1000) {
      setErrorGeneralComments("editComments", {
          type: "manual",
          message: MAX_LENGTH_THOUSAND,
      });
      setIsLoading(false);
      return;
  }
  },[generalCommentsData])

  const submitUpdate = (data) => {
    console.log("Test");
    //setIsLoading(true);
    const putData = {
      comments: generalCommentsData.trim(),
      referenceId: commentsId,
    };

    if (generalCommentsData == '' || generalCommentsData == null) {
      setErrorGeneralComments("editComments", {
        type: "manual",
        message: COMMENTS_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    // if (generalCommentsData.trim().length < 2) {
    //   setErrorGeneralComments("editComments", {
    //     type: "manual",
    //     message: MIN_LENGTH_VALIDATE_MESSAGE,
    //   });
    //   //setIsLoading(false);
    //   return;
    // }

    // if (generalCommentsData.trim().length > 1000) {
    //   setErrorGeneralComments("editComments", {
    //     type: "manual",
    //     message: MAX_LENGTH_THOUSAND,
    //   });
    //   //setIsLoading(false);
    //   return;
    // }
    request({ url: `${GENERAL_COMMENTS}/${commentsId}`, method: "put", data: putData })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");
          setIsLoading(false);
          getGeneralComments();
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const handleDeleteClickOpen = (generalCommentsId) => {
    setDeleteOpen(true);

    setCommentsId(generalCommentsId);
    getGeneralCommentsById();

  };
  const onSubmitDelete = () => {
    request({ url: `${GENERAL_COMMENTS}/${commentsId}`, method: "delete" }).then(res => {
      console.log(res);
      getGeneralComments();

    }).catch(error => {
      console.log(error);
    });
  };

  const getBylaw = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;


      const response = await axios.get(`${BASE_URL}${BYLAW}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setByLawDetails(data);
        setBylawsUpdated(data.length > 0);
        // setByLawDescription(data.description);
        setIsLoading(false);
      } 

    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getBylaw();
  }, []);

  const handleByLawChange = (event, newValue) => {
    if (newValue && newValue.value !== undefined && newValue.value !== null) {
      const selectedByLawId = newValue.value;
      setValueReviewComments("selectedBylaw", selectedByLawId);
      const selectedByLawDescription = byLawDetails.find(
        (byLaw) => byLaw.byLawId === selectedByLawId
      )?.description;

      setSelectedByLaw(selectedByLawId);
      setByLawDescription(selectedByLawDescription);
      setIsBylawFieldEmpty(false);

      // Update the state here
      setSelectedBylawValue(newValue);
      if (!selectedByLawDescription) {
        console.log("workingggg")
        setByLawDescription('');
        setIsBylawFieldEmpty(true);
      }
    } else {
      // Clear the state values
      setByLawDescription('');
      setValueReviewComments("selectedBylaw", null);
      setSelectedByLaw(null);
      setIsBylawFieldEmpty(true);

      // Update the state here
      setSelectedBylawValue(null);
    }
  };

  useEffect(() => {
    console.log("isBylawEmpty", isBylawFieldEmpty)
    console.log("bylaw", selectedBylawValue)
    console.log("isModel", isModalOpen)
  }, [isBylawFieldEmpty, selectedBylawValue, isModalOpen])

  useEffect(() => {
    const selectedBylaw = watchReviewComments('selectedBylaw');

    if (!isModalOpen) {
      if (!selectedBylaw || selectedBylaw === "") {
        setByLawDescription('');
        setIsBylawFieldEmpty(true);
      } else {
        // Find the matching byLaw from byLawDetails
        const matchingByLaw = byLawDetails.find((byLaw) => byLaw.byLawId === selectedBylaw);

        if (matchingByLaw) {
          setByLawDescription(matchingByLaw.description);
          setIsBylawFieldEmpty(false);
        } else {
          // Handle the case where no matching byLaw is found
          setByLawDescription('');
          setIsBylawFieldEmpty(true);
        }
      }
    }
  }, [watchReviewComments('selectedBylaw'), byLawDetails]);

  // useEffect(() => {
  //   const selectedBylaw = watch('selectedBylaw');
  //   console.log("selectedBylaw", selectedBylaw);

  //   if (!selectedBylaw || selectedBylaw === "") {
  //     setByLawDescription('');
  //     setIsBylawFieldEmpty(true);
  //   } else {
  //     // Find the matching byLaw from byLawDetails
  //     const matchingByLaw = byLawDetails.find((byLaw) => byLaw.byLawId === selectedBylaw);

  //     if (matchingByLaw) {
  //       setByLawDescription(matchingByLaw.description);
  //       setIsBylawFieldEmpty(false);
  //     } else {
  //       // Handle the case where no matching byLaw is found
  //       setByLawDescription('');
  //       setIsBylawFieldEmpty(true);
  //     }
  //   }
  // }, [watch('selectedBylaw'), byLawDetails]);

  const renderedBylaw = byLawDetails.map((ByLaw, index) => ({
    topic: ByLaw.topic,
    value: ByLaw.byLawId,
    key: index,
  }));

  const [selectedOption, setSelectedOption] = useState('');
  const [checked, setChecked] = useState(false);
  const [selectedByLaw, setSelectedByLaw] = useState(null);

  const options = [
    { label: 'PENDING', value: 'PENDING_IN_ARC' },
    { label: 'ACKNOWLEDGED', value: 'ACKNOWLEDGED' },
    { label: 'PENDING APPROVAL', value: 'PENDING APPROVAL', disabled: true },
    { label: 'APPROVED', value: 'APPROVED', disabled: true },
    { label: 'REJECTED', value: 'REJECTED', disabled: true },
  ];

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };



  const onUpdateSubmit = (data) => {

     if (!data.reviewComment) {
      setErrorReviewComments("reviewComment", {
        type: "manual",
        message: REVIEW_COMMENT_REQUIRED_MESSAGE,
      });
      return;
    }

    const totalFileSize = file.reduce((acc, file) => acc + file.size, 0);
  const maxSizeInMB = 10;
  const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

  if (totalFileSize > maxSizeInBytes) {
    // setFileError(`Total file size should not exceed ${maxSizeInMB}MB.`);
    return;
  }

  // Check file types
  const allowedFileTypes = ["image/jpg", "image/png", "image/jpeg", "application/pdf"];
  const invalidFiles = file.filter(file => !allowedFileTypes.includes(file.type));

  if (invalidFiles.length > 0) {
    // setFileError("Some files have invalid file types. Allowed types are JPG, PNG, JPEG, PDF.");
    return;
  }

    const rolesString = roles.join(", ");
    // const payload={
    //   status: checked ? 'PENDING APPROVAL' : selectedOption,
    //   bylawId:data.selectedBylaw,
    //   role:rolesString,
    //   assignToBM:checked,
    // }
    const formData = new FormData();
    formData.append('status', checked ? 'PENDING APPROVAL' : selectedOption);

    formData.append('bylawId', data.selectedBylaw);
    formData.append('role', rolesString);
    formData.append('assignToBM', checked);
    formData.append('comments', data.reviewComment);
    file.forEach((file) => {
      formData.append('files', file);
      });

    // Debugging console.log statements
    console.log('jsonData:', formData);

    request({ url: `${BASE_URL}${REVIEW_MAINTENANCE}${maintenanceId}`, method: "put", data: formData }).then(res => {
      //setIsLoading(true);
      console.log(res);
      if (res.status == 200) {

        setIsLoading(false);
        getMaintenanceRequestDetailsById();
        setTimeout(()=>{
          navigate(-1)
        },4000)
       

      }
    }).catch(error => {
      console.log(error.response);
      setIsLoading(false);
      if (error.response) {

        console.log("error.response.status" + error.response.status);


      }
    });
  }


  const onApproveSubmit = (data) => {

    if (!data.reviewComment) {
      setErrorReviewComments("reviewComment", {
        type: "manual",
        message: REVIEW_COMMENT_REQUIRED_MESSAGE,
      });
      return;
    }else if (data.reviewComment.length < 2 || data.reviewComment.length > 1000) {
      setErrorReviewComments("reviewComment", {
        type: "manual",
        message: data.reviewComment.length < 2
          ? MIN_LENGTH_VALIDATE_MESSAGE
          : MAX_LENGTH_THOUSAND,
      });
      return;
    }
    const rolesString = roles.toString();
    const payload = {
      status: "APPROVED" ,
      comments: data.reviewComment,
      role:rolesString,
    };

    const storedToken = token;


    axios.put(`${BASE_URL}${DECISION_MAINTENANCE}${maintenanceId}`, payload, {
      headers: {
        Authorization: `Bearer ${storedToken}`,
      }
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setIsLoading(false);
          setApprovalOpen(false);
       
        getMaintenanceRequestDetailsById();
          const message=res.data
          toast.success(message, { position: "top-center" });
          console.log("first", res);
          setTimeout(()=>{
            navigate(-1)
          },4000)
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        if (error.response && error.response.data) {
          const message = error.response.data.message;
          toast.error(message, { position: "top-center" });
          console.log("error.response.status" + error.response.status);
        } else {
          console.error(error); // Log the entire error for further debugging
          toast.error("An error occurred while processing your request.");
        }
      });
  };

  

  const viewDataTable = useMemo((rowData) => [
    {
      accessorKey: "serial No",
      header: "Serial No",
      size: 20,
      Cell: ({ row }) => row.index + 1,
    },
    // {
    //   accessorKey: "assignedDateTime",
    //   header: "Assigned DateTime",
    //   accessorFn: (row) => moment(row.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
    // },
    {
      accessorKey: "name",
      header: "Name",
      size: 20,
    },
    {
      accessorKey: "status", // The original key in the data
      header: "Review Status",
      size: 20,
      accessorFn: (row) => {
        const status = row.status; // Access the raw value
        if (!status) return "";
    
        if (status === "PENDING_IN_ARC") return "Pending"; // Specific replacement
    
        // Convert snake_case to Camel Case
        return status
          .toLowerCase()
          .replace(/_/g, " ")
          .replace(/(?:^|\s)\S/g, (c) => c.toUpperCase());
      },
    },
    
    
    {
      accessorKey: "reviewDateTime",
      header: "Review DateTime",

      accessorFn: (row) => moment(row.auditDateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
    },
    // {
    //   accessorKey: "comments",
    //   header: "Review Comments",
    //   size: 20,
    //   accessorFn: (row) => {
    //     const comments = row && row.comments;
    //     if (comments && comments.length > 28) {
    //       return comments.slice(0, 28) + "...";
    //     }
    //     return comments;
    //   }
    // },
  ]);


  const handleApprovalOpen = () => {
    setApprovalOpen(true);
    setIsModalOpen(true); // Set the modal as open
  };

  const handleApprovalClose = () => {
    setApprovalOpen(false);
    setIsModalOpen(false); // Set the modal as closed
  };

  const handleRejectOpen = () => {
    setRejectOpen(true);
    setIsModalOpen(true); // Set the modal as open
  };

  const handleRejectClose = () => {
    setRejectOpen(false);
    setIsModalOpen(false); // Set the modal as closed
  };

  const handleIconClose = () => {
    setApprovalOpen(false);
    setIsModalOpen(false);
    setValueReviewComments('reviewComment', '');
  };
  const handleRejectIconClose = () => {
    setRejectOpen(false);
    setIsModalOpen(false);
    setValueReviewComments('reviewComment', '');
  };

  const approvalModelAction = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Approve"
      />
    </>
  );

  const approveModelContent = (
    <>
      <form
        onSubmit={handleSubmitReviewComments(onApproveSubmit)}
        className="space-y-4 md:space-y-6 "
      >
        <CustomToastContainer />
        <DialogContent dividers>
          <CustomInputField
            fieldName="reviewComment"
            // requiredErrorMessage={REVIEW_COMMENT_REQUIRED_MESSAGE}
            // minLength={2}
            // minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
            // maxLength={1000}
            // maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
            fieldId="reviewComment"
            fieldType="text"
            fieldLabel="Review Comment"
            fieldControl={controlReviewComments}
            fieldError={errorsReviewComments}
            fieldRequiredIcon="*"
            multiline={true}
            numberOfRows={3}
          />
        </DialogContent>
        <DialogActions>{approvalModelAction}</DialogActions>
      </form>
    </>
  );

  const onRejectSubmit = (data) => {

    const isBylawValid = data.selectedBylaw !== null && data.selectedBylaw !== '';

    // if (!isBylawValid) {
    //   // Set error for the selectedBylaw field
    //   setErrorReviewComments('selectedBylaw', {
    //     type: 'manual',
    //     message: 'Please provide a valid bylaw.',
    //   });

    //   // Optionally, return or handle the invalid case
    //   return;
    // }

    if (!data.reviewComment) {
      setErrorReviewComments("reviewComment", {
        type: "manual",
        message: REVIEW_COMMENT_REQUIRED_MESSAGE,
      });
      return;
    }else if (data.reviewComment.length < 2 || data.reviewComment.length > 1000) {
      setErrorReviewComments("reviewComment", {
        type: "manual",
        message: data.reviewComment.length < 2
          ? MIN_LENGTH_VALIDATE_MESSAGE
          : MAX_LENGTH_THOUSAND,
      });
      return;
    }
    const storedToken = token;
    const headers = {
      Authorization: `Bearer ${storedToken}`,
    }
    const rolesString = roles.toString();
    const payload = {
      status: "REJECTED",
      comments: data.reviewComment,
      role:rolesString,
    }

    request({ url: `${BASE_URL}${DECISION_MAINTENANCE}${maintenanceId}`, method: "put", data: payload, token: headers }).then(res => {
      //setIsLoading(false);
      console.log(res);
      if (res.status === 200) {
        setRejectOpen(false)
        setIsLoading(false);
        getMaintenanceRequestDetailsById();
        setTimeout(()=>{
          navigate(-1)
        },4000)
      
      }
    }).catch(error => {
      console.log(error.response);
      setIsLoading(false);
      if (error.response) {

        console.log("error.response.status" + error.response.status);


      }
    });
  }

  const rejectModelAction = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Reject"
      />
    </>
  );
  const rejectModelContent = (
    <>
      <form
        onSubmit={handleSubmitReviewComments(onRejectSubmit)}
        className="space-y-4 md:space-y-6 "
      >
        <CustomToastContainer />
        <DialogContent dividers>
          <CustomInputField
            fieldName="reviewComment"
            // requiredErrorMessage={REVIEW_COMMENT_REQUIRED_MESSAGE}
            // minLength={2}
            // minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
            // maxLength={1000}
            // maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
            fieldId="reviewComment"
            fieldType="text"
            fieldLabel="Review Comment"
            fieldControl={controlReviewComments}
            fieldError={errorsReviewComments}
            fieldRequiredIcon="*"
            multiline={true}
            numberOfRows={3}
          />
        </DialogContent>
        <DialogActions>{rejectModelAction}</DialogActions>
      </form>
    </>
  );



  // const data = {
  //   pageTitle: "Maintenance",
  //   buttons: [
  //     {
  //       buttonTitle: "Back",
  //       onClick: handleClick,
  //     },
  //   ],
  //   content: [
  //     {
  //       topic: "Maintenance Request Id",
  //       description: maintenanceRequestData.mrId,
  //     },
  //     {
  //       topic: "What needs attention?",
  //       description: maintenanceRequestData.topic,
  //     },
  //     {
  //       topic: "More Information ",
  //       description: maintenanceRequestData.description,
  //     },
  //     {
  //       topic: "Requested Date Time",
  //       description: moment(maintenanceRequestData.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss A"),
  //     },
  //     {
  //       topic: "Updated Date Time",
  //       description: moment(maintenanceRequestData.auditDateAndTime).format("MM/DD/YYYY hh:mm:ss A"),
  //     },
  //     {
  //       topic: "Is this issue urgent? ",
  //       description: maintenanceRequestData.isUrgent ? "Yes" : "No",
  //     },
  //     {
  //       topic: "Is this issue causing a threat to personal safety?",
  //       description: maintenanceRequestData.isSafty ? "Yes" : "No",
  //     },
  //     {
  //       topic: "Created By",
  //       description: `${maintenanceRequestData.createdByDetails?.firstName || 'Unknown'
  //         } ${maintenanceRequestData.createdByDetails?.lastName || 'Unknown'
  //         }`,
  //     },
  //     {
  //       topic: "Status",
  //       description: CustomPascalChange(status),
  //     },
  //     {
  //       topic: "Address",
  //       description: address,
  //     },
  //     {
  //       topic: "Request Type",
  //       description: maintenanceRequestData.type,
  //     },
  //     {
  //       topic: "Attachments",
  //       component: "AttachmentList",
  //       props: {
  //         fileUrl: fileUrl,
  //         documentName: fileUrl,
  //         docname: fileUrl,
  //         uploadedTimes: createdDateAndTimes,
  //       },
  //     },
  //   ],
  // };
  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '' || str === null) return '';
  
    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };
  let formattedContact = "";
if (createdToDetails?.contact) {
  formattedContact = createdToDetails.contact.replace(/^\+\d+\s?(\d{3})(\d{4})(\d{3})$/, "$1-$2-$3");
}
const fileList = fileImage.map(file => ({
  docname: file.file.substring(12), // Assuming file name is the document name
  fileUrl: file.file, // Assuming file location is the file URL
  documentnamed: file.file, // Assuming file name is the document name
  uploadedTimes: moment(file.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"), // Assuming uploadedTimes is available
  }));
  const data = [
    {
      groupName: 'Maintenance Details',
      items: [
        { label: 'MaintenanceRequest Id', data: `${maintenanceRequestData?.mrId || ""}`, text: 'Unique identifier for the maintenance request.' },
        { label: 'What needs attention?', data: `${maintenanceRequestData?.topic || ""}`, text: 'Description of the issue or topic that needs attention.' },
        { label: 'More Information', data: `${maintenanceRequestData?.description || ""}`, text: 'Additional information or details about the maintenance request.' },
        { label: 'Is this issue urgent?', data: `${maintenanceRequestData.isUrgent ? "Yes" : "No" || ""}`, text: 'Indicates whether the issue requires immediate attention (Yes/No).' },
        { label: 'Is this issue causing a threat to personal safety?', data: `${maintenanceRequestData.isSafty ? "Yes" : "No" || ""}`, text: 'Indicates whether the issue poses a threat to personal safety (Yes/No).' },
        { label: 'Status', data: `${convertToPascalCase(maintenanceRequestData?.status) || ""}`, text: 'Current status of the maintenance request.' },
        { label: 'Request Type', data: `${maintenanceRequestData?.type || ""}`, text: 'Type or category of the maintenance request.' },
        { label: 'ByLaws Topic', data: `${maintenanceRequestData.byLawDetails?.topic || ""}`, text: 'The topic of the bylaw related to the compliance issue' },
        { label: 'ByLaws Description', data: `${maintenanceRequestData.byLawDetails?.description || ""}`, text: 'The description of the bylaw related to the compliance issue' },
      ],
    },
    {
      groupName: 'Date Details',
      items: [
        {
          label: 'Start Date',
          data: maintenanceRequestData?.startDate && moment(maintenanceRequestData.startDate).isValid() ? moment(maintenanceRequestData.startDate).format("MM/DD/YYYY") : "",
          text: 'Date and time when the maintenance request was initiated.'
        },
        {
          label: 'End Date',
          data: maintenanceRequestData?.endDate && moment(maintenanceRequestData.endDate).isValid() ? moment(maintenanceRequestData.endDate).format("MM/DD/YYYY") : "",
          text: 'Date and time when the maintenance request is expected to be completed.'
        },  
        // { 
        //   label: 'Requested Date Time', 
        //   data: moment(maintenanceRequestData?.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss A") || "", 
        //   text: 'Date and time when the maintenance request was submitted.' 
        // },
        // { 
        //   label: 'Updated Date Time', 
        //   data: moment(maintenanceRequestData?.auditDateAndTime).format("MM/DD/YYYY hh:mm:ss A") || "", 
        //   text: 'Date and time when the maintenance request was last updated.' 
        // },
      ],
    },
    {
      groupName: 'Created By Details',
      items: [
        {
          label: 'Firstname',
          label2: 'Lastname',
          data: `${createdByDetails?.firstName || ""}`,
          text: 'First name of the person or entity to whom the maintenance request was created',
          data2: `${createdByDetails?.lastName || ""}`
        },
        { label: 'Email', data: `${createdByDetails?.email || ""}`, text: 'Email address of the person or entity to whom the maintenance request was created' },
        { label: 'Address', data:`${maintenanceRequestData.homeDetails?.address ?? ""}`, text: 'Home address of the person or entity to whom the maintenance request was created' },
        // { label: 'Contact', data: `${formattedContact}`, text: 'Contact number of the person or entity to whom the compliance request was created' },
        // { label: 'Contact Category', data: "", text: 'Category or type of contact information provided' },
      ],
    },
    
    {
      groupName: 'Attachment Details',
      items: [
        { label: 'Attachment', data: (
          <AttachmentListsView fileList={fileList} />
          ), text: 'List of attached files/documents related to the maintenance request.' },
      ],
    },
  ];
    if (maintenanceRequestData.role == 'ROLE_MC') {
      data.push({
        groupName: 'Created To Details',
        items: [
          {
            label: 'Firstname',
            label2: 'Lastname',
            data: `${createdToDetails?.firstName || ""}`,
            text: 'First name of the person or entity to whom the compliance request was created',
            data2: `${createdToDetails?.lastName || ""}`
          },
          { label: 'Email', data: `${createdToDetails?.email || ""}`, text: 'Email address of the person or entity to whom the compliance request was created' },
          { label: 'Address', data: `${groupNames || ""}`, text: 'Home address of the person or entity to whom the compliance request was created' },
          { label: 'Contact', data: `${formattedContact}`, text: 'Contact number of the person or entity to whom the compliance request was created' },
          
        ],
      });
    }
 

  const handleCheckboxChange = () => {
    if (!checked) {
      setConfirmationOpen(true);
      setIsModalOpen(true); // Set isModalOpen to true when opening the modal
    } else {
      setChecked(false);
    }
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
    setIsModalOpen(false);
  };

  const handleConfirmationConfirm = () => {
    setChecked(true);
    setConfirmationOpen(false);
    setIsModalOpen(false);
  };

  const bmCardModelAction = (
    <>
      <CustomButton
        buttonName="Cancel"
        click={handleConfirmationClose}

      />
      <CustomButton
        fieldType="submit"
        buttonName="OK"
        click={handleConfirmationConfirm}
      />

    </>
  );

  const bmCardContent = (
    <>
      <form onSubmit={handleSubmitReviewComments(handleConfirmationConfirm)}>
        <DialogContent dividers>
          <div className="flex mb-5 mt-5">Are you sure you want to send this to board?</div>
        </DialogContent>


        <DialogActions>{bmCardModelAction}</DialogActions>
      </form>
    </>)

  const okCancelButtonActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Cancel"
        click={handleDeleteClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Ok"
        click={handleDeleteClose}
      />

    </>
  );

  const deleteModel = (
    <form
      onSubmit={handleSubmitGeneralComments(onSubmitDelete)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography> Are you sure you want to delete comment?</Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );

  const rowActions = ({ rowData, tabIndex }) => {
    return (
      <div className="text-center flex space-x-3">
        {selectedOption === "PENDING APPROVAL"  && (rowData.createdFor === userDetails.homeOwnerLoginId || rowData.createdFor === userLoginDetails.homeOwnerLoginId) && (
          <>
           <CustomMainButton fieldType="submit" buttonName="Approve" disabled={checked} click={handleSubmitReviewComments(onApproveSubmit)} tooltipText={"Approve is disabled because this request will be sent to the board."}/>
           <CustomMainButton fieldType="submit" buttonName="Reject" disabled={checked} click={handleSubmitReviewComments(onRejectSubmit)}tooltipText={"Reject is disabled because this request will be sent to the board."} color="error" />
          </>
        )}
      </div>
    );
  };

  return (
    <div>
      {/* <CustomViewPages data={data} exportedBy={true}
       handleExportss={handleClickPdf} checked={includeComments}
       handleCheckBoxChange={handleCheckboxChanges}/> */}
        <CustomViewHeading onClick={handleClick}  title={"Maintenance Request"} data={data} fileList={fileList} information={(maintenanceRequestData.description)} attention={maintenanceRequestData.topic}>
        
      <CustomViewWithMore data={data}  />
      {isLoading && <CustomLoading />}
      <CustomToastContainer />
      <br />
      <CustomModelView
        title="Maintenance Request"
        submit={onApproveSubmit}
        content={approveModelContent}
        action={approvalModelAction}
        openStatus={approvalOpen}
        closeStatus={handleApprovalClose}
        iconCloseStatus={handleIconClose}
      />
      <CustomModelView
        title="Send to board"
        content={bmCardContent}
        action={bmCardModelAction}
        openStatus={confirmationOpen}
        closeStatus={handleConfirmationClose}
        iconCloseStatus={handleConfirmationClose}
      />
      <CustomModelView
        title="Maintenance Request"
        submit={onRejectSubmit}
        content={rejectModelContent}
        action={rejectModelAction}
        openStatus={rejectOpen}
        closeStatus={handleRejectClose}
        iconCloseStatus={handleRejectIconClose}
      />
{roles.join(', ') === "ROLE_ARC" && (status === "PENDING_IN_ARC" || status === "PENDING" || status === "ACKNOWLEDGED") && (
  <Card>
    <form className="space-y-3 md:space-y-3 m-3">
      <CardContent>
        <section className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-3 gap-2">
          {/* Status Component */}
          <div className="col-span-1">
            <CustomOptions
              label="Status"
              options={options}
              value={selectedOption}
              onChange={handleSelectChange}
              disabled={isAssignedToBm}
              fieldRequiredIcon="*"
            />
          </div>

          {/* Bylaw and Bylaw Description Combined Row */}
          <div className="col-span-2 md:col-span-3 grid grid-cols-1 md:grid-cols-2 gap-2">
  {/* Select Bylaw Component */}
  <div className="flex">
    <div style={{ width: '100%' }}> {/* Full width for Select Bylaw */}
      <CustomAutoComplete
        fieldName="selectedBylaw"
        id="selectId"
        options={renderedBylaw}
        fieldLabel="Select Bylaw"
        fieldControl={controlReviewComments}
        setValue={setValueReviewComments}
        onChange={handleByLawChange}
        value={selectedBylawValue}
        isRequired={false}
        invalidOption={BYLAW_REQUIRED_MESSAGE}
        bylawNull="no bylaw"
        disabled={!bylawsUpdated}
        style={{ height: '48px' }} // Optional: Set a fixed height
      />
    </div>
  </div>

  {/* Bylaw Description Component */}
  <div className="flex flex-col mt-1"> {/* Adjusted margin-top for less space */}
    {byLawDescription && (
      <Box>
        <Typography style={{ fontSize: "16px", color: "grey" }}>Bylaw Description</Typography>
        <div
          style={{
            maxHeight: '100px', // Set a maximum height
            overflowY: 'auto', // Enable vertical scrolling
            border: '1px solid #ccc', // Optional: Add border to distinguish area
            borderRadius: '4px', // Optional: Add border radius
            padding: '8px', // Optional: Add padding
            height: '100px', // Set a fixed height for consistent layout
          }}
        >
          {/* Display the bylaw description directly */}
          <Typography style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {byLawDescription}
          </Typography>
        </div>
      </Box>
    )}
  </div>
</div>



          {/* Review Comment Field */}
          <div className="col-span-full md:col-span-4">
            <CustomInputField
              fieldName="reviewComment"
              requiredErrorMessage={REVIEW_COMMENT_REQUIRED_MESSAGE}
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldId="reviewComment"
              fieldType="text"
              fieldLabel="Review Comment"
              fieldControl={controlReviewComments}
              fieldError={errorsReviewComments}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={3}
              tooltipMessage={"Review comments are required for Approve/Reject"}
            />
          </div>

          {/* File Upload Section */}
          <div className="col-span-full md:col-span-4 pt-5">
            <CustomFile
              handleFileChange={handleFileChange}
              token={token}
              fileError={fileError}
              file={file}
            />
          </div>

          {/* Checkbox for sending to board */}
          {!isAssignedToBm && (
            <div className="col-span-full md:col-span-4 pt-5">
              <div onClick={(e) => e.stopPropagation()} className='float-left'>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleCheckboxChange}
                      color="primary"
                      size="small"
                    />
                  }
                  label={<span style={{ fontSize: '14px' }}>Send to board? </span>}
                />
              </div>
            </div>
          )}
        </section>

        {/* Buttons Section */}
        <div className="text-center mt-10 flex space-x-3">
          {!isAssignedToBm && (
            <>
              <CustomButton fieldType="submit" buttonName="Update" click={handleSubmitReviewComments(onUpdateSubmit)} />
              <CustomMainButton
                fieldType="submit"
                buttonName="Approve"
                disabled={checked}
                click={handleSubmitReviewComments(onApproveSubmit)}
                tooltipText={"Approve is disabled because this request will be sent to the board."}
              />
              <CustomMainButton
                fieldType="submit"
                buttonName="Reject"
                disabled={checked}
                click={handleSubmitReviewComments(onRejectSubmit)}
                tooltipText={"Reject is disabled because this request will be sent to the board."}
                color="error"
              />
            </>
          )}
        </div>
      </CardContent>
    </form>
  </Card>
)}


<br />
<br />

 {/* <CustomReviewTable columns={viewDataTable} data={reviewComments} /> */}
      <CustomReviewTableWithoutActions
                columns={viewDataTable}
                data={reviewComments}
              />
      <br />

      {/* <CustomHeading title="Comments" />
      <CustomModel
        title="Comments"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        reset={resetGeneralComments}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      />
      <Card>
      <form
           onSubmit={handleSubmitGeneralComments(onSubmit)}

>
  <CardContent>
    <div className="flex items-center space-x-4">
      <CustomInputField
        fieldName="comments"
        // requiredErrorMessage={COMMENTS_REQUIRED_MESSAGE}
        minLength={2}
        minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
        maxLength={1000}
        maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
        fieldId="comments"
        fieldType="text"
        fieldLabel="Write a Comment"
        fieldControl={controlGeneralComments}
        fieldError={errorsGeneralComments}
        fieldRequiredIcon="*"
        multiline={true}
        numberOfRows={2}
        value={generalCommentsDataAdd}
        onInput={handleInputChangeAdd}
        className="flex-grow"
      />
      <CustomButton fieldType="submit" buttonName="Post" />
      {/* {isLoading && <CustomLoading />} 
    </div>
  </CardContent>
</form>


        <CardContent>
          {generalCommentDetails &&
            generalCommentDetails.map((comment, index) => (
              <div key={comment.generalCommentsId}>
                <section className="grid md:grid-cols-1 xl:grid-cols-1 gap-3  ml-5">
                  <p>
                    <span className="text-primary " style={{ float: "left" }}>
                      @{comment.createdByName}{" "}
                    </span>
                    <span style={{ float: "right" }}>
                     
                      {!editModes[index] && comment.createdById === userLoginDetails.homeOwnerLoginId && (
                        <>
                          {index === 0 && (
                            <>
                              {" "}
                            
                              <EditNoteOutlined
                                color="primary"
                                onClick={() => {
                                  handleEditClick(
                                    index,
                                    comment.generalCommentsId
                                  );
                                  console.log(comment.generalCommentsId);
                                }}
                              />{" "}
                            
                              <DeleteOutline
                                color="error"
                                onClick={() => {
                                  handleDeleteClickOpen(
                                    comment.generalCommentsId
                                  );
                                  console.log(comment.generalCommentsId);
                                }}
                              />
                            </>
                          )}
                        </>
                      )}
                       {moment(comment.createdDateAndTime).format(
                        "MM/DD/YYYY HH:mm:ss"
                      )}
                    </span>

                    {editModes[index] ? (
                      <form
                        onSubmit={handleSubmitGeneralComments(submitUpdate)}
                        className="space-y-4 md:space-y-6 "
                      >
                        <CardContent>
                          <CustomInputField
                            fieldName="editComments"
                            minLength={2}
                            minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
                            maxLength={1000}
                            maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
                            fieldId="editComments"
                            fieldType="text"
                            // fieldLabel="Write a Comments"
                            fieldControl={controlGeneralComments}
                            errors={errorsGeneralComments}
                            // fieldRequiredIcon="*"
                            multiline={true}
                            numberOfRows={2}
                            value={generalCommentsData}
                            onInput={handleInputChange}
                          />

<div className="flex justify-center mt-8 space-x-1">
  <CustomButton
    fieldType="submit"
    buttonName="Save"
  />
  <CustomButton
    fieldType="button"
    buttonName="Cancel"
    click={handleCancelClick}
  />
  {isLoading && <CustomLoading />}
</div>
                        </CardContent>
                      </form>
                    ) : (
                      <p className="mt-8 mb-2 break-words">
                        {comment.comments}
                      </p>
                    )}
                  </p>
                </section>

                <Divider />
              </div>
            ))}
        </CardContent>
      </Card>  */}
      <CustomGeneralComments />
</CustomViewHeading>
    </div>
  )
}

export default ArcMaintenanceView