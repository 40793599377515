import React, { useEffect, useMemo, useState } from "react";
import CustomHeading from "../../components/CustomHeading";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import CustomMaterialWithoutActons from "../../components/CustomMaterialWithoutActons";

import { useNavigate } from "react-router-dom";
import { ListAlt } from "@mui/icons-material";
import CustomButton from "../../components/CustomButton";
import CustomModel from "../../components/CustomModel";
import {
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import CustomInputField from "../../components/CustomInputField";
import {
  CATEGORY_REQUIRED_MESSAGE,
  DESCRIPTION_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
} from "../../utills/ApplicationConstants";
import { Controller, useForm } from "react-hook-form";
import { FileUploader } from "react-drag-drop-files";
import { useSelector } from "react-redux";
import axios from "axios";
  import { BASE_URL, INVOICE,REVIEW_INVOICE } from "../../utills/ApplicationRouting";
import { request } from "../../services/AxiosConfig";
import CustomToastContainer from '../../components/CustomToastContainer';
import CustomLoading from "../../components/CustomLoading";

function InvoiceList() {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const fileTypes = [
    "JPG",
    "PNG",
    "JPEG",
    "PDF",
    "DOC",
    "XLS",
    "XLSX",
    "TIF",
    "TIFF",
    "DOCX",
  ];
  const navigate = useNavigate();
  const { token, roles } = useSelector((state) => state.hybridhoa);
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [firstNameData, setFirstNameData] = useState("");
  const [lastNameData, setLastNameData] = useState("");
  const [categoryChange, setCategoryChange] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");
console.log("roles",roles);
 

  const handleViewClick = (selectedRow) => {
    navigate(`/reviewinvoiceview/${selectedRow.original.invoiceId}`);
  };

  const handleFileChange = (file) => {
    setFile(file);
  };

  const handleAddClickOpen = () => {
    setOpen(true);
    
  };
  const handleAddClose = (status) => {
    if (status === 200) {
      setOpen(false);
      setSelectedCategory('');
      setFile('');
    }
  };
  const handleIconClose = () => {

    setOpen(false);
    setSelectedCategory('');
    setFile('');
  };

  const {
    handleSubmit,
    control,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    clearErrors("category");
  };

  const onSubmit = (data) => {
    const formData = new FormData();

    formData.append("description", data.description);
    formData.append("file", file);
    formData.append("category", selectedCategory);
    formData.append("role",roles);

    console.log("description", data.description);
    console.log("category", selectedCategory);
    console.log("file:", file);
    if (!selectedCategory) {
      setError("category", {
        type: "manual",
        message: CATEGORY_REQUIRED_MESSAGE,
      });
      return;
    }
    // return;
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    request({ url: REVIEW_INVOICE, method: "post", data: formData, headers })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");
          getInvoiceDetails();
          setIsLoading(false);
          handleAddClose(res.status);
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const getInvoiceDetails = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${REVIEW_INVOICE}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        // const createdByDetailsArray = data.map((item) => item.createdByDetails);
        // createdByDetailsArray.forEach((createdByDetails) => {
        //   console.log(createdByDetails.firstName);
        //   setFirstNameData(createdByDetails.firstName);
        //   setLastNameData(createdByDetails.lastNameData);
        //   console.log(createdByDetails.lastName);
        //   console.log("CreatedByDetails:", createdByDetails);
        // });
        setInvoiceDetails(data);

        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getInvoiceDetails();
  }, []);

  const eventTable = useMemo((rowData) => [
    {
      accessorKey: "invId",
      header: "Invoice Id",
      size: 20,
    },
    {
      accessorKey: "category",
      header: "Invoice Type",
    },
    // {
    //   accessorKey: "description",
    //   header: "Description",
    // },

    {
      accessorKey:"description",
      header:"Description",
      accessorFn: (row) => {
        const description = row && row.description;
        if (description && description.length > 28) {
          return description.slice(0, 28) + "...";
        }
        return description;
      }
    },
    // in list page name search is not working fixed started
    // {
    //   accessorKey: "createdBy",

    //   // header: "Created By",
    //   header: "Name ",

    //   Cell: ({ row }) =>
    //   `${row.original.createdByDetails?.firstName} ${row.original.createdByDetails?.lastName}`,
 
    // },
   
    {
      accessorKey: "createdBy",
      header: "Name",
      accessorFn: (row) => `${row.createdByDetails?.firstName} ${row.createdByDetails?.lastName}`,
    },
    // in list page name search is not working fixed end
   
    
    // {
    //   accessorKey: "status",
    //   header: "Status",
    // },
    {
      accessorKey: "status",
      header: "Status",
      Cell: ({ row }) => {
        const status = row.original.status;
        return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
      },
    },
  ]);

  const rowActions = [
    {
      label: "View",
      icon: <ListAlt color="primary" />,
      click: (row) => handleViewClick(row),
    },
  ];

  const tableActionButtons = (
    <>
      {/* <CustomButton
        fieldType="button"
        buttonName="Add"
        click={handleAddClickOpen}
      /> */}
    </>
  );
  const handleViewClicks = (selectedRow)=>{
    navigate (`/reviewinvoiceview/${selectedRow.row.original.invoiceId}`);
}

  const addActionButton = (
    <CustomButton fieldType="submit" buttonName="Add" click={handleAddClose} />
  );
  const addModel = (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography gutterBottom>
            <CustomInputField
              fieldName="description"
              requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
              fieldId="description"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={5000}
              maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
              fieldLabel="Description"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
            />

            <br />
            <br />

            <Controller
              name="category"
              control={control}
              // rules={{ required: CATEGORY_REQUIRED_MESSAGE }}
              render={({ field, fieldState }) => (
                <FormControl
                  variant="standard"
                  fullWidth
                  error={fieldState.invalid}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Please Select Category{" "}
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    label="Please Select Category"
                  >
                    <MenuItem value="Purchases">Purchases</MenuItem>
                    <MenuItem value="Sales">Sales</MenuItem>
                    <MenuItem value="Suppliers">Suppliers</MenuItem>
                    <MenuItem value="Expenses">Expenses</MenuItem>
                   
                  </Select>

                  <FormHelperText style={{ color: "#D32F2F" }}>
                    {fieldState.invalid ? fieldState.error?.message : ""}
                  </FormHelperText>
                </FormControl>
              )}
            />
            <br />
            <br />

            <FormLabel>
              Upload <span style={{ color: "red" }}>*</span>
              <FileUploader
                handleChange={handleFileChange}
                name="file"
                types={fileTypes}
                required
              />
              {file ? (
                <p>Selected File: {file.name}</p>
              ) : (
                <p>No file selected</p>
              )}
            </FormLabel>
          </Typography>
        </DialogContent>
        <DialogActions>{addActionButton}</DialogActions>
      </form>
    </>
  );

  return (
    <div>
      <CustomHeading title="Review Invoice" />
      {isLoading && <CustomLoading/>}
      <CustomToastContainer/>
  
      <CustomMaterialWithoutActons
        columns={eventTable}
        data={invoiceDetails}
        rowActions={rowActions}
        actionButtons={tableActionButtons}
        // isLoading={isLoading}
        onRowClick = {handleViewClicks}
        
      />
      <CustomModel
        title="Upload Invoice"
        submit={onSubmit}
        content={addModel}
        action={addActionButton}
        openStatus={open}
        closeStatus={handleAddClose}
        iconCloseStatus={handleIconClose}
        reset={reset}
      />
    </div>
  );
}

export default InvoiceList;
