import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CustomHeader from "./CustomHeader";
import CustomFooter from "./CustomFooter";
import logo from "../assets/img/logo.png";
import { Link } from "react-router-dom";
import {
  GET_ACTIVE_GIF,
  BASE_URL,
  BASE_URL_IMAGE,

} from "../../src/utills/ApplicationRouting";
import axios from "axios";
import { useSelector } from "react-redux";
function CustomLayout({ children }) {
  const location = useLocation();
  const [gifPreview, setGifPreview] = useState(null);
  const { token } = useSelector((state) => state.hybridhoa);
  // const { token } = useSelector((state) => state.hybridhoa);

  const [gifPreviewOld, setGifPreviewOld] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const getNotificationDetailsById = async () => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${GET_ACTIVE_GIF}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (response.status === 200) {
        console.log(response.data);

        const data = response.data;

        if (data) {
          const imageUrls = response.data.map(item => item.imageUrl);
          console.log(imageUrls.length);

          setGifPreviewOld(imageUrls);  // Show old GIF preview initially
        }


      } else {

        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getNotificationDetailsById();
  }, []);

  useEffect(() => {
    if (gifPreviewOld.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % gifPreviewOld.length);
      }, 5000); // Change slide every 5 seconds

      return () => clearInterval(interval);
    }
  }, [gifPreviewOld]);



  const getFile = async () => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL_IMAGE}/${gifPreviewOld}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
        responseType: "blob",
      });
      const blob = response.data;
      const fileURL = URL.createObjectURL(blob);
      setGifPreview(fileURL);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (gifPreviewOld) {
      getFile();
    }
  }, [gifPreviewOld]);
  // Define an array of URLs where you want to hide the content
  const forbiddenUrls = ["/userrole", "/contactverification"];

  // Check if the current URL is in the forbiddenUrls array
  const shouldShowContent = !forbiddenUrls.includes(location.pathname);
  useEffect(() => {
    window.scrollTo(0, 0);
  },[location])
  return (
    <>

      {shouldShowContent && (<div> <CustomHeader />

      // {/* <CustomSideNavBar /> */}


        <div className="p-4 sm:ml-64">
          <div className="p-4 mt-4">{children}</div>

          {gifPreviewOld.length > 0 && (
          <div className="slideshow-wrapper" style={{ display: 'flex', transition: 'transform 1s ease-in-out', width: '100%', position: 'relative' }}>
            {gifPreviewOld.map((item, index) => (
              <img
                key={index}
                src={`${BASE_URL_IMAGE}/${item}`}
                alt={`GIF Preview ${index + 1}`}
                style={{
                  minWidth: '100%',
                  transition: 'opacity 1s ease-in-out',
                  opacity: index === currentIndex ? 1 : 0,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: 'auto',
                  width: 'auto',
                  maxWidth: 'auto',
                  maxHeight: '125px',
                  borderRadius: '8px',
                }}
              />
            ))}
          </div>
        )}
        </div>
        {/* <div className="slideshow-container" style={{ position: 'relative', height: 'auto', width: '100%' }}> */}


        <br />


        {/* <CustomFooter />  */}
      </div>
      )}
    </>
  );
}

export default CustomLayout;
