
import React, { useEffect, useMemo, useState } from "react";

import CustomInputField from '../../components/CustomInputField'
import { Card, CardContent, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import CustomFile from '../../components/CustomFile'

import CustomToastContainer from '../../components/CustomToastContainer'
import CustomHeading from '../../components/CustomHeading'
import CustomButton from '../../components/CustomButton'
import CustomFileEdit from "../../components/CustomFileEdit";

import {
    CATEGORY_REQUIRED_MESSAGE,
    DESCRIPTION_REQUIRED_MESSAGE,
    MAX_LENGTH_FIVE_THOUSAND,
    MIN_LENGTH_VALIDATE_MESSAGE,
  } from "../../utills/ApplicationConstants";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL, INVOICE } from "../../utills/ApplicationRouting";
import axios from "axios";
import { request } from "../../services/AxiosConfig";
import { useSelector } from "react-redux";
import CustomLoading from '../../components/CustomLoading';
import CustomTooltipField from "../../components/CustomTooltipField";
import { InfoOutlined } from "@mui/icons-material";
import CustomFileInvoiceEdit from "../../components/CustomFileInvoiceEdit";



function InvoiceEdit() {
    // const handleViewClick = () => {
    //     navigate(-1);
    //   };
   
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        setError,
        watch,
        clearErrors ,
      } = useForm()
      const navigate = useNavigate();
  const [file, setFile] = useState([]);
  const [fileData,setFileData] = useState([]);
  const [fileOld,setFileOld] = useState([]);

      // const fileTypes = ["JPG", "PNG", "GIF"];
      const fileTypes = [
        "JPG",
        "PNG",
        "JPEG",
        "PDF",
        "DOC",
    "XLS",
    "XLSX",
    "TIF",
    "TIFF",
    "DOCX",
        
      ];

     
      const [invoiceData,setInvoiceData] = useState([]);
      const [fileUrl,setFileUrl]=useState("");
      const [createdDateAndTime,setCreatedDateAndTime]=useState("");
      const [isLoading, setIsLoading] = useState(true);
  const [fileError, setFileError] = useState("");
  const[description,setDescription]=useState("");
  const {invoiceId} = useParams();
  const { token, roles } = useSelector((state) => state.hybridhoa);
  const{des,setDes}=useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    clearErrors("category");
  };

  const handleFileOldChange = (fileOld) => {
    console.log("fileold"+fileOld);
    setFileOld(fileOld);
    }
      const getInvoiceDetailsById = async () => {
        try {
              const storedToken = token
    
          console.log(storedToken);
          
            const response= await axios.get(`${BASE_URL}${INVOICE}/${invoiceId}`,{
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });
                  console.log(response.data); 
    
          if (response.status === 200) {
            const data = response.data;
           
            setFileUrl(data.fileUrl);
            setDescription(data.description)
            setSelectedCategory(data.category)
            console.log(data.fileUrl);
            setIsLoading(false);
            setFileData(data.attachments);
           
    
    
          } else {
            setIsLoading(false);
            throw new Error('Failed to fetch data');
          }
         
        } catch (error) {
          // Handle error
          setIsLoading(false);
          console.error("Error fetching data:", error);
        }
      };
    
    
    
    useEffect(()=>{
      getInvoiceDetailsById();
    },[invoiceId])

    useEffect(()=>{
  console.log("descripation",description);
    },[description])

    useEffect(() => {
      console.log("file",file);
       if(file.length !== 0){
       setFileError("")
       }
       }, [file]);
    const handleClick = () => {
        navigate(-1);
      };

     
      
        
        

      const onSubmit = (data) => {
        if (!description || !/\S/.test(description)|| description.trim() === "") {
          setError("description", {
            type: "manual",
            message: DESCRIPTION_REQUIRED_MESSAGE,
          });
          return;
        }
        // if(file.length===0 && fileData.length===0){
        //   setFileError("Attachment is Required")
        //   return;
        //   };
      //   if (!file || fileData.length == 0) {
      //     setFileError("Attachment is Required");
      //     return;
      // }
      if(file.length ===0 && fileOld.length ===0){


        setFileError("Attachment is Required");
        return;
        }

          
          const totalFileSize = file.reduce((acc, file) => acc + file.size, 0);
const maxSizeInMB = 10;
const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

if (totalFileSize > maxSizeInBytes) {
// setFileError(`Total file size should not exceed ${maxSizeInMB}MB.`);
return;
}

// Check file types
// const allowedFileTypes = ["image/jpg", "image/png", "image/jpeg", "application/pdf"];
const allowedFileTypes = [ "image/jpg", "image/jpeg", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "image/tiff" ];
const invalidFiles = file.filter(file => !allowedFileTypes.includes(file.type));

if (invalidFiles.length > 0) {
// setFileError("Some files have invalid file types. Allowed types are JPG, PNG, JPEG, PDF.");
return;
}
setIsLoading(true);
        const formData = new FormData();
    
        formData.append("description", description);
        file.forEach((file) => {
          formData.append('files', file);
          });
        formData.append("category", selectedCategory);
        formData.append("role",roles);
    
        console.log("description", data.description);
        console.log("category", selectedCategory);
        console.log("file:", file);
        if (!selectedCategory) {
          setError("category", {
            type: "manual",
            message: CATEGORY_REQUIRED_MESSAGE,
          });
          return;
        }
        // return;
        const headers = {
          "Content-Type": "multipart/form-data",
        };
    
        request({ url: `${INVOICE}/${invoiceId}`, method: "put", data: formData, headers })
          .then((res) => {
            console.log(res);
            if (res.status == 200) {
              console.log("Success");
             setIsLoading(false)

              setTimeout(() => {
                navigate(-1);
              }, 4000);
            }
          })
          .catch((error) => {
            console.log(error.response);
            if (error.response) {
              setIsLoading(false);
              console.log("error.response.status" + error.response.status);
            }
          });
      };
      const handleFileChange = (file) => {
        setFile(file);
        };


      const renderTooltip = (message) => (
        <Tooltip title={<Typography sx={{ fontSize: "14px" }}>{message}</Typography>} placement="right">
          <IconButton>
            <InfoOutlined />
          </IconButton>
        </Tooltip>
      );
    
  return (

    <div>
          <div class="flex flex-col md:flex-row">
        <CustomHeading title="Edit Invoice" />

        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleClick}
          />
        </div>
      </div>
      <Card>
      <form  className="space-y-4 md:space-y-6 "  onSubmit={handleSubmit(onSubmit)} >
          <CardContent>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
        
      
            <CustomTooltipField
  fieldName="description"
  fieldId="description"
  minLength={2}
  minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
  maxLength={5000}
  maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
  fieldLabel="Description"
  fieldControl={control}
  fieldError={errors}
  fieldRequiredIcon="*"
  multiline={true}
  numberOfRows={2}
  value={description}
  onInput={(e) => {
    const value = e.target.value;
    setDescription(value);

    if (!/\S/.test(value)) {
      setError("description", {
        type: "manual",
        message: DESCRIPTION_REQUIRED_MESSAGE,
      });
    } else {
      clearErrors("description");
    }
  }}
  tooltipMessage={"Please enter a detailed description."}
/>

<div className="flex items-center md:w-1/2 ">
<div className="w-full ">
            <Controller
              name="category"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl variant="standard" fullWidth error={fieldState.invalid}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Please Select Category <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    label="Please Select Category"
                  >
                    <MenuItem value="Purchases">Purchases</MenuItem>
                    <MenuItem value="Sales">Sales</MenuItem>
                    <MenuItem value="Suppliers">Suppliers</MenuItem>
                    <MenuItem value="Expenses">Expenses</MenuItem>
                  </Select>

                  <FormHelperText style={{ color: "#D32F2F" }}>
                    {fieldState.invalid ? fieldState.error?.message : ""}
                  </FormHelperText>
                </FormControl>
              )}
            />
           </div>
  <div>
    {renderTooltip("Please select a category from the list.")}
  </div>
</div>


            {/* <FormLabel>
              Upload <span style={{ color: "red" }}>*</span>
              <FileUploader
                handleChange={handleFileChange}
                name="file"
                types={fileTypes}
                required
              />
              {file ? (
                <p>Selected File: {file.name}</p>
              ) : (
                <p>No file selected</p>
              )}
            </FormLabel> */}
            {/* <CustomFile fileData={fileUrl} downloadFiles={true} handleFileChange={handleFileChange} token={token} fileError={fileError} isRequired={true} required/> */}
            {/* <CustomFileEdit file={file} handleFileChange={handleFileChange} token={token} fileError={fileError} required={true} isRequired={true} fileData={fileData}/> */}
            {/* <CustomFileEdit downloadFiles={true} fileData={fileData} handleFileChange={handleFileChange} token={token} file={file} isRequired={true} required={true}/> */}
            <CustomFileInvoiceEdit downloadFiles={true} fileData={fileData} handleFileChange={handleFileChange} token={token} file={file} isRequired={true} required={true} fileError={fileError} onFileOldChange={handleFileOldChange}/>
            </section>

            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Update" />
              {isLoading && <CustomLoading />}
            </div>
          </CardContent>
        </form>
      </Card>
      <CustomToastContainer />
    </div>
  )
}

export default InvoiceEdit