import { useSelector } from "react-redux";
import CustomViewPage from "../../components/CustomViewPage";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL, HOME_OWNER_DUES } from "../../utills/ApplicationRouting";
import moment from "moment";
import CustomViewHeading from "../../components/CustomViewHeading";
import CustomViewWithTooltip from "../../components/CustomViewWithTooltip"
function McBmArReportPaidView() {
  const navigate = useNavigate();
  const { homeOwnerPaymentId } = useParams();
  const { token } = useSelector((state) => state.hybridhoa);
  const [isLoading, setIsLoading] = useState(true);
  const [dueData, setDueData] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [homeOwnerPayment,setHomeOwnerPayment]=useState("")
  const handleClick = () => {
    navigate(-1);
  };
  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === '' || str === null) return '';
  
    // Remove underscores and convert string to Pascal case
    return str.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const getNotificationDetailsById = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(
        `${BASE_URL}${HOME_OWNER_DUES}/${homeOwnerPaymentId}?status=PAID`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data.homeOwnerPayments[0];
        console.log(data);
        const homeOwnerDetails = data.homeOwnerDetails[0];
        setHomeOwnerPayment(data)
        if (homeOwnerDetails) {
          setFirstName(homeOwnerDetails.firstName);
          setLastName(homeOwnerDetails.lastName);
        }
        //  setFirstName(homeOwnerDetails.firstName);
        //  setLastName(homeOwnerDetails.lastName);
         setAddress(homeDetails[0].addressForCommunication);
        setDueData(data);
        console.log("data", data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false)
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getNotificationDetailsById();
  }, [homeOwnerPaymentId]);

  const fullName = firstName + " " + lastName;

  // const data = {
  //   pageTitle: "AR Reportss",
  //   buttons: [
  //     {
  //       buttonTitle: "Back",
  //       onClick: handleClick,
  //     },
  //   ],
  //   content: [
  //     {
  //       topic: "Title",
  //       description: `${homeOwnerPayment.title?? ""}`,
  //     },
  //     {
  //       topic: "Description",
  //       // description: `${complainceDetails.createdToDetails?.firstName ?? ""} ${complainceDetails.createdToDetails?.lastName ?? ""}`,
  //       description: `${homeOwnerPayment.description?? ""}`
  //        },
  //     {
  //       topic: "Due Type",
  //       description: `${homeOwnerPayment.dueType?? ""}`,
  //     },
  //     {
  //       topic: "Status",
  //       description: convertToPascalCase(homeOwnerPayment.status?? "") ,
        

  //     },
  //     {
  //       topic: "Amount",
  //       description: `${homeOwnerPayment.dueAmount?? ""}`,
  //     },
  //     {
  //       topic: "HO Name",
  //       description: `${homeOwnerPayment.homeOwnerDetails?.firstName ?? ""} ${homeOwnerPayment.homeOwnerDetails?.lastName ?? ""}`,
  //     },
  //     {
  //       topic: "Home Address",
  //       description: address,
  //     },
  //     {
  //       topic: "Payment Date",
  //       description: moment(dueData.createdDateAndTime).format(
  //         "MM/DD/YYYY hh:mm:ss a"
  //       ),
  //     },
  //   ],
  // };
  const data = [
    {
      groupName: 'Home Owner Payment Details',
      items: [
        { label: 'Title', data:`${homeOwnerPayment.title?? ""}`, text: 'Title entered while creating Due' },
        { label: 'Description', data:`${homeOwnerPayment.description?? ""}`, text: 'Description regards while creating Due' },
        { label: 'Due Date', 
          data:
          // `${moment(homeOwnerPayment.dueDate).format(
          // "MM/DD/YYYY hh:mm:ss A")}`
          homeOwnerPayment.dueDate ? `${moment(homeOwnerPayment.dueDate).format("MM/DD/YYYY")}` : ''

          , text: 'Date for the Payment' },

        { label: 'Due Type', 
          data:`${homeOwnerPayment.dueType?? ""}`, text: 'Type of the Due '},
        { label: 'Status', data:convertToPascalCase(homeOwnerPayment.status?? ""), text: 'Status Of the Due '},
        {label:'Total Amount',data:`${homeOwnerPayment.dueAmount?? ""}`,text: 'TotalAmount of the Due'}
        
        ],
     },
     {
      groupName: 'Home Owner  Details',
      items: [
        { label: 'First Name', data:`${homeOwnerPayment.homeOwnerDetails?.firstName ?? ""} `, text: 'FirstName of the HomeOwner' },
        { label: 'Last Name', data:`${homeOwnerPayment.homeOwnerDetails?.lastName ?? ""} `, text: 'LastName of the HomeOwner' },
        { label: 'Email', data:`${homeOwnerPayment.homeOwnerDetails?.email ?? ""} `, text: 'Email of the HomeOwner' },
        { label: 'Contact', data:`${homeOwnerPayment.homeOwnerDetails?.contact ?? ""} `, text: 'Contact of the HomeOwner' },
        { label: 'Address', data:`${homeOwnerPayment.homeOwnerDetails?.addressForCommunication ?? ""} `, text: 'Address of the HomeOwner' },

        
        ],
     },
    
    
  ];
  return (
    <div>
       <CustomViewHeading onClick={handleClick}  
       title={"Dues Report"} data={data} description={(homeOwnerPayment.description)}>
      <CustomViewWithTooltip data={data}/>
      </CustomViewHeading>
      {/* <CustomViewPage data={data} exportedBy={true} /> */}
    </div>
  );
}

export default McBmArReportPaidView;
