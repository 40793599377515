import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { request } from "../../services/AxiosConfig";
import { BASE_URL, CONTACT_VERIFY, CONTACT_VERIFY_SEND } from "../../utills/ApplicationRouting";
import CustomToastContainer from "../../components/CustomToastContainer";
import { Card, CardContent, Typography } from "@mui/material";
import CustomButton from "../../components/CustomButton";
import CustomPhoneInput from "../../components/CustomPhoneInput";
import { PHONE_REQUIRED_MESSAGE } from "../../utills/ApplicationConstants";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import { clearAuthToken } from "../../redux/Hybridhoa";
import { isValidPhoneNumber } from "react-phone-number-input";
import 'react-toastify/dist/ReactToastify.css';

function PhoneOtpVerification() {
  const { token, userDetails  } = useSelector((state) => state.hybridhoa);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    console.log(userDetails.contact);
    setValue("phoneNumber",userDetails.contact)
  },[userDetails])
    
    
  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
    setValue,
    clearErrors,
} = useForm();
  
  //don't go to dashbord routing and browser back arrow disable fixed by mohan start
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      window.history.pushState(null, "", window.location.href);
    };

    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);
  //don't go to dashbord routing and browser back arrow disable fixed by mohan end


  

  const handleTimeout = () => {
    localStorage.removeItem("token");
    dispatch(clearAuthToken());
    navigate('/');
  };

  const handleSignIn = (e) => {
      // Prevent the default behavior of the anchor tag
    e.preventDefault();
  
      // Clear token from local storage
    localStorage.removeItem("token");
  
      // Dispatch an action to clear token in Redux state
    dispatch(clearAuthToken());
  
      // Navigate to the login page
    navigate("/");
  };
//phone verification fixed by mohan start

const maskPhoneNumber = (phoneNumber) => {
  if (phoneNumber && phoneNumber.length >= 6) {
    if (phoneNumber.startsWith('+')) {
      const countryCode = phoneNumber.substring(0, 2); // Extract the country code (e.g., "+1")
      const secondDigit = phoneNumber.substring(2, 3); // Get the second digit
      const lastTwo = phoneNumber.substring(phoneNumber.length - 2); // Get the last two digits
      const middleDigits = "X".repeat(phoneNumber.length - 5); // Mask the middle part
      return `${countryCode} ${secondDigit}${middleDigits}${lastTwo}`;
    } else {
      const firstDigit = phoneNumber.substring(0, 1); // Extract the first digit
      const secondDigit = phoneNumber.substring(1, 2); // Get the second digit
      const lastTwo = phoneNumber.substring(phoneNumber.length - 2); // Get the last two digits
      const middleDigits = "X".repeat(phoneNumber.length - 4); // Mask the middle part
      return `${firstDigit}${secondDigit}${middleDigits}${lastTwo}`;
    }
  } else {
    return phoneNumber; // Return the input if invalid or too short
  }
};


  const handleVerificationLink = (data) => {
    // const isValidPhoneNumberForCountry = isValidPhoneNumber(data.phoneNumber);

  //   if (!isValidPhoneNumberForCountry) {
  //   setIsLoading(false);
  //   return;
  // }

  setIsLoading(true);

    const postData = {
        phone: data.phoneNumber,
    };

    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

  request({ url: `${BASE_URL}${CONTACT_VERIFY_SEND}`, method: "post", data: postData, headers: config.headers })
    .then((res) => {
            console.log(res);
      if (res.status === 200) {
                console.log("Success",res.data);
                // toast.success(res.data);
                setTimeout(() => {
                    handleTimeout();
                }, 4000);
            } else {
                console.log("Unexpected status code:", res.status);
      }
    })
    .catch((error) => {
            console.log("Error:", error);
      setIsLoading(false);
            if (error.response) {
                console.log("error.response.status", error.response.status);
            }
    });
};

  return (
    <div>
      <CustomToastContainer />
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-full lg:w-1/2">
        <section className="">
            <div className="flex flex-col items-center justify-center mx-auto md:h-screen lg:py-0">
            <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0 ">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <div
                  className="flex items-center justify-center mb-6 text-2xl font-semibold text-gray-900 "
                >
                    <img className="w-44 h-14" src={logo} alt="logo" />
                  </div>
            <div class="flex flex-col md:flex-row">
               
            
                  <Card>
               
                    <CardContent>
                    <div class="flex items-center justify-center font-bold mb-6">
                        <Typography sx={{ fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`, fontWeight: 'bold' }}>
                          Phone Verification
                        </Typography>
                      </div>
                            
                            {/* <section className="flex justify-center items-center mt-5 p-5">
  <Controller
    name="phoneNumber"
    control={control}
    rules={{ required: PHONE_REQUIRED_MESSAGE,
      validate: {
        isValidPhoneNumber: value => isValidPhoneNumber(value) || "Invalid phone number"
      } }}
    render={({ field, fieldState }) => (
      <>
        <CustomPhoneInput
          fieldControl={control}
          label="Phone Number"
          clearErrors={clearErrors}
          country="US"
          value={field.value} 
          onChange={(value) => field.onChange(value)} 
          error={fieldState.invalid ? fieldState.error : null}
          helperText={fieldState.invalid ? fieldState.error?.message : ''}
        />
      </>
    )}
  />   
</section>

                        


                        <div class="text-center mt-10 ">
                        <CustomButton fieldType="submit" buttonName="Send Verification Link" click={handleSubmit(handleVerificationLink)} /> &nbsp;
                           

                        </div>
                        <br/>
                        <div className="flex items-center justify-center">
  <p className="text-sm mr-2"></p>
  <span>
  <a
            href="/"
            className="text-sm font-medium text-primary-600 hover:underline  text-blue-600"
            onClick={handleSignIn}
          >

      Sign in
    </a>
  </span>
</div>*/}

<form onSubmit={handleSubmit(handleVerificationLink)} className="space-y-4 md:space-y-6 ">
    <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5 text-center">
                          <Typography sx={{ fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'` }}>
            Your Phone Number is not yet verified,Please Verify your Phone Number.
                          </Typography>
                          <Typography sx={{ fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'` }}>
                            {maskPhoneNumber(userDetails.contact)}
                          </Typography>
                        </section>
        
                            
                            {/* <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5 text-center">
                              
                                <Controller
                                    name="phoneNumber"
                                    defaultValue="+917010406926"
                                    control={control}
                                    rules={{ required: CONTACT_REQUIRED_MESSAGE }}
                                    render={({ field, fieldState }) => (
                                        <>
                                            <PhoneInput
                                                error={fieldState.invalid}
                                                country={"us"}
                                                value={field.value}
                                                onInput={(value) => field.onChange(value)}
                                            />
                                            <FormHelperText style={{ color: "#D32F2F" }}>
                                                {fieldState.invalid ? fieldState.error?.message : ""}
                                            </FormHelperText>
                                        </>
                                    )}
                                />
                            </section> */}

                        


                        <div class="text-center ">
                                            <CustomButton fieldType="submit" buttonName="Send Verification Link" /> &nbsp;
                          

                        </div>
                       
                        <div className="flex items-center justify-center">
                                            <p className="text-sm mr-2"></p>
                                            <span>
                          <a
                            href="/"
                                                    className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500 text-blue-600"
                            onClick={handleSignIn}
                          >

                            Sign in
                          </a>
                                            </span>
                        </div>
                 
                      </form>
                    </CardContent>



            
                  </Card>
            {/* phone verification fixed by mohan end */}

            </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
        
  );
}

export default PhoneOtpVerification;
