import React, { useEffect, useMemo, useState } from "react";
import CustomHeading from "../../../components/CustomHeading";
import CustomMaterialTable from "../../../components/CustomMaterialTable";
import { useNavigate } from "react-router-dom";
import {
  CancelOutlined,
  DeleteOutline,
  EditNoteOutlined,
  ListAlt,
} from "@mui/icons-material";
import CustomModel from "../../../components/CustomModel";
import { Controller, useForm } from "react-hook-form";
import CustomButton from "../../../components/CustomButton";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  FormControl,
  TextField,
  Typography,FormControlLabel,Checkbox,
} from "@mui/material";
import {
  CATEGORY_INVALID_MESSAGE,
  CATEGORY_REQUIRED_MESSAGE,
  DESCRIPTION_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_HUNDRED,
  MAX_LENGTH_FIVE_THOUSAND,
  MIN_LENGTH_VALIDATE_MESSAGE,
  PUBLISH_DATE_REQUIRED_MESSAGE,
  TOPIC_REQUIRED_MESSAGE,
} from "../../../utills/ApplicationConstants";
import CustomInputField from "../../../components/CustomInputField";
import {
  LocalizationProvider,
  MobileDateTimePicker,
  StaticDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CustomCheckBox from "../../../components/CustomCheckBox";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  BASE_URL,
  CATEGORY,
  EVENT,
  EVENT_CANCEL,BM_EVENT,
} from "../../../utills/ApplicationRouting";
import { request } from "../../../services/AxiosConfig";
import CustomToastContainer from "../../../components/CustomToastContainer";
import CustomAutoComplete from "../../../components/CustomAutoComplete";
import CustomLoading from "../../../components/CustomLoading";
import CustomAutoCompleteEdit from "../../../components/CustomAutoCompleteEdit";
import moment from "moment";
import CustomPublishDatePicker from "../../../components/CustomPublishDate";
import CustomDatePicker from "../../../components/CustomDatePicker";
import CustomModelView from '../../../components/CustomModelView';


function McBmArcEventList() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const minDate = dayjs().startOf("day");
  const maxDate = dayjs().add(1, "year").endOf("day");
  const currentDateTime = new Date();
  const { token, roles, userLoginDetails } = useSelector(
    (state) => state.hybridhoa
  );
  const [eventDetails, setEventDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRow] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [eventTime, setEventTime] = useState("");
  const [isCommunity, setIsCommunity] = useState(false);
  const handleEventChange = (selectedFromDate) => {
    setEventTime(selectedFromDate);
  };
  const [updateRes, setUpdateRes] = useState([]);
  const selectedRow = updateRes;
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [topicData, setTopicData] = useState("");
  const [descriptionData, setDescriptionData] = useState("");
  const [eventTimeData, setEventTimeData] = useState("");
  const [communityData, setCommunityData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [crId, setCrId] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [publishDate, setPublishDate] = useState("");
  const [publishDateData, setPublishDateData] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [checked, setChecked] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
 const [confirmationOpenEdit, setConfirmationOpenEdit] = useState(false);

 const [reasonForCancel, setReasonForCancel] = useState('');

 const handleReasonForCancelChange = (event) => {
   setReasonForCancel(event.target.value);
 };

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const {
    handleSubmit:handleSubmitCommunityEvent,
    
  } = useForm();

  const handlePublishDateChange = (selectedPublishDate) => {
    setPublishDate(selectedPublishDate);
    console.log("hi");
  };

  const handleEditPublish = (selectedPublishData) => {
    setPublishDateData(selectedPublishData);
  };

  const handleViewClick = (selectedRow) => {
    navigate(`/bmarcevent/${selectedRow.row.original.eventId}`);
  };
  const handleAddClickOpen = () => {
    navigate(`/bmarcmcaddevent`);
  };
  const handleAddClose = (status) => {
    if (status == 200) {
      setOpen(false);
      setChecked(false);
      setIsCommunity(false)
    }
  };
  const handleIconClose = () => {
    setOpen(false);
    setChecked(false);
    setIsCommunity(false)
  };

  const handleEditClose = (status) => {
    if (status == 200) {
      setOpenEdit(false);
    }
  };
  const handleEditIconClose = () => {
    setOpenEdit(false);
  };

  const handleDeleteClickOpen = (selectedRow) => {
    console.log(selectedRow);
    setDeleteOpen(true);
    console.log(selectedRow.eventId);
    setSelectedRow(selectedRow);
  };
  
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  useEffect(()=>{
    console.log("roles",roles);
  },[roles])
 
  const handleEditClickOpen = (selectedRow) => {
    // setOpenEdit(true);
    navigate('/bmarcmceditevent/'+selectedRow.eventId);
    // console.log(selectedRow);
    // console.log("selectedRow.id:", selectedRow.eventId);
    // setSelectedRowId(selectedRow.eventId);
    // console.log(selectedRow.eventId);
    // getEventDetailsById(selectedRow.eventId);
  };

  const handleCancelClickOpen = (selectedRow) => {
    setCancelOpen(true);
    setSelectedRow(selectedRow);
  };
  const handleCancelClose = (status) => {
    if(status==200){
    setCancelOpen(false);
    }
  };
  const handleCancelIconClose = () => {
    setCancelOpen(false);
  };
  const handleCheckBoxChange = (e) => {
    setIsCommunity(e.target.checked);
  };
  const handleEditTopic = (e) => {
    setTopicData(e.target.value);
  };

  const handleEditDescription = (e) => {
    setDescriptionData(e.target.value);
  };
  const handleEditEventDate = (selectedEventTime) => {
    setEventTimeData(selectedEventTime);
  };

  const handleEditCommunity = (e) => {
    setCommunityData(e.target.checked);
  };

  const handleCheckboxChangeForEvent = () => {
    if (!checked) {
      setConfirmationOpen(true);
      setIsModalOpen(true); // Set isModalOpen to true when opening the modal
    } else {
      setChecked(false);
    }
  };
  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
    setIsModalOpen(false); 
    setIsCommunity(false)
  };

  const handleConfirmationConfirm = () => {
    setChecked(true);
    setConfirmationOpen(false);
    setIsModalOpen(false);
    setIsCommunity(true)
  };

  const handleCheckboxChangeForEventEdit = () => {
    if (!checked) {
      setConfirmationOpenEdit(true);
      setIsModalOpen(true); // Set isModalOpen to true when opening the modal
    } else {
      setChecked(false);
    }
  };
  const handleConfirmationCloseEdit = () => {
    setConfirmationOpenEdit(false);
    setIsModalOpen(false); 
    setCommunityData(false)
  };

  const handleConfirmationConfirmEdit = () => {
    setChecked(true);
    setConfirmationOpenEdit(false);
    setIsModalOpen(false);
    setCommunityData(true)
  };

  const eventCardModelAction = (
    <>
      <CustomButton
        buttonName="Cancel"
        click={handleConfirmationClose}

      />
      <CustomButton
        fieldType="submit"
        buttonName="OK"
        click={handleConfirmationConfirm}
      />

    </>
  );

  
  const eventCardModelActionEdit = (
    <>
      <CustomButton
        buttonName="Cancel"
        click={handleConfirmationCloseEdit}

      />
      <CustomButton
        fieldType="submit"
        buttonName="OK"
        click={handleConfirmationConfirmEdit}
      />

    </>
  );
  const EventCardContent = (
    <>
      <form onSubmit={handleSubmitCommunityEvent(handleConfirmationConfirm)}>
        <DialogContent dividers>
          <div className="flex mb-5 mt-5">Are you sure this will be informed to all home owner</div>
        </DialogContent>


        <DialogActions>{eventCardModelAction}</DialogActions>
      </form>
    </>)

const EventCardContentEdit = (
    <>
      <form onSubmit={handleSubmitCommunityEvent(handleConfirmationConfirmEdit)}>
        <DialogContent dividers>
          <div className="flex mb-5 mt-5">Are you sure this will be informed to all home ownerssss</div>
        </DialogContent>


        <DialogActions>{eventCardModelAction}</DialogActions>
      </form>
    </>)


  const getCategory = async () => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      console.log(response.data)

      if (response.status === 200) {
        const allCategories = response.data;

       if (roles=="ROLE_BM"){
        const filteredCategories = allCategories.filter((category) => {
          return (
            (category.type === "EV" && category.isEventForArc === false )
          );
        });
        console.log("filteredCategories",filteredCategories);
        setCategoryDetails(filteredCategories);
      
        
        setIsLoading(false);
        const categoryIds = filteredCategories.map(
          (category) => category.categoryId
        );

        categoryIds.forEach((singleCategoryId) => {
          setCategoryId(singleCategoryId);
        });
        setCategoryData(filteredCategories);
      }else if (roles=="ROLE_ARC"){
        const filteredCategories = allCategories.filter((category) => {
          return (
            (category.type === "EV" && category.isEventForArc === true )
          );
        });
        console.log("filteredCategories",filteredCategories);
        setCategoryDetails(filteredCategories);
      
        
        setIsLoading(false);
        const categoryIds = filteredCategories.map(
          (category) => category.categoryId
        );

        categoryIds.forEach((singleCategoryId) => {
          setCategoryId(singleCategoryId);
        });
        setCategoryData(filteredCategories);
      }
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  // const getCategory = async () => {
  //   try {
  //     const storedToken = token;

  //     const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
  //       headers: {
  //         Authorization: `Bearer ${storedToken}`,
  //       },
  //     });

  //     if (response.status === 200) {
  //       const allCategories = response.data;

  //       // Filter categories based on type (e.g., 'EV')
  //       const filteredCategories = allCategories.filter(category => category.type === 'EV');

  //       setCategoryDetails(filteredCategories); // Set the filtered data to the state
  //       setIsLoading(false);
  //       setCategoryData(filteredCategories);
  //     } else {
  //       throw new Error("Failed to fetch data");
  //     }
  //   } catch (error) {
  //     // Handle error
  //     console.error("Error fetching data:", error);
  //   }
  // };


  // useEffect(() => {
  //   getCategory();
  // }, []);


  // const getCategory = async () => {
  //   try {
  //     const storedToken = token;

  //     const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
  //       headers: {
  //         Authorization: `Bearer ${storedToken}`,
  //       },
  //     });
  //     console.log(response.data);

  //     if (response.status === 200) {
  //       const data = response.data;
  //       setCategoryDetails(data); // Set the fetched data to the state
  //       setIsLoading(false);
  //       setCategoryData(data);
  //     } else {
  //       throw new Error("Failed to fetch data");
  //     }
  //   } catch (error) {
  //     // Handle error
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // useEffect(() => {
  //   getCategory();
  // }, []);

  const handleCategoryChange = (event, newValue) => {
    if (newValue) {
      const selectedCategoryId = newValue.value;
    
      // Set the selected homeId to the state
      setValue("category", selectedCategoryId);
    
      // You can also do other operations if needed
    
      console.log("selectedCategoryId:", selectedCategoryId);
    } else {
      setValue("category", null); // Reset to null if no category is selected
    }
  };

  const handleEditCategoryChange = (event, newValue) => {
    if (newValue) {
      const selectedCategoryId = newValue.value;
    
      // Set the selected homeId to the state
      setValue("editcategory", selectedCategoryId);
    
      // You can also do other operations if needed
    
      console.log("selectedCategoryId:", selectedCategoryId);
    } else {
      setValue("editcategory", null); // Reset to null if no category is selected
    }
  };

  const getEventDetails = async () => {
    try {
      const storedToken = token;

      console.log(storedToken);
      setIsLoading(true);


      const response = await axios.get(`${BASE_URL}${BM_EVENT}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        setEventDetails(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
        

      }
    } catch (error) {
      // Handle error
      setIsLoading(false);

      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getEventDetails();
  }, []);

  const renderedCategory = useMemo(() => {
    console.log("Category Details:", categoryDetails);
    const options = categoryDetails.map((category, index) => ({
      category: category.category,
      value: category.categoryId,
      key: index,
    }));
    console.log("Rendered Category:", options);
    return options;
  }, [categoryDetails]);

  const onSubmitDelete = () => {
    const EventId = selectedRows.eventId;
    console.log(selectedRows.eventId);
    // return;
    request({ url: `${EVENT}/${EventId}`, method: "delete" })
      .then((res) => {
        console.log(res);
        getEventDetails();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = (data) => {

    // if (dayjs(publishDate).isAfter(dayjs(eventTime))) {
    //   // Publish date is after the event date
    //   setIsLoading(false);
    //   setError("publishdate", {
    //     type: "manual",
    //     message: "Publish Date cannot be later than the Date & time of event.",
    //   });
    //   return;
    // }
    
    const postData = {
      topic: data.topic,
      description: data.description,
      category: data.category,
      dateAndTime: dayjs(eventTime).format("YYYY-MM-DD HH:mm:ss"),
      isCommunityEvent: isCommunity,
      role: roles[0],
  //  publishDate: dayjs(publishDate).format("YYYY-MM-DD"),
    };
    setIsLoading(true);


    console.log(data.topic);
    console.log(data.description);
    console.log(categoryId);
    console.log(dayjs(eventTime).format("YYYY-MM-DD HH:mm:ss"));
    console.log(isCommunity);

    // return;
    request({ url: EVENT, method: "post", data: postData })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          console.log("Success");

          setIsLoading(false);
          handleAddClose(res.status);
          getEventDetails();
        reset()
        }else{
        setIsLoading(false);

        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          setIsLoading(false);
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const getEventDetailsById = async (id) => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);
      setIsLoading(true);


      const response = await axios.get(`${BASE_URL}${EVENT}/${id}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        console.log(response.data);
        setUpdateRes(data.eventId);
        setTopicData(data.topic);
        setDescriptionData(data.description);
        setPublishDateData(dayjs(data.publishDate));
        const eventTime = dayjs(data.dateAndTime, {
          format: "YYYY-MM-DD HH:mm:ss",
        });
        setEventTimeData(eventTime);
        console.log(eventTime);
        // setCommunityData(data.isCommunityEvent);
        setCrId(response.data.crId);
        setIsLoading(false);
        if(data.isCommunityEvent===true){
          setChecked(true)
        }else{
          setChecked(false)
        }

        const matchingCategory = categoryDetails.find((category) => category.categoryId === data.category);
        console.log("matchingCategory", matchingCategory);
        console.log("categoryDetails", categoryDetails);
        if(matchingCategory){
          setSelectedCategory(matchingCategory.category)
        console.log('category', matchingCategory.category)
          setValue('editcategory', matchingCategory.categoryId)
        }else{
          setSelectedCategory('')
          setValue('editcategory', '')
        }   

      } else {
        throw new Error("Failed to fetch data");
        setIsLoading(false);

      }
    } catch (error) {
      setIsLoading(false);

      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  

  const onSubmitCancel = (data) => {
    const EventId = selectedRows.eventId;
    setIsLoading(true);
    // console.log(data.reasonforcancel);
    // console.log(FormControl[reasonforcancel].value);/
    const postData={
      reason:data.reasonforcancel
    }
    console.log(postData);
    request({ url: `${EVENT_CANCEL}/${EventId}`, method: "post", data: postData })
      .then((res) => {
        handleCancelClose(res.status)
        console.log(res);
        getEventDetails();
        setIsLoading(false);

      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  const onSubmitUpdate = (data) => {
    const publishDate = dayjs(publishDateData);
    const currentDate = dayjs();

    if (publishDate.isBefore(currentDate, 'day')) {
        setError("publishdate", {
            type: "manual",
            message: "Please select a future date",
        });
        setIsLoading(false);
        return;
    }


    if (!topicData) {
      setError("edittopic", {
        type: "manual",
        message: TOPIC_REQUIRED_MESSAGE,
      });
      return;
    }
    if (!descriptionData) {
      setError("editdescription", {
        type: "manual",
        message: DESCRIPTION_REQUIRED_MESSAGE,
      });
      return;
    }
  
    // if (dayjs(publishDateData).isBefore(dayjs(eventTimeData))) {
    //   // Publish date is earlier than event date
    //   // alert("Publish Date cannot be earlier than Date & time of event.");
    //   setIsLoading(false);
    //   setError("publishdate", {
    //     type: "manual",
    //     message: "Publish Date cannot be earlier than Date & time of event.",
    //   });
    //   return;
    // }
    if (dayjs(publishDateData).isAfter(dayjs(eventTimeData))) {
      // Publish date is after the event date
      setIsLoading(false);
      setError("publishdate", {
        type: "manual",
        message: "Publish Date cannot be later than the Date & time of event.",
      });
      return;
    }

    const putData = {
      topic: topicData,
      description: descriptionData,
      category: crId,
      dateAndTime: dayjs(eventTimeData).format("YYYY-MM-DD HH:mm:ss"),
      isCommunityEvent: checked,
      role: roles[0],
      category:data.editcategory,
      publishDate: dayjs(publishDate).format("YYYY-MM-DD"),
    };

    if (topicData == "" || topicData == null) {
      setError("topic", {
        type: "manual",
        message: TOPIC_REQUIRED_MESSAGE,
      });
      return;
    }

    if (descriptionData == "" || descriptionData == null) {
      setError("description", {
        type: "manual",
        message: DESCRIPTION_REQUIRED_MESSAGE,
      });
      return;
    }

    console.log(data.topic);
    console.log(data.description);
    console.log(selectedCategoryId);
    console.log(dayjs(eventTime).format("YYYY-MM-DD HH:mm:ss"));
    console.log(isCommunity);
    setIsLoading(true);

    request({ url: `${EVENT}/${selectedRow}`, method: "put", data: putData })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          setIsLoading(false);
          handleEditClose(res.status);
          getEventDetails();
        }else{
        setIsLoading(false);

        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
        setIsLoading(false);

          console.log("error.response.status" + error.response.status);
        }
      });
  };



  const eventTable = useMemo((rowData) => [
    {
      accessorKey: "serialNo",
      header: "#",
      size: 20,
      Cell: ({ row }) => row.index + 1,
    },
    {
      accessorKey: "topic",
      header: "Topic Of Event",
      accessorFn: (row) => {
        const topic = row && row.topic;
        if (topic && topic.length > 28) {
          return topic.slice(0, 28) + "...";
        }
        return topic;
      }
    },
    {
      accessorKey: "description",
      header: "Description",
      accessorFn: (row) => {
        const description = row && row.description;
        if (description && description.length > 28) {
          return description.slice(0, 28) + "...";
        }
        return description;
      }
    },
    {
      accessorKey: "categoryDetails.category",
      header: "Category",
      Cell: ({ row }) => {
        if (row.original.categoryDetails === null || row.original.categoryDetails === undefined) {
          return "N/A";
        } else {
          return row.original.categoryDetails.category || "N/A";
        }
      },
    },
    {
      accessorKey: "dateAndTime",
      header: "Event Date&Time",
      accessorFn: (row) => moment(row.dateAndTime).format("MM/DD/YYYY hh:mm:ss a"),
    },
    {
      accessorKey: "eventAddress",
      header: "Event Address",
      accessorFn: (row) => {
        const eventAddress = row && row.eventAddress;
        if (eventAddress && eventAddress.length > 28) {
          return eventAddress.slice(0, 28) + "...";
        }
        return eventAddress;
      }
    },
   
   
    // {
    //   accessorKey: "publishDate",
    //   header: "Publishdate",
    //   accessorFn : (row) => {
    //     const publishDate = moment(row.publishDate);
    //     if (publishDate.isValid()) {
    //       return publishDate.format("MM/DD/YYYY");
    //     } else {
    //       return ""; 
    //     }
    //   }
    // },

    // {
    //   accessorKey: "createdBy",
    //   header: "Created By",
    //   // Cell: ({ row }) => {
    //   //   const firstName = row.original.createdByDetails?.firstName || "";
    //   //   const lastName = row.original.createdByDetails?.lastName || "";
    //   //   return `${firstName} ${lastName}`;
    //   // },
    //   accessorFn: (row) => `${row.createdByDetails? row.createdByDetails.firstName : ""} ${row.createdByDetails? row.createdByDetails.lastName : ""} `,

    // }
  ]);

  const rowActions = (row) => {
    const actions = [
      // {
      //   label: "View",
      //   icon: <ListAlt color="primary" />,
      //   click: () => handleViewClick(row),
      // },
    ];
    const eventdateandtime = dayjs(row.original.dateAndTime);
     const now = dayjs();
    if (row.original.auditBy == userLoginDetails.homeOwnerLoginId && row.original.status !=="CANCELED" && row.original.role === "ROLE_BM" && !eventdateandtime.isBefore(now)) {
      actions.push({
        label: "Edit",
        icon: <EditNoteOutlined color="primary" />,

        click: ()=>handleEditClickOpen(row.original),

      });
    }
    
    // if (row.original.createdBy == userLoginDetails.homeOwnerLoginId && row.original.role === roles.join(', ')) {
    //   actions.push({
    //     label: "Delete",
    //     icon: <DeleteOutline color="error" />,
    //     click: () => handleDeleteClickOpen(row.original), 
    //   });
    // }

    if (row.original.auditBy == userLoginDetails.homeOwnerLoginId && row.original.status !=="CANCELED" && row.original.role === "ROLE_BM") {
      actions.push({
        label: "Cancel",
        icon: <CancelOutlined color="error" />,
        click: () => handleCancelClickOpen(row.original),
      });
    }
    return actions;
  };

  const tableActionButtons = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Add"
        click={handleAddClickOpen}
      />
    </>
  );

  const CancelButtonActions = (
    <>
     {/* <CustomButton
        fieldType="button"
        buttonName="No"
        click={handleCancelClose}
      /> */}
      <CustomButton
        fieldType="submit"
        buttonName="Submit"
        click={handleCancelClose}
      />
    </>
  );

  const cancelModel = (
    <form
      onSubmit={handleSubmit(onSubmitCancel)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
      <Typography gutterBottom>
            <CustomInputField
              fieldName="reasonforcancel"
              requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
              fieldId="reasonforcancel"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={5000}
              maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
              fieldLabel="Reason for cancel"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
              onChange={handleReasonForCancelChange}
            />
          </Typography>
        {/* <Typography> Are you sure you want to cancel this event ?</Typography> */}
      </DialogContent>
      <DialogActions>{CancelButtonActions}</DialogActions>
    </form>
  );

  // const cancelModel = (
  //   <form
  //     onSubmit={handleSubmit(onSubmitCancel)}
  //     className="space-y-4 md:space-y-6 "
  //   >
  //     <DialogContent dividers>
  //       <Typography> Are you sure you want to cancel this event ?</Typography>
  //     </DialogContent>
  //     <DialogActions>{CancelButtonActions}</DialogActions>
  //   </form>
  // );

  const okCancelButtonActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="No"
        click={handleDeleteClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Yes"
        click={handleDeleteClose}
      />
    
    </>
  );

  const deleteModel = (
    <form
      onSubmit={handleSubmit(onSubmitDelete)}
      className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography> Are you sure you want to delete this event ?</Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );

  const addActionButton = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Add"
        click={handleAddClose}
      ></CustomButton>
      {isLoading && <CustomLoading />}
    </>
  );
  const addModel = (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography gutterBottom>
            <CustomInputField
              fieldName="topic"
              requiredErrorMessage={TOPIC_REQUIRED_MESSAGE}
              fieldId="topic"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={500}
              maxLengthErrorMessage={MAX_LENGTH_FIVE_HUNDRED}
              fieldLabel="Topic of Event"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              
            />

            <CustomInputField
              fieldName="description"
              requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
              fieldId="description"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={5000}
              maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
              fieldLabel="Description"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
            />

            <br />
            <br />


            <CustomAutoComplete
              fieldName="category"
              requiredErrorMessage = {CATEGORY_REQUIRED_MESSAGE}
              id="selectId"
              options={renderedCategory}
              fieldLabel="Select Category/Subject"
              fieldControl={control}
              error={errors}
              setValue={setValue}
              fieldRequiredIcon="*"
                            onChange={handleCategoryChange}
                            invalidOption={CATEGORY_INVALID_MESSAGE}
            />

            <br />

      
            <Controller
  control={control}
  name="selectedDate"
  render={({ field }) => (
    <FormControl fullWidth>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDateTimePicker
          {...field}
          minDate={minDate}
          maxDate={maxDate}
          disablePast={true}
          // ampm={false}
          onChange={(date) => {
            handleEventChange(date);
            field.onChange(date); // Manually trigger onChange for React Hook Form
          }}
          slotProps={{
            textField: {
              variant: "standard",
              label: (
                <span>
                  Date & time of event
                  <span style={{ color: "red" }}> *</span>
                </span>
              ),
              error: !!errors.selectedDate,
              helperText: errors.selectedDate ? errors.selectedDate.message : "",
            },
          }}
        />
      </LocalizationProvider>
    </FormControl>
  )}
  rules={{ required: 'Date & time of event is required' }} // Add validation rule
/>
{/* <br />
<br />
<section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
            <CustomDatePicker
              fieldName="publishdate"
              label="Publish Date"
              control={control}
              requiredErrorMessage={PUBLISH_DATE_REQUIRED_MESSAGE}
              minDate={minDate}
              maxDate={maxDate}
              error={errors}
              onChange={handlePublishDateChange}
              fieldRequiredIcon="*"
            />
</section> */}

            {/* <CustomCheckBox
              fieldName="communityEvent"
              control={control}
              onChange={handleCheckBoxChange}
              // defaultValue={false}
              labelName="Community Event"
            /> */}
            
<div onClick={(e) => e.stopPropagation()}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleCheckboxChangeForEvent}
                        color="primary"
                        size="small"
                        title="Check to share event with everyone. Boost community spirit by making it visible to all"
                      />
                    }
                    label={<span style={{ fontSize: '14px' }}>Community Event </span>}
                  />
                </div>
          </Typography>
        </DialogContent>
        <DialogActions>{addActionButton}</DialogActions>
      </form>
    </>
  );

  const UpdateActionButton = (
    <>
    <CustomButton
      fieldType="submit"
      buttonName="Update"
      click={handleEditClose}
    ></CustomButton>
    {isLoading && <CustomLoading />}
    </>
  );
  const updateModel = (
    <>
      <form
        onSubmit={handleSubmit(onSubmitUpdate)}
        className="space-y-4 md:space-y-6 "
      >
        <DialogContent dividers>
          <Typography gutterBottom>
            <CustomInputField
              fieldName="edittopic"
              fieldId="edittopic"
              // requiredErrorMessage={TOPIC_REQUIRED_MESSAGE}
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={500}
              maxLengthErrorMessage={MAX_LENGTH_FIVE_HUNDRED}
              fieldLabel="Topic of Event"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              value={topicData}
              onInput={handleEditTopic}
            />

            <CustomInputField
              fieldName="editdescription"
              // requiredErrorMessage={DESCRIPTION_REQUIRED_MESSAGE}
              fieldId="editdescription"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={5000}
              maxLengthErrorMessage={MAX_LENGTH_FIVE_THOUSAND}
              fieldLabel="Description"
              fieldControl={control}
        fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
              value={descriptionData}
              onInput={handleEditDescription}
            />


            <br />
            <br />
            
            <CustomAutoCompleteEdit
              fieldName="editcategory"
              requiredErrorMessage={CATEGORY_REQUIRED_MESSAGE}
              id="selectId"
              options={renderedCategory}
              fieldLabel="Select Category"
              fieldControl={control}
              error={errors}
              setValue={setValue}
              fieldRequiredIcon="*"
              value={selectedCategory}
              onChange={handleEditCategoryChange}
              invalidOption={CATEGORY_INVALID_MESSAGE}          
            />


            <br />
            <br />

            <Controller
              control={control}
              name="editselectedDate"
              // rules={{ required: "Date is required" }}
              render={({ field, fieldState }) => (
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDateTimePicker
                      {...field}
                      value={eventTimeData}
                      minDate={minDate}
                      maxDate={maxDate}
                      disablePast={true}
                      // ampm={false}
                      onChange={handleEditEventDate}
                      slotProps={{
                        textField: {
                          variant: "standard",
                          label: (
                            <span>
                              Date & time of event 
                              <span style={{ color: "red" }}> *</span>
                            </span>
                          ),
                          error: fieldState.invalid,
                          helperText: fieldState.invalid
                            ? fieldState.error?.message
                            : "",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              )}
            />
 <br />
            <br />
<section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">

<CustomDatePicker
    fieldName="publishdate"
    label="Publish Date"
    control={control}
    minDate={minDate}
    maxDate={maxDate}
    error={errors}
    value={publishDateData}
    onChange={handleEditPublish}
    fieldRequiredIcon="*"
    validate={publishDateData} 
    // pastDateErrorMessage="Please select a date in the future"
/>
</section>

            {/* <CustomCheckBox
              fieldName="editcommunityEvent"
              control={control}
              checked={communityData}
              onChange={handleEditCommunity}
              labelName="Community Event"
            /> */}
            <div onClick={(e) => e.stopPropagation()}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleCheckboxChangeForEventEdit}
                        color="primary"
                        size="small"
                        title="Check to share event with everyone. Boost community spirit by making it visible to all"
                      />
                    }
                    label={<span style={{ fontSize: '14px' }}>Community Event </span>}
                  />
                </div>
          </Typography>
        </DialogContent>
        <DialogActions>{UpdateActionButton}</DialogActions>
      </form>
    </>
  );

  return (
    <div>
      <CustomHeading title="Events" />
      {isLoading && <CustomLoading />}

      <CustomToastContainer />

      <CustomMaterialTable
        columns={eventTable}
        data={eventDetails}
        rowActions={rowActions}
        actionButtons={tableActionButtons}
        // isLoading={isLoading}
        onRowClick = {handleViewClick}
      />

      <CustomModel
        title="Add Event"
        submit={onSubmit}
        content={addModel}
        action={addActionButton}
        openStatus={open}
        closeStatus={handleAddClose}
        iconCloseStatus={handleIconClose}
        reset={reset}
      />

      <CustomModel
        title="Update Event"
        submit={onSubmitUpdate}
        content={updateModel}
        action={UpdateActionButton}
        openStatus={openEdit}
        closeStatus={handleEditClose}
        iconCloseStatus={handleEditIconClose}
        reset={reset}
      />
      <CustomModel
        title=" Delete Event"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      />

      <CustomModel
        title="Cancel Event"
        submit={onSubmitCancel}
        content={cancelModel}
        action={CancelButtonActions}
        openStatus={cancelOpen}
        closeStatus={handleCancelClose}
        iconCloseStatus={handleCancelIconClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      />
       <CustomModelView
        title="Community Event"
        content={EventCardContent}
        action={eventCardModelAction}
        openStatus={confirmationOpen}
        closeStatus={handleConfirmationClose}
        iconCloseStatus={handleConfirmationClose}
      />
      <CustomModelView
        title="Community Event"
        content={EventCardContentEdit}
        action={eventCardModelActionEdit}
        openStatus={confirmationOpenEdit}
        closeStatus={handleConfirmationCloseEdit}
        iconCloseStatus={handleConfirmationCloseEdit}
      />
    </div>
  );
}
export default McBmArcEventList;
