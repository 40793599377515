import React, { useEffect, useMemo,useRef, useState } from "react";
import CustomHeading from "../../../components/CustomHeading";
import CustomMaterialTable from "../../../components/CustomMaterialTable";
import { useNavigate,useParams } from "react-router-dom";
import {
    DeleteOutline,
    DescriptionSharp,
    EditNoteOutlined,
    InfoOutlined,
    ListAlt,
    VisibilityOffOutlined,
    VisibilityOutlined,
} from "@mui/icons-material";
import CustomModel from "../../../components/CustomModel";
import { Controller, useForm } from "react-hook-form";
import {
    DESCRIPTION_MAX_LENGTH_VALIDATE_MESSAGE,
    DESCRIPTION_MIN_LENGTH_VALIDATE_MESSAGE,
    DESCRIPTION_REQUIRED_MESSAGE,
    MAX_LENGTH_FIVE_THOUSAND,
    MAX_LENGTH_THOUSAND,
    MAX_LENGTH_TWO_FIFTY,
    MIN_EIGHT_LENGTH_VALIDATE_MESSAGE,
    MIN_LENGTH_VALIDATE_MESSAGE,

    NOTIFICATION_REQUIRED_MESSAGE,
    NOTIFICATION_TYPE_MESSAGE,
    PUBLISH_DATE_REQUIRED_MESSAGE,
    REQUEST_TYPE_REQUIRED_MESSAGE,

    SUBJECT_MAX_LENGTH_VALIDATE_MESSAGE,
    SUBJECT_MIN_LENGTH_VALIDATE_MESSAGE,
    SUBJECT_REQUIRED_MESSAGE,
} from "../../../utills/ApplicationConstants";
import JoditEditor from "jodit-react";

import CustomInputField from "../../../components/CustomInputField";
import CustomButton from "../../../components/CustomButton";
import CustomRadioButton from "../../../components/CustomRadioButton";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useSelector } from "react-redux";
import axios from "axios";
import {
    BASE_URL,
    BASE_URLS,
    GET_ALL_ARC_NOTIFICATION,
    GET_ALL_BM_NOTIFICATION,
    GET_ALL_MC_NOTIFICATION,
    GROUP_CONFIG,
    NOTIFICATION,
} from "../../../utills/ApplicationRouting";
import moment from "moment";
import { request } from "../../../services/AxiosConfig";
import CustomToastContainer from "../../../components/CustomToastContainer";
import CustomLoading from "../../../components/CustomLoading";
import CustomNotificationRadioButton from "../../../components/CustomNotificationRadioButton";
import CustomPublishDatePicker from "../../../components/CustomPublishDate";
import dayjs from "dayjs";
import CustomDatePicker from "../../../components/CustomDatePicker";
import CustomTooltipField from "../../../components/CustomTooltipField";
import {
    DialogActions,
    DialogContent,
    FormControlLabel,
    InputLabel,
    Typography,Card,CardContent,
    Tooltip,
    IconButton,
} from "@mui/material";
function McBmArcSendNotificationt() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openSendDoc, setOpenSendDoc] = useState(false);
    const [hideOpen, setHideOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [userRoles, setUserRoles] = useState("");
    const { token, userLoginDetails, roles } = useSelector(
        (state) => state.hybridhoa
    );
    const [notificationDetails, setNotificationDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [selectedRows, setSelectedRow] = useState(null);
    const [generalNotify, setGeneralNotify] = useState("");
    const [updateRes, setUpdateRes] = useState([]);
    const selectedRow = updateRes;
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [subjectData, setSubjectData] = useState("");
    const [radioData, setRadioData] = useState(false);
    const [descriptionData, setDescriptionData] = useState("");
    const [hideSelectedRow, setHideSelectedRow] = useState("");
    const [notificataionGroup, setNotificationGroup] = useState("");
    const [groupConfigurationDetails, setGroupConfigurationDetails] = useState(
        []
    );
    const [publishContent, setPublishContent] = useState("No");
    const [publishContentHo, setPublishContentHo] = useState("No");
    const [select, setSelect] = useState(false);
    const [selectHo, setSelectHo] = useState(false);
    const [notificationType, setNotiFicationType] = useState(false);
    const [publishDate, setPublishDate] = useState("");
    const minDate = dayjs().startOf("day");
    const maxDate = dayjs().add(1, "year").endOf("day");
    const [publishDateData, setPublishDateData] = useState("");
    const [boardMember,setBoardMember]=useState(false)
    const [hoMember,setHoMember]=useState(false)
    const {notificationId} = useParams();

    const [content, setContent] = useState(""); // Initialize content state with an empty string
    const editorRef = useRef(null);

    
    const handleClick = () => {
        navigate(-1);
      };

    const getGroupConfigurationDetails = async () => {
        try {
            setIsLoading(true);
            const storedToken = token;

            console.log(storedToken);

            const response = await axios.get(`${BASE_URL}${GROUP_CONFIG}`, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            console.log(response);

            if (response.status === 200) {
                const data = response.data;
                setGroupConfigurationDetails(data);

                setIsLoading(false);
            } else {
              setIsLoading(false);
                throw new Error("Failed to fetch data");
            }
        } catch (error) {
          setIsLoading(false);
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        // Assuming data is your array of objects
        const item = groupConfigurationDetails.find(
            (item) => item.topic === "Publish notification to Home Owners with 51% approval"
        );
        const itemHo = groupConfigurationDetails.find(
            (item) =>
                item.topic ===
                "Allow Board member/ARC/MC users to publish general notification to homeowners"
        );
        if (item) {
            setPublishContent(item.content);
            console.log("TestCase", item.content);
        }
        if (itemHo) {
            setPublishContentHo(itemHo.content);
            console.log("TestCase", itemHo.content);
        }
    }, [groupConfigurationDetails, publishContent, publishContentHo]);
    useEffect(() => {
        getGroupConfigurationDetails();
    }, []);
 
 

    const handleEditClose = (status) => {
        if (status == 200) {
            setOpenEdit(false);
        }
    };
    const handleEditPublish = (selectedPublishData) => {
        setPublishDateData(selectedPublishData);
    };

    const handleEditSubject = (e) => {
        setSubjectData(e.target.value);
    };

    const handleEditRadio = (newValue) => {
        setRadioData(newValue);
    };

 

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        setError,
        data,
        formState: { errors },
    } = useForm();

    const handleContentChange = (newContent) => {
      setContent(newContent);
    };
  
    const config = useMemo(
      () => ({
        readonly: false,
        showCharsCounter: false,
        showWordsCounter: false,
        showXPathInStatusbar: false,
        removeButtons: ['file', 'image', 'video', 'spellcheck', 'speechRecognize'],
        showPlaceholder: false,
      }),
      []
    );
  
    const isEmptyContent = (content) => {
      if (!content) return true; // Check for undefined or null content
      // Strip HTML tags and check if content is empty or just spaces
      console.log(content);
  
      const strippedContent = content.replace(/<\/?[^>]+(>|$)/g, "").trim();
      const finalData = strippedContent.replace(/&nbsp;/g, "").trim(); 
      console.log(finalData.length);
      return finalData.length === 0;
    };
  


   

   
    const getNotificationDetailsById = async (id) => {
        try {
            setIsLoading(true);
            const storedToken = token;

            console.log(storedToken);

            const response = await axios.get(`${BASE_URL}${NOTIFICATION}/${notificationId}`, {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            console.log(response.data);

            if (response.status === 200) {
                const data = response.data;
                console.log(response.data);
                setUpdateRes(data.notificationId);
                setSubjectData(data.subject);
                setRadioData(data.isGeneral);
                setPublishDateData(dayjs(data.publishDate));
                setUserRoles(data.role);
                console.log(data.isGeneral);
                setBoardMember(data.publishToBoard)
                console.log("teste radio",data.publishToBoard);
                setHoMember(data.publishToHo)
                setDescriptionData(data.description);
                console.log(data.description);
                setIsLoading(false);
          setContent(data.content || ""); // Ensure content is set to "" if response.data.content is undefined

            } else {
              setIsLoading(false);
                throw new Error("Failed to fetch data");
            }
        } catch (error) {
            // Handle error
            setIsLoading(false);
            console.error("Error fetching data:", error);
        }
    };

    useEffect(()=>{
        getNotificationDetailsById();
      },[notificationId])

    
        
      const onSubmitSendDoc = (data) => {
        // const unHideData = {
        //     subject: subjectData,
        //     description: descriptionData,
        //     role: roles[0],
        //     isGeneral: radioData,
        //     isHidden: false,
        //     publishDate: dayjs(publishDateData).format("YYYY-MM-DD"),
        // };
        // if (subjectData == "" || subjectData == null) {
        //     setError("editSubject", {
        //         type: "manual",
        //         message: SUBJECT_REQUIRED_MESSAGE,
        //     });
        const trimmedSubjectData = subjectData.trim();

       

        if (trimmedSubjectData === "") {
            setError("editSubject", {
                type: "manual",
                message: SUBJECT_REQUIRED_MESSAGE,
            });
    
            return;
        }
        const descriptions = data.editorContent; // Check if data and data.description are defined
          console.log("descriptions", descriptions);
          
          if (!descriptions) {
            setError("editorContent", {
              type: "manual",
              message: DESCRIPTION_REQUIRED_MESSAGE,
            });
            return;
          }
          
          const cleanedData = descriptions.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
          const finalData = cleanedData.replace(/&nbsp;/g, " ");
          const trimData=finalData.trim()
          console.log(finalData);
          
          if (trimData.length < 2) {
            setError("editorContent", {
              type: "manual",
              message: DESCRIPTION_MIN_LENGTH_VALIDATE_MESSAGE,
            });
            return;
          }
          
          if (trimData.length > 1000) {
            setError("editorContent", {
              type: "manual",
              message: DESCRIPTION_MAX_LENGTH_VALIDATE_MESSAGE,
            });
            return;
          }
          setIsLoading(true)
          const putData = {
            subject: trimmedSubjectData,
            description: trimData,
            role: roles[0],
            isGeneral: radioData,
            isHidden: false,
            publishDate: dayjs(publishDateData).format("YYYY-MM-DD"),
        };
        request({
            url: `${NOTIFICATION}/${selectedRow}`,
            method: "put",
            data: putData,
        })
        .then((res) => {
            console.log(res);
            if (res.status == 200) {
                setIsLoading(false);
                setTimeout(() => {
                    navigate(-1);
                  }, 4000);
              
            }
        })
        .catch((error) => {
            console.log(error.response);
            if (error.response) {
                console.log("error.response.status" + error.response.status);
            }
        });
      };
    const handleNotificationTypeClose = () => {
        setNotiFicationType(false); // Close the modal
      };
      const renderTooltip = (message) => (
        <Tooltip title={<Typography sx={{ fontSize: "14px" }}>{message}</Typography>} placement="right">
          <IconButton>
            <InfoOutlined />
          </IconButton>
        </Tooltip>
      );
   
    const notificationTypeActions = (
        <>
          <CustomButton
            fieldType="button"
            buttonName="Ok"
            click={handleNotificationTypeClose}
          />
        </>
      );

    // const notificationTypeModel = (
    //     <form onSubmit={handleSubmit(onSubmitEdit)} className="space-y-4 md:space-y-6">
    //       <DialogContent dividers>
    //         <Typography>
    //           Notification Type is Not Configured. Please Contact Group Admin.
    //         </Typography>
    //       </DialogContent>
    //       <DialogActions>{notificationTypeActions}</DialogActions>
    //     </form>
    //   );

    return (
        <div>

{/* <CustomModel
        title="Notification Type Not Configured"
        // submit={onSubmitNotificationType}
        content={notificationTypeModel}
        action={notificationTypeActions}
        openStatus={notificationType}
        closeStatus={handleNotificationTypeClose}
        iconCloseStatus={handleNotificationTypeClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      /> */}

        <div class="flex flex-col md:flex-row">
      <CustomHeading title="Send Notification " />

      <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
        <CustomButton
          fieldType="button"
          buttonName="Back"
          click={handleClick}
        />
      </div>
    </div>
    <Card>
    <form
                onSubmit={handleSubmit(onSubmitSendDoc)}
                className="space-y-4 md:space-y-6 "
            ><CardContent>
                
            
                        <CustomTooltipField
                            fieldName="editSubject"
                            fieldId="editSubject"
                            minLength={8}
                            minLengthErrorMessage={SUBJECT_MIN_LENGTH_VALIDATE_MESSAGE}
                            maxLength={250}
                            maxLengthErrorMessage={SUBJECT_MAX_LENGTH_VALIDATE_MESSAGE}
                            fieldLabel="Subject"
                            fieldControl={control}
                            fieldError={errors}
                            fieldRequiredIcon="*"
                            multiline={true}
                            numberOfRows={2}
                            value={subjectData}
                            onInput={handleEditSubject}
                            tooltipMessage={"Enter the subject of the notification "}
                        />
                        <br />
                        <br />
                        <div className="flex items-center md:w-1/2 ">
<div className="w-full ">

                        <Controller
                            name="editorContent"
                            control={control}
                            defaultValue=""
                            rules={{
                                // required: DESCRIPTION_REQUIRED_MESSAGE,
                                // validate: value => {
                                //   const strippedValue = value.replace(/(<([^>]+)>)/gi, '');
                                //   console.log("value",value);
                                //   if (strippedValue.length < 2 || strippedValue.length > 1000) {
                                //     return 'The Description field length should be a minimum of 2 and a maximum of 1000 characters';
                                //   }
                                //   return true;
                                // }
                            }}
                            render={({ field }) => (
                                <>
                                    <InputLabel htmlFor="editorContent">
                                        Description <span style={{ color: "red" }}>*</span>
                                    </InputLabel>
                                    {/* <CKEditor
                                        editor={ClassicEditor}
                                        data={descriptionData}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            const cleanedData = data.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
                                            const finalData = cleanedData.replace(/&nbsp;/g, " "); // Replace &nbsp; with space
                                            console.log("data", finalData);
                                            field.onChange(finalData);
                                            console.log(finalData)
                                            setDescriptionData(data)
                                        }}
                                    /> */}
                                      <JoditEditor
  ref={editorRef}
  value={descriptionData} // Ensure descriptionData is passed to value prop
  config={config} // Add your custom configuration for Jodit if needed
  onBlur={field.onBlur} // Handle blur event if necessary
  onChange={(newContent) => {
    const cleanedContent = newContent.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
    const finalContent = cleanedContent.replace(/&nbsp;/g, " "); // Replace &nbsp; with space
    console.log("Cleaned content:", finalContent);
    
    field.onChange(finalContent); // Pass cleaned content to the form field
    setDescriptionData(newContent); // Update descriptionData state with the raw editor content
  }}
/>
                                    {errors.editorContent && (
                                        <span className="text-red-500">
                                            {errors.editorContent.message}
                                        </span>
                                    )}
                                </>
                            )}
                        />
                        <br />

                        </div>
  <div>
    {renderTooltip("Enter the detailed description of the notification")}
  </div>
</div>

                        {/* <CustomRadioButton
              fieldName="editNotificationType"
              fieldControl={control}
              fieldError={errors}
              labelName="Notification Type"
              valueOne={true}
              labelOne="General Document - This will be Publish General Document to Homeowners"
              valueTwo={false}
              labelTwo="Need 51% Approval from Board Member"
              value={radioData}
              onChange={handleEditRadio}
            /> */}    <CustomRadioButton
      fieldName="notificationType"
      fieldControl={control}
     
      labelName="Notification Type"
      valueOne={false}
      labelOne={
        boardMember ? (
          
           " Need 51% Approval from Board Member"
            
        ) : ''
      }
      valueTwo={true}
      labelTwo={
        hoMember ? (
          
          "  General Notification - This will be Publish General Notification to Homeowners"
            
        ) : ''
      }
      value={radioData}
      onChange={handleEditRadio}
      tooltipMessage={"Select the notification type"}
    />
                
                    <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">


                        <CustomDatePicker
                            fieldName="publishdate"
                            label="Publish Date"
                            control={control}
                            minDate={minDate}
                            maxDate={maxDate}
                            error={errors}
                            value={publishDateData}
                            onChange={handleEditPublish}
                            fieldRequiredIcon="*"
                            validate={publishDateData}
                            pastDateErrorMessage="Please select a date in the future"
                            tooltipMessage={"Select the date when the notification should be published"}
                        />
                    </section>
                    <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Resend" />
              {isLoading && <CustomLoading />}
            </div>
                </CardContent>
            </form>
            
    </Card>
    <CustomToastContainer />
   
  </div>
       

    );
}

export default McBmArcSendNotificationt;