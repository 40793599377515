import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react'
import CustomButton from './CustomButton';
import { CARD_URL } from '../utills/ApplicationRouting';
import CustomLoading from './CustomLoading';

function CustomCardElement() {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading,setIsLoading]=useState(false)
  
    const handleSubmit = async (event) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();
      console.log("entered payment card");
  
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
  
      const result = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: CARD_URL,
        },
      });
  
  
      if (result.error) {
        setIsLoading(true)
        setTimeout(() => {
          setIsLoading(false)
        }, 2000);
        // Show error to your customer (for example, payment details incomplete)
        console.log(result.error.message);
      } else {
        setIsLoading(true)
        setTimeout(() => {
          setIsLoading(false)
        }, 2000);
     
      }
    };
  
      return (
  
        <form onSubmit={handleSubmit}>
          {isLoading && <CustomLoading />}
  
            <PaymentElement />
     
            <span style={{float:'right'}} >
           <CustomButton
           fieldType="submit"
           buttonName="Add"
          />
           </span>
  
         
          
          </form>
        );
  }

export default CustomCardElement