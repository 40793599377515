import React, { useState } from 'react'
import CustomHeading from '../../components/CustomHeading'
import CustomToastContainer from '../../components/CustomToastContainer'
import CustomButton from '../../components/CustomButton'
import { Card, CardContent, DialogContent, Typography } from '@mui/material'
import CustomTooltipField from '../../components/CustomTooltipField'
import CustomLoading from '../../components/CustomLoading'
import { TEMPLATE } from '../../utills/ApplicationRouting'
import { request } from '../../services/AxiosConfig'
import { useForm } from 'react-hook-form'
import { FOOTER_REQUIRED_MESSAGE, HEADER_REQUIRED_MESSAGE, MAX_LENGTH_THOUSAND, MIN_LENGTH_VALIDATE_MESSAGE } from '../../utills/ApplicationConstants'
import { useNavigate } from 'react-router-dom'

function TemplateAdd() {
    const {
        handleSubmit,
        control,
        reset,
        setError,
        formState: { errors },
      } = useForm({
        shouldUnregister: true, // Add this option
      });
      const navigate = useNavigate();
    const [isLoading,setIsLoading]= useState(false)
    const [active, setIsActive] = useState(false);
    const handleClick = () => {
        navigate(-1);
      };
    const onSubmit = (data) => {
        setIsLoading(true);
        const postData = {
          header: data.header,
          footer: data.footer,
          isActive: active,
        };
        request({ url: TEMPLATE, method: "post", data: postData })
          .then((res) => {
            console.log(res);
            if (res.status == 200) {
              console.log("Success");
            //   getTemplateDetails();
            //   getRenterDetailsById();
              setIsLoading(false);
              setTimeout(()=>{
                navigate(-1);
            },4000)
            //   handleAddClose(res.status);
            }
          })
          .catch((error) => {
            console.log(error.response);
            if (error.response) {
              setIsLoading(false);
              console.log("error.response.status" + error.response.status);
            }
          });
      };
  return (
    
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomHeading title="Add Template" />
        <CustomToastContainer />
        <div class="md:w-1/2 md:pl-4 sm:text-left md:text-right sm:w-full  mb-2 space-x-2 ">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleClick}
          />
        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <CardContent>
            <section class="grid md:grid-cols-1 xl:grid-cols-1 gap-5">
            
            <CustomTooltipField
              fieldName="header"
              requiredErrorMessage={HEADER_REQUIRED_MESSAGE}
              fieldId="headr"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldLabel="Header"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
              tooltipMessage={"text"}

            />

          <CustomTooltipField

              fieldName="footer"
              requiredErrorMessage={FOOTER_REQUIRED_MESSAGE}
              fieldId="footer"
              minLength={2}
              minLengthErrorMessage={MIN_LENGTH_VALIDATE_MESSAGE}
              maxLength={1000}
              maxLengthErrorMessage={MAX_LENGTH_THOUSAND}
              fieldLabel="Footer"
              fieldControl={control}
              fieldError={errors}
              fieldRequiredIcon="*"
              multiline={true}
              numberOfRows={2}
              tooltipMessage={"text"}

            />
            </section>
            <div class="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Create" />
              {isLoading && <CustomLoading />}

            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  )
}

export default TemplateAdd

