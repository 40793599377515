import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo.png";
import "../../App.css";
import CustomButton from "../CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { TextField, Card, CardContent, InputAdornment, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from 'axios';
import { FORGOT_CHANGE, BASE_URL, FORGOT_PASSWORD, FORGOT_PASSWORD_EMAIL } from "../../utills/ApplicationRouting";
import { NEW_PASSWORD_REQUIRED_MESSAGE, CONFIRM_PASSWORD_REQUIRED_MESSAGE } from "../../utills/ApplicationConstants";
import CustomLoading from "../CustomLoading";
import { useSelector } from 'react-redux';
import { PASSWORD_REGEX } from "../../utills/ApplicationRegex";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomToastContainer from "../CustomToastContainer";


const ForgetChangePassword = () => {
  const navigate = useNavigate();
  const { tokenId } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(false);



  const handleClick = () => {
    navigate(-1);
  };

  const { handleSubmit, control, getValues } = useForm();

  const passwordsMatchValidation = (value) => {
    const { newPassword } = getValues(); // Get the value of newPassword from the form
    return newPassword === value || "Passwords do not match"; // Return error message if passwords don't match
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const emails= localStorage.getItem("email");
    try {
      const postData = {
        newPassword: data.newPassword,
        confirmPassword: data.confirmPassword,
        email: email,
        token:tokenId,

      };
      const response = await axios.post(`${BASE_URL}${FORGOT_CHANGE}/${tokenId}`, postData);
      if (response.status === 200){
      console.log(response)
      const message=response.data

      toast.success(message, { position: "top-center" }); // Display success message

      setTimeout(() => {
        navigate("/")
        // setIsLoading(false)
      }, 2000);
      }
    } catch (error) {
      console.error('Error occurred:', error);
      console.error('Error occurred:', error.response.data.message);
      const messages = error.response.data.message;
      
      toast.error(error.response.data.message, { position: "top-center" });

     
    } finally {
      setIsLoading(false);
    }
  };
  
  const getEmail = async () => {
    try {
  

      // const emails= localStorage.getItem("email");

      const response = await axios.get(`${BASE_URL}${FORGOT_PASSWORD_EMAIL}${tokenId}`, {
        
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        console.log("data",data);

        setEmail(data)
//
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
    
  };

  useEffect(()=>{
    getEmail()
  })

  return (
    <div className="w-full lg:w-full">
      <section className="dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center mx-auto md:h-screen lg:py-0">
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <div className="flex items-center justify-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                <img className="w-44 h-14" src={logo} alt="logo" />
              </div>
               <CustomToastContainer />
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-xm dark:text-white flex items-center justify-center">
                Change Password
              </h1>
              <Card>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <CardContent>
                    <section className="grid md:grid-cols-xl:grid-cols-1 gap-5">
                      <Controller
                        name="newPassword"
                        control={control}
                        rules={{
                          required: NEW_PASSWORD_REQUIRED_MESSAGE,
                          pattern: {
                            value:PASSWORD_REGEX,
                            message: "Password must contain at least one lowercase letter, one uppercase letter, one number, one special character, and be 8-128 characters long.Please avoid using the following symbols in your password: / [ ]  { } "
                          }
                        }}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            type={newPasswordVisible ? "text" : "password"}
                            variant="standard"
                            label="New Password"
                            error={!!fieldState?.error}
                            helperText={fieldState?.error ? fieldState.error.message : ""}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                                    edge="end"
                                  >
                                    {newPasswordVisible ? (
                                      <VisibilityOffIcon />
                                    ) : (
                                      <VisibilityIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                      <Controller
                        name="confirmPassword"
                        control={control}
                        rules={{
                          required: CONFIRM_PASSWORD_REQUIRED_MESSAGE,
                          validate: passwordsMatchValidation,
                        }}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            type={confirmPasswordVisible ? "text" : "password"}
                            variant="standard"
                            label="Confirm Password"
                            error={!!fieldState?.error}
                            onPaste={(e) => e.preventDefault()}
                            helperText={fieldState?.error ? fieldState.error.message : ""}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                                    edge="end"
                                  >
                                    {confirmPasswordVisible ? (
                                      <VisibilityOffIcon />
                                    ) : (
                                      <VisibilityIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </section>

                    <div className="text-center mt-10">
                      <CustomButton fieldType="submit" buttonName="change Password" />
                      {isLoading && <CustomLoading />}
                    </div>
                  </CardContent>
                  
                </form>

              </Card>
              <div className="flex items-center justify-center">
                  <span>
                    <a
                      href="/"
                      className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500 text-blue-600"
                    >
                      Sign in
                    </a>
                  </span>
                </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ForgetChangePassword;
