import { useNavigate, useParams } from "react-router-dom";
import CustomViewPage from "../../../components/CustomViewPage";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BASE_URL, RENTER } from "../../../utills/ApplicationRouting";
import axios from "axios";
import { FileDownloadOutlined } from "@mui/icons-material";
import moment from "moment";
import CustomViewWithTooltip from "../../../components/CustomViewWithTooltip";
import AttachmentList from "../../../components/AttachmentList";
import AttachmentListsView from "../../../components/AttachmentListsView";

function RenterViewMc() {
  const navigate = useNavigate();
  const [renterData, setRenterData] = useState([]);
  const { token } = useSelector((state) => state.hybridhoa);
  const { renterId } = useParams();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [createdDateAndTimes, setCreatedDateAndTimes] = useState("");
  const [createdByDetails,setCreatedByDetails]=useState("");
  const[fileImage,setFileImage]=useState([])
  const handleClick = () => {
    navigate(-1);
  };
  const getHomeDetailsById = async () => {
    try {
      const storedToken = token;

      console.log("RenterId" + renterId);
      const response = await axios.get(`${BASE_URL}${RENTER}/${renterId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);
      if (response.status === 200) {
        const data = response.data;
        const createdByDetails = data.createdByDetails;
        if (createdByDetails) {
          setFirstName(createdByDetails.firstName);
          setLastName(createdByDetails.lastName);
        }
        setCreatedByDetails(createdByDetails);
        setFileImage(data.attachments)
        setRenterData(data);
        setFileUrl(data.fileUrl);
        const formattedDate = moment(data.createdDateAndTime).format(
          "MM/DD/YYYY hh:mm A"
        );
        setCreatedDateAndTimes(formattedDate);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getHomeDetailsById();
  }, [renterId]);

  const fullName = firstName + " " + lastName;

  // const data = {
  //   pageTitle: "Renter Details",
  //   buttons: [
  //     {
  //       buttonTitle: "Back",
  //       onClick: handleClick,
  //     },
  //   ],
  //   content: [
  //     {
  //       topic: "Lease start date",
  //       description:moment(renterData.leaseStartDate).format(
  //         "MM/DD/YYYY"),
  //     },
  //     {
  //       topic: "Lease end date",
  //       description:moment(renterData.leaseEndDate).format(
  //         "MM/DD/YYYY"),
  //     },
  //     {
  //       topic: "First Name ",
  //       description: renterData.firstName,
  //     },
  //     {
  //       topic: "Last Name",
  //       description: renterData.lastName,
  //     },
  //     {
  //       topic: "Contact",
  //       description: renterData.contact,
  //     },
  //     {
  //       topic: "Email",
  //       description: renterData.email,
  //     },
  //     {
  //       topic: "Created By",
  //       description: renterData.createdByName,
  //     },
  //     {
  //       topic: "Date Of Birth",
  //       description:
  //         renterData.dateOfBirth === "null" ? "" : renterData.dateOfBirth,
  //     },
  //     {
  //       topic: "Attachments",
  //       component: "AttachmentList",
  //       props: {
  //         fileUrl: fileUrl,
  //         documentName: fileUrl,
  //         docname: fileUrl,
  //         uploadedTimes: createdDateAndTimes,
  //       },
  //     },
  //   ],
  // };

  const fileList = fileImage.map(file => ({
    docname: file.file.substring(12), // Assuming file name is the document name
    fileUrl: file.file, // Assuming file location is the file URL
    documentnamed: file.file, // Assuming file name is the document name
    uploadedTimes: moment(file.createdDateAndTime).format("MM/DD/YYYY hh:mm:ss a"), // Assuming uploadedTimes is available
    }));

  const data = [
    {
      groupName: 'Renter Details',
      items: [
        { label: 'First Name', data: `${renterData?.firstName || ""}`, text: 'The first name of the renter.' },
        { label: 'Last Name', data: `${renterData.lastName || ""}`, text: 'The last name of the renter.' },
        { label: 'Email', data: `${renterData?.email || ""}`, text: 'The email address of the renter.' },
        {
          label: 'Date Of Birth',
          data: renterData.dateOfBirth === "null" || renterData.dateOfBirth === "undefined" || renterData.dateOfBirth === "" ? "" : moment(renterData?.dateOfBirth).format("MM/DD/YYYY"),
          text: 'The date of birth of the renter.'
        },
        {
          label: 'Contact',
          data: renterData?.contact === "undefined" ? "" : renterData?.contact ?? "",
          text: 'The contact number of the renter.'
        },
      ],
    },
    {
      groupName: 'Date Details',
      items: [
        { label: 'Lease start date', data: `${moment(renterData?.leaseStartDate).format("MM/DD/YYYY") || ""}`, text: 'The start date of the lease.' },
        { label: 'Lease end date', data: `${moment(renterData?.leaseEndDate).format("MM/DD/YYYY") || ""}`, text: 'The end date of the lease.' },
      ],
    },
    {
      groupName: 'Attachment Details',
      items: [
        {
          label: 'Attachment',
          data: (
            <AttachmentListsView fileList={fileList} />
          ),
          text: 'Attachments uploaded for this renter.'
        },
      ],
    },
  ];
  
  return (
    <div>
      {/* <CustomViewPage data={data} /> */}
      <CustomViewWithTooltip data={data} onClick={handleClick}  title={"Renter Details"} />
    </div>
  );
}

export default RenterViewMc;
